import { TemporaryLivingStatus, assignmentTypeOptions, costCapOptions, noticeToVacateOptions, vendorOptions } from '../TempLivingConstants';

const createFormField = (header, accessorKey, type, value, tab, required, showBelow = false, disabled = false, options = null) => {
  const field = {
    header,
    accessorKey,
    type,
    value,
    tab,
    required,
    showBelow,
    disabled,
  };

  if (type === 'dropdown' && options) {
    field.options = options;
  }

  return field;
};

// const createPetFields = (prefix, prefixAccessorKey, petName, petType, petBreed, petWeight, petNotes) => {
//   return [
//     createFormField(`${prefix} Name`, `${prefixAccessorKey}Name`, 'text', petName, 1, false),
//     createFormField(`${prefix} Type`, `${prefixAccessorKey}Type`, 'dropdown', petType ?? PetTypes[2], 1, false, false, false, PetTypes),
//     createFormField(`${prefix} Breed`, `${prefixAccessorKey}Breed`, 'text', petBreed, 1, false),
//     createFormField(`${prefix} Weight`, `${prefixAccessorKey}Weight`, 'number', petWeight, 1, false),
//     createFormField(`${prefix} Notes`, `${prefixAccessorKey}Notes`, 'text', petNotes, 1)
//   ];
// };

export const createInitalizationFormFields = (formData, authPets) => {
  const fields = [
    formData?.initializationDate &&
      createFormField('Initiated Date', 'initializationDate', 'date', formData.initializationDate, 1, false, false, true, []),
    createFormField(
      'Assignment Type',
      'assignmentType',
      'dropdown',
      formData?.assignmentType ?? assignmentTypeOptions[0],
      1,
      true,
      false,
      false,
      assignmentTypeOptions,
    ),
    createFormField('Estimated Move In', 'estimatedMoveIn', 'date', formData?.estimatedMoveIn, 1, true),
    createFormField('Estimated Move Out', 'estimatedMoveOut', 'date', formData?.estimatedMoveOut, 1, true),
    createFormField('Vendor', 'vendor', 'dropdown', formData?.vendor, 1, true, false, false, vendorOptions),
    createFormField('Cost Cap', 'costCap', 'number', formData?.costCap, 1, true),
    createFormField('Daily Cost Cap', 'dailyCostCap', 'number', formData?.dailyCostCap, 1, true),
    createFormField('Days Allowed', 'daysAllowed', 'number', formData?.daysAllowed, 1, true),
    createFormField('Cost Cap Options', 'costCapOption', 'dropdown', formData?.costCapOption, 1, true, false, false, costCapOptions),
    createFormField('Notice to Vacate', 'noticeToVacate', 'dropdown', formData?.noticeToVacate, 1, true, false, false, noticeToVacateOptions),
    createFormField('Notice Due Date', 'noticeDueDate', 'date', formData?.noticeDueDate, 1, true),
    createFormField('Move to City', 'moveToCity', 'text', formData?.moveToCity, 1, true),
    createFormField('Move to State', 'moveToState', 'text', formData?.moveToState, 1, true),
    createFormField('Number of Adults', 'numberOfAdults', 'number', formData?.numberOfAdults, 1, true),
    createFormField('Number of Children', 'numberOfChildren', 'number', formData?.numberOfChildren, 1, true),
    createFormField('Number of Pets', 'numberOfPets', 'number', formData?.numberOfPets, 1, true),
    createFormField('Number of Bedrooms', 'numberOfBedrooms', 'number', formData?.numberOfBedrooms, 1, true),
    createFormField('Internet Access', 'internetAccess', 'checkbox', formData?.internetAccess ?? true, 1, true),
    // createFormField('Pets', 'pets', 'notes', '', 1, false, true, true),
    // ...createPetFields(
    //   'Pet One',
    //   'petOne',
    //   formData?.petOneName,
    //   formData?.petOneType,
    //   formData?.petOneBreed,
    //   formData?.petOneWeight,
    //   formData?.petOneNotes
    // ),
    // ...createPetFields(
    //   'Pet Two',
    //   'petTwo',
    //   formData?.petTwoName,
    //   formData?.petTwoType,
    //   formData?.petTwoBreed,
    //   formData?.petTwoWeight,
    //   formData?.petTwoNotes
    // ),
    // ...createPetFields(
    //   'Pet Three',
    //   'petThree',
    //   formData?.petThreeName,
    //   formData?.petThreeType,
    //   formData?.petThreeBreed,
    //   formData?.petThreeWeight,
    //   formData?.petThreeNotes
    // ),
    createFormField('Special Needs', 'specialNeeds', 'notes', formData?.specialNeeds, 1, false, true),
  ].filter(Boolean); // Remove undefined values for conditional fields

  return fields;
};

const createTempLivingPropertyFormFields = (formData) => {
  const fields = [
    createFormField('Vendor', 'vendorName', 'dropdown', formData?.vendorName ?? formData?.vendor, 2, true, false, false, vendorOptions),
    createFormField('Vendor Contact', 'vendorContact', 'text', formData?.vendorContact, 2, false),
    createFormField('Complex Name', 'complexName', 'text', formData?.complexName, 2, false),
    createFormField('Daily Rate', 'dailyRate', 'number', formData?.dailyRate, 2, false),
    { dividerLine: true, tab: 2 },
    createFormField('Street Address', 'streetAddress', 'text', formData?.streetAddress, 2, false),
    createFormField('Unit Number', 'unitNumber', 'text', formData?.unitNumber, 2, false),

    //TODO - I bet we could abstract the "Create Address Form" and use this throughout the application
    //TODO - also, state and country should be dropdown?
    createFormField('City', 'city', 'text', formData?.city, 2, false),
    createFormField('State', 'state', 'text', formData?.state, 2, false),
    createFormField('Zip Code', 'zipCode', 'text', formData?.zipCode, 2, false),
    createFormField('Country', 'country', 'text', formData?.country, 2, false),
  ];

  return fields;
};

const createMovedOutAndMovedInFormFields = (formData, currentStatus) => {
  const fields = [
    createFormField('Actual Move In', 'movedInDate', 'date', formData?.movedInDate, 3, false),
    createFormField('Notice Due Date', 'noticeDueDate', 'date', formData?.noticeDueDate, 3, false),
    { dividerLine: true, tab: 3 },
    createFormField(
      'Actual Move Out',
      'movedOutDate',
      'date',
      formData?.movedOutDate,
      3,
      false,
      false,
      currentStatus < TemporaryLivingStatus.MovedIn.sequenceOrder,
    ),
    createFormField(
      'Notice Given',
      'noticeGiven',
      'date',
      formData?.noticeGiven,
      3,
      false,
      false,
      currentStatus < TemporaryLivingStatus.MovedIn.sequenceOrder,
    ),
    createFormField(
      'Days Needed',
      'daysNeeded',
      'number',
      formData?.daysNeeded,
      3,
      false,
      false,
      currentStatus < TemporaryLivingStatus.MovedIn.sequenceOrder,
    ),
    createFormField(
      'Days Used',
      'daysUsed',
      'number',
      formData?.daysUsed,
      3,
      false,
      false,
      currentStatus < TemporaryLivingStatus.MovedIn.sequenceOrder,
    ),
    createFormField(
      'Exception Days',
      'exceptionDays',
      'number',
      formData?.exceptionDays,
      3,
      false,
      false,
      currentStatus < TemporaryLivingStatus.MovedIn.sequenceOrder,
    ),
  ];
  return fields;
};

export const createFormFieldsForTempLiving = (formData, authPets, currentStatus) => {
  return [
    ...createInitalizationFormFields(formData, authPets),
    ...createTempLivingPropertyFormFields(formData),
    ...createMovedOutAndMovedInFormFields(formData, currentStatus),
  ];
};
