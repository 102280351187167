import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { DELETE_CLIENT_CONTRACT_SLA_FAILURE, GET_CLIENT_CONTRACT_SLA_FAILURE, POST_CLIENT_CONTRACT_SLA_FAILURE, UPDATE_CLIENT_CONTRACT_SLA_FAILURE } from '../../types';
import { deleteClientContractSLA, getClientContractSLA, postClientContractSLA, updateClientContractSLA } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';


const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientContractsSLATable = (props) => {
  const [clientOverview, setClientOverview] = useState(false);
  const theme = useTheme();

  const adjustedColumns = [
    { accessorKey: 'component', header: 'Component', type: 'dropdown', options:['Communication', 'Service', 'File Management', 'Payment Accuracy', 'System', 'Invoice', 'Payroll', 'HHG'], size: 80, required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'goalOrTarget', header: 'Goal/Target', type: 'text', required: true, size: 60, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'metricOrKPI', header: 'Metric/KPI', type: 'text', required: true, size: 60, enableColumnDragging: false, enableColumnActions: false, enableResizing: false  },
    { accessorKey: 'penalty', header: 'Penalty', type: 'text', required: true, size: 60, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'notes', header: 'Notes', type: 'notes', size: 125, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },

  ];

  const updateRow = async (contractsSLA, values) => {
    const id = contractsSLA.original.clientContractsSLAKey;
    values.clientContractsSLAKey = id;
    values.clientId = props.clientId;
    const resp = await props.updateClientContractSLA(values);
    if (resp.type === UPDATE_CLIENT_CONTRACT_SLA_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractSLA(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_SLA_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postClientContractSLA(values);
    if (resp.type === POST_CLIENT_CONTRACT_SLA_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractSLA(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_SLA_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (contractsSLA) => {
    const id = contractsSLA.original.clientContractsSLAKey;
    const resp = await props.deleteClientContractSLA(id);
    if (resp.type === DELETE_CLIENT_CONTRACT_SLA_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractSLA(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_SLA_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>SLA</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientContractsSLALoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.clientContractsSLA} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {props.clientContractsSLALoading && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

ClientContractsSLATable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientContractsSLALoading: PropTypes.bool,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
  postClientContractSLA: PropTypes.func,
  updateClientContractSLA: PropTypes.func,
  getClientContractSLA: PropTypes.func,
  deleteClientContractSLA: PropTypes.func,
  clientContractsSLA: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { clientContractsSLA } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientContractsSLALoading } = state.clients;
  return {
    clientContractsSLA,
    clientContractsSLALoading,
  };
};
  
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientContractSLA,
    deleteClientContractSLA,
    postClientContractSLA,
    updateClientContractSLA,
  }),
)(ClientContractsSLATable);