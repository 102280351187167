/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress,
  getDestinationAddress, getEmployeeInfo, getFamilyMembers, getFamilyPets, getServices, getTeam, getUserSettings, setAuthId,
  setLoadingComplete,
} from '../../store/actions/authorizations.actions';

import {
  getBrokerAgents, getVendorContacts, getVendors,
} from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  getAssignmentExtensions, getAssignmentTrips, getGlobalDestinationServices, getGlobalHomeServiceProperties, getGlobalInfo,
  getGlobalInternationalSchooling,
  getGlobalLanguageTraining,
} from 'modules/authorizations/store/actions/global.actions';

import {
  COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING,
} from 'styles/theme';

import GlobalService from './globalService.component';
import { fetchAuthDetails, fetchAuthGlobalDetails, fetchBrokerAgents, fetchVendorContacts, fetchVendors } from 'modules/authorizations/utils/fetchDataDetailsService';
import { showToast } from 'modules/layout/layout.actions';


const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const GlobalView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
   
  });
    
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo = details && details?.authDetails?.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details?.authDetails?.summary.clientName) : null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const id = props.match.params.id;

      window.scrollTo(0, 0);
      props.setAuthId(id);
      setIsLoading(true);
      
      await fetchAuthDetails(details, id, props);
      await fetchVendors(props?.vendors, props);
      await fetchVendorContacts(props?.vendorContacts, props);
      await fetchBrokerAgents(props?.brokerAgents, props);
      await fetchAuthGlobalDetails(details, id, props);

      props.setLoadingComplete();
      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && clientInfo &&
          <GlobalService details={details} clientInfo={clientInfo} id={props.match.params.id} history={props.history} location={props.location} />
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

GlobalView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, isLoading, vendors, vendorContacts, brokerAgents } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
    vendors,
    vendorContacts,
    brokerAgents
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    setAuthId,
    setLoadingComplete,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getGlobalInfo,
    getAssignmentTrips,
    getAssignmentExtensions,
    getVendorContacts,
    getVendors,
    getBrokerAgents,
    getGlobalHomeServiceProperties,
    getFamilyMembers,
    getFamilyPets,
    showToast,
    getGlobalInternationalSchooling,
    getGlobalDestinationServices,
    getGlobalLanguageTraining
  }),
)(GlobalView);