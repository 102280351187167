import produce from 'immer';

import {
  GET_RELOCATION_CONSULTANTS_FAILURE, GET_RELOCATION_CONSULTANTS_REQUEST, GET_RELOCATION_CONSULTANTS_SUCCESS, 
} from './types';

import { remapUsers } from './utils';

const initialState = {
  relocationConsultants: null,
  isLoading: false,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_RELOCATION_CONSULTANTS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_RELOCATION_CONSULTANTS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_RELOCATION_CONSULTANTS_SUCCESS:
      draft.relocationConsultants = remapUsers(action.response);
      draft.isLoading = false;
      break;
  
    default:
      break;
  }

  return draft;
}, initialState);
