import { Checkbox } from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateListing } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const ReferralFeesCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { listingInfo, isReadOnly } = props;

  const tabs = [
    { header: 'Referral Fees', order: 1, iconPath: ['fas', 'dollar-sign'] },
  ];

  const summaries = [
    { header: 'Referral Due', accessorKey: 'referralDue', type: 'checkbox', required: false, value: listingInfo ? listingInfo.referralDue : '', tab: 1, Cell: ({ cell }) => <Checkbox name="referralDue" label="Active" checked={cell.row.original.referralDue} disabled={true} /> },
    { header: 'Reason for no Referral', accessorKey: 'reasonForNoReferral', type: 'text', required: false, value: listingInfo ? listingInfo.reasonForNoReferral : '', tab: 1 },
    { header: 'Referral Fee %', accessorKey: 'referralFeePercent', type: 'text', required: false, value: listingInfo ? listingInfo.referralFeePercent : '', tab: 1 },
    { header: 'Exp. Referral Fee', accessorKey: 'expReferralFee', type: 'money', required: false, value: (listingInfo.listPrice && listingInfo.referralFeePercent && listingInfo.brokerCommissionPercent) ? (listingInfo.listPrice * (listingInfo.referralFeePercent / 100) * (listingInfo.brokerCommissionPercent / 100)) : null, tab: 1 },
    { header: 'Broker Commission %', accessorKey: 'brokerCommissionPercent', type: 'number', required: false, value: listingInfo ? listingInfo.brokerCommissionPercent : '', tab: 1 },
    { header: 'Exp. Broker Commission', accessorKey: 'expBrokerCommission', type: 'money', required: false, value: (listingInfo.listPrice && listingInfo.brokerCommissionPercent) ? (listingInfo.listPrice * (listingInfo.brokerCommissionPercent / 100)) : null, tab: 1 },
    { dividerLine: true, tab: 1 },
    { header: 'Actual Referral Received', accessorKey: 'actualReferralFeeReceived', type: 'money', required: false, value: listingInfo ? listingInfo.actualReferralFeeReceived : '', tab: 1 },
    { header: 'Fees Collected', accessorKey: 'feesCollected', type: 'checkbox', required: false, value: listingInfo ? listingInfo.feesCollected : '', tab: 1, Cell: ({ cell }) => <Checkbox name="feesCollected" label="Active" checked={cell.row.original.feesCollected} disabled={true} /> },
    { header: 'Reason Not Collected', accessorKey: 'reasonNotCollected', type: 'text', required: false, value: listingInfo ? listingInfo.reasonNotCollected : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...listingInfo };

    newValues.referralFeePercent = values.referralFeePercent;
    newValues.actualReferralFeeReceived = values.actualReferralFeeReceived;
    newValues.brokerCommissionPercent = values.brokerCommissionPercent;
    newValues.feesCollected = values.feesCollected;
    newValues.reasonNotCollected = values.reasonNotCollected;
    newValues.referralDue = values.referralDue;
    newValues.reasonForNoReferral = values.reasonForNoReferral;

    const resp = await props.updateListing(newValues);
    if (resp.type === UPDATE_LISTINGS_FAILURE) {
      props.showToast('Failed to update broker info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateListing,
    showToast,
  }),
)(ReferralFeesCard);