/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DestinationServices from './destinationServices.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../../layout/types';

import {
  getAuthClientPolicies,
  getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress, getEmployeeInfo, getFamilyMembers, getFamilyPets, getServices, getTeam, getUserSettings, setAuthId, setLoadingComplete,
} from '../../store/actions/authorizations.actions';

import {
  getAuthDestinationServices, getHouseHuntingList, getOfferHistoryList,
  postAuthDestinationServices, updateAuthDestinationServices,
} from 'modules/authorizations/store/actions/destinationService.actions';

import {
  getBrokerAgents,
  getVendors,
} from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  POST_AUTH_DESTINATION_SERVICES_FAILURE, UPDATE_AUTH_DESTINATION_SERVICES_FAILURE,
} from 'modules/authorizations/store/types/destinationServiceTypes';

import {
  COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING,
} from 'styles/theme';
import { fetchAuthDestinationDetails, fetchAuthDetails, fetchBrokerAgents, fetchVendors } from 'modules/authorizations/utils/fetchDataDetailsService';
import { showToast } from 'modules/layout/layout.actions';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const DestinationServicesView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY, 
      warning: COLOR_WARNING,
    },
   
  });
    
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const [isLoading, setIsLoading] = useState(false);

  const updateDestinationService = async (values) => {
    setIsLoading(true);
    if (details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices?.length !== 0) {
      const resp = await props.updateAuthDestinationServices(values);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postAuthDestinationServices(values);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  useEffect(() => {
    async function fetchData () {
      const id = props.match.params.id;

      setIsLoading(true);
      props.setAuthId(id);

      await fetchAuthDetails(details, id, props);
      await fetchVendors(props?.vendors, props);
      await fetchBrokerAgents(props?.brokerAgents, props);
      await fetchAuthDestinationDetails(details, id, props);

      setIsLoading(false);
      props.setLoadingComplete();
    }

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details &&
          <DestinationServices updateDestinationService={updateDestinationService} details={details} brokerAgents={props.brokerAgents} id={props.match.params.id} history={props.history} location={props.location} />
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

DestinationServicesView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, isLoading, vendors, brokerAgents } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
    vendors,
    brokerAgents
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getAuthDestinationServices,
    updateAuthDestinationServices,
    postAuthDestinationServices,
    getBrokerAgents,
    setAuthId,
    setLoadingComplete,
    getHouseHuntingList,
    getOfferHistoryList,
    getEmployeeInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getVendors,
    getFamilyMembers,
    getFamilyPets,
    showToast
  }),
)(DestinationServicesView);