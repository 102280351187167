import { POST_EMPLOYEE_INFO_FAILURE, UPDATE_EMPLOYEE_INFO_FAILURE } from '../../../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

export const useEmployeeHandler = ({ postEmployeeInfo, updateEmployeeInfo, showToast }) => {
  const handleCreateEmployee = async (values) => {
    try {
      const resp = await postEmployeeInfo(values);
      if (resp.type === POST_EMPLOYEE_INFO_FAILURE) {
        showToast('Failed to create employee info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while creating employee info.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleUpdateEmployee = async (values) => {
    try {
      const resp = await updateEmployeeInfo(values);
      if (resp.type === UPDATE_EMPLOYEE_INFO_FAILURE) {
        showToast('Failed to update employee info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while updating employee info.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const createOrUpdateEmployee = async (isNew, values) => {
    try {
      if (isNew) {
        return await handleCreateEmployee(values);
      } else {
        return await handleUpdateEmployee(values);
      }
    } catch (error) {
      showToast('An unexpected error occurred while processing employee data.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return { createOrUpdateEmployee };
};
