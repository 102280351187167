/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Chip, FormControlLabel, MenuItem, Select, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    firstCol: {
      width: 200,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleLossOnSale = (props) => {

  const clientHomesaleLossOnSale = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleLossOnSale : null;

  const [lossOnSaleInitiated, setLossOnSaleInitiated] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.lossOnSaleInitiated : false);
  const [whoIsEligible, setWhoIsEligible] = useState(clientHomesaleLossOnSale && clientHomesaleLossOnSale.whoIsEligible ? clientHomesaleLossOnSale.whoIsEligible.split(',') : []);
  const [howCalculated, setHowCalculated] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.howCalculated : null);
  const [isThereCap, setIsThereCap] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.isThereCap : null);
  const [documentationRequired, setDocumentationRequired] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.documentationRequired : null); 
  const [whenDistributed, setWhenDistributed] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.whenDistributed : null);
  const [whoProcesses, setWhoProcesses] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.whoProcesses : null);
  const [isGrossedUp, setIsGrossedUp] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.isGrossedUp : null);
  const [processNotes, setProcessNotes] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.processNotes : null);
  const [otherNotes, setOtherNotes] = useState(clientHomesaleLossOnSale ? clientHomesaleLossOnSale.otherNotes : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      LossOnSaleInitiated: lossOnSaleInitiated,
      WhoIsEligible: whoIsEligible.join(','),
      HowCalculated: howCalculated,
      IsThereCap: isThereCap,
      DocumentationRequired: documentationRequired,
      WhenDistributed: whenDistributed,
      WhoProcesses: whoProcesses,
      IsGrossedUp: isGrossedUp,
      ProcessNotes: processNotes,
      OtherNotes: otherNotes,
    };

    if (clientHomesaleLossOnSale && clientHomesaleLossOnSale.id) updateBody.Id = clientHomesaleLossOnSale.id;

    props.updateInfo(updateBody);
  }, [lossOnSaleInitiated, whoIsEligible, howCalculated, isThereCap, documentationRequired, whenDistributed, whoProcesses, isGrossedUp, processNotes, otherNotes]);

  return (
    <div className={props.classes.contractsGrid}>
      <div className={props.classes.headerRow}>
        <h3>Loss On Sale</h3>
        <FormControlLabel
          control={
            <Checkbox
              name="homeSaleLossOnSale"
              label="Activate Service"
              checked={lossOnSaleInitiated}
              onChange={(e) => setLossOnSaleInitiated(e.target.checked)}
            />
          }
          label="Activate Service"
          classes={{ root: props.classes.checkbox }}
        />
      </div>
      {lossOnSaleInitiated && (
        <div className={props.classes.tabBorder}>
          <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
            <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
              <p className={props.classes.descText}>Who is eligible for this benefit?</p>
              <Select
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Who is Eligible?'}
                onChange={(e) => setWhoIsEligible(e.target.value)}
                multiple
                value={whoIsEligible}
                renderValue={(selected) => {
                  return (
                    <div className={props.classes.chips}>
                      {selected.map((value) => {
                        const policyInfo = props.clientPolicyNotes.find((o) => o.clientPolicyNotesKey === parseInt(value));
                        return (
                          <Chip
                            key={policyInfo.clientPolicyNotesKey}
                            label={policyInfo.name}
                            className={props.classes.chip}
                            onDelete={() => {
                              setWhoIsEligible(whoIsEligible.filter((b) => b !== value));
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        );
                      })}
                    </div>
                  );}}
              >
                {props.clientPolicyNotes && props.clientPolicyNotes.map((c) => {
                  return <MenuItem key={c.clientPolicyNotesKey} value={c.clientPolicyNotesKey}>{c.name}</MenuItem>;
                })}
              </Select>
              <p className={props.classes.descText}>How is it calculated?</p>
              <TextField
                id="name"
                label="Calculation"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={howCalculated}
                onChange={(e) => setHowCalculated(e.target.value)}
              />
              <p className={props.classes.descText}>Is there a cap?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Has Cap'}
                onChange={(e) => setIsThereCap(e.target.value)}
                required
                select
                value={isThereCap}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
              <p className={props.classes.descText}>What documentation is required?</p>
              <TextField
                id="name"
                label="Documentation Required"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={documentationRequired}
                onChange={(e) => setDocumentationRequired(e.target.value)}
              />
              <p className={props.classes.descText}>When is it distributed?</p>
              <TextField
                id="name"
                label="Distribution"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenDistributed}
                onChange={(e) => setWhenDistributed(e.target.value)}
              />
            </div>

            <div className={props.classes.contentCol}>
              <p className={props.classes.descText}>Who processes this benefit?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Processing'}
                onChange={(e) => setWhoProcesses(e.target.value)}
                required
                select
                value={whoProcesses}
              >
                <MenuItem value={1}>Client</MenuItem>
                <MenuItem value={0}>TRC</MenuItem>
              </TextField>
              <p className={props.classes.descText}>Is this benefit grossed up?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Gross Up'}
                onChange={(e) => setIsGrossedUp(e.target.value)}
                required
                select
                value={isGrossedUp}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
              <TextField
                variant="outlined"
                label={'Process Notes'}
                value={processNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setProcessNotes(e.target.value)}
              />
              <TextField
                variant="outlined"
                label={'Other Notes'}
                value={otherNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}; 

HomeSaleLossOnSale.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleLossOnSale);