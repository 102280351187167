const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const {
  DELETE_GLOBAL_LANGUAGE_TRAINING_REQUEST,
  DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  DELETE_GLOBAL_LANGUAGE_TRAINING_FAILURE,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  POST_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
  POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  POST_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
  UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
  DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  DELETE_GLOBAL_DESTINATION_SERVICES_REQUEST,
  DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  DELETE_GLOBAL_DESTINATION_SERVICES_FAILURE,
  GET_GLOBAL_DESTINATION_SERVICES_REQUEST,
  GET_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  GET_GLOBAL_DESTINATION_SERVICES_FAILURE,
  POST_GLOBAL_DESTINATION_SERVICES_REQUEST,
  POST_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  POST_GLOBAL_DESTINATION_SERVICES_FAILURE,
  GET_GLOBAL_LANGUAGE_TRAINING_REQUEST,
  GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  GET_GLOBAL_LANGUAGE_TRAINING_FAILURE,
  POST_GLOBAL_LANGUAGE_TRAINING_REQUEST,
  POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  POST_GLOBAL_LANGUAGE_TRAINING_FAILURE,
  UPDATE_GLOBAL_LANGUAGE_TRAINING_REQUEST,
  UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  UPDATE_GLOBAL_LANGUAGE_TRAINING_FAILURE,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
  GET_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
  GET_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
  POST_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
  POST_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
  DELETE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
  DELETE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  DELETE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_REQUEST,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_SUCCESS,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_FAILURE,
  GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
  GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
  GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
  POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
  POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
  POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
  DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
  DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
  DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
  GET_GLOBAL_INFO_REQUEST,
  GET_GLOBAL_INFO_SUCCESS,
  GET_GLOBAL_INFO_FAILURE,
  POST_GLOBAL_INFO_REQUEST,
  POST_GLOBAL_INFO_SUCCESS,
  POST_GLOBAL_INFO_FAILURE,
  UPDATE_GLOBAL_INFO_REQUEST,
  UPDATE_GLOBAL_INFO_SUCCESS,
  UPDATE_GLOBAL_INFO_FAILURE,
  UPDATE_GLOBAL_DESTINATION_SERVICES_REQUEST,
  UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  UPDATE_GLOBAL_DESTINATION_SERVICES_FAILURE,
  GET_ASSIGNMENT_TRIPS_REQUEST,
  GET_ASSIGNMENT_TRIPS_SUCCESS,
  GET_ASSIGNMENT_TRIPS_FAILURE,
  POST_ASSIGNMENT_TRIPS_REQUEST,
  POST_ASSIGNMENT_TRIPS_SUCCESS,
  POST_ASSIGNMENT_TRIPS_FAILURE,
  UPDATE_ASSIGNMENT_TRIPS_REQUEST,
  UPDATE_ASSIGNMENT_TRIPS_SUCCESS,
  UPDATE_ASSIGNMENT_TRIPS_FAILURE,
  DELETE_ASSIGNMENT_TRIPS_REQUEST,
  DELETE_ASSIGNMENT_TRIPS_SUCCESS,
  DELETE_ASSIGNMENT_TRIPS_FAILURE,
  GET_ASSIGNMENT_EXTENSIONS_REQUEST,
  GET_ASSIGNMENT_EXTENSIONS_SUCCESS,
  GET_ASSIGNMENT_EXTENSIONS_FAILURE,
  POST_ASSIGNMENT_EXTENSIONS_REQUEST,
  POST_ASSIGNMENT_EXTENSIONS_SUCCESS,
  POST_ASSIGNMENT_EXTENSIONS_FAILURE,
  UPDATE_ASSIGNMENT_EXTENSIONS_REQUEST,
  UPDATE_ASSIGNMENT_EXTENSIONS_SUCCESS,
  UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE,
  DELETE_ASSIGNMENT_EXTENSIONS_REQUEST,
  DELETE_ASSIGNMENT_EXTENSIONS_SUCCESS,
  DELETE_ASSIGNMENT_EXTENSIONS_FAILURE,
} = require('../types/globalTypes');

export const deleteGlobalLanguageTraining = (authId, id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_GLOBAL_LANGUAGE_TRAINING_REQUEST, DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS, DELETE_GLOBAL_LANGUAGE_TRAINING_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalLanguageTraining/${id}`,
      method: 'DELETE',
    },
  };
};

export const getGlobalInternationalSchooling = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        GET_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
        GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
        GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalInternationSchooling`,
      method: 'GET',
    },
  };
};

export const postGlobalInternationalSchooling = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        POST_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
        POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
        POST_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalInternationSchooling/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateGlobalInternationalSchooling = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
        UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
        UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalInternationSchooling/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteGlobalInternationalSchooling = (authId, id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST,
        DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
        DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalInternationSchooling/${id}`,
      method: 'DELETE',
    },
  };
};

export const deleteGlobalDestinationServices = (authId, id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        DELETE_GLOBAL_DESTINATION_SERVICES_REQUEST,
        DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
        DELETE_GLOBAL_DESTINATION_SERVICES_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalDestServices/${id}`,
      method: 'DELETE',
    },
  };
};

export const getGlobalDestinationServices = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_GLOBAL_DESTINATION_SERVICES_REQUEST, GET_GLOBAL_DESTINATION_SERVICES_SUCCESS, GET_GLOBAL_DESTINATION_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalDestServices`,
      method: 'GET',
    },
  };
};

export const postGlobalDestinationServices = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_GLOBAL_DESTINATION_SERVICES_REQUEST, POST_GLOBAL_DESTINATION_SERVICES_SUCCESS, POST_GLOBAL_DESTINATION_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalDestServices/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const getGlobalLanguageTraining = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_GLOBAL_LANGUAGE_TRAINING_REQUEST, GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS, GET_GLOBAL_LANGUAGE_TRAINING_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalLanguageTraining`,
      method: 'GET',
    },
  };
};

export const postGlobalLanguageTraining = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_GLOBAL_LANGUAGE_TRAINING_REQUEST, POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS, POST_GLOBAL_LANGUAGE_TRAINING_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalLanguageTraining/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateGlobalLanguageTraining = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_GLOBAL_LANGUAGE_TRAINING_REQUEST, UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS, UPDATE_GLOBAL_LANGUAGE_TRAINING_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalLanguageTraining/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getGlobalHomeServiceProperties = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
        GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
        GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalHomeService/${id}`,
      method: 'GET',
    },
  };
};

export const getGlobalHomeServiceProperty = (propertyId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST, GET_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS, GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalHomeService/getById/${propertyId}`,
      method: 'GET',
    },
  };
};

export const postGlobalHomeServiceProperty = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        POST_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
        POST_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
        POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
      ],
      authenticated: true,
      endpoint: 'api/authorizations/globalHomeService',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateGlobalHomeServiceProperty = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
        UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
        UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
      ],
      authenticated: true,
      endpoint: 'api/authorizations/globalHomeService',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteGlobalHomeServiceProperty = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        DELETE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST,
        DELETE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
        DELETE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalHomeService/${id}`,
      method: 'DELETE',
    },
  };
};

export const getAllGlobalHomeServicePropertyTenants = (propertyId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_REQUEST,
        GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_SUCCESS,
        GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalHomeService/tenant/${propertyId}`,
      method: 'GET',
    },
  };
};

export const getGlobalHomeServicePropertyTenant = (tenantId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
        GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
        GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalHomeService/tenant/${tenantId}`,
      method: 'GET',
    },
  };
};

export const postGlobalHomeServicePropertyTenant = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
        POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
        POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
      ],
      authenticated: true,
      endpoint: 'api/authorizations/globalHomeService/tenant',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateGlobalHomeServicePropertyTenant = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
        UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
        UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
      ],
      authenticated: true,
      endpoint: 'api/authorizations/globalHomeService/tenant',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteGlobalHomeServicePropertyTenant = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST,
        DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS,
        DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalHomeService/tenant/${id}`,
      method: 'DELETE',
    },
  };
}; /* Global Requests */

export const getGlobalInfo = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_GLOBAL_INFO_REQUEST, GET_GLOBAL_INFO_SUCCESS, GET_GLOBAL_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/global`,
      method: 'GET',
    },
  };
};

export const postGlobalInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_GLOBAL_INFO_REQUEST, POST_GLOBAL_INFO_SUCCESS, POST_GLOBAL_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/global/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateGlobalInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_GLOBAL_INFO_REQUEST, UPDATE_GLOBAL_INFO_SUCCESS, UPDATE_GLOBAL_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/global/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const updateGlobalDestinationServices = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        UPDATE_GLOBAL_DESTINATION_SERVICES_REQUEST,
        UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
        UPDATE_GLOBAL_DESTINATION_SERVICES_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/authorizations/globalDestServices/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getAssignmentTrips = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ASSIGNMENT_TRIPS_REQUEST, GET_ASSIGNMENT_TRIPS_SUCCESS, GET_ASSIGNMENT_TRIPS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalTrips`,
      method: 'GET',
    },
  };
};

export const postAssignmentTrip = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_ASSIGNMENT_TRIPS_REQUEST, POST_ASSIGNMENT_TRIPS_SUCCESS, POST_ASSIGNMENT_TRIPS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalTrips/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAssignmentTrip = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_ASSIGNMENT_TRIPS_REQUEST, UPDATE_ASSIGNMENT_TRIPS_SUCCESS, UPDATE_ASSIGNMENT_TRIPS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalTrips/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteAssignmentTrip = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_ASSIGNMENT_TRIPS_REQUEST, DELETE_ASSIGNMENT_TRIPS_SUCCESS, DELETE_ASSIGNMENT_TRIPS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/globalTrips`,
      method: 'DELETE',
    },
  };
};

export const getAssignmentExtensions = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ASSIGNMENT_EXTENSIONS_REQUEST, GET_ASSIGNMENT_EXTENSIONS_SUCCESS, GET_ASSIGNMENT_EXTENSIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/globalExtensions`,
      method: 'GET',
    },
  };
};

export const postAssignmentExtension = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_ASSIGNMENT_EXTENSIONS_REQUEST, POST_ASSIGNMENT_EXTENSIONS_SUCCESS, POST_ASSIGNMENT_EXTENSIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalExtensions/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAssignmentExtension = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_ASSIGNMENT_EXTENSIONS_REQUEST, UPDATE_ASSIGNMENT_EXTENSIONS_SUCCESS, UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/globalExtensions/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteAssignmentExtension = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_ASSIGNMENT_EXTENSIONS_REQUEST, DELETE_ASSIGNMENT_EXTENSIONS_SUCCESS, DELETE_ASSIGNMENT_EXTENSIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/globalExtensions`,
      method: 'DELETE',
    },
  };
};
