/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */

const clientDates = [{
  title: 'Survey Ready',
  field: 'surveyReady',
}, {
  title: 'Survey Sent',
  field: 'surveySent',
}, {
  title: 'Survey Received',
  field: 'surveyReceived',
}, {
  title: 'Survey Follow Up Sent',
  field: 'surveyFollowUpSent',
}, {
  title: 'Survey Completed',
  field: 'surveyCompleted',
}, {
  title: 'Date Entered',
  field: 'dateEntered',
}, {
  title: 'Audit Date',
  field: 'auditDate',
}, {
  title: 'Post Date',
  field: 'postDate',
}, {
  title: 'Last Contact',
  field: 'lastContactDate',
}, {
  title: 'Year End Cutoff',
  field: 'yearEndCutoffDate',
}, {
  title: 'Blackout Date',
  field: 'blackoutDate',
}, {
  title: 'Following Year Cutoff',
  field: 'followingYearCutoffDate',
}];

const periodDates = [{
  title: 'Period Start',
  field: 'periodStartDate',
}, {
  title: 'Period End',
  field: 'periodEndDate',
}, {
  title: 'Reconciled',
  field: 'reconciledDate',
}, {
  title: 'Payroll Reconciled Through',
  field: 'payrollReconciledThroughDate',
}, {
  title: 'Tax Assistance Uploaded Through',
  field: 'taxAssistanceUploadedThroughDate',
}, {
  title: 'ExpenseCodeAudited',
  field: 'expenseCodesAuditedDate',
}, {
  title: 'Actual Expense Cutoff',
  field: 'actualExpenseCutoffDate',
}, {
  title: 'Wage Request Prep',
  field: 'wageRequestPrepDate',
}, {
  title: 'Actual Wage Request Prep',
  field: 'actualWageRequestPrepDate',
}, {
  title: 'Wage Request Sent',
  field: 'wageRequestSentDate',
}, {
  title: 'Actual Wage Request Sent',
  field: 'actualWageRequestSentDate',
}, {
  title: 'Wage Request Received',
  field: 'wageRequestReceivedDate',
}, {
  title: 'Actual Wage Request Received',
  field: 'actualWageRequestReceivedDate',
}, {
  title: 'Wage Request Upload',
  field: 'wageRequestUploadDate', 
}, {
  title: 'Actual Wage Request Upload',
  field: 'actualWageRequestUploadDate',
}, {
  title: 'Payroll Start',
  field: 'payrollStartDate',
}, {
  title: 'Actual Payroll Start',
  field: 'actualPayrollStartDate', 
}, {
  title: 'Ready for Audit',
  field: 'readyForAuditDate', 
}, {
  title: 'Actual Ready for Audit',
  field: 'actualReadyForAuditDate',
}, {
  title: 'Payroll Sent',
  field: 'payrollSentDate', 
}, {
  title: 'Actual Payroll Sent',
  field: 'actualPayrollSentDate', 
}];

export const parsePayrollEvents = (c, periods) => {
  let events = [];

  clientDates.map((d, i) => {
    if (c[d.field]) events.push({ title: d.title, start: c[d.field], end: c[d.field], allDay: true, color: '#ff0000', id: i });
  });

  periods.map((p, i) => {
    periodDates.map((pData, j) => {
      if (p[pData.field]) events.push({ title: `${pData.title} (P${i + 1})`, start: p[pData.field], end: p[pData.field], allDay: true, color: '#3174ad', id: i + j + 12 });
    });
  });

  return events;
};

export const periodOptions = [{
  id: 1,
  description: 'Weekly',
  number: 52,
  numDays: 7,
}, {
  id: 2,
  description: 'Bi-Weekly',
  number: 26,
  numDays: 14,
}, {
  id: 3,
  description: 'Bi-Monthly',
  number: 24,
  numDays: 16,
}, {
  id: 4,
  description: 'Monthly',
  number: 12,
  numDays: 31,
}, {
  id: 5,
  description: 'Quarterly',
  number: 4,
  numDays: 90,
}, {
  id: 6,
  description: 'Annualy',
  number: 1,
  numDays: 365,
}];

export const generateInitialValues = (clientId) => {
  return {
    calendarInfo: {
      // clientPayrollCalendarId - generated on backend during create function
      clientId,
      calendarYear: null,
      name: null,
      periodFrequencyId: null,
      password: null,
      surveyReady: null,
      surveySent: null,
      surveyReceived: null,
      surveyFollowUpSent: null,
      surveyCompleted: null,
      method: null,
      surveySaved: null,
      dateEntered: new Date().toISOString().slice(0, 10),
      auditDate: null,
      postDate: null,
      rtrCount: null,
      w2C: null,
      w2CReason: null,
      lastContactDate: null,
      yearEndCutoffDate: null,
      blackoutDate: null,
      followingYearCutoffDate: null,
    },
    periodInfo: {
      // clientPayrollPeriodId - generated on backend during create function
      clientPayrollCalendarId: null, // generated once calendar is created
      periodStartDate: null, // generated once calendar is saved
      periodEndDate: null, // generated once calendar is saved
      numberOfEESent: 0, // 0 by default for all periods
      numberOfEEReceived: 0, // 0 by default for all periods
      reconciledDate: null,
      payrollReconciledThroughDate: null,
      taxAssistanceUploadedThroughDate: null,
      expenseCodesAuditedDate: null,
      expenseCutoffDate: null,
      actualExpenseCutoffDate: null,
      wageRequestPrepDate: null,
      actualWageRequestPrepDate: null,
      wageRequestSentDate: null,
      actualWageRequestSentDate: null,
      wageRequestReceivedDate: null,
      actualWageRequestReceivedDate: null,
      wageRequestUploadDate: null,
      actualWageRequestUploadDate: null,
      payrollStartDate: null,
      actualPayrollStartDate: null,
      readyForAuditDate: null,
      actualReadyForAuditDate: null,
      payrollSentDate: null,
      actualPayrollSentDate: null,
    },
  };
};

const getDaysInMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
};

const getDaysNextMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth()+2, 0).getDate();
};

const calculateExcessMonthDays = (date) => {
  let dayDiff = date.getDate() - getDaysNextMonth(date);
  if (dayDiff > 0) {
    return dayDiff;
  } else return 0;
};

const getQuarterDaysToAdd = (date) => {
  if (date) {
    const month = date.getMonth();
    if (month === 2 || month === 3) {
      return 1;
    } else if (month === 5 || month === 6 || month === 8) {
      return 2;
    } else {
      return 0;
    }
  }
};

const getBiMonthlyExcessDays = (date) => {
  if (date) {
    const month = date.getMonth();
    const day = date.getDate();

    if ((month === 0 || month === 2 || month === 4 || month === 6 || month === 7 || month === 9 || month === 11) && (day === 16 || day === 17)) return -1; // 31 day months
    else if (month === 0 && day === 31) return -2;
    else if (month === 1 && (day === 1 || day === 14 || day === 15)) return -2; // 28 day months
    else if ((month === 2 || month === 4 || month === 7 || month === 9) && day === 31) return -1;
    else if ((month === 3 || month === 5 || month === 8 || month === 10) && (day === 15 || day === 16 || day === 1)) return -1; // 30 day months
    else return 0;
  }
};

const addDays = (date, lengthDetails, daysFromStart, periodStartDate) => {
  if (date) {
    if (lengthDetails.id === 3) { 

      if (periodStartDate) {
        var tempDate = new Date(periodStartDate);
        const excessDays = getBiMonthlyExcessDays(tempDate);
        tempDate.setDate(tempDate.getDate() + daysFromStart + excessDays);
        return tempDate;
      } else {
        var tempDate = new Date(date);
        const excessDays = getBiMonthlyExcessDays(tempDate);
        tempDate.setDate(tempDate.getDate() + lengthDetails.numDays + excessDays);
        return tempDate;
      }
    } else if (lengthDetails.id === 4) {
      var tempDate = new Date(date);
      let excessDays = calculateExcessMonthDays(tempDate, daysFromStart);
      let daysToAdd = 0;

      if (daysFromStart > tempDate.getDate() && getDaysNextMonth(tempDate) >= daysFromStart) {
        daysToAdd = daysFromStart;
      } else {
        daysToAdd = getDaysInMonth(tempDate);
      }

      tempDate.setDate(tempDate.getDate() + daysToAdd - excessDays);
      return tempDate;
    } else if (lengthDetails.id === 5) {  
      var tempDate = new Date(date);
      const excessDays = getQuarterDaysToAdd(tempDate);

      tempDate.setDate(tempDate.getDate() + lengthDetails.numDays + excessDays);
      return tempDate;
    } else {
      var tempDate = new Date(date);
      tempDate.setDate(tempDate.getDate() + lengthDetails.numDays);
      return tempDate;
    }
  } else return null;
};

export const calcDateDiff = (dateOne, dateTwo) => {
  if (dateOne && dateTwo) {
    return Math.ceil((dateOne.getTime() - dateTwo.getTime()) / (1000 * 3600 * 24)) + 1;
  } else return null;
};

export const generateFinalPeriodList = (values) => {
  const periodLengthDetails = periodOptions.find((o) => o.id === values.calendarInfo.periodFrequencyId);

  const calendarYear = values.calendarInfo.calendarYear;
  let lastDay = new Date(`${calendarYear}-01-01 00:00:00`);
  if (periodLengthDetails.id === 4) {
    const daysInMonth = new Date(lastDay.getFullYear(), lastDay.getMonth()+1, 0).getDate();
    lastDay.setDate(lastDay.getDate() + daysInMonth - 1);
  } else {
    lastDay.setDate(lastDay.getDate() + periodLengthDetails.numDays - 1);
  }

  let initialPeriod = { ...values.periodInfo };
  let firstDay = new Date(`${calendarYear}-01-01 00:00:00`);
  initialPeriod.periodStartDate = firstDay;
  initialPeriod.periodEndDate = lastDay;

  let periodList = [];
  periodList.push(initialPeriod);

  initialPeriod.reconciledDate = initialPeriod.reconciledDate ? new Date(`${initialPeriod.reconciledDate} 00:00:00`) : null;
  initialPeriod.payrollReconciledThroughDate = initialPeriod.payrollReconciledThroughDate ? new Date(`${initialPeriod.payrollReconciledThroughDate} 00:00:00`) : null;
  initialPeriod.taxAssistanceUploadedThroughDate = initialPeriod.taxAssistanceUploadedThroughDate ? new Date(`${initialPeriod.taxAssistanceUploadedThroughDate} 00:00:00`) : null;
  initialPeriod.expenseCodesAuditedDate = initialPeriod.expenseCodesAuditedDate ? new Date(`${initialPeriod.expenseCodesAuditedDate} 00:00:00`) : null;
  initialPeriod.expenseCutoffDate = initialPeriod.expenseCutoffDate ? new Date(`${initialPeriod.expenseCutoffDate} 00:00:00`) : null;
  initialPeriod.actualExpenseCutoffDate = initialPeriod.actualExpenseCutoffDate ? new Date(`${initialPeriod.actualExpenseCutoffDate} 00:00:00`) : null;
  initialPeriod.wageRequestPrepDate = initialPeriod.wageRequestPrepDate ? new Date(`${initialPeriod.wageRequestPrepDate} 00:00:00`) : null;
  initialPeriod.actualWageRequestPrepDate = initialPeriod.actualWageRequestPrepDate ? new Date(`${initialPeriod.actualWageRequestPrepDate} 00:00:00`) : null;
  initialPeriod.wageRequestSentDate = initialPeriod.wageRequestSentDate ? new Date(`${initialPeriod.wageRequestSentDate} 00:00:00`) : null;
  initialPeriod.actualWageRequestSentDate = initialPeriod.actualWageRequestSentDate ? new Date(`${initialPeriod.actualWageRequestSentDate} 00:00:00`) : null;
  initialPeriod.wageRequestReceivedDate = initialPeriod.wageRequestReceivedDate ? new Date(`${initialPeriod.wageRequestReceivedDate} 00:00:00`) : null;
  initialPeriod.actualWageRequestReceivedDate = initialPeriod.actualWageRequestReceivedDate ? new Date(`${initialPeriod.actualWageRequestReceivedDate} 00:00:00`) : null;
  initialPeriod.wageRequestUploadDate = initialPeriod.wageRequestUploadDate ? new Date(`${initialPeriod.wageRequestUploadDate} 00:00:00`) : null;
  initialPeriod.actualWageRequestUploadDate = initialPeriod.actualWageRequestUploadDate ? new Date(`${initialPeriod.actualWageRequestUploadDate} 00:00:00`) : null;
  initialPeriod.payrollStartDate = initialPeriod.payrollStartDate ? new Date(`${initialPeriod.payrollStartDate} 00:00:00`) : null;
  initialPeriod.actualPayrollStartDate = initialPeriod.actualPayrollStartDate ? new Date(`${initialPeriod.actualPayrollStartDate} 00:00:00`) : null;
  initialPeriod.readyForAuditDate = initialPeriod.readyForAuditDate ? new Date(`${initialPeriod.readyForAuditDate} 00:00:00`) : null;
  initialPeriod.actualReadyForAuditDate = initialPeriod.actualReadyForAuditDate ? new Date(`${initialPeriod.actualReadyForAuditDate} 00:00:00`) : null;
  initialPeriod.payrollSentDate = initialPeriod.payrollSentDate ? new Date(`${initialPeriod.payrollSentDate} 00:00:00`) : null;
  initialPeriod.actualPayrollSentDate = initialPeriod.actualPayrollSentDate ? new Date(`${initialPeriod.actualPayrollSentDate} 00:00:00`) : null;

  let tracker = { ...initialPeriod };

  const daysFromStart = {
    periodStartDate: calcDateDiff(initialPeriod.periodStartDate, firstDay), // generated once calendar is saved
    periodEndDate: calcDateDiff(initialPeriod.periodEndDate, firstDay), // generated once calendar is saved
    reconciledDate: calcDateDiff(initialPeriod.reconciledDate, firstDay),
    payrollReconciledThroughDate: calcDateDiff(initialPeriod.payrollReconciledThroughDate, firstDay),
    taxAssistanceUploadedThroughDate: calcDateDiff(initialPeriod.taxAssistanceUploadedThroughDate, firstDay),
    expenseCodesAuditedDate: calcDateDiff(initialPeriod.expenseCodesAuditedDate, firstDay),
    expenseCutoffDate: calcDateDiff(initialPeriod.expenseCutoffDate, firstDay),
    actualExpenseCutoffDate: calcDateDiff(initialPeriod.actualExpenseCutoffDate, firstDay),
    wageRequestPrepDate: calcDateDiff(initialPeriod.wageRequestPrepDate, firstDay),
    actualWageRequestPrepDate: calcDateDiff(initialPeriod.actualWageRequestPrepDate, firstDay),
    wageRequestSentDate: calcDateDiff(initialPeriod.wageRequestSentDate, firstDay),
    actualWageRequestSentDate: calcDateDiff(initialPeriod.actualWageRequestSentDate, firstDay),
    wageRequestReceivedDate: calcDateDiff(initialPeriod.wageRequestReceivedDate, firstDay),
    actualWageRequestReceivedDate: calcDateDiff(initialPeriod.actualWageRequestReceivedDate, firstDay),
    wageRequestUploadDate: calcDateDiff(initialPeriod.wageRequestUploadDate, firstDay),
    actualWageRequestUploadDate: calcDateDiff(initialPeriod.actualWageRequestUploadDate, firstDay),
    payrollStartDate: calcDateDiff(initialPeriod.payrollStartDate, firstDay),
    actualPayrollStartDate: calcDateDiff(initialPeriod.actualPayrollStartDate, firstDay),
    readyForAuditDate: calcDateDiff(initialPeriod.readyForAuditDate, firstDay),
    actualReadyForAuditDate: calcDateDiff(initialPeriod.actualReadyForAuditDate, firstDay),
    payrollSentDate: calcDateDiff(initialPeriod.payrollSentDate, firstDay),
    actualPayrollSentDate: calcDateDiff(initialPeriod.actualPayrollSentDate, firstDay),
  };

  for (let i = 0; i < periodLengthDetails.number - 1; i++) {

    const periodStartDate = addDays(tracker.periodStartDate, periodLengthDetails, daysFromStart.periodStartDate, null);
    const periodEndDate = addDays(tracker.periodEndDate, periodLengthDetails, daysFromStart.periodEndDate, null);

    const newPeriod = {
      clientPayrollCalendarId: null, // generated once calendar is created
      periodStartDate, // generated once calendar is saved
      periodEndDate, // generated once calendar is saved
      numberOfEESent: tracker.numberOfEESent, // 0 by default for all periods
      numberOfEEReceived: tracker.numberOfEEReceived, // 0 by default for all periods
      reconciledDate: addDays(tracker.reconciledDate, periodLengthDetails, daysFromStart.reconciledDate, periodStartDate),
      payrollReconciledThroughDate: addDays(tracker.payrollReconciledThroughDate, periodLengthDetails, daysFromStart.payrollReconciledThroughDate, periodStartDate),
      taxAssistanceUploadedThroughDate: addDays(tracker.taxAssistanceUploadedThroughDate, periodLengthDetails, daysFromStart.taxAssistanceUploadedThroughDate, periodStartDate),
      expenseCodesAuditedDate: addDays(tracker.expenseCodesAuditedDate, periodLengthDetails, daysFromStart.expenseCodesAuditedDate, periodStartDate),
      expenseCutoffDate: addDays(tracker.expenseCutoffDate, periodLengthDetails, daysFromStart.expenseCutoffDate, periodStartDate),
      actualExpenseCutoffDate: addDays(tracker.actualExpenseCutoffDate, periodLengthDetails, daysFromStart.actualExpenseCutoffDate, periodStartDate),
      wageRequestPrepDate: addDays(tracker.wageRequestPrepDate, periodLengthDetails, daysFromStart.wageRequestPrepDate, periodStartDate),
      actualWageRequestPrepDate: addDays(tracker.actualWageRequestPrepDate, periodLengthDetails, daysFromStart.actualWageRequestPrepDate, periodStartDate),
      wageRequestSentDate: addDays(tracker.wageRequestSentDate, periodLengthDetails, daysFromStart.wageRequestSentDate, periodStartDate),
      actualWageRequestSentDate: addDays(tracker.actualWageRequestSentDate, periodLengthDetails, daysFromStart.actualWageRequestSentDate, periodStartDate),
      wageRequestReceivedDate: addDays(tracker.wageRequestReceivedDate, periodLengthDetails, daysFromStart.wageRequestReceivedDate, periodStartDate),
      actualWageRequestReceivedDate: addDays(tracker.actualWageRequestReceivedDate, periodLengthDetails, daysFromStart.actualWageRequestReceivedDate, periodStartDate),
      wageRequestUploadDate: addDays(tracker.wageRequestUploadDate, periodLengthDetails, daysFromStart.wageRequestUploadDate, periodStartDate),
      actualWageRequestUploadDate: addDays(tracker.actualWageRequestUploadDate, periodLengthDetails, daysFromStart.actualWageRequestUploadDate, periodStartDate),
      payrollStartDate: addDays(tracker.payrollStartDate, periodLengthDetails, daysFromStart.payrollStartDate, periodStartDate),
      actualPayrollStartDate: addDays(tracker.actualPayrollStartDate, periodLengthDetails, daysFromStart.actualPayrollStartDate, periodStartDate),
      readyForAuditDate: addDays(tracker.readyForAuditDate, periodLengthDetails, daysFromStart.readyForAuditDate, periodStartDate),
      actualReadyForAuditDate: addDays(tracker.actualReadyForAuditDate, periodLengthDetails, daysFromStart.actualReadyForAuditDate, periodStartDate),
      payrollSentDate: addDays(tracker.payrollSentDate, periodLengthDetails, daysFromStart.payrollSentDate, periodStartDate),
      actualPayrollSentDate: addDays(tracker.actualPayrollSentDate, periodLengthDetails, daysFromStart.actualPayrollSentDate, periodStartDate),
    };

    periodList.push(newPeriod);

    tracker = newPeriod;
  }

  return periodList;
};

export const cleanCalendarInfo = (calendarInfo) => {
  let c = { ...calendarInfo };
  c.auditDate = c.auditDate ? new Date(`${c.auditDate} 00:00:00`) : null;
  c.blackoutDate = c.blackoutDate ? new Date(`${c.blackoutDate} 00:00:00`) : null;
  c.dateEntered = c.dateEntered ? new Date(`${c.dateEntered} 00:00:00`) : null;
  c.followingYearCutoffDate = c.followingYearCutoffDate ? new Date(`${c.followingYearCutoffDate} 00:00:00`) : null;
  c.lastContactDate = c.lastContactDate ? new Date(`${c.lastContactDate} 00:00:00`) : null;
  c.postDate = c.postDate ? new Date(`${c.postDate} 00:00:00`) : null;
  c.surveyCompleted = c.surveyCompleted ? new Date(`${c.surveyCompleted} 00:00:00`) : null;
  c.surveyFollowUpSent = c.surveyFollowUpSent ? new Date(`${c.surveyFollowUpSent} 00:00:00`) : null;
  c.surveyReady = c.surveyReady ? new Date(`${c.surveyReady} 00:00:00`) : null;
  c.surveyReceived = c.surveyReceived ? new Date(`${c.surveyReceived} 00:00:00`) : null;
  c.surveySaved = c.surveySaved ? new Date(`${c.surveySaved} 00:00:00`) : null;
  c.surveySent = c.surveySent ? new Date(`${c.surveySent} 00:00:00`) : null;
  c.yearEndCutoffDate = c.yearEndCutoffDate ? new Date(`${c.yearEndCutoffDate} 00:00:00`) : null;
  return c;
};