/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Chip, FormControlLabel, MenuItem, Select, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    firstCol: {
      width: 200,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleMortgage = (props) => {

  const clientHomesaleMortgage = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleMortgage : null;

  const [isEnabled, setIsEnabled] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.isEnabled : false);
  const [homesaleMortgageEligibility, setHomesaleMortgageEligibility] = useState(clientHomesaleMortgage && clientHomesaleMortgage.homesaleMortgageEligibility ? clientHomesaleMortgage.homesaleMortgageEligibility.split(',') : []);
  const [whenEligible, setWhenEligible] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.whenEligible : null);
  const [howCalculated, setHowCalculated] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.howCalculated : null);
  const [whenPaid, setWhenPaid] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.whenPaid : null); 
  const [whoPays, setWhoPays] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.whoPays : null);
  const [documentationRequired, setDocumentationRequired] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.documentationRequired : null);
  const [ifMovesAgain, setIfMovesAgain] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.ifMovesAgain : null);
  const [otherNotes, setOtherNotes] = useState(clientHomesaleMortgage ? clientHomesaleMortgage.otherNotes : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      IsEnabled: isEnabled,
      HomesaleMortgageEligibility: homesaleMortgageEligibility.join(','),
      WhenEligible: whenEligible,
      HowCalculated: howCalculated,
      WhenPaid: whenPaid,
      WhoPays: whoPays,
      DocumentationRequired: documentationRequired,
      IfMovesAgain: ifMovesAgain,
      OtherNotes: otherNotes,
    };

    if (clientHomesaleMortgage && clientHomesaleMortgage.id) updateBody.Id = clientHomesaleMortgage.id;

    props.updateInfo(updateBody);
  }, [isEnabled, homesaleMortgageEligibility, whenEligible, howCalculated, whenPaid, whoPays, documentationRequired, ifMovesAgain, otherNotes]);

  return (
    <div className={props.classes.contractsGrid}>
      <div className={props.classes.headerRow}>
        <h3>Mortgage Interest Differential / Mortgage Subsidy Program</h3>
        <FormControlLabel
          control={
            <Checkbox
              name="Mortgage"
              label="Activate Service"
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
          }
          label="Activate Service"
          classes={{ root: props.classes.checkbox }}
        />
      </div>
      {isEnabled && (
        <div className={props.classes.tabBorder}>
          <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
            <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
              <p className={props.classes.descText}>Who is eligible for MIDA / Mortgage Subsidy Program?</p>
              <Select
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Who is Eligible?'}
                onChange={(e) => setHomesaleMortgageEligibility(e.target.value)}
                multiple
                value={homesaleMortgageEligibility}
                renderValue={(selected) => {
                  return (
                    <div className={props.classes.chips}>
                      {selected.map((value) => {
                        const policyInfo = props.clientPolicyNotes.find((o) => o.clientPolicyNotesKey === parseInt(value));
                        return (
                          <Chip
                            key={policyInfo.clientPolicyNotesKey}
                            label={policyInfo.name}
                            className={props.classes.chip}
                            onDelete={() => {
                              setHomesaleMortgageEligibility(homesaleMortgageEligibility.filter((b) => b !== value));
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        );
                      })}
                    </div>
                  );}}
              >
                {props.clientPolicyNotes && props.clientPolicyNotes.map((c) => {
                  return <MenuItem key={c.clientPolicyNotesKey} value={c.clientPolicyNotesKey}>{c.name}</MenuItem>;
                })}
              </Select>
              <p className={props.classes.descText}>When is an employee eligible for MIDA or Mortgage Subsidy?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                label={'When eligible?'}
                style={{ width: '100%', marginRight: 4 }}
                onChange={(e) => setWhenEligible(e.target.value)}
                required
                value={whenEligible}
              />
              <p className={props.classes.descText}>How is it calculated?</p>
              <TextField
                id="name"
                label="How calculated?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={howCalculated}
                onChange={(e) => setHowCalculated(e.target.value)}
              />
              <p className={props.classes.descText}>When is this paid?</p>
              <TextField
                id="name"
                label="When paid?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenPaid}
                onChange={(e) => setWhenPaid(e.target.value)}
              />
            </div>

            <div className={props.classes.contentCol}>
              <p className={props.classes.descText}>Who pays this benefit?</p>
              <TextField
                id="name"
                label="Who pays?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whoPays}
                onChange={(e) => setWhoPays(e.target.value)}
              />
              <p className={props.classes.descText}>What is documentation required?</p>
              <TextField
                id="name"
                label="Documentation required?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={documentationRequired}
                onChange={(e) => setDocumentationRequired(e.target.value)}
              />
              <p className={props.classes.descText}>If the employee moves again before the payments end, how is this handled?</p>
              <TextField
                id="name"
                label="If employee moves again?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={ifMovesAgain}
                onChange={(e) => setIfMovesAgain(e.target.value)}
              />
              <TextField
                variant="outlined"
                label={'Other Notes'}
                value={otherNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
              {/* <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {updateGeneralInfo();}}
                  >
                      Save Info
                  </Button> */}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}; 

HomeSaleMortgage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleMortgage);