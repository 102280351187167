import {
  DELETE_AUTHORIZATIONS_FAILURE,
  DELETE_AUTHORIZATIONS_REQUEST,
  DELETE_AUTHORIZATIONS_SUCCESS,
  DELETE_AUTH_LIST_CLIENT_POLICIES_FAILURE,
  DELETE_AUTH_LIST_CLIENT_POLICIES_REQUEST,
  DELETE_AUTH_LIST_CLIENT_POLICIES_SUCCESS,
  DELETE_AUTH_SERVICES_FAILURE,
  DELETE_AUTH_SERVICES_REQUEST,
  DELETE_AUTH_SERVICES_SUCCESS,
  DELETE_FAMILY_FAILURE,
  DELETE_FAMILY_PET_FAILURE,
  DELETE_FAMILY_PET_REQUEST,
  DELETE_FAMILY_PET_SUCCESS,
  DELETE_FAMILY_REQUEST,
  DELETE_FAMILY_SUCCESS,
  GET_ACTIVITY_LOG_FAILURE,
  GET_ACTIVITY_LOG_REQUEST,
  GET_ACTIVITY_LOG_SUCCESS,
  GET_ALL_AUTH_SERVICES_FAILURE,
  GET_ALL_AUTH_SERVICES_REQUEST,
  GET_ALL_AUTH_SERVICES_SUCCESS,
  GET_AUTHORIZATIONS_FAILURE,
  GET_AUTHORIZATIONS_REQUEST,
  GET_AUTHORIZATIONS_SUCCESS,
  GET_AUTH_CLIENT_POLICIES_FAILURE,
  GET_AUTH_CLIENT_POLICIES_REQUEST,
  GET_AUTH_CLIENT_POLICIES_SUCCESS,
  GET_AUTH_DATE_SUMMARY_FAILURE,
  GET_AUTH_DATE_SUMMARY_REQUEST,
  GET_AUTH_DATE_SUMMARY_SUCCESS,
  GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_FAILURE,
  GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_REQUEST,
  GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_SUCCESS,
  GET_AUTH_LIST_CLIENT_POLICIES_FAILURE,
  GET_AUTH_LIST_CLIENT_POLICIES_REQUEST,
  GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS,
  GET_AUTH_SERVICES_FAILURE,
  GET_AUTH_SERVICES_REQUEST,
  GET_AUTH_SERVICES_SUCCESS,
  GET_AUTH_SUMMARY_FAILURE,
  GET_AUTH_SUMMARY_REQUEST,
  GET_AUTH_SUMMARY_SUCCESS,
  GET_AUTH_TEAM_FAILURE,
  GET_AUTH_TEAM_REQUEST,
  GET_AUTH_TEAM_SUCCESS,
  GET_COMMUNICATION_INFO_FAILURE,
  GET_COMMUNICATION_INFO_REQUEST,
  GET_COMMUNICATION_INFO_SUCCESS,
  GET_DEPARTURE_ADDRESS_FAILURE,
  GET_DEPARTURE_ADDRESS_REQUEST,
  GET_DEPARTURE_ADDRESS_SUCCESS,
  GET_DESTINATION_ADDRESS_FAILURE,
  GET_DESTINATION_ADDRESS_REQUEST,
  GET_DESTINATION_ADDRESS_SUCCESS,
  GET_EMPLOYEE_INFO_FAILURE,
  GET_EMPLOYEE_INFO_REQUEST,
  GET_EMPLOYEE_INFO_SUCCESS,
  GET_FAMILY_FAILURE,
  GET_FAMILY_PET_FAILURE,
  GET_FAMILY_PET_REQUEST,
  GET_FAMILY_PET_SUCCESS,
  GET_FAMILY_REQUEST,
  GET_FAMILY_SUCCESS,
  GET_SO_INFO_FAILURE,
  GET_SO_INFO_REQUEST,
  GET_SO_INFO_SUCCESS,
  GET_USER_SETTINGS_FAILURE,
  GET_USER_SETTINGS_REQUEST,
  GET_USER_SETTINGS_SUCCESS,
  POST_AUTHORIZATIONS_FAILURE,
  POST_AUTHORIZATIONS_REQUEST,
  POST_AUTHORIZATIONS_SUCCESS,
  POST_AUTH_CLIENT_POLICIES_FAILURE,
  POST_AUTH_CLIENT_POLICIES_REQUEST,
  POST_AUTH_CLIENT_POLICIES_SUCCESS,
  POST_AUTH_DATE_SUMMARY_FAILURE,
  POST_AUTH_DATE_SUMMARY_REQUEST,
  POST_AUTH_DATE_SUMMARY_SUCCESS,
  POST_AUTH_LIST_CLIENT_POLICIES_FAILURE,
  POST_AUTH_LIST_CLIENT_POLICIES_REQUEST,
  POST_AUTH_LIST_CLIENT_POLICIES_SUCCESS,
  POST_AUTH_SERVICES_FAILURE,
  POST_AUTH_SERVICES_REQUEST,
  POST_AUTH_SERVICES_SUCCESS,
  POST_AUTH_TEAM_FAILURE,
  POST_AUTH_TEAM_REQUEST,
  POST_AUTH_TEAM_SUCCESS,
  POST_COMMUNICATION_INFO_FAILURE,
  POST_COMMUNICATION_INFO_REQUEST,
  POST_COMMUNICATION_INFO_SUCCESS,
  POST_DEPARTURE_ADDRESS_FAILURE,
  POST_DEPARTURE_ADDRESS_REQUEST,
  POST_DEPARTURE_ADDRESS_SUCCESS,
  POST_DESTINATION_ADDRESS_FAILURE,
  POST_DESTINATION_ADDRESS_REQUEST,
  POST_DESTINATION_ADDRESS_SUCCESS,
  POST_EMPLOYEE_INFO_FAILURE,
  POST_EMPLOYEE_INFO_REQUEST,
  POST_EMPLOYEE_INFO_SUCCESS,
  POST_FAMILY_FAILURE,
  POST_FAMILY_PET_FAILURE,
  POST_FAMILY_PET_REQUEST,
  POST_FAMILY_PET_SUCCESS,
  POST_FAMILY_REQUEST,
  POST_FAMILY_SUCCESS,
  POST_SO_INFO_FAILURE,
  POST_SO_INFO_REQUEST,
  POST_SO_INFO_SUCCESS,
  POST_USER_SETTINGS_FAILURE,
  POST_USER_SETTINGS_REQUEST,
  POST_USER_SETTINGS_SUCCESS,
  SET_AUTH_ID,
  SET_LOADING_COMPLETE,
  UPDATE_AUTHORIZATIONS_FAILURE,
  UPDATE_AUTHORIZATIONS_REQUEST,
  UPDATE_AUTHORIZATIONS_SUCCESS,
  UPDATE_AUTH_CLIENT_POLICIES_FAILURE,
  UPDATE_AUTH_CLIENT_POLICIES_REQUEST,
  UPDATE_AUTH_CLIENT_POLICIES_SUCCESS,
  UPDATE_AUTH_DATE_SUMMARY_FAILURE,
  UPDATE_AUTH_DATE_SUMMARY_REQUEST,
  UPDATE_AUTH_DATE_SUMMARY_SUCCESS,
  UPDATE_AUTH_LIST_CLIENT_POLICIES_FAILURE,
  UPDATE_AUTH_LIST_CLIENT_POLICIES_REQUEST,
  UPDATE_AUTH_LIST_CLIENT_POLICIES_SUCCESS,
  UPDATE_AUTH_SERVICES_FAILURE,
  UPDATE_AUTH_SERVICES_REQUEST,
  UPDATE_AUTH_SERVICES_SUCCESS,
  UPDATE_AUTH_TEAM_FAILURE,
  UPDATE_AUTH_TEAM_REQUEST,
  UPDATE_AUTH_TEAM_SUCCESS,
  UPDATE_COMMUNICATION_INFO_FAILURE,
  UPDATE_COMMUNICATION_INFO_REQUEST,
  UPDATE_COMMUNICATION_INFO_SUCCESS,
  UPDATE_DEPARTURE_ADDRESS_FAILURE,
  UPDATE_DEPARTURE_ADDRESS_REQUEST,
  UPDATE_DEPARTURE_ADDRESS_SUCCESS,
  UPDATE_DESTINATION_ADDRESS_FAILURE,
  UPDATE_DESTINATION_ADDRESS_REQUEST,
  UPDATE_DESTINATION_ADDRESS_SUCCESS,
  UPDATE_EMPLOYEE_INFO_FAILURE,
  UPDATE_EMPLOYEE_INFO_REQUEST,
  UPDATE_EMPLOYEE_INFO_SUCCESS,
  UPDATE_FAMILY_DATA,
  UPDATE_FAMILY_FAILURE,
  UPDATE_FAMILY_PET_DATA,
  UPDATE_FAMILY_PET_FAILURE,
  UPDATE_FAMILY_PET_REQUEST,
  UPDATE_FAMILY_PET_SUCCESS,
  UPDATE_FAMILY_REQUEST,
  UPDATE_FAMILY_SUCCESS,
  UPDATE_SO_INFO_FAILURE,
  UPDATE_SO_INFO_REQUEST,
  UPDATE_SO_INFO_SUCCESS,
  UPDATE_USER_SETTINGS_FAILURE,
  UPDATE_USER_SETTINGS_REQUEST,
  UPDATE_USER_SETTINGS_SUCCESS,
} from '../types/authorizationTypes';

import { CALL_INTAKE_V2_API } from 'middleware/intakeV2Api';

export const getAuthorizationSummaries = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTHORIZATIONS_REQUEST, GET_AUTHORIZATIONS_SUCCESS, GET_AUTHORIZATIONS_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations',
      method: 'GET',
    },
  };
};

export const getAuthorizationSummary = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_SUMMARY_REQUEST, GET_AUTH_SUMMARY_SUCCESS, GET_AUTH_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}`,
      method: 'GET',
    },
  };
};

export const postAuthorizationSummary = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTHORIZATIONS_REQUEST, POST_AUTHORIZATIONS_SUCCESS, POST_AUTHORIZATIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAuthorizationSummary = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTHORIZATIONS_REQUEST, UPDATE_AUTHORIZATIONS_SUCCESS, UPDATE_AUTHORIZATIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteAuthorizationSummary = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_AUTHORIZATIONS_REQUEST, DELETE_AUTHORIZATIONS_SUCCESS, DELETE_AUTHORIZATIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}`,
      method: 'DELETE',
    },
  };
};

export const getEmployeeInfo = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EMPLOYEE_INFO_REQUEST, GET_EMPLOYEE_INFO_SUCCESS, GET_EMPLOYEE_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/employee`,
      method: 'GET',
    },
  };
};

export const postEmployeeInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_EMPLOYEE_INFO_REQUEST, POST_EMPLOYEE_INFO_SUCCESS, POST_EMPLOYEE_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/employee/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateEmployeeInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_EMPLOYEE_INFO_REQUEST, UPDATE_EMPLOYEE_INFO_SUCCESS, UPDATE_EMPLOYEE_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/employee/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getSOInfo = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SO_INFO_REQUEST, GET_SO_INFO_SUCCESS, GET_SO_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/significantOther`,
      method: 'GET',
    },
  };
};

export const postSOInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_SO_INFO_REQUEST, POST_SO_INFO_SUCCESS, POST_SO_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/significantOther/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateSOInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_SO_INFO_REQUEST, UPDATE_SO_INFO_SUCCESS, UPDATE_SO_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/significantOther/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getCommunicationInfo = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_COMMUNICATION_INFO_REQUEST, GET_COMMUNICATION_INFO_SUCCESS, GET_COMMUNICATION_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/communication`,
      method: 'GET',
    },
  };
};

export const postCommunicationInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_COMMUNICATION_INFO_REQUEST, POST_COMMUNICATION_INFO_SUCCESS, POST_COMMUNICATION_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/communication/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateCommunicationInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_COMMUNICATION_INFO_REQUEST, UPDATE_COMMUNICATION_INFO_SUCCESS, UPDATE_COMMUNICATION_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/communication/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const setLoadingComplete = () => ({
  type: SET_LOADING_COMPLETE,
});

export const setAuthId = (id) => ({
  type: SET_AUTH_ID,
  response: id,
});

export const getAuthDateSummary = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_DATE_SUMMARY_REQUEST, GET_AUTH_DATE_SUMMARY_SUCCESS, GET_AUTH_DATE_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/summary`,
      method: 'GET',
    },
  };
};

export const postAuthDateSummary = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_DATE_SUMMARY_REQUEST, POST_AUTH_DATE_SUMMARY_SUCCESS, POST_AUTH_DATE_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/summary/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAuthDateSummary = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_DATE_SUMMARY_REQUEST, UPDATE_AUTH_DATE_SUMMARY_SUCCESS, UPDATE_AUTH_DATE_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/summary/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getDepartureAddress = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_DEPARTURE_ADDRESS_REQUEST, GET_DEPARTURE_ADDRESS_SUCCESS, GET_DEPARTURE_ADDRESS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/departure`,
      method: 'GET',
    },
  };
};

export const postDepartureAddress = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_DEPARTURE_ADDRESS_REQUEST, POST_DEPARTURE_ADDRESS_SUCCESS, POST_DEPARTURE_ADDRESS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/departure/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateDepartureAddress = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_DEPARTURE_ADDRESS_REQUEST, UPDATE_DEPARTURE_ADDRESS_SUCCESS, UPDATE_DEPARTURE_ADDRESS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/departure/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getDestinationAddress = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_DESTINATION_ADDRESS_REQUEST, GET_DESTINATION_ADDRESS_SUCCESS, GET_DESTINATION_ADDRESS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/destination`,
      method: 'GET',
    },
  };
};

export const postDestinationAddress = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_DESTINATION_ADDRESS_REQUEST, POST_DESTINATION_ADDRESS_SUCCESS, POST_DESTINATION_ADDRESS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/destination/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateDestinationAddress = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_DESTINATION_ADDRESS_REQUEST, UPDATE_DESTINATION_ADDRESS_SUCCESS, UPDATE_DESTINATION_ADDRESS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/destination/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getAuthClientPolicies = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_CLIENT_POLICIES_REQUEST, GET_AUTH_CLIENT_POLICIES_SUCCESS, GET_AUTH_CLIENT_POLICIES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/clientPolicy`,
      method: 'GET',
    },
  };
};

export const postAuthClientPolicies = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_CLIENT_POLICIES_REQUEST, POST_AUTH_CLIENT_POLICIES_SUCCESS, POST_AUTH_CLIENT_POLICIES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/clientPolicy/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAuthClientPolicies = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_CLIENT_POLICIES_REQUEST, UPDATE_AUTH_CLIENT_POLICIES_SUCCESS, UPDATE_AUTH_CLIENT_POLICIES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/clientPolicy/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

/* Team calls */
export const getTeam = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_TEAM_REQUEST, GET_AUTH_TEAM_SUCCESS, GET_AUTH_TEAM_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/team`,
      method: 'GET',
    },
  };
};

export const postTeam = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_TEAM_REQUEST, POST_AUTH_TEAM_SUCCESS, POST_AUTH_TEAM_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/team/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateTeam = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_TEAM_REQUEST, UPDATE_AUTH_TEAM_SUCCESS, UPDATE_AUTH_TEAM_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/team/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

/* Services calls */

export const getAuthServices = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ALL_AUTH_SERVICES_REQUEST, GET_ALL_AUTH_SERVICES_SUCCESS, GET_ALL_AUTH_SERVICES_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/authService',
      method: 'GET',
    },
  };
};

export const getServices = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_SERVICES_REQUEST, GET_AUTH_SERVICES_SUCCESS, GET_AUTH_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/authService`,
      method: 'GET',
    },
  };
};

export const postServices = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_SERVICES_REQUEST, POST_AUTH_SERVICES_SUCCESS, POST_AUTH_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/authService/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateServices = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_SERVICES_REQUEST, UPDATE_AUTH_SERVICES_SUCCESS, UPDATE_AUTH_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/authService/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteServices = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_AUTH_SERVICES_REQUEST, DELETE_AUTH_SERVICES_SUCCESS, DELETE_AUTH_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/authService`,
      method: 'DELETE',
    },
  };
};

export const getAuthClientPoliciesListByClient = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [
        GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_REQUEST,
        GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_SUCCESS,
        GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_FAILURE,
      ],
      authenticated: true,
      endpoint: `api/ClientPolicys/GetByClientId/${id}`,
      method: 'GET',
    },
  };
};

export const getAuthClientPoliciesList = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_LIST_CLIENT_POLICIES_REQUEST, GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS, GET_AUTH_LIST_CLIENT_POLICIES_FAILURE],
      authenticated: true,
      endpoint: 'api/ClientPolicys',
      method: 'GET',
    },
  };
};

export const postAuthClientPoliciesList = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_LIST_CLIENT_POLICIES_REQUEST, POST_AUTH_LIST_CLIENT_POLICIES_SUCCESS, POST_AUTH_LIST_CLIENT_POLICIES_FAILURE],
      authenticated: true,
      endpoint: `api/ClientPolicys/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAuthClientPoliciesList = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_LIST_CLIENT_POLICIES_REQUEST, UPDATE_AUTH_LIST_CLIENT_POLICIES_SUCCESS, UPDATE_AUTH_LIST_CLIENT_POLICIES_FAILURE],
      authenticated: true,
      endpoint: `api/ClientPolicys/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteAuthClientPoliciesList = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_AUTH_LIST_CLIENT_POLICIES_REQUEST, DELETE_AUTH_LIST_CLIENT_POLICIES_SUCCESS, DELETE_AUTH_LIST_CLIENT_POLICIES_FAILURE],
      authenticated: true,
      endpoint: `api/ClientsPolicys/${id}`,
      method: 'DELETE',
    },
  };
};

export const getAuthActivityLog = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ACTIVITY_LOG_REQUEST, GET_ACTIVITY_LOG_SUCCESS, GET_ACTIVITY_LOG_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/logs`,
      method: 'GET',
    },
  };
};

export const getUserSettings = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_USER_SETTINGS_REQUEST, GET_USER_SETTINGS_SUCCESS, GET_USER_SETTINGS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/userSetting`,
      method: 'GET',
    },
  };
};

export const postUserSettings = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_USER_SETTINGS_REQUEST, POST_USER_SETTINGS_SUCCESS, POST_USER_SETTINGS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/userSetting/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateUserSettings = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_USER_SETTINGS_REQUEST, UPDATE_USER_SETTINGS_SUCCESS, UPDATE_USER_SETTINGS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/userSetting/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getFamilyMembers = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_FAMILY_REQUEST, GET_FAMILY_SUCCESS, GET_FAMILY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/family`,
      method: 'GET',
    },
  };
};

export const postFamilyMember = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_FAMILY_REQUEST, POST_FAMILY_SUCCESS, POST_FAMILY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/family/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateFamilyMember = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_FAMILY_REQUEST, UPDATE_FAMILY_SUCCESS, UPDATE_FAMILY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/family/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteFamilyMember = (authId, id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_FAMILY_REQUEST, DELETE_FAMILY_SUCCESS, DELETE_FAMILY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/family/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateFamilyMembersState = (family) => {
  return {
    type: UPDATE_FAMILY_DATA,
    response: family,
  };
};

export const getFamilyPets = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_FAMILY_PET_REQUEST, GET_FAMILY_PET_SUCCESS, GET_FAMILY_PET_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/familyPet`,
      method: 'GET',
    },
  };
};

export const postFamilyPet = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_FAMILY_PET_REQUEST, POST_FAMILY_PET_SUCCESS, POST_FAMILY_PET_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/familyPet/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateFamilyPet = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_FAMILY_PET_REQUEST, UPDATE_FAMILY_PET_SUCCESS, UPDATE_FAMILY_PET_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/familyPet/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteFamilyPet = (authId, id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_FAMILY_PET_REQUEST, DELETE_FAMILY_PET_SUCCESS, DELETE_FAMILY_PET_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/familyPet/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateFamilyPetsState = (familyPets) => {
  return {
    type: UPDATE_FAMILY_PET_DATA,
    response: familyPets,
  };
};
