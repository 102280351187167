import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

/*
  Instructions: Place this component on any page where you'd like the mega menu displayed (i.e. each portion of the URL separated by /).
  
  Props:
    - menuTitle (optional): an object array that holds the title of the menu, an optional icon to be displayed next to the menu title, and any styling specifics for the title display.
      PLEASE ONLY ADD 1 TITLE OBJECT IN THIS ARRAY!!!

    - menuLinks: an object array of the menuitems to be displayed
        * Options to include:
            link = path
            name = displayed 
            icon = icon displayed before name
            secondary text = any secondary text if an explanation of the name is needed
            divider = a divider displayed AFTER the menu item
     ex.
        const menuLinks = [
      {
        'link': '/createNote',
        'name': 'Add Note',
      },
      {
        'link': '/notes',
        'name': 'View Notes',
        'divider': 'true',
      },
      {
        'link': '/profile',
        'name': 'Profile',
        'icon': ['fas', 'user-friends'],
      }
      {
        'link': '/secondary',
        'name': 'Secondary',
        'secondaryText': 'Secondary Text',
      }
    ];
*/


const MegaMenu = ({ menuTitle, menuLinks }) => {
  const [menuOpen, setMenuOpen] = useState(null);
  const open = Boolean(menuOpen);

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleClick = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const renderMenuItem = (menuItem) => {
    return (
      <Grid item xs={12} md={menuLinks.length >= 8 ? 6 : 12}>
        <MenuItem>
          { menuItem.icon &&
            <>
              <ListItemIcon>
                <FontAwesomeIcon size="1x" icon={menuItem.icon} color="gray" style={{ marginTop: '6px', marginRight: '13px' }} />
              </ListItemIcon>
            </>
          }
          <ListItemText>{menuItem.name}</ListItemText>
          { menuItem.secondaryText &&
            <Typography variant="body2" color="text.secondary">
              {menuItem.secondaryText}
            </Typography>
          }
        </MenuItem>
        {menuItem.divider &&
          <>
            <Divider />
          </>
        }
      </Grid>
    );
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Typography
          onClick={handleClick}
          sx={{
            marginRight: '7px',
            color: menuTitle[0].color,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'row',
            fontSize: menuTitle[0].fontSize,
          }}
          noWrap
          variant={menuTitle[0].variant}
        >
          {menuTitle[0].name}
        </Typography>
        {menuTitle[0].icon &&
          <>
            <FontAwesomeIcon size="1x" icon={menuTitle[0].icon} color={menuTitle[0].color} style={{ marginTop: '6px', marginRight: '13px' }} />
          </>}
      </div>
      <Paper >
        <Menu
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={menuOpen}
          sx={{ maxWidth: '100%' }}
        >
          <Grid
            container
            sx={{
              maxWidth: menuLinks.length >= 8 ? '400px' : '250px',
              width: '100%', 
            }}
          >
            {menuLinks.map(renderMenuItem)}
          </Grid>
        </Menu>
      </Paper>
    </div>
  );
};

export default MegaMenu; 

