import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import React, { useState } from 'react';

import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Spinner from 'modules/common/spinner.component';
import { deleteVendorContact, getVendorContacts, postVendorContact, updateVendorContact } from 'modules/authorizations/store/actions/dataManagement.actions';
import { DELETE_VENDOR_CONTACT_FAILURE, GET_VENDOR_CONTACT_FAILURE, POST_VENDOR_CONTACT_FAILURE, UPDATE_VENDOR_CONTACT_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import CrudTable from 'modules/clients/details/crudTable.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const ContactsCard = (props) => {

  const { hideEdit, vendorId, vendorContacts } = props;

  const [isLoading, setIsLoading] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'firstName', header: 'First Name', type: 'text', required: true, size: 65 },
    { accessorKey: 'lastName', header: 'Last Name', type: 'text', required: true, size: 65 },
    // { accessorKey: 'lastName', header: 'Last Name', type: 'text', required: false, size: 65, Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-' },
    { accessorKey: 'description', header: 'Description', type: 'text', required: false, size: 65 },
    { accessorKey: 'jobTitle', header: 'Job Title', type: 'text', required: false, size: 65 },
  ];

  const updateRow = async (record, values) => {
    setIsLoading(true);
    values.vendorContactId = record.original.vendorContactId;
    values.vendorId = vendorId;

    // if (values.estimatedStart && values.estimatedStart.length === 0) values.estimatedStart = null;

    const resp = await props.updateVendorContact(values);
    if (resp.type === UPDATE_VENDOR_CONTACT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const createRow = async (values) => {
    setIsLoading(true);
    values.vendorId = vendorId;

    // if (values.estimatedStart && values.estimatedStart.length === 0) values.estimatedStart = null;

    const resp = await props.postVendorContact(values);
    if (resp.type === POST_VENDOR_CONTACT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const deleteRow = async (record) => {
    setIsLoading(true);
    const id = record.original.vendorContactId;
    const resp = await props.deleteVendorContact(id);
    if (resp.type === DELETE_VENDOR_CONTACT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={'Moving Dates'} key={'vendorContacts'} placement="right">
            <FontAwesomeIcon icon={['fas', 'user-friends']} size="2x" />
          </Tooltip>
          <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
            Contacts
          </Typography>
        </Box>
        {!isLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={vendorContacts ? vendorContacts.filter((vc) => vc.vendorId === vendorId) : []} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
            customAddText={'Add Contact'}
            hideEdit={hideEdit}
          />
        )}
        {isLoading && (
          <Spinner />
        )}
      </CardContent>
    </Card>
  );
}; 

export default compose(
  withStyles(styles),
  withTheme,
  connect(null, {
    getVendorContacts,
    deleteVendorContact,
    postVendorContact,
    updateVendorContact,
  }),
)(ContactsCard);