import React from 'react';
import { parse } from 'json2csv';
import {  Link } from '@mui/material';

const DownloadCsvLink = ({ data, fields }) => {
  const handleDownload = () => {
    try {
      const csv = parse(data, { fields });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error while converting data to CSV:', err);
    }
  };

  return (
    <Link onClick={handleDownload} style={{ color: '#41B7C0', cursor: 'pointer', paddingTop: '25px' }}>
      Export to CSV
    </Link>
  );
};

export default DownloadCsvLink;
