export const OfferSentByOptions = ['Email', 'Other', 'Overnight', 'Reg Mail'];
export const OfferSentToOptions = ['Email', 'Home', 'Office', 'Other'];
export const SaleTypeOptions = [
  '(ADV) Equity Advance',
  '(AMD) Amended Value',
  '(AMD Zero) Amend Zero',
  '(BVO) Buyer Value Option',
  '(CSO) Closing Svc Only',
  '(DIR) Directed Offer',
  '(GBO) Guaranteed',
  '(HMA) Home Marketing Assistance',
  '(REVAMD) Rev Amend',
];

export const TitleRepSearchInfo = (titleReps) => {
  return {
    searchingColumns: [
      { name: 'titleRepId', title: 'Title Rep Id' },
      { name: 'companyName', title: 'Company Name' },
      { name: 'city', title: 'City' },
      { name: 'state', title: 'State' },
      { name: 'phone', title: 'Company Phone' },
      { name: 'email', title: 'Company Email' },
    ],
    searchingColumnExtensions: [
      { columnName: 'titleRepId', width: 150 },
      { columnName: 'companyName', width: 250 },
      { name: 'city', width: 175 },
      { name: 'state', width: 175 },
      { columnName: 'phone', width: 175 },
      { columnName: 'email', width: 175 },
    ],
    searchingRows: titleReps,
    idKey: 'titleRepId',
    nameKey: 'companyName',
  };
};