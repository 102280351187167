import { boolean, object, string } from 'yup';

import { formatForWire, isNotDefined } from 'utilities/common';

import { convertInPendingView, grabCountryCode } from '../details/authorizationIntake/utils';

import { REQUIRED_STRING } from 'modules/common/constants';

export default class Phone {
  static schema = object().shape({
    phoneNumber: string(),
    phoneTypeId: REQUIRED_STRING,
    contactInfoOwnerTypeId: REQUIRED_STRING,
    isPrimary: boolean(),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.id = json.id;
    this.countryCode = json.phoneNumber ? grabCountryCode(json.phoneNumber) : 'USA';
    this.phoneNumber = json.phoneNumber ? convertInPendingView(json.phoneNumber) : '';
    this.phoneTypeId = isNotDefined(json.phoneTypeId) ? '': json.phoneTypeId;
    this.contactInfoOwnerTypeId = isNotDefined(json.contactInfoOwnerTypeId) ? '': json.contactInfoOwnerTypeId;
    this.isPrimary = !!json.isPrimary;
  }

  static fromWireData(wireData) {
    return new Phone(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      country: this.country,
      phoneNumber: this.phoneNumber,
      phoneTypeId: this.phoneTypeId,
      contactInfoOwnerTypeId: this.contactInfoOwnerTypeId,
      isPrimary: this.isPrimary,
    });
  }
}
