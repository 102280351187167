import { Checkbox } from '@mui/material';
import { POST_HOMESALE_INFO_FAILURE, UPDATE_HOMESALE_INFO_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postHomesaleInfo, updateHomesaleInfo } from 'modules/authorizations/store/actions/homesale.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const ProgramInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { homesaleInfo, id, isReadOnly } = props;

  const typeOptions = [
    { title: 'HMA', id: 1 },
    { title: 'BVO', id: 2 },
    { title: 'GBO', id: 3 },
  ];

  const tabs = [
    { header: 'Homesale Program Info', order: 1, iconPath: ['fas', 'file-invoice'] },
  ];

  const dateDiff = (endDate, startDate) => {
    const diffInMs = endDate - startDate;
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
  };

  const summaries = [
    { header: 'Sale Type', accessorKey: 'saleType', type: 'dropdown-v2', required: false, value: homesaleInfo ? homesaleInfo.saleType : null, options: typeOptions,  tab: 1 },
    { header: 'Initiation Date', accessorKey: 'initiationDate', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.initiationDate : '', tab: 1 },
    { header: 'Final Rec Complete', accessorKey: 'finalRecComplete', type: 'checkbox', required: false, value: homesaleInfo ? homesaleInfo.finalRecComplete : false, tab: 1, Cell: ({ cell }) => <Checkbox name="finalRecComplete" label="Active" checked={cell.row.original.finalRecComplete} disabled={true} /> },
    { header: 'Due Date', accessorKey: 'dueDate', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.dueDate : '', tab: 1 },
    { header: 'Marketing Period', accessorKey: 'marketingPeriod', type: 'number', required: false, value: homesaleInfo ? homesaleInfo.marketingPeriod : '', tab: 1 },
    { header: 'Marketing Start', accessorKey: 'marketingStart', type: 'date', required: false,  value: homesaleInfo ? homesaleInfo.marketingStart : '', tab: 1 },
    { header: 'Marketing End', accessorKey: 'marketingEnd', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.marketingEnd : '', tab: 1 },
    { header: 'On Hold', accessorKey: 'onHold', type: 'checkbox', required: false, value: homesaleInfo ? homesaleInfo.onHold : false, tab: 1, Cell: ({ cell }) => <Checkbox name="onHold" label="Active" checked={cell.row.original.onHold} disabled={true} /> },
    { header: 'On Hold Start', accessorKey: 'onHoldStart', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.onHoldStart : '', tab: 1 },
    { header: 'On Hold End', accessorKey: 'onHoldEnd', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.onHoldEnd : '', tab: 1 },
    { header: 'Cancelled', accessorKey: 'cancelled', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.cancelled : '', tab: 1 },
    { header: 'Days Held', accessorKey: 'daysHeld', type: 'text', required: false, value: (homesaleInfo && homesaleInfo.onHoldStart && homesaleInfo.onHoldEnd) ? dateDiff(new Date(homesaleInfo.onHoldEnd), new Date(homesaleInfo.onHoldStart)) :  0, hideInModal: true, tab: 1 },
    { header: 'Days on Market', accessorKey: 'daysOnMarket', type: 'text', required: false, value: (homesaleInfo && homesaleInfo.marketingStart && homesaleInfo.marketingEnd) ? dateDiff(new Date(homesaleInfo.marketingEnd), new Date(homesaleInfo.marketingStart)) :  0, hideInModal: true, tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (homesaleInfo && homesaleInfo.length !== 0) {
      let newValues = { ...homesaleInfo };

      newValues.saleType = values.saleType;
      newValues.initiationDate = values.initiationDate;
      newValues.finalRecComplete = values.finalRecComplete;
      newValues.dueDate = values.dueDate;
      newValues.marketingPeriod = values.marketingPeriod;
      newValues.marketingStart = values.marketingStart;
      newValues.marketingEnd = values.marketingEnd;
      newValues.onHold = values.onHold;
      newValues.onHoldStart = values.onHoldStart;
      newValues.onHoldEnd = values.onHoldEnd;
      newValues.cancelled = values.cancelled;

      const resp = await props.updateHomesaleInfo(newValues);
      if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to update homesale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;

      if (values.initiationDate?.length === 0) values.initiationDate = undefined;
      if (values.dueDate?.length === 0) values.dueDate = undefined;
      if (values.marketingStart?.length === 0) values.marketingStart = undefined;
      if (values.marketingEnd?.length === 0) values.marketingEnd = undefined;
      if (values.onHoldStart?.length === 0) values.onHoldStart = undefined;
      if (values.onHoldEnd?.length === 0) values.onHoldEnd = undefined;
      if (values.cancelled?.length === 0) values.cancelled = undefined;
      if (values.marketingPeriod?.length === 0) values.marketingPeriod = undefined;

      values.daysHeld = undefined;
      values.daysOnMarket = undefined;

      const resp = await props.postHomesaleInfo(values);
      if (resp.type === POST_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to update homesale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateHomesaleInfo,
    postHomesaleInfo,
    showToast,
  }),
)(ProgramInfoCard);