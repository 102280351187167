import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { DELETE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, POST_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE } from '../../types';
import { deleteClientContractsCreditsAndRebates, getClientContractsCreditsAndRebates, postClientContractsCreditsAndRebates, updateClientContractsCreditsAndRebates } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';


const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientContractsCreditsAndRebatesTable = (props) => {
  const [clientOverview, setClientOverview] = useState(false);
  const theme = useTheme();

  const adjustedColumns = [
    { accessorKey: 'type', header: 'Type', type: 'dropdown', options:['Referral', 'Sign-On Bonus', 'Credit to TRC Fees', 'Other'], size: 100, required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'amount', header: 'Amount', type: 'text', required: true, size: 100, enableColumnDragging: false, enableColumnActions: false, enableResizing: false, Cell: ({ cell }) => `$${cell.getValue()}` },
    { accessorKey: 'description', header: 'Description', type: 'notes', size: 125, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },

  ];

  const updateRow = async (contractsCreditsAndRebates, values) => {
    const id = contractsCreditsAndRebates.original.clientContractsCreditsAndRebatesKey;
    values.clientContractsCreditsAndRebatesKey = id;
    values.clientId = props.clientId;
    const resp = await props.updateClientContractsCreditsAndRebates(values);
    if (resp.type === UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractsCreditsAndRebates(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postClientContractsCreditsAndRebates(values);
    if (resp.type === POST_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractsCreditsAndRebates(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (contractsCreditsAndRebates) => {
    const id = contractsCreditsAndRebates.original.clientContractsCreditsAndRebatesKey;
    const resp = await props.deleteClientContractsCreditsAndRebates(id);
    if (resp.type === DELETE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractsCreditsAndRebates(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Credits/Rebates</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientContractsCreditsAndRebatesLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.clientContractsCreditsAndRebates} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {props.clientContractsCreditsAndRebatesLoading && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

ClientContractsCreditsAndRebatesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientContractsCreditsAndRebatesLoading: PropTypes.bool,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
  postClientContractsCreditsAndRebates: PropTypes.func,
  updateClientContractsCreditsAndRebates: PropTypes.func,
  getClientContractsCreditsAndRebates: PropTypes.func,
  deleteClientContractsCreditsAndRebates: PropTypes.func,
  clientContractsCreditsAndRebates: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { clientContractsCreditsAndRebates } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientContractsCreditsAndRebatesLoading } = state.clients;
  return {
    clientContractsCreditsAndRebates,
    clientContractsCreditsAndRebatesLoading,
  };
};
  
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientContractsCreditsAndRebates,
    deleteClientContractsCreditsAndRebates,
    postClientContractsCreditsAndRebates,
    updateClientContractsCreditsAndRebates,
  }),
)(ClientContractsCreditsAndRebatesTable);