/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import {
  DELETE_INSPECTORS_FAILURE,
  GET_INSPECTORS_FAILURE,
  POST_INSPECTORS_FAILURE,
  UPDATE_INSPECTORS_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteTitleRep } from 'modules/authorizations/store/actions/homesale.actions';
import { getTitleReps } from 'modules/authorizations/store/actions/dataManagement.actions';
import { memoize } from 'lodash';
import { postTitleRep } from 'modules/authorizations/store/actions/homesale.actions';
import { updateTitleRep } from 'modules/authorizations/store/actions/homesale.actions';
import CrudTable from '../clients/details/crudTable.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import classNames from 'classnames';
import states from 'modules/common/states.json';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const TitleRepTable = (props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    let allStates = states.filter((state) => state.countryCode === useCountryCode).map((state) => state.description);
    return allStates;
  });

  //   public int TitleRepId { get; set; }

  const adjustedColumns = [
    { accessorKey: 'firstName', header: 'Rep First Name', type: 'text', required: false, hideInTable: true },
    { accessorKey: 'lastName', header: 'Rep Last Name', type: 'text', required: false, size: 50 },
    { accessorKey: 'companyName', header: 'Title Rep Company Name', type: 'text', required: true, size: 70 },
    { accessorKey: 'type', header: 'Rep Company Type', type: 'dropdown', options: ['TitleCo', 'Attorney'], required: true, size: 70 },
    { accessorKey: 'streetAddress', header: 'Address', type: 'text', required: true, hideInTable: true },
    { accessorKey: 'city', header: 'City', type: 'text', required: true, size: 50 },
    { accessorKey: 'state', header: 'State', type: 'dropdown', options: renderStateOptions(''), required: true, size: 50 },
    { accessorKey: 'zipCode', header: 'Zip Code', type: 'text', required: true, size: 50, hideInTable: true },
    { accessorKey: 'phone', header: 'Rep Phone Number', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'mobile', header: 'Rep Mobile', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'fax', header: 'Rep Fax', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'email', header: 'Rep Email', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'standardFee', header: 'Standard Fee', type: 'money', required: false, size: 50, hideInTable: true },
    //TODO - we should update this to a select of existing companies
    { accessorKey: 'citiesServed', header: 'Cities Served', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'zipsServed', header: 'Zip Codes Served', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'comments', header: 'Comments', type: 'text', required: false, size: 50, hideInTable: true },
  ];

  const updateRow = async (orgRow, newRow) => {
    newRow.titleRepId = orgRow.original.titleRepId;

    const checkValues = {
      ...newRow,
      standardFee: !newRow?.standardFee || newRow?.standardFee === '' ? null : newRow?.standardFee,
      titleRepId: orgRow.original.titleRepId,
    };

    const resp = await props.updateTitleRep(checkValues);
    if (resp.type === UPDATE_INSPECTORS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getTitleReps();
      if (getResp.type === GET_INSPECTORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    const checkValues = {
      ...values,
      standardFee: !values?.standardFee || values?.standardFee === '' ? null : values?.standardFee,
    };

    const resp = await props.postTitleRep(checkValues);
    if (resp.type === POST_INSPECTORS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getTitleReps();
      if (getResp.type === GET_INSPECTORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (rowToDelete) => {
    const id = rowToDelete.original.titleRepId;
    const resp = await props.deleteTitleRep(id);
    if (resp.type === DELETE_INSPECTORS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getTitleReps();
      if (getResp.type === GET_INSPECTORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
      classes={{ root: classNames(props.classes.accordionStyleContacts) }}
    >
      <AccordionSummary
        expandIcon={
          <Box color={theme.palette.secondary.main}>
            <ExpandMoreIcon color="inherit" />
          </Box>
        }
      >
        <div className={props.classes.newRow}>
          <h2>Title Reps</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.isTitleRepIsLoading && props.titleReps && (
          <div>
            <CrudTable
              editingMode={'modal'} // Can be changed to 'row' to edit inline
              columns={adjustedColumns}
              rows={props.titleReps}
              updateRow={updateRow}
              createRow={createRow}
              deleteRow={deleteRow}
            />
          </div>
        )}
        {props.isTitleRepIsLoading && <Spinner />}
      </AccordionDetails>
    </Accordion>
  );
};

TitleRepTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { titleReps, isTitleRepIsLoading } = state.authorizations;
  return {
    titleReps,
    isTitleRepIsLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    updateTitleRep,
    deleteTitleRep,
    postTitleRep,
    getTitleReps,
  }),
)(TitleRepTable);
