import { withStyles, withTheme, Typography } from '@material-ui/core';

import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';

import { compose } from 'recompose';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { formatDateForInput, formatDateGeneric } from 'utilities/common';
import Spinner from 'modules/common/spinner.component';
import { Box, Divider, MenuItem, Grid as MuiGrid, TextField } from '@mui/material';
import MultiSelectDropdown from 'modules/common/multiSelectDropdownForm.component';
import { SERVICE_FILTER_TYPES, DATE_FILTER_TYPES, PAYROLL_FILTER_TYPES, EXPENSE_LINE_APPROVAL_STATUS } from './expenseConstants';
import DownloadCsvLink from 'modules/common/downLoadCsvLink.component';

const styles = theme => {
  const border = `1px solid ${theme.palette.grey[500]}`;
  return {
    table: {
      borderSpacing: theme.spacing(0, 2)
    },
    tableHead: {
      height: 32
    },
    cell: {
      borderTop: border,
      borderBottom: border,
      borderLeft: 0,
      borderRight: 0,
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: border
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: border
      }
    },
    urgencyMenuItem: {
      justifyContent: 'center'
    },
    selectInput: {
      lineHeight: 1.75,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(0.5)
    },
    row: {
      backgroundColor: theme.palette.common.white
    },
    noDataCell: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      textAlign: 'center'
    }
  };
};

const ApprovedExpensesTableHeader = ({ expenses, setFilteredExpenses }) => {
  //TODO - Do we need this or can the parent handle this?
  const [originalExpenses] = useState(expenses ?? []);

  //Filters
  const [fromDateFilter, setFromDateFilter] = useState();
  const [toDateFilter, setToDateFilter] = useState();
  const [selectedServiceFilterType, setSelectedServiceFilterType] = useState(SERVICE_FILTER_TYPES[0]);
  const [selectedDateFilter, setSelectedDateFilter] = useState(DATE_FILTER_TYPES[0]);
  const [selectedPayrollFilter, setSelectedPayrollFilter] = useState([]);

  //TODO - Remove/Add fields as we see fit.
  const expenseLineItemFields = [
    { label: 'Account One Balance', value: 'accountOneBalance' },
    { label: 'Account Two Balance', value: 'accountTwoBalance' },
    { label: 'Amount', value: 'amount' },
    { label: 'AP Check Number', value: 'apCheckNumber' },
    { label: 'AP In Progress', value: 'apInProgress' },
    { label: 'AP Invoice Number', value: 'apInvoiceNumber' },
    { label: 'AP Line Description', value: 'apLineDescription' },
    { label: 'AP Posted', value: 'apPosted' },
    { label: 'Approval Status', value: 'approvalStatus' },
    { label: 'Approved Amount', value: 'approved' },
    { label: 'AP Route To', value: 'apRouteTo' },
    { label: 'AR Amount', value: 'arAmount' },
    { label: 'AR Check Number', value: 'arCheckNumber' },
    { label: 'AR In Progress', value: 'arInProgress' },
    { label: 'AR Invoice Number', value: 'arInvoiceNumber' },
    { label: 'AR Paid Amount', value: 'arPaidAmount' },
    { label: 'AR Paid Date', value: 'arPaidDate' },
    { label: 'AR Posted', value: 'arPosted' },
    { label: 'AR Void By', value: 'arVoidBy' },
    { label: 'Balance', value: 'balance' },
    { label: 'Balance Deposit Billing', value: 'balanceDepositBilling' },
    { label: 'Budget Currency Amount', value: 'budgetCurrencyAmount' },
    { label: 'CAD Amount', value: 'cadAmount' },
    { label: 'Cash Received', value: 'cashReceived' },
    { label: 'CAT', value: 'cat' },
    { label: 'Category', value: 'category' },
    { label: 'Check Date', value: 'checkDate' },
    { label: 'Check Void', value: 'checkVoid' },
    { label: 'Currency', value: 'currency' },
    { label: 'Currency Code', value: 'currencyCode' },
    { label: 'Denied Amount', value: 'denied' },
    { label: 'Denied Reason', value: 'deniedReason' },
    { label: 'DepApplied Amount', value: 'depAppliedAmount' },
    { label: 'Description', value: 'description' },
    { label: 'Due Date', value: 'dueDate' },
    { label: 'Entry Date', value: 'entryDate' },
    { label: 'Equivalent Currency', value: 'equivalentCurrency' },
    { label: 'Exception', value: 'exception' },
    { label: 'Exchange Rate Date', value: 'exchangeRateDate' },
    { label: 'Expense Code', value: 'expenseCode' },
    { label: 'Expense Code Description', value: 'expenseCodeDescription' },
    { label: 'Expense Code Id', value: 'expenseCodeId' },
    { label: 'Expense Date', value: 'expenseDate' },
    { label: 'Expense Id', value: 'expenseId' },
    { label: 'Expense Line Id', value: 'expenseLineId' },
    { label: 'Far Reason', value: 'farReason' },
    { label: 'From Date', value: 'fromDate' },
    { label: 'Gl Number', value: 'glNumber' },
    { label: 'Has Attachment', value: 'hasAttachment' },
    { label: 'Home Amount', value: 'homeAmount' },
    { label: 'Host Amount', value: 'hostAmount' },
    { label: 'House Hold Goods Id', value: 'houseHoldGoodsId' },
    { label: 'Inventory Current', value: 'inventoryCurrent' },
    { label: 'Invoice Date', value: 'invoiceDate' },
    { label: 'Net Check', value: 'netCheck' },
    { label: 'On Hold Date', value: 'onHoldDate' },
    { label: 'Paid To Detail', value: 'paidToDetail' },
    { label: 'Payee Address One', value: 'payeeAddressOne' },
    { label: 'Payee Address Two', value: 'payeeAddressTwo' },
    { label: 'Pay In Amount', value: 'payInAmount' },
    { label: 'Payment Address', value: 'paymentAddress' },
    { label: 'Pay Option', value: 'payOption' },
    { label: 'Payroll Date', value: 'payrollDate' },
    { label: 'Pay Type', value: 'payType' },
    { label: 'Purchase Order Det Status', value: 'purchaseOrderDetStatus' },
    { label: 'Purchase Order Number', value: 'purchaseOrderNumber' },
    { label: 'Receipt Amount', value: 'receiptAmount' },
    { label: 'Reduce Advance', value: 'reduceAdvance' },
    { label: 'Reference', value: 'reference' },
    { label: 'Refund Amount', value: 'refundAmount' },
    { label: 'Refund Check Num', value: 'refundCheckNum' },
    { label: 'Refund Date', value: 'refundDate' },
    { label: 'Reimbursement Info', value: 'reimbursementInfo' },
    { label: 'Report Date', value: 'reportDate' },
    { label: 'Se Inv Number', value: 'seInvNumber' },
    { label: 'Service', value: 'service' },
    { label: 'Service Company Amount', value: 'serviceCompanyAmount' },
    { label: 'Source Type', value: 'sourceType' },
    { label: 'Status', value: 'status' },
    { label: 'Taxable Benefit Amount', value: 'taxableBenefitAmount' },
    { label: 'Tax Report Date', value: 'taxReportDate' },
    { label: 'Tax Year', value: 'taxYear' },
    { label: 'To Date', value: 'toDate' },
    { label: 'State Excludable Amount', value: 'stateExcludableAmount' },
    { label: 'Payroll In Process Date', value: 'payrollInProcessDate' },
    { label: 'Payroll Posted Date', value: 'payrollPostedDate' },
    { label: 'Payroll Type', value: 'payrollType' },
    { label: 'Earnings Code', value: 'earningsCode' },
    { label: 'Tax State', value: 'taxState' },
    { label: 'Pay Amount', value: 'payAmount' },
    { label: 'Medicare Tax Assistance Original Amount', value: 'medicareTaxAssistanceOriginalAmount' },
    { label: 'Medicare Withholding Original Amount', value: 'medicareWithholdingOriginalAmount' },
    { label: 'Medicare Tax Assistance Surtax Amount', value: 'medicareTaxAssistanceSurtaxAmount' },
    { label: 'Medicare Withholding Surtax Amount', value: 'medicareWithholdingSurtaxAmount' },
    { label: 'Federal Tax Assistance Amount', value: 'federalTaxAssistanceAmount' },
    { label: 'Federal Withholding Amount', value: 'federalWithholdingAmount' },
    { label: 'Federal Reportable Income Amount', value: 'federalReportableIncomeAmount' },
    { label: 'State Tax Assitance Amount', value: 'stateTaxAssitanceAmount' },
    { label: 'State Withholding Amount', value: 'stateWithholdingAmount' },
    { label: 'State Reportable Income Amount', value: 'stateReportableIncomeAmount' },
    { label: 'Social Security Assitance Amount', value: 'socialSecurityAssitanceAmount' },
    { label: 'Social Security Withholding Amount', value: 'socialSecurityWithholdingAmount' },
    { label: 'Social Security Reportable Income Amount', value: 'socialSecurityReportableIncomeAmount' },
    { label: 'Medicare Assitance Amount', value: 'medicareAssitanceAmount' },
    { label: 'Medicare Withholding Amount', value: 'medicareWithholdingAmount' },
    { label: 'Medicare Reportable Income Amount', value: 'medicareReportableIncomeAmount' },
    { label: 'Total Deposit Billing', value: 'totalDepositBilling' },
    { label: 'Trans Status', value: 'transStatus' },
    { label: 'Unpay By', value: 'unpayBy' },
    { label: 'Unpay Date', value: 'unpayDate' },
    { label: 'USD Amount', value: 'usdAmount' },
    { label: 'Void By', value: 'voidBy' },
    { label: 'Void Date', value: 'voidDate' },
    { label: 'Void Invoice Number', value: 'voidInvoiceNumber' },
    { label: 'Voucher Number', value: 'voucherNumber' },
    { label: 'Wire Transfer', value: 'wireTransfer' },
    { label: 'Write Off Amount', value: 'writeOffAmount' }
  ];

  const calculateTotalExpenses = () => {
    if (!expenses) return 0;
    return expenses.reduce((sum, record) => {
      const approvedValue = parseFloat(record.approved) || 0;
      return sum + approvedValue;
    }, 0);
  };

  const countNumberOfTxs = () => {
    //TODO - Figure out what this is
    return 2;
  };

  const dateFilterRequiresFromAndToDates = () => {
    //TODO - Use an array or properties of an object?
    switch (selectedDateFilter) {
      case DATE_FILTER_TYPES[0]:
      case DATE_FILTER_TYPES[1]:
      case DATE_FILTER_TYPES[2]:
      case DATE_FILTER_TYPES[3]:
      case DATE_FILTER_TYPES[4]:
      case DATE_FILTER_TYPES[5]:
        return false;
      default:
        return true;
    }
  };

  const handleFilterChange = setFilterValue => event => {
    setFilterValue(event.target.value);

    if (!originalExpenses || originalExpenses.length === 0) return;

    //TODO - Is there a better way to do this?
    let tempFilterExpenses = { ...originalExpenses };
    tempFilterExpenses = applyServiceFilter(tempFilterExpenses);
    tempFilterExpenses = applyDateFilter(tempFilterExpenses);
    tempFilterExpenses = applyPayrollFilter(tempFilterExpenses);
    if (dateFilterRequiresFromAndToDates()) {
      tempFilterExpenses = applyToDateFilter(tempFilterExpenses);
      tempFilterExpenses = applyFromDateFilter(tempFilterExpenses);
    } else {
      //TODO - Should we clear any current to/from dates?
    }
    setFilteredExpenses(tempFilterExpenses);
  };

  const applyFromDateFilter = value => {
    setFromDateFilter(value);

    //Some logic ...

    return expenses;
  };

  const applyToDateFilter = value => {
    setToDateFilter(value);

    //Some logic ...

    return expenses;
  };

  const applyServiceFilter = value => {
    setSelectedServiceFilterType(value);

    //Some logic ...

    return expenses;
  };

  const applyDateFilter = value => {
    selectedDateFilter(value);

    //Some logic ...

    return expenses;
  };

  const applyPayrollFilter = value => {
    setSelectedPayrollFilter(value);

    //Some logic ...

    return expenses;
  };

  const renderBox = (title, content) => (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ margin: '5px 10px' }}>
      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '0.85rem' }}>
        {title}
      </Typography>
      {content}
    </Box>
  );

  const renderDateBox = (title, value, onChange) =>
    renderBox(
      title,
      <TextField
        value={value}
        onChange={onChange}
        type="date"
        variant="standard"
        InputLabelProps={{ shrink: true }}
        sx={{
          maxWidth: '360px',
          margin: 'dense',
          '& .MuiInputBase-input': {
            fontSize: '0.85rem'
          }
        }}
      />
    );

  const renderSelectBox = (title, value, onChange, options) =>
    renderBox(
      title,
      <TextField
        select
        value={value}
        onChange={onChange}
        SelectProps={{ autoWidth: true, displayEmpty: true }}
        variant="standard"
        sx={{
          minWidth: '100px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiInputBase-input': {
            fontSize: '0.85rem'
          }
        }}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    );

  return (
    <Box>
      <MuiGrid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '10px' }}
      >
        <MuiGrid item>
          {renderBox(
            'Total Expenses',
            <Typography variant="body2" sx={{ paddingTop: '8px', color: '#43423a', fontWeight: 500, fontSize: '0.85rem' }}>
              ${calculateTotalExpenses().toFixed(2)}
            </Typography>
          )}
        </MuiGrid>
        <MuiGrid item>
          {renderBox(
            '# of Txs',
            <Typography variant="body2" sx={{ paddingTop: '8px', color: '#43423a', fontWeight: 500, fontSize: '0.85rem' }}>
              {countNumberOfTxs()}
            </Typography>
          )}
        </MuiGrid>
        <MuiGrid item>
          {renderSelectBox(
            'Service Filter',
            selectedServiceFilterType,
            handleFilterChange(setSelectedServiceFilterType),
            SERVICE_FILTER_TYPES
          )}
        </MuiGrid>
        <MuiGrid item>
          {renderSelectBox('Date Filter', selectedDateFilter, handleFilterChange(setSelectedDateFilter), DATE_FILTER_TYPES)}
        </MuiGrid>
        <MuiGrid item>{renderDateBox('From', formatDateForInput(fromDateFilter), handleFilterChange(setFromDateFilter))}</MuiGrid>
        <MuiGrid item>{renderDateBox('To', formatDateForInput(toDateFilter), handleFilterChange(setToDateFilter))}</MuiGrid>
        <MuiGrid item>
          <MultiSelectDropdown
            options={PAYROLL_FILTER_TYPES}
            label="Payroll Filter"
            selectedOptions={selectedPayrollFilter}
            setSelectedOptions={setSelectedPayrollFilter}
          />
        </MuiGrid>
        <MuiGrid item>
          <Box display="flex" justifyContent="center" alignItems="flex-end" height="100%">
            {/* TODO - Do we want to download just the filtered approved expenses? */}
            <DownloadCsvLink data={originalExpenses} fields={expenseLineItemFields} />
          </Box>
        </MuiGrid>
      </MuiGrid>
      <Divider light sx={{ marginTop: '20px' }} />
    </Box>
  );
};

const filterExpenseFunc = (expenses, expenseLines) => {
  if (expenses && expenseLines && expenses.length > 0 && expenseLines.length > 0) {
    const approvedExpenses = expenses.filter(e => e && e.isExpenseApproved === true);

    return expenseLines.filter(
      li =>
        li &&
        approvedExpenses.some(e => e.expenseId === li.expenseId) &&
        (li.approvalStatus === EXPENSE_LINE_APPROVAL_STATUS[2] || li.approvalStatus === EXPENSE_LINE_APPROVAL_STATUS[3])
    );
  }
  return [];
};

const ApprovedExpenses = props => {
  const { classes, expenses, isLoading, expenseLines, history, id } = props;
  const [filteredLineItems, setFilteredLineItems] = useState(filterExpenseFunc(expenses, expenseLines));

  useEffect(() => {
    setFilteredLineItems(filterExpenseFunc(expenses, expenseLines));
  }, [expenses, expenseLines]);

  //TODO - What date do we want this for?
  const [sorting, setSorting] = useState([{ columnName: 'expenseDate', direction: 'desc' }]);
  const [tableColumnExtensions] = useState([{ columnName: 'description', width: 250, wordWrapEnabled: true }]);

  const changeSorting = sorting => setSorting(sorting);

  const formatDate = key => record => {
    return formatDateGeneric(record[key]);
  };

  const headerCell = props => {
    return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
  };

  const headerRowComponent = props => {
    return <TableHeaderRow.Row className={classes.tableHead} {...props} />;
  };

  const rowComponent = props => {
    return <Table.Row className={classes.row} {...props} />;
  };

  const cellComponent = props => {
    let clickHandler = () => history.push(`/authorizations/${id}/expenses/approved/${props.row.expenseLineId}`);
    let pointerClass = 'handles-click';
    const className = classNames(classes.cell, pointerClass);
    return <Table.Cell onClick={clickHandler} className={className} {...props} />;
  };

  const tableComponent = props => {
    return <Table.Table className={classes.table} {...props} />;
  };

  const noDataCellComponent = props => {
    const content = isLoading ? <Spinner logo /> : 'No Records were returned.';
    const { getMessage, ...rest } = props;
    return (
      <Table.Cell {...rest} className={classes.noDataCell}>
        <Typography>{content}</Typography>
      </Table.Cell>
    );
  };

  const [columns] = useState([
    { name: 'expenseCode', title: 'Expense Code' },
    { name: 'payrollDate', title: 'Payroll Date', getCellValue: formatDate('payrollDate') },
    {
      name: 'approved',
      title: 'Amount',
      getCellValue: record => <Typography>${isNaN(parseFloat(record.approved)) ? 0 : parseFloat(record.approved).toFixed(2)}</Typography>
    },
    { name: 'description', title: 'Description' },
    { name: 'currency', title: 'Currency' },
    { name: 'service', title: 'Service' },
    { name: 'cat', title: 'Category' }
  ]);

  return (
    <div>
      <ApprovedExpensesTableHeader expenses={filteredLineItems} setFilteredExpenses={setFilteredLineItems} />
      <Grid rows={filteredLineItems ? filteredLineItems : []} columns={columns}>
        <SortingState sorting={sorting} onSortingChange={changeSorting} />
        <IntegratedSorting />
        <Table
          columnExtensions={tableColumnExtensions}
          rowComponent={rowComponent}
          tableComponent={tableComponent}
          cellComponent={cellComponent}
          noDataCellComponent={noDataCellComponent}
        />
        <TableHeaderRow rowComponent={headerRowComponent} cellComponent={headerCell} showSortingControls />
      </Grid>
    </div>
  );
};

ApprovedExpenses.defaultProps = { isLoading: false };

export default compose(withStyles(styles), withTheme)(ApprovedExpenses);
