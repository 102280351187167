import {
  Box, Grid, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnHoldIcon from 'modules/common/onHoldIcon.component';
import React from 'react';

export const Status = (props) => {
  const showHold = props.name === 'On Hold' && props.isActive && props.numDays > 0;
  return (
    <Box>
      {showHold && 
        <Box sx={{ position: 'absolute' }}>
          <Box sx={{ marginLeft: '90px', marginTop: '-15px' }}>
            <OnHoldIcon />
          </Box>
        </Box>
      }
      <Box style={{ padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 50, backgroundColor: props.isActive ? '#67a783' : '#fff', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
        <Typography style={{ fontSize: '1.25rem', color: props.isActive ? '#fff' : '#000000' }}>
          {props.name}
        </Typography>
      </Box>
      {props.numDays && (
        <Typography style={{ fontSize: '.75rem', color: '#868686', textAlign: 'center', paddingTop: 5 }}>
          {`${props.numDays} ${props.numDays === 1 ?  'Day' : 'Days'}`}
        </Typography>
      )}
    </Box>
  );
};

const VIP = () => {
  return (
    <Box>
      <Box style={{ padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 50, backgroundColor: 'orange', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
        <Typography style={{ fontSize: '1.25rem', color: 'white' }}>
          VIP
        </Typography>
      </Box>
    </Box>
  );
};

const WebAccess = (props) => {
  const webAccessGranted = props.isActive;
  return (
    <Box>
      { webAccessGranted &&
        <>
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 50, backgroundColor: 'green', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
            <Typography style={{ fontSize: '1.25rem', color: 'white' }}>
              Web Access Granted
            </Typography>
            <FontAwesomeIcon size="2x" icon={['fas', 'fa-check-circle']} color="white" style={{ marginLeft: '10px', marginTop: '1px' }} />    
          </Box>
        </>

      }
      { !webAccessGranted &&
        <>
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 50, backgroundColor: 'red', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
            <Typography style={{ fontSize: '1.25rem', color: 'white' }}>
              No Web Access
            </Typography>
            <FontAwesomeIcon size="2x" icon={['fas', 'fa-ban']} color="white" style={{ marginLeft: '10px', marginTop: '1px' }} />    
          </Box>
        </>

      }
    </Box>
  );
};

export const Arrow = (props) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '23px' }}>
      <FontAwesomeIcon color={props.isActive ? '#67a783' : '#868686'} icon={['fas', 'arrow-right']} size="2x" />
    </Box>
  );
}; 

const StatusStepper = (props) => {
  const { status, dateSummary, vip, webAccess } = props;

  const dateDiff = (endDate, startDate) => {
    const diffInMs = endDate - startDate;
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
  };

  /*
        Days Calculation:
        - Init: (initCallComplete date or current date) - initiation date
        - Active: ((oldest variable date or current date) - initCallComplete date) or 1 if neither date is available
        - On Hold: ((next oldest variable date or current date) - onHoldDate date) or 1 if neither date is available
        - Closed: ((next oldest variable date or current date) - completed date) or 1 if neither date is available
        - Cancelled: ((next oldest variable date or current date) - cancelled date) or 1 if neither date is available
        - Ops Closed: hardcoded as 1 for now since there is no date to represent this in summary
        - On Assignement: hardcoded as 1 for now since there is no date to represent this in summary

        On Hold icon appears if the status is set to On Hold and onHoldDate is not null.
    */
  const calculateStatusOrder = () => {
    let statusOrder = [];
    statusOrder.push({ name: 'Initiated', isActive: status === 'Init' ? true : false, numDays: dateDiff(dateSummary.initCallComplete ? new Date(dateSummary.initCallComplete) : new Date(), new Date(dateSummary.initiation)), hideArrow: true });

    if (status !== 'Init') {
      let variableDates = [];

      if (dateSummary.onHoldDate) variableDates.push({ name: 'On Hold', date: new Date(dateSummary.onHoldDate), isActive: status === 'On Hold' });
      if (dateSummary.completed) variableDates.push({ name: 'Closed', date: new Date(dateSummary.completed), isActive: status === 'Closed' });
      if (dateSummary.cancelled) variableDates.push({ name: 'Cancelled', date: new Date(dateSummary.cancelled), isActive: status === 'Cancelled' });

      variableDates = variableDates.sort((a, b) => a.date - b.date);

      const numDaysActive = dateSummary.initCallComplete ? dateDiff(variableDates[0] ? variableDates[0].date : new Date(), new Date(dateSummary.initCallComplete)) : 1;
      statusOrder.push({ name: 'Active', isActive: status === 'Active' ? true : false, numDays: numDaysActive });

      for (let i = 0; i < variableDates.length; i++) {
        variableDates[i].numDays = variableDates[i].date ? dateDiff(variableDates[i + 1] ? variableDates[i + 1].date : new Date(), variableDates[i].date) : 1;
      }

      variableDates.map((d) => statusOrder.push({ name: d.name, numDays: d.numDays, isActive: d.isActive }));

      if (status === 'Ops Closed') statusOrder.push({ name: 'Ops Closed', isActive: true, numDays: 1 });

      if (status === 'On Assignment') statusOrder.push({ name: 'On Assignment', isActive: true, numDays: 1 });

      if (!statusOrder.some((s) => s.name === 'Closed')) statusOrder.push({ name: 'Closed', numDays: null, isActive: false });
    } else {
      statusOrder.push({ name: 'Active', numDays: null, isActive: false });
      statusOrder.push({ name: 'Closed', numDays: null, isActive: false });
    }

    return statusOrder;
  };

  return (
    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', maxWidth: '100% !important' }}>
      <Grid item xs={10} md={10} lg={9}>
        <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px', gap: 1, justifyContent: 5 }} >
          {calculateStatusOrder().map((s) => {
            return (
              <>
                {!s.hideArrow && <Arrow isActive={s.isActive ? true : false} />}
                <Status name={s.name} isActive={s.isActive ? true : false} numDays={s.numDays} />
              </>
            );
          })}
        </Box>
      </Grid>
      { vip &&
        <>
          <Grid item xs={2} md={2} lg={3}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px', gap: 1, justifyContent: 5, marginLeft: '85px' }} >
              <VIP />
              <WebAccess isActive={webAccess} />
            </Box>
          </Grid>
        </>
      }
      {
        !vip &&
          <>
            <Grid item xs={2} md={2} lg={3}>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px', gap: 1, justifyContent: 5, marginLeft: '165px' }} >
                <WebAccess isActive={webAccess} />
              </Box>
            </Grid>
          </>
      }
      
    </Grid>
    
  );

};

export default StatusStepper;