import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DELETE_AUTHORIZATIONS_FAILURE, GET_AUTHORIZATIONS_FAILURE, POST_AUTHORIZATIONS_FAILURE, UPDATE_AUTHORIZATIONS_FAILURE } from '../authorizations/store/types/authorizationTypes';
import { chain } from 'lodash';
import { deleteAuthorizationSummary, getAuthorizationSummaries, postAuthorizationSummary, updateAuthorizationSummary } from '../authorizations/store/actions/authorizations.actions';
import CrudTable from '../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';
import memoize from 'memoize-one';

import classNames from 'classnames';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      marginTop: 40,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const AuthorizationSummariesTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const getClientList = memoize((clients) => {
    if (!clients) {
      return [];
    }

    let sortedClients = chain(clients)
      .values()
      .uniqWith(clients, (a, b) => a.description === b.description)
      .value()
      .sort((a, b) => String(a.description).localeCompare(b.description))
      .filter((instance) => instance.description);

    let clientSimpleList = [];
    sortedClients.map((c) => {
      return clientSimpleList.push(c.description);
    });

    return clientSimpleList;
  });

  const moveTypes = ['Candidate', 'Intern', 'Domestic Permanent Move', 'Short Term Assignment', 'Long Term Assignment', 'International Move/Localization', 'Repatriation', 'Extended Business Travel'];
  const statusTypes = ['Active', 'Cancelled', 'Closed', 'Init', 'On Assignment', 'On Hold', 'Ops Closed'];

  const adjustedColumns = [
    { accessorKey: 'authorizationId', header: 'Auth Id', type: 'text', required: true, size: 70 },
    { accessorKey: 'transfereeName', header: 'Transferee Name', type: 'text', defaultVal: false, required: true, size: 70 },
    { accessorKey: 'clientName', header: 'Client Name', type: 'dropdown', options: getClientList(props.clients), required: true, size: 70 },
    { accessorKey: 'moveType', header: 'Move Type', type: 'dropdown', options: moveTypes, required: true, size: 50 },
    { accessorKey: 'status', header: 'Status', type: 'dropdown', options: statusTypes, required: true, size: 50 },
    { accessorKey: 'departure', header: 'Departure', type: 'text', required: true, size: 50 },
    { accessorKey: 'destination', header: 'Destination', type: 'text', required: true, size: 50 },
    { accessorKey: 'lastModified', header: 'Last Modified', type: 'date', required: true, size: 50 },
  ];

  const updateRow = async (eligibleProperty, values) => {
    const id = eligibleProperty.original.authorizationId;
    values.authorizationId = id;
    values.clientId = props.clientId;
    const resp = await props.updateAuthorizationSummary(values);
    if (resp.type === UPDATE_AUTHORIZATIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthorizationSummaries(props.clientId);
      if (getResp.type === GET_AUTHORIZATIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postAuthorizationSummary(values);
    if (resp.type === POST_AUTHORIZATIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthorizationSummaries(props.clientId);
      if (getResp.type === GET_AUTHORIZATIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (eligibleProperty) => {
    const id = eligibleProperty.original.authorizationId;
    const resp = await props.deleteAuthorizationSummary(id);
    if (resp.type === DELETE_AUTHORIZATIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthorizationSummaries(props.clientId);
      if (getResp.type === GET_AUTHORIZATIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Authorization Summaries</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.authorizationSummariesLoading && props.authorizationSummaries && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.authorizationSummaries} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {props.authorizationSummariesLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

AuthorizationSummariesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationSummaries, authorizationSummariesLoading } = state.authorizations;
  const { clients } = state.clients;
  return {
    authorizationSummaries,
    authorizationSummariesLoading,
    clients,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getAuthorizationSummaries,
    deleteAuthorizationSummary,
    postAuthorizationSummary,
    updateAuthorizationSummary,
  }),
)(AuthorizationSummariesTable);