/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import { Button, Checkbox, FormControlLabel, Typography, withStyles } from '@material-ui/core';
import { DELETE_CALENDAR_FAILURE } from 'modules/clients/types';
import { Field, Formik } from 'formik';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { TextField } from 'modules/intake/form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteClientCalendar, getClientCalendars } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import Dialog from 'modules/common/dialog.component';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    borderColor: theme.palette.error.main,
  },
}))(Button);

const styles = (theme) => ({
  root: {
    minWidth: 200,
  },
  checkbox: {
    paddingTop: 15,
    paddingLeft: 15,
  },
  newCalendarButtons: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  buttonProgress: {
    width: 100,
  },
});  

const AnnualDates = (props) => {

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const updateCalendar = (values) => {
    props.updateCalendar(values);
  };

  const deleteCalendar = async (id) => {
    let resp = await props.deleteClientCalendar(id);
    if (resp.type === DELETE_CALENDAR_FAILURE) {
      props.showToast('Failed to delete the specified calendar, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } else {
      await props.getClientCalendars(props.calendarData.clientId, props.calendarData.calendarYear);
    }
  };

  const yearStart = props.calendarData ? new Date(`${props.calendarData.calendarYear}-01-01`).toISOString().slice(0, 10) : null;
  const yearEnd = props.calendarData ? new Date(`${props.calendarData.calendarYear}-12-31`).toISOString().slice(0, 10) : null;

  return (
    <Box marginX={2} marginY={2}>
      <Formik
        initialValues={props.calendarData}
        // validate={validate}
        // onSubmit={handleSaveRequest}
        enableReinitialize
        validateOnBlur={true}
        validateOnChange={true}
      >
        {(formikProps) => (
          <div>
            {formikProps.values && (
              <div>
                <h4>General</h4>
                <div>
                  <Field
                    component={TextField}
                    name={'dateEntered'}
                    label={'Date Entered'}
                    className={props.classes.root}
                    value={formikProps.values.dateEntered?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'auditDate'}
                    label={'Audit Date'}
                    className={props.classes.root}
                    value={formikProps.values.auditDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'postDate'}
                    label={'Post Date'}
                    className={props.classes.root}
                    value={formikProps.values.postDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'lastContactDate'}
                    label={'Last Contact Date'}
                    className={props.classes.root}
                    value={formikProps.values.lastContactDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'yearEndCutoffDate'}
                    label={'Year End Cutoff Date'}
                    className={props.classes.root}
                    value={formikProps.values.yearEndCutoffDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'blackoutDate'}
                    label={'Blackout Date'}
                    className={props.classes.root}
                    value={formikProps.values.blackoutDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'followingYearCutoffDate'}
                    label={'Following Year Cutoff Date'}
                    className={props.classes.root}
                    value={formikProps.values.followingYearCutoffDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'method'}
                    label={'Method'}
                    className={props.classes.root}
                    value={formikProps.values.method}
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'rtrCount'}
                    label={'RTR Count'}
                    className={props.classes.root}
                    value={formikProps.values.rtrCount}
                    type="number"
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'password'}
                    label={'Password'}
                    className={props.classes.root}
                    value={formikProps.values.password}
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                </div>

                <h4>Surveys</h4>
                <div>
                  <Field
                    component={TextField}
                    name={'surveyReady'}
                    label={'Survey Ready'}
                    className={props.classes.root}
                    value={formikProps.values.surveyReady?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'surveySent'}
                    label={'Survey Sent'}
                    className={props.classes.root}
                    value={formikProps.values.surveySent?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'surveyReceived'}
                    label={'Survey Received'}
                    className={props.classes.root}
                    value={formikProps.values.surveyReceived?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'surveyFollowUpSent'}
                    label={'Survey Follow Up Sent'}
                    className={props.classes.root}
                    value={formikProps.values.surveyFollowUpSent?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'surveyCompleted'}
                    label={'Survey Completed'}
                    className={props.classes.root}
                    value={formikProps.values.surveyCompleted?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={yearEnd}
                    min={yearStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        name="surveySaved"
                        label="Is Survey Saved"
                        value={formikProps.values.surveySaved}
                      />
                    }
                    label="Is Survey Saved"
                    classes={{ root: props.classes.checkbox }}
                  />
                </div>

                <h4>W2C</h4>
                <div>
                  <Field
                    component={TextField}
                    name={'w2C'}
                    label={'W2C Count'}
                    className={props.classes.root}
                    value={formikProps.values.w2C}
                    type="number"
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'w2CReason'}
                    label={'W2C Reason'}
                    className={props.classes.root}
                    value={formikProps.values.w2CReason}
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                </div>

                <div className={props.classes.newCalendarButtons}>
                  <DeleteButton 
                    variant="outlined" 
                    size="large" 
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    Delete Calendar
                  </DeleteButton>

                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {updateCalendar(formikProps.values);}}
                    disabled={JSON.stringify(formikProps.values) === JSON.stringify(props.calendarData)}
                  >
                    Update Calendar
                  </Button>
                </div>

                {showDeleteDialog && (
                // <ConfirmationDialog
                //     onConfirm={() => {deleteCalendar(formikProps.values.clientPayrollCalendarId);}}
                //     onCancel={() => {setShowDeleteDialog(false);}}
                //     open
                //     title="Calendar Deletion"
                //     confirmLabel="Delete"
                // >
                //     <Typography>Are you sure you would like to delete this calendar? This action cannot be undone.</Typography>
                // </ConfirmationDialog>

                  <Dialog
                    title="Calendar Deletion"
                    onCancel={() => { setShowDeleteDialog(false); }}
                    open={showDeleteDialog}
                    content={
                      <Typography>Are you sure you would like to delete this calendar? This action cannot be undone.</Typography>
                    }
                    actions={
                      <>
                        {!props.isDeletingCalendar &&
                          <Button size="large" variant="outlined" color="secondary" onClick={() => { setShowDeleteDialog(false); }}>Cancel</Button>
                        }
                                        
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          disabled={props.isDeletingCalendar}
                          onClick={() => {deleteCalendar(formikProps.values.clientPayrollCalendarId);}}
                          className={props.classes.buttonProgress}
                        >
                          {props.isDeletingCalendar ? <Spinner size="lg" color="#fff" /> : 'Delete'}
                        </Button>
                      </>
                    }
                  />
                )}
              </div>
  
            )}
          </div>
        )}
      </Formik>
    </Box>
  );
};

AnnualDates.propTypes = {
  calendarData: PropTypes.object,
};


const mapStateToProps = (state) => {
  const { isDeletingCalendar } = state.clients;
  return {
    isDeletingCalendar,
  };
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getClientCalendars,
    deleteClientCalendar,
    showToast,
  }),
)(AnnualDates);