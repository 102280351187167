import { FormTypes } from '../globalDestServiceConstants';
import {
  createGlobalDestinationColumns,
  createGlobalDestinationPostRequest,
  createGlobalDestinationPutRequest,
  createGlobalDestinationRows,
} from '../formData/destServiceFormData';
import { showToast } from 'modules/layout/layout.actions';
import { useCreateDataHandler } from '../apiHandlers/useCreateDataHandler';
import { useDeleteDataHandler } from '../apiHandlers/useDeleteDataHandler';
import { useFetchGlobalDestinationServiceDataHandler } from '../apiHandlers/useFetchDataHandler';
import { useSelector } from 'react-redux';
import { useUpdateDataHandler } from '../apiHandlers/useUpdateDataHandler';
import CrudTable from 'modules/clients/details/crudTable.component';
import React, { useRef, useState } from 'react';
import SpinnerComponent from 'modules/common/spinner.component';

const DestinationServicesCrudTable = ({ id, isReadOnly }) => {
  const createDataHandler = useCreateDataHandler(showToast);
  const updateDataHandler = useUpdateDataHandler(showToast);
  const deleteDataHandler = useDeleteDataHandler(showToast);
  const dataHandler = useFetchGlobalDestinationServiceDataHandler(showToast);

  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const scrollPositionRef = useRef(0);
  const containerRef = useRef(null);

  const { globalDestinationServices, vendors, vendorContacts } = useSelector((state) => {
    return {
      globalDestinationServices: state.authorizations.authorizationDetails[id]?.authGlobal?.globalDestinationServices,
      vendors: state.authorizations.vendors,
      vendorContacts: state.authorizations.vendorContacts,
    };
  });

  const handleCrudOperation = async (operation, ...args) => {
    scrollPositionRef.current = window.scrollY;
    setIsLocalLoading(true);
    const response = await operation(...args);
    if (response) {
      await dataHandler.fetchData(id);
    }
    setTimeout(() => {
      window.scrollTo(0, scrollPositionRef.current);
      setIsLocalLoading(false);
    }, 0);
    return response;
  };

  const updateRow = async (originalRow, newRow) => {
    return await handleCrudOperation(
      updateDataHandler.updateData,
      FormTypes.DestinationService,
      createGlobalDestinationPutRequest(globalDestinationServices[originalRow.index], newRow, id),
    );
  };

  const createRow = async (newRow) => {
    return await handleCrudOperation(
      createDataHandler.createData,
      FormTypes.DestinationService,
      createGlobalDestinationPostRequest(newRow, id),
    );
  };

  const deleteRow = async (rowToDelete) => {
    return await handleCrudOperation(
      deleteDataHandler.deleteData,
      FormTypes.DestinationService,
      id,
      rowToDelete.original.authGlobalDestinationId,
    );
  };

  return (
    <div style={{ minHeight: '200px', position: 'relative' }} ref={containerRef}>
      {isLocalLoading ? (
        <div style={{ position: 'absolute', display: 'flex' }}>
          <SpinnerComponent />
        </div>
      ) : (
        <CrudTable
          editingMode={'modal'}
          columns={createGlobalDestinationColumns(vendors, vendorContacts)}
          rows={createGlobalDestinationRows(globalDestinationServices, vendors, vendorContacts)}
          updateRow={updateRow}
          createRow={createRow}
          deleteRow={deleteRow}
          customAddText={'Add Destination Service'}
          hideEdit={isReadOnly}
          toolbarCustomButton={true}
          customButtonText={'Order Service'}
          customButtonMethod={() => alert('Order Service Letter Sent Successfully!')}
        />
      )}
    </div>
  );
};

export default DestinationServicesCrudTable;
