import { DELETE_FAMILY_FAILURE } from '../../../store/types/authorizationTypes';
import { GET_INSPECTIONS_FAILURE, POST_INSPECTIONS_FAILURE, UPDATE_INSPECTIONS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

export const useInspectionsHandler = ({
  getInspections,
  postInspection,
  updateInspection,
  deleteInspection,
  showToast,
  updateInspectionState,
}) => {
  const handleCreateInspection = async (values) => {
    try {
      const resp = await postInspection(values);
      if (resp.type === POST_INSPECTIONS_FAILURE) {
        showToast('Failed to create inspection, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return resp.response;
    } catch (error) {
      showToast('An unexpected error occurred while creating inspection.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleUpdateInspection = async (values) => {
    try {
      const resp = await updateInspection(values);
      if (resp.type === UPDATE_INSPECTIONS_FAILURE) {
        showToast('Failed to update inspection, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return resp.response;
    } catch (error) {
      showToast('An unexpected error occurred while updating inspection.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleGetInspection = async (authId) => {
    try {
      const resp = await getInspections(authId);
      if (resp.type === GET_INSPECTIONS_FAILURE) {
        showToast('Failed to get inspections, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return resp.response;
    } catch (error) {
      showToast('An unexpected error occurred while fetching inspections', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleDeleteInspection = async (authId, inspectionId) => {
    try {
      const resp = await deleteInspection(authId, inspectionId);
      if (resp.type === DELETE_FAMILY_FAILURE) {
        showToast('Failed to delete inspection, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while deleting inspection.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleUpdateInspectionState = async (inspections) => {
    return (await updateInspectionState(inspections))?.response;
  };

  return {
    handleCreateInspection,
    handleUpdateInspection,
    handleGetInspection,
    handleDeleteInspection,
    handleUpdateInspectionState,
  };
};
