/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, DialogActions, Grid } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  createInspectionPutRequest,
  createInspectionsFormFields,
  createOrderInspectionSentRequest,
  createPostRequest,
  createVendorFormTab,
  createVendorInfoFormFields,
  createVendorInfoPutRequest,
} from './FormFields/inspectionsFormFields';
import { createInspectionsFormTab } from './FormFields/inspectionsFormFields';
import {
  deleteInspection,
  getInspections,
  postInspection,
  updateInspection,
  updateInspectionState,
} from 'modules/authorizations/store/actions/homesale.actions';
import { showToast } from 'modules/layout/layout.actions';
import { useInspectionsHandler } from './inspectionsApiHandler';
import EditableCard from 'modules/common/editableCard.component';
import InspectionsHeader from './inspectionsHeader';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const Inspections = (props) => {
  const { inspectors, brokers, id, vendors, inspections, isReadOnly } = props;

  const [selectedInspection, setSelectedInspection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sendInspectionIsLoading, setSendInspectionIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const inspectionHandler = useInspectionsHandler(props);

  useEffect(() => {
    if (inspections?.length > 0 && !selectedInspection) {
      //We will default to the most recently created inspection
      setSelectedInspection(inspections[inspections.length - 1]);
    }
  }, [inspections]);

  const handleOrderInspection = async () => {
    setSendInspectionIsLoading(true);
    let isSuccessful = false;
    const request = createOrderInspectionSentRequest(selectedInspection, id);
    const resp = await inspectionHandler.handleUpdateInspection(request);
    if (resp) {
      setSelectedInspection(resp);
      isSuccessful = true;
    }
    setSendInspectionIsLoading(false);
    return isSuccessful;
  };

  const createNewInspection = async (values) => {
    setIsLoading(true);
    let isSuccessful = false;
    const request = createPostRequest(values, id);
    const resp = await inspectionHandler.handleCreateInspection(request);
    if (resp) {
      setSelectedInspection(resp);
      isSuccessful = true;
    }
    setIsLoading(false);
    return isSuccessful;
  };

  const handleInspectionFormSubmission = async (tab, values) => {
    setIsLoading(true);
    let isSuccessful = false;
    const request = createInspectionPutRequest(selectedInspection, values, id);
    const resp = await inspectionHandler.handleUpdateInspection(request);
    if (resp) {
      setSelectedInspection(resp);
      isSuccessful = true;
    }
    setIsLoading(false);
    return isSuccessful;
  };

  const handleVendorInfoFormSubmission = async (tab, values) => {
    setIsLoading(true);
    let isSuccessful = false;
    const request = createVendorInfoPutRequest(selectedInspection, values, id);
    const resp = await inspectionHandler.handleUpdateInspection(request);
    if (resp) {
      setSelectedInspection(resp);
      isSuccessful = true;
    }
    setIsLoading(false);
    return isSuccessful;
  };

  const handleDeleteSelectedInspection = async () => {
    if (!selectedInspection) return null;
    setIsDeleteLoading(true);
    const resp = await inspectionHandler.handleDeleteInspection(id, selectedInspection.inspectionId);
    const updatedInspections = await inspectionHandler.handleUpdateInspectionState(
      inspections.filter((s) => s.inspectionId !== selectedInspection.inspectionId),
    );

    if (updatedInspections?.length > 0) {
      setSelectedInspection(updatedInspections[updatedInspections.length - 1]);
    } else {
      setSelectedInspection(null);
    }

    setIsDeleteLoading(false);
    return resp;
  };

  const SendInspectionButton = ({ ...rest }) => {
    let buttonText = selectedInspection?.inspectionSent === true ? 'Re-order Inspection' : 'Order Inspection';
    return (
      <>
        {sendInspectionIsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 10px',
              }}
            > {!isReadOnly && 
                <Button
                  onClick={handleOrderInspection}
                  color="secondary"
                  variant="contained"
                  sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                >
                  {buttonText}
                </Button>
              }
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {isDeleteLoading ? (
        <Spinner />
      ) : (
        <>
          <InspectionsHeader
            inspections={inspections}
            vendors={vendors.filter((vendor) => vendor?.vendorType === 'Inspectors')}
            createNewInspectionFunc={createNewInspection}
            isLoading={isLoading}
            selectedInspection={selectedInspection}
            setSelectedInspection={setSelectedInspection}
            handleDeleteSelectedInspection={handleDeleteSelectedInspection}
            handleOrderInspection={handleOrderInspection}
            isReadOnly={isReadOnly}
          />
          {(inspections?.length ?? 0) === 0 ? (
            <div />
          ) : (
            <Box sx={{ paddingTop: '10px' }}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} xl={8}>
                  <EditableCard
                    tabs={createInspectionsFormTab()}
                    summaries={createInspectionsFormFields(selectedInspection)}
                    updateFields={handleInspectionFormSubmission}
                    isLoading={isLoading}
                    numColumns={5}
                    ButtonComponent={SendInspectionButton}
                    hideEdit={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} xl={4}>
                  <EditableCard
                    tabs={createVendorFormTab()}
                    summaries={createVendorInfoFormFields(selectedInspection, brokers, inspectors)}
                    updateFields={handleVendorInfoFormSubmission}
                    isLoading={isLoading}
                    hideEdit={isReadOnly}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
};

Inspections.propTypes = {
  inspectors: PropTypes.array.isRequired,
  brokers: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  vendors: PropTypes.array.isRequired,
  inspections: PropTypes.array,
};

export default compose(
  connect(null, {
    getInspections,
    postInspection,
    updateInspection,
    deleteInspection,
    updateInspectionState,
    showToast,
  }),
)(Inspections);
