/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'modules/intake/form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { periodOptions } from './utils';
import { withStyles } from '@material-ui/core';
import Hint from 'modules/common/hint.component';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    minWidth: 200,
  },
  tab: {
    width: 160,
    minWidth: 160,
  },
  tabsRoot: {
    marginBottom: theme.spacing(1),
  },
});  

const NewPeriodDates = (props) => {
  const formikProps = props.formikProps;
  const periodLength = periodOptions.find((o) => o.id === formikProps.values.calendarInfo.periodFrequencyId)?.numDays;
  const calendarYear = formikProps.values.calendarInfo.calendarYear;
  const today = new Date(`${calendarYear}-01-01`);
  let lastDay = new Date(`${calendarYear}-01-01`);
  lastDay.setDate(lastDay.getDate() + periodLength - 1);

  const periodStart = today.toISOString().slice(0, 10);
  const periodEnd = lastDay.toISOString().slice(0, 10);

  return (
    <Box marginX={2} marginY={2}>
      <Hint>Select the dates for the first period, the rest of the period dates will be calculated automatically. Individual dates can be changed later.</Hint>

      <h4>General</h4>
      <div>
        <Field
          component={TextField}
          name={'periodInfo.actualExpenseCutoffDate'}
          label={'Actual Expense Cutoff'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualExpenseCutoffDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.actualPayrollSentDate'}
          label={'Actual Payroll Sent'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualPayrollSentDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.actualPayrollStartDate'}
          label={'Actual Payroll Start'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualPayrollStartDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.actualReadyForAuditDate'}
          label={'Actual Ready For Audit'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualReadyForAuditDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.actualWageRequestReceivedDate'}
          label={'Actual Wage Request Received'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualWageRequestReceivedDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.expenseCodesAuditedDate'}
          label={'Expense Codes Audited'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.expenseCodesAuditedDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.expenseCutoffDate'}
          label={'Expense Cutoff'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.expenseCutoffDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.payrollReconciledThroughDate'}
          label={'Payroll Reconciled Through'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.payrollReconciledThroughDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.payrollSentDate'}
          label={'Payroll Sent'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.payrollSentDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.payrollStartDate'}
          label={'Payroll Start'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.payrollStartDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.readyForAuditDate'}
          label={'Ready for Audit'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.readyForAuditDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.reconciledDate'}
          label={'Reconciled'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.reconciledDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.taxAssistanceUploadedThroughDate'}
          label={'Tax Assistance Uploaded Through'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.taxAssistanceUploadedThroughDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
      </div>

      <h4>Wage Request</h4>
      <div>
        <Field
          component={TextField}
          name={'periodInfo.actualWageRequestPrepDate'}
          label={'Actual Wage Request Prep'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualWageRequestPrepDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.actualWageRequestSentDate'}
          label={'Actual Wage Request Sent'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualWageRequestSentDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.actualWageRequestUploadDate'}
          label={'Actual Wage Request Upload'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.actualWageRequestUploadDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.wageRequestPrepDate'}
          label={'Wage Request Prep'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.wageRequestPrepDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.wageRequestReceivedDate'}
          label={'Wage Request Received'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.wageRequestReceivedDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.wageRequestSentDate'}
          label={'Wage Request Sent'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.wageRequestSentDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'periodInfo.wageRequestUploadDate'}
          label={'Wage Request Upload'}
          className={props.classes.root}
          value={formikProps.values.periodInfo.wageRequestUploadDate}
          type="date"
          max={periodEnd}
          min={periodStart}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
      </div>
    </Box>
  );
};

NewPeriodDates.propTypes = {
  selectedPeriodData: PropTypes.object,
  periodDatesList: PropTypes.array,
};


// const mapStateToProps = (state) => {
//   const { clientCalendars, isLoading, periodData } = state.clients;
//   return {
//     clientCalendars,
//     isLoading,
//     periodData,
//   };
// };
  
export default compose(
  withStyles(styles),
  connect(null, {
  }),
)(NewPeriodDates);