/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';

import { Box, Button, Card, CardContent, Paper, TableRow, Typography } from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import React, { Fragment, useEffect, useState } from 'react';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../layout/types';

import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import { Link } from 'react-router-dom';
import { getMonth } from '../../common/utils';
import { getVendorHistory, getVendors, updateVendor, getAllVendorAuthNotes } from 'modules/authorizations/store/actions/dataManagement.actions';
import {
  GET_VENDOR_HISTORY_FAILURE,
  GET_VENDORS_FAILURE,
  UPDATE_VENDORS_FAILURE
} from 'modules/authorizations/store/types/dataManagementTypes';
import { showToast } from 'modules/layout/layout.actions';
import ExpensesModal from './expensesModal.component';
import { GET_EXPENSE_LINES_FAILURE, GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { getExpenseLines, getVoucherSummaries } from 'modules/expenses/expenses.actions';
import NotesModal from './notesModal.component';

const styles = theme => ({
  wideControl: {
    minWidth: 174
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  selfServicePolicySelect: {
    minWidth: 300
  }
});

const VendorHistoryHeader = ({ details, updateStatus }) => {
  let contractDate = new Date(details?.contractDate);
  let contractExpiresDate = new Date(details?.contractExpires);

  return (
    <Card sx={{ marginBottom: '20px' }}>
      <CardContent sx={{ padding: '16px !important' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Vendor ID
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.vendorId}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Company Name
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.companyName}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Vendor Type
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.vendorType}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Status
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.status}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Contract Date
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                <Link>{`${getMonth(contractDate.getMonth())} ${contractDate.getDate()} ${contractDate.getFullYear()}`}</Link>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Contract Expires
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                <Link>{`${getMonth(
                  contractExpiresDate.getMonth()
                )} ${contractExpiresDate.getDate()} ${contractExpiresDate.getFullYear()}`}</Link>
              </Typography>
            </Box>
          </Box>

          <Button
            color="secondary"
            variant="contained"
            sx={{
              maxHeight: '40px',
              marginLeft: '12px',
              marginTop: '8px',
              backgroundColor: '#ffffff',
              '&:hover': { backgroundColor: '#faf8f2' },
              color: '#080600'
            }}
            onClick={updateStatus}
          >
            {details?.status === 'Active' ? 'Set As Inactive' : 'Set As Active'}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const VendorHistoryView = props => {
  const [details, setDetails] = useState(props.vendors ? props.vendors.find(v => v.vendorId === parseInt(props.match.params.id)) : null);
  const [history, setHistory] = useState([]);
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [selectedAuthorizationId, setSelectedAuthorizationId] = useState();

  const handleOpenModal = authId => {
    setSelectedAuthorizationId(authId);
    setIsExpenseModalOpen(true);
  };

  const handleOpenNotesModal = authId => {
    setSelectedAuthorizationId(authId);
    setIsNotesModalOpen(true);
  }

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsExpenseModalOpen(false);
  };

  const updateStatus = async () => {
    let newStatus = details?.status === 'Active' ? 'Inactive' : 'Active';
    let request = { ...details, status: newStatus, statusLastUpdated: new Date().toISOString() };
    let response = await props.updateVendor(request);
    if (response.type === UPDATE_VENDORS_FAILURE) {
      props.showToast('Failed to update vendor, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setDetails(response.response);
    }
  };

  const renderHeaderRow = row => {
    return (
      <TableRow sx={{ backgroundColor: '#41B7C0', '& .MuiButtonBase-root': { color: 'white !important' } }}>
        {row.children.map(c => {
          return c;
        })}
      </TableRow>
    );
  };

  const formatDate = record => {
    let date = new Date(record.dateTimeStamp);
    // const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
    return (
      <Fragment key={record.dateTimeStamp}>
        <div>{`${getMonth(date.getMonth())} ${date.getDate()} ${date.getFullYear()}`}</div>
      </Fragment>
    );
  };

  const seeNotesButton = record => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <Box sx={{marginRight: '10px'}}>
          <Fragment key={record.date}>
            <Button color="primary" variant="contained" sx={{ backgroundColor: '#36939b', '&:hover': { backgroundColor: '#15585e' } }} onClick={() => handleOpenNotesModal(record.authorizationId)}
            >
              See Notes
            </Button>
          </Fragment>
        </Box>
        <Box>
          <Fragment key={record.date}>
            <Button
              color="primary"
              variant="contained"
              sx={{ backgroundColor: '#36939b', '&:hover': { backgroundColor: '#15585e' } }}
              onClick={() => handleOpenModal(record.authorizationId)}
            >
              See Expenses
            </Button>
          </Fragment>
        </Box>
      </Box>
    );
  };

  const [sorting, setSorting] = useState([{ columnName: 'date', direction: 'desc' }]);
  const [isLoading, setIsLoading] = useState(false);

  const [columns] = useState([
    { name: 'dateTimeStamp', title: 'Date', getCellValue: formatDate },
    { name: 'clientName', title: 'Client' },
    { name: 'authorizationId', title: 'Auth Id' },
    { name: 'fullName', title: 'Full Name' },
    { name: 'serviceType', title: 'Service Type' },
    { name: 'serviceArea', title: 'Area' },
    { name: 'notes', title: '', getCellValue: seeNotesButton },
    // { name: 'expenses', title: 'Expenses', getCellValue: seeExpensesButton }
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'dateTimeStamp', width: 100 },
    { columnName: 'authorizationId', width: 100 },
    { columnName: 'serviceType', width: 150 },
    { columnName: 'fullName', width: 200 },
    { columnName: 'serviceArea', width: 300 }
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [pageSizes] = useState([15, 20, 25]);
  const [filters, setFilters] = useState([]);

  const filterCell = (props) => {
    return props.column.name !== 'notes' ? <TableFilterRow.Cell {...props} /> : null;
  };

  const headerCell = (props) => {
    return props.column.name !== 'notes' ? <TableHeaderRow.Cell {...props} /> : <TableFilterRow.Cell />;
  };

  // const [logs] = useState([{date: new Date(), client: 'Demo Client', authId: '99999-119', fullName: 'Sample User', serviceType: 'Household Goods', area: 'Shipment of Goods'}]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      let { type, response } = await props.getVendors();
      if (type === GET_VENDORS_FAILURE) {
        props.showToast('Failed to retrieve vendors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        setDetails(response.find(v => v.vendorId === parseInt(props.match.params.id)));
      }

      let historyResp = await props.getVendorHistory(props.match.params.id);
      if (historyResp.type === GET_VENDOR_HISTORY_FAILURE) {
        props.showToast('Failed to retrieve vendor history, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        setHistory(historyResp.response);
      }

      if (!props.voucherSummaries) {
        let { type } = await props.getVoucherSummaries();
        if (type === GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE) {
          props.showToast('Failed to retrieve vouchers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!props.expenseLines) {
        let { type } = await props.getExpenseLines();
        if (type === GET_EXPENSE_LINES_FAILURE) {
          props.showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!props.vendorAuthNotes) {
        let { type } = await props.getAllVendorAuthNotes();
        if (type === GET_EXPENSE_LINES_FAILURE) {
          props.showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <Box>
      {!isLoading && details && history && (
        <Box sx={{ padding: 3 }}>
          <VendorHistoryHeader details={details} updateStatus={updateStatus} />

          <Paper>
            <Grid rows={history.sort((a, b) => new Date(b.date) - new Date(a.date))} columns={columns}>
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <FilteringState filters={filters} onFiltersChange={setFilters} />
              <IntegratedFiltering />
              <IntegratedPaging />
              <Table columnExtensions={tableColumnExtensions} />
              <TableHeaderRow rowComponent={renderHeaderRow} cellComponent={headerCell} showSortingControls />
              <TableFilterRow cellComponent={filterCell} />
              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          </Paper>
        </Box>
      )}
      {isLoading && <FullscreenSpinner />}
      <ExpensesModal
        isExpenseModalOpen={isExpenseModalOpen}
        voucherSummaries={props?.voucherSummaries}
        expenseLines={props?.expenseLines}
        authorizationId={selectedAuthorizationId}
        vendorId={details?.vendorId}
        handleCloseModal={handleCloseModal}
        history={props.history}
      />
      <NotesModal
        isNotesModalOpen={isNotesModalOpen}
        authorizationId={selectedAuthorizationId}
        vendorNotes={props?.vendorAuthNotes}
        handleCloseNotesModal={handleCloseNotesModal}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  const { authorizationDetails, vendorAuthNotes, isLoading } = state.authorizations;
  const { voucherSummaries, expenseLines } = state.expenses;
  return {
    authorizationDetails,
    isLoading,
    voucherSummaries,
    vendorAuthNotes,
    expenseLines
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getVendors,
    showToast,
    updateVendor,
    getVendorHistory,
    getVoucherSummaries,
    getExpenseLines,
    getAllVendorAuthNotes,
  })
)(VendorHistoryView);
