/* eslint-disable no-useless-computed-key */
import { MenuItem, TextField, withStyles } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

import { GET_CLIENTS_FAILURE, GET_INTERNAL_USERS_FAILURE } from './types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getClients, getInternalUsers } from './clients.actions';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import { grabFullName } from './utils';
import ClientsGrid from './clientsGrid.component';
import Log from 'utilities/log';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
});

class ClientsView extends PureComponent {
  state = {
    managerId: 'All Managers',
    relocationConsultantId: -1,
    addingNoteForRecord: null,
    isAddingNote: false,
    reassigningRecord: null,
    deletingRecord: null,
    isReassigningRecord: false,
    isDeletingRecord: false,
    currentUserHasClients: false,
    initialLoad: false,
  };

  async componentDidMount() {
    this.props.setPageTitle('My Clients');
    this.setState({ initialLoad: true });

    if (!this.props.internalUsers) {
      let { type } = await this.props.getInternalUsers();
      if (type === GET_INTERNAL_USERS_FAILURE) {
        this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.unmappedClients) {
      let { type } = await this.props.getClients();
      if (type === GET_CLIENTS_FAILURE) {
        this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } 
    }

    // Test first request to Intake API V2
    // await this.props.getTestApiV2();
  }

  currentUserHasClients = (managers, curUser) => {
    return managers.some((m) => m.toLowerCase() === curUser);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  filterClients = (clients, managerId, clientStatus) => {
    return clients.filter((client) => {
      return ((managerId === 'All Managers' || (managerId === 'My Clients' && client.serviceRep?.toLowerCase() === this.props.profile?.moveTrackUsername) 
      || (managerId !== 'My Clients' && client.serviceRep === managerId)));
    });
  };

  render() {
    Log.trace('RENDER', 'ClientsView');
    const { managerId, initialLoad } = this.state;
    const { classes, unmappedClients, managerOptions, profile, isLoadingAuths, isLoadingClients, internalUsers, history } = this.props;

    const filteredClients = this.filterClients(unmappedClients || [], managerId);
    const sortedManagers = managerOptions ? managerOptions : [];

    const currentUserHasClients = profile && managerOptions && this.currentUserHasClients(managerOptions, profile.moveTrackUsername);

    if (initialLoad && currentUserHasClients) {
      this.setState({ managerId: 'My Clients', initialLoad: false });
    }

    return (
      <Fragment>
        <div className={classes.headerContainer}>
          <TextField
            select
            label="CSM/CSS"
            className={classes.textField}
            value={managerId}
            onChange={this.handleChange('managerId')}
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            {internalUsers && currentUserHasClients && (
              <MenuItem value={'My Clients'}>
                <em>My Clients ({grabFullName(internalUsers, profile.moveTrackUsername)})</em>
              </MenuItem>
            )}
            <MenuItem value={'All Managers'}>
              <em>All CSM/CSS Clients</em>
            </MenuItem>
            {internalUsers && sortedManagers && sortedManagers.map((option) => {
              return (
                <MenuItem value={option}>
                  {grabFullName(internalUsers, option)}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div className="px-2">
          <ClientsGrid
            clients={filteredClients}
            internalUsers={internalUsers}
            isLoading={isLoadingClients || isLoadingAuths}
            history={history}
          />
        </div>
      </Fragment>
    );
  }
}

ClientsView.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clients: PropTypes.object,
  unmappedClients: PropTypes.array,
  getClients: PropTypes.func.isRequired,
  getInternalUsers: PropTypes.func.isRequired,
  isLoadingClients: PropTypes.bool.isRequired,
  isLoadingAuths: PropTypes.bool.isRequired,
  showToast: PropTypes.func.isRequired,
  profile: PropTypes.object,
  managerOptions: PropTypes.array,
  internalUsers: PropTypes.array,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapStateToProps = (state) => {
  const {
    intake: { ['isLoading']: isLoadingAuths },
    clients: { ['isLoading']: isLoadingClients, clients, unmappedClients, managerOptions, internalUsers },
    auth: { profile },
  } = state;
  return { isLoadingClients, isLoadingAuths, clients, unmappedClients, managerOptions, profile, internalUsers };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    getClients,
    showToast,
    getInternalUsers,
    getTestApiV2,
  }),
)(ClientsView);