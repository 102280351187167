import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const SplitShipmentCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo, removeSplitShipment } = props;

  const tabs = [
    { header: 'Split Shipment', order: 1, iconPath: ['fas', 'arrows-split-up-and-left'] },
  ];

  const summaries = [
    { header: 'Line One', accessorKey: 'splitAddressOne', type: 'text', required: false, value: segmentInfo ? segmentInfo.splitAddressOne : '', tab: 1 },
    { header: 'Line Two', accessorKey: 'splitAddressTwo', type: 'text', required: false, value: segmentInfo ? segmentInfo.splitAddressTwo : '', tab: 1 },
    { header: 'City', accessorKey: 'splitCity', type: 'text', required: false, value: segmentInfo ? segmentInfo.splitCity : '', tab: 1 },
    { header: 'State', accessorKey: 'splitState', type: 'text', required: false, value: segmentInfo ? segmentInfo.splitState : '', tab: 1 },
    { header: 'Zip', accessorKey: 'splitZip', type: 'text', required: false, value: segmentInfo ? segmentInfo.splitZip : '', tab: 1 },
    { header: 'County', accessorKey: 'splitCounty', type: 'text', required: false, value: segmentInfo ? segmentInfo.splitCounty : '', tab: 1 },
    { header: 'Country', accessorKey: 'splitCountry', type: 'text', required: false, value: segmentInfo ? segmentInfo.splitCountry : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.splitAddressOne = values.splitAddressOne;
    newValues.splitAddressTwo = values.splitAddressTwo;
    newValues.splitCity = values.splitCity;
    newValues.splitState = values.splitState;
    newValues.splitZip = values.splitZip;
    newValues.splitCounty = values.splitCounty;
    newValues.splitCountry = values.splitCountry;

        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      removeButton={true}
      removeButtonAction={removeSplitShipment}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(SplitShipmentCard);