import { object, string } from 'yup';

import { MAX_LENGTH_ERROR_MESSAGE, plusMinusFiveYearsDateSchema } from 'modules/common/constants';
import { calculateDuration, formatDateForInput, formatForWire, parseNumbers } from 'utilities/common';

export default class TemporaryLivingBenefits {
  static schema = object().shape({
    estimatedStartDate: plusMinusFiveYearsDateSchema,
    estimatedEndDate: plusMinusFiveYearsDateSchema,
    costCap: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.estimatedStartDate = formatDateForInput(json.estimatedStartDate);
    this.estimatedEndDate = formatDateForInput(json.estimatedEndDate);
    this.tempLivingStayLength = calculateDuration(this.estimatedStartDate, this.estimatedEndDate) || '';
    this.costCap = json.costCap === null ? 0 : json.costCap;
  }

  static fromWireData(wireData) {
    return new TemporaryLivingBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      estimatedStartDate: this.estimatedStartDate,
      estimatedEndDate: this.estimatedEndDate,
      tempLivingStayLength: this.tempLivingStayLength,
      ...parseNumbers({
        costCap: this.costCap,
      }),
    });
  }
}
