import { POST_HOMESALE_INFO_FAILURE, UPDATE_HOMESALE_INFO_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

export const useBuyoutHandler = ({ updateHomesaleInfo, postHomesaleInfo, showToast }) => {
  
  const handleUpdateHomesaleInfo = async (values) => {
    try {
      const resp = await updateHomesaleInfo(values);
      if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
        showToast('Failed to update homesale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while updating homesale info.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleCreateHomesaleInfo = async (values) => {
    try {
      const resp = await postHomesaleInfo(values);
      if (resp.type === POST_HOMESALE_INFO_FAILURE) {
        showToast('Failed to create homesale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while creating homesale info.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return {
    handleUpdateHomesaleInfo,
    handleCreateHomesaleInfo,
  };
};
