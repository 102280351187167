/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, MenuItem, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 400,
    },
    firstCol: {
      width: 200,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleGboAppraisalsOffer = (props) => {

  const data = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleGboAppraisalsOffer : null;
  
  const [isEnabled, setIsEnabled] = useState(data ? data.isEnabled : false);
  const [mandatoryMarketingPeriod, setMandatoryMarketingPeriod] = useState(data ? data.mandatoryMarketingPeriod : null);
  const [orderAppraisalOn, setOrderAppraisalOn] = useState(data ? data.orderAppraisalOn : null);
  const [guaranteedOfferDetermination, setGuaranteedOfferDetermination] = useState(data ? data.guaranteedOfferDetermination : null); 
  const [guaranteedOfferExtendedToEmployeeOn, setGuaranteedOfferExtendedToEmployeeOn] = useState(data ? data.guaranteedOfferExtendedToEmployeeOn : null);
  const [whenInspectionsOrdered, setWhenInspectionsOrdered] = useState(data ? data.whenInspectionsOrdered : null);
  const [responsibleForInspections, setResponsibleForInspections] = useState(data ? data.responsibleForInspections : null);
  const [responsibleForAdditionalRepairs, setResponsibleForAdditionalRepairs] = useState(data ? data.responsibleForAdditionalRepairs : null);
  const [otherNotes, setOtherNotes] = useState(data ? data.otherNotes : null);
  const [incompleteInspections, setIncompleteInspections] = useState(data ? data.incompleteInspections : null);
  const [requireOfferAcceptance, setRequireOfferAcceptance] = useState(data ? data.requireOfferAcceptance : null);
  const [acceptancePeriod, setAcceptancePeriod] = useState(data ? data.acceptancePeriod : null);
  const [order3rdAppraisal, setOrder3rdAppraisal] = useState(data ? data.order3rdAppraisal : null);
  const [initiateDetailedInspections, setInitiateDetailedInspections] = useState(data ? data.initiateDetailedInspections : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      IsEnabled: isEnabled,
      MandatoryMarketingPeriod: mandatoryMarketingPeriod,
      OrderAppraisalOn: orderAppraisalOn,
      GuaranteedOfferDetermination: guaranteedOfferDetermination,
      GuaranteedOfferExtendedToEmployeeOn: guaranteedOfferExtendedToEmployeeOn,
      WhenInspectionsOrdered: whenInspectionsOrdered,
      ResponsibleForInspections: responsibleForInspections,
      ResponsibleForAdditionalRepairs: responsibleForAdditionalRepairs,
      OtherNotes: otherNotes,
      IncompleteInspections: incompleteInspections,
      RequireOfferAcceptance: requireOfferAcceptance,
      AcceptancePeriod: acceptancePeriod,
      Order3rdAppraisal: order3rdAppraisal,
      InitiateDetailedInspections: initiateDetailedInspections,
    };
    
    if (data && data.clientHomesaleGboAppraisalsOfferId) updateBody.ClientHomesaleGboAppraisalsOfferId = data.clientHomesaleGboAppraisalsOfferId;

    props.updateInfo(updateBody);
  }, [isEnabled, mandatoryMarketingPeriod, orderAppraisalOn, guaranteedOfferDetermination, guaranteedOfferExtendedToEmployeeOn, whenInspectionsOrdered, responsibleForInspections, responsibleForAdditionalRepairs, otherNotes, incompleteInspections, 
    requireOfferAcceptance, acceptancePeriod, order3rdAppraisal, initiateDetailedInspections]);

  return (
    <div className={props.classes.contractsGrid}>
      <div className={props.classes.headerRow}>
        <h3>GBO Appraisals/Guaranteed Offer and Inspections</h3>
        <FormControlLabel
          control={
            <Checkbox
              name="activateService"
              label="Activate Service"
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
          }
          label="Activate Service"
          classes={{ root: props.classes.checkbox }}
        />
      </div>
      {isEnabled && (
        <div className={props.classes.tabBorder}>
          <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
            <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>              
              <p className={props.classes.descText}>What is the mandatory marketing period? (This is the period of time the employee must market the home)</p>
              <TextField
                id="name"
                label="Mandatory Marketing Period"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={mandatoryMarketingPeriod}
                onChange={(e) => setMandatoryMarketingPeriod(e.target.value)}
              />
              <p className={props.classes.descText}>On what day should appraisals be ordered? (Recommend ordering 2 weeks before the offer is extended. The offer is recommended to be extended towards the end of the required marketing period)?</p>
              <TextField
                id="name"
                label="Order Appraisal On"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={orderAppraisalOn}
                onChange={(e) => setOrderAppraisalOn(e.target.value)}
              />
              <p className={props.classes.descText}>TRC will order 2 appraisals and ensure the variance is within 5%. If they are outside of that variance, should a 3rd be ordered?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Order 3rd Appraisal'}
                onChange={(e) => setOrder3rdAppraisal(e.target.value)}
                required
                select
                value={order3rdAppraisal}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'na'}>N/A</MenuItem>
              </TextField>
              <p className={props.classes.descText}>If a 3rd is ordered, how will the Guaranteed Offer be determined? (Recommended: Average the 2 closest – Other options: average 2 highest, 2 closest, or all 3)</p>
              <TextField
                id="name"
                label="Guaranteed Offer Determination"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={guaranteedOfferDetermination}
                onChange={(e) => setGuaranteedOfferDetermination(e.target.value)}
              />
              <p className={props.classes.descText}>If inspections are ordered up front and the transferee has satisfied the repair requirements who would be responsible for any additional repairs resulting from buyer’s inspections? (Recommend not offering until near the buyout)</p>
              <TextField
                id="name"
                label="Who Is Responsible For Additional Repairs"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={responsibleForAdditionalRepairs}
                onChange={(e) => setResponsibleForAdditionalRepairs(e.target.value)}
              />
          
              <p className={props.classes.descText}>Initiate detailed inspections</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Initiate Detailed Inspections'}
                onChange={(e) => setInitiateDetailedInspections(e.target.value)}
                required
                select
                value={initiateDetailedInspections}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </TextField>  
            </div>

            <div className={props.classes.contentCol}>
              <p className={props.classes.descText}>When is the guaranteed offer extended to the employee? At what day in the process?</p>
              <TextField
                id="name"
                label="Guaranteed Offer Extended To Employee On"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={guaranteedOfferExtendedToEmployeeOn}
                onChange={(e) => setGuaranteedOfferExtendedToEmployeeOn(e.target.value)}
              />
              <p className={props.classes.descText}>What is the acceptance period?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Acceptance Period'}
                onChange={(e) => setAcceptancePeriod(e.target.value)}
                required
                select
                value={acceptancePeriod}
              >
                <MenuItem value={30}>30 Days</MenuItem>
                <MenuItem value={60}>60 Days</MenuItem>
                <MenuItem value={90}>90 Days</MenuItem>
              </TextField>  
              
              <p className={props.classes.descText}>If a bona fide offer is presented, is there an acceptance requirement if the offer is within a specific range of the Guaranteed Offer?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Require Offer Acceptance'}
                onChange={(e) => setRequireOfferAcceptance(e.target.value)}
                required
                select
                value={requireOfferAcceptance}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'na'}>N/A</MenuItem>
              </TextField>  

              <p className={props.classes.descText}>When are inspections ordered? (Recommend to order near date appraisals are ordered)</p>
              <TextField
                id="name"
                label="Inspections Ordered On"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenInspectionsOrdered}
                onChange={(e) => setWhenInspectionsOrdered(e.target.value)}
              />
              <p className={props.classes.descText}>Who is responsible for the inspections? (Typically employee)</p>
              <TextField
                id="name"
                label="Who Is Responsible For Inspections"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={responsibleForInspections}
                onChange={(e) => setResponsibleForInspections(e.target.value)}
              />

              
              <p className={props.classes.descText}>If inspections cannot be completed due to time of year, how should TRC proceed?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Incomplete Inspections'}
                onChange={(e) => setIncompleteInspections(e.target.value)}
                required
                select
                value={incompleteInspections}
              >
                <MenuItem value={0}>Use disclosures and other inspections to indicate potential problems</MenuItem>
                <MenuItem value={1}>Have the transferee sign inability to inspection document stating everything is in working order</MenuItem>
                <MenuItem value={2}>Other</MenuItem>
              </TextField> 

              <TextField
                variant="outlined"
                label={'Other Notes'}
                value={otherNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
            </div>
          </form>  
        </div>
      )}
    </div>
  );
}; 

HomeSaleGboAppraisalsOffer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleGboAppraisalsOffer);