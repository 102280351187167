import { Accordion, AccordionDetails, AccordionSummary, Box, TextField as MuiTextField, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { memoize } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
import countries from 'modules/common/countries.json';
import states from 'modules/common/states.json';

    
import { DELETE_CLIENT_CONTACT_FAILURE, GET_CLIENT_CONTACTS_FAILURE, POST_CLIENT_CONTACT_FAILURE, UPDATE_CLIENT_CONTACT_FAILURE } from '../../types';
import { deleteClientContact, getClientContacts, postClientContact, updateClientContact } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientContactsTable = (props) => {
  const [clientOverview, setClientOverview] = useState(false);
  const theme = useTheme();

  const renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    let allStates = states.filter((state) => state.countryCode === useCountryCode)
      .map(( state ) => (
        state.description
      ));
    return allStates;
  });

  const renderCountryOptions = memoize(() => {
    let allCountries = countries.map(( country ) => (
      country.description
    ));
    return allCountries;
  });

  const adjustedColumns = [
    { accessorKey: 'firstName', header: 'First Name', type: 'text', required: true, size: 70, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'lastName', header: 'Last Name', type: 'text', required: true, size: 70, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'primaryContact', header: 'Primary Contact', type: 'checkbox', hideInTable: true },
    { accessorKey: 'officeEmail', header: 'Work Email', type: 'text', required: true, size: 80, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'jobTitle', header: 'Job Title', type: 'text', required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'status', header: 'Status', type: 'dropdown', options: ['I', 'A'], size: 30, required: true, enableColumnDragging: false, enableResizing: false, filterVariant: 'select',
      filterSelectOptions: [{ text: 'Active', value: 'A' }, { text: 'Inactive', value: 'I' }] },
    { accessorKey: 'officePhone', header: 'Work Phone', type: 'text', required: true, size: 60, enableColumnActions: false, enableColumnDragging: false, enableResizing: false },
    { accessorKey: 'altPhone1', header: 'Mobile Phone', type: 'text', hideInTable: true },
    { accessorKey: 'description', header: 'Contact Type', type: 'text', required: true, hideInTable: true },
    { accessorKey: 'address1', header: 'Work Address 1', type: 'text', hideInTable: true },
    { accessorKey: 'address2', header: 'Work Address 2', type: 'text', hideInTable: true },
    { accessorKey: 'relosourceAccess', header: 'ReloSource Access?', type: 'dropdown', options: ['Yes', 'No'], hideInTable: true },
    { accessorKey: 'city', header: 'Work City', type: 'text', hideInTable: true },
    { accessorKey: 'state', header: 'Work State', type: 'dropdown', options: renderStateOptions(''), hideInTable: true },
    { accessorKey: 'reportingAccess', header: 'RS Reporting Access?', type: 'dropdown', options: ['Yes', 'No'], hideInTable: true },
    { accessorKey: 'zipCode', header: 'Work Postal Code', type: 'text',  hideInTable: true },
    { accessorKey: 'country', header: 'Work Country', type: 'dropdown', options: renderCountryOptions(), hideInTable: true },
    { accessorKey: 'authorizer', header: 'Authorizer', type: 'dropdown', options: ['Yes', 'No'], hideInTable: true },
    { accessorKey: 'divisionResponsibility', header: 'Division/Responsibility', type: 'text', hideInTable: true },
    { accessorKey: 'industryInfo', header: 'Industry Info?', type: 'dropdown', options: ['Yes', 'No'], hideInTable: true },
    { accessorKey: 'onlineAuthContact', header: 'Exception Approver', type: 'dropdown', options: ['Yes', 'No'], hideInTable: true },
    { accessorKey: 'disabled1', header: 'User 1', type: 'text', hideInTable: true, disabled: true },
    { accessorKey: 'companyNews', header: 'Company News?', type: 'dropdown', options: ['Yes', 'No'], hideInTable: true },
    { accessorKey: 'pullsToInvoices', header: 'Pulls to Invoices?', type: 'dropdown', options: ['Yes', 'No'], hideInTable: true },
    { accessorKey: 'disabled2', header: 'User 2', type: 'text', hideInTable: true, disabled: true },
    { accessorKey: 'disabled3', header: 'User 3', type: 'text', hideInTable: true, disabled: true },
    { accessorKey: 'authApprovalRouting', header: 'Auth Approval Routing', type: 'text', hideInTable: true },
    { accessorKey: 'disabled4', header: 'User 4', type: 'text', hideInTable: true, disabled: true },
    { accessorKey: 'disabled5', header: 'User 5', type: 'text', hideInTable: true, disabled: true },
    { accessorKey: 'exceptionApprovalRouting', header: 'Exception Approval Routing', type: 'text', hideInTable: true },

    { accessorKey: 'contactNotes', header: 'Contact Notes', type: 'notes', hideInTable: true },


    { accessorKey: 'addedOn', header: 'Added On', type: 'date', hideInTable: true, disabled: true },
    { accessorKey: 'addedBy', header: 'Added By', type: 'text', hideInTable: true, disabled: true },
    { accessorKey: 'modifiedOn', header: 'Modified On', type: 'date', hideInTable: true, disabled: true },
    { accessorKey: 'modifiedBy', header: 'Modified By', type: 'text', hideInTable: true, disabled: true },
  ];

  const getPrimaryContact = (clientContacts) => {
    return clientContacts.find((contact) => contact.primaryContact === true);
  };




  const displayPrimaryContact = (clientContacts) => {
    let primaryContact = getPrimaryContact(clientContacts);
    if (primaryContact){
      return (
        <div className={props.classes.primaryContactRow}>
          <h3 className={props.classes.primaryText}>Primary Contact:</h3>

          <div className={props.classes.inputGroup}>
            <MuiTextField
              type="text"
              name="firstName"
              label="First Name"
              variant="outlined"
              value={primaryContact.firstName}
              className={props.classes.root}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <MuiTextField
              type="text"
              name="lastName"
              label="Last Name"
              variant="outlined"
              value={primaryContact.lastName}
              className={props.classes.root}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <MuiTextField
              type="text"
              name="officePhone"
              label="Work Phone"
              variant="outlined"
              value={primaryContact.officePhone}
              className={props.classes.root}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <MuiTextField
              type="text"
              name="officeEmail"
              label="Work Email"
              variant="outlined"
              value={primaryContact.officeEmail}
              className={props.classes.root}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <MuiTextField
              type="text"
              name="jobTitle"
              label="Job Title"
              variant="outlined"
              value={primaryContact.jobTitle}
              className={props.classes.root}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </div>
        </div>
      );
    }
  };

  const updateRow = async (contact, values) => {
    const id = contact.original.clientContactKey;
    values.clientContactKey = id;
    values.clientId = props.clientId;
    const resp = await props.updateClientContact(values);
    if (resp.type === UPDATE_CLIENT_CONTACT_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContacts(props.clientId);
      if (getResp.type === GET_CLIENT_CONTACTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postClientContact(values);
    if (resp.type === POST_CLIENT_CONTACT_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContacts(props.clientId);
      if (getResp.type === GET_CLIENT_CONTACTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (contact) => {
    const id = contact.original.clientContactKey;
    const resp = await props.deleteClientContact(id);
    if (resp.type === DELETE_CLIENT_CONTACT_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContacts(props.clientId);
      if (getResp.type === GET_CLIENT_CONTACTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const validateEmail = (email) => {
    return !!email.length && email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const customValidator = (cell, value) => {
    return cell.column.columnDef.type === 'email' ? validateEmail(value) : true;
  };

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Contacts</h2>
          { displayPrimaryContact(props.clientContacts) }
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientContactsLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.clientContacts} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
            customValidator={customValidator} 
            initialState={{
              columnFilters: [
                {
                  id: 'status',
                  value: 'A',
                },
              ],
            }}
          />
        )}
        {props.clientContactsLoading && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

ClientContactsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientContactsLoading: PropTypes.bool,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
  postClientContact: PropTypes.func,
  updateClientContact: PropTypes.func,
  getClientContacts: PropTypes.func,
  deleteClientContact: PropTypes.func,
  clientContacts: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { clientContacts } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientContactsLoading } = state.clients;
  return {
    clientContacts,
    clientContactsLoading,
  };
};
  
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientContacts,
    deleteClientContact,
    postClientContact,
    updateClientContact,
  }),
)(ClientContactsTable);