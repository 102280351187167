import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { DELETE_CLIENT_CONTRACT_FEE_FAILURE, GET_CLIENT_CONTRACT_FEE_FAILURE, POST_CLIENT_CONTRACT_FEE_FAILURE, UPDATE_CLIENT_CONTRACT_FEE_FAILURE } from '../../types';
import { deleteClientContractFees, getClientContractFees, postClientContractFees, updateClientContractFees } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';


const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientContractsFeesTable = (props) => {
  const [clientOverview, setClientOverview] = useState(false);
  const theme = useTheme();

  const adjustedColumns = [
    { accessorKey: 'feeId', header: 'Fee Id', type: 'text', enableColumnDragging: false, enableColumnActions: false, enableResizing: false, hideInTable: true, disabled: true },
    { accessorKey: 'feeType', header: 'Fee Type', type: 'dropdown', options:['Home Sale', 'Homeowner no HS', 'Renter', 'Lump Sum', 'HMA', 'STA', 'LTA', 'International', 'Tracking Only', 'Candidate'], size: 80, required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'effectiveDate', header: 'Effective Date', type: 'date', size: 60, required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us')  },
    { accessorKey: 'endDate', header: 'End Date', type: 'date', size: 60, enableColumnDragging: false, enableColumnActions: false, enableResizing: false, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us') },
    { accessorKey: 'amount', header: 'Amount', type: 'text', required: true, size: 100, enableColumnDragging: false, enableColumnActions: false, enableResizing: false, Cell: ({ cell }) => `$${cell.getValue()}` },
    { accessorKey: 'notes', header: 'Notes', type: 'notes', size: 125, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },

  ];

  const assignFeeIdValue = (values) => {
    switch (values.feeType) {
      case 'Home Sale':
        values.feeId = 0;
        break;
      case 'Homeowner no HS':
        values.feeId = 1;
        break;
      case 'Renter':
        values.feeId = 2;
        break;
      case 'Lump Sum':
        values.feeId = 3;
        break;
      case 'HMA':
        values.feeId = 4;
        break;
      case 'STA':
        values.feeId = 5;
        break;
      case 'LTA':
        values.feeId = 6;
        break;
      case 'International':
        values.feeId = 7;
        break;
      case 'Tracking Only':
        values.feeId = 8;
        break;
      case 'Candidate':
        values.feeId = 9;
        break;
      default:
        values.feeId = 0;
        break;
    }
    return values.feeId;
  };

  const updateRow = async (contractsFee, values) => {
    const id = contractsFee.original.clientContractsFeesKey;
    values.clientContractsFeesKey = id;
    values.clientId = props.clientId;
    values.feeId = assignFeeIdValue(values);
    const resp = await props.updateClientContractFees(values);
    if (resp.type === UPDATE_CLIENT_CONTRACT_FEE_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractFees(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_FEE_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    values.feeId = assignFeeIdValue(values);
    const resp = await props.postClientContractFees(values);
    if (resp.type === POST_CLIENT_CONTRACT_FEE_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractFees(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_FEE_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (contractsFee) => {
    const id = contractsFee.original.clientContractsFeesKey;
    const resp = await props.deleteClientContractFees(id);
    if (resp.type === DELETE_CLIENT_CONTRACT_FEE_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientContractFees(props.clientId);
      if (getResp.type === GET_CLIENT_CONTRACT_FEE_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Fees</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientContractsFeesLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.clientContractsFees} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {props.clientContractsFeesLoading && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

ClientContractsFeesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientContractsFeesLoading: PropTypes.bool,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
  postClientContractFees: PropTypes.func,
  updateClientContractFees: PropTypes.func,
  getClientContractFees: PropTypes.func,
  deleteClientContractFees: PropTypes.func,
  clientContractsFees: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { clientContractsFees } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientContractsFeesLoading } = state.clients;
  return {
    clientContractsFees,
    clientContractsFeesLoading,
  };
};
  
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientContractFees,
    deleteClientContractFees,
    postClientContractFees,
    updateClientContractFees,
  }),
)(ClientContractsFeesTable);