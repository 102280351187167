/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummaries, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress, getEmployeeInfo, getFamilyMembers, getFamilyPets, getServices, getTeam, getUserSettings, setAuthId } from '../../store/actions/authorizations.actions';
import { getExpenseLinesByAuthId, getExpenseSummariesByAuth } from 'modules/expenses/expenses.actions';
import { setLoadingComplete } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import Expenses from './expenses.component';
import { getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import { fetchAuthDetails, fetchAuthExpensesDetails, fetchVendors } from 'modules/authorizations/utils/fetchDataDetailsService';

const ExpensesView = props => {
  const { authorizationDetails, currentAuthId, match, authorizationSummaries, unmappedClients } = props;
  
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const details = authorizationDetails ? authorizationDetails[currentAuthId] : null;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const id = match.params.id;
      props.setAuthId(id);
        setIsLoading(true);

        await fetchAuthDetails(details, id, props);
        await fetchVendors(props?.vendors, props);
        await fetchAuthExpensesDetails(details, id, props);
        
        props.setLoadingComplete();
        setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && <Expenses details={details} id={match.params.id} history={props.history} authorizationSummaries={authorizationSummaries} unmappedClients={unmappedClients} />}
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

ExpensesView.propTypes = {
  authorizationDetails: PropTypes.object,
  currentAuthId: PropTypes.string,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading, currentAuthId, authorizationSummaries, vendors } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    isLoading,
    currentAuthId,
    authorizationSummaries,
    unmappedClients,
    vendors
  };
};

export default compose(
  connect(mapStateToProps, {
    getAuthClientPolicies,
    getEmployeeInfo,
    getExpenseSummariesByAuth,
    setAuthId,
    setLoadingComplete,
    showToast,
    getExpenseLinesByAuthId,
    getAuthorizationSummaries,
    getVendors,
    getAuthorizationSummary,
    getCommunicationInfo,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getFamilyMembers,
    getFamilyPets,
  })
)(ExpensesView);
