import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'modules/intake/form';
import { compose } from 'recompose';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

const styles = (theme) => ({
  accordionStyle: {
    maxWidth: 1050,
  },
  inputGroup: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  root: {
    minWidth: 350,
  },
  webRoot: {
    minWidth: 315,
  },
  mediumRoot: {
    minWidth: 200,
  },
  miniRoot: {
    width: 140,
  },
  detailsLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
  },
  contentCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  dualField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    width: 350,
    marginRight: theme.spacing(1),
  },
  checkbox: {
    paddingTop: 15,
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    alignContent: 'center',
  },
});

const TRCTeamTable = (props) => {
  const [clientOverview, setClientOverview] = useState(false);
  const formikProps = props.formikProps;
  const theme = useTheme();

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyle) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <h2>TRC Team</h2>
      </AccordionSummary>
      <AccordionDetails>
        <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
          <div className={props.classes.contentCol}>
            <Field
              component={TextField}
              name="salesRep"
              label="Business Development"
              className={props.classes.root}
              value={formikProps.values.salesRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            {/* <Field
              component={TextField}
              name="implementationRep"
              label="Implementation Rep"
              className={props.classes.root}
              value={formikProps.values.implementationRep}
            />
            <Field
              component={TextField}
              name="financialRep"
              label="Invoicing/Finance Rep"
              className={props.classes.root}
              value={formikProps.values.financialRep}
            />
            <Field
              component={TextField}
              name="reportingRep"
              label="Reporting Rep"
              className={props.classes.root}
              value={formikProps.values.reportingRep}
            /> */}
            <Field
              component={TextField}
              name="serviceRep"
              label="Client Services Manager"
              className={props.classes.root}
              value={formikProps.values.serviceRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="executiveRep"
              label="Client Services Executive"
              className={props.classes.root}
              value={formikProps.values.executiveRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="globalAssignmentRep"
              label="Client Services Director"
              className={props.classes.root}
              value={formikProps.values.globalAssignmentRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="payrollSpecialist"
              label="Payroll Specialist"
              className={props.classes.root}
              value={formikProps.values.payrollSpecialist}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </div>

          <div className={props.classes.contentCol}>
            {/* <Field
              component={TextField}
              name="homesaleRep"
              label="Homesale Rep"
              className={props.classes.root}
              value={formikProps.values.homesaleRep}
            /> */}
            <Field
              component={TextField}
              name="resaleRep"
              label="Inventory Specialist"
              className={props.classes.root}
              value={formikProps.values.resaleRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="expenseRep"
              label="Expense Analyst"
              className={props.classes.root}
              value={formikProps.values.expenseRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="homefindRep"
              label="Service Team"
              className={props.classes.root}
              value={formikProps.values.homefindRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="hhgRep"
              label="Service Delivery Manager"
              className={props.classes.root}
              value={formikProps.values.HHGRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            {/* <Field
              component={TextField}
              name="tempLivingRep"
              label="Temp Living Rep"
              className={props.classes.root}
              value={formikProps.values.tempLivingRep}
            /> */}
            <Field
              component={TextField}
              name="globalCompRep"
              label="Global Comp Analyst"
              className={props.classes.root}
              value={formikProps.values.globalCompRep}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

TRCTeamTable.propTypes = {
  classes: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
};
  
export default compose(
  withStyles(styles),
)(TRCTeamTable);