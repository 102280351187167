export const GET_LISTINGS_FAILURE = 'GET_LISTINGS_FAILURE';
export const GET_LISTINGS_REQUEST = 'GET_LISTINGS_REQUEST';
export const GET_LISTINGS_SUCCESS = 'GET_LISTINGS_SUCCESS';

export const POST_LISTINGS_FAILURE = 'POST_LISTINGS_FAILURE';
export const POST_LISTINGS_REQUEST = 'POST_LISTINGS_REQUEST';
export const POST_LISTINGS_SUCCESS = 'POST_LISTINGS_SUCCESS';

export const UPDATE_LISTINGS_FAILURE = 'UPDATE_LISTINGS_FAILURE';
export const UPDATE_LISTINGS_REQUEST = 'UPDATE_LISTINGS_REQUEST';
export const UPDATE_LISTINGS_SUCCESS = 'UPDATE_LISTINGS_SUCCESS';

export const GET_ALL_HOMESALE_RESALE_OFFER_FAILURE = 'GET_ALL_HOMESALE_RESALE_OFFER_FAILURE';
export const GET_ALL_HOMESALE_RESALE_OFFER_REQUEST = 'GET_ALL_HOMESALE_RESALE_OFFER_REQUEST';
export const GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS = 'GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS';

export const GET_HOMESALE_RESALE_OFFER_FAILURE = 'GET_HOMESALE_RESALE_OFFER_FAILURE';
export const GET_HOMESALE_RESALE_OFFER_REQUEST = 'GET_HOMESALE_RESALE_OFFER_REQUEST';
export const GET_HOMESALE_RESALE_OFFER_SUCCESS = 'GET_HOMESALE_RESALE_OFFER_SUCCESS';

export const POST_HOMESALE_RESALE_OFFER_FAILURE = 'POST_HOMESALE_RESALE_OFFER_FAILURE';
export const POST_HOMESALE_RESALE_OFFER_REQUEST = 'POST_HOMESALE_RESALE_OFFER_REQUEST';
export const POST_HOMESALE_RESALE_OFFER_SUCCESS = 'POST_HOMESALE_RESALE_OFFER_SUCCESS';

export const UPDATE_HOMESALE_RESALE_OFFER_FAILURE = 'UPDATE_HOMESALE_RESALE_OFFER_FAILURE';
export const UPDATE_HOMESALE_RESALE_OFFER_REQUEST = 'UPDATE_HOMESALE_RESALE_OFFER_REQUEST';
export const UPDATE_HOMESALE_RESALE_OFFER_SUCCESS = 'UPDATE_HOMESALE_RESALE_OFFER_SUCCESS';

export const DELETE_HOMESALE_RESALE_OFFER_FAILURE = 'DELETE_HOMESALE_RESALE_OFFER_FAILURE';
export const DELETE_HOMESALE_RESALE_OFFER_REQUEST = 'DELETE_HOMESALE_RESALE_OFFER_REQUEST';
export const DELETE_HOMESALE_RESALE_OFFER_SUCCESS = 'DELETE_HOMESALE_RESALE_OFFER_SUCCESS';

export const GET_EQUITY_FAILURE = 'GET_EQUITY_FAILURE';
export const GET_EQUITY_REQUEST = 'GET_EQUITY_REQUEST';
export const GET_EQUITY_SUCCESS = 'GET_EQUITY_SUCCESS';

export const POST_EQUITY_FAILURE = 'POST_EQUITY_FAILURE';
export const POST_EQUITY_REQUEST = 'POST_EQUITY_REQUEST';
export const POST_EQUITY_SUCCESS = 'POST_EQUITY_SUCCESS';

export const UPDATE_EQUITY_FAILURE = 'UPDATE_EQUITY_FAILURE';
export const UPDATE_EQUITY_REQUEST = 'UPDATE_EQUITY_REQUEST';
export const UPDATE_EQUITY_SUCCESS = 'UPDATE_EQUITY_SUCCESS';

export const DELETE_EQUITY_FAILURE = 'DELETE_EQUITY_FAILURE';
export const DELETE_EQUITY_REQUEST = 'DELETE_EQUITY_REQUEST';
export const DELETE_EQUITY_SUCCESS = 'DELETE_EQUITY_SUCCESS';

export const GET_TAX_FAILURE = 'GET_TAX_FAILURE';
export const GET_TAX_REQUEST = 'GET_TAX_REQUEST';
export const GET_TAX_SUCCESS = 'GET_TAX_SUCCESS';

export const POST_TAX_FAILURE = 'POST_TAX_FAILURE';
export const POST_TAX_REQUEST = 'POST_TAX_REQUEST';
export const POST_TAX_SUCCESS = 'POST_TAX_SUCCESS';

export const UPDATE_TAX_FAILURE = 'UPDATE_TAX_FAILURE';
export const UPDATE_TAX_REQUEST = 'UPDATE_TAX_REQUEST';
export const UPDATE_TAX_SUCCESS = 'UPDATE_TAX_SUCCESS';

export const DELETE_TAX_FAILURE = 'DELETE_TAX_FAILURE';
export const DELETE_TAX_REQUEST = 'DELETE_TAX_REQUEST';
export const DELETE_TAX_SUCCESS = 'DELETE_TAX_SUCCESS';

export const GET_CREDIT_FAILURE = 'GET_CREDIT_FAILURE';
export const GET_CREDIT_REQUEST = 'GET_CREDIT_REQUEST';
export const GET_CREDIT_SUCCESS = 'GET_CREDIT_SUCCESS';

export const POST_CREDIT_FAILURE = 'POST_CREDIT_FAILURE';
export const POST_CREDIT_REQUEST = 'POST_CREDIT_REQUEST';
export const POST_CREDIT_SUCCESS = 'POST_CREDIT_SUCCESS';

export const UPDATE_CREDIT_FAILURE = 'UPDATE_CREDIT_FAILURE';
export const UPDATE_CREDIT_REQUEST = 'UPDATE_CREDIT_REQUEST';
export const UPDATE_CREDIT_SUCCESS = 'UPDATE_CREDIT_SUCCESS';

export const DELETE_CREDIT_FAILURE = 'DELETE_CREDIT_FAILURE';
export const DELETE_CREDIT_REQUEST = 'DELETE_CREDIT_REQUEST';
export const DELETE_CREDIT_SUCCESS = 'DELETE_CREDIT_SUCCESS';

export const GET_INSPECTIONS_FAILURE = 'GET_INSPECTIONS_FAILURE';
export const GET_INSPECTIONS_REQUEST = 'GET_INSPECTIONS_REQUEST';
export const GET_INSPECTIONS_SUCCESS = 'GET_INSPECTIONS_SUCCESS';

export const POST_INSPECTIONS_FAILURE = 'POST_INSPECTIONS_FAILURE';
export const POST_INSPECTIONS_REQUEST = 'POST_INSPECTIONS_REQUEST';
export const POST_INSPECTIONS_SUCCESS = 'POST_INSPECTIONS_SUCCESS';

export const UPDATE_INSPECTIONS_FAILURE = 'UPDATE_INSPECTIONS_FAILURE';
export const UPDATE_INSPECTIONS_REQUEST = 'UPDATE_INSPECTIONS_REQUEST';
export const UPDATE_INSPECTIONS_SUCCESS = 'UPDATE_INSPECTIONS_SUCCESS';

export const DELETE_INSPECTIONS_FAILURE = 'DELETE_INSPECTIONS_FAILURE';
export const DELETE_INSPECTIONS_REQUEST = 'DELETE_INSPECTIONS_REQUEST';
export const DELETE_INSPECTIONS_SUCCESS = 'DELETE_INSPECTIONS_SUCCESS';

export const UPDATE_INSPECTIONS_STATE = 'UPDATE_INSPECTIONS_STATE';

export const GET_APPRAISALS_FAILURE = 'GET_APPRAISALS_FAILURE';
export const GET_APPRAISALS_REQUEST = 'GET_APPRAISALS_REQUEST';
export const GET_APPRAISALS_SUCCESS = 'GET_APPRAISALS_SUCCESS';

export const POST_APPRAISALS_FAILURE = 'POST_APPRAISALS_FAILURE';
export const POST_APPRAISALS_REQUEST = 'POST_APPRAISALS_REQUEST';
export const POST_APPRAISALS_SUCCESS = 'POST_APPRAISALS_SUCCESS';

export const UPDATE_APPRAISALS_FAILURE = 'UPDATE_APPRAISALS_FAILURE';
export const UPDATE_APPRAISALS_REQUEST = 'UPDATE_APPRAISALS_REQUEST';
export const UPDATE_APPRAISALS_SUCCESS = 'UPDATE_APPRAISALS_SUCCESS';

export const GET_HOMESALE_INFO_FAILURE = 'GET_HOMESALE_INFO_FAILURE';
export const GET_HOMESALE_INFO_REQUEST = 'GET_HOMESALE_INFO_REQUEST';
export const GET_HOMESALE_INFO_SUCCESS = 'GET_HOMESALE_INFO_SUCCESS';

export const POST_HOMESALE_INFO_FAILURE = 'POST_HOMESALE_INFO_FAILURE';
export const POST_HOMESALE_INFO_REQUEST = 'POST_HOMESALE_INFO_REQUEST';
export const POST_HOMESALE_INFO_SUCCESS = 'POST_HOMESALE_INFO_SUCCESS';

export const UPDATE_HOMESALE_INFO_FAILURE = 'UPDATE_HOMESALE_INFO_FAILURE';
export const UPDATE_HOMESALE_INFO_REQUEST = 'UPDATE_HOMESALE_INFO_REQUEST';
export const UPDATE_HOMESALE_INFO_SUCCESS = 'UPDATE_HOMESALE_INFO_SUCCESS';
