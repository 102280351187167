import {
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE
} from 'modules/authorizations/store/types/temporaryLivingTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  deleteTemporaryLivingAssignment,
  getTemporaryLivingAssignments,
  postTemporaryLivingAssignments,
  updateTemporaryLivingAssignments
} from 'modules/authorizations/store/actions/temporaryLiving.actions';
import { useDispatch } from 'react-redux';

export const useUpdateOrCreateFormHandler = (showToast, isNewForm = false) => {
  const dispatch = useDispatch();

  const handleFormSubmission = async request => {
    try {
      const response = await dispatch(isNewForm ? postTemporaryLivingAssignments(request) : updateTemporaryLivingAssignments(request));

      if (response.type === UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE || response.type === POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE) {
        showToast('Failed to update/create assignment, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return { content: response.response, isSuccessful: false };
      }
      return { content: response.response, isSuccessful: true };
    } catch (error) {
      console.error('Error handling form submission:', error);
      showToast('Failed to update/create assignment, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return { isSuccessful: false };
    }
  };
  return handleFormSubmission;
};

export const useDeleteFormHandler = showToast => {
  const dispatch = useDispatch();
  const handleAssignmentDelete = async (authId, tempLivingAssignmentId) => {
    try {
      const deleteResponse = await dispatch(deleteTemporaryLivingAssignment(authId, tempLivingAssignmentId));
      if (deleteResponse.type === DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE) {
        showToast('Failed to delete assignment, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      console.error('Failed to delete assignment, please try again:', error);
      showToast('Failed to delete assignment, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };
  return handleAssignmentDelete;
};

export const useFetchDataHandler = showToast => {
  const dispatch = useDispatch();
  const handleFetchData = async authId => {
    try {
      const fetchResponse = await dispatch(getTemporaryLivingAssignments(authId));
      if (fetchResponse.type === GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE) {
        showToast('Failed to fetch assignments, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('Failed to fetch assignments, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };
  return handleFetchData;
};
