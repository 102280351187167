export const GET_INTERNAL_USERS_REQUEST = 'GET_INTERNAL_USERS_REQUEST';
export const GET_INTERNAL_USERS_SUCCESS = 'GET_INTERNAL_USERS_SUCCESS';
export const GET_INTERNAL_USERS_FAILURE = 'GET_INTERNAL_USERS_FAILURE';

export const GET_CLIENT_DETAILS_REQUEST = 'GET_CLIENT_DETAILS_REQUEST';
export const GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_FAILURE = 'GET_CLIENT_DETAILS_FAILURE';

export const GET_CLIENT_CALENDARS_REQUEST = 'GET_CLIENT_CALENDARS_REQUEST';
export const GET_CLIENT_CALENDARS_SUCCESS = 'GET_CLIENT_CALENDARS_SUCCESS';
export const GET_CLIENT_CALENDARS_FAILURE = 'GET_CLIENT_CALENDARS_FAILURE';

export const GET_CALENDAR_PERIODS_REQUEST = 'GET_CALENDAR_PERIODS_REQUEST';
export const GET_CALENDAR_PERIODS_SUCCESS = 'GET_CALENDAR_PERIODS_SUCCESS';
export const GET_CALENDAR_PERIODS_FAILURE = 'GET_CALENDAR_PERIODS_FAILURE';

export const POST_CLIENT_CALENDAR_REQUEST = 'POST_CLIENT_CALENDAR_REQUEST';
export const POST_CLIENT_CALENDAR_SUCCESS = 'POST_CLIENT_CALENDAR_SUCCESS';
export const POST_CLIENT_CALENDAR_FAILURE = 'POST_CLIENT_CALENDAR_FAILURE';

export const UPDATE_CLIENT_CALENDAR_REQUEST = 'UPDATE_CLIENT_CALENDAR_REQUEST';
export const UPDATE_CLIENT_CALENDAR_SUCCESS = 'UPDATE_CLIENT_CALENDAR_SUCCESS';
export const UPDATE_CLIENT_CALENDAR_FAILURE = 'UPDATE_CLIENT_CALENDAR_FAILURE';

export const UPDATE_PERIOD_REQUEST = 'UPDATE_PERIOD_REQUEST';
export const UPDATE_PERIOD_SUCCESS = 'UPDATE_PERIOD_SUCCESS';
export const UPDATE_PERIOD_FAILURE = 'UPDATE_PERIOD_FAILURE';

export const DELETE_CALENDAR_REQUEST = 'DELETE_CALENDAR_REQUEST';
export const DELETE_CALENDAR_SUCCESS = 'DELETE_CALENDAR_SUCCESS';
export const DELETE_CALENDAR_FAILURE = 'DELETE_CALENDAR_FAILURE';

export const POST_CLIENT_POLICY_NOTES_REQUEST = 'POST_CLIENT_POLICY_NOTES_REQUEST';
export const POST_CLIENT_POLICY_NOTES_SUCCESS = 'POST_CLIENT_POLICY_NOTES_SUCCESS';
export const POST_CLIENT_POLICY_NOTES_FAILURE = 'POST_CLIENT_POLICY_NOTES_FAILURE';

export const DELETE_CLIENT_POLICY_NOTES_REQUEST = 'DELETE_CLIENT_POLICY_NOTES_REQUEST';
export const DELETE_CLIENT_POLICY_NOTES_SUCCESS = 'DELETE_CLIENT_POLICY_NOTES_SUCCESS';
export const DELETE_CLIENT_POLICY_NOTES_FAILURE = 'DELETE_CLIENT_POLICY_NOTES_FAILURE';

export const UPDATE_CLIENT_POLICY_NOTES_REQUEST = 'UPDATE_CLIENT_POLICY_NOTES_REQUEST';
export const UPDATE_CLIENT_POLICY_NOTES_SUCCESS = 'UPDATE_CLIENT_POLICY_NOTES_SUCCESS';
export const UPDATE_CLIENT_POLICY_NOTES_FAILURE = 'UPDATE_CLIENT_POLICY_NOTES_FAILURE';

export const GET_CLIENT_POLICY_NOTES_REQUEST = 'GET_CLIENT_POLICY_NOTES_REQUEST';
export const GET_CLIENT_POLICY_NOTES_SUCCESS = 'GET_CLIENT_POLICY_NOTES_SUCCESS';
export const GET_CLIENT_POLICY_NOTES_FAILURE = 'GET_CLIENT_POLICY_NOTES_FAILURE';

export const GET_CLIENT_HOMESALE_INFO_REQUEST = 'GET_CLIENT_HOMESALE_INFO_REQUEST';
export const GET_CLIENT_HOMESALE_INFO_SUCCESS = 'GET_CLIENT_HOMESALE_INFO_SUCCESS';
export const GET_CLIENT_HOMESALE_INFO_FAILURE = 'GET_CLIENT_HOMESALE_INFO_FAILURE';
export const UPDATE_CLIENT_HOMESALE_INFO_REQUEST = 'UPDATE_CLIENT_HOMESALE_INFO_REQUEST';
export const UPDATE_CLIENT_HOMESALE_INFO_SUCCESS = 'UPDATE_CLIENT_HOMESALE_INFO_SUCCESS';
export const UPDATE_CLIENT_HOMESALE_INFO_FAILURE = 'UPDATE_CLIENT_HOMESALE_INFO_FAILURE';

export const GET_CLIENT_LUMP_SUM_REQUEST = 'GET_CLIENT_LUMP_SUM_REQUEST';
export const GET_CLIENT_LUMP_SUM_SUCCESS = 'GET_CLIENT_LUMP_SUM_SUCCESS';
export const GET_CLIENT_LUMP_SUM_FAILURE = 'GET_CLIENT_LUMP_SUM_FAILURE';
export const UPDATE_CLIENT_LUMP_SUM_REQUEST = 'UPDATE_CLIENT_LUMP_SUM_REQUEST';
export const UPDATE_CLIENT_LUMP_SUM_SUCCESS = 'UPDATE_CLIENT_LUMP_SUM_SUCCESS';
export const UPDATE_CLIENT_LUMP_SUM_FAILURE = 'UPDATE_CLIENT_LUMP_SUM_FAILURE';

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

export const GET_CLIENT_CONTACTS_REQUEST = 'GET_CLIENT_CONTACTS_REQUEST';
export const GET_CLIENT_CONTACTS_SUCCESS = 'GET_CLIENT_CONTACTS_SUCCESS';
export const GET_CLIENT_CONTACTS_FAILURE = 'GET_CLIENT_CONTACTS_FAILURE';

export const GET_CLIENT_ISSUES_CHALLENGES_REQUEST = 'GET_CLIENT_ISSUES_CHALLENGES_REQUEST';
export const GET_CLIENT_ISSUES_CHALLENGES_SUCCESS = 'GET_CLIENT_ISSUES_CHALLENGES_SUCCESS';
export const GET_CLIENT_ISSUES_CHALLENGES_FAILURE = 'GET_CLIENT_ISSUES_CHALLENGES_FAILURE';

export const GET_CLIENT_GENERAL_NOTES_REQUEST = 'GET_CLIENT_GENERAL_NOTES_REQUEST';
export const GET_CLIENT_GENERAL_NOTES_SUCCESS = 'GET_CLIENT_GENERAL_NOTES_SUCCESS';
export const GET_CLIENT_GENERAL_NOTES_FAILURE = 'GET_CLIENT_GENERAL_NOTES_FAILURE';

export const GET_CLIENT_CONTRACT_REQUEST = 'GET_CLIENT_CONTRACT_REQUEST';
export const GET_CLIENT_CONTRACT_SUCCESS = 'GET_CLIENT_CONTRACT_SUCCESS';
export const GET_CLIENT_CONTRACT_FAILURE = 'GET_CLIENT_CONTRACT_FAILURE';

export const GET_CLIENT_CONTRACT_SLA_REQUEST = 'GET_CLIENT_CONTRACT_SLA_REQUEST';
export const GET_CLIENT_CONTRACT_SLA_SUCCESS = 'GET_CLIENT_CONTRACT_SLA_SUCCESS';
export const GET_CLIENT_CONTRACT_SLA_FAILURE = 'GET_CLIENT_CONTRACT_SLA_FAILURE';

export const POST_CLIENT_CONTRACT_SLA_REQUEST = 'POST_CLIENT_CONTRACT_SLA_REQUEST';
export const POST_CLIENT_CONTRACT_SLA_SUCCESS = 'POST_CLIENT_CONTRACT_SLA_SUCCESS';
export const POST_CLIENT_CONTRACT_SLA_FAILURE = 'POST_CLIENT_CONTRACT_SLA_FAILURE';

export const DELETE_CLIENT_CONTRACT_SLA_REQUEST = 'DELETE_CLIENT_CONTRACT_SLA_REQUEST';
export const DELETE_CLIENT_CONTRACT_SLA_SUCCESS = 'DELETE_CLIENT_CONTRACT_SLA_SUCCESS';
export const DELETE_CLIENT_CONTRACT_SLA_FAILURE = 'DELETE_CLIENT_CONTRACT_SLA_FAILURE';

export const UPDATE_CLIENT_CONTRACT_SLA_REQUEST = 'UPDATE_CLIENT_CONTRACT_SLA_REQUEST';
export const UPDATE_CLIENT_CONTRACT_SLA_SUCCESS = 'UPDATE_CLIENT_CONTRACT_SLA_SUCCESS';
export const UPDATE_CLIENT_CONTRACT_SLA_FAILURE = 'UPDATE_CLIENT_CONTRACT_SLA_FAILURE';

export const GET_CLIENT_CONTRACT_FEE_REQUEST = 'GET_CLIENT_CONTRACT_FEE_REQUEST';
export const GET_CLIENT_CONTRACT_FEE_SUCCESS = 'GET_CLIENT_CONTRACT_FEE_SUCCESS';
export const GET_CLIENT_CONTRACT_FEE_FAILURE = 'GET_CLIENT_CONTRACT_FEE_FAILURE';

export const POST_CLIENT_CONTRACT_FEE_REQUEST = 'POST_CLIENT_CONTRACT_FEE_REQUEST';
export const POST_CLIENT_CONTRACT_FEE_SUCCESS = 'POST_CLIENT_CONTRACT_FEE_SUCCESS';
export const POST_CLIENT_CONTRACT_FEE_FAILURE = 'POST_CLIENT_CONTRACT_FEE_FAILURE';

export const DELETE_CLIENT_CONTRACT_FEE_REQUEST = 'DELETE_CLIENT_CONTRACT_FEE_REQUEST';
export const DELETE_CLIENT_CONTRACT_FEE_SUCCESS = 'DELETE_CLIENT_CONTRACT_FEE_SUCCESS';
export const DELETE_CLIENT_CONTRACT_FEE_FAILURE = 'DELETE_CLIENT_CONTRACT_FEE_FAILURE';

export const UPDATE_CLIENT_CONTRACT_FEE_REQUEST = 'UPDATE_CLIENT_CONTRACT_FEE_REQUEST';
export const UPDATE_CLIENT_CONTRACT_FEE_SUCCESS = 'UPDATE_CLIENT_CONTRACT_FEE_SUCCESS';
export const UPDATE_CLIENT_CONTRACT_FEE_FAILURE = 'UPDATE_CLIENT_CONTRACT_FEE_FAILURE';

export const GET_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST = 'GET_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST';
export const GET_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS = 'GET_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS';
export const GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE = 'GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE';

export const POST_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST = 'POST_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST';
export const POST_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS = 'POST_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS';
export const POST_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE = 'POST_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE';

export const DELETE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST = 'DELETE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST';
export const DELETE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS = 'DELETE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS';
export const DELETE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE = 'DELETE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE';

export const UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST = 'UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST';
export const UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS = 'UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS';
export const UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE = 'UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE';

export const POST_CLIENT_CONTACT_REQUEST = 'POST_CLIENT_CONTACT_REQUEST';
export const POST_CLIENT_CONTACT_SUCCESS = 'POST_CLIENT_CONTACT_SUCCESS';
export const POST_CLIENT_CONTACT_FAILURE = 'POST_CLIENT_CONTACT_FAILURE';

export const DELETE_CLIENT_CONTACT_REQUEST = 'DELETE_CLIENT_CONTACT_REQUEST';
export const DELETE_CLIENT_CONTACT_SUCCESS = 'DELETE_CLIENT_CONTACT_SUCCESS';
export const DELETE_CLIENT_CONTACT_FAILURE = 'DELETE_CLIENT_CONTACT_FAILURE';

export const UPDATE_CLIENT_CONTACT_REQUEST = 'UPDATE_CLIENT_CONTACT_REQUEST';
export const UPDATE_CLIENT_CONTACT_SUCCESS = 'UPDATE_CLIENT_CONTACT_SUCCESS';
export const UPDATE_CLIENT_CONTACT_FAILURE = 'UPDATE_CLIENT_CONTACT_FAILURE';

export const POST_CLIENT_ISSUES_CHALLENGES_REQUEST = 'POST_CLIENT_ISSUES_CHALLENGES_REQUEST';
export const POST_CLIENT_ISSUES_CHALLENGES_SUCCESS = 'POST_CLIENT_ISSUES_CHALLENGES_SUCCESS';
export const POST_CLIENT_ISSUES_CHALLENGES_FAILURE = 'POST_CLIENT_ISSUES_CHALLENGES_FAILURE';

export const DELETE_CLIENT_ISSUES_CHALLENGES_REQUEST = 'DELETE_CLIENT_ISSUES_CHALLENGES_REQUEST';
export const DELETE_CLIENT_ISSUES_CHALLENGES_SUCCESS = 'DELETE_CLIENT_ISSUES_CHALLENGES_SUCCESS';
export const DELETE_CLIENT_ISSUES_CHALLENGES_FAILURE = 'DELETE_CLIENT_ISSUES_CHALLENGES_FAILURE';

export const UPDATE_CLIENT_ISSUES_CHALLENGES_REQUEST = 'UPDATE_CLIENT_ISSUES_CHALLENGES_REQUEST';
export const UPDATE_CLIENT_ISSUES_CHALLENGES_SUCCESS = 'UPDATE_CLIENT_ISSUES_CHALLENGES_SUCCESS';
export const UPDATE_CLIENT_ISSUES_CHALLENGES_FAILURE = 'UPDATE_CLIENT_ISSUES_CHALLENGES_FAILURE';

export const POST_CLIENT_GENERAL_NOTES_REQUEST = 'POST_CLIENT_GENERAL_NOTES_REQUEST';
export const POST_CLIENT_GENERAL_NOTES_SUCCESS = 'POST_CLIENT_GENERAL_NOTES_SUCCESS';
export const POST_CLIENT_GENERAL_NOTES_FAILURE = 'POST_CLIENT_GENERAL_NOTES_FAILURE';

export const DELETE_CLIENT_GENERAL_NOTES_REQUEST = 'DELETE_CLIENT_GENERAL_NOTES_REQUEST';
export const DELETE_CLIENT_GENERAL_NOTES_SUCCESS = 'DELETE_CLIENT_GENERAL_NOTES_SUCCESS';
export const DELETE_CLIENT_GENERAL_NOTES_FAILURE = 'DELETE_CLIENT_GENERAL_NOTES_FAILURE';

export const UPDATE_CLIENT_GENERAL_NOTES_REQUEST = 'UPDATE_CLIENT_GENERAL_NOTES_REQUEST';
export const UPDATE_CLIENT_GENERAL_NOTES_SUCCESS = 'UPDATE_CLIENT_GENERAL_NOTES_SUCCESS';
export const UPDATE_CLIENT_GENERAL_NOTES_FAILURE = 'UPDATE_CLIENT_GENERAL_NOTES_FAILURE';

export const GET_CLIENT_SPECIAL_PROCESS_REQUEST = 'GET_CLIENT_SPECIAL_PROCESS_REQUEST';
export const GET_CLIENT_SPECIAL_PROCESS_SUCCESS = 'GET_CLIENT_SPECIAL_PROCESS_SUCCESS';
export const GET_CLIENT_SPECIAL_PROCESS_FAILURE = 'GET_CLIENT_SPECIAL_PROCESS_FAILURE';

export const DELETE_CLIENT_SPECIAL_PROCESS_REQUEST = 'DELETE_CLIENT_SPECIAL_PROCESSE_REQUEST';
export const DELETE_CLIENT_SPECIAL_PROCESS_SUCCESS = 'DELETE_CLIENT_SPECIAL_PROCESS_SUCCESS';
export const DELETE_CLIENT_SPECIAL_PROCESS_FAILURE = 'DELETE_CLIENT_SPECIAL_PROCESS_FAILURE';

export const UPSERT_CLIENT_SPECIAL_PROCESS_REQUEST = 'UPSERT_CLIENT_SPECIAL_PROCESS_REQUEST';
export const UPSERT_CLIENT_SPECIAL_PROCESS_SUCCESS = 'UPSERT_CLIENT_SPECIAL_PROCESS_SUCCESS';
export const UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE = 'UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE';

export const GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_REQUEST = 'GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_REQUEST';
export const GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_SUCCESS = 'GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_SUCCESS';
export const GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_FAILURE = 'GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_FAILURE';

export const DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST = 'DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST';
export const DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS = 'DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS';
export const DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE = 'DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE';

export const UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST = 'UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST';
export const UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS = 'UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS';
export const UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE = 'UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE';

export const GET_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST = 'GET_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST';
export const GET_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS = 'GET_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS';
export const GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE = 'GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE';

export const SET_CURRENT_CLIENT_ID = 'SET_CURRENT_CLIENT_ID';

export const GET_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST = 'GET_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST';
export const GET_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS = 'GET_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS';
export const GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE = 'GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE';

export const POST_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST = 'POST_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST';
export const POST_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS = 'POST_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS';
export const POST_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE = 'POST_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE';

export const DELETE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST = 'DELETE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST';
export const DELETE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS = 'DELETE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS';
export const DELETE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE = 'DELETE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE';

export const UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST = 'UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST';
export const UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS = 'UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS';
export const UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE = 'UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE';

export const GET_HOMESALE_CLOSING_COSTS_REQUEST = 'GET_HOMESALE_CLOSING_COSTS_REQUEST';
export const GET_HOMESALE_CLOSING_COSTS_SUCCESS = 'GET_HOMESALE_CLOSING_COSTS_SUCCESS'; 
export const GET_HOMESALE_CLOSING_COSTS_FAILURE = 'GET_HOMESALE_CLOSING_COSTS_FAILURE';

export const POST_HOMESALE_CLOSING_COSTS_REQUEST = 'POST_HOMESALE_CLOSING_COSTS_REQUEST';
export const POST_HOMESALE_CLOSING_COSTS_SUCCESS = 'POST_HOMESALE_CLOSING_COSTS_SUCCESS';
export const POST_HOMESALE_CLOSING_COSTS_FAILURE = 'POST_HOMESALE_CLOSING_COSTS_FAILURE';

export const DELETE_HOMESALE_CLOSING_COSTS_REQUEST = 'DELETE_HOMESALE_CLOSING_COSTS_REQUEST';
export const DELETE_HOMESALE_CLOSING_COSTS_SUCCESS = 'DELETE_HOMESALE_CLOSING_COSTS_SUCCESS'; 
export const DELETE_HOMESALE_CLOSING_COSTS_FAILURE = 'DELETE_HOMESALE_CLOSING_COSTS_FAILURE';

export const UPDATE_HOMESALE_CLOSING_COSTS_REQUEST = 'UPDATE_HOMESALE_CLOSING_COSTS_REQUEST';
export const UPDATE_HOMESALE_CLOSING_COSTS_SUCCESS = 'UPDATE_HOMESALE_CLOSING_COSTS_SUCCESS';
export const UPDATE_HOMESALE_CLOSING_COSTS_FAILURE = 'UPDATE_HOMESALE_CLOSING_COSTS_FAILURE';

export const GET_HOMESALE_GBO_INSPECTIONS_REQUEST = 'GET_HOMESALE_GBO_INSPECTIONS_REQUEST';
export const GET_HOMESALE_GBO_INSPECTIONS_SUCCESS = 'GET_HOMESALE_GBO_INSPECTIONS_SUCCESS';
export const GET_HOMESALE_GBO_INSPECTIONS_FAILURE = 'GET_HOMESALE_GBO_INSPECTIONS_FAILURE';

export const POST_HOMESALE_GBO_INSPECTIONS_REQUEST = 'POST_HOMESALE_GBO_INSPECTIONS_REQUEST';
export const POST_HOMESALE_GBO_INSPECTIONS_SUCCESS = 'POST_HOMESALE_GBO_INSPECTIONS_SUCCESS';
export const POST_HOMESALE_GBO_INSPECTIONS_FAILURE = 'POST_HOMESALE_GBO_INSPECTIONS_FAILURE';

export const DELETE_HOMESALE_GBO_INSPECTIONS_REQUEST = 'DELETE_HOMESALE_GBO_INSPECTIONS_REQUEST';
export const DELETE_HOMESALE_GBO_INSPECTIONS_SUCCESS = 'DELETE_HOMESALE_GBO_INSPECTIONS_SUCCESS';
export const DELETE_HOMESALE_GBO_INSPECTIONS_FAILURE = 'DELETE_HOMESALE_GBO_INSPECTIONS_FAILURE';

export const UPDATE_HOMESALE_GBO_INSPECTIONS_REQUEST = 'UPDATE_HOMESALE_GBO_INSPECTIONS_REQUEST';
export const UPDATE_HOMESALE_GBO_INSPECTIONS_SUCCESS = 'UPDATE_HOMESALE_GBO_INSPECTIONS_SUCCESS';
export const UPDATE_HOMESALE_GBO_INSPECTIONS_FAILURE = 'UPDATE_HOMESALE_GBO_INSPECTIONS_FAILURE';

export const GET_POLICIES_GENERAL_INFO_REQUEST = 'GET_POLICIES_GENERAL_INFO_REQUEST';
export const GET_POLICIES_GENERAL_INFO__SUCCESS = 'GET_POLICIES_GENERAL_INFO__SUCCESS'; 
export const GET_POLICIES_GENERAL_INFO__FAILURE = 'GET_POLICIES_GENERAL_INFO__FAILURE';

export const UPDATE_POLICIES_GENERAL_INFO__REQUEST = 'UPDATE_POLICIES_GENERAL_INFO__REQUEST';
export const UPDATE_POLICIES_GENERAL_INFO__SUCCESS = 'UPDATE_POLICIES_GENERAL_INFO__SUCCESS';
export const UPDATE_POLICIES_GENERAL_INFO__FAILURE = 'UPDATE_POLICIES_GENERAL_INFO__FAILURE';

export const GET_CLIENT_METADATA_REQUEST = 'GET_CLIENT_METADATA_REQUEST';
export const GET_CLIENT_METADATA_SUCCESS = 'GET_CLIENT_METADATA_SUCCESS'; 
export const GET_CLIENT_METADATA_FAILURE = 'GET_CLIENT_METADATA_FAILURE';

export const POST_GBO_INSPECTIONS_INITIALIZE_REQUEST = 'POST_HOMESALE_GBO_INSPECTIONS_REQUEST';
export const POST_GBO_INSPECTIONS_INITIALIZE_SUCCESS = 'POST_HOMESALE_GBO_INSPECTIONS_SUCCESS';
export const POST_GBO_INSPECTIONS_INITIALIZE_FAILURE = 'POST_HOMESALE_GBO_INSPECTIONS_FAILURE';

export const GET_HOMESALE_HHG_PROCESS_REQUEST = 'GET_HOMESALE_HHG_PROCESS_REQUEST';
export const GET_HOMESALE_HHG_PROCESS_SUCCESS = 'GET_HOMESALE_HHG_PROCESS_SUCCESS';
export const GET_HOMESALE_HHG_PROCESS_FAILURE = 'GET_HOMESALE_HHG_PROCESS_FAILURE';

export const POST_HOMESALE_HHG_PROCESS_REQUEST = 'POST_HOMESALE_HHG_PROCESS_REQUEST';
export const POST_HOMESALE_HHG_PROCESS_SUCCESS = 'POST_HOMESALE_HHG_PROCESS_SUCCESS';
export const POST_HOMESALE_HHG_PROCESS_FAILURE = 'POST_HOMESALE_HHG_PROCESS_FAILURE';

export const DELETE_HOMESALE_HHG_PROCESS_REQUEST = 'DELETE_HOMESALE_HHG_PROCESS_REQUEST';
export const DELETE_HOMESALE_HHG_PROCESS_SUCCESS = 'DELETE_HOMESALE_HHG_PROCESS_SUCCESS';
export const DELETE_HOMESALE_HHG_PROCESS_FAILURE = 'DELETE_HOMESALE_HHG_PROCESS_FAILURE';

export const UPDATE_HOMESALE_HHG_PROCESS_REQUEST = 'UPDATE_HOMESALE_HHG_PROCESS_REQUEST';
export const UPDATE_HOMESALE_HHG_PROCESS_SUCCESS = 'UPDATE_HOMESALE_HHG_PROCESS_SUCCESS';
export const UPDATE_HOMESALE_HHG_PROCESS_FAILURE = 'UPDATE_HOMESALE_HHG_PROCESS_FAILURE';

export const SET_LOADING_COMPLETE = 'SET_LOADING_COMPLETE';
