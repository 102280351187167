import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

import { DELETE_VENDORS_FAILURE, DELETE_VENDOR_CONTACT_FAILURE, GET_VENDORS_FAILURE, GET_VENDOR_CONTACT_FAILURE, POST_VENDORS_FAILURE, POST_VENDOR_CONTACT_FAILURE, UPDATE_VENDORS_FAILURE, UPDATE_VENDOR_CONTACT_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { deleteVendor, deleteVendorContact, getVendorContacts, getVendors, postVendor, postVendorContact, updateVendor, updateVendorContact } from 'modules/authorizations/store/actions/dataManagement.actions';
import CrudTable from '../clients/details/crudTable.component';
import CrudTableCard from 'modules/common/crudTableCard.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const VendorTypeOptions = [
  'Inspectors', 
  'School', 
  'Insp', 
  'Brk', 
  'Hhg', 
  'Spc', 
  'Tax', 
  'Oth', 
  'TenancyMgmt', 
  'Tl', 
  'Client', 
  'Hoa', 
  'Mtg', 
  'Insur', 
  'Util', 
  'App', 
  'Tp', 
  'Pet', 
  'Dsc', 
  'Division'
];


const VendorsTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const crudTableCardTabs = [
    { header: 'Vendors', order: 1, iconPath: ['fas', 'user'] },
    { header: 'Vendor Contacts', order: 2, iconPath: ['fas', 'user'] },
  ];

  const vendorsAdjustedColumns = [
    { accessorKey: 'companyName', header: 'Company Name', type: 'text', required: true, size: 70 },
    //TODO - We need to add all the options and update the Modal to allow for multiple selections of types.
    { accessorKey: 'vendorType', header: 'Vendor Type', type: 'dropdown', options: VendorTypeOptions, required: false, size: 70 },
    { accessorKey: 'description', header: 'Description', type: 'text', required: false, size: 50 },
    { accessorKey: 'address', header: 'Address', type: 'text', required: false, size: 50 },
    { accessorKey: 'city', header: 'City', type: 'text', required: false, size: 50 },
    { accessorKey: 'state', header: 'State', type: 'text', required: false, size: 50 },
    { accessorKey: 'zip', header: 'Zip', type: 'text', required: false, size: 50 },
    { accessorKey: 'phone', header: 'Phone', type: 'text', required: false, size: 50 },
    { accessorKey: 'email', header: 'Email', type: 'text', required: false, size: 50 },
    { accessorKey: 'primary', header: 'Primary', type: 'text', required: false, size: 50 },
  ];

  const vendorContactsAdjustedColumns = [
    { accessorKey: 'vendorId', header: 'Vendor Id', type: 'number', required: true, size: 70 },
    { accessorKey: 'fullName', header: 'Full Name', type: 'text', required: false, size: 50 },
    { accessorKey: 'officePhone', header: 'Office Phone', type: 'text', required: false, size: 50 },
    { accessorKey: 'officeEmail', header: 'Office Email', type: 'text', required: false, size: 50 },
  ];

  const updateVendorRow = async (vendor, values) => {
    values.vendorId = vendor.original.vendorId;

    setIsLoading(true);
    const resp = await props.updateVendor(values);
    if (resp.type === UPDATE_VENDORS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendors();
      setIsLoading(false);
      if (getResp.type === GET_VENDORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createVendorRow = async (values) => {
    setIsLoading(true);
    const resp = await props.postVendor(values);
    if (resp.type === POST_VENDORS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendors();
      setIsLoading(false);
      if (getResp.type === GET_VENDORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteVendorRow = async (vendor) => {
    const id = vendor.original.vendorId;
    const resp = await props.deleteVendor(id);
    if (resp.type === DELETE_VENDORS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getVendors();
      if (getResp.type === GET_VENDORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const updateVendorContactRow = async (vendor, values) => {
    values.vendorContactId = vendor.original.vendorContactId;
    // values.vendorId = vendor.original.vendorId;

    setIsLoading(true);
    const resp = await props.updateVendorContact(values);
    if (resp.type === UPDATE_VENDOR_CONTACT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      setIsLoading(false);
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createVendorContactRow = async (values) => {
    setIsLoading(true);
    const resp = await props.postVendorContact(values);
    if (resp.type === POST_VENDOR_CONTACT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      setIsLoading(false);
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteVendorContactRow = async (vendor) => {
    const id = vendor.original.vendorContactId;
    const resp = await props.deleteVendorContact(id);
    if (resp.type === DELETE_VENDOR_CONTACT_FAILURE) {
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const header = { accessorKey: 'state', value: 'WI', title: 'Wisconsin Vendor' };
  
  const crudTableCardSummaries = [
    { adjustedColumns: vendorsAdjustedColumns, rows: props.vendors, addButtonText: 'Add Vendor', tab: 1, updateRow: updateVendorRow, createRow: createVendorRow, deleteRow: deleteVendorRow, headerRow: header },
    { adjustedColumns: vendorContactsAdjustedColumns, rows: props.vendorContacts, addButtonText: 'Add Vendor Contact', tab: 2, updateRow: updateVendorContactRow, createRow: createVendorContactRow, deleteRow: deleteVendorContactRow },
  ];

  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Vendors</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!isLoading && props.vendors && (
          <div>
            <CrudTable 
              editingMode={'modal'} // Can be changed to 'row' to edit inline 
              columns={vendorsAdjustedColumns} 
              rows={props.vendors} 
              updateRow={updateVendorRow} 
              createRow={createVendorRow} 
              deleteRow={deleteVendorRow}
            />
            <CrudTableCard
              tabs={crudTableCardTabs}
              summaries={crudTableCardSummaries}
              numColumns={5}
            />
          </div>
          
        )}
        {isLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

const mapStateToProps = (state) => {
  const { vendors, vendorContacts } = state.authorizations;
  return {
    vendors,
    vendorContacts,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getVendors,
    deleteVendor,
    postVendor,
    updateVendor,
    deleteVendorContact,
    getVendorContacts,
    postVendorContact,
    updateVendorContact,
  }),
)(VendorsTable);