import { Box } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';

import ClientPolicyLumpSum from './policies/clientPolicyLumpSum.component';
import GeneralInformation from './policies/generalInformation.component';
import HomeSaleInformation from './homesale/homeSaleInformation.component';
import ImportantNotes from './policies/importantNotes.component';
import PolicyNotes from './dataTables/clientPolicyNotesTable.component';
import PropTypes from 'prop-types';

const ClientPoliciesTab = ({ formikProps, clientPolicyNotes }) => {

  return (
    <Box marginX={2} marginY={4}>
      <ImportantNotes clientId = {formikProps.values.clientId} />
      {clientPolicyNotes && <PolicyNotes clientId={formikProps.values.moveTrackClientId} />}
      <GeneralInformation clientId={formikProps.values.moveTrackClientId} />
      <HomeSaleInformation clientId={formikProps.values.moveTrackClientId} />
      <ClientPolicyLumpSum clientId={formikProps.values.clientId} formikProps={formikProps} />
    </Box>
  );  
};

ClientPoliciesTab.propTypes = {
  formikProps: PropTypes.object.isRequired,
  clientPolicyNotes: PropTypes.array,
  clientId: PropTypes.number,
};


const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  return {
    clientPolicyNotes,
  };
};
  
export default compose(
  connect(mapStateToProps, null),
)(ClientPoliciesTab);