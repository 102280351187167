//TODO - We need to pass in all the current values also .....

export const CreateApprovalApiRequest = (existingValues, newValues, id) => {
  if (!newValues) return null;

  const request = { ...existingValues };
  request.authorizationId = id;
  request.approvalSaleType = newValues.approvalSaleType;
  request.appraisedValue = newValues.appraisedValue;
  request.offerAmount = newValues.offerAmount;
  request.offerDate = newValues.offerDate;
  request.offerValid = newValues.offerValid;
  request.clientApprovedDate = newValues.clientApprovedDate;
  request.clientApprovedBy = newValues.clientApprovedBy;
  request.approvalConcessions = newValues.approvalConcessions;
  request.offerSentBy = newValues.offerSentBy;
  request.offerSentTo = newValues.offerSentTo;
  request.offerSent = newValues.offerSent;

  request.buyoutAgreementReceived = newValues.buyoutAgreementReceived;
  request.initialBillDate = newValues.initialBillDate;
  request.offerReceived = newValues.offerReceived;
  request.contingencies = newValues.contingencies;
  request.subjectTo = newValues.subjectTo;

  return request;
};

export const CreateAcceptanceApiRequest = (existingValues, newValues, id) => {
  if (!newValues) return null;

  const request = { ...existingValues };
  request.authorizationId = id;
  request.amdValAcctAmount = newValues.amdValAcctAmount;
  request.offerAcceptedAmount = newValues.offerAcceptedAmount;
  request.offerAccepted = newValues.offerAccepted;
  request.offerVerballyAccepted = newValues.offerVerballyAccepted;
  request.directedEquity = newValues.directedEquity;
  request.acceptanceConcessions = newValues.acceptanceConcessions;
  request.homeWarranty = newValues.homeWarranty;
  request.repairs = newValues.repairs;
  request.scheduledBuyout = newValues.scheduledBuyout;
  request.actualBuyout = newValues.actualBuyout;
  request.proration = newValues.proration;
  request.possession = newValues.possession;
  request.insure = newValues.insure;
  request.endInsure = newValues.endInsure;
  request.offerExtGranted = newValues.offerExtGranted;
  request.offerExtExpires = newValues.offerExtExpires;
  request.reAppraisalOrdered = newValues.reAppraisalOrdered;
  request.offerRejected = newValues.offerRejected;
  request.offerFollowUp = newValues.offerFollowUp;
  request.fund = newValues.fund;
  request.vacate = newValues.vacate;

  return request;
};

export const CreatePersonalPropertyApiRequest = (existingValues, newValues, id) => {
  if (!newValues) return null;

  const request = { ...existingValues };
  request.authorizationId = id;
  request.personalPropertyIncluded = newValues.personalPropertyIncluded;
  request.personalPropertyExcluded = newValues.personalPropertyExcluded;

  return request;
};

export const CreateTitleOrderApiRequest = (existingValues, newValues, id) => {
  if (!newValues) return null;

  const request = { ...existingValues };
  request.authorizationId = id;
  request.titleCompanyId = newValues.titleRepId;
  request.orderedDate = newValues.orderedDate;
  request.receivedDate = newValues.receivedDate;
  request.titleClear = newValues.titleClear;
  request.orderedNumber = newValues.orderedNumber;
  request.titleOrderDueDate = newValues.titleOrderDueDate;

  return request;
};

export const CreateTitleDocumentsApiRequest = (existingValues, newValues, id) => {
  if (!newValues) return null;

  const request = { ...existingValues };
  request.authorizationId = id;
  request.titleDocumentSentTo = newValues.titleDocumentSentTo;
  request.titleDocumentsAddress = newValues.titleDocumentsAddress;
  request.titleDocumentCity = newValues.titleDocumentCity;
  request.titleDocumentState = newValues.titleDocumentState;
  request.titleDocumentsZipcode = newValues.titleDocumentsZipcode;
  request.titleDocumentSentForSignature = newValues.titleDocumentSentForSignature;
  request.recievedBack = newValues.recievedBack;

  return request;
};

export const CreateLocalTitleCoApiRequest = (existingValues, newValues, id) => {
  if (!newValues) return null;

  const request = { ...existingValues };
  request.authorizationId = id;
  request.localTitleContact = newValues.localTitleContact;
  request.localTitleCompany = newValues.localTitleCompany;

  request.localTitleAddress = newValues.localTitleAddress;
  request.localTitleCity = newValues.localTitleCity;
  request.localTitleState = newValues.localTitleState;
  request.localTitleZipcode = newValues.localTitleZipcode;
  request.localTitlePhone = newValues.localTitlePhone;
  request.localTitleFax = newValues.localTitleFax;
  request.localTitleEmail = newValues.localTitleEmail;
  request.localTitleDocumentsSent = newValues.localTitleDocumentsSent;

  return request;
};

export const CreateImportantDeadlinesApiRequest = (existingValues, newValues, id) => {
  if (!newValues) return null;

  const request = { ...existingValues };
  
  request.authorizationId = id;
  request.loanApp = newValues.loanApp;
  request.loanCommit = newValues.loanCommit;
  request.creditInfo = newValues.creditInfo;
  request.dissapproval = newValues.dissapproval;
  request.loanDocs = newValues.loanDocs;
  request.existingLoan = newValues.existingLoan;
  request.loanTransfer = newValues.loanTransfer;
  request.appraisal = newValues.appraisal;
  request.title = newValues.title;
  request.survey = newValues.survey;
  request.documentRequest = newValues.documentRequest;
  request.governingDocs = newValues.governingDocs;
  request.offRecordMatters = newValues.offRecordMatters;
  request.offRecordmattersObj = newValues.offRecordmattersObj;
  request.rightOfRefusal = newValues.rightOfRefusal;
  request.sellersPropertyDisclose = newValues.sellersPropertyDisclose;
  request.inspectionObjection = newValues.inspectionObjection;
  request.resolution = newValues.resolution;

  return request;
};

export const CreateSendOfferPackageRequest = (existingValues, id) => {
  return {
    ...existingValues,
    offerPackageSent: true,
    authorizationId: id,
  };
};

export const CreateRequestInsurancePlacementRequest = (existingValues, id) => {
  return {
    ...existingValues,
    insurancePlacementSent: true,
    authorizationId: id,
  };
};

export const CreateResetButtonStatusApiRequest = (existingValues, id) => {
  return {
    ...existingValues,
    offerPackageSent: false,
    insurancePlacementSent: false,
    authorizationId: id,
  };
};
