import { MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const TempLivingAssignmentSelection = ({ selectedTempLivingAssignment, setSelectedTempLivingAssignment, temporaryLivingAssignments }) => {
  const handleChange = event => {
    const selectedAssignment = temporaryLivingAssignments.find(item => item.temporaryLivingId === event.target.value);
    setSelectedTempLivingAssignment(selectedAssignment);
  };

  return (
    <TextField
      select
      label="Assignments"
      value={selectedTempLivingAssignment?.temporaryLivingId || 0}
      onChange={handleChange}
      SelectProps={{
        autoWidth: true,
        displayEmpty: true
      }}
      variant="outlined"
      disabled={!temporaryLivingAssignments || temporaryLivingAssignments.length === 0}
    >
      {temporaryLivingAssignments?.length > 0 ? (
        temporaryLivingAssignments.map(item => (
          <MenuItem key={item.temporaryLivingId} value={item.temporaryLivingId}>
            {item.displayName}
          </MenuItem>
        ))
      ) : (
        <MenuItem key={0} value={0} disabled style={{ justifyContent: 'center', opacity: 1 }}>
          <em>No Assignments Available</em>
        </MenuItem>
      )}
    </TextField>
  );
};

TempLivingAssignmentSelection.propTypes = {
  temporaryLivingAssignments: PropTypes.array,
  selectedTemporaryLivingAssignment: PropTypes.object
};

export default React.memo(TempLivingAssignmentSelection);
