/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Button, Fab, FormControl, Grid, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme } from '@mui/material/styles';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { postExpenseLine, updateExpenseLine } from 'modules/expenses/expenses.actions';
import { InputAdornment } from '@material-ui/core';
import { EXPENSE_LINE_APPROVAL_STATUS } from '../expenseConstants';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import { POST_EXPENSE_LINE_FAILURE, UPDATE_EXPENSE_LINE_FAILURE } from 'modules/expenses/types';
import { checkDirty } from 'modules/authorizations/utils';
 
const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

const ReportLineItem = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { lineItem, expenseCodes, isReadOnly } = props;

  const [formState, setFormState] = useState({...lineItem});
  const [isApprovedAmountEnabled, setIsApprovedAmountEnabled] = useState((lineItem.approvalStatus === EXPENSE_LINE_APPROVAL_STATUS[2]));
  const [justChangeStatus, setJustChangedStatus] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    switch (formState.approvalStatus) {
        case EXPENSE_LINE_APPROVAL_STATUS[0]:
            setFormState(prevState => ({
                ...prevState,
                approved: '0.00',
                denied: prevState.amount,
            }));
            break;
        case EXPENSE_LINE_APPROVAL_STATUS[1]:
            setFormState(prevState => ({
                ...prevState,
                approved: '0.00',
                denied: 0.00,
            }));
            break;
        case EXPENSE_LINE_APPROVAL_STATUS[3]:
            setFormState(prevState => ({
                ...prevState,
                approved: parseFloat(prevState.amount).toFixed(2),
                denied: 0.00,
            }));
            break;
        case EXPENSE_LINE_APPROVAL_STATUS[2]:
            if(justChangeStatus){
                //Reset approved/denied after changeing from from status to another(i.e approved status to partial approved)
                setFormState(prevState => ({
                    ...prevState,
                    approved: lineItem.approved,
                    denied: (parseFloat(lineItem.amount === '' ? 0 : lineItem.amount) - parseFloat(lineItem.approved === '' ? 0 : lineItem.approved)).toFixed(2),
                }));
                setJustChangedStatus(false);
            } else {
                setFormState(prevState => ({
                    ...prevState,
                    denied: (parseFloat(prevState.amount === '' ? 0 : prevState.amount) - parseFloat(prevState.approved === '' ? 0 : prevState.approved)).toFixed(2),
                }));
            }
            break;
        default:
            break;
    }

    setIsApprovedAmountEnabled((formState.approvalStatus === EXPENSE_LINE_APPROVAL_STATUS[2]) && !isReadOnly);

    setIsDirty(checkDirty(lineItem, formState));
}, [formState.approvalStatus, formState.amount, formState.approved]);


  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const expenseCodeSearchInfo = {
    searchingColumns: [{ name: 'expenseCodeCode', title: 'Expense Code' }, { name: 'expenseCodeDescription', title: 'Description' }, { name: 'classification', title: 'Classification' }, { name: 'category', title: 'Category' },],
    searchingColumnExtensions: [{ columnName: 'expenseCodeCode', width: 150 }, { columnName: 'expenseCodeDescription', width: 250 }, { columnName: 'classification', width: 200 }, { columnName: 'category', width: 200 },],
    searchingRows: expenseCodes,
    idKey: 'expenseCodeId',
    nameKey: 'expenseCodeCode',
  };

  const payOptions = ['Yes', 'No', 'N/A'];

  const saveItem = async () => {
    setIsLoading(true);

    let newVals = { ...formState };
    newVals.expenseLineId = lineItem.expenseLineId;
    newVals.authorizationId = lineItem.authorizationId;
    newVals.expenseId = lineItem.expenseId;

    if(formState.approved === ''){
        //Default to 0
        formState.approved = 0;
    }

    if(formState.approvalStatus === EXPENSE_LINE_APPROVAL_STATUS[2]){
        //Determine if we should change the approval status
        let approved = parseFloat(formState.approved);
        if(!isNaN(approved)){
            if(approved === 0){
                newVals.approvalStatus = EXPENSE_LINE_APPROVAL_STATUS[0];
                formState.approvalStatus = EXPENSE_LINE_APPROVAL_STATUS[0];
            }
            else{
                let totalAmount = parseFloat(formState.amount);
                if(!isNaN(totalAmount)){
                    if(totalAmount === approved){
                        newVals.approvalStatus = EXPENSE_LINE_APPROVAL_STATUS[3];
                        formState.approvalStatus = EXPENSE_LINE_APPROVAL_STATUS[3];
                    }
                }
            }
        }
    }
    formState.approved = parseFloat(formState.approved).toFixed(2)
    formState.denied = parseFloat(formState.denied).toFixed(2)
    formState.amount = parseFloat(formState.amount).toFixed(2)

    newVals.approved = formState.approved.toString();
    newVals.denied = formState.denied.toString();
    newVals.amount = formState.amount.toString();

    const resp = await props.updateExpenseLine(newVals);
    if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
        props.showToast('Failed to update the expense line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
    } else { 
        props.showToast(`Successfully updated expense line ${lineItem.expenseLineId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
        setIsLoading(false);
        return true;
    }
  };

  const splitLine = async () => {
    setIsLoading(true);

    let newVals = { 
        expenseId: formState.expenseId, 
        authorizationId: formState.authorizationId, 
        amount: '0.00', 
        approved: '0.00', 
        denied: '0.00', 
        description: formState.description + ' Copy', 
        expenseCode: formState.expenseCode ? formState.expenseCode : null,
        expenseCodeId: formState.expenseCodeId ? formState.expenseCodeId : null,
        expenseCodeDescription: formState.expenseCodeDescription ? formState.expenseCodeDescription : null,
        cat: formState.cat ? formState.cat : null,
    };

    const resp = await props.postExpenseLine(newVals);
    if (resp.type === POST_EXPENSE_LINE_FAILURE) {
        props.showToast('Failed to split the expense line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
    } else { 
        props.showToast(`Successfully split expense line ${resp.response.expenseLineId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
        setIsLoading(false);
        return true;
    }
  }

  useEffect(() => {
    setIsDirty(checkDirty(lineItem, formState));
  }, [formState]);

  return (
        <Box>
            <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
                <Grid item md={2}>
                    <Select
                        labelId={'approvalStatus'}
                        key={'approvalStatus'}
                        label={''}
                        name={'approvalStatus'}
                        value={formState.approvalStatus}
                        sx={{ maxWidth: '140px', minWidth: '120px' }}
                        disabled={isReadOnly}
                        onChange={(e) => {
                                setJustChangedStatus(true);
                                setFormState({ ...formState, approvalStatus: e.target.value })
                            }
                        }
                    >
                        {EXPENSE_LINE_APPROVAL_STATUS.map((opt) => (
                            <MenuItem disabled={isReadOnly} key={opt} value={opt} sx={{ margin: 'dense' }}>
                                {opt}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item md={2}>
                    <Box sx={{paddingTop: '10px'}}>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                {formState.cat ? formState.cat : <div>&mdash;</div>}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item md={5}>
                    <TextField
                        key={'description'}
                        label={''}
                        name={'description'}
                        required={false}
                        disabled={isReadOnly}
                        value={formState.description}
                        sx={{ maxWidth: '500px' }}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFormState({ ...formState, description: e.target.value })
                        }
                    />
                </Grid>
                <Grid item md={2}>
                        <Select
                            labelId={'payOption'}
                            key={'payOption'}
                            label={''}
                            name={'payOption'}
                            value={formState.payOption}
                            sx={{ maxWidth: '150px', minWidth: '120px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                            setFormState({ ...formState, payOption: e.target.value })
                            }
                        >
                            {payOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt} value={opt} sx={{ margin: 'dense' }}>
                                    {opt}
                                </MenuItem>
                            ))}
                        </Select>
                </Grid>
                <Grid item md={2}>
                    <TextField
                        key={'amount'}
                        label={''}
                        name={'amount'}
                        required={false}
                        disabled={true}
                        value={isNaN(parseFloat(formState.amount)) ? formState.amount : parseFloat(formState.amount).toFixed(2)}
                        sx={{ maxWidth: '500px' }}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        type={'number'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        allowDecimal={true}
                        onChange={(e) =>
                            setFormState({ ...formState, amount: e.target.value })
                        }
                    />
                </Grid>
                <Grid item md={2}>
                <TextField
                        key={'approved'}
                        label={''}
                        name={'approved'}
                        required={false}
                        disabled={!isApprovedAmountEnabled}
                        value={formState.approved}
                        sx={{ maxWidth: '500px' }}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        type={'number'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        onChange={(e) => {
                            let inputValue = e.target.value;

                            if (inputValue.includes('.')) {
                                let splitStr = inputValue.split('.');
                                if (splitStr[1].length > 2) {
                                  splitStr[1] = splitStr[1].substring(0, 2);
                                }
                                inputValue = splitStr.join('.');
                            };

                            if (inputValue === '' || (!isNaN(parseFloat(inputValue)) && parseFloat(inputValue) >= 0)) {
                                let approvedAmount = inputValue === '' ? 0 : parseFloat(inputValue);

                                if(approvedAmount > parseFloat(formState.amount)){
                                    approvedAmount = parseFloat(formState.amount);
                                    inputValue = formState.amount;
                                }

                                setFormState(prevState => ({
                                    ...prevState,
                                    approved: inputValue,
                                    denied: prevState.amount - approvedAmount,
                                }));
                            }
                        }}
                    />
                </Grid>
                <Grid item md={2}>
                    <TextField
                        key={'denied'}
                        label={''}
                        name={'denied'}
                        required={false}
                        disabled={true}
                        value={isNaN(parseFloat(formState.denied)) ? formState.denied : parseFloat(formState.denied).toFixed(2)}
                        sx={{ maxWidth: '500px' }}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        type={'number'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        allowDecimal={true}
                        onChange={(e) =>
                            setFormState({ ...formState, denied: e.target.value })
                        }
                    />
                </Grid>
                <Grid item md={2}>
                    <Box>
                        {lineItem.hasAttachment ? <Typography variant="body2" sx={{ color: '#41B7C0', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>View Attachment</Typography> : <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px'}}>No Attachment</Typography>}
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => props.closeLineItem()}>
                        <FontAwesomeIcon size="1x" icon={['fas', 'xmark']} color={theme.palette.common.white} />
                    </Fab>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Reference
                            </Typography>
                        </div>
                        <TextField
                            key={'reference'}
                            label={''}
                            name={'reference'}
                            required={false}
                            disabled={isReadOnly}
                            value={formState.reference}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFormState({ ...formState, reference: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={3}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Expense Code
                            </Typography>
                        </div>
                        <FormControl key={''}>
                            <AdvancedSearch
                                key={'expenseCode'}
                                labelText={''}
                                displayValueAccessorKey={expenseCodeSearchInfo.nameKey}
                                saveIdValueAccessorKey={expenseCodeSearchInfo.idKey}
                                searchingCriteria={expenseCodeSearchInfo.searchingColumns}
                                searchingCriteriaExtensions={expenseCodeSearchInfo.searchingColumnExtensions}
                                searchingRows={expenseCodeSearchInfo.searchingRows}
                                id={formState.expenseCodeId}
                                value={formState.expenseCode}
                                setIdValue={(id, value) => {
                                    const expenseCodeInfo = expenseCodes.find(e => e.expenseCodeId === id);

                                    setFormState({ ...formState, expenseCode: value, expenseCodeId: id, expenseCodeDescription: expenseCodeInfo.expenseCodeDescription, cat: expenseCodeInfo.category });
                                }}
                                maxWidthsx={'360px'}
                                isReadOnly={isReadOnly}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item md={3}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Expense Code Desc
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                {formState.expenseCodeDescription ? formState.expenseCodeDescription : <div>&mdash;</div>}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                GL Number
                            </Typography>
                        </div>
                        <TextField
                            key={'glNumber'}
                            label={''}
                            name={'glNumber'}
                            required={false}
                            disabled={isReadOnly}
                            value={formState.glNumber}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFormState({ ...formState, glNumber: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={4}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Denied Reason
                            </Typography>
                        </div>
                        <TextField
                            key={'deniedReason'}
                            label={''}
                            name={'deniedReason'}
                            required={false}
                            disabled={isReadOnly}
                            value={formState.deniedReason}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFormState({ ...formState, deniedReason: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Report Date
                            </Typography>
                        </div>
                        <TextField
                            key={'reportDate'}
                            label={''}
                            name={'reportDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(formState.reportDate)}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                                setFormState({ ...formState, reportDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Expense Date
                            </Typography>
                        </div>
                        <TextField
                            key={'expenseDate'}
                            label={''}
                            name={'expenseDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(formState.expenseDate)}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                                setFormState({ ...formState, expenseDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Payroll Date
                            </Typography>
                        </div>
                        <TextField
                            key={'payrollDate'}
                            label={''}
                            name={'payrollDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(formState.payrollDate)}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                                setFormState({ ...formState, payrollDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Due Date
                            </Typography>
                        </div>
                        <TextField
                            key={'dueDate'}
                            label={''}
                            name={'dueDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(formState.dueDate)}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                                setFormState({ ...formState, dueDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                From Date
                            </Typography>
                        </div>
                        <TextField
                            key={'fromDate'}
                            label={''}
                            name={'fromDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(formState.fromDate)}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                                setFormState({ ...formState, fromDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                To Date
                            </Typography>
                        </div>
                        <TextField
                            key={'toDate'}
                            label={''}
                            name={'toDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(formState.toDate)}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={(e) =>
                                setFormState({ ...formState, toDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>

                <Grid item md={14}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '24px' }}>
                        {isLoading && <Spinner/>}
                        {!isReadOnly && !isLoading && <>
                            <Button onClick={splitLine} sx={{marginRight: '10px'}}>Split</Button>
                            <Button color="primary" variant="contained" onClick={saveItem} disabled={!isDirty}>
                                Save Line
                            </Button>
                        </>}
                    </Box>
                </Grid>

                {/* <Grid item md={2}>
                    <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Balance
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                {formState.approved ? formState.expenseCodeDescription : <div>&mdash;</div>}
                            </Typography>
                        </div>
                    </Box>
                </Grid> */}
            </Grid>
        </Box>
  );

};

export default compose(
  connect(null, {
    updateExpenseLine,
    postExpenseLine,
    showToast,
  }),
)(ReportLineItem);