const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const {
  GET_AUTH_TAX_ASSISTANCE_REQUEST,
  GET_AUTH_TAX_ASSISTANCE_SUCCESS,
  GET_AUTH_TAX_ASSISTANCE_FAILURE,
  POST_AUTH_TAX_ASSISTANCE_REQUEST,
  POST_AUTH_TAX_ASSISTANCE_SUCCESS,
  POST_AUTH_TAX_ASSISTANCE_FAILURE,
  UPDATE_AUTH_TAX_ASSISTANCE_REQUEST,
  UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS,
  UPDATE_AUTH_TAX_ASSISTANCE_FAILURE,
  GET_TAX_POLICIES_BY_CLIENT_REQUEST,
  GET_TAX_POLICIES_BY_CLIENT_SUCCESS,
  GET_TAX_POLICIES_BY_CLIENT_FAILURE,
} = require('../types/taxAssistanceTypes');

export const getAuthTaxAssistance = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_TAX_ASSISTANCE_REQUEST, GET_AUTH_TAX_ASSISTANCE_SUCCESS, GET_AUTH_TAX_ASSISTANCE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/taxassistance`,
      method: 'GET',
    },
  };
};

export const postAuthTaxAssistance = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_TAX_ASSISTANCE_REQUEST, POST_AUTH_TAX_ASSISTANCE_SUCCESS, POST_AUTH_TAX_ASSISTANCE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/taxassistance/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAuthTaxAssistance = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_TAX_ASSISTANCE_REQUEST, UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS, UPDATE_AUTH_TAX_ASSISTANCE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/taxassistance/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getTaxPoliciesByClientId = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_TAX_POLICIES_BY_CLIENT_REQUEST, GET_TAX_POLICIES_BY_CLIENT_SUCCESS, GET_TAX_POLICIES_BY_CLIENT_FAILURE],
      authenticated: true,
      endpoint: `api/TaxPolicys/GetByClientId/${id}`,
      method: 'GET',
    },
  };
};
