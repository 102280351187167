import { Checkbox, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { DELETE_HOMESALE_GBO_INSPECTIONS_FAILURE, GET_HOMESALE_GBO_INSPECTIONS_FAILURE, POST_HOMESALE_GBO_INSPECTIONS_FAILURE, UPDATE_HOMESALE_GBO_INSPECTIONS_FAILURE } from '../../types';
import { deleteHomesaleGboInspections, getHomesaleGboInspections, postHomesaleGboInspections, updateHomesaleGboInspections } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const HomeSaleGboInspections = (props) => {

  const whoIsResponsibleOptions = [
    { title: 'Client', id: 1 },
    { title: 'Transferee', id: 0 },
  ];

  const equityWitholdingOptions = [
    { title: 'Highest', id: 2 },
    { title: 'Average', id: 1 },
    { title: 'N/A', id: 0 },
  ];

  const adjustedColumns = [
    { accessorKey: 'inspectionType', header: 'Inspection Type', type: 'text', required: true, size: 75 },
    { accessorKey: 'alwaysOrder', header: 'Always Order', type: 'checkbox', defaultVal: false, required: false, size: 70, Cell: ({ cell }) => <Checkbox name="alwaysOrder" label="Active" checked={cell.row.original.alwaysOrder} disabled={true} /> },
    { accessorKey: 'orderUponRecommendation', header: 'Order Upon Recommendation', type: 'checkbox', defaultVal: false, required: true, size: 70, Cell: ({ cell }) => <Checkbox name="orderUponRecommendation" label="Active" checked={cell.row.original.orderUponRecommendation} disabled={true} /> },
    { accessorKey: 'whoIsResponsible', header: 'Who is responsible for repairs?', type: 'dropdown-v2', options: whoIsResponsibleOptions, size: 68, defaultVal: 0, required: true, Cell: ({ cell }) => cell.row.original.whoIsResponsible != null && <p>{whoIsResponsibleOptions.find((o) => o.id === cell.row.original.whoIsResponsible).title}</p> },
    { accessorKey: 'reInspectRequired', header: 'Re-Inspect Required?', type: 'checkbox', required: true, defaultVal: false, size: 70, hideInTable: true, Cell: ({ cell }) => <Checkbox name="reInspectRequired" label="Active" checked={cell.row.original.reInspectRequired} disabled={true} /> },
    { accessorKey: 'certifiedContractorReceiptRequired', header: 'Certified Contractor Receipt Required?', type: 'checkbox', defaultVal: false, required: true, size: 70, hideInTable: true, Cell: ({ cell }) => <Checkbox name="certifiedContractorReceiptRequired" label="Active" checked={cell.row.original.certifiedContractorReceiptRequired} disabled={true} /> },
    { accessorKey: 'repairsWithheldFromEquity', header: 'Repairs Withheld from Equity?', type: 'checkbox', defaultVal: false, required: true, size: 70, hideInTable: true, Cell: ({ cell }) => <Checkbox name="repairsWithheldFromEquity" label="Active" checked={cell.row.original.repairsWithheldFromEquity} disabled={true} /> },
    { accessorKey: 'capAmount', header: 'CAP Amount', type: 'text', required: false, size: 70, hideInTable: true },
    { accessorKey: 'equityWithholdingCalculation', header: 'Equity withholding calculation for major repairs require multiple estimates.', type: 'dropdown-v2', options: equityWitholdingOptions, size: 68, defaultVal: 0, required: true, hideInTable: true, Cell: ({ cell }) => cell.row.original.equityWithholdingCalculation != null && <p>{equityWitholdingOptions.find((o) => o.id === cell.row.original.equityWithholdingCalculation).title}</p> },
  ];

  const updateRow = async (eligibleProperty, values) => {
    const id = eligibleProperty.original.clientHomesaleGboInspectionsKey;
    values.clientHomesaleGboInspectionsKey = id;
    values.clientId = props.clientId;
    values.deletionDisabled = eligibleProperty.original.deletionDisabled;
    const resp = await props.updateHomesaleGboInspections(values);
    if (resp.type === UPDATE_HOMESALE_GBO_INSPECTIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleGboInspections(props.clientId);
      if (getResp.type === GET_HOMESALE_GBO_INSPECTIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postHomesaleGboInspections(values);
    if (resp.type === POST_HOMESALE_GBO_INSPECTIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleGboInspections(props.clientId);
      if (getResp.type === GET_HOMESALE_GBO_INSPECTIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (eligibleProperty) => {
    const id = eligibleProperty.original.clientHomesaleGboInspectionsKey;
    const resp = await props.deleteHomesaleGboInspections(id);
    if (resp.type === DELETE_HOMESALE_GBO_INSPECTIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleGboInspections(props.clientId);
      if (getResp.type === GET_HOMESALE_GBO_INSPECTIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      {!props.homesaleGboInspectionsLoading && (
        <CrudTable 
          editingMode={'modal'} // Can be changed to 'row' to edit inline 
          columns={adjustedColumns} 
          rows={props.clientHomesaleGboInspections} 
          updateRow={updateRow} 
          createRow={createRow} 
          deleteRow={deleteRow} 
        />
      )}
      {props.homesaleGboInspectionsLoading && (
        <Spinner />
      )}
    </>
  );
}; 

HomeSaleGboInspections.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientHomesaleGboInspections } = state.clients.clientsInfo[state.clients.currentClientId];
  const { homesaleGboInspectionsLoading } = state.clients;
  return {
    clientHomesaleGboInspections,
    homesaleGboInspectionsLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getHomesaleGboInspections,
    deleteHomesaleGboInspections,
    postHomesaleGboInspections,
    updateHomesaleGboInspections,
  }),
)(HomeSaleGboInspections);