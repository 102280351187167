import { POST_GLOBAL_INFO_FAILURE, UPDATE_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

import countries from 'modules/common/countries.json';
import { postFamilyMember, updateFamilyMember } from 'modules/authorizations/store/actions/authorizations.actions';
import { UPDATE_FAMILY_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';

const VisaPermitCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { globalInfo, id, isReadOnly, familyMemberId } = props;

  const applicationTypeOptions = [
    { title: 'Verbal', id: 1 },
    { title: 'Written', id: 2 },
  ];

  const appointmentOptions = [
    { title: 'Email', id: 1 },
    { title: 'Phone', id: 2 },
  ];


  const visaTypeOptions = [
    { title: 'AOPSEnding', id: 1 },
    { title: 'CPR', id: 2 },
    { title: 'E2', id: 3 },
    { title: 'H1B', id: 4 },
    { title: 'J1', id: 5 },
    { title: 'J2', id: 6 },
    { title: 'L1A', id: 7 },
    { title: 'L1B', id: 8 },
    { title: 'L2', id: 9 },
    { title: 'LPR', id: 10 },
    { title: 'O1', id: 11 },
    { title: 'O2', id: 12 },
    { title: 'O3', id: 13 },
    { title: 'TC', id: 14 },
    { title: 'TN', id: 15 },
  ];

  const visaStatusOptions = [
    { title: 'Application', id: 1 },
    { title: 'Approved', id: 2 },
    { title: 'Denied', id: 3 },
    { title: 'Expired', id: 4 },
    { title: 'Filed', id: 5 },
    { title: 'In Progress', id: 6 },
    { title: 'Inactive', id: 7 },
    { title: 'Pending', id: 8 },
    { title: 'Processing', id: 9 },
  ];

  const countriesBasic = ['USA', 'UK', 'JAP', 'DEU' ];

  const tabs = [
    { header: 'Visa/Immigration', order: 1, iconPath: ['fas', 'passport'] },
  ];

  const summaries = [
    { header: 'Current Visa', accessorKey: 'currentVisa', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.currentVisa : '', options: visaTypeOptions, tab: 1 },
    { header: 'Visa Expiry Date', accessorKey: 'visaExpiryDate', type: 'date', required: false, value: globalInfo ? globalInfo.visaExpiryDate : '', tab: 1 },
    { header: 'Visa Max Date', accessorKey: 'visaMaxDate', type: 'date', required: false, value: globalInfo ? globalInfo.visaMaxDate : '', tab: 1 },
    { header: 'Visa Issued By', accessorKey: 'visaIssuedBy', type: familyMemberId === 0 ? 'dropdown-v2' : 'dropdown', required: false, value: globalInfo ? globalInfo.visaIssuedBy : '', options: familyMemberId === 0 ? countries : countriesBasic, tab: 1 },
    { header: 'Visa Document Number', accessorKey: 'visaDocumentNumber', type: 'text', required: false, value: globalInfo ? globalInfo.visaDocumentNumber : '', tab: 1 },
    // { header: 'Home Country', accessorKey: 'visaCenter', type: 'dropdown-v2', required: false,  value: globalInfo ? globalInfo.homeCountry : '', options: countries, tab: 1 },
    { header: 'Visa Status', accessorKey: 'visaStatus', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.visaStatus : '', options: visaStatusOptions, tab: 1 },
    // { header: 'Citizenship', accessorKey: 'visaStatusDate', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.citizenship : '', options: countries, tab: 1 },
    { header: 'Application Type', accessorKey: 'visaApplicationType', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.visaApplicationType : '', options: applicationTypeOptions, tab: 1 },
    { header: 'Appt Scheduled Date', accessorKey: 'visaAppointmentScheduleDate', type: 'date', required: false, value: globalInfo ? globalInfo.visaAppointmentScheduleDate : '', tab: 1 },
    { header: 'Appt Scheduled Method', accessorKey: 'vAppointmentScheduleMethod', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.vAppointmentScheduleMethod : '', options: appointmentOptions, tab: 1 },
    { header: 'Interview Date', accessorKey: 'visaInterviewDate', type: 'date', required: false, value: globalInfo ? globalInfo.visaInterviewDate : '', tab: 1 },
    { header: 'Consulate', accessorKey: 'consulate', type: 'text', required: false, value: globalInfo ? globalInfo.consulate : '', tab: 1 },
    { header: 'Receipt Number', accessorKey: 'visaReceiptNumber', type: 'text', required: false, value: globalInfo ? globalInfo.visaReceiptNumber : '', tab: 1 },
    //{ header: '', accessorKey: 'visaReceiptDate', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.eligibleForTaxService : '', options: basicOptions, tab: 1 },
    { header: 'Travel To Date', accessorKey: 'travelTo', type: 'date', required: false, value: globalInfo ? globalInfo.travelTo : '', tab: 1 },
    { header: 'Passport Received', accessorKey: 'passportReceived', type: 'date', required: false, value: globalInfo ? globalInfo.passportReceived : '', tab: 1 },
    //{ header: '', accessorKey: 'passportIssuedBy', type: 'text', required: false, value: globalInfo ? globalInfo.jobDescription : '', tab: 1 },
    { header: 'Passport Number', accessorKey: 'passportNumber', type: 'text', required: false, value: globalInfo ? globalInfo.passportNumber : '', tab: 1 },
    { header: 'Passport Expiry', accessorKey: 'passportExpires', type: 'date', required: false, value: globalInfo ? globalInfo.passportExpires : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (globalInfo && globalInfo.length !== 0) {
      let newValues = { ...globalInfo };

      newValues.currentVisa = values.currentVisa;
      newValues.visaExpiryDate = values.visaExpiryDate;
      newValues.visaMaxDate = values.visaMaxDate;
      newValues.visaIssuedBy = values.visaIssuedBy;
      newValues.visaDocumentNumber = values.visaDocumentNumber;
      newValues.visaStatus = values.visaStatus;
      newValues.visaApplicationType = values.visaApplicationType;
      newValues.visaAppointmentScheduleDate = values.visaAppointmentScheduleDate;
      newValues.vAppointmentScheduleMethod = values.vAppointmentScheduleMethod;
      newValues.visaInterviewDate = values.visaInterviewDate;
      newValues.consulate = values.consulate;
      newValues.visaReceiptNumber = values.visaReceiptNumber;
      newValues.travelTo = values.travelTo;
      newValues.passportReceived = values.passportReceived;
      newValues.passportNumber = values.passportNumber;
      newValues.passportExpires = values.passportExpires;

      if (familyMemberId === 0) {
        const resp = await props.updateGlobalInfo(newValues);
        if (resp.type === UPDATE_GLOBAL_INFO_FAILURE) {
          props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      } else {
        const resp = await props.updateFamilyMember(newValues);
        if (resp.type === UPDATE_FAMILY_FAILURE) {
          props.showToast('Failed to update family member info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      }

    } else {
      values.authorizationId = id;

      if (familyMemberId === 0) {
        const resp = await props.postGlobalInfo(values);
        if (resp.type === POST_GLOBAL_INFO_FAILURE) {
          props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      } 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    updateFamilyMember,
    postFamilyMember,
    showToast,
  }),
)(VisaPermitCard);