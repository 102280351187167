export const SERVICE_FILTER_TYPES = ['None', 'All Expenses', 'Tax Assistance', 'Homesale', 'Destination', 'Miscellaneous'];
export const DATE_FILTER_TYPES = [
  'None',
  'All Expenses',
  'Not Payroll Posted',
  'Not AP Posted',
  'Not AR Posted',
  'Not AR Paid',
  'Payroll Posted',
  'AP Posted',
  'AR Posted',
  'AR Paid',
  'Payroll Date Between',
  'Entry Date Between',
  'Tax Report Date Between',
  'Invoice Date Between'
];
export const PAYROLL_FILTER_TYPES = ['All Types', 'T', 'N', 'F', 'E', 'V', 'MT', 'MN', 'O', 'P'];
export const EXPENSE_LINE_APPROVAL_STATUS = ['Denied', 'Pending', 'Partial Approved', 'Approved']