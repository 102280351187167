/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Card, CardContent, Fab, Grid, Tooltip, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import AgentsCard from './agents.component';
import ShipmentInfoCard from './shipmentInfoCard.component';
import ShipmentMoveDates from './carShipmentMoveDates.component';
import Spinner from 'modules/common/spinner.component';
import SplitShipmentCard from './splitShipmentCard.component';
import WeightInfoCard from './weightInfoCard.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const Shipment = (props) => {    
  const { details, location, id, segmentId, isReadOnly } = props;

  const [isLoading, setIsLoading] = useState(false);

  const segmentInfo = details?.authHouseHoldGoods?.hhgSegments ? details?.authHouseHoldGoods?.hhgSegments.find((s) => s.segmentId === segmentId) : null;

  const enableSplitShipment = async () => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.secondaryShippingRequired = true;
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const removeSplitShipment = async () => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.secondaryShippingRequired = false;
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <AgentsCard segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} />
          <Box sx={{ paddingTop: '16px' }}>
            <ShipmentMoveDates segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} />
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <ShipmentInfoCard segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} />

          {segmentInfo.type === 1 && (
            <Box sx={{ paddingTop: '16px' }}>
              <WeightInfoCard segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} />
            </Box>
          )}

          <Box sx={{ paddingTop: '16px' }}>
            {!segmentInfo.secondaryShippingRequired && !isLoading && (
              <Card>
                <Box>
                  <CardContent>
                    <div className="row justify-space-between align-center">
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title="Estimates" key="Estimates" placement="right">
                          <FontAwesomeIcon icon={['fas', 'arrows-split-up-and-left']} size="2x" />
                        </Tooltip>
                        <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                          Split Shipment
                        </Typography>
                      </Box>
                      <Fab color="primary" size="small" onClick={enableSplitShipment}>
                        <FontAwesomeIcon size="1x" icon={['far', 'plus']} color={'white'} />
                      </Fab>
                    </div>
                  </CardContent>
                </Box>
              </Card>
            )}
            {segmentInfo.secondaryShippingRequired && !isLoading && <SplitShipmentCard segmentInfo={segmentInfo} id={id} hideEdit={location.pathname.includes('expenses') || isReadOnly} removeSplitShipment={removeSplitShipment} />}
            {isLoading && <Spinner />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

};

Shipment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    updateHhgSegment,
    showToast,
  }),
)(Shipment);