/* eslint-disable react-hooks/exhaustive-deps */

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { POST_HOMESALE_INFO_FAILURE, UPDATE_HOMESALE_INFO_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postHomesaleInfo, updateHomesaleInfo } from 'modules/authorizations/store/actions/homesale.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, Fab, Grid, Stack, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));


const EditCardModal = (props) => {
  const { open, onSubmit, isLoading, onClose, homesaleInfo, appraisalsBmas, appraisalsSelected, currencyFormatter, groupAppraisals, statusOptions } = props;
  const classes = useStyles();

  const [brokerSpreadOneId, setBrokerSpreadOneId] = useState(homesaleInfo ? homesaleInfo.brokerSpreadOneId : null);
  const [brokerSpreadTwoId, setBrokerSpreadTwoId] = useState(homesaleInfo ? homesaleInfo.brokerSpreadTwoId : null);
  const [appraisalSpreadOneId, setAppraisalSpreadOneId] = useState(homesaleInfo ? homesaleInfo.appraisalSpreadOneId : null);
  const [appraisalSpreadTwoId, setAppraisalSpreadTwoId] = useState(homesaleInfo ? homesaleInfo.appraisalSpreadTwoId : null);

  const [isDirty, setIsDirty] = useState(false);

  const onSubmitModal = (e) => {
    e.preventDefault();

    let values = {};

    values.appraisalSpreadOneId = appraisalSpreadOneId;
    values.appraisalSpreadTwoId = appraisalSpreadTwoId;
    values.brokerSpreadOneId = brokerSpreadOneId;
    values.brokerSpreadTwoId = brokerSpreadTwoId;

    onSubmit(values);
  };

  const checkedSpread = (e, group) => {
    if (appraisalsSelected) {
      if (e.target.checked) {
        setAppraisalSpreadOneId(group[0].appraisalId);
        setAppraisalSpreadTwoId(group[1].appraisalId);
      } else {
        setAppraisalSpreadOneId(null);
        setAppraisalSpreadTwoId(null);
      }
    } else {
      if (e.target.checked) {
        setBrokerSpreadOneId(group[0].appraisalId);
        setBrokerSpreadTwoId(group[1].appraisalId);
      } else {
        setBrokerSpreadOneId(null);
        setBrokerSpreadTwoId(null);
      }
    }
  };

  useEffect(() => {
    if (homesaleInfo) {
      if (brokerSpreadOneId !== homesaleInfo.brokerSpreadOneId || brokerSpreadTwoId !== homesaleInfo.brokerSpreadTwoId || appraisalSpreadOneId !== homesaleInfo.appraisalSpreadOneId || appraisalSpreadTwoId !== homesaleInfo.appraisalSpreadTwoId) {
        setIsDirty(true);
      }
    }

  }, [brokerSpreadOneId, brokerSpreadTwoId, appraisalSpreadOneId, appraisalSpreadTwoId]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Move Dates" key="Move Dates" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-bill-trend-up']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Spread Selection
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Code</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Name</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>PSP Written</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      { appraisalsSelected ? 'Appraised Value' : 'MLSP'}
                      </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Status</Typography>
                  </div>
                </Box>
              </Grid>

              {appraisalsBmas.length > 0 && appraisalsBmas.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1)).map((a) => {
                return (
                  <>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.appraiserCode ? a.appraiserCode : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="3">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.appraiserName ? a.appraiserName : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="3">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.appraisedValueWritten ? currencyFormatter.format(a.appraisedValueWritten) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.suggestedListWritten ? currencyFormatter.format(a.suggestedListWritten) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.status ? statusOptions.find((s) => s.id === a.status).title : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                  </>
                );
              })}

              {appraisalsBmas.length === 0 && (
                <>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                </>
              )}

              <Box sx={{ width: '100%' }}>
                <hr />
              </Box>

              <Grid item md="1">
                <Box />
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Spread Codes</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>SLP Spread %</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>PSP Spread %</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Average PSP Written</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Average SLP Written</Typography>
                  </div>
                </Box>
              </Grid>

              {appraisalsBmas.length > 0 && groupAppraisals(appraisalsBmas).map((g) => {
                let suggestedListSpreadPercent = (g[0].suggestedListWritten && g[1].suggestedListWritten) ? Math.abs((1 - (g[0].suggestedListWritten / g[1].suggestedListWritten)) * 100).toFixed(2) : null;
                let appraisedValueSpreadPercent = (g[0].appraisedValueWritten && g[1].appraisedValueWritten) ? Math.abs((1 - (g[0].appraisedValueWritten / g[1].appraisedValueWritten)) * 100).toFixed(2) : null;

                return (
                  <>
                    <Grid item md="1">
                      <Box>
                        <Box sx={{ marginBottom: '20px' }}>
                          <Checkbox name="spreadSelected" label="Active" sx={{ padding: '0px' }} onChange={(e) => checkedSpread(e, g)} checked={appraisalsSelected ? (appraisalSpreadOneId && appraisalSpreadTwoId && appraisalSpreadOneId === g[0].appraisalId && appraisalSpreadTwoId === g[1].appraisalId) : (brokerSpreadOneId && brokerSpreadTwoId && brokerSpreadOneId === g[0].appraisalId && brokerSpreadTwoId === g[1].appraisalId)} disabled={false} />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md="3">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{`${g[0].appraiserCode} / ${g[1].appraiserCode}`}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: (suggestedListSpreadPercent && Number(suggestedListSpreadPercent) > 5) ? 'red' : '#43423a', fontWeight: 500, fontSize: '1rem' }}>{suggestedListSpreadPercent ? suggestedListSpreadPercent : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: (appraisedValueSpreadPercent && Number(appraisedValueSpreadPercent) > 5) ? 'red' : '#43423a', fontWeight: 500, fontSize: '1rem' }}>{appraisedValueSpreadPercent ? appraisedValueSpreadPercent : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(g[0].appraisedValueWritten && g[1].appraisedValueWritten) ? currencyFormatter.format(((g[0].appraisedValueWritten + g[1].appraisedValueWritten) / 2).toFixed(2)) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(g[0].suggestedListWritten && g[1].suggestedListWritten) ? currencyFormatter.format(((g[0].suggestedListWritten + g[1].suggestedListWritten) / 2).toFixed(2)) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                  </>
                );
              })}

              {appraisalsBmas.length === 0 && (
                <>
                  <Grid item md="1">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                </>
              )}

            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>Save</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const SpreadSelectorCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const statusOptions = [
    { title: 'Used', id: 1 },
    { title: 'Cancelled', id: 2 },
    { title: 'Dropped', id: 3 },
    { title: 'Approved', id: 4 },
    { title: 'Reappraisal', id: 5 },
    { title: 'Dest. Appraisal', id: 6 },
    { title: 'Appraisal Reconsideration', id: 7 },
  ];

  const { appraisalsBmas, appraisalsSelected, homesaleInfo, id, isReadOnly } = props;

  const updateFields = async (values) => {
    setIsLoading(true);

    let newVals = { ...homesaleInfo };
    
    newVals.appraisalSpreadOneId = values.appraisalSpreadOneId;
    newVals.appraisalSpreadTwoId = values.appraisalSpreadTwoId;
    newVals.brokerSpreadOneId = values.brokerSpreadOneId;
    newVals.brokerSpreadTwoId = values.brokerSpreadTwoId;

    if (!homesaleInfo) {
      newVals.authorizationId = id;
  
      let resp = await props.postHomesaleInfo(newVals);
      if (resp.type === POST_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to update homesale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
  
      setIsLoading(false);
      return true;
    } else {
      let resp = await props.updateHomesaleInfo(newVals);
      if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to select spread, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }

      setIsLoading(false);
      return true;
    }
  };

  const handleSaveEdits = async (values) => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update moving dates data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } 
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const groupAppraisals = (appraisalBmasTemp) => {
    let pairs = [];

    for (let i = 0; i < appraisalBmasTemp.length; i++) {
      for (let j = i + 1; j < appraisalBmasTemp.length; j++) {
        pairs.push([appraisalBmasTemp[i], appraisalBmasTemp[j]]);
      }
    }

    return pairs;
  };
    
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'money-bill-trend-up']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Spread Selection
                </Typography>
              </div>
              {!isReadOnly && <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
              </Fab>
              }
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2}>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Code</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Name</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>PSP Written</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                    { appraisalsSelected ? 'Appraised Value' : 'MLSP'}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Status</Typography>
                  </div>
                </Box>
              </Grid>

              {appraisalsBmas.length > 0 && appraisalsBmas.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1)).map((a) => {
                return (
                  <>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.appraiserCode ? a.appraiserCode : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="3">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.appraiserName ? a.appraiserName : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="3">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.appraisedValueWritten ? currencyFormatter.format(a.appraisedValueWritten) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.suggestedListWritten ? currencyFormatter.format(a.suggestedListWritten) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{a.status ? statusOptions.find((s) => s.id === a.status).title : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                  </>
                );
              })}

              {appraisalsBmas.length === 0 && (
                <>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                </>
              )}

              <Box sx={{ width: '100%' }}>
                <hr />
              </Box>

              <Grid item md="1">
                <Box />
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Spread Codes</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>SLP Spread %</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>PSP Spread %</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Average PSP Written</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Average SLP Written</Typography>
                  </div>
                </Box>
              </Grid>

              {appraisalsBmas.length > 0 && groupAppraisals(appraisalsBmas).map((g) => {
                let suggestedListSpreadPercent = (g[0].suggestedListWritten && g[1].suggestedListWritten) ? Math.abs((1 - (g[0].suggestedListWritten / g[1].suggestedListWritten)) * 100).toFixed(2) : null;
                let appraisedValueSpreadPercent = (g[0].appraisedValueWritten && g[1].appraisedValueWritten) ? Math.abs((1 - (g[0].appraisedValueWritten / g[1].appraisedValueWritten)) * 100).toFixed(2) : null;

                return (
                  <>
                    <Grid item md="1">
                      <Box>
                        <Box sx={{ marginBottom: '20px' }}>
                          <Checkbox name="spreadSelected" label="Active" sx={{ padding: '0px' }} checked={appraisalsSelected ? (homesaleInfo && homesaleInfo.appraisalSpreadOneId === g[0].appraisalId && homesaleInfo.appraisalSpreadTwoId === g[1].appraisalId) : (homesaleInfo && homesaleInfo.brokerSpreadOneId === g[0].appraisalId && homesaleInfo.brokerSpreadTwoId === g[1].appraisalId)} disabled={true} />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md="3">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{`${g[0].appraiserCode} / ${g[1].appraiserCode}`}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: (suggestedListSpreadPercent && Number(suggestedListSpreadPercent) > 5) ? 'red' : '#43423a', fontWeight: 500, fontSize: '1rem' }}>{suggestedListSpreadPercent ? suggestedListSpreadPercent : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: (appraisedValueSpreadPercent && Number(appraisedValueSpreadPercent) > 5) ? 'red' : '#43423a', fontWeight: 500, fontSize: '1rem' }}>{appraisedValueSpreadPercent ? appraisedValueSpreadPercent : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(g[0].appraisedValueWritten && g[1].appraisedValueWritten) ? currencyFormatter.format(((g[0].appraisedValueWritten + g[1].appraisedValueWritten) / 2).toFixed(2)) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md="2">
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(g[0].suggestedListWritten && g[1].suggestedListWritten) ? currencyFormatter.format(((g[0].suggestedListWritten + g[1].suggestedListWritten) / 2).toFixed(2)) : <div>&mdash;</div>}</Typography>
                        </div>
                      </Box>
                    </Grid>
                  </>
                );
              })}

              {appraisalsBmas.length === 0 && (
                <>
                  <Grid item md="1">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="3">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md="2">
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}><div>&mdash;</div></Typography>
                      </div>
                    </Box>
                  </Grid>
                </>
              )}

            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          homesaleInfo={homesaleInfo}
          appraisalsBmas={appraisalsBmas}
          appraisalsSelected={appraisalsSelected}
          currencyFormatter={currencyFormatter}
          groupAppraisals={groupAppraisals}
          statusOptions={statusOptions}
        />
      )}
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateHomesaleInfo,
    postHomesaleInfo,
    showToast,
  }),
)(SpreadSelectorCard);