import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, MenuItem, Select, TextField, useTheme, withStyles  } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { getClientLumpSum, updateClientLumpSum } from '../../clients.actions';


const styles = (theme) => ({
  accordionStyle: {
    maxWidth: 1050,
  },
  buttonContainer: {
    marginLeft: 16,
    marginTop: 16,
  },
  row: {
    backgroundColor: theme.palette.common.white,
  },
  accordionStyleContacts: {
    maxWidth: 1050,
  },
  inputGroup: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  root: {
    maxWidth: 170,
    marginRight: 10,
  },
  extRoot: {
    width: 115,
    marginRight: 10,
  },
  newRow: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1050,
  },
  detailsLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
  },
  contentCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstCol: {
    width: 200,
  },
  textField: {
    minWidth: 350,
  },
  descText: {
    minWidth: 350,
    marginBottom: 0,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tabBorder: {
    borderLeft: `2px solid ${theme.palette.grey[400]}`,
    marginLeft: theme.spacing(2),
  },
});

const ClientPolicyLumpSum = (props) => {
  const [clientOverview, setClientOverview] = useState(false);
  const formikProps = props.formikProps;
  const [lumpSumEligibility, setLumpSumElgibility] = useState(formikProps && formikProps.values.lumpSumEligibility ? formikProps.values.lumpSumEligibility.split(',') : []);

  const theme = useTheme();

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyle) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <h2>Lump Sum</h2>
      </AccordionSummary>
      <AccordionDetails>
        <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
          <div className={classNames(props.classes.contentCol)}>
            <p className={props.classes.descText}>Who is eligible for this benefit?</p>
            <Select
              className={props.classes.textField}
              variant="outlined"
              fullWidth
              label={'Who is Eligible?'}
              onChange={(e) => setLumpSumElgibility(e.target.value)}
              multiple
              value={lumpSumEligibility}
              renderValue={(selected) => {
                return (
                  <div className={props.classes.chips}>
                    {selected.map((value) => {
                      const policyInfo = props.clientPolicyNotes.find((o) => o.clientPolicyNotesKey === parseInt(value));
                      return (
                        <Chip
                          key={policyInfo.clientPolicyNotesKey}
                          label={policyInfo.name}
                          className={props.classes.chip}
                          onDelete={() => {
                            setLumpSumElgibility(lumpSumEligibility.filter((b) => b !== value));
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      );
                    })}
                  </div>
                );}}
            >
              {props.clientPolicyNotes && props.clientPolicyNotes.map((c) => {
                return <MenuItem key={c.clientPolicyNotesKey} value={c.clientPolicyNotesKey}>{c.name}</MenuItem>;
              })}
            </Select>
            <p className={props.classes.descText}>Are there limits to what the Lump Sum covers?</p>
            <TextField
              id="name"
              name="lumpSumLimits"
              label="Lump Sum Limits"
              variant="outlined"
              fullWidth
              style={{ minWidth: '100%' }}
              className={props.classes.root}
              value={formikProps.values.lumpSumLimits}
            />
            <p className={props.classes.descText}>How is it calculated? Or is it a standard amount?</p>
            <TextField
              id="name"
              name="lumpSumCalculated"
              label="Lump Sum Calculated"
              variant="outlined"
              fullWidth
              style={{ minWidth: '100%' }}
              className={props.classes.root}
              value={formikProps.values.lumpSumCalculated}
            />
            <p className={props.classes.descText}>Who calculates the amount?</p>
            <TextField
              id="name"
              name="whoCalcsLumpSum"
              label="Who Calculates Lump Sum"
              variant="outlined"
              fullWidth
              style={{ minWidth: '100%' }}
              className={props.classes.root}
              value={formikProps.values.whoCalculatesLumpSum}
            />
          </div>
          <div className={props.classes.contentCol}>
            <p className={props.classes.descText}>Who funds?</p>
            <TextField
              className={props.classes.textField}
              variant="outlined"
              fullWidth
              label={'Who funds?'}
              select
              value={formikProps.values.lumpSumFunding}
            >
              <MenuItem value={1}>Client</MenuItem>
              <MenuItem value={0}>TRC</MenuItem>
            </TextField>
            <p className={props.classes.descText}>When is the lump sum funded?</p>
            <TextField
              id="name"
              name="whenFunded"
              label="When is Lump Sum funded"
              variant="outlined"
              fullWidth
              style={{ minWidth: '100%' }}
              className={props.classes.root}
              value={formikProps.values.whenLumpSumFunded}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <p className={props.classes.descText}>Is this benefit grossed up?</p>
            <TextField
              className={props.classes.textField}
              variant="outlined"
              fullWidth
              label={'Gross Up'}
              select
              value={formikProps.values.lumpSumGrossedUp}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </TextField>
            <p className={props.classes.descText}>Other Notes:</p>
            <TextField
              variant="outlined"
              label={'Other Notes'}
              value={formikProps.values.notes}
              multiline
              minRows={5}
              sx={{ width: '1100px' }}
            />
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );  
};

ClientPolicyLumpSum.propTypes = {
  classes: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
  clientId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientLumpSum } = state.clients.clientsInfo[state.clients.currentClientId];
  return {
    clientPolicyNotes,
    clientLumpSum,
  };
};

  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getClientLumpSum,
    showToast,
    updateClientLumpSum,
  }),
)(ClientPolicyLumpSum);