/* eslint-disable react-hooks/exhaustive-deps */

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import React, { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, Dialog, DialogActions, Fab, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';

import { formatDateForInput } from 'utilities/common';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const EditCardModal = props => {
  const { open, onSubmit, isLoading, onClose, estimateInfo } = props;
  const classes = useStyles();

  const [estimatedLongTermStorageStart, setEstimatedLongTermStorageStart] = useState(
    estimateInfo ? estimateInfo.estimatedLongTermStorageStart : ''
  );
  const [estimatedLongTermStorageEnd, setEstimatedLongTermStorageEnd] = useState(
    estimateInfo ? estimateInfo.estimatedLongTermStorageEnd : ''
  );
  const [actualLongTermStorageStart, setActualLongTermStorageStart] = useState(estimateInfo ? estimateInfo.actualLongTermStorageStart : '');
  const [actualLongTermStorageEnd, setActualLongTermStorageEnd] = useState(estimateInfo ? estimateInfo.actualLongTermStorageEnd : '');

  const [isDirty, setIsDirty] = useState(false);

  const onSubmitModal = e => {
    e.preventDefault();

    const values = {
      estimatedLongTermStorageStart,
      estimatedLongTermStorageEnd,
      actualLongTermStorageStart,
      actualLongTermStorageEnd
    };

    onSubmit(values);
  };

  useEffect(() => {
    if (estimateInfo) {
      if (
        (!(estimatedLongTermStorageStart === '' && estimateInfo.estimatedLongTermStorageStart == null) &&
          estimatedLongTermStorageStart !== estimateInfo.estimatedLongTermStorageStart) ||
        (!(estimatedLongTermStorageEnd === '' && estimateInfo.estimatedLongTermStorageEnd == null) &&
          estimatedLongTermStorageEnd !== estimateInfo.estimatedLongTermStorageEnd) ||
        (!(actualLongTermStorageStart === '' && estimateInfo.actualLongTermStorageStart == null) &&
          actualLongTermStorageStart !== estimateInfo.actualLongTermStorageStart) ||
        (!(actualLongTermStorageEnd === '' && estimateInfo.actualLongTermStorageEnd == null) &&
          actualLongTermStorageEnd !== estimateInfo.actualLongTermStorageEnd)
      ) {
        setIsDirty(true);
      }
    }
  }, [estimatedLongTermStorageStart, estimatedLongTermStorageEnd, actualLongTermStorageStart, actualLongTermStorageEnd]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Move Dates" key="Move Dates" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Move Dates
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem'
            }}
          >
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Estimated Start
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Estimated End
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Actual Start
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Actual End
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Long Term Storage
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedLongTermStorageStart'}
                    label={''}
                    name={'estimatedLongTermStorageStart'}
                    value={formatDateForInput(estimatedLongTermStorageStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={e => setEstimatedLongTermStorageStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedLongTermStorageEnd'}
                    label={''}
                    name={'estimatedLongTermStorageEnd'}
                    value={formatDateForInput(estimatedLongTermStorageEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={e => setEstimatedLongTermStorageEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualLongTermStorageStart'}
                    label={''}
                    name={'actualLongTermStorageStart'}
                    value={formatDateForInput(actualLongTermStorageStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={e => setActualLongTermStorageStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualLongTermStorageEnd'}
                    label={''}
                    name={'actualLongTermStorageEnd'}
                    value={formatDateForInput(actualLongTermStorageEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={e => setActualLongTermStorageEnd(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>
              Save
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const GoodShipmentMoveDatesCard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const { estimateInfo } = props;

  const updateFields = async values => {
    setIsLoading(true);

    let newVals = { ...estimateInfo };

    newVals.estimatedLongTermStorageStart = values.estimatedLongTermStorageStart;
    newVals.estimatedLongTermStorageEnd = values.estimatedLongTermStorageEnd;
    newVals.actualLongTermStorageStart = values.actualLongTermStorageStart;
    newVals.actualLongTermStorageEnd = values.actualLongTermStorageEnd;

    const resp = await props.updateHhgEstimate(newVals);
    if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
      props.showToast('Failed to update the moving dates segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      setIsLoading(false);
      return true;
    }
  };

  const handleSaveEdits = async values => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update moving dates data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'calendar-days']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Moving Dates
                </Typography>
              </div>
              {!props.hideEdit && (
                <>
                  <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                    <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                  </Fab>
                </>
              )}
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Estimated Start
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Estimated End
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Actual Start
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Actual End
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Long Term Storage
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo && estimateInfo.estimatedLongTermStorageStart ? (
                        new Date(estimateInfo.estimatedLongTermStorageStart).toLocaleDateString('en-us')
                      ) : (
                        <div>&mdash;</div>
                      )}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo && estimateInfo.estimatedLongTermStorageEnd ? (
                        new Date(estimateInfo.estimatedLongTermStorageEnd).toLocaleDateString('en-us')
                      ) : (
                        <div>&mdash;</div>
                      )}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo && estimateInfo.actualLongTermStorageStart ? (
                        new Date(estimateInfo.actualLongTermStorageStart).toLocaleDateString('en-us')
                      ) : (
                        <div>&mdash;</div>
                      )}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo && estimateInfo.actualLongTermStorageEnd ? (
                        new Date(estimateInfo.actualLongTermStorageEnd).toLocaleDateString('en-us')
                      ) : (
                        <div>&mdash;</div>
                      )}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          estimateInfo={estimateInfo}
        />
      )}
    </ThemeProvider>
  );
};

export default compose(
  connect(null, {
    updateHhgEstimate,
    showToast
  })
)(GoodShipmentMoveDatesCard);
