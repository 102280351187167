import { Box, Button, Checkbox, DialogActions, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { UPDATE_APPRAISALS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { UPDATE_HOMESALE_INFO_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateAppraisal } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const OrderDetailsCard = (props) => {

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { appraisalInfo, appraisalsSelected, brokerAgents, isReadOnly } = props;

  const tabs = [
    { header: appraisalsSelected ? 'Appraisal Info' : 'BMA Info', order: 1, iconPath: ['fas', 'house'] },
  ];

  const appliesToOptions = [
    { title: 'Departure', id: 1 },
    { title: 'Destination', id: 2 },
  ];

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'brokerId', title: 'Id' }, { name: 'brokerCode', title: 'Code' }, { name: 'agentName', title: 'Name' }, { name: 'agentName', title: 'Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'brokerId', width: 150 }, { columnName: 'brokerCode', width: 250 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents.filter((a) => a.typeOfAgent === (appraisalsSelected ? 'Agent' : 'Broker')),
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const summaries = [
    { header: appraisalsSelected ? 'Appraiser' : 'Broker', accessorKey: 'appraiserId', nameKey: 'agentName', nameValue: appraisalInfo ? appraisalInfo.appraiserName : '', type: 'advanced-search', searchInfo: brokerAgentSearchInfo, required: true,  value: appraisalInfo ? appraisalInfo.appraiserId : '', tab: 1, hideInTable: true },
    { header: appraisalsSelected ? 'Appraisal Code' : 'Broker Code', accessorKey: 'appraiserCode', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.appraiserCode : null, hideInModal: true,  tab: 1 },
    { header: appraisalsSelected ? 'Appraiser Name' : 'Broker Name', accessorKey: 'appraiserName', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.appraiserName : false, hideInModal: true, tab: 1 },
    { header: appraisalsSelected ? 'Appraisal Group' : 'Broker Group', accessorKey: 'appraisalGroup', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.appraisalGroup : '', hideInModal: true, tab: 1 },
    { header: 'Applies To', accessorKey: 'appliesTo', type: 'dropdown-v2', required: false, options: appliesToOptions, value: appraisalInfo ? appraisalInfo.appliesTo : '', tab: 1 },
    { header: appraisalsSelected ? 'Agent Selected' : 'Broker Selected', accessorKey: 'agentSelected', type: 'checkbox', required: false, value: appraisalInfo ? appraisalInfo.agentSelected : '', hideInModal: true, tab: 1, Cell: ({ cell }) => <Checkbox name="agentSelected" label="Active" checked={cell.row.original.agentSelected} disabled={true} /> },
    { header: 'Ordered', accessorKey: 'orderedDate', type: 'date', required: false,  value: appraisalInfo ? appraisalInfo.orderedDate : '', tab: 1 },
    { header: 'Appointment', accessorKey: 'appointmentDate', type: 'date', required: false, value: appraisalInfo ? appraisalInfo.appointmentDate : '', tab: 1 },
    { header: 'Fee', accessorKey: 'fee', type: 'money', required: false, value: appraisalInfo ? appraisalInfo.fee : false, tab: 1 },
    { header: 'Cancelled', accessorKey: 'cancelled', type: 'date', required: false, value: appraisalInfo ? appraisalInfo.cancelled : '', tab: 1 },
    { header: 'By Whom', accessorKey: 'byWhom', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.byWhom : '', tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', showBelow: true, required: false, value: appraisalInfo ? appraisalInfo.notes : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...appraisalInfo };

    if (values.appraiserId != null) {
      const newAppraiser = brokerAgents.find((v) => v.brokerId === values.appraiserId);
      newValues.appraiserId = newAppraiser.brokerId;
      newValues.appraiserCode = newAppraiser.brokerCode;
      newValues.appraiserName = newAppraiser.agentName;
      newValues.appraisalGroup = newAppraiser.companyName;
    }

    newValues.appliesTo = values.appliesTo;
    newValues.agentSelected = values.agentSelected;
    newValues.orderedDate = values.orderedDate;
    newValues.appointmentDate = values.appointmentDate;
    newValues.fee = values.fee;
    newValues.cancelled = values.cancelled;
    newValues.byWhom = values.byWhom;
    newValues.notes = values.notes;

    const resp = await props.updateAppraisal(newValues);
    if (resp.type === UPDATE_APPRAISALS_FAILURE) {
      props.showToast('Failed to update appraisal info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };

  const notifyOfAppraisers = async () => {
    setIsLoadingButton(true);
    let newVals = { ...appraisalInfo };
    newVals.notifiedOfAppraiserSent = true;

    let resp = await props.updateAppraisal(newVals);
    if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
      props.showToast('Failed to notify ee of Appraisers/Brokers, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('EE notified of Appraisers/Brokers successfully!', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }

    setIsLoadingButton(false);
    return resp;
  };

  const orderAppraisal = async () => {
    setIsLoadingButton(true);
    let newVals = { ...appraisalInfo };
    newVals.orderAppraisalSent = true;


    let resp = await props.updateAppraisal(newVals);
    if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
      props.showToast('Failed to order Appraisal/BMA, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Appraisal/BMA ordered successfully.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }

    setIsLoadingButton(false);
    return resp;
  };

  const selectAgent = async () => {
    setIsLoadingButton(true);
    let newVals = { ...appraisalInfo };
    newVals.agentSelectedSent = true;
    newVals.agentSelected = true;

    let resp = await props.updateAppraisal(newVals);
    if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
      props.showToast('Failed to select Agent/Broker, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Agent/Broker selected successfully, notification sent.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }

    setIsLoadingButton(false);
    return resp;
  };

  const deSelectAgent = async () => {
    setIsLoadingButton(true);
    let newVals = { ...appraisalInfo };
    newVals.agentSelectedSent = false;
    newVals.agentSelected = false;

    let resp = await props.updateAppraisal(newVals);
    if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
      props.showToast('Failed to deselect Agent/Broker.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Successfully deselected Agent/Broker.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }

    setIsLoadingButton(false);
    return resp;
  };
  

  const appraisalInfoButtons = ({ ...rest }) => {
    
    return (
      <>
        {isLoadingButton && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          </Box>
        )}
        {!isLoadingButton && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                {!isReadOnly && !appraisalInfo.notifiedOfAppraiserSent && (
                  <Button
                    onClick={async () => await notifyOfAppraisers()}
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#868686',
                      '&:hover': { backgroundColor: '#474747' },
                    }}
                  >
                    {`Notify EE of ${appraisalsSelected ? 'Appraisers' : 'Brokers'}`}
                  </Button>
                )}
                {!isReadOnly && !appraisalInfo.orderAppraisalSent && (
                  <Button
                    onClick={async () => await orderAppraisal()}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    {`Order ${appraisalsSelected ? 'Appraisal' : 'BMA'}`}
                  </Button>
                )}
                {!isReadOnly && !appraisalInfo.agentSelectedSent && (
                  <Button
                    onClick={async () => await selectAgent()}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    {`Select ${appraisalsSelected ? 'Agent' : 'Broker'}`}
                  </Button>
                )}
              </Box>
            </Box>
            {(appraisalInfo.notifiedOfAppraiserSent || appraisalInfo.orderAppraisalSent || appraisalInfo.agentSelectedSent) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Box>
                  {appraisalInfo.notifiedOfAppraiserSent && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> EE notified of Agents/Brokers successfully {!isReadOnly && <> <Link onClick={async () => await notifyOfAppraisers()} sx={{ cursor: 'pointer' }}>, click here</Link> to resend.</>}</Typography>}
                  {appraisalInfo.orderAppraisalSent && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Appraisal/BMA ordered successfully {!isReadOnly && <> <Link onClick={async () => await orderAppraisal()} sx={{ cursor: 'pointer' }}>, click here</Link> to resend.</>}</Typography>}
                  {appraisalInfo.agentSelectedSent && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Agent/Broker has been selected successfully {!isReadOnly && <> <Link onClick={async () => await deSelectAgent()} sx={{ cursor: 'pointer' }}>, click here</Link> to deselect.</>}</Typography>}
                </Box>
              </Box>
            )}
          </Box>
          
        )}
      </>
    );
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      ButtonComponent={appraisalInfoButtons}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateAppraisal,
    showToast,
  }),
)(OrderDetailsCard);