/* eslint-disable no-case-declarations */

import {
  GET_AUTH_TAX_ASSISTANCE_SUCCESS,
  GET_TAX_POLICIES_BY_CLIENT_SUCCESS,
  POST_AUTH_TAX_ASSISTANCE_SUCCESS,
  UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS,
} from '../types/taxAssistanceTypes';

const handleTaxAssistanceReducer = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId].authTaxAssistance)
    draft.authorizationDetails[draft.currentAuthId].authTaxAssistance = {};

  switch (action.type) {
    case UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS:
    case POST_AUTH_TAX_ASSISTANCE_SUCCESS:
    case GET_AUTH_TAX_ASSISTANCE_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authTaxAssistance.taxAssistance = action.response;
      break;

    case GET_TAX_POLICIES_BY_CLIENT_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authTaxAssistance.clientTaxPolicies = action.response;
      break;

    default:
      break;
  }
  return draft;
};

export default handleTaxAssistanceReducer;
