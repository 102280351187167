export const InspectionTypesOptions = [
  'Air Condition',
  'Electric',
  'Final',
  'General',
  'Gen New',
  'Heating',
  'Infest',
  'Initial',
  'Maint',
  'Moisture',
  'MoldAI',
  'MoldSA',
  'Move In',
  'Move Out',
  'OST',
  'PoolSP',
  'QR Check',
  'Radon',
  'Repair',
  'Roof',
  'Septic',
  'Sewer',
  'Smoked ET',
  'Sprinkler',
  'Structure',
  'Stucco',
  'Well',
];

export const InspectionRequiredByOptions = [
  'Appraiser',
  'Broker',
  'City Municipality',
  'Disclosure Statement',
  'Owner',
  'PM Company',
  'State',
];

export const InspectionsStatusOptions = ['Clear', 'Damage', 'Occupied', 'Repair By Owner', 'Repair By Tenant', 'Vacant'];
