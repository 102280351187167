import { InputLabel } from '@material-ui/core';

import React from 'react';
import { FormControl, ListItemText, MenuItem, Select, Checkbox } from '@mui/material';

const MultiSelectDropdown = ({ options, label, selectedOptions, setSelectedOptions }) => {
  const handleChange = event => {
    setSelectedOptions(event.target.value);
  };

  return (
    <FormControl fullWidth variant="standard" sx={{ margin: '2px 5px' }}>
      <InputLabel sx={{ fontSize: '0.85rem', marginBottom: '4px' }}>{label}</InputLabel>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={selected => selected.join(', ')}
        sx={{
          fontSize: '0.85rem',
          '& .MuiInputBase-root': {
            marginTop: '0px'
          },
          '& .MuiSelect-select': {
            paddingTop: '0px',
            paddingBottom: '0px'
          }
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option} sx={{ fontSize: '0.85rem' }}>
            <Checkbox checked={selectedOptions.indexOf(option) > -1} sx={{ padding: '0 8px' }} />
            <ListItemText primary={option} sx={{ fontSize: '0.85rem' }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
