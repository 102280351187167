import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';

import Spinner from 'modules/common/spinner.component';
import {
  deleteHhgVehicles,
  getHhgVehicles,
  postHhgVehicles,
  updateHhgVehicles
} from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import MultiRowForm from 'modules/common/multiRowForm.component';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

const CarShipmentVehiclesCrudTable = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { hhgSegmentVehicles, estimateId, authorizationId, isReadOnly } = props;

  const formFields = [
    {
      name: 'hhgSegmentVehicleId',
      type: 'hidden'
    },
    {
      name: 'estimateId',
      type: 'hidden'
    },
    {
      name: 'authorizationId',
      type: 'hidden'
    },
    { name: 'makeOfCar', type: 'text', label: 'Make of Car' },
    { name: 'modelOfCar', type: 'text', label: 'Model of Car' }
  ];
  const formLabel = 'Vehicles';
  const addRowLabel = 'Add Vehicle';
  const header = { title: 'Vehicles', iconPath: ['fas', 'car'] };

  const createNewRowFunc = () => {
    return {
      hhgSegmentVehicleId: 0,
      estimateId: estimateId,
      authorizationId: authorizationId,
      makeOfCar: '',
      modelOfCar: ''
    };
  };

  const saveForm = async formValues => {
    setIsLoading(true);

    formValues = formValues ?? [];

    const deletedVehicles = hhgSegmentVehicles.filter(
      vehicle => !formValues.some(formValue => formValue.hhgSegmentVehicleId === vehicle.hhgSegmentVehicleId)
    );

    const newVehicles = formValues.filter(formValue => formValue.hhgSegmentVehicleId === 0);

    const updatedVehicles = formValues.filter(
      formValue =>
        formValue.hhgSegmentVehicleId !== 0 &&
        hhgSegmentVehicles.some(
          vehicle =>
            vehicle.hhgSegmentVehicleId === formValue.hhgSegmentVehicleId &&
            (vehicle.makeOfCar !== formValue.makeOfCar || vehicle.modelOfCar !== formValue.modelOfCar)
        )
    );

    try {
      for (const vehicle of deletedVehicles) {
        await props.deleteHhgVehicles(vehicle.hhgSegmentVehicleId);
      }

      for (const vehicle of newVehicles) {
        await props.postHhgVehicles(vehicle);
      }

      for (const vehicle of updatedVehicles) {
        await props.updateHhgVehicles(vehicle);
      }

      await props.getHhgVehicles(authorizationId);
    } catch (error) {
      console.error('Error saving form:', error);
      props.showToast('Failed to update Vehicles, please try again later', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <MultiRowForm
          formValues={hhgSegmentVehicles.map(item => ({ ...item })) || []}
          saveFunction={saveForm}
          formFields={formFields}
          createNewRowFunc={createNewRowFunc}
          formLabel={formLabel}
          addRowLabel={addRowLabel}
          isDisabled={isReadOnly}
          header={header}
        />
      )}
    </>
  );
};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast,
    deleteHhgVehicles,
    postHhgVehicles,
    updateHhgVehicles,
    getHhgVehicles
  })
)(CarShipmentVehiclesCrudTable);
