import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import { updateVendor } from 'modules/authorizations/store/actions/dataManagement.actions';
import { UPDATE_VENDORS_REQUEST } from 'modules/authorizations/store/types/dataManagementTypes';

const VendorBankingEditableCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { vendorDetails, isReadOnly } = props;

  const accountTypes = ['Checking', 'Savings'];

  const bankingTab = [{ header: 'Banking', order: 1, iconPath: ['fas', 'file-contract'] }];

  const bankingSummaries = [
    {
      header: 'Account Location',
      accessorKey: 'accountLocation',
      type: 'text',
      required: false,
      value: vendorDetails?.accountLocation ?? '',
      tab: 1
    },
    {
      header: 'Account Type',
      accessorKey: 'accountType',
      type: 'dropdown',
      options: accountTypes,
      required: false,
      value: vendorDetails?.accountType ?? '',
      tab: 1
    },
    {
      header: 'Status',
      accessorKey: 'bankingStatus',
      type: 'text',
      required: false,
      value: vendorDetails?.bankingStatus ?? '',
      tab: 1
    },
    {
      header: 'Account Holder Name',
      accessorKey: 'accountHolderName',
      type: 'text',
      required: false,
      value: vendorDetails?.accountHolderName ?? '',
      tab: 1
    },
    {
      header: 'Routing Number',
      accessorKey: 'routingNumber',
      type: 'text',
      required: false,
      value: vendorDetails?.routingNumber ?? '',
      tab: 1
    },
    {
      header: 'Account Number',
      accessorKey: 'accountNumber',
      type: 'text',
      required: false,
      value: vendorDetails?.accountNumber ?? '',
      tab: 1
    },
    {
      header: 'Currency Code',
      accessorKey: 'currencyCode',
      type: 'text',
      required: false,
      value: vendorDetails?.currencyCode ?? '',
      tab: 1
    },
    {
      header: 'Payment Code',
      accessorKey: 'paymentCode',
      type: 'text',
      required: false,
      value: vendorDetails?.paymentCode ?? '',
      tab: 1
    },
    {
      header: 'Transit Code',
      accessorKey: 'transitCode',
      type: 'text',
      required: false,
      value: vendorDetails?.transitCode ?? '',
      tab: 1
    },
    {
      header: 'Special Instructions',
      accessorKey: 'specialInstructions',
      type: 'text',
      required: false,
      value: vendorDetails?.specialInstructions ?? '',
      tab: 1
    }
  ];

  const updateBankingFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...vendorDetails };

    newValues.accountLocation = values.accountLocation;
    newValues.accountType = values.accountType;
    newValues.bankingStatus = values.bankingStatus;
    newValues.accountHolderName = values.accountHolderName;
    newValues.routingNumber = values.routingNumber;
    newValues.accountNumber = values.accountNumber;
    newValues.currencyCode = values.currencyCode;
    newValues.paymentCode = values.paymentCode;
    newValues.transitCode = values.transitCode;
    newValues.specialInstructions = values.specialInstructions;

    const resp = await props.updateVendor(newValues);
    if (resp.type === UPDATE_VENDORS_REQUEST) {
      props.showToast('Failed to update vendor details, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      setIsLoading(false);
      return true;
    }
  };

  return (
    <EditableCard
      tabs={bankingTab}
      summaries={bankingSummaries}
      numColumns={2}
      updateFields={updateBankingFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );
};

export default compose(
  connect(null, {
    updateVendor,
    showToast
  })
)(VendorBankingEditableCard);
