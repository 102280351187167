import { CALL_INTAKE_API } from 'middleware/intakeApi';
import { CALL_INTAKE_V2_API } from 'middleware/intakeV2Api';


import {
  GET_RELOCATION_CONSULTANTS_FAILURE, GET_RELOCATION_CONSULTANTS_REQUEST, GET_RELOCATION_CONSULTANTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE, GET_SEARCH_RESULTS_REQUEST, GET_SEARCH_RESULTS_SUCCESS,
} from './types';

export const getRelocationConsultants = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_RELOCATION_CONSULTANTS_REQUEST, GET_RELOCATION_CONSULTANTS_SUCCESS, GET_RELOCATION_CONSULTANTS_FAILURE],
      authenticated: true,
      endpoint: 'relocationConsultants',
      method: 'GET',
    },
  };
};

export const getSearchResults = (searchTerm) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SEARCH_RESULTS_REQUEST, GET_SEARCH_RESULTS_SUCCESS, GET_SEARCH_RESULTS_FAILURE],
      authenticated: true,
      endpoint: `api/ClientPolicys/search/${searchTerm}`,
      method: 'GET',
    },
  };
};