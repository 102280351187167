import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const WeightInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const tabs = [
    { header: 'Weight Information', order: 1, iconPath: ['fas', 'weight-scale'] },
  ];

  const summaries = [
    { header: 'Land Estimate (lbs)', accessorKey: 'landEstimate', type: 'decimal', required: false, value: segmentInfo ? segmentInfo.landEstimate : '', tab: 1 },
    { header: 'Land Actual (lbs)', accessorKey: 'landActual', type: 'decimal', required: false, value: segmentInfo ? segmentInfo.landActual : '', tab: 1 },
    { header: 'Air Estimate (lbs)', accessorKey: 'airEstimate', type: 'decimal', required: false, value: segmentInfo ? segmentInfo.airEstimate : '', tab: 1 },
    { header: 'Air Actual (lbs)', accessorKey: 'airActual', type: 'decimal', required: false, value: segmentInfo ? segmentInfo.airActual : '', tab: 1 },
    { header: 'Sea Estimate (lbs)', accessorKey: 'seaEstimate', type: 'decimal', required: false, value: segmentInfo ? segmentInfo.seaEstimate : '', tab: 1 },
    { header: 'Sea Actual (lbs)', accessorKey: 'seaActual', type: 'decimal', required: false, value: segmentInfo ? segmentInfo.seaActual : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.landEstimate = values.landEstimate;
    newValues.landActual = values.landActual;
    newValues.airEstimate = values.airEstimate;
    newValues.airActual = values.airActual;
    newValues.seaEstimate = values.seaEstimate;
    newValues.seaActual = values.seaActual;
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(WeightInfoCard);