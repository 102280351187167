import {
    Dialog,
    Typography,
    withStyles,
    withTheme,
  } from '@material-ui/core';
      
  import { IntegratedFiltering } from '@devexpress/dx-react-grid';
  import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
  import {
    IntegratedSorting,
    SortingState,
  } from '@devexpress/dx-react-grid';
      
  import { compose } from 'recompose';
  import React, { Fragment, PureComponent } from 'react';
  import classNames from 'classnames';
      
  import { formatDateGeneric } from 'utilities/common';
  import Log from 'utilities/log';
  import Spinner from 'modules/common/spinner.component';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpenseLineItemDetailsView from './expenseLineItemDetailsView.component';
      
  const styles = (theme) => {
    const intakeRecordBorder = `1px solid ${theme.palette.grey[500]}`;
    return {
      table: {
        borderSpacing: theme.spacing(0, 2),
      },
      tableHead: {
        height: 32,
      },
      cell: {
        borderTop: intakeRecordBorder,
        borderBottom: intakeRecordBorder,
        borderLeft: 0,
        borderRight: 0,
        '&:first-child': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderLeft: intakeRecordBorder,
        },
        '&:last-child': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
          borderRight: intakeRecordBorder,
        },
      },
      urgencyMenuItem: {
        justifyContent: 'center',
      },
      selectInput: {
        lineHeight: 1.75,
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
      },
      row: {
        backgroundColor: theme.palette.common.white,
      },
      noDataCell: {
        paddingBottom: theme.spacing(12),
        paddingTop: theme.spacing(12),
        textAlign: 'center',
      },
      dialogContentContainer: {
        margin: '15px',
      },
      dialogIconRow:{
        display: 'flex',
        flexDirection: 'row',
        padding: '40px',
        paddingBottom: '20px',
      },
    };
  };
      
  const FILTER_DISABLED_COLUMNS = ['organization'];
      
  class SimilarExpensesTable extends PureComponent {
      static defaultProps = {
        isLoading: false,
      };
    
      constructor(props) {
        super(props);
    
        this.state = {
          columns: [
            { name: 'cat', title: 'CAT' },
            { name: 'expenseCode', title: 'Expense Code' }, // Records aren't accurately filtered by date (text based sorting rather than time based)
            { name: 'description', title: 'Expense Description'},
            { name: 'amount', title: 'Amount', getCellValue: (record) => <Typography>${record.amount}</Typography> },
            { name: 'currencyCode', title: 'Currency' },
            { name: 'payrollDate', title: 'Payroll Date', getCellValue: this.formatDate('payrollDate') },
            { name: 'expenseDate', title: 'Expense Date', getCellValue: this.formatDate('expenseDate') },
            // { name: 'service', title: 'Service' },
            // { name: 'status', title: 'Status', getCellValue: this.setStatusCellValue },
          ],
          filters: [],
          sorting: [{ columnName: 'expenseDate', direction: 'desc' }],
          tableColumnExtensions: [
            { columnName: 'description', width: 250, wordWrapEnabled: true },
          ],
          integratedFilteringColumnExtensions: [
            // { columnName: 'dateSubmitted', predicate: this.keyPredicate },
          ],
          approvedExpense: null,
          setOpenApprovedModal: false,
        };
  
        this.changeSorting = (sorting) => this.setState({ sorting });
  
      }
  
      setStatusCellValue = (record) => {
        const { theme: { palette } } = this.props;
    
        return (
          <div>
            {record.status === 3 && (
              <FontAwesomeIcon color={palette.warning.main} icon={['fas', 'exclamation-triangle']} size="lg" title="Expense on Hold (Repay Agreement Missing)." />
            )}
            {record.status === 2 && (
              <FontAwesomeIcon color={palette.error.main} icon={['fas', 'exclamation-circle']} size="lg" title="Expense Not Viewed for Three Days!" />
            )}
            {record.status === 4 && (
              <FontAwesomeIcon color={palette.secondary.main} icon={['fas', 'circle']} size="lg" title="Expense Not Viewed." />
            )}
          </div>
        );
      };
  
      getDaysWaitingValue = (record) => {
        let date = record.dateSubmitted ? new Date(record.dateSubmitted) : null;
        let today = new Date();
        const diffTime = date ? Math.abs(today - date) : null;
        const diffDays = diffTime ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : null;
        return <div>{diffDays}</div>;
      }
  
      // Filter predicate may need to change, originally based on sorting descriptions.
      keyPredicate = (value, filter) => {
        return new Date(value.key) - new Date(filter.value);
      };
  
      formatDate = (key) => (record) => {
        return formatDateGeneric(record[key]);
      };
  
      cellClicked = (id) => {
        // this.props.history.push(`/authorizations/${id}`);
      }
  
      getDetailsCellValue = (record) => {
        const { transfereeName, clientName, moveType } = record;
        return (
          <Fragment key={transfereeName}>
            <Typography>{transfereeName}</Typography>
            <Typography>{clientName}</Typography>
            <Typography>{moveType}</Typography>
          </Fragment>
        );
      };
    
      changeFilters = (filters) => this.setState({ filters });
    
      filterCell = (props) => {
        return props.column.title && !FILTER_DISABLED_COLUMNS.includes(props.column.name) ?
          <TableFilterRow.Cell {...props} /> : null;
      };
    
      headerCell = (props) => {
        return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
      };
    
      headerRowComponent = (props) => {
        return <TableHeaderRow.Row className={this.props.classes.tableHead} {...props} />;
      };
    
      rowComponent = (props) => {
        return <Table.Row className={this.props.classes.row} {...props} />;
      };
    
      cellComponent = (props) => {
        // let clickHandler = () => this.props.history.push(`/authorizations/${this.props.authId}/expenses/approved/${props.row.expenseLineId}`);
        let clickHandler = () => this.setState({ approvedExpense: props.row.expenseLineId, openApprovedModal: true});
        let pointerClass = 'handles-click';
        const className = classNames(this.props.classes.cell, pointerClass);
        return <Table.Cell onClick={clickHandler} className={className} {...props} />;
      };
    
      tableComponent = (props) => {
        return <Table.Table className={this.props.classes.table} {...props} />;
      };
    
      noDataCellComponent = (props) => {
        const content = this.props.isLoading ? <Spinner logo /> : 'No Records were returned.  Check the filters above.';
        // need to remove the getMessage function to avoid exception
        // eslint-disable-next-line no-unused-vars
        const { getMessage, ...rest } = props;
        return (
          <Table.Cell {...rest} className={this.props.classes.noDataCell}>
            <Typography>{content}</Typography>
          </Table.Cell>
        );
      };
    
      render() {
        Log.trace('RENDER', 'authorizationsGrid');
        const { details, location, history, summaryExpenseLines } = this.props;
        const {
          columns,
          tableColumnExtensions,
          integratedFilteringColumnExtensions,
          sorting,
          openApprovedModal,
          approvedExpense,
        } = this.state;
    
        return (
            <>
                <Grid
                    rows={(details?.authExpenses?.expenseLines && summaryExpenseLines && details?.authExpenses?.submittedExpenses) ? details?.authExpenses?.expenseLines.filter(el => summaryExpenseLines.find(sl => (sl.cat === el.cat && sl.expenseId !== el.expenseId && details?.authExpenses?.submittedExpenses.find(se => se.expenseId === el.expenseId)?.isExpenseApproved))) : []}
                    columns={columns}
                >
                    <SortingState
                        sorting={sorting}
                        onSortingChange={this.changeSorting}
                    />
                    

                    <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
                    <IntegratedSorting />
                    <Table
                        columnExtensions={tableColumnExtensions}
                        rowComponent={this.rowComponent}
                        tableComponent={this.tableComponent}
                        cellComponent={this.cellComponent}
                        noDataCellComponent={this.noDataCellComponent}
                    />
                    <TableHeaderRow rowComponent={this.headerRowComponent} cellComponent={this.headerCell} showSortingControls />

                </Grid>


                <Dialog open={openApprovedModal} fullWidth={true} maxWidth={'lg'} onClose={() => this.setState({ approvedExpense: null, openApprovedModal: false})}>
                    <div className="row justify-space-between align-center">
                        <div className={this.props.classes.dialogIconRow}>
                            <Typography variant="h5" >
                                Approved Expense {approvedExpense}
                            </Typography>
                        </div>
                    </div>
                    <div className={this.props.classes.dialogContentContainer}>
                        <ExpenseLineItemDetailsView details={details} expenseLineId={approvedExpense} location={location} history={history} viewOnly={true}/>
                    </div>
                </Dialog>
            </>

        );
      }
  }
      
  export default compose(
    withStyles(styles),
    withTheme,
  )(SimilarExpensesTable);