/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box,
  Card,
  CardContent,
  Paper,
  TableRow,
  Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import React, { Fragment, useEffect, useState } from 'react';

import { GET_ACTIVITY_LOG_FAILURE, GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SUMMARY_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../layout/types';
import { getAuthActivityLog, getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete } from '../store/actions/authorizations.actions';

import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import { Link } from 'react-router-dom';
import { getMonth } from '../../common/utils';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
});

const ActivityLogHeader = ({ details, logs }) => {
  let date = new Date(logs[0] ? logs[0].date : details?.authDetails?.summary.lastModified);
  const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });

  return (
    <Card sx={{ marginBottom: '20px' }}>
      <CardContent sx={{ padding: '16px !important' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Auth ID
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.authDetails?.summary.authorizationId}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Transferee
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.authDetails?.summary.transfereeName}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Status
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.authDetails?.summary.status}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Departure
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.authDetails?.summary.departure}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Destination
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {details?.authDetails?.summary.destination}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '4px' }}>
              <Typography variant="subtitle2" sx={{ fontSize: '16px' }}>
                Last Modified
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                <Link>{`${getMonth(date.getMonth())} ${date.getDate()} ${date.getFullYear()} ${formatter.format(date)}`}</Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};


const ActivityLogView = (props) => {
    
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;

  const renderHeaderRow = (row) => {
    return (<TableRow sx={{ backgroundColor: '#41B7C0', '& .MuiButtonBase-root': { color: 'white !important' } }}>
      {row.children.map((c) => {
        return c;
      })}
    </TableRow>);
  };

  const formatDate = (record) => {
    let date = new Date(record.date);
    const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
    return (
      <Fragment key={record.date}>
        <div>{`${getMonth(date.getMonth())} ${date.getDate()} ${date.getFullYear()} ${formatter.format(date)}`}</div>
      </Fragment>
    );
  };

  const [sorting, setSorting] = useState([{ columnName: 'date', direction: 'desc' }]);

  const [columns] = useState([
    { name: 'date', title: 'Date', getCellValue: formatDate },
    { name: 'user', title: 'User' },
    { name: 'type', title: 'Type' },
    { name: 'area', title: 'Area' },
    { name: 'change', title: 'Change' },
    { name: 'oldValue', title: 'Old Value' },
    { name: 'newValue', title: 'New Value' },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'type', width: 100 },
    { columnName: 'area', width: 125 },
    { columnName: 'user', width: 100 },
    { columnName: 'date', width: 175 },
    { columnName: 'change', width: 300 },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [pageSizes] = useState([15, 20, 25]);
  const [filters, setFilters] = useState([]);

  const [logs, setLogs] = useState([]);

  useEffect(() => {

    async function fetchData () {
      const id = props.match.params.id;

      props.setAuthId(id);

      const [authActivityLogResp, authSummaryResp, authDateSummaryResp] = await Promise.all([
        props.getAuthActivityLog(id),
        props.getAuthorizationSummary(id),
        props.getAuthDateSummary(id),
      ]);

      if (authActivityLogResp.type === GET_ACTIVITY_LOG_FAILURE) {
        props.showToast('Failed to retrieve auth activity log, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      } else {
        setLogs(authActivityLogResp.response.sort((a, b) => new Date(b.date) - new Date(a.date)));
      }

      if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
        
      if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      props.setLoadingComplete();
    }

    fetchData();
  }, []);

  return (
    <Box>
      {!props.isLoading && details && logs &&
        <Box sx={{ padding: 3 }}>
          <ActivityLogHeader details={details} logs={logs} />

          <Paper>
            <Grid
              rows={logs.sort((a, b) => new Date(b.date) - new Date(a.date))}
              columns={columns}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={setSorting}
              />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <FilteringState
                filters={filters}
                onFiltersChange={setFilters}
              />
              <IntegratedFiltering />
              <IntegratedPaging />
              <Table columnExtensions={tableColumnExtensions} />
              <TableHeaderRow rowComponent={renderHeaderRow} showSortingControls />
              <TableFilterRow />
              <PagingPanel
                pageSizes={pageSizes}
              />
            </Grid>
          </Paper>
        </Box>
      }
      {props.isLoading && <FullscreenSpinner />}

    </Box>
  );

};

const mapStateToProps = (state) => {
  const { authorizationDetails, isLoading } = state.authorizations;
  return {
    authorizationDetails,
    isLoading,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthActivityLog,
    getAuthorizationSummary,
    getAuthDateSummary,
    setAuthId,
    setLoadingComplete,
  }),
)(ActivityLogView);