import { TextField as MuiTextField, Paper, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import Log from 'utilities/log';

import { HEADER_HEIGHT_SHRINK } from '../constants';

const styles = (theme) => ({
  container: {
    height: 'auto',
    minHeight: HEADER_HEIGHT_SHRINK,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    zIndex: theme.zIndex.appBar,
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },
  inputGroup: {
    display: 'inline-flex',
    marginRight: theme.spacing(2),
    verticalAlign: 'top',
    '&:last-child': {
      marginRight: 0,
    },
  },
  root: {
    marginRight: theme.spacing(1),
  },
  rootClientName: {
    width: 260,
    marginRight: theme.spacing(1),
  },
});

class ClientHeader extends Component {

  state = {
    showReloPolicyConfirmation: false,
    newReloPolicyId: null,
  };

  render() {
    Log.trace('RENDER', 'ClientHeader');
    const {
      classes,
      headerInfo,
      shouldShowSideDrawer,
    } = this.props;

    return (
      <>
        <Paper square className={classNames('position-fixed', classes.container, { [classes.drawerOpen]: shouldShowSideDrawer })}>
          <div className={classes.inputGroup}>
            <MuiTextField
              type="text"
              name="clientNumber"
              label="Client Number"
              variant="outlined"
              classes={{ root: classes.root }}
              value={headerInfo.clientCode}
              InputProps={{ readOnly: true }}
            />
            <MuiTextField
              type="text"
              name="clientName"
              label="Client Name"
              variant="outlined"
              classes={{ root: classes.rootClientName }}
              value={headerInfo.description}
              InputProps={{ readOnly: true }}
            />
            <MuiTextField
              type="text"
              name="csmName"
              label="CSM/CSS Name"
              variant="outlined"
              classes={{ root: classes.root }}
              value={headerInfo.serviceRep}
              InputProps={{ readOnly: true }}
            />
            <MuiTextField
              type="text"
              name="status"
              label="Status"
              variant="outlined"
              classes={{ root: classes.root }}
              value={headerInfo.clientStatus}
              InputProps={{ readOnly: true }}
            />
          </div>
        </Paper>
      </>
    );
  }
}

ClientHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  headerInfo: PropTypes.object.isRequired,
  shouldShowSideDrawer: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer,
  };
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {}),
)(ClientHeader);