/* eslint-disable react-hooks/exhaustive-deps */

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Card, CardContent, Grid, MenuItem, Paper, Select, Tooltip, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { memoize } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import classNames from 'classnames';
import states from 'modules/common/states.json';

import { DELETE_AUTH_HOUSE_HUNTING_LIST_FAILURE, DELETE_AUTH_OFFER_HISTORY_LIST_FAILURE, GET_AUTH_DESTINATION_SERVICES_FAILURE, GET_AUTH_HOUSE_HUNTING_LIST_FAILURE, GET_AUTH_OFFER_HISTORY_LIST_FAILURE, POST_AUTH_DESTINATION_SERVICES_FAILURE, POST_AUTH_HOUSE_HUNTING_LIST_FAILURE, POST_AUTH_OFFER_HISTORY_LIST_FAILURE, UPDATE_AUTH_DESTINATION_SERVICES_FAILURE, UPDATE_AUTH_HOUSE_HUNTING_LIST_FAILURE, UPDATE_AUTH_OFFER_HISTORY_LIST_FAILURE } from 'modules/authorizations/store/types/destinationServiceTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteHouseHuntingList, deleteOfferHistoryList, getAuthDestinationServices, getHouseHuntingList, getOfferHistoryList, postAuthDestinationServices, postHouseHuntingList, postOfferHistoryList, updateAuthDestinationServices, updateHouseHuntingList, updateOfferHistoryList } from 'modules/authorizations/store/actions/destinationService.actions';
import { getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete } from 'modules/authorizations/store/actions/authorizations.actions';
import { getBrokerAgents, getBrokerAgentsById } from 'modules/authorizations/store/actions/dataManagement.actions';

import { setStatusTypes } from '../../utils';
import CrudTable from 'modules/clients/details/crudTable.component';
import DestinationServicesDetailsEditableCard from './destinationServicesDetailsEditableCard.component';
import DestinationServicesStatusStepper from './destinationServicesStatusStepper.component';
import EditableCard from 'modules/common/editableCard.component';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const addFullAddress = (offerHistory) => {
  console.log(offerHistory);
  if (offerHistory) {
    return offerHistory.map((item) => {
      const { city, state } = item;

      const displayAddress = [
        city ? `${city}${state ? ',' : ''}` : '',
        state,
      ].filter(Boolean).join(' ');

      return {
        ...item,
        fullAddress: displayAddress,  
      };
    });
  } else {
    return []; 
  }
};
const DestinationServices = (props) => {    

  const { brokerAgents, details, location, isReadOnly } = props;

  const serviceTypes = ['Renter', 'Buyer'];
  const [type, setType] = useState(details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.type : '');
  const [currentStatus, setCurrentStatus] = useState(details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.status : 'Initiated');
  const [currentStatusTypes, setCurrentStatusTypes] = useState(setStatusTypes(type, currentStatus));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOfferHistory, setIsLoadingOfferHistory] = useState(false);
  const [isLoadingHouseHunting, setIsLoadingHouseHunting] = useState(false);
  const [offerHistory, setOfferHistory] = useState(addFullAddress(details?.authDestinationService?.offerHistory));
  const renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    let allStates = states.filter((state) => state.countryCode === useCountryCode)
      .map(( state ) => (
        state.description
      ));
    return allStates;
  });

  useEffect(() => {
    setOfferHistory(addFullAddress(details?.authDestinationService?.offerHistory));
  }, [details?.authDestinationService?.offerHistory])

  const houseHuntingAdjustedColumns = [
    { accessorKey: 'houseHuntingTripStart', header: 'HH Trip Start', type: 'date', size: 70, required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us') },
    { accessorKey: 'houseHuntingTripEnd', header: 'HH Trip End', type: 'date', size: 70, required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us') },
  ];

  const offerHistoryAdjustedColumns = [
    { accessorKey: 'status', header: 'Status', type: 'dropdown', options: ['Under Contract', 'Pending', 'Fall Through', 'Closed'], required: false, size: 60 },
    { accessorKey: 'purchasePrice', header: 'Purchase Price', type: 'text', required: false, size: 70 },
    { accessorKey: 'fullAddress', header: 'Address', type: 'text', required: false, size: 150, hideInModel: true },
    { accessorKey: 'scheduleCloseDate', header: 'Schedule Close Date', type: 'date', required: false, size: 50, Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-') },
    { accessorKey: 'actualCloseDate', header: 'Actual Close Date', type: 'date', required: false, size: 50, Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-') },
    { accessorKey: 'addressLineOne', header: 'Address', type: 'text', required: true, size: 90, hideInTable: true  },
    { accessorKey: 'city', header: 'City', type: 'text', required: true, size: 70, hideInTable: true  },
    { accessorKey: 'state', header: 'State', type: 'dropdown', options: renderStateOptions(''), required: true, size: 70, hideInTable: true  },
    { accessorKey: 'zipCode', header: 'Zip Code', type: 'text', required: true, size: 70, hideInTable: true  },
    { accessorKey: 'fallThrough', header: 'Fall Through', type: 'checkbox', required: false, size: 70, hideInTable: true },
    { accessorKey: 'fallThroughReason', header: 'Fall Through Reason', type: 'dropdown', options: ['Buyer did not get loan', 'Specified in comments', 'Unspecified'], required: false, size: 70, hideInTable: true },
    { accessorKey: 'comments', header: 'Comments', type: 'notes', required: false, size: 70, hideInTable: true },
  ];

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'typeOfAgent', title: 'Type of Agent' }, { name: 'agentName', title: 'Agent Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'typeOfAgent', width: 150 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents,
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const mainCardTabs = [
    { header: 'Destination Services', order: 1, iconPath: ['fas', 'fa-truck-moving'] },
  ];

  let selectedBrokerId = details?.authDestinationService?.destinationServices?.renterBrokerAgentId ?? 0;
  let selectedBuyerBrokerId = details?.authDestinationService?.destinationServices?.buyerBrokerAgentId ?? 0;

  let selectedBroker = null;
  let selectedBuyerBroker = null;

  if(brokerAgents){
    selectedBroker = brokerAgents.find(s => s.brokerId === selectedBrokerId);
    selectedBuyerBroker = brokerAgents.find(s => s.brokerId === selectedBuyerBrokerId);
  }
  
  let displayAddress = '';
  console.log(details?.authDetails);

  if(details?.authDetails?.destinationAddress?.city && details?.authDetails?.destinationAddress?.state){
    displayAddress = `${details?.authDetails?.destinationAddress?.city}, ${details?.authDetails?.destinationAddress?.state}`;
  }
  
  if(details?.authDetails?.destinationAddress?.city && !details?.authDetails?.destinationAddress?.state){
    displayAddress = `${details?.authDetails?.destinationAddress?.city}`;
  }

  if(!details?.authDetails?.destinationAddress?.city && details?.authDetails?.destinationAddress?.state){
    displayAddress = `${details?.authDetails?.destinationAddress?.state}`;
  }

  const mainCardRenterSummaries = [
    { header: 'Service Start Date', accessorKey: 'initiationDate', type: 'date', required: false, value: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.initiationDate : null, tab: 1, hideInModal: true },
    { header: 'Broker/Agent', accessorKey: 'renterBrokerAgentId', nameKey: 'renterBrokerAgentName', nameValue: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.renterBrokerAgentName : '', type: 'advanced-search', searchInfo: brokerAgentSearchInfo, required: false, value: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.renterBrokerAgentId : 0, tab: 1 },
    { header: 'Agent Email', type: 'text', value: selectedBroker?.contactPhoneNumber ?? '', tab: 1, hideInModal: true },
    { header: 'Agent Phone', type: 'text',  value: selectedBroker?.contactEmail ?? '', tab: 1, hideInModal: true },
    { header: 'New Address', type: 'text', value: displayAddress, tab: 1, hideInModal: true},
    { header: 'Canceled Date', accessorKey: 'renterCancelDate', type: 'date', required: false, value: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.renterCancelDate : null, tab: 1 },
  ];

  const mainCardBuyerSummaries = [
    { header: 'Service Start Date', accessorKey: 'initiationDate', type: 'date', required: false, value: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.initiationDate : null, tab: 1, hideInModal: true },
    { header: 'Broker/Agent', accessorKey: 'buyerBrokerAgentId', nameKey: 'buyerBrokerAgentName', nameValue: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.buyerBrokerAgentName : '', type: 'advanced-search', searchInfo: brokerAgentSearchInfo, required: false, value: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.buyerBrokerAgentId : 0, tab: 1 },
    { header: 'Agent Email', type: 'text', value: selectedBuyerBroker?.contactPhoneNumber ?? '', tab: 1, hideInModal: true },
    { header: 'Agent Phone', type: 'text',  value: selectedBuyerBroker?.contactEmail ?? '', tab: 1, hideInModal: true },
    { header: 'New Address', type: 'text', value: displayAddress, tab: 1, hideInModal: true},
    { header: 'Mortgage Lender Selected', accessorKey: 'mortgageLendersSelected', type: 'dropdown', options: ['Premia', 'PNC', 'Rocket', 'Other'], required: false, value: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.mortgageLendersSelected : '', tab: 1 },
    { header: 'Canceled Date', accessorKey: 'buyerCancelDate', type: 'date', required: false, value: details && details?.authDestinationService?.destinationServices ? details?.authDestinationService?.destinationServices.buyerCancelDate : null, tab: 1 },
  ];

  const onCancelService = async () => {
    setCurrentStatus('Canceled');
    let newVals = { ...details?.authDestinationService?.destinationServices };
    if (type === 'Renter')
    {
      newVals.renterStatus = currentStatus;
      newVals.renterCancelDate = new Date().toISOString().split('T')[0];
    }
    else {
      newVals.buyerStatus = currentStatus;
      newVals.buyerCancelDate = new Date().toISOString().split('T')[0];
    }
    newVals.authorizationId = props.id;

    if (details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices?.length !== 0) {
      const resp = await props.updateAuthDestinationServices(newVals);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update the employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getAuthDestinationServices(props.id);
        if (getResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          return false;
        } else {
          setCurrentStatusTypes(setStatusTypes(type, currentStatus));
          setIsLoading(false);
          return true;
        }
      }
    } else {
      const resp = await props.postAuthDestinationServices(newVals);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getAuthDestinationServices(props.id);
        if (getResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          return false;
        } else {
          setCurrentStatusTypes(setStatusTypes(type, currentStatus));
          setIsLoading(false);
          return true;
        }
      }
    }
  };

  const onCompleteService = async () => {
    setCurrentStatus('Completed');
    let newVals = { ...details?.authDestinationService?.destinationServices };
    if (type === 'Renter')
    {
      newVals.renterStatus = currentStatus;
      newVals.renterCancelDate = null;
    }
    else {
      newVals.buyerStatus = currentStatus;
      newVals.buyerCancelDate = null;
    }

    if (details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices?.length !== 0) {
      const resp = await props.updateAuthDestinationServices(newVals);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update the employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getAuthDestinationServices(props.id);
        if (getResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          return false;
        } else {
          setCurrentStatusTypes(setStatusTypes(type, currentStatus));
          setIsLoading(false);
          return true;
        }
      }
    } else {
      const resp = await props.postAuthDestinationServices(newVals);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getAuthDestinationServices(props.id);
        if (getResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          return false;
        } else {
          setCurrentStatusTypes(setStatusTypes(type, currentStatus));
          setIsLoading(false);
          return true;
        }
      }
    }
  };

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    let newVals = { ...details?.authDestinationService?.destinationServices };

    newVals.type = type;
    if (type === 'Renter')
    {
      newVals.renterStatus = currentStatus;
      newVals.renterBrokerAgentId = values.renterBrokerAgentId;
      newVals.renterBrokerAgentName = values.renterBrokerAgentName;
      newVals.renterServiceStartDate = values.renterServiceStartDate;
      newVals.renterCancelDate = values.renterCancelDate;
    }
    else {
      newVals.buyerStatus = currentStatus;
      newVals.buyerBrokerAgentId = values.buyerBrokerAgentId;
      newVals.buyerBrokerAgentName = values.buyerBrokerAgentName;
      newVals.buyerServiceStartDate = values.buyerServiceStartDate;
      newVals.buyerCancelDate = values.buyerCancelDate;
      newVals.mortgageLendersSelected = values.mortgageLendersSelected;
    }
    newVals.authorizationId = props.id;
        
    if (details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices?.length !== 0) {
      const resp = await props.updateAuthDestinationServices(newVals);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update the destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getAuthDestinationServices(props.id);
        if (getResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    }
  };

  // We have 2 separate editable cards that need to be able to save specific info. We should keep the formstate for saving:
  // set the initial value to whatever the value is if it exists
  // when we updateFields, setFormState before calling update values

  const updateDestinationServicesTypeAndStatus = async (newType) => {
    let newVals = { ...details?.authDestinationService?.destinationServices };
    newVals.type = newType;
    if (type === 'Renter')
    {
      newVals.renterStatus = currentStatus;
    }
    else {
      newVals.buyerStatus = currentStatus;
    }
    newVals.authorizationId = props.id;
    
    setType(newType);

    if (details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices?.length !== 0) {
      const resp = await props.updateAuthDestinationServices(newVals);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update the employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getAuthDestinationServices(props.id);
        if (getResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true;
        }
      }
    } else {
      const resp = await props.postAuthDestinationServices(newVals);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getAuthDestinationServices(props.id);
        if (getResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true;
        }
      }
    }
  };

  const updateHouseHuntingRow = async (houseHunting, values) => {
    setIsLoadingHouseHunting(true);
    const id = houseHunting.original.houseId;
    values.houseId = id;
    values.authorizationId = props.id;
    const resp = await props.updateHouseHuntingList(values);
    if (resp.type === UPDATE_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
      setIsLoadingHouseHunting(false);
      return false;
    } else {
      const getResp = await props.getHouseHuntingList(props.id);
      if (getResp.type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
        setIsLoadingHouseHunting(false);
        return false;
      } else {
        setIsLoadingHouseHunting(false);
        return true;
      }
    }
  };

  const createHouseHuntingRow = async (values) => {
    setIsLoadingHouseHunting(true);
    values.authorizationId = props.id;
    const resp = await props.postHouseHuntingList(values);
    if (resp.type === POST_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
      setIsLoadingHouseHunting(false);
      return false;
    } else {
      const getResp = await props.getHouseHuntingList(props.id);
      if (getResp.type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
        setIsLoadingHouseHunting(false);
        return false;
      } else {
        setIsLoadingHouseHunting(false);
        return true;
      }
    }
  };

  const deleteHouseHuntingRow = async (houseHunting) => {
    setIsLoadingHouseHunting(true);
    const id = houseHunting.original.houseId;
    const resp = await props.deleteHouseHuntingList(id);
    if (resp.type === DELETE_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
      setIsLoadingHouseHunting(false);
      return false;
    } else {
      const getResp = await props.getHouseHuntingList(props.id);
      if (getResp.type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
        setIsLoadingHouseHunting(false);
        return false;
      } else {
        setIsLoadingHouseHunting(false);
        return true;
      }
    }
  };

  const updateOfferHistoryRow = async (offer, values) => {
    setIsLoadingOfferHistory(true);
    const id = offer.original.offerId;
    values.offerId = id;
    values.authorizationId = props.id;

    let newValues = {
      authorizationId: values.authorizationId,
      offerId: values.offerId,
      addressLineOne: values.addressLineOne,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      status: values.status ? values.status : '',
      purchasePrice: values.purchasePrice ? values.purchasePrice : '',
      scheduleCloseDate: values.scheduleCloseDate ? values.scheduleCloseDate : null,
      actualCloseDate: values.actualCloseDate ? values.actualCloseDate : null,
      fallThrough: values.fallThrough ? true : false,
      fallThroughReason: values.fallThroughReason ? values.fallThroughReason : '',
      comments: values.comments ? values.comments : '',
    };
    const resp = await props.updateOfferHistoryList(newValues);
    if (resp.type === UPDATE_AUTH_OFFER_HISTORY_LIST_FAILURE) {
      setIsLoadingOfferHistory(false);
      return false;
    } else {
      const getResp = await props.getOfferHistoryList(props.id);
      if (getResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
        setIsLoadingOfferHistory(false);
        return false;
      } else {
        setIsLoadingOfferHistory(false);
        return true;
      }
    }
  };

  const createOfferHistoryRow = async (values) => {
    setIsLoadingOfferHistory(true);
    values.authorizationId = props.id;

    let newValues = {
      authorizationId: values.authorizationId,
      addressLineOne: values.addressLineOne,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      status: values.status ? values.status : '',
      purchasePrice: values.purchasePrice ? values.purchasePrice : '',
      scheduleCloseDate: values.scheduleCloseDate ? values.scheduleCloseDate : null,
      actualCloseDate: values.actualCloseDate ? values.actualCloseDate : null,
      fallThrough: values.fallThrough ? true : false,
      fallThroughReason: values.fallThroughReason ? values.fallThroughReason : '',
      comments: values.comments ? values.comments : '',
    };
    const resp = await props.postOfferHistoryList(newValues);
    if (resp.type === POST_AUTH_OFFER_HISTORY_LIST_FAILURE) {
      setIsLoadingOfferHistory(false);
      return false;
    } else {
      const getResp = await props.getOfferHistoryList(props.id);
      if (getResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
        setIsLoadingOfferHistory(false);
        return false;
      } else {
        setIsLoadingOfferHistory(false);
        return true;
      }
    }
  };

  const deleteOfferHistoryRow = async (offer) => {
    setIsLoadingOfferHistory(true);
    const id = offer.original.offerId;
    const resp = await props.deleteOfferHistoryList(id);
    if (resp.type === DELETE_AUTH_OFFER_HISTORY_LIST_FAILURE) {
      setIsLoadingOfferHistory(false);
      return false;
    } else {
      const getResp = await props.getOfferHistoryList(props.id);
      if (getResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
        setIsLoadingOfferHistory(false);
        return false;
      } else {
        setIsLoadingOfferHistory(false);
        return true;
      }
    }
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
        <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)} sx={{
          position: 'sticky',
          paddingRight: '10px',
          paddingLeft: '10px',
          marginBottom: '15px',
          minWidth: '100%',
        }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', marginBottom: '10px' }}>
            <Box>
              <div className={props.classes.mb2}>
                <Typography variant="subtitle2">
                  Service Type
                </Typography>
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Select
                  labelId={'type'}
                  key={'type'}
                  label={''}
                  name={'type'}
                  value={type}
                  sx={{ maxWidth: '360px', minWidth: '180px' }}
                  onChange={(e) => {
                    setCurrentStatusTypes(setStatusTypes(e.target.value, currentStatus));
                    updateDestinationServicesTypeAndStatus(e.target.value);
                  }}
                >
                  {serviceTypes.map((opt) => (
                    <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
                { details && details?.authDestinationService?.destinationServices && type === 'Renter' && location && (!location.pathname.includes('expenses') && !isReadOnly) &&
                  <>
                    <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} > Send Rental Referral Letter </Button>
                  </>
                }
                { details && details?.authDestinationService?.destinationServices && type === 'Buyer' && location && (!location.pathname.includes('expenses') && !isReadOnly) &&
                  <>
                    <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} > Send Mortgage Leads Letter </Button>
                  </>
                }
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
              <Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                    Move Type
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                    {details?.authDetails?.summary.moveType}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                    {type ? type : 'Destination Services'} Status
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                    {currentStatus}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                    Policy
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                    {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                  </Typography>
                </Box>
              </Box>
            </Box>
            { location && (!location.pathname.includes('expenses') && !isReadOnly) &&
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={onCancelService}> Cancel Service </Button>
                <Button color="secondary"variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#f0b11d', '&:hover': { backgroundColor: '#ba8a00' } }}> Set On Hold </Button>
                <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={onCompleteService}> Complete Service </Button>
              </Box>
            }
          </Box>
        </Paper>
      </Grid>
      <DestinationServicesStatusStepper status={currentStatus} allStatuses={currentStatusTypes} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          { type === 'Renter' &&
            <>
              <EditableCard
                tabs={mainCardTabs}
                summaries={mainCardRenterSummaries}
                numColumns={4} 
                updateFields={updateFields}
                isLoading={isLoading}
                hideEdit={location.pathname.includes('expenses') || isReadOnly}
              />
            </>
          }
          { type === 'Buyer' &&
            <>
              <EditableCard
                tabs={mainCardTabs}
                summaries={mainCardBuyerSummaries}
                numColumns={4} 
                updateFields={updateFields}
                isLoading={isLoading}
                hideEdit={location.pathname.includes('expenses') || isReadOnly}
              />
            </>
          }
          <DestinationServicesDetailsEditableCard
            authId={props.id}
            destinationServices={details?.authDestinationService?.destinationServices || {}}
            hideEdit={location.pathname.includes('expenses') || isReadOnly}
          />
          { details && details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices.type === 'Renter' &&
            <>
              <Card sx={{ marginTop: '16px' }}>
                <Box>
                  <CardContent className={props.classes.cardContainer}>
                    <div className="row justify-space-between align-center">
                      <div className={props.classes.iconRow}>
                        <Tooltip title={'Money'} key={'Destination Services'} placement="right">
                          <FontAwesomeIcon icon={['fas', 'house']} size="2x" />
                        </Tooltip>
                        <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                          House Hunting
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
                    {!isLoadingHouseHunting && (
                      <CrudTable 
                        editingMode={'modal'} // Can be changed to 'row' to edit inline 
                        columns={houseHuntingAdjustedColumns} 
                        rows={details?.authDestinationService?.houseHunting || []} 
                        createRow={createHouseHuntingRow}
                        updateRow={updateHouseHuntingRow}
                        deleteRow={deleteHouseHuntingRow}
                        customAddText={'Add Trip'}
                        hideEdit={location.pathname.includes('expenses') || isReadOnly}
                      />
                    )}
                    {(isLoadingHouseHunting) && (
                      <Spinner />
                    )}
                  </CardContent>
                </Box>
              </Card>
            </>
          }
          { details && details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices.type === 'Buyer' &&
            <>
              <Card sx={{ marginTop: '16px' }}>
                <Box>
                  <CardContent className={props.classes.cardContainer}>
                    <div className="row justify-space-between align-center">
                      <div className={props.classes.iconRow}>
                        <Tooltip title={'Money'} key={'Destination Services'} placement="right">
                          <FontAwesomeIcon icon={['fas', 'house']} size="2x" />
                        </Tooltip>
                        <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                          Offer History
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
                    {!isLoadingOfferHistory && (
                      <CrudTable 
                        editingMode={'modal'} // Can be changed to 'row' to edit inline 
                        columns={offerHistoryAdjustedColumns} 
                        rows={offerHistory || []} 
                        createRow={createOfferHistoryRow}
                        updateRow={updateOfferHistoryRow}
                        deleteRow={deleteOfferHistoryRow}
                        customAddText={'Add Offer'}
                        hideEdit={location.pathname.includes('expenses') || isReadOnly}
                        showBasicTable={true}
                      />
                    )}
                    {(isLoadingOfferHistory) && (
                      <Spinner />
                    )}
                  </CardContent>
                </Box>
              </Card>
            </>
          }
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          { details && details?.authDestinationService?.destinationServices && details?.authDestinationService?.destinationServices.type === 'Buyer' &&
            <>
              <Card className={props.classes.card} sx={{ marginTop: '16px' }}>
                <Box>
                  <CardContent className={props.classes.cardContainer}>
                    <div className="row justify-space-between align-center">
                      <div className={props.classes.iconRow}>
                        <Tooltip title={'Money'} key={'Destination Services'} placement="right">
                          <FontAwesomeIcon icon={['fas', 'house']} size="2x" />
                        </Tooltip>
                        <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                          House Hunting
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
                    {!isLoadingHouseHunting && (
                      <CrudTable 
                        editingMode={'modal'} // Can be changed to 'row' to edit inline 
                        columns={houseHuntingAdjustedColumns} 
                        rows={details?.authDestinationService?.houseHunting || []} 
                        createRow={createHouseHuntingRow}
                        updateRow={updateHouseHuntingRow}
                        deleteRow={deleteHouseHuntingRow}
                        customAddText={'Add Trip'}
                        hideEdit={location.pathname.includes('expenses') || isReadOnly}
                      />
                    )}
                    {(isLoadingHouseHunting) && (
                      <Spinner />
                    )}
                  </CardContent>
                </Box>
              </Card>
            </>
          }
        </Grid>
      </Grid>
    </Box>
  );

};

DestinationServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getAuthDestinationServices,
    postAuthDestinationServices,
    updateAuthDestinationServices,
    getBrokerAgents,
    setAuthId,
    setLoadingComplete,
    getBrokerAgentsById,
    getHouseHuntingList,
    postHouseHuntingList,
    updateHouseHuntingList,
    deleteHouseHuntingList,
    getOfferHistoryList,
    postOfferHistoryList,
    updateOfferHistoryList,
    deleteOfferHistoryList,
  }),
)(DestinationServices);