/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'modules/intake/form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    minWidth: 200,
  },
  checkbox: {
    paddingTop: 15,
    paddingLeft: 15,
  },
});  

const NewAnnualDates = (props) => {

  const formikProps = props.formikProps;

  const yearStart = formikProps.values.calendarInfo ? new Date(`${formikProps.values.calendarInfo.calendarYear}-01-01`).toISOString().slice(0, 10) : null;
  const yearEnd = formikProps.values.calendarInfo ? new Date(`${formikProps.values.calendarInfo.calendarYear}-12-31`).toISOString().slice(0, 10) : null;

  return (
    <Box marginX={2} marginY={2}>
      <h4>General</h4>
      <div>
        <Field
          component={TextField}
          name={'calendarInfo.dateEntered'}
          label={'Date Entered'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.dateEntered?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.auditDate'}
          label={'Audit Date'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.auditDate?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.postDate'}
          label={'Post Date'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.postDate?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.lastContactDate'}
          label={'Last Contact Date'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.lastContactDate?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.yearEndCutoffDate'}
          label={'Year End Cutoff Date'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.yearEndCutoffDate?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.blackoutDate'}
          label={'Blackout Date'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.blackoutDate?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.followingYearCutoffDate'}
          label={'Following Year Cutoff Date'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.followingYearCutoffDate?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.method'}
          label={'Method'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.method}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.rtrCount'}
          label={'RTR Count'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.rtrCount}
          type="number"
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.password'}
          label={'Password'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.password}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
      </div>

      <h4>Surveys</h4>
      <div>
        <Field
          component={TextField}
          name={'calendarInfo.surveyReady'}
          label={'Survey Ready'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.surveyReady?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.surveySent'}
          label={'Survey Sent'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.surveySent?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.surveyReceived'}
          label={'Survey Received'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.surveyReceived?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.surveyFollowUpSent'}
          label={'Survey Follow Up Sent'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.surveyFollowUpSent?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.surveyCompleted'}
          label={'Survey Completed'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.surveyCompleted?.split('T')[0]}
          type="date"
          disabled={false}
          max={yearEnd}
          min={yearStart}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <FormControlLabel
          control={
            <Field
              component={Checkbox}
              name="calendarInfo.surveySaved"
              label="Is Survey Saved"
              value={formikProps.values.calendarInfo.surveySaved}
            />
          }
          label="Is Survey Saved"
          classes={{ root: props.classes.checkbox }}
        />
      </div>

      <h4>W2C</h4>
      <div>
        <Field
          component={TextField}
          name={'calendarInfo.w2C'}
          label={'W2C Count'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.w2C}
          type="number"
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
        <Field
          component={TextField}
          name={'calendarInfo.w2CReason'}
          label={'W2C Reason'}
          className={props.classes.root}
          value={formikProps.values.calendarInfo.w2CReason}
          disabled={false}
          InputLabelProps={{ style: { fontSize: 13 } }}
        />
      </div>
    </Box>
  );
};

NewAnnualDates.propTypes = {
  calendarData: PropTypes.object,
};


// const mapStateToProps = (state) => {
//   const { clientCalendars, isLoading, periodData } = state.clients;
//   return {
//     clientCalendars,
//     isLoading,
//     periodData,
//   };
// };
  
export default compose(
  withStyles(styles),
  connect(null, {
  }),
)(NewAnnualDates);