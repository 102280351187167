import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateListing } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const BrokerInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { listingInfo, brokerAgents, isReadOnly } = props;

  const tabs = [
    { header: 'Broker Info', order: 1, iconPath: ['fas', 'person'] },
  ];

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'brokerId', title: 'Id' }, { name: 'brokerCode', title: 'Code' }, { name: 'agentName', title: 'Name' }, { name: 'agentName', title: 'Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'brokerId', width: 150 }, { columnName: 'brokerCode', width: 250 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents.filter((a) => a.typeOfAgent === 'Broker'),
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const summaries = [
    { header: 'Broker', accessorKey: 'brokerId', nameKey: 'agentName', nameValue: listingInfo ? listingInfo.brokerName : '', type: 'advanced-search', searchInfo: brokerAgentSearchInfo, required: true,  value: listingInfo ? listingInfo.brokerId : '', tab: 1, hideInTable: true },
    
    { header: 'Broker Code', accessorKey: 'brokerCode', type: 'text', required: false, value: listingInfo ? listingInfo.brokerCode : '', hideInModal: true,  tab: 1 },
    { header: 'Broker Name', accessorKey: 'brokerName', type: 'text', required: false, value: listingInfo ? listingInfo.brokerName : '', hideInModal: true, tab: 1 },
    { header: 'Broker Group', accessorKey: 'brokerGroup', type: 'text', required: false, value: listingInfo ? listingInfo.brokerGroup : '', hideInModal: true, tab: 1 },
    { header: 'Broker Email', accessorKey: 'brokerEmail', type: 'text', required: false, value: listingInfo ? listingInfo.brokerEmail : '', hideInModal: true, tab: 1 },
    { header: 'Broker Phone', accessorKey: 'brokerPhone', type: 'text', required: false, value: listingInfo ? listingInfo.brokerPhone : '', hideInModal: true, tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...listingInfo };

    if (values.brokerId != null) {
      const newBroker = brokerAgents.find((v) => v.brokerId === values.brokerId);
      newValues.brokerId = newBroker.brokerId;
      newValues.brokerCode = newBroker.brokerCode;
      newValues.brokerName = newBroker.agentName;
      newValues.brokerGroup = newBroker.companyName;
      newValues.brokerEmail = newBroker.contactEmail;
      newValues.brokerPhone = newBroker.contactMobileNumber;
    }

    const resp = await props.updateListing(newValues);
    if (resp.type === UPDATE_LISTINGS_FAILURE) {
      props.showToast('Failed to update broker info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      smallerModal={true}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateListing,
    showToast,
  }),
)(BrokerInfoCard);