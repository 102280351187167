import * as Sentry from '@sentry/browser';
import { matchPath } from 'react-router-dom';
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';

import { authorizedRouteMap } from './authorizedRouteMap';
import Log from 'utilities/log';
import history from 'browserHistory';

// this should remain hard-coded and is the same across environments
const TRC_TOKEN_NAMESPACE = 'http://trcinternal.trcgm.com/';

const HOST_URL = process.env.REACT_APP_HOST_URL || 'http://localhost:3004';
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || 'trc-internal-test.auth0.com';
const AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID || 'igEEwYAzpCqbeYqBf16ZYgotV9fIWAF6';
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://testtrcinternalapi.trcgm.com';

class Auth {
  accessToken;
  idToken;
  expiresAt;
  userRoles;
  userPermissions;
  userMetadata;

  auth0 = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENTID,
    redirectUri: `${HOST_URL}/callback`,
    audience: AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid',
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.userHasRole = this.userHasRole.bind(this);
    this.userHasPermission = this.userHasPermission.bind(this);
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        Log.error(`handle authentication error: ${err.toString()}`);
        history.replace('/');
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  parseToken = (token) => {
    try {
      const tokenData = jwtDecode(token);
      return tokenData ? tokenData : null;
    } catch (err) {
      Log.error(`auth token parse error: ${err.toString()}`);
      return null;
    }
  };

  getUserPermissionsList = () => {
    return this.userPermissions;
  };

  getUserRoleList = () => {
    return this.userRoles;
  };

  getUserMetadata = () => {
    return this.userMetadata;
  };

  // check for presence of a certain user permission
  userHasPermission = (permission) => {
    const userPermissions = this.getUserPermissionsList();
    const hasUserPermission = userPermissions.some((up) => up === permission);
    if (hasUserPermission) {
      return true;
    }
  };

  isPathAuthorized = (path) => {
    const permissions = this.getUserPermissionsList();
    const requiredPermissionKey = Object.keys(authorizedRouteMap)
      .find((key) => matchPath(path, {
        path: key,
        exact: true,
        strict: false,
      }));
    if (requiredPermissionKey) {
      return permissions.findIndex((p) => p === authorizedRouteMap[requiredPermissionKey]) > -1;
    }
    return true;
  };

  // check for presence of a certain role
  userHasRole = (role) => {
    const roles = this.getUserRoleList();
    return roles.findIndex((r) => r === role) > -1;
  };

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the Access Token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;

    const parsedToken = this.parseToken(this.accessToken);
    const permissions = parsedToken['permissions'];
    const roles = parsedToken[`${TRC_TOKEN_NAMESPACE}roles`];
    const user_metadata = parsedToken[`${TRC_TOKEN_NAMESPACE}user_metadata`];

    this.userPermissions = permissions ? permissions : [];
    localStorage.setItem('userPermissions', JSON.stringify(this.userPermissions));

    this.userRoles = roles ? roles : [];
    localStorage.setItem('userRoles', JSON.stringify(this.userRoles));

    this.userMetadata = user_metadata ? user_metadata : [];

    const path = localStorage.getItem('redirectAfterLogin') || '/';
    history.replace(path);
  }

  renewSession() {
    return new Promise((resolve) => {
      try {
        this.auth0.checkSession({}, (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            resolve(true);
          } else {
            this.logout();
            Log.error(`Could not get a new token: ${err ? err.toString() : 'Unknown'}`);
            resolve(false);
          }
        });
      } catch (err) {
        resolve(false);
      }
    });
  }

  login() {
    this.auth0.authorize();
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    Sentry.setUser(null);

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('access_token');

    this.auth0.logout({
      returnTo: window.location.origin,
    });

    window.location = `https://${this.auth0.baseOptions.domain}/v2/logout?returnTo=${encodeURIComponent(window.location.origin)}`;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }

  requestPasswordReset(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err, resp) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        },
      );
    });
  }
}

export default new Auth(); // Singleton
