import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import { Box, Button } from '@mui/material';

const SegmentInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const communicationTabs = [
    { header: 'Service Info', order: 1, iconPath: ['fas', 'suitcase'] },
  ];

  const billToOptions = [
    { title: 'Transferee', id: 1 },
    { title: 'Client', id: 2 },
  ];

  const communicationSummaries = [
    { header: 'Initiation Date', accessorKey: 'initiationDate', type: 'date', required: false, value: segmentInfo ? segmentInfo.initiationDate : '', tab: 1, hideInModal: true },
    { header: 'Type', accessorKey: 'type', type: 'text', required: false, value: segmentInfo ? (segmentInfo.type === 1 ? 'International' : 'Domestic') : 'Domestic', tab: 1, hideInModal: true },
    { header: 'Bill To', accessorKey: 'billTo', type: 'dropdown-v2', required: false, value: segmentInfo ? segmentInfo.billTo : '', options: billToOptions, tab: 1 },
    { header: 'Shipment', accessorKey: 'shipment', type: 'text', required: false, value: segmentInfo ? segmentInfo.shipment : '', tab: 1 },
    { header: 'Monetary Cap', accessorKey: 'monetaryCap', type: 'checkbox', required: false,  value: segmentInfo ? segmentInfo.monetaryCap : '', tab: 1 },
    { header: 'Monetary Cap', accessorKey: 'monetaryCapAmount', type: 'money', required: false, value: segmentInfo ? segmentInfo.monetaryCapAmount : '', tab: 1 },
    { header: 'Pref Survey On', accessorKey: 'perfSurveyOn', type: 'date', required: false, value: segmentInfo ? segmentInfo.perfSurveyOn : '', tab: 1 },
    { header: 'Pref Pack/Load', accessorKey: 'prefSurveyPackLoad', type: 'date', required: false, value: segmentInfo ? segmentInfo.prefSurveyPackLoad : '', tab: 1 },
    { header: 'Delivery Goal', accessorKey: 'deliveryGoal', type: 'date', required: false, value: segmentInfo ? segmentInfo.deliveryGoal : '', tab: 1 },
    { header: 'Est Approved By', accessorKey: 'estApprovedBy', type: 'date', required: false, value: segmentInfo ? segmentInfo.estApprovedBy : '', tab: 1 },
    { header: 'Client Disc Rt', accessorKey: 'clientDiscRt', type: 'text', required: false, value: segmentInfo ? segmentInfo.clientDiscRt : '', tab: 1 },
    { header: 'Insured', accessorKey: 'insuredAmount', type: 'money', required: false, value: segmentInfo ? segmentInfo.insuredAmount : '', tab: 1 },
    { header: 'Auto Ship Est', accessorKey: 'autoShipEstAmount', type: 'money', required: false, value: segmentInfo ? segmentInfo.autoShipEstAmount : '', tab: 1 },
    { header: 'Est Approved', accessorKey: 'estApprovedAmount', type: 'money', required: false, value: segmentInfo ? segmentInfo.estApprovedAmount : '', tab: 1 },
    { header: 'Est Approved Dt', accessorKey: 'estApprovedDt', type: 'date', required: false, value: segmentInfo ? segmentInfo.estApprovedDt : '', tab: 1 },
    { header: 'Needs Effective', accessorKey: 'needsEffective', type: 'date', required: false, value: segmentInfo ? segmentInfo.needsEffective : '', tab: 1 },
    { header: 'Needs Expiration', accessorKey: 'needsExpiration', type: 'date', required: false, value: segmentInfo ? segmentInfo.needsExpiration : '', tab: 1 },
    { header: 'Act to UID', accessorKey: 'actToUid', type: 'text', required: false, value: segmentInfo ? segmentInfo.actToUid : '', tab: 1 },
  ];

  const ServiceInfoButtons = ({ ...rest }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 10px',
              }}
            >
                <Button
                  onClick={() => alert('Vendor Letter Sent Successfully!')}
                  color="secondary"
                  variant="contained"
                  sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                >
                  Send Vendor Letter
                </Button>
            </Box>
          </Box>
        </Box>
      )
  };

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.initiationDate = values.initiationDate;
    newValues.type = values.type === 'Domestic' ? 0 : 1;
    newValues.estApprovedBy = values.estApprovedBy;
    newValues.clientDiscRt = values.clientDiscRt;
    newValues.shipment = values.shipment;
    newValues.monetaryCap = values.monetaryCap;
    newValues.monetaryCapAmount = values.monetaryCapAmount;
    newValues.insuredAmount = values.insuredAmount;
    newValues.autoShipEstAmount = values.autoShipEstAmount;
    newValues.estApprovedAmount = values.estApprovedAmount;
    newValues.estApprovedDt = values.estApprovedDt;
    newValues.perfSurveyOn = values.perfSurveyOn;
    newValues.prefSurveyPackLoad = values.prefSurveyPackLoad;
    newValues.deliveryGoal = values.deliveryGoal;
    newValues.needsEffective = values.needsEffective;
    newValues.needsExpiration = values.needsExpiration;
    newValues.storageFacility = values.storageFacility;
    newValues.storageAddr = values.storageAddr;
    newValues.storageCsz = values.storageCsz;
    newValues.storagePhone = values.storagePhone;
    newValues.actToUid = values.actToUid;
    newValues.storageUnits = values.storageUnits;

    // values.name = segmentInfo.name;
    // values.type = values.type === 'Domestic' ? 0 : 1;
    // values.authorizationId = props.id;
    // values.segmentId = segmentInfo.segmentId;
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={communicationTabs}
      summaries={communicationSummaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
      ButtonComponent={ServiceInfoButtons}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(SegmentInfoCard);