const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const {
  GET_AUTH_OFFER_HISTORY_LIST_REQUEST,
  GET_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  GET_AUTH_OFFER_HISTORY_LIST_FAILURE,
  POST_AUTH_OFFER_HISTORY_LIST_REQUEST,
  POST_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  POST_AUTH_OFFER_HISTORY_LIST_FAILURE,
  UPDATE_AUTH_OFFER_HISTORY_LIST_REQUEST,
  UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  UPDATE_AUTH_OFFER_HISTORY_LIST_FAILURE,
  DELETE_AUTH_OFFER_HISTORY_LIST_REQUEST,
  DELETE_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  DELETE_AUTH_OFFER_HISTORY_LIST_FAILURE,
  GET_AUTH_HOUSE_HUNTING_LIST_REQUEST,
  GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  GET_AUTH_HOUSE_HUNTING_LIST_FAILURE,
  POST_AUTH_HOUSE_HUNTING_LIST_REQUEST,
  POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  POST_AUTH_HOUSE_HUNTING_LIST_FAILURE,
  UPDATE_AUTH_HOUSE_HUNTING_LIST_REQUEST,
  UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  UPDATE_AUTH_HOUSE_HUNTING_LIST_FAILURE,
  DELETE_AUTH_HOUSE_HUNTING_LIST_REQUEST,
  DELETE_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  DELETE_AUTH_HOUSE_HUNTING_LIST_FAILURE,
  GET_AUTH_DESTINATION_SERVICES_REQUEST,
  GET_AUTH_DESTINATION_SERVICES_SUCCESS,
  GET_AUTH_DESTINATION_SERVICES_FAILURE,
  POST_AUTH_DESTINATION_SERVICES_REQUEST,
  POST_AUTH_DESTINATION_SERVICES_SUCCESS,
  POST_AUTH_DESTINATION_SERVICES_FAILURE,
  UPDATE_AUTH_DESTINATION_SERVICES_REQUEST,
  UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS,
  UPDATE_AUTH_DESTINATION_SERVICES_FAILURE,
} = require('../types/destinationServiceTypes');

export const getOfferHistoryList = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_OFFER_HISTORY_LIST_REQUEST, GET_AUTH_OFFER_HISTORY_LIST_SUCCESS, GET_AUTH_OFFER_HISTORY_LIST_FAILURE],
      authenticated: true,
      endpoint: `api/OfferHistory/GetByAuthorizationId/${id}`,
      method: 'GET',
    },
  };
};

export const postOfferHistoryList = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_OFFER_HISTORY_LIST_REQUEST, POST_AUTH_OFFER_HISTORY_LIST_SUCCESS, POST_AUTH_OFFER_HISTORY_LIST_FAILURE],
      authenticated: true,
      endpoint: 'api/OfferHistory/',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateOfferHistoryList = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_OFFER_HISTORY_LIST_REQUEST, UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS, UPDATE_AUTH_OFFER_HISTORY_LIST_FAILURE],
      authenticated: true,
      endpoint: 'api/OfferHistory/',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteOfferHistoryList = (listItemId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_AUTH_OFFER_HISTORY_LIST_REQUEST, DELETE_AUTH_OFFER_HISTORY_LIST_SUCCESS, DELETE_AUTH_OFFER_HISTORY_LIST_FAILURE],
      authenticated: true,
      endpoint: `api/OfferHistory/${listItemId}`,
      method: 'DELETE',
    },
  };
};
export const getHouseHuntingList = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_HOUSE_HUNTING_LIST_REQUEST, GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS, GET_AUTH_HOUSE_HUNTING_LIST_FAILURE],
      authenticated: true,
      endpoint: `api/HouseHunting/GetByClientId/${id}`,
      method: 'GET',
    },
  };
};

export const postHouseHuntingList = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_HOUSE_HUNTING_LIST_REQUEST, POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS, POST_AUTH_HOUSE_HUNTING_LIST_FAILURE],
      authenticated: true,
      endpoint: 'api/HouseHunting/',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateHouseHuntingList = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_HOUSE_HUNTING_LIST_REQUEST, UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS, UPDATE_AUTH_HOUSE_HUNTING_LIST_FAILURE],
      authenticated: true,
      endpoint: 'api/HouseHunting/',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteHouseHuntingList = (listItemId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_AUTH_HOUSE_HUNTING_LIST_REQUEST, DELETE_AUTH_HOUSE_HUNTING_LIST_SUCCESS, DELETE_AUTH_HOUSE_HUNTING_LIST_FAILURE],
      authenticated: true,
      endpoint: `api/HouseHunting/${listItemId}`,
      method: 'DELETE',
    },
  };
}; 

export const getAuthDestinationServices = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_AUTH_DESTINATION_SERVICES_REQUEST, GET_AUTH_DESTINATION_SERVICES_SUCCESS, GET_AUTH_DESTINATION_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/destinationService`,
      method: 'GET',
    },
  };
};

export const postAuthDestinationServices = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_AUTH_DESTINATION_SERVICES_REQUEST, POST_AUTH_DESTINATION_SERVICES_SUCCESS, POST_AUTH_DESTINATION_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/destinationService/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAuthDestinationServices = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_AUTH_DESTINATION_SERVICES_REQUEST, UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS, UPDATE_AUTH_DESTINATION_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/destinationService/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};
