import { Arrow, Status } from '../../statusStepper.component';
import { Box, Typography } from '@mui/material';
import { New, TemporaryLivingStatus } from '../TempLivingConstants';
import { calculateTemporaryLivingNumberOfDays } from '../Utils/tempLivingUtils';
import React, { Fragment } from 'react';

const StatusItem = ({ statusKey, statusValue, isActive, numDays }) => {
  return (
    <Fragment key={statusKey}>
      {/* Arrows render to the left, we dont want this for first element */}
      {statusValue.sequenceOrder > 1 && <Arrow isActive={isActive} />}
      {statusKey !== New && (
        <Box>
          <Status name={statusValue.name} isActive={isActive} numDays={numDays > 0 ? numDays : null} />
          {/* Component will look funky unless we add some white space if the num days is 0 or null */}
          {numDays < 1 && <Typography style={{ paddingTop: 5 }}>&nbsp;</Typography>}
        </Box>
      )}
    </Fragment>
  );
};

const TempLivingStatusStepper = ({ selectedTempLivingAssignment }) => {
  const selectedTemporaryLivingStatus = TemporaryLivingStatus[selectedTempLivingAssignment?.activeStatus] ?? { sequenceOrder: 0 };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 1,
        padding: '20px',
      }}
    >
      {Object.entries(TemporaryLivingStatus).map(([key, value]) => (
        <StatusItem
          key={key}
          statusKey={key}
          statusValue={value}
          isActive={selectedTemporaryLivingStatus?.sequenceOrder === value.sequenceOrder}
          numDays={calculateTemporaryLivingNumberOfDays(value, selectedTempLivingAssignment)}
        />
      ))}
    </Box>
  );
};

export default TempLivingStatusStepper;
