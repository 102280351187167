import {
  Box, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
  
const Status = (props) => {
  return (
    <Box>
      <Box style={{ padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 50, backgroundColor: props.isActive ? '#67a783' : '#fff', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
        <Typography style={{ fontSize: '1.25rem', color: props.isActive ? '#fff' : '#000000' }}>
          {props.name}
        </Typography>
      </Box>
      {props.numDays && (
        <Typography style={{ fontSize: '.75rem', color: '#868686', textAlign: 'center', paddingTop: 5 }}>
          {`${props.numDays} ${props.numDays === 1 ?  'Day' : 'Days'}`}
        </Typography>
      )}
    </Box>
  );
};
  
const Arrow = (props) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <FontAwesomeIcon color={props.isActive ? '#67a783' : '#868686'} icon={['fas', 'arrow-right']} size="2x" />
    </Box>
  );
}; 
  
const DestinationServicesStatusStepper = (props) => {
  const { status, allStatuses } = props;
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px', gap: 1, justifyContent: 'center' }} >
      {allStatuses.map((s) => {
        return (
          <>
            {!(s === 'Initiated') && <Arrow isActive={(status === s)} />}
            <Status name={s} isActive={(status === s)} />
          </>
        );
      })}
    </Box>
      
  );
  
};
  
export default DestinationServicesStatusStepper;