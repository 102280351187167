import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

import { DELETE_VENDOR_CONTACT_FAILURE, GET_VENDOR_CONTACT_FAILURE, POST_VENDOR_CONTACT_FAILURE, UPDATE_VENDOR_CONTACT_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { deleteVendorContact, getVendorContacts, postVendorContact, updateVendorContact } from 'modules/authorizations/store/actions/dataManagement.actions';
import CrudTable from '../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const VendorContactsTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'vendorId', header: 'Vendor Id', type: 'number', required: true, size: 70 },
    { accessorKey: 'firstName', header: 'First Name', type: 'text', required: false, size: 50 },
    { accessorKey: 'lastName', header: 'Last Name', type: 'text', required: false, size: 50 },
    { accessorKey: 'fullName', header: 'Full Name', type: 'text', required: false, size: 50 },
    { accessorKey: 'officePhone', header: 'Office Phone', type: 'text', required: false, size: 50 },
    { accessorKey: 'officeEmail', header: 'Office Email', type: 'text', required: false, size: 50 },
    { accessorKey: 'description', header: 'Description', type: 'text', required: false, size: 65 },
    { accessorKey: 'jobTitle', header: 'Job Title', type: 'text', required: false, size: 65 },
  ];

  const updateRow = async (vendor, values) => {
    values.vendorContactId = vendor.original.vendorContactId;
    // values.vendorId = vendor.original.vendorId;

    setIsLoading(true);
    const resp = await props.updateVendorContact(values);
    if (resp.type === UPDATE_VENDOR_CONTACT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      setIsLoading(false);
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    setIsLoading(true);
    const resp = await props.postVendorContact(values);
    if (resp.type === POST_VENDOR_CONTACT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      setIsLoading(false);
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (vendor) => {
    const id = vendor.original.vendorContactId;
    const resp = await props.deleteVendorContact(id);
    if (resp.type === DELETE_VENDOR_CONTACT_FAILURE) {
      return false;
    } else {
      const getResp = await props.getVendorContacts();
      if (getResp.type === GET_VENDOR_CONTACT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Vendor Contacts</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!isLoading && props.vendorContacts && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.vendorContacts} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {isLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

const mapStateToProps = (state) => {
  const { vendorContacts } = state.authorizations;
  return {
    vendorContacts,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getVendorContacts,
    deleteVendorContact,
    postVendorContact,
    updateVendorContact,
  }),
)(VendorContactsTable);