/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
    
const useStyles = makeStyles((theme) => (
  {
    card: {
      width: '100%',
      maxWidth: 1500,
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cardContainer: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    
    },
    iconRow:{
      display: 'flex',
      flexDirection: 'row',
    },
    dialogIconRow:{
      display: 'flex',
      flexDirection: 'row',
      padding: '40px',
      paddingBottom: '20px',
    },
    cardDisplayContent: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '10px',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipClickable: {
      cursor: 'pointer',
    },
    iconOverlayImg: {
      position: 'absolute',
    },
    spacingX: {
      marginRight: theme.spacing(1),
    },
    footer: {
      height: 15,
      marginBottom: theme.spacing(2),
    },
    mb2: {
      marginBottom: '6.5px',
      marginTop: '6.5px',
      marginRight: '16px',
    },
    editModalContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100% !important',
      marginLeft: '0px !important',
    },
    editModalColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100% !important',
    },
    editModalInput: {
      minWidth: '332px',
    },
    dialogContentContainer: {
      margin: '15px',
    },
    labelText: {
      minWidth: 350,
      marginBottom: 0,
    },
    initiatedStatusBox: {
      border: '1px solid #67a783',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#67a783',
    },
    notInitiatedStatusBox: {
      border: '1px solid #fd6120',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#fd6120',
    },
    expensesStatusBox: {
      border: '1px solid #868686',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#868686',
    },
  }
));
    
/*
        - Required Props: 
         * tabs - a list of tabs to be included in the card
         * summaries - a list of objects detailing the specific textfield requirements for the editing feature as well as the necessary values to display
         * numColumns - number of xl device columns
        - Optional Props: 
         * updateFields (typically an API PUT call followed by GET call to refresh the data, returns boolean) 
    
        - Required Label Fields: accessorKey, header, type, options (only required for dropdown type)
        - Optional Label Fields: required (defaults to false), size, enableColumnOrdering, enableEditing, enableSorting, hideInTable (if column should be displayed in the CRUD table or not)
    
        - Label Types: text, notes, number, date, dropdown, dropdownv2
    
        Tabs are displayed in the order they appear in the list. If there is only one item in the list, then it will be displayed as a 'header' (not clickable, no other visible tabs) and all items in the summaries list will be displayed and editable by default. If within the summaries list
        there is an item with a 'tab' option of a different value than the 'order' in the single item within the tabs list. (Example - there is an item in the summaries list with a 'tab' value of 3. There is only one item in the tabs list. That item will still be displayed within the card.)
    
        const tabs = [
            { accessorKey: 'nameOfTabMatchingInSummaries', header: 'displayTitleOfTab', order: 1, iconPath: ['fas', 'user-friends'] },
        ];
    
        The editable card is largely based off of the CRUD table functionality. An example of a label input is:
        const summaries = [
            { header: 'Text Example', type: 'text', required: true, size: 72 },
            { header: 'Dropdown Example', type: 'dropdown', options: ['Yes', 'No'], required: true, size: 68 },
            { header: 'Dropdown Version 2 Example', type: 'dropdown-v2', options: arrayOfOptions, defaultVal: 0, required: true, Cell: ({cell}) => cell.fieldValues.original.dropdownV2Example != null && <p>{dropdownV2Exampoe.find(o => o.id === cell.fieldValues.dropdownV2Example).title}</p>} ,
            { header: 'Checkbox Example', type: 'checkbox', defaultVal: false, required: true, Cell: ({cell}) => <Checkbox name="checkboxExample" label="Active" checked=(cell.fieldValues.original.checkboxExample} disabled={false} },
            { header: 'Date Example', type: 'date', required: true, size: 72 },
            { header: 'Notes Example', type: 'notes', required: true},
        ]
    
        Included in the summaries array, you need a VALUE that contains the proper value you want displayed. An example is that fields = authorizationInformation. Values will then be gathered by the accessor key in the summaries array:
    
              We want to display the values for an authorization's ID and Client:
              const summaries = [
                { header: 'File #', value: this.props.authorizationInformation.mtAuthorizationId, type: 'text', required: true, disabled: true },
                { header: 'Client', value: this.props.authorizationInformation.client.description, type: 'text', required: true, disabled: true },
              ]
    
        The updateFields props is a FUNCTION for updating the fields via the editing modal. Here is an example of how to write an updateFields function:
        
        const updateAuthorizationInformation = async (authorizationInformation, values) => {
          const id = authorizationInformation.mtAuthorizationId;
          values.mtAuthorizationId = id;
          values.clientId = authorizationInformation.clients.clientId;
          const resp = await props.updateAuthorizationInformation(values);
          if (resp.type === UPDATE_AUTHORIZATION_INFORMATION_FAILURE) {
            return false;
          } else {
          const getResp = await props.getAuthorizationInformation(authorizationInformation.clients.clientId);
          if (getResp.type === GET_AUTHORIZATION_INFORMATION_FAILURE) {
            return false;
          } else {
            return true;
          }
        }
      };
    
    */
    
    
const UserSettingsModal = (props) => {
  const { settingsInfo, updateFields, isLoading } = props;
  const classes = useStyles();
    
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
     
  });
    
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
    
    
  const handleSaveEdits = async ( vip, webAccess, excludeFromVendorReport, far, excludeFromSystemReport ) => {
    if (!Object.keys(validationErrors).length) {
      // Send update call to parent to update and refresh values.
      if (updateFields) {
        const resp = await updateFields(vip, webAccess, excludeFromVendorReport, far, excludeFromSystemReport);
        if (!resp) {
          showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        } else {
          setSettingsModalOpen(false);
        }
      }
      setSettingsModalOpen(false); //required to exit editing mode and close modal
    }
  };
    
  const handleCancelEdits = () => {
    setValidationErrors({});
    setSettingsModalOpen(false); //required to exit editing mode and close modal
  };
        
  return (
    <ThemeProvider theme={theme}>
      <Box onClick={() => setSettingsModalOpen(true)}>
        <div className="row justify-space-between align-center" >
          <Typography variant="h6" color="inherit" noWrap sx={{ marginRight: '7px', display: 'flex', justifyContent: 'row', fontSize: '1.0714285714285714rem !important' }}>
            Settings
          </Typography>    
          <FontAwesomeIcon size="1x" icon={['fas', 'fa-gear']} color="white" sx={{ marginRight: '13px' }} />    
        </div>
      </Box>
      { settingsModalOpen &&
            
        <EditCardModal
          settingsInfo={settingsInfo}
          classes={classes}
          open={settingsModalOpen}
          isLoading={isLoading}
          onClose={handleCancelEdits}
          onSubmit={handleSaveEdits}
        />
      }
    
    </ThemeProvider>
  );
};
UserSettingsModal.propTypes = {
  updateFields: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};
export default connect(
  null,
  { showToast },
)(UserSettingsModal);
    
    
export const EditCardModal = ({ open, classes, settingsInfo, onClose, onSubmit, isLoading }) => {
  
  const [vip, setVIP] = useState(settingsInfo.vip ? settingsInfo.vip : '');
  const [webAccess, setWebAccess] = useState(settingsInfo.webAccess ? settingsInfo.webAccess : '');
  const [excludeFromVendorReport, setExcludeFromVendorReport] = useState(settingsInfo.excludeFromVendorReport ? settingsInfo.excludeFromVendorReport : '');
  const [far, setFAR] = useState(settingsInfo.far ? settingsInfo.far : '');
  const [excludeFromSystemReport, setExcludeFromSystemReport] = useState(settingsInfo.excludeFromSystemReport ? settingsInfo.excludeFromSystemReport : '');
    
  const handleSubmit = (e) => {
    //put your validation logic here
    // need to add 'modified on / by' logic here
    e.preventDefault();
    onSubmit(vip, webAccess, excludeFromVendorReport, far, excludeFromSystemReport);
  };
    
  const handleCancel = (e) => {
    //put your validation logic here
    e.preventDefault();
    onClose();
  };
    
  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Typography variant="h5" >
              User Settings
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 3 }} sx={{ padding: '30px', paddingTop: '0px' }}>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'vip'}
                    name={'vip'}
                    label={'VIP'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={vip}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setVIP( e.target.checked ? true : false )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'webAccess'}
                    name={'webAccess'}
                    label={'Web Access'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={webAccess}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setWebAccess(e.target.checked ? true : false )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'excludeFromVendorReport'}
                    name={'excludeFromVendorReport'}
                    label={'Exclude From Vendor Report'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={excludeFromVendorReport}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setExcludeFromVendorReport(e.target.checked ? true : false )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'far'}
                    name={'far'}
                    label={'FAR'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={far}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setFAR(e.target.checked ? true : false )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'excludeFromSystemReport'}
                    name={'excludeFromSystemReport'}
                    label={'Exclude From System Report'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={excludeFromSystemReport}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setExcludeFromSystemReport(e.target.checked ? true : false )
                    }
                  />   
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && 
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        }
        {!isLoading && 
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSubmit} color="primary" type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        }
      </form>
    </Dialog>
  );
};
    
    