import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  TextField,
  Box,
  Typography,
  Card,
  CardContent,
  Tooltip,
  Grid,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import Spinner from 'modules/common/spinner.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const mapRows = (selectedLineExpense, selectedLineExpenseCustomTaxes) => {
  return [
    {
      id: 'Federal',
      isCustomTax: false,
      displayName: 'Federal',
      taxAssistanceAmount: selectedLineExpense?.federalTaxAssistanceAmount,
      withholdingAmount: selectedLineExpense?.federalWithholdingAmount,
      reportableIncomeAmount: selectedLineExpense?.federalReportableIncomeAmount
    },
    {
      id: 'State',
      isCustomTax: false,
      displayName: 'State',
      taxAssistanceAmount: selectedLineExpense?.stateTaxAssitanceAmount,
      withholdingAmount: selectedLineExpense?.stateWithholdingAmount,
      reportableIncomeAmount: selectedLineExpense?.stateReportableIncomeAmount
    },
    {
      id: 'Soc Sec',
      isCustomTax: false,
      displayName: 'Soc Sec',
      taxAssistanceAmount: selectedLineExpense?.socialSecurityAssitanceAmount,
      withholdingAmount: selectedLineExpense?.socialSecurityWithholdingAmount,
      reportableIncomeAmount: selectedLineExpense?.socialSecurityReportableIncomeAmount
    },
    {
      id: 'Medicare',
      isCustomTax: false,
      displayName: 'Medicare',
      taxAssistanceAmount: selectedLineExpense?.medicareAssitanceAmount,
      withholdingAmount: selectedLineExpense?.medicareWithholdingAmount,
      reportableIncomeAmount: selectedLineExpense?.medicareReportableIncomeAmount
    },
    ...createCustomRows(selectedLineExpenseCustomTaxes)
  ];
};

const createCustomRows = selectedLineExpenseCustomTaxes => {
  if (!Array.isArray(selectedLineExpenseCustomTaxes) || selectedLineExpenseCustomTaxes.length === 0) return [];
  return selectedLineExpenseCustomTaxes.map(tax => ({
    id: tax?.customTaxId,
    isCustomTax: true,
    displayName: tax?.displayName,
    taxAssistanceAmount: tax?.taxAssistanceAmount,
    withholdingAmount: tax?.withholdingAmount,
    reportableIncomeAmount: tax?.reportableIncomeAmount
  }));
};

const ExpenseLineItemsTaxesEditableCard = props => {
  const {
    selectedLineExpense,
    selectedLineExpenseCustomTaxes,
    updateCustomTaxes,
    deleteCustomTaxes,
    createCustomTaxes,
    handleTaxesSubmission,
    isLoading,
    isDeleteLoading
  } = props;
  const classes = useStyles();
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  //TODO - Should we just stuff all these into custom tax and change the name?
  const [rows, setRows] = useState(mapRows(selectedLineExpense, selectedLineExpenseCustomTaxes));

  const [editingRow, setEditingRow] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [totals, setTotals] = useState({
    taxAssistanceAmount: '--',
    withholdingAmount: '--',
    reportableIncomeAmount: '--'
  });

  const handleAddRow = () => {
    setEditingRow({
      displayName: '',
      taxAssistanceAmount: 0,
      withholdingAmount: 0,
      reportableIncomeAmount: 0,
      isCustomTax: true,
      id: null
    });
    setDialogOpen(true);
  };

  const handleDeleteRow = async id => {
    const resp = await deleteCustomTaxes(id);
    if (resp) {
      const newRows = rows.filter(row => row.id !== id);
      setRows(newRows);
    }
  };

  const handleEditRow = row => {
    setEditingRow(row);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditingRow(null);
  };

  const handleDialogSave = async () => {
    if (editingRow.isCustomTax) {
      let resp;
      if (!editingRow.id) {
        resp = await createCustomTaxes(editingRow);
        if (resp.isSuccessful) {
          setRows(prevRows => [...prevRows, { ...editingRow, id: resp.newId }]);
          handleDialogClose();
        }
      } else {
        resp = await updateCustomTaxes(editingRow);
        if (resp) {
          setRows(prevRows => prevRows.map(row => (row.id === editingRow.id ? { ...editingRow } : row)));
          handleDialogClose();
        }
      }
    } else {
      const resp = await handleTaxesSubmission(editingRow);
      if (resp) {
        setRows(prevRows => prevRows.map(row => (row.id === editingRow.id ? { ...editingRow } : row)));
        handleDialogClose();
      }
    }
  };

  const handleInputChange = (e, field) => {
    setEditingRow({ ...editingRow, [field]: e.target.value });
  };

  const getTotal = useCallback(
    col => {
      const total = rows.reduce((acc, row) => {
        const value = parseFloat(row[col]);
        return isNaN(value) ? acc : acc + value;
      }, 0);
      return `$${total.toFixed(2)}`;
    },
    [rows]
  );

  const parseValueForGrid = val => {
    if (val === null || isNaN(val)) return '--';

    let parsedVal = parseFloat(val);
    if (isNaN(parsedVal)) return '--';

    return `$${parsedVal.toFixed(2)}`;
  };

  const renderHeader = () => {
    return (
      <div className="row justify-space-between align-center">
        <div className={classes.iconRow}>
          <Tooltip title="Taxes" key={'Taxes'} placement="right">
            <FontAwesomeIcon icon={['fa', 'file-contract']} size="2x" />
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              paddingLeft: '20px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: '1.3125rem',
                fontWeight: 'bold'
              }}
            >
              Taxes
            </Typography>
          </Box>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTotals({
      taxAssistanceAmount: getTotal('taxAssistanceAmount'),
      withholdingAmount: getTotal('withholdingAmount'),
      reportableIncomeAmount: getTotal('reportableIncomeAmount')
    });
  }, [rows, getTotal]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ position: 'relative' }}>
        <Box>
          <CardContent className={classes.cardContainer}>{renderHeader()}</CardContent>
          {isDeleteLoading ? (
            <FullscreenSpinner />
          ) : (
            <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Description
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Tax Assistance
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Withholding
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Reportable Income
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow
                        key={row.id}
                        onClick={() => handleEditRow(row)}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)'
                          },
                          cursor: 'pointer'
                        }}
                      >
                        <TableCell sx={{ borderBottom: 'none' }}>
                          {row.isCustomTax ? (
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                handleDeleteRow(row.id);
                              }}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            {row.displayName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            {parseValueForGrid(row.taxAssistanceAmount)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            {parseValueForGrid(row.withholdingAmount)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            {parseValueForGrid(row.reportableIncomeAmount)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>Total</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>{totals.taxAssistanceAmount}</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>{totals.withholdingAmount}</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>{totals.reportableIncomeAmount}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                  <div className="row justify-space-between align-center">
                    <div className={classes.dialogIconRow}>
                      <Tooltip title="Currency" key="Currency" placement="right">
                        <FontAwesomeIcon icon={['fas', 'money-bill-transfer']} size="3x" />
                      </Tooltip>
                      <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
                        {editingRow?.id ? `Edit ${editingRow?.displayName}` : `Add Custom Tax`}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.dialogContentContainer}>
                    <Stack
                      sx={{
                        width: '100%',
                        minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
                        gap: '1.5rem'
                      }}
                    >
                      <Grid container spacing={{ xs: 2, md: 3 }} sx={{ padding: '30px', paddingTop: '0px' }}>
                        <Grid item key="displayName">
                          <TextField
                            label="Description"
                            fullWidth
                            value={editingRow?.displayName || ''}
                            onChange={e => handleInputChange(e, 'displayName')}
                            margin="dense"
                            disabled={!editingRow?.isCustomTax}
                          />
                        </Grid>
                        <Grid item key="taxAssistanceAmount">
                          <TextField
                            label="Tax Assistance Amount"
                            value={editingRow?.taxAssistanceAmount || ''}
                            onChange={e => handleInputChange(e, 'taxAssistanceAmount')}
                            sx={{ maxWidth: '360px' }}
                            variant="standard"
                            type="number"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item key="withholdingAmount">
                          <TextField
                            label="Withholding"
                            value={editingRow?.withholdingAmount || ''}
                            onChange={e => handleInputChange(e, 'withholdingAmount')}
                            sx={{ maxWidth: '360px' }}
                            variant="standard"
                            type="number"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item key="reportableIncomeAmount">
                          <TextField
                            label="Reportable Income"
                            value={editingRow?.reportableIncomeAmount || ''}
                            onChange={e => handleInputChange(e, 'reportableIncomeAmount')}
                            sx={{ maxWidth: '360px' }}
                            variant="standard"
                            type="number"
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </div>
                  <DialogActions>
                    {isLoading && (
                      <DialogActions sx={{ p: '1.25rem' }}>
                        <Spinner />
                      </DialogActions>
                    )}
                    {!isLoading && (
                      <DialogActions sx={{ p: '1.25rem' }}>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleDialogSave} color="primary">
                          Save
                        </Button>
                      </DialogActions>
                    )}
                  </DialogActions>
                </Dialog>
              </TableContainer>
              {!props.viewOnly && (
                <Box sx={{ paddingTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleAddRow} variant="contained" color="primary" style={{ margin: '10px' }}>
                    Add Custom Tax
                  </Button>
                </Box>
              )}
            </CardContent>
          )}
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default ExpenseLineItemsTaxesEditableCard;
