import { TextField as MuiTextField, withStyles } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import InputContext from 'modules/intake/form/inputContext';
import NumberFormat from 'modules/intake/form/numberFormat.component';

const styles = (theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
});

class TextField extends PureComponent {
  static defaultProps = {
    numeric: false,
  };
  static contextType = InputContext;

  render() {
    const { onBlur: contextOnBlur, onFocus: contextOnFocus, onChange: contextOnChange } = this.context;
    const {
      classes,
      numeric,
      prefix,
      suffix,
      allowDecimal,
      includeThousandsSeparator,
      inputProps,
      onChange: propsOnChange,
      onBlur: propsOnBlur,
      ...rest
    } = this.props;

    const {
      field: {
        name: fieldName,
        onChange: formikOnChange,
        onBlur: formikOnBlur,
      },
      form,
    } = rest;

    const inputComponentProps = numeric ? {
      inputComponent: NumberFormat,
    } : {};
    const numberFormatProps = numeric ? {
      allowDecimal,
      includeThousandsSeparator,
      prefix,
      suffix,
    } : {};
    const isDateInput = rest.type === 'date';
    const dateRange = (isDateInput && rest.max && rest.min) ? { max: rest.max, min: rest.min } : { max: '9999-12-31', min: '1400-01-01' };
    const dateInputProps = isDateInput ? dateRange : {};

    return (
      <MuiTextField
        {...fieldToTextField(rest)}
        onBlur={(event) => {
          if (propsOnBlur) {
            propsOnBlur(event);
          } else {
            form.setFieldTouched(fieldName);
            formikOnBlur(event);
          }
          contextOnBlur(event);
        }}
        onFocus={contextOnFocus}
        onChange={(event) => {
          if (propsOnChange) {
            propsOnChange(event);
          } else {
            formikOnChange(event);
          }
          contextOnChange(event);
        }}
        onKeyPress={isDateInput ? (event) => {
          // date inputs don't trigger change event if date is not yet valid, need to reset timer here
          contextOnChange();
        }: undefined}
        InputLabelProps={{ shrink: isDateInput || (rest.value != null && rest.value !== '') ? true : undefined }}
        InputProps={inputComponentProps}
        // eslint-disable-next-line
        inputProps={{ ...inputProps, ...numberFormatProps, ...dateInputProps }}
        variant="outlined"
        classes={{
          root: classes.root,
        }}
      />
    );
  }
}

TextField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  numeric: PropTypes.bool,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  allowDecimal: PropTypes.bool,
  includeThousandsSeparator: PropTypes.bool,
  inputProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextField);
