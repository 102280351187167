/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DELETE_BROKER_AGENTS_FAILURE, GET_ALL_BROKER_AGENTS_FAILURE, POST_BROKER_AGENTS_FAILURE, UPDATE_BROKER_AGENTS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { deleteBrokerAgents, getBrokerAgents, postBrokerAgents, updateBrokerAgents } from 'modules/authorizations/store/actions/dataManagement.actions';
import CrudTable from '../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';
import countries from 'modules/common/countries.json';
import states from 'modules/common/states.json';

import { memoize } from 'lodash';
import classNames from 'classnames';


const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const BrokerAgentsTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    let allStates = states.filter((state) => state.countryCode === useCountryCode)
      .map(( state ) => (
        state.description
      ));
    return allStates;
  });

  const renderCountryOptions = memoize(() => {
    let allCountries = countries.map(( country ) => (
      country.description
    ));
    return allCountries;
  });

  const adjustedColumns = [
    { accessorKey: 'typeOfAgent', header: 'Type of Agent', type: 'dropdown', options: ['Broker', 'Agent', 'Appraiser'], required: true, size: 70 },
    { accessorKey: 'brokerCode', header: 'Broker Code', type: 'text', required: false, size: 70 },
    { accessorKey: 'agentName', header: 'Agent Name', type: 'text', required: true, size: 70 },
    { accessorKey: 'companyName', header: 'Company Name', type: 'text', required: true, size: 70 },
    { accessorKey: 'rank', header: 'Rank', type: 'dropdown', options: ['1 - DNU', '2 - Poor', '3 - Fair', '4 - Good', '5 - Excellent'], required: false, size: 50, hideInTable: true },
    { accessorKey: 'surveyRanking', header: 'Survey Ranking', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'surveyCount', header: 'Survey Count', type: 'number', required: false, size: 50, hideInTable: true },
    { accessorKey: 'masterRec', header: 'Master Rec', type: 'checkbox', required: false, size: 50, hideInTable: true },
    { accessorKey: 'masterRecCode', header: 'Master Rec Code', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'city', header: 'City', type: 'text', required: true, size: 50 },
    { accessorKey: 'state', header: 'State', type: 'dropdown', options: renderStateOptions(''), required: true, size: 50 },
    { accessorKey: 'zipCode', header: 'Zip Code', type: 'text', required: true, size: 50, hideInTable: true },
    { accessorKey: 'country', header: 'Country', type: 'dropdown', options: renderCountryOptions(), required: true, size: 50, hideInTable: true },
    { accessorKey: 'citiesServed', header: 'Cities Served', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'zipCodesServed', header: 'Zip Codes Served', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'phoneNumber', header: 'Phone Number', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'contactType', header: 'Contact Type', type: 'dropdown', options: ['Agent', 'Appraiser', 'Buyers Agent', 'Relo Director', 'Sellers Agent'], required: false, size: 50, hideInTable: true },
    { accessorKey: 'contactFirstName', header: 'Contact First Name', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'contactLastName', header: 'Contact Last Name', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'contactPhoneNumber', header: 'Contact Phone Number', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'contactEmail', header: 'Contact Email', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'contactMobileNumber', header: 'Contact Mobile Number', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'contactFax', header: 'Contact fax', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'taxID', header: 'Tax ID', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'titleRepId', header: 'Title Rep Id', type: 'number', required: false, size: 50, hideInTable: true },
    { accessorKey: 'vendorId', header: 'Vendor Id', type: 'number', required: false, size: 50, hideInTable: true },
  ];

  const updateRow = async (policy, values) => {
    const id = policy.original.id;
    values.id = id;

    const checkValues = {
      brokerId: policy.original.brokerId,
      typeOfAgent: values.typeOfAgent,
      brokerCode: values.brokerCode ? values.brokerCode : '',
      agentName: values.agentName ? values.agentName : '',
      companyName: values.companyName ? values.companyName : '',
      rank: values.rank ? values.rank : '',
      surveyRanking: values.surveyRanking ? values.surveyRanking : '',
      surveyCount: values.surveyCount ? values.surveyCount : 0,
      masterRec: values.masterRec ? values.masterRec : false,
      masterRecCode: values.masterRecCode ? values.masterRecCode : '',
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      country: values.country,
      phoneNumber: values.phoneNumber ? values.phoneNumber : '',
      contactType: values.contactType ? values.contactType : '',
      contactFirstName: values.contactFirstName ? values.contactFirstName : '',
      contactLastName: values.contactLastName ? values.contactLastName : '',
      contactPhoneNumber: values.contactPhoneNumber ? values.contactPhoneNumber : '',
      contactEmail: values.contactEmail ? values.contactEmail : '',
      contactMobileNumber: values.contactMobileNumber ? values.contactMobileNumber : '',
      contactFax: values.contactFax ? values.contactFax : '',
      taxID: values.taxID ? values.taxID : '',
      titleRepId: values.titleRepId ? values.titleRepId : 0,
      vendorId: values.vendorId ? values.vendorId : 0,
    };

    const resp = await props.updateBrokerAgents(checkValues);
    if (resp.type === UPDATE_BROKER_AGENTS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getBrokerAgents();
      if (getResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    const checkValues = {
      typeOfAgent: values.typeOfAgent,
      brokerCode: values.brokerCode ? values.brokerCode : '',
      agentName: values.agentName ? values.agentName : '',
      companyName: values.companyName ? values.companyName : '',
      rank: values.rank ? values.rank : '',
      surveyRanking: values.surveyRanking ? values.surveyRanking : '',
      surveyCount: values.surveyCount ? values.surveyCount : 0,
      masterRec: values.masterRec ? values.masterRec : false,
      masterRecCode: values.masterRecCode ? values.masterRecCode : '',
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      country: values.country,
      phoneNumber: values.phoneNumber ? values.phoneNumber : '',
      contactType: values.contactType ? values.contactType : '',
      contactFirstName: values.contactFirstName ? values.contactFirstName : '',
      contactLastName: values.contactLastName ? values.contactLastName : '',
      contactPhoneNumber: values.contactPhoneNumber ? values.contactPhoneNumber : '',
      contactEmail: values.contactEmail ? values.contactEmail : '',
      contactMobileNumber: values.contactMobileNumber ? values.contactMobileNumber : '',
      contactFax: values.contactFax ? values.contactFax : '',
      taxID: values.taxID ? values.taxID : '',
      titleRepId: values.titleRepId ? values.titleRepId : 0,
      vendorId: values.vendorId ? values.vendorId : 0,
    };

    const resp = await props.postBrokerAgents(checkValues);
    if (resp.type === POST_BROKER_AGENTS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getBrokerAgents();
      if (getResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (brokerAgent) => {
    const id = brokerAgent.original.brokerId;
    const resp = await props.deleteBrokerAgents(id);
    if (resp.type === DELETE_BROKER_AGENTS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getBrokerAgents();
      if (getResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Broker/Agents</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.brokerAgentsLoading && props.brokerAgents && (
          <div>
            <CrudTable 
              editingMode={'modal'} // Can be changed to 'row' to edit inline 
              columns={adjustedColumns} 
              rows={props.brokerAgents} 
              updateRow={updateRow} 
              createRow={createRow} 
              deleteRow={deleteRow} 
            />
          </div>
        )}
        {props.brokerAgentsLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

BrokerAgentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { brokerAgents, brokerAgentsLoading } = state.authorizations;
  return {
    brokerAgents,
    brokerAgentsLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    deleteBrokerAgents, 
    getBrokerAgents, 
    postBrokerAgents, 
    updateBrokerAgents,
  }),
)(BrokerAgentsTable);