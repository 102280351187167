/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, DialogActions, Grid } from '@mui/material';
import {
  CreateAcceptanceApiRequest,
  CreateApprovalApiRequest,
  CreateImportantDeadlinesApiRequest,
  CreateLocalTitleCoApiRequest,
  CreatePersonalPropertyApiRequest,
  CreateRequestInsurancePlacementRequest,
  CreateResetButtonStatusApiRequest,
  CreateSendOfferPackageRequest,
  CreateTitleDocumentsApiRequest,
  CreateTitleOrderApiRequest,
} from './buyoutApiRequestFactory';
import {
  CreateApprovalAndAcceptanceFormFields,
  CreateImportantDeadlinesFormFields,
  CreatePersonalPropertyFormFields,
  CreateTitleFormFields,
} from './buyoutFormFields';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postHomesaleInfo, updateHomesaleInfo } from 'modules/authorizations/store/actions/homesale.actions.js';
import { showToast } from 'modules/layout/layout.actions';
import { useBuyoutHandler } from './buyoutApiHandler.js';
import EditableCard from 'modules/common/editableCard.component';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const Buyout = (props) => {
  const { homesale, titleReps, id, isReadOnly } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSendButtonRequestLoading, setIsSendButtonRequestLoading] = useState(false);
  const buyoutHandler = useBuyoutHandler(props);

  const checkAndCreateHomesale = async () => {
    if (!homesale || !homesale.authorizationId || !homesale.authorizationId === '') {
      return await buyoutHandler.handleCreateHomesaleInfo({ ...homesale, authorizationId: id });
    }
    return true;
  };

  const handleSendOfferPackageSubmit = async () => {
    setIsSendButtonRequestLoading(true);
    const createHomesaleRep = await checkAndCreateHomesale();
    if (!createHomesaleRep) {
      setIsSendButtonRequestLoading(false);
      return false;
    }
    const request = CreateSendOfferPackageRequest(homesale, id);
    const resp = await buyoutHandler.handleUpdateHomesaleInfo(request);
    setIsSendButtonRequestLoading(false);
    return resp;
  };

  const handleResetButtonStatuses = async () => {
    setIsSendButtonRequestLoading(true);
    const createHomesaleRep = await checkAndCreateHomesale();
    if (!createHomesaleRep) {
      setIsSendButtonRequestLoading(false);
      return false;
    }
    const request = CreateResetButtonStatusApiRequest(homesale, id);
    const resp = await buyoutHandler.handleUpdateHomesaleInfo(request);
    setIsSendButtonRequestLoading(false);
    return resp;
  };

  const handleRequestInsurancePlacementSubmit = async () => {
    setIsSendButtonRequestLoading(true);
    const createHomesaleRep = await checkAndCreateHomesale();
    if (!createHomesaleRep) {
      setIsSendButtonRequestLoading(false);
      return false;
    }
    const request = CreateRequestInsurancePlacementRequest(homesale, id);
    const resp = await buyoutHandler.handleUpdateHomesaleInfo(request);
    setIsSendButtonRequestLoading(false);
    return resp;
  };

  const handleUpdateApprovalOrAcceptanceSubmit = async (tab, values) => {
    setIsLoading(true);
    const createHomesaleRep = await checkAndCreateHomesale();
    if (!createHomesaleRep) {
      setIsLoading(false);
      return false;
    }
    let request = null;
    switch (tab) {
      case 1:
        request = CreateApprovalApiRequest(homesale, values, id);
        break;
      case 2:
        request = CreateAcceptanceApiRequest(homesale, values, id);
        break;
      default:
        console.log('Invalid Tab');
        break;
    }
    const resp = await buyoutHandler.handleUpdateHomesaleInfo(request);
    setIsLoading(false);
    return resp;
  };

  const handlePersonalPropertySubmit = async (tab, values) => {
    setIsLoading(true);
    const createHomesaleRep = await checkAndCreateHomesale();
    if (!createHomesaleRep) {
      setIsLoading(false);
      return false;
    }
    const request = CreatePersonalPropertyApiRequest(homesale, values, id);
    const resp = await buyoutHandler.handleUpdateHomesaleInfo(request);
    setIsLoading(false);
    return resp;
  };

  const handleTitleCompanySubmit = async (tab, values) => {
    setIsLoading(true);
    const createHomesaleRep = await checkAndCreateHomesale();
    if (!createHomesaleRep) {
      setIsLoading(false);
      return false;
    }
    let request = null;
    switch (tab) {
      case 1:
        request = CreateTitleOrderApiRequest(homesale, values, id);
        break;
      case 2:
        request = CreateTitleDocumentsApiRequest(homesale, values, id);
        break;
      case 3:
        request = CreateLocalTitleCoApiRequest(homesale, values, id);
        break;
      default:
        console.log('Invalid Tab');
        break;
    }
    const resp = await buyoutHandler.handleUpdateHomesaleInfo(request);
    setIsLoading(false);
    return resp;
  };

  const handleImportantDatesSubmit = async (tab, values) => {
    setIsLoading(true);
    const createHomesaleRep = await checkAndCreateHomesale();
    if (!createHomesaleRep) {
      setIsLoading(false);
      return false;
    }
    let request = CreateImportantDeadlinesApiRequest(homesale, values, id);
    const resp = buyoutHandler.handleUpdateHomesaleInfo(request);
    setIsLoading(false);
    return resp;
  };

  //        public bool? OfferPackageSent { get; set; }
  //public bool? InsurancePlacementSent { get; set; }
  //

  const SendInspectionButton = ({ ...rest }) => {
    let offerPackageButtonMessage = homesale?.offerPackageSent === true ? 'Re-Send Offer Package' : 'Send Offer Package';
    let insurancePlacementButtonMessage =
      homesale?.insurancePlacementSent === true ? 'Re-Request Insurance Placement' : 'Request Insurance Placement';
    return (
      <>
        {isSendButtonRequestLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          </Box>
        ) : (
          !isReadOnly && 
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 10px',
              }}
            >
              <Button
                onClick={async () => await handleSendOfferPackageSubmit()}
                color="secondary"
                variant="contained"
                sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
              >
                {offerPackageButtonMessage}
              </Button>
              <Button
                onClick={async () => await handleRequestInsurancePlacementSubmit()}
                color="secondary"
                variant="contained"
                sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
              >
                {insurancePlacementButtonMessage}
              </Button>
              <Button
                onClick={async () => await handleResetButtonStatuses()}
                variant="contained"
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: '#868686',
                  '&:hover': { backgroundColor: '#474747' },
                }}
              >
                Reset Button Statuses
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  };

  const showButtonComponentFunc = (currentTab) => {
    return currentTab === 2;
  };

  let approvalAndAcceptanceFormFields = CreateApprovalAndAcceptanceFormFields(homesale);
  let titleFormFields = CreateTitleFormFields(homesale, titleReps);
  let personalPropertyFormFields = CreatePersonalPropertyFormFields(homesale);
  let importantDeadlines = CreateImportantDeadlinesFormFields(homesale);

  return (
    <>
      <Box sx={{ paddingTop: '10px' }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8}>
            <Box sx={{ mb: 1 }}>
              <EditableCard
                tabs={approvalAndAcceptanceFormFields.tabs}
                summaries={approvalAndAcceptanceFormFields.summaries}
                updateFields={handleUpdateApprovalOrAcceptanceSubmit}
                isLoading={isLoading}
                numColumns={5}
                ButtonComponent={SendInspectionButton}
                showButtonComponentFunc={showButtonComponentFunc}
                hideEdit={isReadOnly}
              />
            </Box>
            {homesale?.saleType !== 1 ? (
              <Box sx={{ mb: 1 }}>
                <EditableCard
                  tabs={titleFormFields.tabs}
                  summaries={titleFormFields.summaries}
                  updateFields={handleTitleCompanySubmit}
                  isLoading={isLoading}
                  numColumns={5}
                  hideEdit={isReadOnly}
                />
              </Box>
            ) : (
              <div />
            )}
          </Grid>
          <Grid item xs={12} xl={4}>
            {homesale?.saleType !== 1 ? (
              <Box sx={{ mb: 1 }}>
                <EditableCard
                  tabs={personalPropertyFormFields.tabs}
                  summaries={personalPropertyFormFields.summaries}
                  updateFields={handlePersonalPropertySubmit}
                  isLoading={isLoading}
                  numColumns={1}
                  hideEdit={isReadOnly}
                />
              </Box>
            ) : (
              <div />
            )}
            <Box sx={{ mb: 1 }}>
              <EditableCard
                tabs={importantDeadlines.tabs}
                summaries={importantDeadlines.summaries}
                updateFields={handleImportantDatesSubmit}
                isLoading={isLoading}
                numColumns={3}
                hideEdit={isReadOnly}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Buyout.propTypes = {
  homesale: PropTypes.object.isRequired,
  titleReps: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

export default compose(
  connect(null, {
    updateHomesaleInfo,
    postHomesaleInfo,
    showToast,
  }),
)(Buyout);
