
export const GET_TAX_POLICIES_BY_CLIENT_FAILURE = 'GET_TAX_POLICIES_BY_CLIENT_FAILURE';
export const GET_TAX_POLICIES_BY_CLIENT_REQUEST = 'GET_TAX_POLICIES_BY_CLIENT_REQUEST';
export const GET_TAX_POLICIES_BY_CLIENT_SUCCESS = 'GET_TAX_POLICIES_BY_CLIENT_SUCCESS';

export const GET_AUTH_TAX_ASSISTANCE_FAILURE = 'GET_AUTH_TAX_ASSISTANCE_FAILURE';
export const GET_AUTH_TAX_ASSISTANCE_REQUEST = 'GET_AUTH_TAX_ASSISTANCE_REQUEST';
export const GET_AUTH_TAX_ASSISTANCE_SUCCESS = 'GET_AUTH_TAX_ASSISTANCE_SUCCESS';

export const POST_AUTH_TAX_ASSISTANCE_FAILURE = 'POST_AUTH_TAX_ASSISTANCE_FAILURE';
export const POST_AUTH_TAX_ASSISTANCE_REQUEST = 'POST_AUTH_TAX_ASSISTANCE_REQUEST';
export const POST_AUTH_TAX_ASSISTANCE_SUCCESS = 'POST_AUTH_TAX_ASSISTANCE_SUCCESS';

export const UPDATE_AUTH_TAX_ASSISTANCE_FAILURE = 'UPDATE_AUTH_TAX_ASSISTANCE_FAILURE';
export const UPDATE_AUTH_TAX_ASSISTANCE_REQUEST = 'UPDATE_AUTH_TAX_ASSISTANCE_REQUEST';
export const UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS = 'UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS';

 