/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummaries, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress, getEmployeeInfo, getFamilyMembers, getFamilyPets, getServices, getTeam, getUserSettings, setAuthId } from '../../../store/actions/authorizations.actions';
import { setLoadingComplete } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import { getExpenseLinesByAuthId, getExpenseSummariesByAuth } from 'modules/expenses/expenses.actions';
import ExpenseLineItemDetails from './expenseLineItemDetailsView.component';
import { getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import { fetchAuthDetails, fetchAuthExpensesDetails, fetchVendors } from 'modules/authorizations/utils/fetchDataDetailsService';

const ExpenseLineItemDetailsView = props => {
  const { authorizationDetails, currentAuthId, match, history } = props;

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const details = authorizationDetails ? authorizationDetails[currentAuthId] : null;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const id = match.params.id;
      props.setAuthId(id);

      setIsLoading(true);

      await fetchAuthDetails(details, id, props);
      await fetchVendors(props?.vendors, props);
      await fetchAuthExpensesDetails(details, id, props);
      
      props.setLoadingComplete();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const expenseLineId = match.params.expenseLineId;

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && <ExpenseLineItemDetails details={details} expenseLineId={expenseLineId} location={props.location} history={history}/>}
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading, currentAuthId, vendors } = state.authorizations;
  return {
    authorizationDetails,
    isLoading,
    currentAuthId,
    vendors
  };
};

export default compose(
  connect(mapStateToProps, {
    getAuthClientPolicies,
    getEmployeeInfo,
    getExpenseSummariesByAuth,
    setAuthId,
    setLoadingComplete,
    showToast,
    getExpenseLinesByAuthId,
    getAuthorizationSummaries,
    getVendors,
    getAuthorizationSummary,
    getCommunicationInfo,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getFamilyMembers,
    getFamilyPets,
  }),
)(ExpenseLineItemDetailsView);
