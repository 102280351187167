import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const StorageDetailsCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const tabs = [
    { header: 'Storage Details', order: 1, iconPath: ['fas', 'file-contract'] },
  ];

  const typeOptions = [
    { title: 'Short Term', id: 1 },
    { title: 'Long Term', id: 2 },
  ];

  const summaries = [
    { header: 'Storage Type', accessorKey: 'storageType', type: 'dropdown-v2', options: typeOptions, required: false, value: segmentInfo ? segmentInfo.storageType : '', tab: 1 },
    { header: 'Storage Weight (lbs)', accessorKey: 'storageWeight', type: 'decimal', required: false, value: segmentInfo ? segmentInfo.storageWeight : '', tab: 1 },
    { header: 'Est Cost of Exception', accessorKey: 'storageExceptionCost', type: 'money', required: false, value: segmentInfo ? segmentInfo.storageExceptionCost : '', tab: 1 },
    { header: 'Car Storage', accessorKey: 'carStorageRequired', type: 'checkbox', required: false, value: segmentInfo ? segmentInfo.carStorageRequired : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.storageType = values.storageType;
    newValues.storageWeight = values.storageWeight;
    newValues.storageExceptionCost = values.storageExceptionCost;
    newValues.carStorageRequired = values.carStorageRequired;

        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={2} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(StorageDetailsCard);