/* eslint-disable array-callback-return */
import { array, object, string } from 'yup';
import { get, without } from 'lodash';

import { formatForWire, isNotDefined, parseNumbers } from 'utilities/common';
import { getDistance } from 'utilities/googleMapsClient';
import AuthorizationComment from './authorizationComment';
import AuthorizationInformation from './authorizationInformation';
import Benefits from './benefits';
import Contact from './contact';
import DeparturePropertyInformation from './departurePropertyInformation';
import DestinationInformation from './destinationInformation';
import FamilyInformation from './familyInformation';
import HhgInformation from './hhgInformation';
import Location from './location';
import Note from './note';
import PrimaryInformation from './primaryInformation';
import TemporaryLivingInformation from './temporaryLivingInformation';

export default class IntakeRecord {
  static schema = object().shape({
    primaryInformation: PrimaryInformation.schema,
    authorizationInformation: AuthorizationInformation.schema,
    familyInformation: FamilyInformation.schema,
    departureProperty: DeparturePropertyInformation.schema,
    hhgInformation: HhgInformation.schema,
    temporaryLiving: TemporaryLivingInformation.schema,
    newDestination: DestinationInformation.schema,
    notes: array().of(Note.schema),
    benefits: Benefits.schema,
    authorizationComments: array().of(AuthorizationComment.schema),
    mortgageVendors: object().shape({
      staticVendors: array().nullable(),
      dynamicVendors: array().nullable(),
      reason: string().nullable(),
      message: string().nullable(),
    }),
    vendorLetters: object().shape({
      staticLetters: array().nullable(),
      dynamicLetters: array().nullable(),
      reason: string().nullable(),
    }),
  });

  constructor(wireData, metadata) {
    const json = wireData || {};
    const {
      id,
      departureProperty,
      contacts,
      contactPreferenceTypeId,
      destinationCity,
      destinationStateCode,
      destinationCountryCode,
      relocationDistance,
      mtAuthorizationId,
      client,
      moveTypeId,
      vip,
      newJobTitle,
      authorizationDate,
      newJobStartDate,
      repaymentAgreementRequired,
      repaymentAgreementSentDate,
      repaymentAgreementReceivedDate,
      relocationPolicyCodeId,
      intakeRecordStatusId,
      pets,
      phoneNumbers,
      emailAddresses,
      isSellingDepartureProperty,
      isEligibleForHomesale,
      isHhgServiceInitiated,
      isTemporaryLivingServiceInitiated,
      isDestinationServiceInitiated,
      notes,
      authorizationComments,
      maritalStatusId,
      taxFilingStatusId,
      selfServicePolicyId,
      selfServicePolicyBudget,
      estimatedStartDate,
      estimatedEndDate,
      departureAddressComment,
      destinationInformationComment
    } = json;

    const mappedContacts = contacts.map((contact) => new Contact(contact, metadata.contactTypes));
    const transferee = mappedContacts.find((contact) => contact.isTransferee());
    const nonTransfereeContacts = without(mappedContacts, transferee);

    this.id = id;

    this.primaryInformation = new PrimaryInformation({
      transferee,
      departureCity: departureProperty.city,
      departureStateCode: departureProperty.stateCode,
      departureCountryCode: departureProperty.countryCode,
      destinationCity,
      destinationStateCode,
      destinationCountryCode,
      relocationDistance,
      relocationPolicyCodeId,
      intakeRecordStatusId,
      relocationPolicyCodes: client.relocationPolicyCodes,
      newJobStartDate,
      mtAuthorizationId,
      estimatedStartDate,
      estimatedEndDate,
      departureAddressComment,
      destinationInformationComment
    });

    this.authorizationInformation = new AuthorizationInformation({
      client,
      moveTypeId,
      authorizationDate,
      vip,
      newJobTitle,
      repaymentAgreementRequired,
      repaymentAgreementSentDate,
      repaymentAgreementReceivedDate,
      selfServicePolicyId,
      selfServicePolicyBudget,
    });

    this.familyInformation = new FamilyInformation({
      maritalStatusId,
      taxFilingStatusId,
      contacts: nonTransfereeContacts,
      pets,
      phoneNumbers,
      emailAddresses,
      contactPreferenceTypeId,
      metadata,
    });

    this.departureProperty = new DeparturePropertyInformation(
      {
        moveTypeId,
        isSellingDepartureProperty,
        isEligibleForHomesale,
        intakeRecordId: id,
        ...json.departureProperty,
      },
      
      transferee,
      nonTransfereeContacts,
      metadata,
    );

    this.hhgInformation = new HhgInformation({
      moveTypeId,
      isHhgServiceInitiated,
      ...json.intakeHhgServiceDetail,
    });

    this.temporaryLiving = new TemporaryLivingInformation(
      {
        moveTypeId,
        isTemporaryLivingServiceInitiated,
        ...json.temporaryLivingInfo,
      },
      nonTransfereeContacts,
      this.familyInformation.pets,
    );

    this.newDestination = new DestinationInformation({
      moveTypeId,
      isDestinationServiceInitiated,
      ...json.destinationServiceInfo,
    });

    const temporaryLivingInfo = get(json, 'temporaryLivingInfo') || {};
    this.benefits = new Benefits(
      {
        relocationAllowance: json.relocationAllowance,
        candidateInternInfo: json.candidateInternInfo,
        homesaleProcess: json.homesaleProcess,
        hhgBenefitsTimeline: json.hhgBenefitsTimeline,
        temporaryLivingInfo: {
          estimatedStartDate: temporaryLivingInfo.estimatedStartDate,
          estimatedEndDate: temporaryLivingInfo.estimatedEndDate,
          costCap: temporaryLivingInfo.costCap,
        },
        houseHunting: json.houseHunting,
        finalMove: json.finalMove,
        miscTimelineEntries: json.miscTimelineEntries,
        spousalAssistance: json.spousalAssistance,
      },
      this.temporaryLiving,
      this.primaryInformation
    );


    ///////////// Mortgage Leads //////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    const vendorsList = [
      {
        name: 'Rocket Mortgage',
        id: 1,
      }, 
      {
        name: 'PNC',
        id: 2,
      },
      {
        name: 'Premia',
        id: 3,
      },
    ];

    let dynamicVendors = [];
    if (json.mortgageLeadRocketSelected !== 0) {
      dynamicVendors.push({
        name: 'Rocket Mortgage',
        id: 1,
      });
    }
    if (json.mortgageLeadPncSelected !== 0) {
      dynamicVendors.push({
        name: 'PNC',
        id: 2,
      });
    }
    if (json.mortgageLeadPremiaSelected !== 0) {
      dynamicVendors.push({
        name: 'Premia',
        id: 3,
      });
    }

    let staticVendors = [...dynamicVendors];
    vendorsList.map((vendor) => {
      if (!staticVendors.some((o) => o.id === vendor.id)) {
        staticVendors.push(vendor);
      }
    });

    this.mortgageVendors = {
      staticVendors,
      dynamicVendors,
      reason: json.mortgageLeadReason || '',
      message: json.mortgageLeadMessage || '',
      closingCostMethod: json.mortgageClosingCostMethod || '',
      typeOfRelocation: json.mortgageTypeOfRelocation || '',
      coApplicantEmail: json.mortgageCoApplicantEmail || '',
      coApplicantPhone: json.mortgageCoApplicantPhone || '',
      statusOfTransferee: json.mortgageStatusOfTransferee || '',
      benefitsCap: json.mortgageBenefitsCap || '',
      amountOfCap: json.mortgageAmountOfCap || '',
      spanishSpeaking: json.mortgageSpanishSpeaking || '',
      directBillEligible: json.mortgageDirectBillEligible ? 'Yes' : (json.mortgageDirectBillEligible === false ? 'No' : ''),
    };

    ///////////// VendorLetters  //////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////    

    //should be temporary until we start taking them from the API
    const vendorsListLetters = [
      {
        name: 'One Source',
        id: '2622087',
      },
    ];

    //json.vendorLetter is a array of letter IDs from the database for this intake record
    //Loop through vendorsListLetters and find matches in json.vendorLetter and add them to dynamicVendorsLetters so they will appear
    //in the select list on the page
    let dynamicVendorsLetters = [];
    let vendors = JSON.parse(json.vendorLetters);

    //if no vendors came back from the server, assign vendorsListLetters as being selected
    if (vendors.length > 0){
      vendorsListLetters.forEach((a) => {
        if (vendors.indexOf(a.id) > -1) dynamicVendorsLetters.push(a);
      },
      );   
    }
    else if (json.vendorLettersReason === null) dynamicVendorsLetters = vendorsListLetters; 

    this.vendorLetters = {
      staticLetters: [...vendorsListLetters],
      dynamicLetters:  [...dynamicVendorsLetters],
      reason: json.vendorLettersReason || '',
    };
  
    this.notes = (notes || []).map((instance) => new Note(instance));

    this.authorizationComments = (authorizationComments || []).map(
      (instance) => new AuthorizationComment(instance, metadata.commentServiceTypes, metadata.commentRegardingTypes),
    );
  }

  static async fromWireData(wireData, metadata) {
    const intakeRecord = new IntakeRecord(wireData, metadata);
    if (isNotDefined(intakeRecord.primaryInformation.relocationDistance)) {
      await intakeRecord.primaryInformation.calculateRelocationDistance();
    }
    return intakeRecord;
  }
  
  static generateErrors(errorData) {
    if (!errorData) {
      return {};
    }

    const {
      primaryInformation,
      authorizationInformation,
      familyInformation,
      departureProperty,
      hhgInformation,
      temporaryLiving,
      newDestination,
      relocationAllowance,
      candidateInternInfo,
      houseHunting,
      homesaleProcess,
      finalMove,
      temporaryLivingTimeline,
      householdGoods,
      spousalAssistance,
      vendorLetters,
    } = errorData;

    return {
      primaryInformation: PrimaryInformation.generateErrors(primaryInformation),
      authorizationInformation: AuthorizationInformation.generateErrors(authorizationInformation),
      familyInformation: FamilyInformation.generateErrors(familyInformation),
      departureProperty: DeparturePropertyInformation.generateErrors(departureProperty),
      hhgInformation: HhgInformation.generateErrors(hhgInformation),
      temporaryLiving: TemporaryLivingInformation.generateErrors(temporaryLiving),
      newDestination: DestinationInformation.generateErrors(newDestination),
      benefits: Benefits.generateErrors({
        relocationAllowance,
        candidateInternInfo,
        houseHunting,
        homesaleProcess,
        finalMove,
        temporaryLivingTimeline,
        householdGoods,
        spousalAssistance,
      }),
      vendorLetters,      
    };
  } 
 

  async toWireData() {

    let syncedRelocationDistance = null;
    try {
      const { city, stateCode, countryCode } = this.departureProperty.address;
      this.primaryInformation.departureCity = city;
      this.primaryInformation.departureStateCode = stateCode;
      this.primaryInformation.departureCountryCode = countryCode;

      const { value } = await getDistance(
        new Location({ city, state: stateCode, country: countryCode }),
        this.primaryInformation.destinationLocation,
      );
      syncedRelocationDistance = value;
    } catch (err) {
      // eat it
    }

    const {
      transferee,
      destinationLocation: { city: destinationCity, state: destinationStateCode, country: destinationCountryCode },
      relocationPolicyCodeId,
      newJobStartDate,
      mtAuthorizationId,
      estimatedStartDate,
      estimatedEndDate,
      departureAddressComment,
      destinationInformationComment,
    } = this.primaryInformation.toWireData();

    const {
      maritalStatusId,
      taxFilingStatusId,
      contacts,
      pets,
      phoneNumbers,
      emailAddresses,
      contactPreferenceTypeId,
    } = this.familyInformation.toWireData();

    const { isSellingDepartureProperty, isEligibleForHomesale, ...departurePropertyRest } = this.departureProperty.toWireData();

    const { isHhgServiceInitiated, ...hhgInformationRest } = this.hhgInformation.toWireData();

    const { isTemporaryLivingServiceInitiated, ...temporaryLivingRest } = this.temporaryLiving.toWireData(contacts, pets);

    const { isDestinationServiceInitiated, ...newDestinationRest } = this.newDestination.toWireData();

    const {
      relocationAllowance,
      candidateInternInfo,
      homesaleProcess,
      hhgBenefitsTimeline,
      temporaryLiving: temporaryLivingBenefits,
      houseHunting,
      finalMove,
      miscellaneous: { miscTimelineEntries },
      spousalAssistance,
    } = this.benefits.toWireData();

    const mortgageLeadData = {
      mortgageLeadPremiaSelected: this.mortgageVendors.dynamicVendors.some((o) => o.id === 3) ? 1 : 0,
      mortgageLeadPncSelected: this.mortgageVendors.dynamicVendors.some((o) => o.id === 2) ? 1 : 0,
      mortgageLeadRocketSelected: this.mortgageVendors.dynamicVendors.some((o) => o.id === 1) ? 1 : 0,
      mortgageLeadMessage: this.mortgageVendors.message,
      mortgageLeadReason: this.mortgageVendors.reason,
      mortgageClosingCostMethod: this.mortgageVendors.closingCostMethod,
      mortgageTypeOfRelocation: this.mortgageVendors.typeOfRelocation,
      mortgageCoApplicantEmail: this.mortgageVendors.coApplicantEmail,
      mortgageCoApplicantPhone: this.mortgageVendors.coApplicantPhone,
      mortgageStatusOfTransferee: this.mortgageVendors.statusOfTransferee,
      mortgageBenefitsCap: this.mortgageVendors.benefitsCap,
      mortgageSpanishSpeaking: this.mortgageVendors.spanishSpeaking,
      mortgageDirectBillEligible: (this.mortgageVendors.directBillEligible === 'Yes') ? true : (this.mortgageVendors.directBillEligible === 'No' ? false : null),
      ...parseNumbers({
        mortgageAmountOfCap: this.mortgageVendors.amountOfCap,
      }),
    };

    const vendorLettersData = {
      vendorLettersList: this.vendorLetters.dynamicLetters.map((letter) => letter.id),
      vendorLettersReason: this.vendorLetters.reason,
    };

    return formatForWire({
      id: this.id,
      contacts: [transferee, ...contacts],
      ...this.authorizationInformation.toWireData(),
      newJobStartDate,
      mtAuthorizationId,
      estimatedStartDate,
      estimatedEndDate,
      departureCity : this.primaryInformation.departureCity,
      departureStateCode : this.primaryInformation.departureStateCode,
      departureCountryCode : this.primaryInformation.departureCountryCode,
      destinationCity,
      destinationStateCode,
      destinationCountryCode,
      relocationDistance: departurePropertyRest.city && destinationCity ? syncedRelocationDistance : null,
      relocationPolicyCodeId,
      maritalStatusId,
      taxFilingStatusId,
      pets,
      phoneNumbers,
      emailAddresses,
      contactPreferenceTypeId,
      isSellingDepartureProperty,
      isEligibleForHomesale,
      departureProperty: departurePropertyRest,
      isHhgServiceInitiated,
      intakeHhgServiceDetail: hhgInformationRest,
      isTemporaryLivingServiceInitiated,
      departureAddressComment,
      destinationInformationComment,
      temporaryLivingInfo: {
        ...temporaryLivingRest,
        ...temporaryLivingBenefits,
      },
      isDestinationServiceInitiated: isDestinationServiceInitiated || (isHhgServiceInitiated || isTemporaryLivingServiceInitiated) ? true : false,
      destinationServiceInfo: newDestinationRest,
      notes: this.notes.map((instance) => instance.toWireData()),
      relocationAllowance,
      homesaleProcess,
      hhgBenefitsTimeline,
      houseHunting,
      finalMove,
      miscTimelineEntries,
      spousalAssistance,
      authorizationComments: this.authorizationComments.map((instance) => instance.toWireData()),
      candidateInternInfo,
      ...mortgageLeadData,
      ...vendorLettersData,
    });
  }
}
