import { Checkbox } from '@mui/material';
import { POST_HOMESALE_INFO_FAILURE, UPDATE_HOMESALE_INFO_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postHomesaleInfo, updateHomesaleInfo } from 'modules/authorizations/store/actions/homesale.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const PropertyInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { homesaleInfo, id, isReadOnly } = props;

  const typeOptions = [
    { title: 'Single Family', id: 1 },
    { title: 'Condo', id: 2 },
    { title: 'Mobile Home', id: 3 },
    { title: 'Multi-Family', id: 4 },
    { title: 'Rental Property', id: 5 },
    { title: 'Co-Op', id: 6 },
    { title: 'Townhouse', id: 7 },
    { title: 'Vacation Property', id: 8 },
  ];

  const tabs = [
    { header: 'Property Info', order: 1, iconPath: ['fas', 'house-user'] },
  ];

  const summaries = [
    { header: 'Property Type', accessorKey: 'propertyType', type: 'dropdown-v2', required: false, value: homesaleInfo ? homesaleInfo.propertyType : null, options: typeOptions,  tab: 1 },
    { header: 'Original Purchase Amount', accessorKey: 'originalPurchaseAmount', type: 'money', required: false, value: homesaleInfo ? homesaleInfo.originalPurchaseAmount : false, tab: 1 },
    { header: 'Original Purchase Date', accessorKey: 'originalPurchaseDate', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.originalPurchaseDate : '', tab: 1 },
    { header: 'Satisfied Offer Amount', accessorKey: 'satisfiedOfferAmount', type: 'money', required: false, value: homesaleInfo ? homesaleInfo.satisfiedOfferAmount : '', tab: 1 },
    { header: 'Est. Sale Amount', accessorKey: 'estimatedSaleAmount', type: 'money', required: false,  value: homesaleInfo ? homesaleInfo.estimatedSaleAmount : '', tab: 1 },
    { header: 'HO Disclosure Sent', accessorKey: 'hoDisclosureSent', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.hoDisclosureSent : '', tab: 1 },
    { header: 'HO Disclosure Received', accessorKey: 'hoDisclosureReceived', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.hoDisclosureReceived : false, tab: 1 },
    { header: 'Client Contact', accessorKey: 'clientContact', type: 'text', required: false, value: homesaleInfo ? homesaleInfo.clientContact : '', tab: 1 },
    { header: 'Contact Date', accessorKey: 'contactDate', type: 'date', required: false, value: homesaleInfo ? homesaleInfo.contactDate : '', tab: 1 },
    { dividerLine: true, tab: 1 },
    { header: 'Built Before 78', accessorKey: 'builtBefore', type: 'checkbox', required: false, value: homesaleInfo ? homesaleInfo.builtBefore : '', tab: 1, Cell: ({ cell }) => <Checkbox name="builtBefore" label="Active" checked={cell.row.original.builtBefore} disabled={true} /> },
    { header: 'Septic', accessorKey: 'septic', type: 'checkbox', required: false, value: homesaleInfo ? homesaleInfo.septic : '', tab: 1, Cell: ({ cell }) => <Checkbox name="septic" label="Active" checked={cell.row.original.septic} disabled={true} /> },
    { header: 'Well', accessorKey: 'well', type: 'checkbox', required: false, value: homesaleInfo ? homesaleInfo.well : '', tab: 1, Cell: ({ cell }) => <Checkbox name="well" label="Active" checked={cell.row.original.well} disabled={true} /> },
    { header: 'Pool', accessorKey: 'pool', type: 'checkbox', required: false, value: homesaleInfo ? homesaleInfo.pool : '', tab: 1, Cell: ({ cell }) => <Checkbox name="pool" label="Active" checked={cell.row.original.pool} disabled={true} /> },
    { dividerLine: true, tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', required: false, value: homesaleInfo ? homesaleInfo.notes : '', showBelow: true, tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (homesaleInfo && homesaleInfo.length !== 0) {
      let newValues = { ...homesaleInfo };

      newValues.propertyType = values.propertyType;
      newValues.originalPurchaseAmount = values.originalPurchaseAmount;
      newValues.originalPurchaseDate = values.originalPurchaseDate;
      newValues.satisfiedOfferAmount = values.satisfiedOfferAmount;
      newValues.estimatedSaleAmount = values.estimatedSaleAmount;
      newValues.hoDisclosureSent = values.hoDisclosureSent;
      newValues.hoDisclosureReceived = values.hoDisclosureReceived;
      newValues.clientContact = values.clientContact;
      newValues.contactDate = values.contactDate;
      newValues.builtBefore = values.builtBefore;
      newValues.septic = values.septic;
      newValues.well = values.well;
      newValues.pool = values.pool;
      newValues.notes = values.notes;

      const resp = await props.updateHomesaleInfo(newValues);
      if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to update homesale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      let newValues = {
        authorizationId: id,
      };

      if (values.propertyType && typeof values.propertyType !== 'string') newValues.propertyType = values.propertyType;
      if (values.originalPurchaseAmount && values.originalPurchaseAmount.length !== 0) newValues.originalPurchaseAmount = values.originalPurchaseAmount;
      if (values.originalPurchaseDate && values.originalPurchaseDate.length !== 0) newValues.originalPurchaseDate = values.originalPurchaseDate;
      if (values.satisfiedOfferAmount && values.satisfiedOfferAmount.length !== 0) newValues.satisfiedOfferAmount = values.satisfiedOfferAmount;
      if (values.estimatedSaleAmount && values.estimatedSaleAmount.length !== 0) newValues.estimatedSaleAmount = values.estimatedSaleAmount;
      if (values.hoDisclosureSent && values.hoDisclosureSent.length !== 0) newValues.hoDisclosureSent = values.hoDisclosureSent;
      if (values.hoDisclosureReceived && values.hoDisclosureReceived.length !== 0) newValues.hoDisclosureReceived = values.hoDisclosureReceived;
      if (values.clientContact && values.clientContact.length !== 0) newValues.clientContact = values.clientContact;
      if (values.contactDate && values.contactDate.length !== 0) newValues.contactDate = values.contactDate;
      if (values.builtBefore && values.builtBefore.length !== 0) newValues.builtBefore = values.builtBefore;
      if (values.septic && values.septic.length !== 0) newValues.septic = values.septic;
      if (values.well && values.well.length !== 0) newValues.well = values.well;
      if (values.pool && values.pool.length !== 0) newValues.pool = values.pool;
      if (values.notes && values.notes.length !== 0) newValues.notes = values.notes;

      const resp = await props.postHomesaleInfo(values);
      if (resp.type === POST_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to update homesale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateHomesaleInfo,
    postHomesaleInfo,
    showToast,
  }),
)(PropertyInfoCard);