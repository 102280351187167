import { POST_GLOBAL_INFO_FAILURE, UPDATE_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';


const DatesGlobalCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { globalInfo, id, isReadOnly } = props;

  const tabs = [{ header: 'Dates', order: 1, iconPath: ['fas', 'calendar-days'] }];

  const summaries = [
    {
      header: 'Estimated Start',
      accessorKey: 'estimatedStart',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.estimatedStart : '',
      tab: 1
    },
    {
      header: 'Estimated End',
      accessorKey: 'estimatedEnd',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.estimatedEnd : '',
      tab: 1
    },
    {
      header: 'Estimated Work Days',
      accessorKey: 'estimatedWorkDays',
      type: 'number',
      required: false,
      value: globalInfo ? globalInfo.estimatedWorkDays : '',
      tab: 1,
      hideInModal: true
    },
    {
      header: 'Actual Start',
      accessorKey: 'actualStart',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.actualStart : '',
      tab: 1
    },
    {
      header: 'Actual End',
      accessorKey: 'actualEnd',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.actualEnd : '',
      tab: 1
    },
    {
      header: 'Actual Work Days',
      accessorKey: 'actualWorkDays',
      type: 'number',
      required: false,
      value: globalInfo ? globalInfo.actualWorkDays : '',
      tab: 1
    },
    { header: 'Hire Date', accessorKey: 'hireDate', type: 'date', required: false, value: globalInfo ? globalInfo.hireDate : '', tab: 1 },
    {
      header: 'Completed Date',
      accessorKey: 'completedDate',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.completedDate : '',
      tab: 1
    },
    {
      header: 'Cancelled Date',
      accessorKey: 'cancelledDate',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.cancelledDate : '',
      tab: 1
    },
    {
      header: 'Cert of Coverage Exp',
      accessorKey: 'certificateOfCovExpires',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.certificateOfCovExpires : '',
      tab: 1
    }
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (globalInfo && globalInfo.length !== 0) {
      let newValues = { ...globalInfo };

      let estimatedWorkDays = null;

      if (values.estimatedStart && values.estimatedEnd) {
        const startDate = new Date(values.estimatedStart);
        const endDate = new Date(values.estimatedEnd);
      
        estimatedWorkDays = differenceInCalendarDays(endDate, startDate) + 2; //+ 2 to account for start and end day

        estimatedWorkDays = estimatedWorkDays < 0 ? 0 : estimatedWorkDays;
      }

      newValues.estimatedStart = values.estimatedStart;
      newValues.estimatedEnd = values.estimatedEnd;
      newValues.estimatedWorkDays = estimatedWorkDays;
      newValues.actualStart = values.actualStart;
      newValues.actualEnd = values.actualEnd;
      newValues.actualWorkDays = values.actualWorkDays;
      newValues.hireDate = values.hireDate;
      newValues.completedDate = values.completedDate;
      newValues.cancelledDate = values.cancelledDate;
      newValues.certificateOfCovExpires = values.certificateOfCovExpires;

      const resp = await props.updateGlobalInfo(newValues);
      if (resp.type === UPDATE_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postGlobalInfo(values);
      if (resp.type === POST_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3}
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );
};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast
  })
)(DatesGlobalCard);
