/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { memoize } from 'lodash';
import CrudTable from '../clients/details/crudTable.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import classNames from 'classnames';
import { deleteVoucherSummary, getVoucherSummaries, postVoucherSummary, updateVoucherSummary } from 'modules/expenses/expenses.actions';
import { DELETE_VOUCHER_SUMMARY_FAILURE, GET_VOUCHER_SUMMARIES_FAILURE, POST_VOUCHER_SUMMARY_FAILURE, UPDATE_VOUCHER_SUMMARY_FAILURE } from 'modules/expenses/types';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const VoucherSummariesTable = (props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const renderAuthOptions = memoize(() => {
    let allAuths = props.authorizationSummaries ? props.authorizationSummaries.map((auth) => auth.authorizationId) : [];
    return allAuths;
  });

  const vendorSearchInfo = (vendors) => {
    return {
      searchingColumns: [
        { name: 'vendorId', title: 'Vendor Id' },
        { name: 'companyName', title: 'Company Name' },
        { name: 'city', title: 'City' },
        { name: 'state', title: 'State' },
        { name: 'phone', title: 'Company Phone' },
        { name: 'email', title: 'Company Email' },
      ],
      searchingColumnExtensions: [
        { columnName: 'vendorId', width: 150 },
        { columnName: 'companyName', width: 250 },
        { name: 'city', width: 175 },
        { name: 'state', width: 175 },
        { columnName: 'phone', width: 175 },
        { columnName: 'email', width: 175 },
      ],
      searchingRows: vendors,
      idKey: 'vendorId',
      nameKey: 'companyName',
    };
  };

  const clientSearchInfo = (clients) => {
    return {
      searchingColumns: [
        { name: 'clientId', title: 'Client Id' },
        { name: 'clientName', title: 'Company Name' },
        { name: 'moveTrackClientId', title: 'MT Client Id' },
        { name: 'clientCode', title: 'Client Code' },
        { name: 'clientStatus', title: 'Status' },
        { name: 'serviceRep', title: 'Service Rep' },
      ],
      searchingColumnExtensions: [
        { columnName: 'clientId', width: 150 },
        { columnName: 'clientName', width: 250 },
        { name: 'moveTrackClientId', width: 175 },
        { name: 'clientCode', width: 175 },
        { columnName: 'clientStatus', width: 175 },
        { columnName: 'serviceRep', width: 175 },
      ],
      searchingRows: clients,
      idKey: 'clientId',
      nameKey: 'clientName',
    };
  };

  const statusOptions = [
    { title: 'Draft', id: 1 },
    { title: 'Pending', id: 2 },
    { title: 'On Hold', id: 3 },
    { title: 'Approved', id: 4 },
    { title: 'Denied', id: 5 },
  ];

  const payInOptions = [
    { title: 'USD', id: 1 },
    { title: 'CAD', id: 2 },
    { title: 'EUR', id: 3 },
    { title: 'GBP', id: 4 },
    { title: 'JPY', id: 5 },
    { title: 'CHF', id: 6 },
  ];

  const batchOptions = [
    { title: 'ACH/WIRE', id: 1 },
    { title: 'AP TRACKING', id: 2 },
    { title: 'BILL TO EE - CHECK', id: 3 },
    { title: 'BILL TO EE - INTL', id: 4 },
    { title: 'BILL TO EE - ACH/WIRE', id: 5 },
    { title: 'BILL TO EE - TRACKING', id: 7 },
    { title: 'BLACKOUT', id: 8 },
    { title: 'CHECK', id: 9 },
    { title: 'CLIENT INTEREST', id: 10 },
    { title: 'CODING CORRECTION', id: 11 },
    { title: 'DEPOSIT', id: 12 },
    { title: 'EM TRACKING', id: 13 },
    { title: 'FEE', id: 14 },
    { title: 'INTERNATIONAL', id: 15 },
    { title: 'JOURNAL ENTRY', id: 16 },
    { title: 'ON HOLD', id: 17 },
  ];

  const bankOptions = [
    { title: 'BMO Harris Bank - USD - ****9999 (Dft/ACH)', id: 1 },
    { title: 'Wells Fargo Bank - USD - ****9999 (Dft/ACH)', id: 2 },
    { title: 'PNC Bank - USD - ****9999 (Dft/ACH)', id: 3 },
    { title: 'US Bank - USD - ****9999 (Dft/ACH)', id: 4 },
    { title: 'Bank of America - USD - ****9999 (Dft/ACH)', id: 5 },
    { title: 'Chase Bank - USD - ****9999 (Dft/ACH)', id: 6 },
  ];

  const notPayableOptions = [
    { title: 'Backout/Re-enter', id: 1 },
    { title: 'Cost Avoidance', id: 2 },
    { title: 'Paid by Client Through Payroll', id: 3 },
    { title: 'Tracking', id: 4 },
    { title: 'N/A', id: 5 },
  ];

  const voucherTypeOptions = [
    { title: 'AP Voucher', id: 1 },
    { title: 'AR Voucher', id: 2 },
    { title: 'AFCR Voucher', id: 3 },
    { title: 'Refund Voucher', id: 4 },
  ];

  let unmappedClientsAdjusted = [];
  
  if (props.unmappedClients) {
    unmappedClientsAdjusted = props.unmappedClients.map((c) => {
        let newCopy = {...c};
        newCopy.clientName = c.description;
        newCopy.clientId = c.id;
        return newCopy;
    })
  }

  const adjustedColumns = [
    { accessorKey: 'payToVendor', header: 'Is Pay to Vendor', type: 'checkbox', required: false, size: 50, Cell: ({ cell }) => <Checkbox name="payToVendor" label="Active" checked={cell.row.original.payToVendor} disabled={true} /> },
    { accessorKey: 'status', header: 'Status', type: 'dropdown-v2', options: statusOptions, required: true, size: 60, Cell: ({ cell }) => cell.row.original.status != null && <p>{statusOptions.find((o) => o.id === cell.row.original.status)?.title}</p> },
    { accessorKey: 'clientId', header: 'Client Id', nameKey: 'clientName', required: true, searchInfo: clientSearchInfo(unmappedClientsAdjusted), type: 'advanced-search', size: 65, Cell: ({ cell }) => (cell.getValue() ? unmappedClientsAdjusted?.find((v) => v.id === cell.getValue())?.clientName : '')},
    { accessorKey: 'vendorId', header: 'Vendor', nameKey: 'companyName', searchInfo: vendorSearchInfo(props.vendors), type: 'advanced-search', required: true, size: 65, Cell: ({ cell }) => (cell.getValue() ? props.vendors?.find((v) => v.vendorId === cell.getValue())?.companyName : '')},
    { accessorKey: 'authorizationId', header: 'Authorization Id', type: 'dropdown', options: renderAuthOptions(), required: true, size: 50 },

    { accessorKey: 'invoiceNumber', header: 'Invoice Number', type: 'text', required: true, size: 50, hideInTable: true },
    { accessorKey: 'amount', header: 'Amount', type: 'money', required: true, size: 70 },
    { accessorKey: 'expenseAnalyst', header: 'Expense Analyst', type: 'text', required: true, size: 50, hideInTable: true },

    { accessorKey: 'invoiceDate', header: 'Invoice Date', type: 'date', size: 50, hideInTable: true },
    { accessorKey: 'receiptCurrency', header: 'Receipt Currency', type: 'dropdown-v2', options: payInOptions, size: 50, hideInTable: true },
    { accessorKey: 'payInCurrency', header: 'Pay In Currency', type: 'dropdown-v2', options: payInOptions, size: 50, hideInTable: true },
    { accessorKey: 'batchType', header: 'Batch Type', type: 'dropdown-v2', options: batchOptions, size: 50, hideInTable: true },
    { accessorKey: 'voucherType', header: 'Voucher Type', type: 'dropdown-v2', options: voucherTypeOptions, size: 50, hideInTable: true },
    { accessorKey: 'onHoldReason', header: 'On Hold Reason', type: 'text', size: 50, hideInTable: true },
    { accessorKey: 'denyReason', header: 'Deny Reason', type: 'text', size: 50, hideInTable: true },

    { accessorKey: 'payToBankAccount', header: 'Bank Account', type: 'dropdown-v2', options: bankOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'notPayable', header: 'Not Payable', type: 'dropdown-v2', options: notPayableOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'rushPayment', header: 'Rush Payment', type: 'checkbox', required: false, size: 50, hideInTable: true },
    { accessorKey: 'entryDate', header: 'Entry Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'dueDate', header: 'Due Date', type: 'date', required: false, size: 50, hideInTable: true },

    { accessorKey: 'description', header: 'Description', type: 'notes', required: true, size: 150 },
  ];

  const updateRow = async (orgRow, newRow) => {
    let checkValues = {...newRow};

    checkValues.voucherId = orgRow.original.voucherId;

    if (newRow.authorizationId) {
        checkValues.authorizationId = newRow.authorizationId;
        checkValues.authName = props.authorizationSummaries.find((a) => a.authorizationId === newRow.authorizationId)?.transfereeName;
    }

    if (newRow.vendorId) {
        checkValues.vendorId = newRow.vendorId;
        checkValues.vendorName = props.vendors?.find((v) => v.vendorId === newRow.vendorId)?.companyName;
    }

    if (newRow.clientId) {
        checkValues.clientId = newRow.clientId;
        checkValues.clientName = props.unmappedClients?.find((v) => v.id === newRow.clientId)?.description;
    }

    if (checkValues.invoiceDate != null && checkValues.invoiceDate.length === 0) checkValues.invoiceDate = null;
    if (checkValues.entryDate != null && checkValues.entryDate.length === 0) checkValues.entryDate = null;
    if (checkValues.dueDate != null && checkValues.dueDate.length === 0) checkValues.dueDate = null;


    checkValues.amount = parseFloat(checkValues.amount);

    const resp = await props.updateVoucherSummary(checkValues);
    if (resp.type === UPDATE_VOUCHER_SUMMARY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getVoucherSummaries();
      if (getResp.type === GET_VOUCHER_SUMMARIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    let checkValues = {...values};

    if (values.authorizationId) {
        checkValues.authName = props.authorizationSummaries.find((a) => a.authorizationId === values.authorizationId).transfereeName;
    }

    if (values.vendorId) {
        checkValues.vendorId = values.vendorId;
        checkValues.vendorName = props.vendors?.find((v) => v.vendorId === values.vendorId)?.companyName;
    }

    if (values.clientId) {
        checkValues.clientId = values.clientId;
        checkValues.clientName = props.unmappedClients?.find((v) => v.id === values.clientId)?.description;
    }

    checkValues.amount = parseFloat(checkValues.amount);

    if (typeof checkValues.payToVendor === 'string') checkValues.payToVendor = null;
    if (typeof checkValues.status === 'string') checkValues.status = null;
    if (typeof checkValues.receiptCurrency === 'string') checkValues.receiptCurrency = null;
    if (typeof checkValues.payInCurrency === 'string') checkValues.payInCurrency = null;
    if (typeof checkValues.batchType === 'string') checkValues.batchType = null;
    if (typeof checkValues.voucherType === 'string') checkValues.voucherType = null;
    if (typeof checkValues.rushPayment === 'string') checkValues.rushPayment = null;
    if (typeof checkValues.payToBankAccount === 'string') checkValues.payToBankAccount = null;
    if (typeof checkValues.notPayable === 'string') checkValues.notPayable = null;

    if (checkValues.invoiceDate != null && checkValues.invoiceDate.length === 0) checkValues.invoiceDate = null;
    if (checkValues.entryDate != null && checkValues.entryDate.length === 0) checkValues.entryDate = null;
    if (checkValues.dueDate != null && checkValues.dueDate.length === 0) checkValues.dueDate = null;

    const resp = await props.postVoucherSummary(checkValues);
    if (resp.type === POST_VOUCHER_SUMMARY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getVoucherSummaries();
      if (getResp.type === GET_VOUCHER_SUMMARIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (rowToDelete) => {
    const id = rowToDelete.original.voucherId;
    const resp = await props.deleteVoucherSummary(id);
    if (resp.type === DELETE_VOUCHER_SUMMARY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getVoucherSummaries();
      if (getResp.type === GET_VOUCHER_SUMMARIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
      classes={{ root: classNames(props.classes.accordionStyleContacts) }}
    >
      <AccordionSummary
        expandIcon={
          <Box color={theme.palette.secondary.main}>
            <ExpandMoreIcon color="inherit" />
          </Box>
        }
      >
        <div className={props.classes.newRow}>
          <h2>Voucher Summaries</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.isLoadingVoucherSummaries && props.voucherSummaries && (
          <div>
            <CrudTable
              editingMode={'modal'} // Can be changed to 'row' to edit inline
              columns={adjustedColumns}
              rows={props.voucherSummaries}
              updateRow={updateRow}
              createRow={createRow}
              deleteRow={deleteRow}
            />
          </div>
        )}
        {props.isLoadingVoucherSummaries && <Spinner />}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => {
  const { voucherSummaries, isLoadingVoucherSummaries } = state.expenses;
  const { authorizationSummaries, vendors } = state.authorizations; 
  const { unmappedClients } = state.clients;
  return {
    voucherSummaries,
    isLoadingVoucherSummaries,
    authorizationSummaries,
    unmappedClients,
    vendors,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    updateVoucherSummary,
    deleteVoucherSummary,
    postVoucherSummary,
    getVoucherSummaries,
  }),
)(VoucherSummariesTable);
