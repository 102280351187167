
export const GET_AUTH_PETS_FAILURE = 'GET_AUTH_PETS_FAILURE';
export const GET_AUTH_PETS_REQUEST = 'GET_AUTH_PETS_REQUEST';
export const GET_AUTH_PETS_SUCCESS = 'GET_AUTH_PETS_SUCCESS';

export const POST_AUTH_PETS_FAILURE = 'POST_AUTH_PETS_FAILURE';
export const POST_AUTH_PETS_REQUEST = 'POST_AUTH_PETS_REQUEST';
export const POST_AUTH_PETS_SUCCESS = 'POST_AUTH_PETS_SUCCESS';

export const GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE = 'GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE';
export const GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST = 'GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST';
export const GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS = 'GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS';

export const POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE = 'POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE';
export const POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST = 'POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST';
export const POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS = 'POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS';

export const UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE = 'UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE';
export const UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST = 'UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST';
export const UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS = 'UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS';

export const DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE = 'DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE';
export const DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST = 'DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST';
export const DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS = 'DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS';
