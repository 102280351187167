import { InspectionRequiredByOptions, InspectionTypesOptions, InspectionsStatusOptions } from './inspectionsFormFieldConstants';

export const createInspectionsFormFields = (inspection) => {
  return [
    {
      header: 'Ordered Date',
      accessorKey: 'orderedDate',
      type: 'date',
      required: false,
      value: inspection?.orderedDate,
      tab: 1,
    },
    {
      header: 'Appointment Date',
      accessorKey: 'appointmentDate',
      type: 'date',
      required: false,
      value: inspection?.appointmentDate,
      tab: 1,
    },
    {
      header: 'Received Date',
      accessorKey: 'receivedDate',
      type: 'date',
      required: false,
      value: inspection?.receivedDate,
      tab: 1,
    },
    {
      header: 'Cancelled Date',
      accessorKey: 'cancelledDate',
      type: 'date',
      required: false,
      value: inspection?.cancelledDate,
      tab: 1,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      type: 'dropdown',
      required: true,
      options: InspectionsStatusOptions,
      value: inspection?.status,
      tab: 1,
    },
    {
      header: 'Required By',
      accessorKey: 'requiredBy',
      type: 'dropdown',
      required: true,
      options: InspectionRequiredByOptions,
      value: inspection?.requiredBy,
      tab: 1,
    },
    {
      header: 'Repair Amount',
      accessorKey: 'repairAmount',
      type: 'money',
      required: false,
      value: inspection?.repairAmount,
      tab: 1,
    },
    {
      header: 'Repaired On',
      accessorKey: 'repairedDate',
      type: 'date',
      required: false,
      value: inspection?.repairedDate,
      tab: 1,
    },
    {
      header: 'Amount to Deduct',
      accessorKey: 'dedFromEquityAmt',
      type: 'money',
      required: false,
      value: inspection?.dedFromEquityAmt,
      tab: 1,
    },
    {
      header: 'Paid Date',
      accessorKey: 'paidDate',
      type: 'date',
      required: false,
      value: inspection?.paidDate,
      tab: 1,
    },
    {
      header: 'Repairs Completed',
      accessorKey: 'repairsCompleted',
      type: 'checkbox',
      required: false,
      value: inspection?.repairsCompleted === true,
      tab: 1,
    },
    {
      header: 'Receipts Received',
      accessorKey: 'receiptsReceived',
      type: 'checkbox',
      required: false,
      value: inspection?.receiptsReceived === true,
      tab: 1,
    },
    {
      header: 'Morreale Notified',
      accessorKey: 'morrealeNotified',
      type: 'checkbox',
      required: false,
      value: inspection?.morrealeNotified === true,
      tab: 1,
    },
    {
      header: 'Morreale Notified Date',
      accessorKey: 'morrealeNotifiedDate',
      type: 'date',
      required: false,
      value: inspection?.morrealeNotifiedDate,
      tab: 1,
    },
    {
      header: 'Comments',
      accessorKey: 'comments',
      type: 'notes',
      required: false,
      value: inspection?.comments,
      tab: 1,
    },
  ];
};

export const vendorSearchInfo = (vendors) => {
  return {
    searchingColumns: [
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'companyName', title: 'Company Name' },
      { name: 'city', title: 'City' },
      { name: 'state', title: 'State' },
      { name: 'phone', title: 'Company Phone' },
      { name: 'email', title: 'Company Email' },
    ],
    searchingColumnExtensions: [
      { columnName: 'vendorId', width: 150 },
      { columnName: 'companyName', width: 250 },
      { name: 'city', width: 175 },
      { name: 'state', width: 175 },
      { columnName: 'phone', width: 175 },
      { columnName: 'email', width: 175 },
    ],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName',
  };
};

export const createNewInspectionsFormFields = (vendors) => {
  return [
    {
      header: 'Inspection Type',
      accessorKey: 'inspectionType',
      type: 'dropdown',
      options: InspectionTypesOptions,
      required: false,
      tab: 1,
    },
    {
      header: 'Vendor',
      accessorKey: 'vendorId',
      nameKey: 'companyName',
      type: 'advanced-search',
      searchInfo: vendorSearchInfo(vendors),
      required: true,
      tab: 1,
    },
  ];
};

export const createInspectionsFormTab = () => {
  return [{ header: 'Inspections', order: 1, iconPath: ['fas', 'file-contract'] }];
};

export const createPostRequest = (values, authId) => {
  const request = { ...values };
  request.authorizationId = authId;
  return request;
};

export const createVendorFormTab = () => {
  return [{ header: 'Vendor Info', order: 1, iconPath: ['fas', 'person-walking-luggage'] }];
};

export const createInspectionPutRequest = (selectedInspection, values, authId) => {
  const request = { ...selectedInspection };
  request.authorizationId = authId;
  request.orderedDate = values.orderedDate;
  request.appointmentDate = values.appointmentDate;
  request.receivedDate = values.receivedDate;
  request.cancelledDate = values.cancelledDate;
  request.status = values.status;
  request.requiredBy = values.requiredBy;
  request.repairAmount = values.repairAmount;
  request.repairedDate = values.repairedDate;
  request.dedFromEquityAmt = values.dedFromEquityAmt;
  request.paidDate = values.paidDate;
  request.repairsCompleted = values.repairsCompleted;
  request.receiptsReceived = values.receiptsReceived;
  request.morrealeNotified = values.morrealeNotified;
  request.morrealeNotifiedDate = values.morrealeNotifiedDate;
  request.comments = values.comments;
  return request;
};

export const createOrderInspectionSentRequest = (selectedInspection, authId) => {
  const request = { ...selectedInspection };
  request.authorizationId = authId;
  request.inspectionSent = true;
  return request;
};

export const createVendorInfoPutRequest = (existingValues, values, authId) => {
  const request = { ...existingValues };
  request.authorizationId = authId;
  request.brokerId = values?.brokerId;
  request.inspectorId = values?.inspectorId;
  return request;
};

const brokerSearchInfo = (brokerContacts) => {
  return {
    searchingColumns: [
      { name: 'brokerId', title: 'Broker Id' },
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'brokerFullName', title: 'Full Name' },
      { name: 'contactPhoneNumber', title: 'Contact Phone' },
      { name: 'contactEmail', title: 'Contact Email' },
    ],
    searchingColumnExtensions: [
      { columnName: 'brokerId', width: 150 },
      { columnName: 'vendorId', width: 150 },
      { columnName: 'brokerFullName', width: 200 },
      { columnName: 'contactPhoneNumber', width: 175 },
      { columnName: 'contactEmail', width: 175 },
    ],
    searchingRows: brokerContacts,
    idKey: 'brokerId',
    nameKey: 'brokerFullName',
  };
};
const inspectorSearchInfo = (inspectors) => {
  return {
    searchingColumns: [
      { name: 'inspectorId', title: 'Inspector Id' },
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'inspectorFullName', title: 'Full Name' },
      { name: 'inspectorPhone', title: 'Contact Phone' },
      { name: 'inspectorEmail', title: 'Contact Email' },
    ],
    searchingColumnExtensions: [
      { columnName: 'inspectorId', width: 150 },
      { columnName: 'vendorId', width: 150 },
      { columnName: 'inspectorFullName', width: 200 },
      { columnName: 'inspectorPhone', width: 175 },
      { columnName: 'inspectorEmail', width: 175 },
    ],
    searchingRows: inspectors,
    idKey: 'inspectorId',
    nameKey: 'inspectorFullName',
  };
};
export const createVendorInfoFormFields = (inspection, brokers, inspectors) => {
  const broker = brokers?.find((b) => b.brokerId === inspection?.brokerId);
  const inspector = inspectors?.find((i) => i.inspectorId === inspection?.inspectorId);

  return [
    {
      header: 'Broker',
      accessorKey: 'brokerId',
      nameKey: 'brokerFullName',
      nameValue: broker?.brokerFullName,
      type: 'advanced-search',
      searchInfo: brokerSearchInfo(brokers),
      required: false,
      value: broker?.brokerId,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Inspector',
      accessorKey: 'inspectorId',
      nameKey: 'inspectorFullName',
      nameValue: inspector?.inspectorFullName,
      type: 'advanced-search',
      searchInfo: inspectorSearchInfo(inspectors),
      required: false,
      value: inspector?.inspectorId,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Inspector Code',
      accessorKey: 'inspectorCode',
      type: 'text',
      required: false,
      value: inspector?.inspectorCode,
      tab: 1,
      hideInModal: true,
    },
    {
      header: 'Inspection Company',
      accessorKey: 'companyName',
      type: 'text',
      required: false,
      value: inspector?.companyName,
      tab: 1,
      hideInModal: true,
    },
    {
      header: 'Inspection Contact',
      accessorKey: 'inspectorFullName',
      type: 'text',
      required: false,
      value: inspector?.inspectorFullName,
      tab: 1,
      hideInModal: true,
    },
    {
      header: 'Broker Code',
      accessorKey: 'brokerCode',
      type: 'text',
      required: false,
      value: broker?.brokerCode,
      tab: 1,
      hideInModal: true,
    },
    {
      header: 'Broker Company',
      accessorKey: 'companyName',
      type: 'text',
      required: false,
      value: broker?.companyName,
      tab: 1,
      hideInModal: true,
    },
    {
      header: 'Brokage Contact',
      accessorKey: 'brokerFullName',
      type: 'text',
      required: false,
      value: broker?.brokerFullName,
      tab: 1,
      hideInModal: true,
    },
  ];
};
