import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
   
import CrudTable from '../../crudTable.component';
import Spinner from 'modules/common/spinner.component';
    
import { DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE, GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE, UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE } from '../../../types';

import { deleteClientPoliciesImportantNotes, getClientPoliciesImportantNotes, upsertClientPoliciesImportantNotes } from '../../../clients.actions';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientPoliciesImportantNotesTable = (props) => { 
  const adjustedColumns = [
    { accessorKey: 'description', header: 'Description', type: 'notes', required: true, size: 70, enableColumnDragging: false, enableColumnActions: false, enableResizing: false }];

  const updateRow = async (clientPoliciesImportantNote, values) => {
    const id = clientPoliciesImportantNote.original.clientPoliciesImportantNoteId;
    values.clientPoliciesImportantNoteId = id;
    values.clientId = props.clientId;
    const resp = await props.upsertClientPoliciesImportantNotes(values);
    if (resp.type === UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientPoliciesImportantNotes(props.clientId);
      if (getResp.type === GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientPoliciesImportantNoteId = 0;
    values.clientId = props.clientId;
    const resp = await props.upsertClientPoliciesImportantNotes(values);
    if (resp.type === UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientPoliciesImportantNotes(props.clientId);
      if (getResp.type === GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (clientPoliciesImportantNote) => {
    const clientPoliciesImportantNoteId = clientPoliciesImportantNote.original.clientPoliciesImportantNoteId;
    const clientId = props.clientId;
    const resp = await props.deleteClientPoliciesImportantNotes( { clientId, clientPoliciesImportantNoteId });
    if (resp.type === DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientPoliciesImportantNotes(props.clientId);
      if (getResp.type === GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };


  return (
    <>
      {!props.clientPoliciesImportantNotesIsLoading && (
        <CrudTable 
          editingMode={'modal'} // Can be changed to 'row' to edit inline 
          columns={adjustedColumns} 
          rows={props.clientPoliciesImportantNotes}
          updateRow={updateRow} 
          createRow={createRow} 
          deleteRow={deleteRow} 
        />
      )}
      {props.clientPoliciesImportantNotesIsLoading && (
        <Spinner />
      )}
    </>
  );
}; 

ClientPoliciesImportantNotesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  upsertClientPoliciesImportantNotes: PropTypes.func,
  getClientPoliciesImportantNotes: PropTypes.func,
  deleteClientPoliciesImportantNotes: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { clientPoliciesImportantNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientPoliciesImportantNotesIsLoading } = state.clients;
  return {
    clientPoliciesImportantNotes,
    clientPoliciesImportantNotesIsLoading,
  };
};
  
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientPoliciesImportantNotes,
    upsertClientPoliciesImportantNotes,
    deleteClientPoliciesImportantNotes,
  }),
)(ClientPoliciesImportantNotesTable);