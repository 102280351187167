import { DELETE_TAX_FAILURE, GET_TAX_FAILURE, POST_TAX_FAILURE, UPDATE_LISTINGS_FAILURE, UPDATE_TAX_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteTax, getTaxes, postTax, updateEquity, updateTax } from 'modules/authorizations/store/actions/homesale.actions';
import { showToast } from 'modules/layout/layout.actions';
import MultiTabCard, { ContentType } from 'modules/common/multiTabCard/multiTabCard.component';
import React, { useState } from 'react';

const TaxCard = (props) => {
  const { equityInfo, taxes, isReadOnly } = props;

  const [isLoading, setIsLoading] = useState(false);

  const taxInfoSummaries = [
    { header: 'Corp Equity Advance Amount', accessorKey: 'corpEquityAdvanceAmount', type: 'money', required: false, value: equityInfo ? equityInfo.corpEquityAdvanceAmount : '', tab: 1 },
    { header: 'Corp Equity Advance Code', accessorKey: 'corpEquityAdvanceCode', type: 'text', required: false, value: equityInfo ? equityInfo.corpEquityAdvanceCode : '', tab: 1 },
    { header: 'Relo Equity Advance Amount', accessorKey: 'reloEquityAdvanceAmount', type: 'money', required: false, value: equityInfo ? equityInfo.reloEquityAdvanceAmount : '', tab: 1 },
    { header: 'Relo Equity Advance Code', accessorKey: 'reloEquityAdvanceCode', type: 'text', required: false, value: equityInfo ? equityInfo.reloEquityAdvanceCode : '', tab: 1 },
    { header: 'Holdback Amount', accessorKey: 'holdbackAmount', type: 'money', required: false, value: equityInfo ? equityInfo.holdbackAmount : '', tab: 1 },
    { header: 'Holdback Code', accessorKey: 'holdbackCode', type: 'text', required: false, value: equityInfo ? equityInfo.holdbackCode : '', tab: 1 },
    { header: 'Unpaid Taxes', accessorKey: 'unpaidTaxes', type: 'money', required: false, value: equityInfo ? equityInfo.unpaidTaxes : '', tab: 1 },
    { header: 'Unpaid HOAs', accessorKey: 'unpaidHoas', type: 'money', required: false, value: equityInfo ? equityInfo.unpaidHoas : '', tab: 1 },
    { header: 'Description', accessorKey: 'description', type: 'notes', required: false, value: equityInfo ? equityInfo.description : '', showBelow: true, tab: 1 },
  ];

  const otherTaxesColumns = [
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 60, displayInHeaderRow: true },
    { accessorKey: 'base', header: 'Base', type: 'money', required: true, size: 55, hideInTable: false, displayInHeaderRow: true, Cell: ({ cell }) => cell.row.original.base != null && <p>{`$${cell.row.original.base}`}</p> },
    { accessorKey: 'periodFrom', header: 'From', type: 'date', required: true, size: 60, hideInTable: true, displayInHeaderRow: true },
    { accessorKey: 'periodTo', header: 'To', type: 'date', required: true, size: 60, hideInTable: true, displayInHeaderRow: true },
    { accessorKey: 'paid', header: 'Paid', type: 'money', required: true, size: 55, displayInHeaderRow: true, Cell: ({ cell }) => cell.row.original.paid != null && <p>{`$${cell.row.original.paid}`}</p> },
  ];

  const taxTabs = [
    {
      order: 1,
      contentType: ContentType.EditableCard,
      header: 'Tax Info',
      iconPath: ['fas', 'landmark'],
      isDisabled: false,
      headerMessage: null,
      hideEdit: false,
    },
    {
      order: 2,
      contentType: ContentType.CrudTable,
      header: 'Other Taxes',
      iconPath: ['fas', 'dollar-sign'],
      isDisabled: false,
      headerMessage: null,
      hideEdit: false,
    },
  ];

  const updateOtherTax = async (vendor, values) => {
    values.taxId = vendor.original.taxId;
    values.equityId = vendor.original.equityId;

    setIsLoading(true);
    const resp = await props.updateTax(values);
    if (resp.type === UPDATE_TAX_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getTaxes(equityInfo.equityId);
      setIsLoading(false);
      if (getResp.type === GET_TAX_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createOtherTax = async (values) => {
    setIsLoading(true);
    values.equityId = equityInfo.equityId;

    const resp = await props.postTax(values);
    if (resp.type === POST_TAX_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getTaxes(equityInfo.equityId);
      setIsLoading(false);
      if (getResp.type === GET_TAX_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteOtherTax = async (vendor) => {
    const id = vendor.original.taxId;
    const resp = await props.deleteTax(id);
    if (resp.type === DELETE_TAX_FAILURE) {
      return false;
    } else {
      const getResp = await props.getTaxes(equityInfo.equityId);
      if (getResp.type === GET_TAX_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const updateTaxInfoFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...equityInfo };

    newValues.corpEquityAdvanceAmount = values.corpEquityAdvanceAmount;
    newValues.corpEquityAdvanceCode = values.corpEquityAdvanceCode;
    newValues.reloEquityAdvanceAmount = values.reloEquityAdvanceAmount;
    newValues.reloEquityAdvanceCode = values.reloEquityAdvanceCode;
    newValues.holdbackAmount = values.holdbackAmount;
    newValues.holdbackCode = values.holdbackCode;
    newValues.unpaidTaxes = values.unpaidTaxes;
    newValues.unpaidHoas = values.unpaidHoas;
    newValues.description = values.description;

    const resp = await props.updateEquity(newValues);
    if (resp.type === UPDATE_LISTINGS_FAILURE) {
      props.showToast('Failed to update equity info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };

  const taxesTableContents = [
    {
      contentType: ContentType.EditableCard,
      tabOrder: 1,
      columns: null,
      rows: null,
      updateRow: null,
      createRow: null,
      deleteRow: null,
      getNameOfDeleteRow: null,
      customAddText: null,
      headerRow: null,
      summaries: taxInfoSummaries,
      numColumns: 2,
      updateFields: updateTaxInfoFields,
      isLoading,
      hideEdit: isReadOnly,
      topLink: null,
    },
    {
      contentType: ContentType.CrudTable,
      tabOrder: 2,
      columns: otherTaxesColumns,
      rows: taxes,
      updateRow: updateOtherTax,
      createRow: createOtherTax,
      deleteRow: deleteOtherTax,
      // getNameOfDeleteRow: getNameOfDeleteRow,
      customAddText: 'Add Tax',
      summaries: null,
      numColumns: null,
      updateFields: null,
      isLoading,
      hideEdit: isReadOnly,
      topLink: null,
    },
  ];

  return (
    <MultiTabCard
      tabs={taxTabs}
      contents={taxesTableContents}
    />
  );
};

export default compose(
  connect(null, {
    showToast,
    updateEquity,
    updateTax,
    deleteTax,
    postTax,
    getTaxes,
  }),
)(TaxCard);
