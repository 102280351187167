/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Chip, FormControlLabel, MenuItem, Select, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 350,
    },
    firstCol: {
      width: 200,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleDirectReimbursement = (props) => {

  const clientHomesaleDirectReimbursement = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleDirectReimbursement : null;

  const [isEnabled, setIsEnabled] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.isEnabled : false);
  const [directReimbursementEligibility, setDirectReimbursementEligibility] = useState(clientHomesaleDirectReimbursement && clientHomesaleDirectReimbursement.directReimbursementEligibility ? clientHomesaleDirectReimbursement.directReimbursementEligibility.split(',') : []);
  const [whenDirectReimbursementAllowed, setWhenDirectReimbursementAllowed] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.whenDirectReimbursementAllowed : null);
  const [whenStandAloneWithHMAAllowed, setWhenStandAloneWithHMAAllowed] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.whenStandAloneWithHMAAllowed : null);
  const [whenStandAloneNoHMAAllowed, setWhenStandAloneWithNoHMAAllowed] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.whenStandAloneNoHMAAllowed : null); 
  const [whenExcludedFromProgram, setWhenExcludedFromProgram] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.whenExcludedFromProgram : null);
  const [whenOptOut, setWhenOptOut] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.whenOptOut : null);
  const [isHMARequired, setIsHMARequired] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.isHMARequired : null);
  const [whoReimbursesBenefit, setWhoReimbursesBenefit] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.whoReimbursesBenefit : null);
  const [isGrossedUp, setIsGrossedUp] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.isGrossedUp : null);
  const [listingRequirement, setListingRequirement] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.listingRequirement : null);
  const [otherNotes, setOtherNotes] = useState(clientHomesaleDirectReimbursement ? clientHomesaleDirectReimbursement.otherNotes : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      IsEnabled: isEnabled,
      DirectReimbursementEligibility: directReimbursementEligibility.join(','),
      WhenDirectReimbursementAllowed: whenDirectReimbursementAllowed,
      WhenStandAloneWithHMAAllowed: whenStandAloneWithHMAAllowed,
      WhenStandAloneNoHMAAllowed: whenStandAloneNoHMAAllowed,
      WhenExcludedFromProgram: whenExcludedFromProgram,
      WhenOptOut: whenOptOut,
      IsHMARequired: isHMARequired,
      WhoReimbursesBenefit: whoReimbursesBenefit,
      IsGrossedUp: isGrossedUp,
      ListingRequirement: listingRequirement,
      OtherNotes: otherNotes,
    };

    if (clientHomesaleDirectReimbursement && clientHomesaleDirectReimbursement.id) updateBody.Id = clientHomesaleDirectReimbursement.id;

    props.updateInfo(updateBody);
  }, [isEnabled, directReimbursementEligibility, whenDirectReimbursementAllowed, whenStandAloneWithHMAAllowed, whenStandAloneNoHMAAllowed, whenExcludedFromProgram, whenOptOut, isHMARequired, whoReimbursesBenefit, listingRequirement, otherNotes]);

  return (
    <div className={props.classes.contractsGrid}>
      <div className={props.classes.headerRow}>
        <h3>Direct Reimbursement</h3>
        <FormControlLabel
          control={
            <Checkbox
              name="directReimbursement"
              label="Activate Service"
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
          }
          label="Activate Service"
          classes={{ root: props.classes.checkbox }}
        />
      </div>
      {isEnabled && (
        <div className={props.classes.tabBorder}>
          <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
            <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
              <p className={props.classes.descText}>Who is eligible for Direct Reimbursement?</p>
              <Select
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Who is Eligible?'}
                onChange={(e) => setDirectReimbursementEligibility(e.target.value)}
                multiple
                value={directReimbursementEligibility}
                renderValue={(selected) => {
                  return (
                    <div className={props.classes.chips}>
                      {selected.map((value) => {
                        const policyInfo = props.clientPolicyNotes.find((o) => o.clientPolicyNotesKey === parseInt(value));
                        return (
                          <Chip
                            key={policyInfo.clientPolicyNotesKey}
                            label={policyInfo.name}
                            className={props.classes.chip}
                            onDelete={() => {
                              setDirectReimbursementEligibility(directReimbursementEligibility.filter((b) => b !== value));
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        );
                      })}
                    </div>
                  );}}
              >
                {props.clientPolicyNotes && props.clientPolicyNotes.map((c) => {
                  return <MenuItem key={c.clientPolicyNotesKey} value={c.clientPolicyNotesKey}>{c.name}</MenuItem>;
                })}
              </Select>


              <p className={props.classes.descText}>When is direct reimbursement allowed?</p>
              <TextField
                id="name"
                label="When allowed"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenDirectReimbursementAllowed}
                onChange={(e) => setWhenDirectReimbursementAllowed(e.target.value)}
              />
              <p className={props.classes.descText}>As a standalone benefit with HMA?</p>
              <TextField
                id="name"
                label="With HMA"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenStandAloneWithHMAAllowed}
                onChange={(e) => setWhenStandAloneWithHMAAllowed(e.target.value)}
              />
              <p className={props.classes.descText}>As a standalone benefit with no HMA required?</p>
              <TextField
                id="name"
                label="No HMA"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenStandAloneNoHMAAllowed}
                onChange={(e) => setWhenStandAloneWithNoHMAAllowed(e.target.value)}
              />
              <p className={props.classes.descText}>As a part of a GBO/BVO process when a home is EXCLUDED from the program?</p>
              <TextField
                id="name"
                label="Part of a GBO/BVO process"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenExcludedFromProgram}
                onChange={(e) => setWhenExcludedFromProgram(e.target.value)}
              />
              <p className={props.classes.descText}>As a part of a GBO/BVO process when an employee opts out? (Not recommended)</p>
              <TextField
                id="name"
                label="When employee opts out"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={whenOptOut}
                onChange={(e) => setWhenOptOut(e.target.value)}
              />
            </div>
            <div className={props.classes.contentCol}>
              <p className={props.classes.descText}>Is Home Marketing Assistance required? This includes going through the BMA process and listing requirement.</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Home Marketing Assistance required'}
                onChange={(e) => setIsHMARequired(e.target.value)}
                required
                select
                value={isHMARequired}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'na'}>N/A</MenuItem>
              </TextField>
              <p className={props.classes.descText}>Who reimburses this benefit? Client or TRC?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Who reimburses'}
                onChange={(e) => setWhoReimbursesBenefit(e.target.value)}
                required
                select
                value={whoReimbursesBenefit}
              >
                <MenuItem value={'Client'}>Client</MenuItem>
                <MenuItem value={'TRC'}>TRC</MenuItem>
              </TextField>
              <p className={props.classes.descText}>Is this benefit grossed up? For all scenarios?</p>
              <TextField
                id="name"
                label="Grossed up"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={isGrossedUp}
                onChange={(e) => setIsGrossedUp(e.target.value)}
              />
              <p className={props.classes.descText}>Is there a listing requirement?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Listing requirement'}
                onChange={(e) => setListingRequirement(e.target.value)}
                required
                select
                value={listingRequirement}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'na'}>N/A</MenuItem>
              </TextField>
              <p className={props.classes.descText}>Other Notes:</p>
              <TextField
                variant="outlined"
                label={'Other Notes'}
                value={otherNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}; 

HomeSaleDirectReimbursement.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleDirectReimbursement);