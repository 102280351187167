import {
  DELETE_GLOBAL_DESTINATION_SERVICES_FAILURE,
  DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  DELETE_GLOBAL_LANGUAGE_TRAINING_FAILURE,
} from 'modules/authorizations/store/types/globalTypes';
import { FormTypes } from '../globalDestServiceConstants';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  deleteGlobalDestinationServices,
  deleteGlobalInternationalSchooling,
  deleteGlobalLanguageTraining,
} from 'modules/authorizations/store/actions/global.actions';
import { useDispatch } from 'react-redux';

export const useDeleteDataHandler = (showToast) => {
  const dispatch = useDispatch();

  const handleDeleteDestinationServiceData = async (authId, formId) => {
    const response = await dispatch(deleteGlobalDestinationServices(authId, formId));
    if (response.type === DELETE_GLOBAL_DESTINATION_SERVICES_FAILURE) {
      showToast('Failed deleting destination service, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const handleDeleteLanguageTrainingData = async (authId, formId) => {
    const response = await dispatch(deleteGlobalLanguageTraining(authId, formId));
    if (response.type === DELETE_GLOBAL_LANGUAGE_TRAINING_FAILURE) {
      showToast('Failed deleting language training, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const handleDeleteInternationalSchoolingData = async (authId, formId) => {
    const response = await dispatch(deleteGlobalInternationalSchooling(authId, formId));
    if (response.type === DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE) {
      showToast('Failed deleting international schooling, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const deleteData = async (formType, authId, formId) => {
    try {
      switch (formType) {
        case FormTypes.DestinationService:
          return await handleDeleteDestinationServiceData(authId, formId);
        case FormTypes.LanguageTraining:
          return await handleDeleteLanguageTrainingData(authId, formId);
        case FormTypes.InternationalSchooling:
          return await handleDeleteInternationalSchoolingData(authId, formId);
        default:
          console.error('Not a valid form type.');
          return false;
      }
    } catch (error) {
      showToast('Failed deleting data, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return { deleteData };
};
