/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { memoize } from 'lodash';
import CrudTable from '../clients/details/crudTable.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import classNames from 'classnames';
import { deleteExpenseSummary, getExpenseSummaries, postExpenseSummary, updateExpenseSummary } from 'modules/expenses/expenses.actions';
import { DELETE_EXPENSE_SUMMARY_FAILURE, GET_EXPENSE_SUMMARIES_FAILURE, POST_EXPENSE_SUMMARY_FAILURE, UPDATE_EXPENSE_SUMMARY_FAILURE } from 'modules/expenses/types';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ExpenseSummariesTable = (props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const renderAuthOptions = memoize(() => {
    let allAuths = props.authorizationSummaries ? props.authorizationSummaries.map((auth) => auth.authorizationId) : [];
    return allAuths;
  });

  const statusOptions = [
    { title: 'Not Viewed', id: 1 },
    { title: 'Not Viewed with Error', id: 2 },
    { title: 'On Hold', id: 3 },
    { title: 'Viewed', id: 4 },
  ];

  const paidToOptions = [
    { title: 'Employee', id: 1 },
    { title: 'PrePaid Employee', id: 2 },
    { title: 'Vendor', id: 3 },
  ];

  const reimbursementOptions = [
    { title: 'Check to Old Address', id: 1 },
    { title: 'Check to Temp Address', id: 2 },
    { title: 'Check to New Address', id: 3 },
    { title: 'Direct Deposit', id: 4 },
    { title: 'Check to Manual Address', id: 5 },
    { title: 'Prepaid Card', id: 6 },
  ];

  const payInOptions = [
    { title: 'USD', id: 1 },
    { title: 'CAD', id: 2 },
    { title: 'EUR', id: 3 },
    { title: 'GBP', id: 4 },
    { title: 'JPY', id: 5 },
    { title: 'CHF', id: 6 },
  ];

  const batchOptions = [
    { title: 'ACH/WIRE', id: 1 },
    { title: 'AP TRACKING', id: 2 },
    { title: 'BILL TO EE - CHECK', id: 3 },
    { title: 'BILL TO EE - INTL', id: 4 },
    { title: 'BILL TO EE - ACH/WIRE', id: 5 },
    { title: 'BILL TO EE - TRACKING', id: 7 },
    { title: 'BLACKOUT', id: 8 },
    { title: 'CHECK', id: 9 },
    { title: 'CLIENT INTEREST', id: 10 },
    { title: 'CODING CORRECTION', id: 11 },
    { title: 'DEPOSIT', id: 12 },
    { title: 'EM TRACKING', id: 13 },
    { title: 'FEE', id: 14 },
    { title: 'INTERNATIONAL', id: 15 },
    { title: 'JOURNAL ENTRY', id: 16 },
    { title: 'ON HOLD', id: 17 },
  ];

  const bankOptions = [
    { title: 'BMO Harris Bank - USD - ****9999 (Dft/ACH)', id: 1 },
    { title: 'Wells Fargo Bank - USD - ****9999 (Dft/ACH)', id: 2 },
    { title: 'PNC Bank - USD - ****9999 (Dft/ACH)', id: 3 },
    { title: 'US Bank - USD - ****9999 (Dft/ACH)', id: 4 },
    { title: 'Bank of America - USD - ****9999 (Dft/ACH)', id: 5 },
    { title: 'Chase Bank - USD - ****9999 (Dft/ACH)', id: 6 },
  ];

  const notPayableOptions = [
    { title: 'Backout/Re-enter', id: 1 },
    { title: 'Cost Avoidance', id: 2 },
    { title: 'Paid by Client Through Payroll', id: 3 },
    { title: 'Tracking', id: 4 },
    { title: 'N/A', id: 5 },
  ];

  const adjustedColumns = [
    { accessorKey: 'authorizationId', header: 'Authorization Id', type: 'dropdown', options: renderAuthOptions(), required: true, size: 50 },
    { accessorKey: 'authName', header: 'Authorization Name', type: 'text', required: false, hideInModel: true, size: 50 },
    { accessorKey: 'status', header: 'Status', type: 'dropdown-v2', options: statusOptions, required: true, size: 70, Cell: ({ cell }) => cell.row.original.status != null && <p>{statusOptions.find((o) => o.id === cell.row.original.status)?.title}</p> },
    { accessorKey: 'dateSubmitted', header: 'Date Submitted', type: 'date', required: true, size: 50 },
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 70 },
    { accessorKey: 'total', header: 'Total', type: 'money', required: true, size: 50 },
    { accessorKey: 'expenseAnalyst', header: 'Expense Analyst', type: 'text', required: true, size: 50, hideInTable: true },
    { accessorKey: 'paidTo', header: 'Paid To', type: 'dropdown-v2', options: paidToOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'reimbursementType', header: 'Reimbursement Type', type: 'dropdown-v2', options: reimbursementOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'payInCurrency', header: 'Pay In Currency', type: 'dropdown-v2', options: payInOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'equivalentCurrency', header: 'Equivalent Currency', type: 'dropdown-v2', options: payInOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'batchType', header: 'Batch Type', type: 'dropdown-v2', options: batchOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'bankAccount', header: 'Bank Account', type: 'dropdown-v2', options: bankOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'notPayable', header: 'Not Payable', type: 'dropdown-v2', options: notPayableOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'rushPayment', header: 'Rush Payment', type: 'checkbox', required: false, size: 50, hideInTable: true },
    { accessorKey: 'containsAttachment', header: 'Contains Attachment', type: 'checkbox', required: false, size: 50, hideInTable: true },
    { accessorKey: 'isExpenseApproved', header: 'Approve Expense', type: 'checkbox', required: false, size: 50, hideInTable: true },
  ];

  const updateRow = async (orgRow, newRow) => {
    newRow.expenseId = orgRow.original.expenseId;

    let checkValues = {...newRow};

    if (newRow.authorizationId) {
        checkValues.authName = props.authorizationSummaries.find((a) => a.authorizationId === newRow.authorizationId)?.transfereeName;
    }

    const resp = await props.updateExpenseSummary(checkValues);
    if (resp.type === UPDATE_EXPENSE_SUMMARY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getExpenseSummaries();
      if (getResp.type === GET_EXPENSE_SUMMARIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    let checkValues = {...values};

    if (values.authorizationId) {
        checkValues.authName = props.authorizationSummaries.find((a) => a.authorizationId === values.authorizationId).transfereeName;
    }

    if (typeof checkValues.bankAccount === 'string') checkValues.bankAccount = null;
    if (typeof checkValues.batchType === 'string') checkValues.batchType = null;
    if (typeof checkValues.containsAttachment === 'string') checkValues.containsAttachment = null;
    if (typeof checkValues.equivalentCurrency === 'string') checkValues.equivalentCurrency = null;
    if (typeof checkValues.notPayable === 'string') checkValues.notPayable = null;
    if (typeof checkValues.payInCurrency === 'string') checkValues.payInCurrency = null;
    if (typeof checkValues.reimbursementType === 'string') checkValues.reimbursementType = null;
    if (typeof checkValues.rushPayment === 'string') checkValues.rushPayment = null;

    const resp = await props.postExpenseSummary(checkValues);
    if (resp.type === POST_EXPENSE_SUMMARY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getExpenseSummaries();
      if (getResp.type === GET_EXPENSE_SUMMARIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (rowToDelete) => {
    const id = rowToDelete.original.expenseId;
    const resp = await props.deleteExpenseSummary(id);
    if (resp.type === DELETE_EXPENSE_SUMMARY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getExpenseSummaries();
      if (getResp.type === GET_EXPENSE_SUMMARIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
      classes={{ root: classNames(props.classes.accordionStyleContacts) }}
    >
      <AccordionSummary
        expandIcon={
          <Box color={theme.palette.secondary.main}>
            <ExpandMoreIcon color="inherit" />
          </Box>
        }
      >
        <div className={props.classes.newRow}>
          <h2>Expense Summaries</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.isLoadingExpenseSummaries && props.expenseSummaries && (
          <div>
            <CrudTable
              editingMode={'modal'} // Can be changed to 'row' to edit inline
              columns={adjustedColumns}
              rows={props.expenseSummaries}
              updateRow={updateRow}
              createRow={createRow}
              deleteRow={deleteRow}
            />
          </div>
        )}
        {props.isLoadingExpenseSummaries && <Spinner />}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => {
  const { expenseSummaries, isLoadingExpenseSummaries } = state.expenses;
  const { authorizationSummaries } = state.authorizations; 
  return {
    expenseSummaries,
    isLoadingExpenseSummaries,
    authorizationSummaries,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    updateExpenseSummary,
    deleteExpenseSummary,
    postExpenseSummary,
    getExpenseSummaries,
  }),
)(ExpenseSummariesTable);
