import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

import CrudTable from '../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';

import {
  DELETE_EXPENSE_CODES_FAILURE, GET_EXPENSE_CODES_FAILURE, POST_EXPENSE_CODES_FAILURE, UPDATE_EXPENSE_CODES_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';

import {
  deleteExpenseCodes, getExpenseCodes, postExpenseCodes, updateExpenseCodes,
} from 'modules/authorizations/store/actions/dataManagement.actions';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ExpenseCodesTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'expenseCodeCode', header: 'Expense Code', type: 'text', required: true, size: 70 },
    { accessorKey: 'expenseCodeDescription', header: 'Expense Code Description', type: 'text', required: true, size: 50 },
    { accessorKey: 'classification', header: 'Classification', type: 'dropdown', options: ['T', 'E', 'O', 'P', 'F', 'MT', 'N', 'MN'], required: false, size: 70 },
    { accessorKey: 'category', header: 'Category', type: 'dropdown', options: ['EA', 'RC', 'AC', 'PC', 'HHG', 'TRIP', 'HH', 'TL'], required: false, size: 70 },
  ];

  const updateExpenseCode = async (vendor, values) => {
    values.expenseCodeId = vendor.original.expenseCodeId;

    setIsLoading(true);
    const resp = await props.updateExpenseCodes(values);
    if (resp.type === UPDATE_EXPENSE_CODES_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getExpenseCodes();
      setIsLoading(false);
      if (getResp.type === GET_EXPENSE_CODES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createExpenseCode = async (values) => {
    setIsLoading(true);
    const resp = await props.postExpenseCodes(values);
    if (resp.type === POST_EXPENSE_CODES_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getExpenseCodes();
      setIsLoading(false);
      if (getResp.type === GET_EXPENSE_CODES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteExpenseCodeLoc = async (vendor) => {
    const id = vendor.original.expenseCodeId;
    const resp = await props.deleteExpenseCodes(id);
    if (resp.type === DELETE_EXPENSE_CODES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getExpenseCodes();
      if (getResp.type === GET_EXPENSE_CODES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Expense Codes</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!isLoading && props.expenseCodes && (
          <div>
            <CrudTable 
              editingMode={'modal'}
              columns={adjustedColumns} 
              rows={props.expenseCodes} 
              updateRow={updateExpenseCode} 
              createRow={createExpenseCode} 
              deleteRow={deleteExpenseCodeLoc} 
            />
          </div>
          
        )}
        {isLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

const mapStateToProps = (state) => {
  const { expenseCodes } = state.authorizations;
  return {
    expenseCodes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getExpenseCodes,
    deleteExpenseCodes,
    postExpenseCodes,
    updateExpenseCodes,
  }),
)(ExpenseCodesTable);