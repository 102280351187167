import { DELETE_CREDIT_FAILURE, GET_CREDIT_FAILURE, POST_CREDIT_FAILURE, UPDATE_CREDIT_FAILURE, UPDATE_EQUITY_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteCredit, getCredits, postCredit, updateCredit, updateEquity } from 'modules/authorizations/store/actions/homesale.actions';
import { showToast } from 'modules/layout/layout.actions';
import MultiTabCard, { ContentType } from 'modules/common/multiTabCard/multiTabCard.component';
import React, { useState } from 'react';

const CreditsChargesCard = (props) => {
  const { credits, equityInfo, expenseCodes, isReadOnly } = props;

  const typeOptions = [
    { title: 'Credit', id: 1 },
    { title: 'Charge', id: 2 },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const expenseCodeSearchInfo = {
    searchingColumns: [
      { name: 'expenseCodeId', title: 'Id' },
      { name: 'expenseCodeCode', title: 'Expense Code' },
      { name: 'expenseCodeDescription', title: 'Description' },
      { name: 'classification', title: 'Classification' },
    ],
    searchingColumnExtensions: [
      { columnName: 'expenseCodeId', width: 150 },
      { columnName: 'expenseCodeCode', width: 175 },
      { name: 'expenseCodeDescription', width: 250 },
      { name: 'classification', width: 175 },
    ],
    searchingRows: expenseCodes,
    idKey: 'expenseCodeId',
    nameKey: 'expenseCodeCode',
  };

  const creditsTaxesSummaries = [
    { header: 'Appraisal', accessorKey: 'appraisalAmount', type: 'money', required: false, value: equityInfo ? equityInfo.appraisalAmount : '', tab: 1 },
    { header: 'Inspection', accessorKey: 'inspectionAmount', type: 'money', required: false, value: equityInfo ? equityInfo.inspectionAmount : '', tab: 1 },
    { header: 'Title Work', accessorKey: 'titleWorkAmount', type: 'money', required: false, value: equityInfo ? equityInfo.titleWorkAmount : '', tab: 1 },
    { header: 'Attorney Fees', accessorKey: 'attorneyFeesAmount', type: 'money', required: false, value: equityInfo ? equityInfo.attorneyFeesAmount : '', tab: 1 },
    { header: 'Doc Preparation', accessorKey: 'docPreparationAmount', type: 'money', required: false, value: equityInfo ? equityInfo.docPreparationAmount : '', tab: 1 },
    { header: 'Close Fee', accessorKey: 'closeFeeAmount', type: 'money', required: false, value: equityInfo ? equityInfo.closeFeeAmount : '', tab: 1 },
    { header: 'Record Fee', accessorKey: 'recordFeeAmount', type: 'money', required: false, value: equityInfo ? equityInfo.recordFeeAmount : '', tab: 1 },
    { header: 'Doc Tax', accessorKey: 'docTaxAmount', type: 'money', required: false, value: equityInfo ? equityInfo.docTaxAmount : '', tab: 1 },
    { header: 'Commission', accessorKey: 'commissionAmount', type: 'money', required: false, value: equityInfo ? equityInfo.commissionAmount : '', tab: 1 },
  ];

  const otherCreditsColumns = [
    { accessorKey: 'type', header: 'Type', type: 'dropdown-v2', options: typeOptions, required: true, size: 55, displayInHeaderRow: true, Cell: ({ cell }) => cell.row.original.type != null && <p>{typeOptions.find((o) => o.id === cell.row.original.type).title}</p> },
    { accessorKey: 'expenseCodeId', header: 'Expense Code', type: 'advanced-search', required: true, size: 55, hideInTable: true, displayInHeaderRow: true, nameKey: 'expenseCodeCode', searchInfo: expenseCodeSearchInfo, Cell: ({ cell }) => (cell.getValue() ? expenseCodes?.find((v) => v.expenseCodeId === cell.getValue())?.expenseCodeCode : '') },
    { accessorKey: 'amount', header: 'Amount', type: 'money', required: true, size: 60, displayInHeaderRow: false, Cell: ({ cell }) => cell.row.original.amount != null && <p>{`$${cell.row.original.amount}`}</p> },
    { accessorKey: 'expenseCode', header: 'Expense Code', type: 'text', required: true, size: 60, hideInModel: true, displayInHeaderRow: true },
    { accessorKey: 'expenseCodeDescription', header: 'Expense Name', type: 'text', required: true, size: 60, hideInModel: true, hideInTable: true, displayInHeaderRow: true },
  ];

  const taxTabs = [
    {
      order: 1,
      contentType: ContentType.EditableCard,
      header: 'Credits/Charges',
      iconPath: ['fas', 'money-bill'],
      isDisabled: false,
      headerMessage: null,
      hideEdit: false,
    },
    {
      order: 2,
      contentType: ContentType.CrudTable,
      header: 'Other C&C',
      iconPath: ['fas', 'sack-dollar'],
      isDisabled: false,
      headerMessage: null,
      hideEdit: false,
    },
  ];

  const updateCreditCharge = async (vendor, values) => {
    values.creditId = vendor.original.creditId;
    values.equityId = vendor.original.equityId;

    if (values.expenseCodeId) {
      const expenseCodeFullInfo = expenseCodes.find((e) => e.expenseCodeId === values.expenseCodeId);
      values.expenseCode = expenseCodeFullInfo.expenseCodeCode;
      values.expenseCodeDescription = expenseCodeFullInfo.expenseCodeDescription;
    }

    setIsLoading(true);
    const resp = await props.updateCredit(values);
    if (resp.type === UPDATE_CREDIT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getCredits(equityInfo.equityId);
      setIsLoading(false);
      if (getResp.type === GET_CREDIT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createCreditCharge = async (values) => {
    setIsLoading(true);
    values.equityId = equityInfo.equityId;

    if (values.expenseCodeId) {
      const expenseCodeFullInfo = expenseCodes.find((e) => e.expenseCodeId === values.expenseCodeId);
      values.expenseCode = expenseCodeFullInfo.expenseCodeCode;
      values.expenseCodeDescription = expenseCodeFullInfo.expenseCodeDescription;
    }

    const resp = await props.postCredit(values);
    if (resp.type === POST_CREDIT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getCredits(equityInfo.equityId);
      setIsLoading(false);
      if (getResp.type === GET_CREDIT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteCreditCharge = async (vendor) => {
    const id = vendor.original.creditId;
    const resp = await props.deleteCredit(id);
    if (resp.type === DELETE_CREDIT_FAILURE) {
      return false;
    } else {
      const getResp = await props.getCredits(equityInfo.equityId);
      if (getResp.type === GET_CREDIT_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const updateCreditsInfoFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...equityInfo };

    newValues.appraisalAmount = values.appraisalAmount;
    newValues.inspectionAmount = values.inspectionAmount;
    newValues.titleWorkAmount = values.titleWorkAmount;
    newValues.attorneyFeesAmount = values.attorneyFeesAmount;
    newValues.docPreparationAmount = values.docPreparationAmount;
    newValues.closeFeeAmount = values.closeFeeAmount;
    newValues.recordFeeAmount = values.recordFeeAmount;
    newValues.docTaxAmount = values.docTaxAmount;
    newValues.commissionAmount = values.commissionAmount;

    const resp = await props.updateEquity(newValues);
    if (resp.type === UPDATE_EQUITY_FAILURE) {
      props.showToast('Failed to update equity info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };

  const taxesTableContents = [
    {
      contentType: ContentType.EditableCard,
      tabOrder: 1,
      columns: null,
      rows: null,
      updateRow: null,
      createRow: null,
      deleteRow: null,
      getNameOfDeleteRow: null,
      customAddText: null,
      headerRow: null,
      summaries: creditsTaxesSummaries,
      numColumns: 3,
      updateFields: updateCreditsInfoFields,
      isLoading,
      hideEdit: isReadOnly,
      topLink: null,
    },
    {
      contentType: ContentType.CrudTable,
      tabOrder: 2,
      columns: otherCreditsColumns,
      rows: credits,
      updateRow: updateCreditCharge,
      createRow: createCreditCharge,
      deleteRow: deleteCreditCharge,
      // getNameOfDeleteRow: getNameOfDeleteRow,
      customAddText: 'Add Credit / Charge',
      summaries: null,
      numColumns: null,
      updateFields: null,
      isLoading,
      hideEdit: isReadOnly,
      topLink: null,
    },
  ];

  return (
    <MultiTabCard
      tabs={taxTabs}
      contents={taxesTableContents}
    />
  );
};

export default compose(
  connect(null, {
    showToast,
    updateEquity,
    updateCredit,
    deleteCredit,
    postCredit,
    getCredits,
  }),
)(CreditsChargesCard);
