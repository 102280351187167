/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ValueType } from './customExpenseLinesUtils';
import SpinnerComponent from 'modules/common/spinner.component';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const CustomExpenseDetailsCurrencyEditableCard = props => {
  const { updateFields, isLoading, onCancelProp, selectedExpenseLineItem, hideEdit } = props;
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const createTableValues = () => {
    return {
      Receipt: { amount: selectedExpenseLineItem?.receiptAmount, currency: selectedExpenseLineItem?.currency },
      'Pay In': { amount: selectedExpenseLineItem?.payInAmount, currency: selectedExpenseLineItem?.currency },
      Home: { amount: selectedExpenseLineItem?.homeAmount, currency: selectedExpenseLineItem?.currency },
      Host: { amount: selectedExpenseLineItem?.hostAmount, currency: selectedExpenseLineItem?.currency },
      USD: { amount: selectedExpenseLineItem?.usdAmount, currency: 'USD' },
      CAD: { amount: selectedExpenseLineItem?.cadAmount, currency: 'CAD' },
      'Service Co': { amount: selectedExpenseLineItem?.serviceCompanyAmount, currency: selectedExpenseLineItem?.currency }
    };
  };

  const rowNames = ['Receipt', 'Pay In', 'Home', 'Host', 'USD', 'CAD', 'Service Co'];

  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const handleSaveEdits = async (authId, values) => {
    if (updateFields) {
      const resp = await updateFields(authId, values);
      if (!resp) {
        showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      } else {
        setEditingModalOpen(false);
      }
    }
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  const handleCancelEdits = () => {
    setEditingModalOpen(false); //required to exit editing mode and close modal
    if (onCancelProp) {
      onCancelProp();
    }
  };

  const renderHeader = () => {
    return (
      <div className="row justify-space-between align-center">
        <div className={classes.iconRow}>
          <Tooltip title="Currency" key={'Currency'} placement="right">
            <FontAwesomeIcon icon={['fas', 'truck-moving']} size="2x" />
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              paddingLeft: '20px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: '1.3125rem',
                fontWeight: 'bold'
              }}
            >
              Currency
            </Typography>
          </Box>
        </div>
        {!hideEdit && (
          <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
            <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
          </Fab>
        )}
      </div>
    );
  };

  //Top part ...
  const renderOption = (value, valueType, header) => {
    return (
      <Grid item xs={2} sm={2} md={2} xl={2}>
        <Box>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
              {header}
            </Typography>
          </div>
          {valueType === ValueType.DateTimeType && (
            <div className={classes.mb2}>
              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                {value ? new Date(value).toLocaleDateString('en-us') : <div>&mdash;</div>}
              </Typography>
            </div>
          )}
          {valueType === ValueType.MoneyType && (
            <div className={classes.mb2}>
              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                {value ? `$${value}` : <div>&mdash;</div>}
              </Typography>
            </div>
          )}
          {valueType === ValueType.StringType && (
            <div className={classes.mb2}>
              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                {value ? value : <div>&mdash;</div>}
              </Typography>
            </div>
          )}
        </Box>
      </Grid>
    );
  };

  const renderEditableCardTable = values => {
    return (
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
              Amount
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
              Currency
            </Typography>
          </Grid>
          {rowNames.map(name => (
            <React.Fragment key={name}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                  {values[name]?.amount || '--'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                  {values[name]?.currency || '--'}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ position: 'relative' }}>
        <Box>
          <CardContent className={classes.cardContainer}>{renderHeader()}</CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2} columns={{ xs: 6 }}>
              {renderOption(selectedExpenseLineItem?.exchangeRateDate, ValueType.DateTimeType, 'Exchange Rate Date')}
              {renderOption(selectedExpenseLineItem?.budgetCurrencyAmount, ValueType.MoneyType, 'Budget Currency Amount')}
              {renderOption(selectedExpenseLineItem?.equivalentCurrency, ValueType.StringType, 'Equivalent Currency')}
              {renderEditableCardTable(createTableValues())}
            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          selectedExpenseLineItem={selectedExpenseLineItem}
          classes={classes}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={handleCancelEdits}
          onSubmit={handleSaveEdits}
        />
      )}
    </ThemeProvider>
  );
};
CustomExpenseDetailsCurrencyEditableCard.propTypes = {
  tabs: PropTypes.array.isRequired,
  summaries: PropTypes.array.isRequired,
  updateFields: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
};
export default connect(null, { showToast })(CustomExpenseDetailsCurrencyEditableCard);

export const EditCardModal = ({ selectedExpenseLineItem, classes, onClose, onSubmit, isLoading, open }) => {
  const payInOptions = ['USD', 'CAD', 'EUR', 'GBP', 'JPY', 'CHF'];
  const [values, setValues] = useState(selectedExpenseLineItem);

  useEffect(() => {
    setValues(selectedExpenseLineItem);
  }, [selectedExpenseLineItem]);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(values);
  };

  const handleCancel = e => {
    e.preventDefault();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Currency" key="Currency" placement="right">
              <FontAwesomeIcon icon={['fas', 'truck-moving']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Currency
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px' },
              gap: '1.5rem'
            }}
          >
            <Grid
              container
              spacing={{ xs: 2 }}
              sx={{ padding: '30px', paddingTop: '0px', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <Grid item key={'exchangeRateDate'} sx={{ margin: '0 10px' }}>
                <TextField
                  key="exchangeRateDate"
                  label="Exchange Rate Date"
                  name="exchangeRateDate"
                  value={formatDateForInput(values?.exchangeRateDate)}
                  margin="dense"
                  variant="standard"
                  sx={{ maxWidth: '360px' }}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'budgetCurrencyAmount'} sx={{ margin: '0 20px' }}>
                <TextField
                  key="budgetCurrencyAmount"
                  label="Budget Currency Amount"
                  name="budgetCurrencyAmount"
                  value={values?.budgetCurrencyAmount}
                  margin="dense"
                  sx={{ maxWidth: '360px' }}
                  variant="standard"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  allowDecimal={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'equivalentCurrency'} sx={{ margin: '0 20px' }}>
                <FormControl sx={{ marginTop: '6px' }}>
                  <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id="Equivalent Currency">
                    Equivalent Currency
                  </InputLabel>
                  <Select
                    labelId="Equivalent Currency"
                    key="equivalentCurrency"
                    label="Equivalent Currency"
                    name="equivalentCurrency"
                    value={values?.equivalentCurrency}
                    margin="dense"
                    variant="standard"
                    sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                    onChange={handleChange}
                  >
                    {payInOptions.map(opt => (
                      <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                        {opt}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Box sx={{ padding: '20px', maxWidth: '800px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Currency
                    </Typography>
                  </Grid>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Receipt
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="receiptAmount"
                        label="Receipt"
                        name="receiptAmount"
                        value={values?.receiptAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ maxWidth: '360px', color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {selectedExpenseLineItem?.currency}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Pay In
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="payInAmount"
                        name="payInAmount"
                        value={values?.payInAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ maxWidth: '360px', color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {selectedExpenseLineItem?.currency}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Home Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="homeAmount"
                        name="homeAmount"
                        value={values?.homeAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ maxWidth: '360px', color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {selectedExpenseLineItem?.currency}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Host Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="hostAmount"
                        name="hostAmount"
                        value={values?.hostAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ maxWidth: '360px', color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {selectedExpenseLineItem?.currency}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        USD
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="usdAmount"
                        name="usdAmount"
                        value={values?.usdAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ maxWidth: '360px', color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        USD
                      </Typography>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        CAD
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="cadAmount"
                        name="cadAmount"
                        value={values?.cadAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ maxWidth: '360px', color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        CAD
                      </Typography>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Service Co
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="serviceCompanyAmount"
                        name="serviceCompanyAmount"
                        value={values?.serviceCompanyAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ maxWidth: '360px', color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {selectedExpenseLineItem?.currency}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                </Grid>
              </Box>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <SpinnerComponent />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};
