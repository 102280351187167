import {
  GET_ALL_BROKER_AGENTS_FAILURE,
  GET_ALL_BROKER_AGENTS_REQUEST,
  GET_ALL_BROKER_AGENTS_SUCCESS,
  GET_EXPENSE_CODES_SUCCESS,
  GET_INSPECTORS_FAILURE,
  GET_INSPECTORS_REQUEST,
  GET_INSPECTORS_SUCCESS,
  GET_TAX_POLICIES_FAILURE,
  GET_TAX_POLICIES_REQUEST,
  GET_TAX_POLICIES_SUCCESS,
  GET_TITLE_REP_FAILURE,
  GET_TITLE_REP_REQUEST,
  GET_TITLE_REP_SUCCESS,
  GET_VENDORS_SUCCESS,
  GET_VENDOR_CONTACT_SUCCESS,
  GET_VENDOR_DOCUMENTS_SUCCESS,
  UPDATE_VENDORS_SUCCESS,
  GET_VENDOR_NOTES_SUCCESS,
} from '../types/dataManagementTypes';

const handleDataManagementReducer = (draft, action) => {
  switch (action.type) {
    case GET_ALL_BROKER_AGENTS_REQUEST:
      draft.brokerAgentsLoading = true;
      break;

    case GET_ALL_BROKER_AGENTS_SUCCESS:
      draft.brokerAgentsLoading = false;
      draft.brokerAgents = action.response;
      break;

    case GET_ALL_BROKER_AGENTS_FAILURE:
      draft.brokerAgentsLoading = false;
      break;

    case GET_TAX_POLICIES_REQUEST:
      draft.taxPoliciesLoading = true;
      break;

    case GET_TAX_POLICIES_FAILURE:
      draft.taxPoliciesLoading = false;
      break;

    case GET_TAX_POLICIES_SUCCESS:
      draft.taxPolicies = action.response;
      draft.taxPoliciesLoading = false;
      break;

    case GET_INSPECTORS_REQUEST:
      draft.isInspectorsLoading = true;
      break;

    case GET_INSPECTORS_SUCCESS:
      draft.inspectors = action.response;
      draft.isInspectorsLoading = false;
      break;

    case GET_INSPECTORS_FAILURE:
      draft.isInspectorsLoading = false;
      break;

    case GET_VENDORS_SUCCESS:
      draft.vendors = action.response;
      break;

    case GET_VENDOR_NOTES_SUCCESS:
      draft.vendorAuthNotes = action.response;
      break;

    case GET_VENDOR_CONTACT_SUCCESS:
      draft.vendorContacts = action.response;
      break;

    case GET_VENDOR_DOCUMENTS_SUCCESS:
      draft.vendorDocuments = action.response;
      break;

    case GET_EXPENSE_CODES_SUCCESS:
      draft.expenseCodes = action.response;
      break;

    case GET_TITLE_REP_REQUEST:
      draft.isTitleRepIsLoading = true;
      break;

    case GET_TITLE_REP_SUCCESS:
      draft.titleReps = action.response;
      draft.isTitleRepIsLoading = false;
      break;

    case GET_TITLE_REP_FAILURE:
      draft.isTitleRepIsLoading = false;
      break;

    case UPDATE_VENDORS_SUCCESS: {
      const vendors = draft.vendors;
      if (vendors) {
        const indexToUpdate = vendors.findIndex(s => s.vendorId === action.response?.vendorId);
        if (indexToUpdate !== -1) {
          vendors[indexToUpdate] = action.response;
          draft.vendors = [...vendors];
        }
      }
      break;
    }

    default:
      break;
  }
  return draft;
};

export default handleDataManagementReducer;
