import { Box, Button, DialogActions, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { UPDATE_EQUITY_FAILURE, UPDATE_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateEquity } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import SpinnerComponent from 'modules/common/spinner.component';

const EquityDetailsCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const { equityInfo, isReadOnly } = props;

  const tabs = [
    { header: 'Equity Details', order: 1, iconPath: ['fas', 'money-check-dollar'] },
  ];

  const typeOptions = [
    { title: 'HMA', id: 1 },
    { title: 'BVO', id: 2 },
    { title: 'GBO', id: 3 },
  ];

  const equityStatuses = [
    { title: 'Advance', id: 1 },
    { title: 'Estimate', id: 2 },
    { title: 'Final', id: 3 },
  ];

  const summaries = [
    { header: 'Version', accessorKey: 'version', type: 'number', required: false, hideInModal: true, value: equityInfo ? equityInfo.version : '', tab: 1 },
    { header: 'Posted', accessorKey: 'postedOn', type: 'date', required: false, hideInModal: true, value: equityInfo ? equityInfo.postedOn : '', tab: 1 },
    { header: 'Sale Type', accessorKey: 'saleType', type: 'dropdown-v2', required: false, options: typeOptions, value: equityInfo ? equityInfo.saleType : '', tab: 1 },
    { header: 'Prorate Date', accessorKey: 'prorateDate', type: 'date', required: false, value: equityInfo ? equityInfo.prorateDate : '', tab: 1 },
    { header: 'Status', accessorKey: 'status', type: 'dropdown-v2', options: equityStatuses, required: false, value: equityInfo ? equityInfo.status : '', tab: 1 },
    { header: 'Closing', accessorKey: 'closing', type: 'date', required: false, value: equityInfo ? equityInfo.closing : '', tab: 1 },
    { header: 'Purchase/Sale Price', accessorKey: 'salePrice', type: 'money', required: false, value: equityInfo ? equityInfo.salePrice : '', tab: 1 },
    { header: 'Mortgage Commit Date', accessorKey: 'mortgageCommit', type: 'date', required: false, value: equityInfo ? equityInfo.mortgageCommit : '', tab: 1 },
    { dividerLine: true, tab: 1 },
    { header: 'Comment', accessorKey: 'comment', type: 'notes', required: false, value: equityInfo ? equityInfo.comment : '', showBelow: true, tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...equityInfo };

    newValues.saleType = values.saleType;
    newValues.prorateDate = values.prorateDate;
    newValues.status = values.status;
    newValues.closing = values.closing;
    newValues.salePrice = values.salePrice;
    newValues.mortgageCommit = values.mortgageCommit;
    newValues.comment = values.comment;

    const resp = await props.updateEquity(newValues);
    if (resp.type === UPDATE_LISTINGS_FAILURE) {
      props.showToast('Failed to update equity info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };

  const requestEquityAdvance = async () => {
    setIsLoadingButton(true);
    let newVals = { ...equityInfo };
    newVals.equityAdvanceRequested = true;

    let resp = await props.updateEquity(newVals);
    if (resp.type === UPDATE_EQUITY_FAILURE) {
      props.showToast('Failed to request equity advance, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Equity advance requested successfully.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }

    setIsLoadingButton(false);
    return resp;
  };

  const sendDocumentPackage = async () => {
    setIsLoadingButton(true);
    let newVals = { ...equityInfo };
    newVals.documentPackageSent = true;

    let resp = await props.updateEquity(newVals);
    if (resp.type === UPDATE_EQUITY_FAILURE) {
      props.showToast('Failed to send document package, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Document package sent successfully.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }

    setIsLoadingButton(false);
    return resp;
  };

  const requestEquityCalculation = async () => {
    setIsLoadingButton(true);
    let newVals = { ...equityInfo };
    newVals.equityCalculationRequested = true;

    let resp = await props.updateEquity(newVals);
    if (resp.type === UPDATE_EQUITY_FAILURE) {
      props.showToast('Failed to request equity calculation, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Equity calculation requested successfully.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }

    setIsLoadingButton(false);
    return resp;
  };

  const equityDetailsButtons = () => {
    return (
      <>
        {isLoadingButton && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <SpinnerComponent />
            </DialogActions>
          </Box>
        )}
        {!isLoadingButton && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                {!isReadOnly && !equityInfo.equityAdvanceRequested && (
                  <Button
                    onClick={async () => await requestEquityAdvance()}
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#868686',
                      '&:hover': { backgroundColor: '#474747' },
                    }}
                  >
                    {'Request Equity Advance'}
                  </Button>
                )}
                {!isReadOnly && !equityInfo.documentPackageSent && (
                  <Button
                    onClick={async () => await sendDocumentPackage()}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    {'Send EE Document Package'}
                  </Button>
                )}
                {!isReadOnly && !equityInfo.equityCalculationRequested && (
                  <Button
                    onClick={async () => await requestEquityCalculation()}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    {'Request Equity Calculation'}
                  </Button>
                )}
              </Box>
            </Box>
            {(equityInfo.equityAdvanceRequested || equityInfo.documentPackageSent || equityInfo.equityCalculationRequested) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Box>
                  {equityInfo.equityAdvanceRequested && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Equity advance requested successfully {!isReadOnly && <> <Link onClick={async () => await requestEquityAdvance()} sx={{ cursor: 'pointer' }}>, click here</Link> to resend. </> } </Typography>}
                  {equityInfo.documentPackageSent && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Document package sent successfully {!isReadOnly && <><Link onClick={async () => await sendDocumentPackage()} sx={{ cursor: 'pointer' }}>, click here</Link> to resend.</> } </Typography>}
                  {equityInfo.equityCalculationRequested && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Equity calculation requested successfully {!isReadOnly && <><Link onClick={async () => await requestEquityCalculation()} sx={{ cursor: 'pointer' }}>, click here</Link> to resend.</> } </Typography>}
                </Box>
              </Box>
            )}
          </Box>
            
        )}
      </>
    );
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      ButtonComponent={equityDetailsButtons}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateEquity,
    showToast,
  }),
)(EquityDetailsCard);