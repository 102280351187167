import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import React, { useState } from 'react';

import { Box, Card, CardContent, Checkbox, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DELETE_HHG_ITEMS_FAILURE, GET_HHG_ITEMS_FAILURE, POST_HHG_ITEMS_FAILURE, UPDATE_HHG_ITEMS_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { deleteHhgItems, getHhgItems, postHhgItems, updateHhgItems } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import CrudTable from '../../../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const MovedItems = (props) => {

  const { isLoadingEstimates, segmentInfo, itemsToBeMoved, hideEdit } = props;

  const [isLoading, setIsLoading] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 60 },
    { accessorKey: 'quantity', header: 'Quantity', type: 'number', required: false, size: 55 },
    { accessorKey: 'value', header: 'Value', type: 'money', required: false, size: 50, Cell: ({ cell }) => <p>${cell.row.original.value}</p> },
    { accessorKey: 'specialHandling', header: 'Special Handling', type: 'text', required: false, size: 65 },
    { accessorKey: 'transitProt', header: 'Transit Protection', type: 'checkbox', defaultVal: false, required: false, size: 65, Cell: ({ cell }) => <Checkbox name="transitProt" label="Active" checked={cell.row.original.transitProt} disabled={true} /> },
    { accessorKey: 'heavyUnusual', header: 'Heavy/Unusual', type: 'checkbox', defaultVal: false, required: false, size: 65, Cell: ({ cell }) => <Checkbox name="heavyUnusual" label="Active" checked={cell.row.original.heavyUnusual} disabled={true} /> },
  ];

  const updateRow = async (item, values) => {
    setIsLoading(true);
    values.itemId = item.original.itemId;
    values.segmentId = segmentInfo.segmentId;
    values.authorizationId = segmentInfo.authorizationId;

    const resp = await props.updateHhgItems(values);
    if (resp.type === UPDATE_HHG_ITEMS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getHhgItems(segmentInfo.segmentId);
      if (getResp.type === GET_HHG_ITEMS_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const createRow = async (values) => {
    setIsLoading(true);
    values.segmentId = segmentInfo.segmentId;
    values.authorizationId = segmentInfo.authorizationId;

    const resp = await props.postHhgItems(values);
    if (resp.type === POST_HHG_ITEMS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getHhgItems(segmentInfo.segmentId);
      if (getResp.type === GET_HHG_ITEMS_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const deleteRow = async (item) => {
    setIsLoading(true);
    const id = item.original.itemId;
    const resp = await props.deleteHhgItems(id);
    if (resp.type === DELETE_HHG_ITEMS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getHhgItems(segmentInfo.segmentId);
      if (getResp.type === GET_HHG_ITEMS_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={'Items to be Moved'} key={'itemsToBeMoved'} placement="right">
            <FontAwesomeIcon icon={['fas', 'cart-flatbed-suitcase']} size="2x" />
          </Tooltip>
          <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
            Items to be Moved
          </Typography>
        </Box>
        {!isLoading && !isLoadingEstimates && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={itemsToBeMoved ? itemsToBeMoved : []} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
            customAddText={'Add Item'}
            hideEdit={hideEdit}
          />
        )}
        {(isLoading || isLoadingEstimates) && (
          <Spinner />
        )}
      </CardContent>
    </Card>
  );
}; 

export default compose(
  withStyles(styles),
  withTheme,
  connect(null, {
    getHhgItems,
    deleteHhgItems,
    postHhgItems,
    updateHhgItems,
  }),
)(MovedItems);