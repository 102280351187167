import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createFamilyPostRequest, createFamilyPutRequest, mapFamilyRow } from './FormFields/familyFormFields';
import { createMultiTabComponentContent, createMultiTabComponentTabs } from './FormFields/multiTabFormFields';
import {
  deleteFamilyMember,
  deleteFamilyPet,
  getFamilyMembers,
  getFamilyPets,
  postEmployeeInfo,
  postFamilyMember,
  postFamilyPet,
  updateEmployeeInfo,
  updateFamilyMember,
  updateFamilyMembersState,
  updateFamilyPet,
  updateFamilyPetsState,
} from '../../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import { useEmployeeHandler } from './Handlers/useEmployeeHandler';
import { useFamilyHandler } from './Handlers/useFamilyHandler';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { createFamilyPetPostRequest, createFamilyPetPutRequest, mapPetRow } from './FormFields/petFormFields';
import { usePetHandler } from './Handlers/usePetHandler';
import MultiTabCard from 'modules/common/multiTabCard/multiTabCard.component';

const EmployeeCard = (props) => {
  const { clientInfo, employee, family, id, vendors, familyPets } = props;

  const employeeHandler = useEmployeeHandler(props);
  const familyHandler = useFamilyHandler(props);
  const petHandler = usePetHandler(props);

  const [isLoading, setIsLoading] = useState(false);

  const handleEmployeeFormSubmission = async (tab, values) => {
    try {
      setIsLoading(true);
      values.authorizationId = id;
      return await employeeHandler.createOrUpdateEmployee(!(employee && employee.length !== 0), values);
    } catch (error) {
      console.error('Error submitting employee form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCrudOperation = async (operation, ...args) => {
    try {
      setIsLoading(true);
      const response = await operation(...args);
      return response;
    } catch (error) {
      console.error('Error during CRUD operation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFamilyRow = async (originalRow, newRow) => {
    const updatedValues = createFamilyPutRequest(family[originalRow.index], newRow, id);
    const resp = await handleCrudOperation(familyHandler.handleUpdateFamilyMember, updatedValues);
    if (!resp) return false;

    return { hasContent: true, content: mapFamilyRow(updatedValues) };
  };

  const createFamilyRow = async (newRow) => {
    return await handleCrudOperation(familyHandler.handleCreateFamilyMember, createFamilyPostRequest(newRow, id));
  };

  const deleteFamilyRow = async (rowToDelete) => {
    const response = await handleCrudOperation(familyHandler.handleDeleteFamilyMember, id, rowToDelete.original.familyMemberId);
    if (response) {
      props.updateFamilyMembersState(family.filter((fam) => fam.familyMemberId !== rowToDelete.original.familyMemberId));
    }
    return true;
  };

  const updatePetRow = async (originalRow, newRow) => {
    const updatedValues = createFamilyPetPutRequest(familyPets[originalRow.index], newRow, id);
    const resp = await handleCrudOperation(petHandler.handleUpdatePet, updatedValues);
    if (!resp) return false;

    return { hasContent: true, content: mapPetRow(updatedValues) };
  };

  const createPetRow = async (newRow) => {
    return await handleCrudOperation(petHandler.handleCreatePet, createFamilyPetPostRequest(newRow, id));
  };

  const deletePetRow = async (rowToDelete) => {
    const response = await handleCrudOperation(petHandler.handleDeletePet, id, rowToDelete.original.familyPetId);
    if (response) {
      props.updateFamilyPetsState(familyPets.filter((pet) => pet.familyPetId !== rowToDelete.original.familyPetId));
    }
    return true;
  };

  return (
    <MultiTabCard
      tabs={createMultiTabComponentTabs()}
      contents={createMultiTabComponentContent(
        clientInfo,
        employee,
        handleEmployeeFormSubmission,
        isLoading,
        family,
        updateFamilyRow,
        createFamilyRow,
        deleteFamilyRow,
        vendors,
        familyPets,
        updatePetRow,
        createPetRow,
        deletePetRow,
      )}
    />
  );
};

EmployeeCard.propTypes = {
  clientInfo: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  family: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  postFamilyMember: PropTypes.func.isRequired,
  updateFamilyMember: PropTypes.func.isRequired,
  getFamilyMembers: PropTypes.func.isRequired,
  deleteFamilyMember: PropTypes.func.isRequired,
  postEmployeeInfo: PropTypes.func.isRequired,
  updateEmployeeInfo: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getFamilyPets: PropTypes.func.isRequired,
  postFamilyPet: PropTypes.func.isRequired,
  updateFamilyPet: PropTypes.func.isRequired,
  deleteFamilyPet: PropTypes.func.isRequired,
  updateFamilyPetsState: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  familyPets: PropTypes.array.isRequired,
};

export default compose(
  connect(null, {
    postFamilyMember,
    updateFamilyMember,
    getFamilyMembers,
    deleteFamilyMember,
    postEmployeeInfo,
    updateEmployeeInfo,
    updateFamilyMembersState,
    showToast,
    getFamilyPets,
    postFamilyPet,
    updateFamilyPet,
    deleteFamilyPet,
    updateFamilyPetsState,
  }),
)(EmployeeCard);
