import { Initiated, MovedIn, New, TemporaryLivingStatus } from '../TempLivingConstants';
import { getStatusKey } from './tempLivingConstantsUtils';

export const selectDefaultAssignment = (assignments) => {
  if (!assignments || assignments.length === 0) return null;

  const result = assignments.reduce(
    (mostRecentAssignment, assignment, index) => {
      if (index === 0 || assignment?.assignmentCreatedOn > mostRecentAssignment?.assignmentCreatedOn) {
        return { assignmentCreatedOn: assignment?.assignmentCreatedOn, index };
      }
      return mostRecentAssignment;
    },
    { assignmentCreatedOn: null, index: -1 },
  );

  return result.index !== -1 ? assignments[result.index] : null;
};

export const showCompleteButton = (selectedTemporaryLivingAssignment, currentStatusConfig) => {
  switch (getStatusKey(currentStatusConfig)) {
    case New:
    case Initiated:
    case MovedIn:
      return currentStatusConfig?.showCompleteButtonOnField.every((prop) => {
        return (
          Object.prototype.hasOwnProperty.call(selectedTemporaryLivingAssignment, prop) &&
          selectedTemporaryLivingAssignment[prop] !== null &&
          selectedTemporaryLivingAssignment[prop] !== ''
        );
      });
    default:
      return false;
  }
};

export const getCurrentStatusSequence = (selectedAssignment) => {
  return TemporaryLivingStatus[selectedAssignment?.activeStatus]?.sequenceOrder ?? 0;
};

export const shouldHideEditIcon = (formSequenceId, selectedAssignment, isPropertyForm) => {
  const currentStatusSequence = getCurrentStatusSequence(selectedAssignment);
  if (isPropertyForm && currentStatusSequence >= formSequenceId) {
    return false;
  }
  return currentStatusSequence !== formSequenceId;
};

export const calculateDaysSinceStatusUpdated = (dateUpdated) => {
  return Math.floor((new Date() - new Date(dateUpdated)) / (1000 * 60 * 60 * 24));
};

export const calculateTemporaryLivingNumberOfDays = (status, assignment) => {
  if (!assignment || !status?.dateFieldName) {
    return 0;
  }

  const dateValue = assignment[status.dateFieldName];
  if (!dateValue) {
    return 0;
  }

  try {
    return calculateDaysSinceStatusUpdated(dateValue);
  } catch (error) {
    console.error('Error calculating days since status updated:', error);
    return 0;
  }
};
