export const YesOrNo = ['Yes', 'No'];
export const ValueType = {
  DateTimeType: 0,
  IntType: 1,
  FloatType: 2,
};

export const vendorSearchInfo = (vendors) => {
  return {
    searchingColumns: [
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'companyName', title: 'Company Name' },
      { name: 'city', title: 'City' },
      { name: 'state', title: 'State' },
      { name: 'phone', title: 'Company Phone' },
      { name: 'email', title: 'Company Email' },
    ],
    searchingColumnExtensions: [
      { columnName: 'vendorId', width: 150 },
      { columnName: 'companyName', width: 250 },
      { name: 'city', width: 175 },
      { name: 'state', width: 175 },
      { columnName: 'phone', width: 175 },
      { columnName: 'email', width: 175 },
    ],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName',
  };
};

export const vendorContactSearchInfo = (vendorContacts) => {
  return {
    searchingColumns: [
      { name: 'vendorContactId', title: 'Vendor Contact Id' },
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'fullName', title: 'Full Name' },
      { name: 'officePhone', title: 'Contact Phone' },
      { name: 'officeEmail', title: 'Contact Email' },
    ],
    searchingColumnExtensions: [
      { columnName: 'vendorContactId', width: 150 },
      { columnName: 'vendorId', width: 150 },
      { columnName: 'fullName', width: 200 },
      { columnName: 'officePhone', width: 175 },
      { columnName: 'officeEmail', width: 175 },
    ],
    searchingRows: vendorContacts,
    idKey: 'vendorContactId',
    nameKey: 'fullName',
  };
};

//Not to sure why all my form fields are being sent as strings, so we will just parse them all out...
export const parseFormValue = (value, valueType) => {
  if (!value || value === '') return null;

  switch (valueType) {
    case ValueType.DateTimeType:
      return new Date(value).toISOString(); 
    case ValueType.IntType:
      return parseInt(value);
    case ValueType.FloatType:
      return parseFloat(value);
    default:
      console.log('Invalid Type');
      return null;
  }
};
