/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import TaxAssistance from './taxAssistance.component';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../layout/types';

import {
  getAuthClientPolicies,
  getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress, getEmployeeInfo, getFamilyMembers, getFamilyPets, getServices, getTeam, getUserSettings, setAuthId, setLoadingComplete,
} from '../store/actions/authorizations.actions';

import {
  getAuthTaxAssistance, getTaxPoliciesByClientId, postAuthTaxAssistance, updateAuthTaxAssistance,
} from '../store/actions/taxAssistance.actions';

import {
   POST_AUTH_TAX_ASSISTANCE_FAILURE, UPDATE_AUTH_TAX_ASSISTANCE_FAILURE,
} from '../store/types/taxAssistanceTypes';

import {
  COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING,
} from 'styles/theme';
import { fetchAuthDetails, fetchTaxAssistanceDetails, fetchVendors } from '../utils/fetchDataDetailsService';
import { showToast } from 'modules/layout/layout.actions';
import { getVendors } from '../store/actions/dataManagement.actions';


const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  }, 
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const TaxAssistanceView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
   
  });
    
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo = details && details?.authDetails?.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details?.authDetails?.summary.clientName) : null;

  const [isLoading, setIsLoading] = useState(false);

  const updateTaxAssistance = async (values) => {
    setIsLoading(true);
    if (details?.authTaxAssistance?.taxAssistance && details?.authTaxAssistance?.taxAssistance.length !== 0) {
      const resp = await props.updateAuthTaxAssistance(values);
      if (resp.type === UPDATE_AUTH_TAX_ASSISTANCE_FAILURE) {
        props.showToast('Failed to update auth tax assistance info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postAuthTaxAssistance(values);
      if (resp.type === POST_AUTH_TAX_ASSISTANCE_FAILURE) {
        props.showToast('Failed to update auth tax assistance info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  useEffect(() => {
    async function fetchData () {
      const id = props.match.params.id;

      setIsLoading(true);
      props.setAuthId(id);

      await fetchAuthDetails(details, id, props);
      await fetchVendors(props?.vendors, props);
      await fetchTaxAssistanceDetails(details, clientInfo, id, props);

      setIsLoading(false);
      props.setLoadingComplete();
    }

    fetchData();
  }, [clientInfo]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && clientInfo && details?.authTaxAssistance?.clientTaxPolicies &&
          <TaxAssistance updateTaxAssistance={updateTaxAssistance} details={details} clientInfo={clientInfo} clientTaxPolicies={details?.authTaxAssistance?.clientTaxPolicies} id={props.match.params.id} history={props.history} />
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

TaxAssistanceView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, isLoading, vendors } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
    vendors
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getAuthTaxAssistance, 
    getTaxPoliciesByClientId,
    setAuthId,
    setLoadingComplete,
    postAuthTaxAssistance,
    updateAuthTaxAssistance,
    showToast,
    getEmployeeInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getVendors,
    getFamilyMembers,
    getFamilyPets,
  }),
)(TaxAssistanceView);