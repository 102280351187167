/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';

import StorageDates from './storageDates.component';
import StorageDetailsCard from './storageDetailsCard.component';
import StorageVendorInfo from './storageVendorInfo.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const Storage = (props) => {    
  const { details, id, location, segmentId, isReadOnly } = props;

  const segmentInfo = details?.authHouseHoldGoods?.hhgSegments ? details?.authHouseHoldGoods?.hhgSegments.find((s) => s.segmentId === segmentId) : null;

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <StorageVendorInfo segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} />
          <Box sx={{ paddingTop: '16px' }}>
            <StorageDates segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} />
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <StorageDetailsCard segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} />
        </Grid>
      </Grid>
    </Box>
  );

};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(Storage);