
import { POST_AUTH_DATE_SUMMARY_FAILURE, UPDATE_AUTH_DATE_SUMMARY_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postAuthDateSummary, updateAuthDateSummary } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import OnHoldIcon from 'modules/common/onHoldIcon.component';
import React, { useState } from 'react';

const SummaryCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { dateSummary } = props;

  const summaryTabs = [
    { header: 'Summary', order: 1 },
  ];

  const fields = [
    { header: 'Initiation', accessorKey: 'initiation', type: 'date', required: true, value: dateSummary ? dateSummary.initiation : null, tab: 1 },
    { header: 'Last Modified', accessorKey: 'lastModified', type: 'datetime', required: false, value: dateSummary ? dateSummary.lastModified : null, tab: 1, hideInModal: true, underline: true },
    { header: 'Candidate Convo', accessorKey: 'candidateConvo', type: 'date', required: false, value: dateSummary ? dateSummary.candidateConvo : null, tab: 1 },
    { header: 'Init Call Complete', accessorKey: 'initCallComplete', type: 'date', required: false, value: dateSummary ? dateSummary.initCallComplete : null, tab: 1 },
    { header: 'Job Start', accessorKey: 'jobStart', type: 'date', required: false, value: dateSummary ? dateSummary.jobStart : null, tab: 1 },
    { header: 'On Hold Date', accessorKey: 'onHoldDate', type: 'date', required: false, value: dateSummary ? dateSummary.onHoldDate : null, tab: 1, showFloatingIcon: dateSummary && dateSummary.onHoldDate != null, floatingIcon: OnHoldIcon },
    { header: 'Cancelled', accessorKey: 'cancelled', type: 'date', required: false, value: dateSummary ? dateSummary.cancelled : null, tab: 1 },
    { header: 'Completed', accessorKey: 'completed', type: 'date', required: false, value: dateSummary ? dateSummary.completed : null, tab: 1 },
    { dividerLine: true },
    { header: 'File Type', accessorKey: 'fileType', type: 'notes', showBelow: true, required: false, value: dateSummary ? dateSummary.fileType : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;
    if (values.initiation != null && values.initiation.length === 0) values.initiation = null;
    if (values.candidateConvo != null && values.candidateConvo.length === 0) values.candidateConvo = null;
    if (values.initCallComplete != null && values.initCallComplete.length === 0) values.initCallComplete = null;
    if (values.jobStart != null && values.jobStart.length === 0) values.jobStart = null;
    if (values.onHoldDate != null && values.onHoldDate.length === 0) values.onHoldDate = null;
    if (values.cancelled != null && values.cancelled.length === 0) values.cancelled = null;
    if (values.completed != null && values.completed.length === 0) values.completed = null;
    values.lastModified = new Date();

    if (dateSummary && dateSummary.length !== 0) {
      const resp = await props.updateAuthDateSummary(values);
      if (resp.type === UPDATE_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to update date summary info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postAuthDateSummary(values);
      if (resp.type === POST_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to update date summary info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={summaryTabs}
      summaries={fields}
      numColumns={2} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    postAuthDateSummary,
    updateAuthDateSummary,
    showToast,
  }),
)(SummaryCard);