/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Card, CardContent, Grid, MenuItem, Select, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { getExpenseLinesByAuthId, getExpenseLinesBySummaryId, getExpenseSummariesByAuth, postExpenseLine, postMultipleExpenseLines, updateExpenseLine, updateExpenseSummary } from 'modules/expenses/expenses.actions';
import HouseholdGoods from 'modules/authorizations/details/hhg/householdGoods.component';
import SimilarExpensesTable from 'modules/authorizations/details/expenses/details/similarExpensesTable.component';
import TaxAssistance from 'modules/authorizations/details/taxAssistance.component';
import { GET_AUTH_TAX_ASSISTANCE_FAILURE, GET_TAX_POLICIES_BY_CLIENT_FAILURE } from 'modules/authorizations/store/types/taxAssistanceTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { getAuthTaxAssistance, getTaxPoliciesByClientId } from 'modules/authorizations/store/actions/taxAssistance.actions';
import { updateAuthDestinationServices, getAuthDestinationServices, postAuthDestinationServices, getHouseHuntingList, getOfferHistoryList } from 'modules/authorizations/store/actions/destinationService.actions';
import { UPDATE_AUTH_DESTINATION_SERVICES_FAILURE, POST_AUTH_DESTINATION_SERVICES_FAILURE, GET_AUTH_DESTINATION_SERVICES_FAILURE, GET_AUTH_HOUSE_HUNTING_LIST_FAILURE, GET_AUTH_OFFER_HISTORY_LIST_FAILURE } from 'modules/authorizations/store/types/destinationServiceTypes';
import TempLivingView from 'modules/authorizations/details/TemporaryLiving/tempLivingView.container';
import HomesaleServiceComponent from 'modules/authorizations/details/homesale/homesaleService.component';
import GlobalServiceComponent from 'modules/authorizations/details/global/globalService.component';
import DestinationServices from 'modules/authorizations/details/destinationServices/destinationServices.component';
import CandidateServices from 'modules/authorizations/details/candidateServices/candidateServices.component';
import InternServices from 'modules/authorizations/details/candidateServices/internServices.component';
import { getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress, getEmployeeInfo, getServices, getSOInfo, getTeam, getUserSettings, setAuthId } from 'modules/authorizations/store/actions/authorizations.actions';
import { getHhgSegments } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import { getBrokerAgents, getExpenseCodes, getInspectors, getTitleReps, getVendorContacts, getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import { getAssignmentExtensions, getAssignmentTrips, getGlobalHomeServiceProperties, getGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { getAllAppraisals, getAllListings, getEquity, getHomesaleInfo, getHomesaleResaleOffers, getInspections } from 'modules/authorizations/store/actions/homesale.actions';
import { getCandidateServices, getCandidateTrips, getCostEstimates, getInternServices } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { GET_AUTH_CLIENT_POLICIES_FAILURE, GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SERVICES_FAILURE, GET_AUTH_SUMMARY_FAILURE, GET_AUTH_TEAM_FAILURE, GET_COMMUNICATION_INFO_FAILURE, GET_DEPARTURE_ADDRESS_FAILURE, GET_DESTINATION_ADDRESS_FAILURE, GET_EMPLOYEE_INFO_FAILURE, GET_SO_INFO_FAILURE, GET_USER_SETTINGS_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';
import { GET_HHG_SEGMENTS_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { GET_ALL_BROKER_AGENTS_FAILURE, GET_EXPENSE_CODES_FAILURE, GET_INSPECTORS_FAILURE, GET_TITLE_REP_FAILURE, GET_VENDOR_CONTACT_FAILURE, GET_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { GET_ASSIGNMENT_EXTENSIONS_FAILURE, GET_ASSIGNMENT_TRIPS_FAILURE, GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, GET_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { GET_APPRAISALS_FAILURE, GET_EQUITY_FAILURE, GET_HOMESALE_INFO_FAILURE, GET_HOMESALE_RESALE_OFFER_FAILURE, GET_INSPECTIONS_FAILURE, GET_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { GET_ALL_CANDIDATE_TRIPS_FAILURE, GET_CANDIDATE_SERVICES_FAILURE, GET_COST_ESTIMATES_FAILURE, GET_INTERN_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { GET_EXPENSE_LINES_BY_AUTH_FAILURE, GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { GET_CLIENTS_FAILURE } from 'modules/clients/types';
import { getClients } from 'modules/clients/clients.actions';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

const createFilePreviewOptions = (authServices, moveType) => {
  let previewOptions = ['Similar Expenses'];

  if (authServices?.taxAssistance === 'Completed' || authServices?.taxAssistance === 'Initiated') {
    previewOptions.push('Tax Assistance');
  }
  if (authServices?.homesale === 'Completed' || authServices?.homesale === 'Initiated') {
    previewOptions.push('Homesale');
  }
  if (authServices?.global === 'Completed' || authServices?.global === 'Initiated') {
    previewOptions.push('Global');
  }
  if (authServices?.temporaryLiving === 'Completed' || authServices?.temporaryLiving === 'Initiated') {
    previewOptions.push('Temporary Living');
  }
  if (authServices?.householdGoods === 'Completed' || authServices?.householdGoods === 'Initiated') {
    previewOptions.push('HHG');
  }
  if (authServices?.homefindRenter === 'Completed' || authServices?.homefindRenter === 'Initiated') {
    previewOptions.push('Destination Services');
  }
  if (
    authServices?.homefindBuyer === 'Completed' ||
    authServices?.homefindBuyer === 'Initiated' ||
    moveType === 'Candidate' || moveType === 'Intern'
  ) {
    previewOptions.push('Candidate Services');
  }

  return previewOptions;
};


const DynamicFilePreviewCard = (props) => {
  const { location, vendors, inspectors, titleReps, brokerAgents, summaryExpenseLines, authOptions } = props;
  
  const [authId, setAuthId] = useState(null);
  const [details, setDetails] = useState(null);

  const authServices = details?.authDetails?.authServices;
  const moveType = details?.authDetails?.summary?.moveType ?? '';

  const [isLoading, setIsLoading] = useState(false);
  const [contentToPreview, setContentToPreview] = useState('Similar Expenses');
  const [householdGoodsLoading, setHouseholdGoodsLoading] = useState(false);
  const [clientTaxPolicies, setClientTaxPolicies] = useState(null);

  const previewOptions = createFilePreviewOptions(authServices, moveType);

  const clientInfo = details && details?.authDetails?.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details?.authDetails?.summary.clientName) : null;

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });  

  const getTaxAssistanceData = async () => {
    setIsLoading(true); 

    if(!details?.authTaxAssistance?.taxAssistance) {
      let taxAssistanceResp = await props.getAuthTaxAssistance(authId);
      if (taxAssistanceResp.type === GET_AUTH_TAX_ASSISTANCE_FAILURE) {
        this.props.showToast('Failed to retrieve tax policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if(!clientTaxPolicies){
      let { type, response } = await props.getTaxPoliciesByClientId(clientInfo.id);
      if (type === GET_TAX_POLICIES_BY_CLIENT_FAILURE) {
        this.props.showToast('Failed to retrieve client tax policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        setClientTaxPolicies(response);
      }
    }

    setIsLoading(false);
  };

  const updateDestinationService = async (values) => {
    setIsLoading(true);
    if (details.destinationServices && details.destinationServices.length !== 0) {
      const resp = await props.updateAuthDestinationServices(values);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postAuthDestinationServices(values);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const makeRequests = async (newAuthId) => {
    setIsLoading(true);

    await props.setAuthId(newAuthId);

    const [authSummaryResp, employeeInfoResp, soInfoResp, authDateSummaryResp, communicationInfoResp, departureResp, destinationResp, authClientPoliciesResp,
        teamResp, authServiceResp, userSettingsResp, hhgSegmentsResp, vendorsResp, vendorContactsResp, globalInfoResp,
      assignmentTripsResp, assignmentExtensionsResp, homeSaleInfoResp, appraisalsResp, inspectorsResp,
    allListingsResp, titleRepsResp, homeSaleResaleOfferResp, equityResp, globalHomeServicePropertiesResp, destinationServicesResp, houseHuntingResp, 
    offerHistoryResp, candidateServicesResp, internServicesResp, candidateTripsResp, costEstimatesResp,
    expenseSummariesResp, expenseLinesByAuthIdResp, expenseCodesResp, clientsResp, brokerAgentsResp, inspectionsResp ] = await Promise.all([
        props.getAuthorizationSummary(newAuthId),
        props.getEmployeeInfo(newAuthId),
        props.getSOInfo(newAuthId),
        props.getAuthDateSummary(newAuthId),
        props.getCommunicationInfo(newAuthId),
        props.getDepartureAddress(newAuthId),
        props.getDestinationAddress(newAuthId),
        props.getAuthClientPolicies(newAuthId),
        props.getTeam(newAuthId),
        props.getServices(newAuthId),
        props.getUserSettings(newAuthId),
        props.getHhgSegments(newAuthId),
        props.getVendors(),
        props.getVendorContacts(),
        props.getGlobalInfo(newAuthId),
        props.getAssignmentTrips(newAuthId),
        props.getAssignmentExtensions(newAuthId),
        props.getHomesaleInfo(newAuthId),
        props.getAllAppraisals(newAuthId),
        props.getInspections(newAuthId),
        props.getAllListings(newAuthId),
        props.getTitleReps(newAuthId),
        props.getHomesaleResaleOffers(newAuthId),
        props.getEquity(newAuthId),
        props.getGlobalHomeServiceProperties(newAuthId),
        props.getAuthDestinationServices(newAuthId),
        props.getHouseHuntingList(newAuthId),
        props.getOfferHistoryList(newAuthId),
        props.getCandidateServices(newAuthId),
        props.getInternServices(newAuthId),
        props.getCandidateTrips(newAuthId),
        props.getCostEstimates(newAuthId),
        props.getExpenseSummariesByAuth(newAuthId), 
        props.getExpenseLinesByAuthId(newAuthId),
        props.getExpenseCodes(), 
        props.getClients(),
        props.getBrokerAgents(),
        props.getInspectors(),
      ]);

      setIsLoading(false);

      if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
  
      if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
        props.showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
  
      if (soInfoResp.type === GET_SO_INFO_FAILURE) {
        props.showToast('Failed to retrieve significant other info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
          
      if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (communicationInfoResp.type === GET_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to retrieve employee communication info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (departureResp.type === GET_DEPARTURE_ADDRESS_FAILURE) {
        props.showToast('Failed to retrieve departure address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (destinationResp.type === GET_DESTINATION_ADDRESS_FAILURE) {
        props.showToast('Failed to retrieve destination address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
        props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (teamResp.type === GET_AUTH_TEAM_FAILURE) {
        props.showToast('Failed to retrieve auth team, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authServiceResp.type === GET_AUTH_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve auth services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
        props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (userSettingsResp.type === GET_USER_SETTINGS_FAILURE) {
        props.showToast('Failed to retrieve employee settings info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (hhgSegmentsResp.type === GET_HHG_SEGMENTS_FAILURE) {
        props.showToast('Failed to retrieve hhg segments info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (vendorsResp.type === GET_VENDORS_FAILURE) {
        props.showToast('Failed to retrieve vendor info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (vendorContactsResp.type === GET_VENDOR_CONTACT_FAILURE) {
        props.showToast('Failed to retrieve vendor contact info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
      
      if (globalInfoResp.type === GET_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to retrieve global info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (assignmentTripsResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        props.showToast('Failed to retrieve assignment trips info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (assignmentExtensionsResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
        props.showToast('Failed to retrieve assignment extensions, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (homeSaleInfoResp.type === GET_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to retrieve homesale info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (appraisalsResp.type === GET_APPRAISALS_FAILURE) {
        props.showToast('Failed to retrieve appraisals, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (inspectorsResp.type === GET_INSPECTORS_FAILURE) {
        props.showToast('Failed to retrieve inspectiors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (allListingsResp.type === GET_LISTINGS_FAILURE) {
        props.showToast('Failed to retrieve listings, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (titleRepsResp.type === GET_TITLE_REP_FAILURE) {
        props.showToast('Failed to retrieve title reps please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (homeSaleResaleOfferResp.type === GET_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to retrieve homesale resale offers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (equityResp.type === GET_EQUITY_FAILURE) {
        props.showToast('Failed to retrieve equity, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (globalHomeServicePropertiesResp.type === GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
        props.showToast('Failed to retrieve global home service properties, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (destinationServicesResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve destination services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (houseHuntingResp.type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
        props.showToast('Failed to retrieve house hunting list, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (offerHistoryResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
        props.showToast('Failed to retrieve offer history list, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
      
      if (candidateServicesResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve candidate services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (internServicesResp.type === GET_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve intern services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (candidateTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
        props.showToast('Failed to retrieve candidate trips, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (costEstimatesResp.type === GET_COST_ESTIMATES_FAILURE) {
        props.showToast('Failed to retrieve cost estimates, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseSummariesResp.type === GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE) {
        showToast('Failed to retrieve submitted expenses, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return; 
      }

      if (expenseLinesByAuthIdResp.type === GET_EXPENSE_LINES_BY_AUTH_FAILURE) {
        props.showToast('Failed to retrieve expense lines by auth id, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseCodesResp.type === GET_EXPENSE_CODES_FAILURE) {
        showToast('Failed to retrieve expense codes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (clientsResp.type === GET_CLIENTS_FAILURE) {
        showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }

      if (brokerAgentsResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
        props.showToast('Failed to retrieve broker/agents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (inspectionsResp.type === GET_INSPECTIONS_FAILURE) {
        props.showToast('Failed to retrieve inspections, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
  }

  useEffect(() => {
    if (contentToPreview === 'Tax Assistance' && (!details?.authTaxAssistance?.taxAssistance || !clientTaxPolicies)) {
      getTaxAssistanceData();
    }
  }, [contentToPreview]);

  useEffect(() => {
    setDetails(props.authorizationDetails && authId ? props.authorizationDetails[authId] : null);
  }, [props.authorizationDetails]);

  useEffect(() => {
    if (props.authId) {
        setAuthId(props.authId);
        makeRequests(props.authId);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between">
                <Box sx={{display: 'flex'}}>
                  <Tooltip title="Estimates" key="Estimates" placement="right">
                    <FontAwesomeIcon icon={['fas', 'file-contract']} size="2x" />
                  </Tooltip>
                  <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                    File Preview
                  </Typography>
                </Box>
                {authOptions && (
                    <Box sx={{display: 'flex'}}>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingTop: '16px' }}>
                                Authorization
                            </Typography>
                        </div>
                        <Select
                            labelId={'authSelected'}
                            key={'authSelected'}
                            label={''}
                            name={'authSelected'}
                            value={authId}
                            sx={{ maxWidth: '360px', minWidth: '180px' }}
                            onChange={(e) => {
                                setContentToPreview('Similar Expenses');
                                setAuthId(e.target.value);
                                makeRequests(e.target.value);
                            }}
                        >
                            {authOptions.map((opt) => (
                                <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                                    {opt}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                )}
                <Box sx={{display: 'flex'}}>
                    <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingTop: '16px' }}>
                            {!authOptions && details && details?.authDetails?.summary && `${details?.authDetails?.summary.transfereeName} (${authId}) -`} Content To Preview
                        </Typography>
                    </div>
                    <Select
                        labelId={'contentToPreview'}
                        key={'contentToPreview'}
                        label={''}
                        name={'contentToPreview'}
                        value={contentToPreview}
                        sx={{ maxWidth: '360px', minWidth: '180px' }}
                        onChange={(e) => {
                            setIsLoading(false);
                            setHouseholdGoodsLoading(false);                        
                            setContentToPreview(e.target.value)
                        }}
                    >
                        {previewOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                                {opt}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: '#41B7C0', fontWeight: 500, fontSize: '1rem', paddingTop: '20px'}}>View Attachment</Typography>
                </Box>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
            <Grid container spacing={1}>
            <Grid item xs={12}>
                {authId && (
                    <Box>
                        {(contentToPreview === 'HHG') && !householdGoodsLoading &&
                        <HouseholdGoods details={details} clientInfo={clientInfo} id={authId} history={props.history} location={location} isReadOnly={true} />
                        }
                        {(contentToPreview === 'Similar Expenses') && !isLoading && details &&
                        <SimilarExpensesTable details={details} authId={authId} history={props.history} location={location} summaryExpenseLines={summaryExpenseLines} />
                        }
                        {(contentToPreview === 'Tax Assistance') && !isLoading && details && clientInfo && clientTaxPolicies &&
                        <TaxAssistance isReadOnly={true} details={details} clientInfo={clientInfo} clientTaxPolicies={clientTaxPolicies} id={authId} history={props.history} />
                        }
                        {(contentToPreview === 'Temporary Living') && <TempLivingView match={{params: { id: authId }}} isReadOnly={true}/>}
                        {(contentToPreview === 'Homesale') && !isLoading && details && clientInfo &&
                        <HomesaleServiceComponent
                            isReadOnly={true}
                            details={details}
                            clientInfo={clientInfo}
                            id={authId} 
                            history={props.history} 
                            brokerAgents={brokerAgents}
                            vendors={vendors}
                            inspectors={inspectors}
                            titleReps={titleReps}
                            location={location} 
                            />
                        }
                        {(contentToPreview === 'Global') && !isLoading && details && clientInfo &&
                        <GlobalServiceComponent
                            isReadOnly={true}
                            details={details}
                            clientInfo={clientInfo}
                            id={authId}
                            history={props.history}
                            location={location}
                            />
                        }
                        {(contentToPreview === 'Destination Services') && <DestinationServices updateAuthDestinationServices={updateDestinationService} details={details} brokerAgents={brokerAgents} id={authId} history={props.history} location={location} isReadOnly={true} />}
                        {(contentToPreview === 'Candidate Services') && 
                        <Grid>
                            <CandidateServices details={details} id={authId} brokerAgents={brokerAgents} history={props.history} location={location} isReadOnly={true} />
                            <InternServices details={details} id={authId} brokerAgents={brokerAgents} history={props.history} location={location} isReadOnly={true} />
                        </Grid>
                        }

                        {(isLoading || !details) && <Spinner />}
                    </Box>
                )}
                {!authId && (
                    <Box>Select an AuthID above to see preview content.</Box>
                )}
                
                </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );

};

const mapStateToProps = state => {
    const { authorizationDetails, isLoading, currentAuthId, expenseCodes, brokerAgents, vendors, inspectors, titleReps } = state.authorizations;
    const { unmappedClients } = state.clients;
    return {
      authorizationDetails,
      isLoading,
      currentAuthId,
      expenseCodes,
      unmappedClients,
      brokerAgents,
      vendors,
      inspectors,
      titleReps
    };
};

export default compose(
  connect(mapStateToProps, {
    updateExpenseSummary,
    postExpenseLine,
    updateExpenseLine,
    getExpenseLinesBySummaryId,
    postMultipleExpenseLines,
    showToast,
    getAuthTaxAssistance,
    getTaxPoliciesByClientId,
    updateAuthDestinationServices,
    postAuthDestinationServices,
    getAuthDestinationServices,
    getAuthorizationSummary,
    getEmployeeInfo,
    getSOInfo,
    getAuthDateSummary,
    getCommunicationInfo,
    getDepartureAddress,
    getDestinationAddress,
    getAuthClientPolicies,
    getTeam,
    getServices,
    getUserSettings,
    getHhgSegments,
    getVendors,
    getVendorContacts,
    getGlobalInfo,
    getAssignmentTrips,
    getAssignmentExtensions,
    getHomesaleInfo,
    getAllAppraisals,
    getInspections,
    getAllListings,
    getTitleReps,
    getHomesaleResaleOffers,
    getEquity,
    getGlobalHomeServiceProperties,
    getHouseHuntingList,
    getOfferHistoryList,
    getCandidateServices,
    getInternServices,
    getCandidateTrips,
    getCostEstimates,
    setAuthId,
    getExpenseSummariesByAuth,
    getExpenseLinesByAuthId,
    getExpenseCodes,
    getClients,
    getBrokerAgents,
    getInspectors,
  }),
)(DynamicFilePreviewCard);