import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles  } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ClientPoliciesImportantNotesTable from './dataTables/importantNotesTable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

const styles = (theme) => ({
  accordionStyle: {
    maxWidth: 1050,
  },
});

const ImportantNotes = (props) => {
  const [importantNotes, setImportantNotes] = useState(false);
  const theme = useTheme();
  
  return (
    <Accordion expanded={importantNotes} onChange={()=> {setImportantNotes(!importantNotes);}} classes={{ root: classNames(props.classes.accordionStyle) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <h2>Important Notes</h2>
      </AccordionSummary>
      <AccordionDetails>
        {props.clientPoliciesImportantNotes && <ClientPoliciesImportantNotesTable clientId = {props.clientId} /> }
      </AccordionDetails>
    </Accordion>
  );  
};

ImportantNotes.propTypes = {
  clientId: PropTypes.number,
};


const mapStateToProps = (state) => {
  const { clientPoliciesImportantNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  return {
    clientPoliciesImportantNotes,
  };
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, { }),
)(ImportantNotes);