import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const AgentsCard = (props) => {

  const { segmentInfo, vendors, vendorContacts } = props;

  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    { header: 'Booking Agent', order: 1, iconPath: ['fas', 'book'] },
    { header: 'Local Agent', order: 2, iconPath: ['fas', 'phone'] },
    { header: 'Car Carrier', order: 3, iconPath: ['fas', 'car'] },
  ];

  const vendorSearchInfo = {
    searchingColumns: [{ name: 'vendorId', title: 'Vendor Id' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }, { name: 'phone', title: 'Company Phone' }, { name: 'email', title: 'Company Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorId', width: 150 }, { columnName: 'companyName', width: 250 }, { name: 'city', width: 175 }, { name: 'state', width: 175 }, { columnName: 'phone', width: 175 }, { columnName: 'email', width: 175 }],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName',
  };

  const vendorContactSearchInfo = {
    searchingColumns: [{ name: 'vendorContactId', title: 'Vendor Contact Id' }, { name: 'vendorId', title: 'Vendor Id' }, { name: 'fullName', title: 'Full Name' }, { name: 'officePhone', title: 'Contact Phone' }, { name: 'officeEmail', title: 'Contact Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorContactId', width: 150 }, { columnName: 'vendorId', width: 150 }, { columnName: 'fullName', width: 200 }, { columnName: 'officePhone', width: 175 }, { columnName: 'officeEmail', width: 175 }],
    searchingRows: vendorContacts,
    idKey: 'vendorContactId',
    nameKey: 'fullName',
  };

  const summaries = [
    { header: 'Vendor Name', accessorKey: 'bookingAgentId', nameKey: 'bookingAgentCompanyName', nameValue: segmentInfo.bookingAgentCompanyName, type: 'advanced-search', searchInfo: vendorSearchInfo, required: true,  value: segmentInfo ? segmentInfo.bookingAgentId : '', tab: 1, hideInTable: true },
    { header: 'Vendor Contact', accessorKey: 'bookingAgentContactId', nameKey: 'bookingAgentContactName', nameValue: segmentInfo.bookingAgentContactName,  type: 'advanced-search', searchInfo: vendorContactSearchInfo, required: true,  value: segmentInfo ? segmentInfo.bookingAgentContactId : '', tab: 1, hideInTable: true },
    { header: 'Company Name', accessorKey: 'bookingAgentCompanyName', type: 'text',  required: false, value: segmentInfo ? segmentInfo.bookingAgentCompanyName : '', tab: 1, hideInModal: true },
    { header: 'Company Phone', accessorKey: 'bookingAgentPhone', type: 'text', required: false, value: segmentInfo ? segmentInfo.bookingAgentPhone : '', tab: 1, hideInModal: true },
    { header: 'Company Email', accessorKey: 'bookingAgentEmail', type: 'text', required: false, value: segmentInfo ? segmentInfo.bookingAgentEmail : '', tab: 1, hideInModal: true },
    { header: 'Contact Name', accessorKey: 'bookingAgentContactName', type: 'text', required: false,  value: segmentInfo ? segmentInfo.bookingAgentContactName : '', tab: 1, hideInModal: true },
    { header: 'Contact Email', accessorKey: 'bookingAgentContactEmail', type: 'text', required: false,  value: segmentInfo ? segmentInfo.bookingAgentContactEmail : '', tab: 1, hideInModal: true },

    { header: 'Vendor Name', accessorKey: 'localAgentId', nameKey: 'localAgentCompanyName', nameValue: segmentInfo.localAgentCompanyName, type: 'advanced-search', searchInfo: vendorSearchInfo, required: true,  value: segmentInfo ? segmentInfo.localAgentId : '', tab: 2, hideInTable: true },
    { header: 'Vendor Contact', accessorKey: 'localAgentContactId', nameKey: 'localAgentContactName', nameValue: segmentInfo.localAgentContactName,  type: 'advanced-search', searchInfo: vendorContactSearchInfo, required: true,  value: segmentInfo ? segmentInfo.localAgentContactId : '', tab: 2, hideInTable: true },
    { header: 'Company Name', accessorKey: 'localAgentCompanyName', type: 'text',  required: false, value: segmentInfo ? segmentInfo.localAgentCompanyName : '', tab: 2, hideInModal: true },
    { header: 'Company Phone', accessorKey: 'localAgentPhone', type: 'text', required: false, value: segmentInfo ? segmentInfo.localAgentPhone : '', tab: 2, hideInModal: true },
    { header: 'Company Email', accessorKey: 'localAgentEmail', type: 'text', required: false, value: segmentInfo ? segmentInfo.localAgentEmail : '', tab: 2, hideInModal: true },
    { header: 'Contact Name', accessorKey: 'localAgentContactName', type: 'text', required: false,  value: segmentInfo ? segmentInfo.localAgentContactName : '', tab: 2, hideInModal: true },
    { header: 'Contact Email', accessorKey: 'localAgentContactEmail', type: 'text', required: false,  value: segmentInfo ? segmentInfo.localAgentContactEmail : '', tab: 2, hideInModal: true },

    { header: 'Vendor Name', accessorKey: 'carCarrierId', nameKey: 'carCarrierCompanyName', nameValue: segmentInfo.carCarrierCompanyName, type: 'advanced-search', searchInfo: vendorSearchInfo, required: true,  value: segmentInfo ? segmentInfo.carCarrierId : '', tab: 3, hideInTable: true },
    { header: 'Vendor Contact', accessorKey: 'carCarrierContactId', nameKey: 'carCarrierContactName', nameValue: segmentInfo.carCarrierContactName,  type: 'advanced-search', searchInfo: vendorContactSearchInfo, required: true,  value: segmentInfo ? segmentInfo.carCarrierContactId : '', tab: 3, hideInTable: true },
    { header: 'Company Name', accessorKey: 'carCarrierCompanyName', type: 'text',  required: false, value: segmentInfo ? segmentInfo.carCarrierCompanyName : '', tab: 3, hideInModal: true },
    { header: 'Company Phone', accessorKey: 'carCarrierPhone', type: 'text', required: false, value: segmentInfo ? segmentInfo.carCarrierPhone : '', tab: 3, hideInModal: true },
    { header: 'Company Email', accessorKey: 'carCarrierEmail', type: 'text', required: false, value: segmentInfo ? segmentInfo.carCarrierEmail : '', tab: 3, hideInModal: true },
    { header: 'Contact Name', accessorKey: 'carCarrierContactName', type: 'text', required: false,  value: segmentInfo ? segmentInfo.carCarrierContactName : '', tab: 3, hideInModal: true },
    { header: 'Contact Email', accessorKey: 'carCarrierContactEmail', type: 'text', required: false,  value: segmentInfo ? segmentInfo.carCarrierContactEmail : '', tab: 3, hideInModal: true },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };
    
    // Update Booking Agent
    if (tab === 1 && values.bookingAgentId != null) {
      const newVendor = vendors.find((v) => v.vendorId === values.bookingAgentId);
      newValues.bookingAgentId = newVendor.vendorId;
      newValues.bookingAgentCompanyName = newVendor.companyName;
      newValues.bookingAgentPhone = newVendor.phone;
      newValues.bookingAgentEmail = newVendor.email;
    }

    // Update Booking Agent Contact
    if (tab === 1 && values.bookingAgentContactId != null) {
      const newVendorContact = vendorContacts.find((v) => v.vendorContactId === values.bookingAgentContactId);
      newValues.bookingAgentContactId = newVendorContact.vendorContactId;
      newValues.bookingAgentContactName = newVendorContact.fullName;
      newValues.bookingAgentContactEmail = newVendorContact.officeEmail;
    }

    // Update Local Agent
    if (tab === 2 && values.localAgentId != null) {
      const newVendor = vendors.find((v) => v.vendorId === values.localAgentId);
      newValues.localAgentId = newVendor.vendorId;
      newValues.localAgentCompanyName = newVendor.companyName;
      newValues.localAgentPhone = newVendor.phone;
      newValues.localAgentEmail = newVendor.email;
    }

    // Update Local Agent Contact
    if (tab === 2 && values.localAgentContactId != null) {
      const newVendorContact = vendorContacts.find((v) => v.vendorContactId === values.localAgentContactId);
      newValues.localAgentContactId = newVendorContact.vendorContactId;
      newValues.localAgentContactName = newVendorContact.fullName;
      newValues.localAgentContactEmail = newVendorContact.officeEmail;
    }

    // Update Car Carrier
    if (tab === 3 && values.carCarrierId != null) {
      const newVendor = vendors.find((v) => v.vendorId === values.carCarrierId);
      newValues.carCarrierId = newVendor.vendorId;
      newValues.carCarrierCompanyName = newVendor.companyName;
      newValues.carCarrierPhone = newVendor.phone;
      newValues.carCarrierEmail = newVendor.email;
    }

    // Update Car Carrier Contact
    if (tab === 3 && values.carCarrierContactId != null) {
      const newVendorContact = vendorContacts.find((v) => v.vendorContactId === values.carCarrierContactId);
      newValues.carCarrierContactId = newVendorContact.vendorContactId;
      newValues.carCarrierContactName = newVendorContact.fullName;
      newValues.carCarrierContactEmail = newVendorContact.officeEmail;
    }
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the vendor info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
      smallerModal={true}
    />
  );

};

const mapStateToProps = (state) => {
  const {
    authorizations: { vendors, vendorContacts },
  } = state;
  return { vendors, vendorContacts };
};

export default compose(
  connect(mapStateToProps, {
    updateHhgSegment,
    showToast,
  }),
)(AgentsCard);