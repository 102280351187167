const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const {
  GET_ALL_HOMESALE_RESALE_OFFER_REQUEST,
  GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS,
  GET_ALL_HOMESALE_RESALE_OFFER_FAILURE,
  GET_HOMESALE_RESALE_OFFER_REQUEST,
  GET_HOMESALE_RESALE_OFFER_SUCCESS,
  GET_HOMESALE_RESALE_OFFER_FAILURE,
  POST_HOMESALE_RESALE_OFFER_REQUEST,
  POST_HOMESALE_RESALE_OFFER_SUCCESS,
  POST_HOMESALE_RESALE_OFFER_FAILURE,
  UPDATE_HOMESALE_RESALE_OFFER_REQUEST,
  UPDATE_HOMESALE_RESALE_OFFER_SUCCESS,
  UPDATE_HOMESALE_RESALE_OFFER_FAILURE,
  DELETE_HOMESALE_RESALE_OFFER_REQUEST,
  DELETE_HOMESALE_RESALE_OFFER_SUCCESS,
  DELETE_HOMESALE_RESALE_OFFER_FAILURE,
  GET_INSPECTIONS_REQUEST,
  GET_INSPECTIONS_SUCCESS,
  GET_INSPECTIONS_FAILURE,
  POST_INSPECTIONS_REQUEST,
  POST_INSPECTIONS_SUCCESS,
  POST_INSPECTIONS_FAILURE,
  UPDATE_INSPECTIONS_REQUEST,
  UPDATE_INSPECTIONS_SUCCESS,
  UPDATE_INSPECTIONS_FAILURE,
  DELETE_INSPECTIONS_REQUEST,
  DELETE_INSPECTIONS_FAILURE,
  UPDATE_INSPECTIONS_STATE,
  GET_EQUITY_REQUEST,
  GET_EQUITY_SUCCESS,
  GET_EQUITY_FAILURE,
  POST_EQUITY_REQUEST,
  POST_EQUITY_SUCCESS,
  POST_EQUITY_FAILURE,
  UPDATE_EQUITY_REQUEST,
  UPDATE_EQUITY_SUCCESS,
  UPDATE_EQUITY_FAILURE,
  DELETE_EQUITY_REQUEST,
  DELETE_EQUITY_SUCCESS,
  DELETE_EQUITY_FAILURE,
  GET_TAX_REQUEST,
  GET_TAX_SUCCESS,
  GET_TAX_FAILURE,
  POST_TAX_REQUEST,
  POST_TAX_SUCCESS,
  POST_TAX_FAILURE,
  UPDATE_TAX_REQUEST,
  UPDATE_TAX_SUCCESS,
  UPDATE_TAX_FAILURE,
  DELETE_TAX_REQUEST,
  DELETE_TAX_SUCCESS,
  DELETE_TAX_FAILURE,
  GET_CREDIT_REQUEST,
  GET_CREDIT_SUCCESS,
  GET_CREDIT_FAILURE,
  POST_CREDIT_REQUEST,
  POST_CREDIT_SUCCESS,
  POST_CREDIT_FAILURE,
  UPDATE_CREDIT_REQUEST,
  UPDATE_CREDIT_SUCCESS,
  UPDATE_CREDIT_FAILURE,
  DELETE_CREDIT_REQUEST,
  DELETE_CREDIT_SUCCESS,
  DELETE_CREDIT_FAILURE,
  GET_APPRAISALS_REQUEST,
  GET_APPRAISALS_SUCCESS,
  GET_APPRAISALS_FAILURE,
  POST_APPRAISALS_REQUEST,
  POST_APPRAISALS_SUCCESS,
  POST_APPRAISALS_FAILURE,
  UPDATE_APPRAISALS_REQUEST,
  UPDATE_APPRAISALS_SUCCESS,
  UPDATE_APPRAISALS_FAILURE,
  GET_LISTINGS_REQUEST,
  GET_LISTINGS_SUCCESS,
  GET_LISTINGS_FAILURE,
  POST_LISTINGS_REQUEST,
  POST_LISTINGS_SUCCESS,
  POST_LISTINGS_FAILURE,
  UPDATE_LISTINGS_REQUEST,
  UPDATE_LISTINGS_SUCCESS,
  UPDATE_LISTINGS_FAILURE,
  GET_HOMESALE_INFO_REQUEST,
  GET_HOMESALE_INFO_SUCCESS,
  GET_HOMESALE_INFO_FAILURE,
  POST_HOMESALE_INFO_REQUEST,
  POST_HOMESALE_INFO_SUCCESS,
  POST_HOMESALE_INFO_FAILURE,
  UPDATE_HOMESALE_INFO_REQUEST,
  UPDATE_HOMESALE_INFO_SUCCESS,
  UPDATE_HOMESALE_INFO_FAILURE,
} = require('../types/homesaleTypes');
const { DELETE_TITLE_REP_REQUEST, DELETE_TITLE_REP_SUCCESS, DELETE_TITLE_REP_FAILURE, UPDATE_TITLE_REP_REQUEST, UPDATE_TITLE_REP_SUCCESS, UPDATE_TITLE_REP_FAILURE, POST_TITLE_REP_REQUEST, POST_TITLE_REP_SUCCESS, POST_TITLE_REP_FAILURE } = require('../types/dataManagementTypes');

export const getHomesaleResaleOffers = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ALL_HOMESALE_RESALE_OFFER_REQUEST, GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS, GET_ALL_HOMESALE_RESALE_OFFER_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/homesaleResale/${id}`,
      method: 'GET',
    },
  };
};

export const getHomesaleResaleOffer = (offerId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_HOMESALE_RESALE_OFFER_REQUEST, GET_HOMESALE_RESALE_OFFER_SUCCESS, GET_HOMESALE_RESALE_OFFER_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/homesaleResale/getById/${offerId}`,
      method: 'GET',
    },
  };
};

export const postHomesaleResaleOffer = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_HOMESALE_RESALE_OFFER_REQUEST, POST_HOMESALE_RESALE_OFFER_SUCCESS, POST_HOMESALE_RESALE_OFFER_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/homesaleResale',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateHomesaleResaleOffer = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_HOMESALE_RESALE_OFFER_REQUEST, UPDATE_HOMESALE_RESALE_OFFER_SUCCESS, UPDATE_HOMESALE_RESALE_OFFER_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/homesaleResale',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteHomesaleResaleOffer = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_HOMESALE_RESALE_OFFER_REQUEST, DELETE_HOMESALE_RESALE_OFFER_SUCCESS, DELETE_HOMESALE_RESALE_OFFER_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/homesaleResale/${id}`,
      method: 'DELETE',
    },
  };
};
export const deleteTitleRep = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_TITLE_REP_REQUEST, DELETE_TITLE_REP_SUCCESS, DELETE_TITLE_REP_FAILURE],
      authenticated: true,
      endpoint: `api/titleRep/${id}`,
      method: 'DELETE',
    },
  };
};
export const updateTitleRep = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_TITLE_REP_REQUEST, UPDATE_TITLE_REP_SUCCESS, UPDATE_TITLE_REP_FAILURE],
      authenticated: true,
      endpoint: 'api/titleRep',
      method: 'PUT',
      payload: values,
    },
  };
};
export const postTitleRep = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_TITLE_REP_REQUEST, POST_TITLE_REP_SUCCESS, POST_TITLE_REP_FAILURE],
      authenticated: true,
      endpoint: 'api/titleRep',
      method: 'POST',
      payload: values,
    },
  };
};
export const getInspections = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_INSPECTIONS_REQUEST, GET_INSPECTIONS_SUCCESS, GET_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/inspections`,
      method: 'GET',
    },
  };
};

export const postInspection = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_INSPECTIONS_REQUEST, POST_INSPECTIONS_SUCCESS, POST_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/inspections/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateInspection = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_INSPECTIONS_REQUEST, UPDATE_INSPECTIONS_SUCCESS, UPDATE_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/inspections/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteInspection = (authId, id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_INSPECTIONS_REQUEST, DELETE_INSPECTIONS_REQUEST, DELETE_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/inspections/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateInspectionState = (inspections) => {
  return { type: UPDATE_INSPECTIONS_STATE, response: inspections };
};

/* Homesale - Equity Requests */

export const getEquity = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EQUITY_REQUEST, GET_EQUITY_SUCCESS, GET_EQUITY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/equity`,
      method: 'GET',
    },
  };
};

export const postEquity = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_EQUITY_REQUEST, POST_EQUITY_SUCCESS, POST_EQUITY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/equity/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateEquity = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_EQUITY_REQUEST, UPDATE_EQUITY_SUCCESS, UPDATE_EQUITY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/equity/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteEquity = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_EQUITY_REQUEST, DELETE_EQUITY_SUCCESS, DELETE_EQUITY_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/equity`,
      method: 'DELETE',
    },
  };
};

export const getTaxes = (equityId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_TAX_REQUEST, GET_TAX_SUCCESS, GET_TAX_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${equityId}/tax`,
      method: 'GET',
    },
  };
};

export const postTax = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_TAX_REQUEST, POST_TAX_SUCCESS, POST_TAX_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/tax/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateTax = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_TAX_REQUEST, UPDATE_TAX_SUCCESS, UPDATE_TAX_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/tax/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteTax = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_TAX_REQUEST, DELETE_TAX_SUCCESS, DELETE_TAX_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/tax`,
      method: 'DELETE',
    },
  };
};

export const getCredits = (equityId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_CREDIT_REQUEST, GET_CREDIT_SUCCESS, GET_CREDIT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${equityId}/credit`,
      method: 'GET',
    },
  };
};

export const postCredit = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_CREDIT_REQUEST, POST_CREDIT_SUCCESS, POST_CREDIT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/credit/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateCredit = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_CREDIT_REQUEST, UPDATE_CREDIT_SUCCESS, UPDATE_CREDIT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/credit/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteCredit = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_CREDIT_REQUEST, DELETE_CREDIT_SUCCESS, DELETE_CREDIT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/credit`,
      method: 'DELETE',
    },
  };
};
/* Homesale - Appraisals Requests */

export const getAllAppraisals = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_APPRAISALS_REQUEST, GET_APPRAISALS_SUCCESS, GET_APPRAISALS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/homesaleAppraisals`,
      method: 'GET',
    },
  };
};

export const postAppraisal = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_APPRAISALS_REQUEST, POST_APPRAISALS_SUCCESS, POST_APPRAISALS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/homesaleAppraisals/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateAppraisal = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_APPRAISALS_REQUEST, UPDATE_APPRAISALS_SUCCESS, UPDATE_APPRAISALS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/homesaleAppraisals/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};
/* Homesale - Listings Requests */

export const getAllListings = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_LISTINGS_REQUEST, GET_LISTINGS_SUCCESS, GET_LISTINGS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/listings`,
      method: 'GET',
    },
  };
};

export const postListing = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_LISTINGS_REQUEST, POST_LISTINGS_SUCCESS, POST_LISTINGS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/listings/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateListing = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_LISTINGS_REQUEST, UPDATE_LISTINGS_SUCCESS, UPDATE_LISTINGS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/listings/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};
/* Homesale Requests */

export const getHomesaleInfo = (authId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_HOMESALE_INFO_REQUEST, GET_HOMESALE_INFO_SUCCESS, GET_HOMESALE_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/homesale`,
      method: 'GET',
    },
  };
};

export const postHomesaleInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_HOMESALE_INFO_REQUEST, POST_HOMESALE_INFO_SUCCESS, POST_HOMESALE_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/homesale/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateHomesaleInfo = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_HOMESALE_INFO_REQUEST, UPDATE_HOMESALE_INFO_SUCCESS, UPDATE_HOMESALE_INFO_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/homesale/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};
