import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import React, { useState } from 'react';

import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Spinner from 'modules/common/spinner.component';
import { deleteVendorDocument, getVendorDocuments, postVendorDocument, updateVendorDocument } from 'modules/authorizations/store/actions/dataManagement.actions';
import { DELETE_VENDOR_DOCUMENT_FAILURE, GET_VENDOR_DOCUMENTS_FAILURE, POST_VENDOR_DOCUMENT_FAILURE, UPDATE_VENDOR_DOCUMENT_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import CrudTable from 'modules/clients/details/crudTable.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const DocumentsCard = (props) => {

  const { hideEdit, vendorId, vendorDocuments } = props;

  const [isLoading, setIsLoading] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'date', header: 'Date', type: 'date', required: false, size: 65, Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-' },
    { accessorKey: 'description', header: 'Description', type: 'text', required: false, size: 65 },
    { accessorKey: 'attachment', header: 'Attachment', type: 'file', required: false, size: 65 },
    { accessorKey: 'notes', header: 'Notes', type: 'text', required: false, size: 65 },
  ];

  const updateRow = async (record, values) => {
    setIsLoading(true);
    values.vendorDocumentId = record.original.vendorDocumentId;
    values.vendorId = vendorId;

    if (values.date && values.date.length === 0) values.date = null;

    const resp = await props.updateVendorDocument(values);
    if (resp.type === UPDATE_VENDOR_DOCUMENT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorDocuments(vendorId);
      if (getResp.type === GET_VENDOR_DOCUMENTS_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const createRow = async (values) => {
    setIsLoading(true);
    values.vendorId = vendorId;

    if (values.date && values.date.length === 0) values.date = null;

    const resp = await props.postVendorDocument(values);
    if (resp.type === POST_VENDOR_DOCUMENT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorDocuments(vendorId);
      if (getResp.type === GET_VENDOR_DOCUMENTS_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const deleteRow = async (record) => {
    setIsLoading(true);
    const id = record.original.vendorDocumentId;
    const resp = await props.deleteVendorDocument(id);
    if (resp.type === DELETE_VENDOR_DOCUMENT_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getVendorDocuments(vendorId);
      if (getResp.type === GET_VENDOR_DOCUMENTS_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={'Moving Dates'} key={'vendorContacts'} placement="right">
            <FontAwesomeIcon icon={['fas', 'file-import']} size="2x" />
          </Tooltip>
          <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
            Documents
          </Typography>
        </Box>
        {!isLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={vendorDocuments ? vendorDocuments : []} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
            customAddText={'Add Document'}
            hideEdit={hideEdit}
          />
        )}
        {isLoading && (
          <Spinner />
        )}
      </CardContent>
    </Card>
  );
}; 

export default compose(
  withStyles(styles),
  withTheme,
  connect(null, {
    getVendorDocuments,
    deleteVendorDocument,
    postVendorDocument,
    updateVendorDocument,
  }),
)(DocumentsCard);