import { POST_GLOBAL_INFO_FAILURE, UPDATE_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

import countries from 'modules/common/countries.json';

const OverviewGlobalCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { globalInfo, id, isReadOnly } = props;

  const basicOptions = [
    { title: 'Yes', id: 1 },
    { title: 'No', id: 2 }
  ];

  const typeOptions = [
    { title: 'Long Term', id: 1 },
    { title: 'Short Term', id: 2 }
  ];

  const tabs = [{ header: 'Overview', order: 1, iconPath: ['fas', 'suitcase'] }];

  const visaStatusOptions = ['Application', 'Approved', 'Denied', 'Expired', 'Filed', 'In Progress', 'Inactive', 'Pending', 'Processing'];

  const summaries = [
    {
      header: 'Type',
      accessorKey: 'type',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.type : '',
      options: typeOptions,
      tab: 1
    },
    {
      header: 'Assignment Country',
      accessorKey: 'country',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.country : '',
      options: countries,
      tab: 1
    },
    {
      header: 'Assignment City',
      accessorKey: 'assignmentCity',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.assignmentCity : '',
      tab: 1
    },
    {
      header: 'Assignment Length',
      accessorKey: 'lengthOfAssignment',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.lengthOfAssignment : '',
      tab: 1
    },
    {
      header: 'Client Assignment Number',
      accessorKey: 'clientNumber',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.clientNumber : '',
      tab: 1
    },
    {
      header: 'Home Country',
      accessorKey: 'homeCountry',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.homeCountry : '',
      options: countries,
      tab: 1
    },
    {
      header: 'Home State',
      accessorKey: 'homeState',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.homeState : '',
      tab: 1
    },
    {
      header: 'Citizenship',
      accessorKey: 'citizenship',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.citizenship : '',
      options: countries,
      tab: 1
    },
    {
      header: 'Pay In Currency',
      accessorKey: 'payInCurrency',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.payInCurrency : '',
      tab: 1
    },
    {
      header: 'Eligible For Tax Service',
      accessorKey: 'eligibleForTaxService',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.eligibleForTaxService : '',
      options: basicOptions,
      tab: 1
    },
    {
      header: 'EE Visa Status',
      accessorKey: 'transfereeVisaStatus',
      type: 'dropdown',
      required: false,
      options: visaStatusOptions,
      value: globalInfo ? globalInfo.transfereeVisaStatus : '',
      tab: 1
    },
    {
      header: 'Trip Cap Amt',
      accessorKey: 'tripCapAmount',
      type: 'number',
      required: false,
      value: globalInfo?.tripCapAmount,
      tab: 1,
    },
    {
      header: 'Max # of Trips',
      accessorKey: 'maxNumberOfTrips',
      type: 'number',
      required: false,
      value: globalInfo?.maxNumberOfTrips,
      tab: 1,
    },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', required: false, value: globalInfo ? globalInfo.notes : '', tab: 1 }
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (globalInfo && globalInfo.length !== 0) {
      let newValues = { ...globalInfo };

      newValues.type = values.type;
      newValues.country = values.country;
      newValues.lengthOfAssignment = values.lengthOfAssignment;
      newValues.clientNumber = values.clientNumber;
      newValues.homeCountry = values.homeCountry;
      newValues.homeState = values.homeState;
      newValues.citizenship = values.citizenship;
      newValues.payInCurrency = values.payInCurrency;
      newValues.eligibleForTaxService = values.eligibleForTaxService;
      newValues.notes = values.notes;
      newValues.assignmentCity = values.assignmentCity;
      newValues.transfereeVisaStatus = values.transfereeVisaStatus;
      newValues.tripCapAmount = values.tripCapAmount;
      newValues.maxNumberOfTrips = values.maxNumberOfTrips;

      const resp = await props.updateGlobalInfo(newValues);
      if (resp.type === UPDATE_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postGlobalInfo(values);
      if (resp.type === POST_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5}
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );
};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast
  })
)(OverviewGlobalCard);
