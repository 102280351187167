import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const ShipmentInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const tabs = [
    { header: 'Shipment Details', order: 1, iconPath: ['fas', 'file-contract'] },
  ];

  const summaries = [
    { header: 'HHG Inventory', accessorKey: 'inventoryFileName', type: 'file', required: false, value: segmentInfo ? segmentInfo.inventoryFileName : '', tab: 1 },
    { header: 'Move Miles', accessorKey: 'moveMiles', type: 'number', required: false, value: segmentInfo ? segmentInfo.moveMiles : '', tab: 1 },
    { header: '# of Vehicles Authorized', accessorKey: 'numVehiclesAuthorized', type: 'number', required: false, value: segmentInfo ? segmentInfo.numVehiclesAuthorized : '', tab: 1 },
    { header: 'Make of Car(s)', accessorKey: 'makeOfCars', type: 'text', required: false, value: segmentInfo ? segmentInfo.makeOfCars : '', tab: 1 },
    { header: 'Model of Car(s)', accessorKey: 'modelOfCars', type: 'text', required: false, value: segmentInfo ? segmentInfo.modelOfCars : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.inventoryFileName = values.inventoryFileName;
    newValues.moveMiles = values.moveMiles;
    newValues.makeOfCars = values.makeOfCars;
    newValues.modelOfCars = values.modelOfCars;
    newValues.numVehiclesAuthorized = values.numVehiclesAuthorized;

        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(ShipmentInfoCard);