/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
  Paper, Typography, Link, Button, MenuItem, Select
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderDetailsCard from './orderDetailsCard.component';
import ProcessDetailsCard from './processDetailsCard.component';
import Spinner from 'modules/common/spinner.component';
import SpreadSelectorCard from './spreadSelectorCard.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const AppraisalBmaDetails = (props) => {    
  const { appraisalId, appraisalsSelected, brokerAgents, details, id, isLoadingEstimates, isReadOnly, orderList, isOrderingList, setAppraisalId, setShowAddAppraiser, setShowAddBroker } = props;

  const appraisalInfo = details?.authHomesale?.appraisalsBmas ? details?.authHomesale?.appraisalsBmas.find((a) => a.appraisalId === appraisalId) : null;

  return (
    <Box>
      {!isReadOnly && appraisalInfo == null && !isLoadingEstimates && (
        <Box sx={{ padding: '20px', fontSize: '20px' }}>
          <b>No {appraisalsSelected ? 'Appraisal' : 'BMA'} selected. Create a new one below to get started.</b>
        </Box>
      )}
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={10} rowSpacing={2}>
            <SpreadSelectorCard appraisalsSelected={appraisalsSelected} id={id} appraisalInfo={appraisalInfo} brokerAgents={brokerAgents} appraisalsBmas={details?.authHomesale?.appraisalsBmas.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1))} homesaleInfo={details?.authHomesale?.homesale} isReadOnly={isReadOnly} />
          </Grid>
        <Grid item xs={12} xl={12}>
          <Paper square>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row'}} />
                <Box />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
                <Select
                  labelId={'appraisalId'}
                  key={'appraisalId'}
                  label={''}
                  name={'appraisalId'}
                  value={appraisalId}
                  sx={{ maxWidth: '360px', minWidth: '180px' }}
                  onChange={(e) => setAppraisalId(e.target.value)}
                >
                  {details?.authHomesale?.appraisalsBmas
                    ?.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1))
                    .map((opt) => (
                      <MenuItem key={opt.appraisalId} value={opt.appraisalId} sx={{ margin: 'dense' }}>
                        {`${opt.appraiserName} (${opt.appraiserCode})`}
                      </MenuItem>
                    ))}
                </Select>
                {!isReadOnly && appraisalsSelected && (
                  <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddAppraiser(true)}>New Appraiser</Button>
                )}
                {!isReadOnly && !appraisalsSelected && (
                  <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddBroker(true)}>New Broker</Button>
                )}
              </Box>
              {!isReadOnly && appraisalsSelected && (
                <Box sx={{paddingLeft: '20px' }}>
                  {details?.authHomesale?.homesale?.appraiserRequestSent && !isOrderingList && (
                    <Typography color="secondary" sx={{ marginTop: '10px' }}>
                      <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Appraiser List request has been sent,
                      <Link onClick={async () => await orderList()} sx={{ cursor: 'pointer' }}>click here</Link> to resend.
                    </Typography>
                  )}
                  {!details?.authHomesale?.homesale?.appraiserRequestSent && !isOrderingList && (
                    <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginTop: '8px' }} onClick={async () => await orderList()}>Request Appraiser List</Button>
                  )}
                  {isOrderingList && <Spinner />}
                </Box>
              )}
              {!isReadOnly && !appraisalsSelected && (
                <Box sx={{paddingLeft: '20px' }}>
                  {details?.authHomesale?.homesale?.brokerRequestSent && !isOrderingList && (
                    <Typography color="secondary" sx={{ marginTop: '10px' }}>
                      <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Broker List request has been sent,
                      <Link onClick={async () => await orderList()} sx={{ cursor: 'pointer' }}>click here</Link> to resend.
                    </Typography>
                  )}
                  {!details?.authHomesale?.homesale?.brokerRequestSent && !isOrderingList && (
                    <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginTop: '8px' }} onClick={async () => await orderList()}>Request Broker List</Button>
                  )}
                  {isOrderingList && <Spinner />}
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
        {appraisalInfo != null && !isLoadingEstimates && (
          <>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <OrderDetailsCard appraisalsSelected={appraisalsSelected} id={id} appraisalInfo={appraisalInfo} brokerAgents={brokerAgents} isReadOnly={isReadOnly} />
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <ProcessDetailsCard appraisalsSelected={appraisalsSelected} id={id} appraisalInfo={appraisalInfo} brokerAgents={brokerAgents} isReadOnly={isReadOnly} />
        </Grid>
        </>
      )}
            </Grid>
      {isLoadingEstimates && <Spinner />}
    </Box>
  );

};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(AppraisalBmaDetails);