import { Box, Card, CardContent, Fab, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import CrudTableCardComponent from '../crudTableCard.component';
import MultiTabEditableCardComponent from './multiTabEditableCard.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SpinnerComponent from '../spinner.component';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
  tabContent: {
    display: 'flex',
    flexGrow: 1,
    padding: '0px',
    margin: '0px',
    height: '100%',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

/*
    - Required Props:
    * tabs - a list of tabs to be included in the card
    * contents - the content for each tab
    * openCurrentTab - the tab to be opened by default
    * 
    * Please view Prop types for structure of tabs and content
*/

const MultiTabCard = ({ tabs, contents, openCurrentTab = 1 }) => {
  const classes = useStyles();
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const [currentTab, setCurrentTab] = useState(1);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  let onlyOneTab = tabs.length === 1;

  useEffect(() => {
    setCurrentTab(openCurrentTab);
  }, [openCurrentTab]);

  const renderTab = (tab) => (
    <Tab
      key={tab.order}
      icon={
        <Box sx={{ paddingRight: '10px' }}>
          <FontAwesomeIcon icon={tab.iconPath} size="1x" />
        </Box>
      }
      iconPosition={'start'}
      value={tab.order}
      label={tab.header}
      disabled={tab?.isDisabled ?? false}
      sx={{
        zIndex: 1,
        minWidth: '265px',
        minHeight: '0px',
        textTransform: 'none',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: 'black',
      }}
    />
  );

  const renderHeader = (header) => (
    <div className="row justify-space-between align-center">
      <div className={classes.iconRow}>
        {header.iconPath && (
          <Tooltip title={header.header} key={header.header} placement="right">
            <FontAwesomeIcon icon={header.iconPath} size="2x" />
          </Tooltip>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingLeft: header.iconPath ? '20px' : '0px',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: '1.3125rem',
              fontWeight: 'bold',
            }}
          >
            {header.header}
          </Typography>
          {header.headerMessage && (
            <Typography
              variant="h5"
              sx={{
                fontSize: '0.875rem',
                color: 'green',
              }}
            >
              {header.headerMessage}
            </Typography>
          )}
        </Box>
      </div>
      {header.contentType === ContentType.EditableCard && !header.hideEdit && (
        <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
          <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
        </Fab>
      )}
    </div>
  );

  const handleChangeTab = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const shouldDisplayEditButton = () => {
    let content =  contents.find((s) => s.tabOrder === currentTab);
    if(!content)
      return false;

    return content?.contentType === ContentType.EditableCard && !content.hideEdit
  };

  const renderContent = (content) => {
    if (content.tabOrder === currentTab) {
      switch (content.contentType) {
        case ContentType.CrudTable:
          return (
            <>
              {content.isLoading ? (
                <SpinnerComponent />
              ) : (
                <CrudTableCardComponent
                  summaries={[
                    {
                      adjustedColumns: content.columns,
                      rows: content.rows,
                      updateRow: content.updateRow,
                      createRow: content.createRow,
                      deleteRow: content.deleteRow,
                      addButtonText: content.customAddText,
                      headerRow: content.headerRow,
                      hideEdit: content.hideEdit
                    },
                  ]}
                  tabs={[{ order: 1, multiTabComponent: true }]}
                  numColumns={content.numColumns}
                  isLoading={content.isLoading}
                  getNameOfDeleteRow={content.getNameOfDeleteRow}
                />
              )}
            </>
          );
        case ContentType.EditableCard:
          return (
            <MultiTabEditableCardComponent
              tabs={[{ order: 1, multiTabComponent: true }]}
              summaries={content.summaries}
              numColumns={content.numColumns}
              updateFields={content.updateFields}
              isLoading={content.isLoading}
              hideEdit={true}
              topLink={content.topLink}
              editingModalOpen={editingModalOpen}
              setEditingModalOpen={setEditingModalOpen}
              myTab={content.tabOrder}
              parentCurrentTab={currentTab}
            />
          );
        default:
          console.log('Invalid content type');
          return null;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ position: 'relative' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            {onlyOneTab ? (
              tabs.map(renderHeader)
            ) : (
              <Box className="row justify-space-between align-center" style={{ position: 'relative' }}>
                <Tabs
                  variant="fullWidth"
                  value={currentTab}
                  onChange={(event, tab) => handleChangeTab(event, tab)}
                  style={{ flex: 0.9 }}
                  sx={{ flexGrow: 1 }}
                >
                  {tabs.map(renderTab)}
                </Tabs>
                {shouldDisplayEditButton() && (
                  <Box sx={{ position: 'absolute', right: '5px' }}>
                    <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                      <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                    </Fab>
                  </Box>
                )}
              </Box>
            )}
          </CardContent>
          <CardContent
            sx={{
              display: 'flex',
              flexGrow: 1,
              padding: '0px',
              margin: '0px',
              height: '100%',
              '&:last-child': {
                paddingBottom: 0,
              },
            }}
          >
            {contents.map(renderContent)}
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );
};

MultiTabCard.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number.isRequired,
      header: PropTypes.string.isRequired,
      contentType: PropTypes.number.isRequired,
      iconPath: PropTypes.array,
      isDisabled: PropTypes.bool,
      headerMessage: PropTypes.string,
      hideEdit: PropTypes.bool, // Editable Card TODO - Do we even want this?
    }),
  ).isRequired,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      contentType: PropTypes.number.isRequired, // Both
      tabOrder: PropTypes.number.isRequired, // Both
      numColumns: PropTypes.number, // Both
      isLoading: PropTypes.bool, // Both
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          accessorKey: PropTypes.string.isRequired,
          header: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          options: PropTypes.array,
          required: PropTypes.bool,
          size: PropTypes.number,
          enableColumnOrdering: PropTypes.bool,
          enableColumnResizing: PropTypes.bool,
          enableEditing: PropTypes.bool,
          enableSorting: PropTypes.bool,
          hideInTable: PropTypes.bool,
        }),
      ), // Crud
      rows: PropTypes.array, // Crud
      updateRow: PropTypes.func, // Crud
      createRow: PropTypes.func, // Crud
      deleteRow: PropTypes.func, // Crud
      getNameOfDeleteRow: PropTypes.func, //Crud
      customAddText: PropTypes.string, // Crud
      headerRow: PropTypes.shape({
        accessorKey: PropTypes.string.isRequired,
        value: PropTypes.any,
        title: PropTypes.string,
      }), //Crud
      summaries: PropTypes.arrayOf(
        PropTypes.shape({
          accessorKey: PropTypes.string.isRequired,
          header: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          options: PropTypes.array,
          value: PropTypes.any,
          required: PropTypes.bool,
          showBelow: PropTypes.bool,
          disabled: PropTypes.bool,
        }),
      ), // Editable Card
      updateFields: PropTypes.func, // Editable Card
      hideEdit: PropTypes.bool, // Editable Card
      topLink: PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        tab: PropTypes.number.isRequired,
      }), // Editable Card
    }),
  ).isRequired,
  openCurrentTab: PropTypes.number,
};

MultiTabCard.defaultProps = {
  openCurrentTab: 1,
};

export const ContentType = {
  CrudTable: 0,
  EditableCard: 1,
};

export default MultiTabCard;
