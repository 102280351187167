/* eslint-disable no-useless-computed-key */
import { Box, Checkbox, FormControl, FormControlLabel, MenuItem, TextField, withStyles } from '@material-ui/core';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Fragment, PureComponent } from 'react';
import memoize from 'memoize-one';

//import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';

import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from '../layout/types';
import { GET_VENDORS_FAILURE, POST_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { getVendors, postVendor } from 'modules/authorizations/store/actions/dataManagement.actions';
import VendorsGrid from './vendorsGrid.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import { Button, Dialog, DialogActions, Typography } from '@mui/material';
import Spinner from 'modules/common/spinner.component';
import { getAuthorizationSummaries } from 'modules/authorizations/store/actions/authorizations.actions';
import { GET_AUTHORIZATIONS_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
});

class VendorsView extends PureComponent {
    state = {
        reassigningRecord: null,
        deletingRecord: null,
        isReassigningRecord: false,
        isDeletingRecord: false,
        webAccessRecord: null,
        showWebAccessModal: false,
        showAlterWebAccessModal: false,
        isLoading: false,
        selectedStatuses: -1,
        selectedStatusName: '',
        selectedType: -1,
        selectedTypeName: null,
        showCreateVendor: false,
        isLoadingModal: false,
        newCompanyName: null,
        newDescription: null,
        newVendorType: null,
        newStatus: 'Active',
        newAddress: null,
        newCity: null,
        newState: null,
        newZip: null,
        newPhone: null,
        newEmail: null,
        newBankingRequired: false,
      };

  async componentDidMount() {
    this.props.setPageTitle('Vendors');

    this.setState({ isLoading: true });

    if (!this.props.vendors) {
      let { type } = await this.props.getVendors();
      if (type === GET_VENDORS_FAILURE) {
        this.props.showToast('Failed to retrieve vednors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.authorizationSummaries) {
      let { type } = await this.props.getAuthorizationSummaries();
      if (type === GET_AUTHORIZATIONS_FAILURE) {
        this.props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    this.setState({ isLoading: false });
  }


  createVendor = async () => {
    this.setState({ isLoadingModal: true });

    let {
      newCompanyName, newDescription, newStatus, newVendorType, newAddress, newCity, newState, newZip, newPhone, newEmail, newBankingRequired,
     } = this.state;

     let types = [
      { title: 'Inspectors', id: 1 },
      { title: 'School', id: 2 },
      { title: 'Insp', id: 3 },
      { title: 'Brk', id: 4 },
      { title: 'Hhg', id: 5 },
      { title: 'Spc', id: 6 },
      { title: 'Tax', id: 7 },
      { title: 'Oth', id: 8 },
      { title: 'TenancyMgmt', id: 9 },
      { title: 'TI', id: 10 },
      { title: 'Client', id: 11 },
      { title: 'Hoa', id: 12 },
      { title: 'Mtg', id: 13 },
      { title: 'Insur', id: 14 },
      { title: 'Util', id: 15 },
      { title: 'App', id: 16 },
      { title: 'Tp', id: 17 },
      { title: 'Pet', id: 18 },
      { title: 'Dsc', id: 19 },
      { title: 'Division', id: 20 },
    ];

    let searchingVendor = types.filter((s) => s.id === newVendorType);

    let values = {
      companyName: newCompanyName,       
      status: newStatus,
      description: newDescription,
      address: newAddress,
      city: newCity,
      state: newState,
      zip: newZip,
      phone: newPhone,
      email: newEmail,
      primary: '',
      vendorType: searchingVendor[0].title,
      contractDate: null,
      contractExpires: null,
      statusLastUpdated: new Date(),
      bankingRequired: newBankingRequired,
    }

    const resp = await this.props.postVendor(values);

    if (resp.type === POST_VENDORS_FAILURE) {
      this.props.showToast('Failed to create new vendor, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
    else
    {
      this.setState({ 
        isLoadingModal: false,
        showCreateVendor: false,
        newCompanyName: null,
        newDescription: null,
        newAddress: null,
        newCity: null,
        newState: null,
        newZip: null,
        newPhone: null,
        newEmail: null,
        newVendorType: null,
        newBankingRequired: false,
       });

      this.props.showToast('Successfully created new vendor', {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
    }

  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleVendorTypeChange = (vendorTypes) => (event) => {
    let type = vendorTypes.filter((s) => s.id === event.target.value);
    this.setState({
      selectedType: type[0].id,
      selectedTypeName: type[0].title,
    });
  };

  filterSummaries = memoize((vendors, selectedType, selectedTypeName, selectedStatuses, selectedStatusName) => {
    return vendors.filter((summary) => {
      return (selectedType === -1 || (selectedTypeName.length !== 0 && selectedTypeName.includes(summary.vendorType))) &&
        (selectedStatuses === -1 || (summary.status !== null && summary.status === selectedStatusName));
    });
  });

  render() {
    Log.trace('RENDER', 'VendorsView');
    const { showCreateVendor, selectedStatuses, selectedStatusName, selectedType, selectedTypeName, isLoadingModal, newCompanyName, newDescription, newVendorType, newAddress, newCity, newState, newZip, newPhone, newEmail, newBankingRequired } = this.state;
    const { classes, history, isLoading, vendors } = this.props;

    const sortedStatuses = [
        { title: 'Active', id: 1 },
        { title: 'Inactive', id: 2 },
      ];

      const vendorTypes = [
        { title: 'Vendor Type', id: -1 },
        { title: 'Inspectors', id: 1 },
        { title: 'School', id: 2 },
        { title: 'Insp', id: 3 },
        { title: 'Brk', id: 4 },
        { title: 'Hhg', id: 5 },
        { title: 'Spc', id: 6 },
        { title: 'Tax', id: 7 },
        { title: 'Oth', id: 8 },
        { title: 'TenancyMgmt', id: 9 },
        { title: 'TI', id: 10 },
        { title: 'Client', id: 11 },
        { title: 'Hoa', id: 12 },
        { title: 'Mtg', id: 13 },
        { title: 'Insur', id: 14 },
        { title: 'Util', id: 15 },
        { title: 'App', id: 16 },
        { title: 'Tp', id: 17 },
        { title: 'Pet', id: 18 },
        { title: 'Dsc', id: 19 },
        { title: 'Division', id: 20 },
      ];

    let vendorSummaries = this.filterSummaries(vendors || [], selectedType || [], selectedTypeName || [], selectedStatuses || [], selectedStatusName || []);

    return (
      <Fragment>
        <div className={classes.headerContainer}>
          <TextField
            select
            label="Status"
            className={classes.textField}
            value={selectedStatuses}
            onChange={this.handleChange('selectedStatuses')}
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            <MenuItem value={-1}>
              <em>Status</em>
            </MenuItem>
            {sortedStatuses.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Vendor Type"
            className={classes.textField}
            value={selectedType}
            onChange={this.handleVendorTypeChange(vendorTypes)}
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            {vendorTypes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box sx={{padding: '10px', display: 'flex', flexDirection: 'row'}}>

            </Box>
          <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginRight: '10px', marginTop: '8px', backgroundColor: '#5D9878', '&:hover': { backgroundColor: '#528569' } }} onClick={() => this.setState({ showCreateVendor: true })}> Create Vendor </Button>
        </Box>
        { vendorSummaries && 
          <>
            {this.state.isLoading && <FullscreenSpinner/>}
            {!this.state.isLoading && (
              <div className="px-2">
                <VendorsGrid
                  vendors={vendorSummaries ?? []}
                  isLoading={isLoading}
                  history={history}
                />
              </div>
            )}

            <Dialog open={showCreateVendor} fullWidth={false} maxWidth={'lg'}>
              <Box sx={{ padding: '25px'}}>
                <Typography variant="h5" >
                  New Vendor
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                  <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newCompanyName'}
                      label={'Company Name'}
                      name={'cnewCompanyName'}
                      required={true}
                      variant="outlined"
                      sx={{ maxWidth: '360px' }}
                      value={newCompanyName}
                      onChange={(e) => this.setState({ newCompanyName: e.target.value})}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                    <Typography variant="caption" display="block" color="primary">
                      Required
                    </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newDescription'}
                      label={'Description'}
                      name={'newDescription'}
                      value={newDescription}
                      variant="outlined"
                      required={false}
                      sx={{ maxWidth: '360px' }}
                      type="text"
                      onChange={(e) => this.setState({ newDescription: e.target.value})}
                      InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    select
                    labelId={'newVendorType'}
                    key={'newVendorType'}
                    label={'Vendor Type'}
                    name={'newVendorType'}
                    className={classes.textField}
                    value={newVendorType}
                    required={true}
                    onChange={(e) => this.setState({
                      newVendorType: e.target.value,
                    })}
                    SelectProps={{
                      autoWidth: true,
                    }}
                    variant="outlined"
                  >
                    {vendorTypes.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                        {opt.title}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newAddress'}
                      label={'Address'}
                      name={'newAddress'}
                      value={newAddress}
                      required={false}
                      sx={{ maxWidth: '360px' }}
                      variant="outlined"
                      onChange={(e) => this.setState({ newAddress: e.target.value})}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newCity'}
                      label={'City'}
                      name={'newCity'}
                      value={newCity}
                      required={false}
                      sx={{ maxWidth: '360px' }}
                      variant="outlined"
                      onChange={(e) => this.setState({ newCity: e.target.value})}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newState'}
                      label={'State'}
                      name={'newState'}
                      value={newState}
                      required={false}
                      sx={{ maxWidth: '360px' }}
                      variant="outlined"
                      onChange={(e) => this.setState({ newState: e.target.value})}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newZip'}
                      label={'ZIP'}
                      name={'newZip'}
                      value={newZip}
                      required={false}
                      sx={{ maxWidth: '360px' }}
                      variant="outlined"
                      onChange={(e) => this.setState({ newZip: e.target.value})}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newPhone'}
                      label={'Phone'}
                      name={'newPhone'}
                      value={newPhone}
                      required={false}
                      sx={{ maxWidth: '360px' }}
                      variant="outlined"
                      onChange={(e) => this.setState({ newPhone: e.target.value})}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                    <TextField
                      key={'newEmail'}
                      label={'Email'}
                      name={'newEmail'}
                      value={newEmail}
                      required={false}
                      sx={{ maxWidth: '360px' }}
                      variant="outlined"
                      onChange={(e) => this.setState({ newEmail: e.target.value})}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                    <FormControlLabel
                      key={'newBankingRequired'}
                      name={'newBankingRequired'}
                      label={'Banking Required'}
                      labelPlacement="start"
                      control={<Checkbox />}
                      required={false}
                      disabled={false}
                      checked={newBankingRequired}
                      variant="standard"
                      margin="dense"
                      sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                      onClick={(e) => this.setState({ newBankingRequired: e.target.checked })}
                    />
                  </FormControl>
                </Box>

              </Box>
              <DialogActions sx={{ p: '1.25rem' }}>
                {isLoadingModal && <Spinner />}
                {!isLoadingModal &&
                  <>
                    <Button onClick={() => this.setState({ showCreateVendor: false })} sx={{ color: '#41B7C0', '&:hover': { color: '#3d959c' } }}>Cancel</Button>
                    <Button onClick={this.createVendor} color="primary" sx={{ backgroundColor: '#41B7C0', '&:hover': { backgroundColor: '#3d959c' } }} type="submit" variant="contained" >
                      Create Vendor
                    </Button>
                  </>
                }
              </DialogActions>
            </Dialog>
            </>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    authorizations: { authorizationSummaries, vendors },
    expenses: { vendorSummaries, isLoading },
  } = state;
  return { vendorSummaries, isLoading, vendors, authorizationSummaries };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getTestApiV2,
    getAuthorizationSummaries,
    getVendors,
    postVendor,
  }),
)(VendorsView);