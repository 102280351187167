
export const GET_AUTH_HOUSE_HUNTING_LIST_FAILURE = 'GET_AUTH_HOUSE_HUNTING_LIST_FAILURE';
export const GET_AUTH_HOUSE_HUNTING_LIST_REQUEST = 'GET_AUTH_HOUSE_HUNTING_LIST_REQUEST';
export const GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS = 'GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS';

export const POST_AUTH_HOUSE_HUNTING_LIST_FAILURE = 'POST_AUTH_HOUSE_HUNTING_LIST_FAILURE';
export const POST_AUTH_HOUSE_HUNTING_LIST_REQUEST = 'POST_AUTH_HOUSE_HUNTING_LIST_REQUEST';
export const POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS = 'POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS';

export const UPDATE_AUTH_HOUSE_HUNTING_LIST_FAILURE = 'UPDATE_AUTH_HOUSE_HUNTING_LIST_FAILURE';
export const UPDATE_AUTH_HOUSE_HUNTING_LIST_REQUEST = 'UPDATE_AUTH_HOUSE_HUNTING_LIST_REQUEST';
export const UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS = 'UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS';

export const DELETE_AUTH_HOUSE_HUNTING_LIST_FAILURE = 'DELETE_AUTH_HOUSE_HUNTING_LIST_FAILURE';
export const DELETE_AUTH_HOUSE_HUNTING_LIST_REQUEST = 'DELETE_AUTH_HOUSE_HUNTING_LIST_REQUEST';
export const DELETE_AUTH_HOUSE_HUNTING_LIST_SUCCESS = 'DELETE_AUTH_HOUSE_HUNTING_LIST_SUCCESS';

export const GET_AUTH_OFFER_HISTORY_LIST_FAILURE = 'GET_AUTH_OFFER_HISTORY_LIST_FAILURE';
export const GET_AUTH_OFFER_HISTORY_LIST_REQUEST = 'GET_AUTH_OFFER_HISTORY_LIST_REQUEST';
export const GET_AUTH_OFFER_HISTORY_LIST_SUCCESS = 'GET_AUTH_OFFER_HISTORY_LIST_SUCCESS';

export const POST_AUTH_OFFER_HISTORY_LIST_FAILURE = 'POST_AUTH_OFFER_HISTORY_LIST_FAILURE';
export const POST_AUTH_OFFER_HISTORY_LIST_REQUEST = 'POST_AUTH_OFFER_HISTORY_LIST_REQUEST';
export const POST_AUTH_OFFER_HISTORY_LIST_SUCCESS = 'POST_AUTH_OFFER_HISTORY_LIST_SUCCESS';

export const UPDATE_AUTH_OFFER_HISTORY_LIST_FAILURE = 'UPDATE_AUTH_OFFER_HISTORY_LIST_FAILURE';
export const UPDATE_AUTH_OFFER_HISTORY_LIST_REQUEST = 'UPDATE_AUTH_OFFER_HISTORY_LIST_REQUEST';
export const UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS = 'UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS';

export const DELETE_AUTH_OFFER_HISTORY_LIST_FAILURE = 'DELETE_AUTH_OFFER_HISTORY_LIST_FAILURE';
export const DELETE_AUTH_OFFER_HISTORY_LIST_REQUEST = 'DELETE_AUTH_OFFER_HISTORY_LIST_REQUEST';
export const DELETE_AUTH_OFFER_HISTORY_LIST_SUCCESS = 'DELETE_AUTH_OFFER_HISTORY_LIST_SUCCESS';

export const GET_AUTH_DESTINATION_SERVICES_FAILURE = 'GET_AUTH_DESTINATION_SERVICES_FAILURE';
export const GET_AUTH_DESTINATION_SERVICES_REQUEST = 'GET_AUTH_DESTINATION_SERVICES_REQUEST';
export const GET_AUTH_DESTINATION_SERVICES_SUCCESS = 'GET_AUTH_DESTINATION_SERVICES_SUCCESS';

export const POST_AUTH_DESTINATION_SERVICES_FAILURE = 'POST_AUTH_DESTINATION_SERVICES_FAILURE';
export const POST_AUTH_DESTINATION_SERVICES_REQUEST = 'POST_AUTH_DESTINATION_SERVICES_REQUEST';
export const POST_AUTH_DESTINATION_SERVICES_SUCCESS = 'POST_AUTH_DESTINATION_SERVICES_SUCCESS';

export const UPDATE_AUTH_DESTINATION_SERVICES_FAILURE = 'UPDATE_AUTH_DESTINATION_SERVICES_FAILURE';
export const UPDATE_AUTH_DESTINATION_SERVICES_REQUEST = 'UPDATE_AUTH_DESTINATION_SERVICES_REQUEST';
export const UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS = 'UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS';

