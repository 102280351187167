import {
  Box,
} from '@mui/material';
import React from 'react';

const OnHoldIcon = () => {
  return (
    <Box sx={{ height: '40px', width: '40px', backgroundColor: '#f0b11d', borderRadius: '50%', display: 'inline-block', border: 1 }}>
      <Box sx={{ margin: '5px', marginTop: '10px' }}>
        <b>Hold</b>
      </Box>
    </Box>
  );
};

export default OnHoldIcon;