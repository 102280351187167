import {
  GenderOptions,
  MaritalOptions,
  PrefixOptions,
  SuffixOptions,
  TaxFilingStatusOptions,
  tenNinetyNineOptions,
} from './formFieldConstants';

export const createEmployeeSummary = (employee) => {
  return [
    {
      header: 'Prefix',
      accessorKey: 'prefix',
      type: 'dropdown',
      required: false,
      options: PrefixOptions,
      value: employee ? employee.prefix : '',
      tab: 1,
    },
    { header: 'First Name', accessorKey: 'firstName', type: 'text', required: true, value: employee ? employee.firstName : '', tab: 1 },
    {
      header: 'Middle',
      accessorKey: 'middle',
      type: 'text',
      required: false,
      value: employee ? employee.middle : '',
      tab: 1,
      hideInTable: true,
    },
    { header: 'Last Name', accessorKey: 'lastName', type: 'text', required: true, value: employee ? employee.lastName : '', tab: 1 },
    {
      header: 'Suffix',
      accessorKey: 'suffix',
      type: 'dropdown',
      required: false,
      options: SuffixOptions,
      value: employee ? employee.suffix : '',
      tab: 1,
    },
    {
      header: 'Marital',
      accessorKey: 'marital',
      type: 'dropdown',
      required: false,
      options: MaritalOptions,
      value: employee ? employee.marital : '',
      tab: 1,
      hideInTable: true,
    },
    { header: 'Employee ID', accessorKey: 'employeeId', type: 'text', required: false, value: employee ? employee.employeeId : '', tab: 1 },
    // { header: 'Nickname', accessorKey: 'nickname', type: 'text', required: false, value: employee ? employee.nickName : '', tab: 1 },
    // {
    //   header: '1099',
    //   accessorKey: 'tenNinetyNine',
    //   type: 'dropdown',
    //   required: false,
    //   options: tenNinetyNineOptions,
    //   value: employee ? employee.tenNinetyNine : '',
    //   tab: 1
    // },
    { header: 'SSN', accessorKey: 'ssn', type: 'text', required: false, value: employee?.ssn, tab: 1, hideInTable: true },
    {
      header: 'Birth Date',
      accessorKey: 'birthDate',
      type: 'date',
      required: false,
      value: employee?.birthDate,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Gender',
      accessorKey: 'gender',
      type: 'dropdown',
      options: GenderOptions,
      required: false,
      value: employee?.gender,
      tab: 1,
      hideInTable: true,
    },
    {
      header: '1099',
      accessorKey: 'tenNinetyNine',
      type: 'dropdown',
      required: false,
      options: tenNinetyNineOptions,
      value: employee ? employee.tenNinetyNine : '',
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Tax Filing Status',
      accessorKey: 'taxFilingStatus',
      type: 'dropdown',
      required: false,
      options: TaxFilingStatusOptions,
      value: employee ? employee.taxFilingStatus : '',
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Languages',
      accessorKey: 'language',
      type: 'notes',
      required: false,
      value: employee ? employee.language : '',
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Special Needs',
      accessorKey: 'specialNeeds',
      type: 'notes',
      required: false,
      value: employee?.specialNeeds,
      tab: 1,
      hideInTable: true,
    },
    { header: 'Comments', accessorKey: 'comments', type: 'notes', required: false, value: employee?.comments, tab: 1, hideInTable: true },
  ];
};
export const createEmployeeTopLink = (clientInfo) => {
  return clientInfo ? { title: clientInfo.description, link: `/clients/${clientInfo.moveTrackClientId}`, tab: 1 } : null;
};
