/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { GET_CREDIT_FAILURE, GET_TAX_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { getCredits, getTaxes } from 'modules/authorizations/store/actions/homesale.actions';
import CreditsChargesCard from './creditsChargesCard.component';
import EquityDetailsCard from './equityDetailsCard.component';
import MortgagesCard from './mortgagesCard.component';
import Spinner from 'modules/common/spinner.component';
import Taxes from './taxes.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const Equity = (props) => {    
  const { details, equityId, expenseCodes, isReadOnly } = props;

  const [isLoading, setIsLoading] = useState(false);

  const equityInfo = details?.authHomesale?.equities ? details?.authHomesale?.equities.find((a) => a.equityId === equityId) : null;

  const grabTaxCreditData = async () => {
    setIsLoading(true);

    const resp = await props.getTaxes(equityId);
    if (resp.type === GET_TAX_FAILURE) {
      props.showToast('Failed to get equity taxes data, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    }

    const creditResp = await props.getCredits(equityId);
    if (creditResp.type === GET_CREDIT_FAILURE) {
      props.showToast('Failed to get equity credits and charges data, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (equityId) {
      grabTaxCreditData();
    }
  }, [equityId]);

  return (
    <Box>
      {equityInfo == null && !isLoading && (
        <Box sx={{ padding: '20px', fontSize: '20px' }}>
          <b>No Equity selected. Create a new one above to get started.</b>
        </Box>
      )}
      {equityInfo != null && !isLoading && (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <EquityDetailsCard equityInfo={equityInfo} isReadOnly={isReadOnly} />
            <Box sx={{ paddingTop: '16px' }}>
              <MortgagesCard equityInfo={equityInfo} isReadOnly={isReadOnly}/>
            </Box>
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <Taxes equityInfo={equityInfo} taxes={details?.authHomesale?.equityTaxes} isReadOnly={isReadOnly}/>
            <Box sx={{ paddingTop: '16px' }}>
              <CreditsChargesCard equityInfo={equityInfo} credits={details?.authHomesale?.equityCredits} expenseCodes={expenseCodes} isReadOnly={isReadOnly}/>
            </Box>
          </Grid>
        </Grid>
      )}
      {isLoading && <Spinner />}
    </Box>
  );

};

const mapStateToProps = (state) => {
  const { expenseCodes } = state.authorizations;
  return {
    expenseCodes,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getTaxes,
    getCredits,
  }),
)(Equity);