import { Typography, withStyles, withTheme } from '@material-ui/core';
import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { compose } from 'recompose';
import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
import { formatDateGeneric } from 'utilities/common';
import Log from 'utilities/log';
import Spinner from 'modules/common/spinner.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControlLabel } from '@mui/material';

const styles = theme => {
  const intakeRecordBorder = `1px solid ${theme.palette.grey[500]}`;
  return {
    table: {
      borderSpacing: theme.spacing(0, 2)
    },
    tableHead: {
      height: 32
    },
    cell: {
      borderTop: intakeRecordBorder,
      borderBottom: intakeRecordBorder,
      borderLeft: 0,
      borderRight: 0,
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: intakeRecordBorder
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: intakeRecordBorder
      }
    },
    urgencyMenuItem: {
      justifyContent: 'center'
    },
    selectInput: {
      lineHeight: 1.75,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(0.5)
    },
    row: {
      backgroundColor: theme.palette.common.white
    },
    noDataCell: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      textAlign: 'center'
    }
  };
};

const FILTER_DISABLED_COLUMNS = ['organization'];

class SubmittedExpensesGrid extends PureComponent {
  static defaultProps = {
    isLoading: false
  };

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'expenseId', title: 'Expense ID' },
        { name: 'dateSubmitted', title: 'Date Submitted', getCellValue: this.formatDate('dateSubmitted') }, // Records aren't accurately filtered by date (text based sorting rather than time based)
        { name: 'daysWaiting', title: 'Days Waiting', getCellValue: this.getDaysWaitingValue },
        { name: 'description', title: 'Description' },
        { name: 'total', title: 'Total', getCellValue: record => <Typography>${isNaN(parseFloat(record.total)) ? 0 : parseFloat(record.total).toFixed(2)}</Typography> },
        { name: 'expenseAnalyst', title: 'Expense Analyst' },
        { name: 'numLines', title: 'Num Lines', getCellValue: (record) => <Typography>{record.numLines}</Typography> },
        { name: 'status', title: 'Status', getCellValue: this.setStatusCellValue }
      ],
      filters: [],
      sorting: [{ columnName: 'dateSubmitted', direction: 'desc' }],
      tableColumnExtensions: [{ columnName: 'description', width: 250, wordWrapEnabled: true }],
      integratedFilteringColumnExtensions: [
        // { columnName: 'dateSubmitted', predicate: this.keyPredicate },
      ],
      showAllExpenses: false
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.toggleShowAllExpenses = this.toggleShowAllExpenses.bind(this);
  }

  toggleShowAllExpenses() {
    this.setState(prevState => ({
      showAllExpenses: !prevState.showAllExpenses
    }));
  }

  setStatusCellValue = record => {
    const {
      theme: { palette }
    } = this.props;

    return (
      <div>
        {record.status === 3 && (
          <FontAwesomeIcon
            color={palette.warning.main}
            icon={['fas', 'exclamation-triangle']}
            size="lg"
            title="Expense on Hold (Repay Agreement Missing)."
          />
        )}
        {record.status === 2 && (
          <FontAwesomeIcon
            color={palette.error.main}
            icon={['fas', 'exclamation-circle']}
            size="lg"
            title="Expense Not Viewed for Three Days!"
          />
        )}
        {record.status === 4 && (
          <FontAwesomeIcon color={palette.secondary.main} icon={['fas', 'circle']} size="lg" title="Expense Not Viewed." />
        )}
      </div>
    );
  };

  getDaysWaitingValue = record => {
    let date = record.dateSubmitted ? new Date(record.dateSubmitted) : null;
    let today = new Date();
    const diffTime = date ? Math.abs(today - date) : null;
    const diffDays = diffTime ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : null;
    return <div>{diffDays}</div>;
  };

  // Filter predicate may need to change, originally based on sorting descriptions.
  keyPredicate = (value, filter) => {
    return new Date(value.key) - new Date(filter.value);
  };

  formatDate = key => record => {
    return formatDateGeneric(record[key]);
  };

  cellClicked = id => {
    // this.props.history.push(`/authorizations/${id}`);
  };

  getDetailsCellValue = record => {
    const { transfereeName, clientName, moveType } = record;
    return (
      <Fragment key={transfereeName}>
        <Typography>{transfereeName}</Typography>
        <Typography>{clientName}</Typography>
        <Typography>{moveType}</Typography>
      </Fragment>
    );
  };

  changeFilters = filters => this.setState({ filters });

  filterCell = props => {
    return props.column.title && !FILTER_DISABLED_COLUMNS.includes(props.column.name) ? <TableFilterRow.Cell {...props} /> : null;
  };

  headerCell = props => {
    return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
  };

  headerRowComponent = props => {
    return <TableHeaderRow.Row className={this.props.classes.tableHead} {...props} />;
  };

  rowComponent = props => {
    const { row } = props;
    const isApproved = row.isExpenseApproved;

    return (
      <Table.Row
        {...props}
        style={{
          backgroundColor: isApproved ? '#E0E0E0' : 'inherit',
          color: isApproved ? '#424242' : 'inherit'
        }}
      />
    );
  };

  cellComponent = props => {
    let clickHandler = () => this.props.history.push(`/authorizations/${this.props.id}/expenses/${props.row.expenseId}`); // Add handler for when a client is clicked
    let pointerClass = 'handles-click';
    const className = classNames(this.props.classes.cell, pointerClass);
    return <Table.Cell onClick={clickHandler} className={className} {...props} />;
  };

  tableComponent = props => {
    return <Table.Table className={this.props.classes.table} {...props} />;
  };

  noDataCellComponent = props => {
    const content = this.props.isLoading ? <Spinner logo /> : 'No Records were returned.  Check the filters above.';
    // need to remove the getMessage function to avoid exception
    // eslint-disable-next-line no-unused-vars
    const { getMessage, ...rest } = props;
    return (
      <Table.Cell {...rest} className={this.props.classes.noDataCell}>
        <Typography>{content}</Typography>
      </Table.Cell>
    );
  };

  createdSubmittedExpensesFields = (submittedExpenses, expenseLines) => {
    //TODO - This should be done in the DB once we get there.
    if (!submittedExpenses || !expenseLines) return [];

    return submittedExpenses.map(expense => {
      const matchingLineItems = expenseLines.filter(item => item.expenseId === expense.expenseId);
      const total = matchingLineItems.reduce((sum, item) => {
        const amount = parseFloat(item.amount);
        return sum + (isNaN(amount) ? 0 : amount);
      }, 0);
      const numLines = matchingLineItems.length;
      return {
        ...expense,
        total,
        numLines
      };
    });
  };

  render() {
    Log.trace('RENDER', 'authorizationsGrid');
    const { submittedExpenses, expenseLines } = this.props;
    const { columns, filters, tableColumnExtensions, integratedFilteringColumnExtensions, sorting, showAllExpenses } = this.state;

    const createdSubmittedExpenses = this.createdSubmittedExpensesFields(submittedExpenses, expenseLines);
    const filteredExpenses = createdSubmittedExpenses ? createdSubmittedExpenses.filter(s => showAllExpenses || !s.isExpenseApproved) : [];

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.showAllExpenses}
              onChange={this.toggleShowAllExpenses}
              color="primary"
              sx={{
                transform: 'scale(0.8)',
                paddingTop: '10px',
                paddingLeft: '20px'
              }}
            />
          }
          label={'Show All Expenses'}
          sx={{
            paddingTop: '5px',
            paddingLeft: '20px'
          }}
          componentsProps={{
            typography: {
              sx: {
                fontSize: '0.875rem'
              }
            }
          }}
        />

        <Grid rows={filteredExpenses} columns={columns}>
          <SortingState sorting={sorting} onSortingChange={this.changeSorting} />

          <FilteringState filters={filters} onFiltersChange={this.changeFilters} />
          <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
          <IntegratedSorting />
          <Table
            columnExtensions={tableColumnExtensions}
            rowComponent={this.rowComponent}
            tableComponent={this.tableComponent}
            cellComponent={this.cellComponent}
            noDataCellComponent={this.noDataCellComponent}
          />
          <TableHeaderRow rowComponent={this.headerRowComponent} cellComponent={this.headerCell} showSortingControls />
          <TableFilterRow cellComponent={this.filterCell} />
        </Grid>
      </div>
    );
  }
}

export default compose(withStyles(styles), withTheme)(SubmittedExpensesGrid);
