/* eslint-disable react-hooks/exhaustive-deps */

import { GET_COST_ESTIMATES_FAILURE, POST_COST_ESTIMATES_FAILURE, UPDATE_COST_ESTIMATES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import {
  Grid,
} from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getCostEstimates, postCostEstimates, updateCostEstimates } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
  
const CostEstimatesCard = (props) => {
  
  const [isLoading, setIsLoading] = useState(false);
  
  const { costEstimate, hideEdit } = props;

  const costEstimateTabs = [
    { header: 'Cost Estimates', order: 1, iconPath: ['fas', 'fa-money-check-alt'] },
  ];
    
  const costEstimateSummaries = [
    { header: 'Move CAP', accessorKey: 'moveCAP', type: 'money', required: false, value: '1,234', tab: 1, hideInModal: true },
    { header: 'Current Total Cost', accessorKey: 'currentTotalCost', type: 'money', required: false, value: '334', tab: 1, hideInModal: true },
    { dividerLine: true, tab: 1 },
    { header: 'Requested Date', accessorKey: 'requestedDate', type: 'date', required: false, value: costEstimate ? costEstimate.requestedDate : null, tab: 1 },
    { header: 'Completed Date', accessorKey: 'completedDate', type: 'date', required: false, value: costEstimate ? costEstimate.completedDate : null, tab: 1 },
    { header: 'Estimates Amount', accessorKey: 'estimatesAmount', type: 'money', required: false, value: costEstimate ? costEstimate.estimatesAmount : '', tab: 1 },
    { header: 'Travel Comparison', accessorKey: 'travelComparrison', type: 'money', required: false, value: costEstimate ? costEstimate.travelComparrison : '', tab: 1 },
  ];
  
  const updateCostEstimatesFields = async (tab, values) => {
    setIsLoading(true);
    let newVals = { ...costEstimate };
    newVals.authorizationId = props.id;
    newVals.requestedDate = values.requestedDate;
    newVals.completedDate = values.completedDate;
    newVals.estimatesAmount = values.estimatesAmount;
    newVals.travelComparrison = values.travelComparrison;
        
    if (costEstimate && costEstimate.length === 0) {
      const resp = await props.postCostEstimates(newVals);
      if (resp.type === POST_COST_ESTIMATES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCostEstimates(props.id);
        if (getResp.type === GET_COST_ESTIMATES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      const resp = await props.updateCostEstimates(newVals);
      if (resp.type === UPDATE_COST_ESTIMATES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCostEstimates(props.id);
        if (getResp.type === GET_COST_ESTIMATES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    }
  };
  
  return (
    <Grid item sx={{ marginBottom: '10px' }}>
      <EditableCard
        tabs={costEstimateTabs}
        summaries={costEstimateSummaries}
        numColumns={2} 
        updateFields={updateCostEstimatesFields}
        isLoading={isLoading}
        hideEdit={hideEdit}
      />
    </Grid>
  );
  
};
  
const mapStateToProps = (state) => {
  return {
  };
};
  
export default compose(
  connect(mapStateToProps, {
    getCostEstimates,
    updateCostEstimates,
    postCostEstimates,
    showToast,
  }),
)(CostEstimatesCard);