export const PrefixOptions = ['N/a', 'Mr', 'Mrs', 'Ms', 'Mx'];
export const SuffixOptions = ['N/a', 'Jr', 'III'];
export const MaritalOptions = ['Single', 'Married', 'Divorced', 'Separated', 'Widowed', 'Other'];
export const tenNinetyNineOptions = ['1', '2', '3'];
export const GenderOptions = ['Man', 'Woman', 'Other'];
export const TaxFilingStatusOptions = ['Single', 'Married', 'Married Filling Separately', 'Head of Household', 'Never Entered'];
export const RelationshipOptions = ['Spouse', 'Partner', 'Child', 'Elderly'];
export const YesOrNo = ['Yes', 'No'];
export const PetType = ['Unknown', 'Dog', 'Cat', 'Other'];

export const ValueType = {
  DateTimeType: 0,
  IntType: 1,
  FloatType: 2,
};

export const vendorSearchInfo = (vendors) => {
  return {
    searchingColumns: [
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'companyName', title: 'Company Name' },
      { name: 'city', title: 'City' },
      { name: 'state', title: 'State' },
      { name: 'phone', title: 'Company Phone' },
      { name: 'email', title: 'Company Email' },
    ],
    searchingColumnExtensions: [
      { columnName: 'vendorId', width: 150 },
      { columnName: 'companyName', width: 250 },
      { name: 'city', width: 175 },
      { name: 'state', width: 175 },
      { columnName: 'phone', width: 175 },
      { columnName: 'email', width: 175 },
    ],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName',
  };
};

export const calculateAge = (birthday) => {
  if (!birthday || birthday === '') return null;

  const bdayParsed = new Date(birthday);
  if (isNaN(bdayParsed.getTime())) return null;

  const today = new Date();
  let age = today.getFullYear() - bdayParsed.getFullYear();
  const monthDifference = today.getMonth() - bdayParsed.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < bdayParsed.getDate())) {
    age--;
  }

  return age;
};

export const parseFormValue = (value, valueType) => {
  if (!value || value === '') return null;

  switch (valueType) {
    case ValueType.DateTimeType:
      return new Date(value).toISOString();
    case ValueType.IntType:
      return parseInt(value);
    case ValueType.FloatType:
      return parseFloat(value);
    default:
      console.log('Invalid Type');
      return null;
  }
};

