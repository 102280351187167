/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, TextField, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DELETE_AUTH_LIST_CLIENT_POLICIES_FAILURE, GET_AUTH_LIST_CLIENT_POLICIES_FAILURE, POST_AUTH_LIST_CLIENT_POLICIES_FAILURE, UPDATE_AUTH_LIST_CLIENT_POLICIES_FAILURE } from '../authorizations/store/types/authorizationTypes';
import { deleteAuthClientPoliciesList, getAuthClientPoliciesList, postAuthClientPoliciesList, updateAuthClientPoliciesList } from '../authorizations/store/actions/authorizations.actions';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import CrudTable from '../clients/details/crudTable.component';
import SearchableDropdown from 'modules/common/searchableDropdown.component';
import Spinner from 'modules/common/spinner.component';
import ToolTip from 'modules/common/toolTip.component';

import DraftTextEditor from 'modules/common/DraftTextEditor';
import QuillTextEditor from 'modules/common/ReactQuillTextEditor';
import classNames from 'classnames';
// import TinyMceEditor from 'modules/common/TinyMceEditor';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientPoliciesTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [id, setId] = useState(0);

  const adjustedColumns = [
    { accessorKey: 'clientId', header: 'Client Id', type: 'text', required: true, size: 70 },
    { accessorKey: 'clientCode', header: 'Client Code', type: 'text', required: true, size: 70 },
    { accessorKey: 'clientShortDescription', header: 'Short Description', type: 'text', required: true, size: 70 },
    { accessorKey: 'clientFullDescription', header: 'Full Description', type: 'text', required: true, size: 50 },
    { accessorKey: 'clientStatus', header: 'Status', type: 'text', required: true, size: 50 },
    { accessorKey: 'clientContactName', header: 'Contact Name', type: 'text', required: true, size: 50 },
    { accessorKey: 'clientPolicyCode', header: 'Policy Code', type: 'text', required: true, size: 50 },
    { accessorKey: 'clientPolicyDescription', header: 'Policy Description', type: 'text', required: true, size: 50 },
    { accessorKey: 'clientBillingPolicy', header: 'Billing Policy', type: 'text', required: true, size: 50 },
  ];

  const [searchingColumns] = useState([
    { name: 'clientCode', title: 'Client Code' },
    { name: 'clientShortDescription', title: 'Short Description' },
    { name: 'clientFullDescription', title: 'Full Description' },
    { name: 'clientPolicyCode', title: 'Policy Code' },
    { name: 'clientPolicyDescription', title: 'Policy Description' },
  ]);

  const [searchingColumnExtensions] = useState([
    { columnName: 'clientCode', width: 150 },
    { columnName: 'clientShortDescription', width: 200 },
    { columnName: 'clientFullDescription', width: 300 },
    { columnName: 'clientPolicyCode', width: 175 },
    { columnName: 'clientPolicyDescription', width: 250 },
  ]);

  const updateRow = async (policy, values) => {
    const id = policy.original.id;
    values.id = id;
    const resp = await props.updateAuthClientPoliciesList(values);
    if (resp.type === UPDATE_AUTH_LIST_CLIENT_POLICIES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthClientPoliciesList();
      if (getResp.type === GET_AUTH_LIST_CLIENT_POLICIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    const resp = await props.postAuthClientPoliciesList(values);
    if (resp.type === POST_AUTH_LIST_CLIENT_POLICIES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthClientPoliciesList();
      if (getResp.type === GET_AUTH_LIST_CLIENT_POLICIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (policy) => {
    const id = policy.original.id;
    const resp = await props.deleteAuthClientPoliciesList(id);
    if (resp.type === DELETE_AUTH_LIST_CLIENT_POLICIES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthClientPoliciesList();
      if (getResp.type === GET_AUTH_LIST_CLIENT_POLICIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const renderPolicyOptions = (values) => {
    let tempArray = [];
    values.map((val) => {
      let tempObject = {
        key: val.clientPolicyCode,
        label: val.clientPolicyDescription,
      };
      tempArray.push(tempObject);
    });
    // if the returned object wasn't filtered properly, filter the new array
    tempArray.sort((a, b) => a.label.localeCompare(b.label));
    return tempArray;
  };


  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Client Policies</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientPoliciesLoading && props.clientPolicies && (
          <div>
            <CrudTable 
              editingMode={'modal'} // Can be changed to 'row' to edit inline 
              columns={adjustedColumns} 
              rows={props.clientPolicies} 
              updateRow={updateRow} 
              createRow={createRow} 
              deleteRow={deleteRow} 
            />
            <div sx={{ marginBottom: '4px', display: 'flex', flexDirection: 'row', maxWidth: '500px' }}>
              <SearchableDropdown
                label={'Policies'}
                options={[]}
                renderOptionsOnSearch={renderPolicyOptions}
              />
              <ToolTip label={'Search for the policy you need in the dropdown. Start typing and our system will search for every matching result! If there are no results returned, adjust your search until you find what you need or contact our IT staff.'} />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <AdvancedSearch labelText={'Policy'} displayValueAccessorKey={'clientPolicyCode'} saveIdValueAccessorKey={'clientPolicyId'} searchingCriteria={searchingColumns} searchingCriteriaExtensions={searchingColumnExtensions} searchingRows={props.clientPolicies} id={id} value={value}
                setIdValue={(id, value) => { setId(id); setValue(value); }}
              />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <DraftTextEditor />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <QuillTextEditor />
            </div>
            {/* <div style={{paddingTop: '20px'}}>
              <TinyMceEditor />
            </div> */}
            <div style={{ paddingTop: '20px' }}>
              <TextField inputProps={{ spellCheck: true }} label={'Spellcheck Example'} multiline={true} fullWidth minRows={3} variant="outlined" />
            </div>
          </div>
        )}
        {props.clientPoliciesLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

ClientPoliciesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicies, clientPoliciesLoading } = state.authorizations;
  return {
    clientPolicies,
    clientPoliciesLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    deleteAuthClientPoliciesList, 
    getAuthClientPoliciesList, 
    postAuthClientPoliciesList, 
    updateAuthClientPoliciesList,
  }),
)(ClientPoliciesTable);