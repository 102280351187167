export const GET_AUTHORIZATIONS_REQUEST = 'GET_AUTHORIZATIONS_REQUEST';
export const GET_AUTHORIZATIONS_SUCCESS = 'GET_AUTHORIZATIONS_SUCCESS';
export const GET_AUTHORIZATIONS_FAILURE = 'GET_AUTHORIZATIONS_FAILURE';

export const GET_AUTH_SUMMARY_REQUEST = 'GET_AUTH_SUMMARY_REQUEST';
export const GET_AUTH_SUMMARY_SUCCESS = 'GET_AUTH_SUMMARY_SUCCESS';
export const GET_AUTH_SUMMARY_FAILURE = 'GET_AUTH_SUMMARY_FAILURE';

export const POST_AUTHORIZATIONS_REQUEST = 'POST_AUTHORIZATIONS_REQUEST';
export const POST_AUTHORIZATIONS_SUCCESS = 'POST_AUTHORIZATIONS_SUCCESS';
export const POST_AUTHORIZATIONS_FAILURE = 'POST_AUTHORIZATIONS_FAILURE';

export const UPDATE_AUTHORIZATIONS_REQUEST = 'UPDATE_AUTHORIZATIONS_REQUEST';
export const UPDATE_AUTHORIZATIONS_SUCCESS = 'UPDATE_AUTHORIZATIONS_SUCCESS';
export const UPDATE_AUTHORIZATIONS_FAILURE = 'UPDATE_AUTHORIZATIONS_FAILURE';

export const DELETE_AUTHORIZATIONS_REQUEST = 'DELETE_AUTHORIZATIONS_REQUEST';
export const DELETE_AUTHORIZATIONS_SUCCESS = 'DELETE_AUTHORIZATIONS_SUCCESS';
export const DELETE_AUTHORIZATIONS_FAILURE = 'DELETE_AUTHORIZATIONS_FAILURE';

export const GET_EMPLOYEE_INFO_FAILURE = 'GET_EMPLOYEE_INFO_FAILURE';
export const GET_EMPLOYEE_INFO_REQUEST = 'GET_EMPLOYEE_INFO_REQUEST';
export const GET_EMPLOYEE_INFO_SUCCESS = 'GET_EMPLOYEE_INFO_SUCCESS';

export const POST_EMPLOYEE_INFO_FAILURE = 'POST_EMPLOYEE_INFO_FAILURE';
export const POST_EMPLOYEE_INFO_REQUEST = 'POST_EMPLOYEE_INFO_REQUEST';
export const POST_EMPLOYEE_INFO_SUCCESS = 'POST_EMPLOYEE_INFO_SUCCESS';

export const UPDATE_EMPLOYEE_INFO_FAILURE = 'UPDATE_EMPLOYEE_INFO_FAILURE';
export const UPDATE_EMPLOYEE_INFO_REQUEST = 'UPDATE_EMPLOYEE_INFO_REQUEST';
export const UPDATE_EMPLOYEE_INFO_SUCCESS = 'UPDATE_EMPLOYEE_INFO_SUCCESS';

export const GET_SO_INFO_FAILURE = 'GET_SO_INFO_FAILURE';
export const GET_SO_INFO_REQUEST = 'GET_SO_INFO_REQUEST';
export const GET_SO_INFO_SUCCESS = 'GET_SO_INFO_SUCCESS';

export const POST_SO_INFO_FAILURE = 'POST_SO_INFO_FAILURE';
export const POST_SO_INFO_REQUEST = 'POST_SO_INFO_REQUEST';
export const POST_SO_INFO_SUCCESS = 'POST_SO_INFO_SUCCESS';

export const UPDATE_SO_INFO_FAILURE = 'UPDATE_SO_INFO_FAILURE';
export const UPDATE_SO_INFO_REQUEST = 'UPDATE_SO_INFO_REQUEST';
export const UPDATE_SO_INFO_SUCCESS = 'UPDATE_SO_INFO_SUCCESS';

export const GET_COMMUNICATION_INFO_FAILURE = 'GET_COMMUNICATION_INFO_FAILURE';
export const GET_COMMUNICATION_INFO_REQUEST = 'GET_COMMUNICATION_INFO_REQUEST';
export const GET_COMMUNICATION_INFO_SUCCESS = 'GET_COMMUNICATION_INFO_SUCCESS';

export const POST_COMMUNICATION_INFO_FAILURE = 'POST_COMMUNICATION_INFO_FAILURE';
export const POST_COMMUNICATION_INFO_REQUEST = 'POST_COMMUNICATION_INFO_REQUEST';
export const POST_COMMUNICATION_INFO_SUCCESS = 'POST_COMMUNICATION_INFO_SUCCESS';

export const UPDATE_COMMUNICATION_INFO_FAILURE = 'UPDATE_COMMUNICATION_INFO_FAILURE';
export const UPDATE_COMMUNICATION_INFO_REQUEST = 'UPDATE_COMMUNICATION_INFO_REQUEST';
export const UPDATE_COMMUNICATION_INFO_SUCCESS = 'UPDATE_COMMUNICATION_INFO_SUCCESS';

export const SET_AUTH_ID = 'SET_AUTH_ID';
export const SET_LOADING_COMPLETE = 'SET_LOADING_COMPLETE';

export const GET_AUTH_DATE_SUMMARY_FAILURE = 'GET_AUTH_DATE_SUMMARY_FAILURE';
export const GET_AUTH_DATE_SUMMARY_REQUEST = 'GET_AUTH_DATE_SUMMARY_REQUEST';
export const GET_AUTH_DATE_SUMMARY_SUCCESS = 'GET_AUTH_DATE_SUMMARY_SUCCESS';

export const POST_AUTH_DATE_SUMMARY_FAILURE = 'POST_AUTH_DATE_SUMMARY_FAILURE';
export const POST_AUTH_DATE_SUMMARY_REQUEST = 'POST_AUTH_DATE_SUMMARY_REQUEST';
export const POST_AUTH_DATE_SUMMARY_SUCCESS = 'POST_AUTH_DATE_SUMMARY_SUCCESS';

export const UPDATE_AUTH_DATE_SUMMARY_FAILURE = 'UPDATE_AUTH_DATE_SUMMARY_FAILURE';
export const UPDATE_AUTH_DATE_SUMMARY_REQUEST = 'UPDATE_AUTH_DATE_SUMMARY_REQUEST';
export const UPDATE_AUTH_DATE_SUMMARY_SUCCESS = 'UPDATE_AUTH_DATE_SUMMARY_SUCCESS';

export const GET_DEPARTURE_ADDRESS_FAILURE = 'GET_DEPARTURE_ADDRESS_FAILURE';
export const GET_DEPARTURE_ADDRESS_REQUEST = 'GET_DEPARTURE_ADDRESS_REQUEST';
export const GET_DEPARTURE_ADDRESS_SUCCESS = 'GET_DEPARTURE_ADDRESS_SUCCESS';

export const POST_DEPARTURE_ADDRESS_FAILURE = 'POST_DEPARTURE_ADDRESS_FAILURE';
export const POST_DEPARTURE_ADDRESS_REQUEST = 'POST_DEPARTURE_ADDRESS_REQUEST';
export const POST_DEPARTURE_ADDRESS_SUCCESS = 'POST_DEPARTURE_ADDRESS_SUCCESS';

export const UPDATE_DEPARTURE_ADDRESS_FAILURE = 'UPDATE_DEPARTURE_ADDRESS_FAILURE';
export const UPDATE_DEPARTURE_ADDRESS_REQUEST = 'UPDATE_DEPARTURE_ADDRESS_REQUEST';
export const UPDATE_DEPARTURE_ADDRESS_SUCCESS = 'UPDATE_DEPARTURE_ADDRESS_SUCCESS';

export const GET_DESTINATION_ADDRESS_FAILURE = 'GET_DESTINATION_ADDRESS_FAILURE';
export const GET_DESTINATION_ADDRESS_REQUEST = 'GET_DESTINATION_ADDRESS_REQUEST';
export const GET_DESTINATION_ADDRESS_SUCCESS = 'GET_DESTINATION_ADDRESS_SUCCESS';

export const POST_DESTINATION_ADDRESS_FAILURE = 'POST_DESTINATION_ADDRESS_FAILURE';
export const POST_DESTINATION_ADDRESS_REQUEST = 'POST_DESTINATION_ADDRESS_REQUEST';
export const POST_DESTINATION_ADDRESS_SUCCESS = 'POST_DESTINATION_ADDRESS_SUCCESS';

export const UPDATE_DESTINATION_ADDRESS_FAILURE = 'UPDATE_DESTINATION_ADDRESS_FAILURE';
export const UPDATE_DESTINATION_ADDRESS_REQUEST = 'UPDATE_DESTINATION_ADDRESS_REQUEST';
export const UPDATE_DESTINATION_ADDRESS_SUCCESS = 'UPDATE_DESTINATION_ADDRESS_SUCCESS';

export const GET_AUTH_CLIENT_POLICIES_FAILURE = 'GET_AUTH_CLIENT_POLICIES_FAILURE';
export const GET_AUTH_CLIENT_POLICIES_REQUEST = 'GET_AUTH_CLIENT_POLICIES_REQUEST';
export const GET_AUTH_CLIENT_POLICIES_SUCCESS = 'GET_AUTH_CLIENT_POLICIES_SUCCESS';

export const POST_AUTH_CLIENT_POLICIES_FAILURE = 'POST_AUTH_CLIENT_POLICIES_FAILURE';
export const POST_AUTH_CLIENT_POLICIES_REQUEST = 'POST_AUTH_CLIENT_POLICIES_REQUEST';
export const POST_AUTH_CLIENT_POLICIES_SUCCESS = 'POST_AUTH_CLIENT_POLICIES_SUCCESS';

export const UPDATE_AUTH_CLIENT_POLICIES_FAILURE = 'UPDATE_AUTH_CLIENT_POLICIES_FAILURE';
export const UPDATE_AUTH_CLIENT_POLICIES_REQUEST = 'UPDATE_AUTH_CLIENT_POLICIES_REQUEST';
export const UPDATE_AUTH_CLIENT_POLICIES_SUCCESS = 'UPDATE_AUTH_CLIENT_POLICIES_SUCCESS';

export const GET_AUTH_TEAM_FAILURE = 'GET_AUTH_TEAM_FAILURE';
export const GET_AUTH_TEAM_REQUEST = 'GET_AUTH_TEAM_REQUEST';
export const GET_AUTH_TEAM_SUCCESS = 'GET_AUTH_TEAM_SUCCESS';

export const POST_AUTH_TEAM_FAILURE = 'POST_AUTH_TEAM_FAILURE';
export const POST_AUTH_TEAM_REQUEST = 'POST_AUTH_TEAM_REQUEST';
export const POST_AUTH_TEAM_SUCCESS = 'POST_AUTH_TEAM_SUCCESS';

export const UPDATE_AUTH_TEAM_FAILURE = 'UPDATE_AUTH_TEAM_FAILURE';
export const UPDATE_AUTH_TEAM_REQUEST = 'UPDATE_AUTH_TEAM_REQUEST';
export const UPDATE_AUTH_TEAM_SUCCESS = 'UPDATE_AUTH_TEAM_SUCCESS';

export const GET_ALL_AUTH_SERVICES_FAILURE = 'GET_ALL_AUTH_SERVICES_FAILURE';
export const GET_ALL_AUTH_SERVICES_REQUEST = 'GET_ALL_AUTH_SERVICES_REQUEST';
export const GET_ALL_AUTH_SERVICES_SUCCESS = 'GET_ALL_AUTH_SERVICES_SUCCESS';

export const GET_AUTH_SERVICES_FAILURE = 'GET_AUTH_SERVICES_FAILURE';
export const GET_AUTH_SERVICES_REQUEST = 'GET_AUTH_SERVICES_REQUEST';
export const GET_AUTH_SERVICES_SUCCESS = 'GET_AUTH_SERVICES_SUCCESS';

export const POST_AUTH_SERVICES_FAILURE = 'POST_AUTH_SERVICES_FAILURE';
export const POST_AUTH_SERVICES_REQUEST = 'POST_AUTH_SERVICES_REQUEST';
export const POST_AUTH_SERVICES_SUCCESS = 'POST_AUTH_SERVICES_SUCCESS';

export const UPDATE_AUTH_SERVICES_FAILURE = 'UPDATE_AUTH_SERVICES_FAILURE';
export const UPDATE_AUTH_SERVICES_REQUEST = 'UPDATE_AUTH_SERVICES_REQUEST';
export const UPDATE_AUTH_SERVICES_SUCCESS = 'UPDATE_AUTH_SERVICES_SUCCESS';

export const DELETE_AUTH_SERVICES_REQUEST = 'DELETE_AUTH_SERVICES_REQUEST';
export const DELETE_AUTH_SERVICES_SUCCESS = 'DELETE_AUTH_SERVICES_SUCCESS';
export const DELETE_AUTH_SERVICES_FAILURE = 'DELETE_AUTH_SERVICES_FAILURE';

export const GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_FAILURE = 'GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_FAILURE';
export const GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_REQUEST = 'GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_REQUEST';
export const GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_SUCCESS = 'GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_SUCCESS';
 
export const GET_AUTH_LIST_CLIENT_POLICIES_FAILURE = 'GET_AUTH_LIST_CLIENT_POLICIES_FAILURE';
export const GET_AUTH_LIST_CLIENT_POLICIES_REQUEST = 'GET_AUTH_LIST_CLIENT_POLICIES_REQUEST';
export const GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS = 'GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS';

export const POST_AUTH_LIST_CLIENT_POLICIES_FAILURE = 'POST_AUTH_LIST_CLIENT_POLICIES_FAILURE';
export const POST_AUTH_LIST_CLIENT_POLICIES_REQUEST = 'POST_AUTH_LIST_CLIENT_POLICIES_REQUEST';
export const POST_AUTH_LIST_CLIENT_POLICIES_SUCCESS = 'POST_AUTH_LIST_CLIENT_POLICIES_SUCCESS';

export const UPDATE_AUTH_LIST_CLIENT_POLICIES_FAILURE = 'UPDATE_AUTH_LIST_CLIENT_POLICIES_FAILURE';
export const UPDATE_AUTH_LIST_CLIENT_POLICIES_REQUEST = 'UPDATE_AUTH_LIST_CLIENT_POLICIES_REQUEST';
export const UPDATE_AUTH_LIST_CLIENT_POLICIES_SUCCESS = 'UPDATE_AUTH_LIST_CLIENT_POLICIES_SUCCESS';

export const DELETE_AUTH_LIST_CLIENT_POLICIES_FAILURE = 'DELETE_AUTH_LIST_CLIENT_POLICIES_FAILURE';
export const DELETE_AUTH_LIST_CLIENT_POLICIES_REQUEST = 'DELETE_AUTH_LIST_CLIENT_POLICIES_REQUEST';
export const DELETE_AUTH_LIST_CLIENT_POLICIES_SUCCESS = 'DELETE_AUTH_LIST_CLIENT_POLICIES_SUCCESS';

export const GET_ACTIVITY_LOG_FAILURE = 'GET_ACTIVITY_LOG_FAILURE';
export const GET_ACTIVITY_LOG_REQUEST = 'GET_ACTIVITY_LOG_REQUEST';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';

export const GET_USER_SETTINGS_FAILURE = 'GET_USER_SETTINGS_FAILURE';
export const GET_USER_SETTINGS_REQUEST = 'GET_USER_SETTINGS_REQUEST';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';

export const POST_USER_SETTINGS_FAILURE = 'POST_USER_SETTINGS_FAILURE';
export const POST_USER_SETTINGS_REQUEST = 'POST_USER_SETTINGS_REQUEST';
export const POST_USER_SETTINGS_SUCCESS = 'POST_USER_SETTINGS_SUCCESS';

export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';
export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';

export const GET_FAMILY_FAILURE = 'GET_FAMILY_FAILURE';
export const GET_FAMILY_REQUEST = 'GET_FAMILY_REQUEST';
export const GET_FAMILY_SUCCESS = 'GET_FAMILY_SUCCESS';

export const POST_FAMILY_FAILURE = 'POST_FAMILY_FAILURE';
export const POST_FAMILY_REQUEST = 'POST_FAMILY_REQUEST';
export const POST_FAMILY_SUCCESS = 'POST_FAMILY_SUCCESS';

export const UPDATE_FAMILY_FAILURE = 'UPDATE_FAMILY_FAILURE';
export const UPDATE_FAMILY_REQUEST = 'UPDATE_FAMILY_REQUEST';
export const UPDATE_FAMILY_SUCCESS = 'UPDATE_FAMILY_SUCCESS';

export const DELETE_FAMILY_FAILURE = 'DELETE_FAMILY_FAILURE';
export const DELETE_FAMILY_REQUEST = 'DELETE_FAMILY_REQUEST';
export const DELETE_FAMILY_SUCCESS = 'DELETE_FAMILY_SUCCESS';

export const UPDATE_FAMILY_DATA = 'UPDATE_FAMILY_DATA';

export const GET_FAMILY_PET_FAILURE = 'GET_FAMILY_PET_FAILURE';
export const GET_FAMILY_PET_REQUEST = 'GET_FAMILY_PET_REQUEST';
export const GET_FAMILY_PET_SUCCESS = 'GET_FAMILY_PET_SUCCESS';

export const POST_FAMILY_PET_FAILURE = 'POST_FAMILY_PET_FAILURE';
export const POST_FAMILY_PET_REQUEST = 'POST_FAMILY_PET_REQUEST';
export const POST_FAMILY_PET_SUCCESS = 'POST_FAMILY_PET_SUCCESS';

export const UPDATE_FAMILY_PET_FAILURE = 'UPDATE_FAMILY_PET_FAILURE';
export const UPDATE_FAMILY_PET_REQUEST = 'UPDATE_FAMILY_PET_REQUEST';
export const UPDATE_FAMILY_PET_SUCCESS = 'UPDATE_FAMILY_PET_SUCCESS';

export const DELETE_FAMILY_PET_FAILURE = 'DELETE_FAMILY_PET_FAILURE';
export const DELETE_FAMILY_PET_REQUEST = 'DELETE_FAMILY_PET_REQUEST';
export const DELETE_FAMILY_PET_SUCCESS = 'DELETE_FAMILY_PET_SUCCESS';

export const UPDATE_FAMILY_PET_DATA = 'UPDATE_FAMILY_PET_DATA';


