export const GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE = 'GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE';
export const GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST = 'GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST';
export const GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS = 'GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS';

export const GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE = 'GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE';
export const GET_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST = 'GET_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST';
export const GET_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS = 'GET_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS';

export const POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE = 'POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE';
export const POST_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST = 'POST_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST';
export const POST_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS = 'POST_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS';

export const UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE = 'UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE';
export const UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST = 'UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST';
export const UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS = 'UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS';

export const DELETE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE = 'DELETE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE';
export const DELETE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST = 'DELETE_GLOBAL_HOME_SERVICE_PROPERTY_REQUEST';
export const DELETE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS = 'DELETE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS';

export const GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_FAILURE = 'GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_FAILURE';
export const GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_REQUEST = 'GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_REQUEST';
export const GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_SUCCESS = 'GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_SUCCESS';

export const GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE = 'GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE';
export const GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST = 'GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST';
export const GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS = 'GET_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS';

export const POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE = 'POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE';
export const POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST = 'POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST';
export const POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS = 'POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS';

export const UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE = 'UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE';
export const UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST = 'UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST';
export const UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS = 'UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS';

export const DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE = 'DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE';
export const DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST = 'DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_REQUEST';
export const DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS = 'DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_SUCCESS';

export const GET_GLOBAL_DESTINATION_SERVICES_FAILURE = 'GET_GLOBAL_DESTINATION_SERVICES_FAILURE';
export const GET_GLOBAL_DESTINATION_SERVICES_REQUEST = 'GET_GLOBAL_DESTINATION_SERVICES_REQUEST';
export const GET_GLOBAL_DESTINATION_SERVICES_SUCCESS = 'GET_GLOBAL_DESTINATION_SERVICES_SUCCESS';

export const POST_GLOBAL_DESTINATION_SERVICES_FAILURE = 'POST_GLOBAL_DESTINATION_SERVICES_FAILURE';
export const POST_GLOBAL_DESTINATION_SERVICES_REQUEST = 'POST_GLOBAL_DESTINATION_SERVICES_REQUEST';
export const POST_GLOBAL_DESTINATION_SERVICES_SUCCESS = 'POST_GLOBAL_DESTINATION_SERVICES_SUCCESS';

export const UPDATE_GLOBAL_DESTINATION_SERVICES_FAILURE = 'UPDATE_GLOBAL_DESTINATION_SERVICES_FAILURE';
export const UPDATE_GLOBAL_DESTINATION_SERVICES_REQUEST = 'UPDATE_GLOBAL_DESTINATION_SERVICES_REQUEST';
export const UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS = 'UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS';

export const DELETE_GLOBAL_DESTINATION_SERVICES_FAILURE = 'DELETE_GLOBAL_DESTINATION_SERVICES_FAILURE';
export const DELETE_GLOBAL_DESTINATION_SERVICES_REQUEST = 'DELETE_GLOBAL_DESTINATION_SERVICES_REQUEST';
export const DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS = 'DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS';

export const GET_GLOBAL_LANGUAGE_TRAINING_FAILURE = 'GET_GLOBAL_LANGUAGE_TRAINING_FAILURE';
export const GET_GLOBAL_LANGUAGE_TRAINING_REQUEST = 'GET_GLOBAL_LANGUAGE_TRAINING_REQUEST';
export const GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS = 'GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS';

export const POST_GLOBAL_LANGUAGE_TRAINING_FAILURE = 'POST_GLOBAL_LANGUAGE_TRAINING_FAILURE';
export const POST_GLOBAL_LANGUAGE_TRAINING_REQUEST = 'POST_GLOBAL_LANGUAGE_TRAINING_REQUEST';
export const POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS = 'POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS';

export const UPDATE_GLOBAL_LANGUAGE_TRAINING_FAILURE = 'UPDATE_GLOBAL_LANGUAGE_TRAINING_FAILURE';
export const UPDATE_GLOBAL_LANGUAGE_TRAINING_REQUEST = 'UPDATE_GLOBAL_LANGUAGE_TRAINING_REQUEST';
export const UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS = 'UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS';

export const DELETE_GLOBAL_LANGUAGE_TRAINING_FAILURE = 'DELETE_GLOBAL_LANGUAGE_TRAINING_FAILURE';
export const DELETE_GLOBAL_LANGUAGE_TRAINING_REQUEST = 'DELETE_GLOBAL_LANGUAGE_TRAINING_REQUEST';
export const DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS = 'DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS';

export const GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE = 'GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE';
export const GET_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST = 'GET_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST';
export const GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS = 'GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS';

export const POST_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE = 'POST_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE';
export const POST_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST = 'POST_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST';
export const POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS = 'POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS';

export const UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE = 'UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE';
export const UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST = 'UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST';
export const UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS = 'UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS';

export const DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE = 'DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE';
export const DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST = 'DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_REQUEST';
export const DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS = 'DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS';

export const GET_GLOBAL_INFO_FAILURE = 'GET_GLOBAL_INFO_FAILURE';
export const GET_GLOBAL_INFO_REQUEST = 'GET_GLOBAL_INFO_REQUEST';
export const GET_GLOBAL_INFO_SUCCESS = 'GET_GLOBAL_INFO_SUCCESS';

export const POST_GLOBAL_INFO_FAILURE = 'POST_GLOBAL_INFO_FAILURE';
export const POST_GLOBAL_INFO_REQUEST = 'POST_GLOBAL_INFO_REQUEST';
export const POST_GLOBAL_INFO_SUCCESS = 'POST_GLOBAL_INFO_SUCCESS';

export const UPDATE_GLOBAL_INFO_FAILURE = 'UPDATE_GLOBAL_INFO_FAILURE';
export const UPDATE_GLOBAL_INFO_REQUEST = 'UPDATE_GLOBAL_INFO_REQUEST';
export const UPDATE_GLOBAL_INFO_SUCCESS = 'UPDATE_GLOBAL_INFO_SUCCESS';

export const GET_ASSIGNMENT_TRIPS_FAILURE = 'GET_ASSIGNMENT_TRIPS_FAILURE';
export const GET_ASSIGNMENT_TRIPS_REQUEST = 'GET_ASSIGNMENT_TRIPS_REQUEST';
export const GET_ASSIGNMENT_TRIPS_SUCCESS = 'GET_ASSIGNMENT_TRIPS_SUCCESS';

export const POST_ASSIGNMENT_TRIPS_FAILURE = 'POST_ASSIGNMENT_TRIPS_FAILURE';
export const POST_ASSIGNMENT_TRIPS_REQUEST = 'POST_ASSIGNMENT_TRIPS_REQUEST';
export const POST_ASSIGNMENT_TRIPS_SUCCESS = 'POST_ASSIGNMENT_TRIPS_SUCCESS';

export const UPDATE_ASSIGNMENT_TRIPS_FAILURE = 'UPDATE_ASSIGNMENT_TRIPS_FAILURE';
export const UPDATE_ASSIGNMENT_TRIPS_REQUEST = 'UPDATE_ASSIGNMENT_TRIPS_REQUEST';
export const UPDATE_ASSIGNMENT_TRIPS_SUCCESS = 'UPDATE_ASSIGNMENT_TRIPS_SUCCESS';

export const DELETE_ASSIGNMENT_TRIPS_FAILURE = 'DELETE_ASSIGNMENT_TRIPS_FAILURE';
export const DELETE_ASSIGNMENT_TRIPS_REQUEST = 'DELETE_ASSIGNMENT_TRIPS_REQUEST';
export const DELETE_ASSIGNMENT_TRIPS_SUCCESS = 'DELETE_ASSIGNMENT_TRIPS_SUCCESS';

export const GET_ASSIGNMENT_EXTENSIONS_FAILURE = 'GET_ASSIGNMENT_EXTENSIONS_FAILURE';
export const GET_ASSIGNMENT_EXTENSIONS_REQUEST = 'GET_ASSIGNMENT_EXTENSIONS_REQUEST';
export const GET_ASSIGNMENT_EXTENSIONS_SUCCESS = 'GET_ASSIGNMENT_EXTENSIONS_SUCCESS';

export const POST_ASSIGNMENT_EXTENSIONS_FAILURE = 'POST_ASSIGNMENT_EXTENSIONS_FAILURE';
export const POST_ASSIGNMENT_EXTENSIONS_REQUEST = 'POST_ASSIGNMENT_EXTENSIONS_REQUEST';
export const POST_ASSIGNMENT_EXTENSIONS_SUCCESS = 'POST_ASSIGNMENT_EXTENSIONS_SUCCESS';

export const UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE = 'UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE';
export const UPDATE_ASSIGNMENT_EXTENSIONS_REQUEST = 'UPDATE_ASSIGNMENT_EXTENSIONS_REQUEST';
export const UPDATE_ASSIGNMENT_EXTENSIONS_SUCCESS = 'UPDATE_ASSIGNMENT_EXTENSIONS_SUCCESS';

export const DELETE_ASSIGNMENT_EXTENSIONS_FAILURE = 'DELETE_ASSIGNMENT_EXTENSIONS_FAILURE';
export const DELETE_ASSIGNMENT_EXTENSIONS_REQUEST = 'DELETE_ASSIGNMENT_EXTENSIONS_REQUEST';
export const DELETE_ASSIGNMENT_EXTENSIONS_SUCCESS = 'DELETE_ASSIGNMENT_EXTENSIONS_SUCCESS';
