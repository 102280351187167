/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import {
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Field, FieldArray } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get, values as getValues, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  AutosaveDeleteButton,
  Checkbox,
  RadioGroup,
  Select,
  TextField,
  ToggleSelect,
} from 'modules/intake/form';
import { COUNTRY_CODES } from 'modules/common/countryCodes';
import { Contact, Email, Pet, Phone, isCandidateMoveType, isInternMoveType, isSpouseContactType, isTransfereeContactType } from 'modules/intake/models';
import { calculateAge, isNotDefined } from 'utilities/common';
import { convertPhoneNumber } from './utils';
import { showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';

const styles = (theme) => ({
  inputRowSelect: {
    width: 140,
  },
  phoneInputRowSelect: {
    width: 120,
  },
  phoneNumberInputRowSelect: {
    width: 160,
  },
  countryCodeSelect: {
    width: 155,
  },
  inputRowSmallFormControl: {
    marginRight: theme.spacing(2),
    maxWidth: 80,
  },
  phoneInputMargin: {
    width: 140,
    marginTop: theme.spacing(1.75),
    marginRight: theme.spacing(21),
    paddingBottom: theme.spacing(1),
    fontSize: 13,
  },
  customGridItem: {
    '@media (min-width: 1645px)': {
      flexBasis: '50%',
      maxWidth: '50%',
    },
    '@media (min-width: 1301px) and (max-width: 1644px)': {
      flexBasis: '67%',
      maxWidth: '67%',
    },
    '@media (max-width: 1300px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
});

const findPrimary = (arrayHelpers) => {
  const collection = get(arrayHelpers.form.values, arrayHelpers.name, []);
  return collection.find((instance) => instance.isPrimary);
};

const findPrimaryIndex = (arrayHelpers) => {
  const collection = get(arrayHelpers.form.values, arrayHelpers.name, []);
  return collection.findIndex((instance) => instance.isPrimary);
};

const addContact = (arrayHelpers, contactMetadata) => () => {
  arrayHelpers.push(new Contact(null, contactMetadata));
};

const addPet = (arrayHelpers) => () => {
  arrayHelpers.push(new Pet());
};

const addPhoneNumber = (arrayHelpers) => () => {
  const hasPrimary = !!findPrimary(arrayHelpers);
  arrayHelpers.push(new Phone({ isPrimary: !hasPrimary }));
};

const addEmailAddress = (arrayHelpers) => () => {
  const hasPrimary = !!findPrimary(arrayHelpers);
  arrayHelpers.push(new Email({ isPrimary: !hasPrimary }));
};

const getSelectableContactTypes = (contacts, contact, contactMetadata) => {
  const spouse = contacts.find((instance) => {
    return instance.isSpouse();
  });
  return getValues(contactMetadata).filter((contactType) => {
    return !isTransfereeContactType(contactType.id, contactMetadata);
  }).map((contactType) => {
    const isSpouseType = isSpouseContactType(contactType.id, contactMetadata);
    const disableSpouse = spouse && spouse !== contact;
    return {
      ...contactType,
      disabled: isSpouseType && disableSpouse,
    };
  });
};

const canChangePrimaryPhone = (arrayHelpers) => {
  return arrayHelpers.form.values.familyInformation.phoneNumbers.length > 1;
};

const setPrimaryPhone = (arrayHelpers, index) => {
  const oldPrimary = findPrimary(arrayHelpers);
  const oldPrimaryIndex = findPrimaryIndex(arrayHelpers);
  const newPrimary = arrayHelpers.form.values.familyInformation.phoneNumbers[index];

  arrayHelpers.replace(oldPrimaryIndex, new Phone({ ...oldPrimary, isPrimary: false }));
  arrayHelpers.replace(index, new Phone({ ...newPrimary, isPrimary: true }));
};

const canRemoveItem = (arrayHelpers, index) => {
  const collection = get(arrayHelpers.form.values, arrayHelpers.name, []);
  return !collection[index].isPrimary || collection.length === 1;
};

const canChangePrimaryEmail = (arrayHelpers) => {
  return arrayHelpers.form.values.familyInformation.emailAddresses.length > 1;
};

const setPrimaryEmail = (arrayHelpers, index) => {
  const oldPrimary = findPrimary(arrayHelpers);
  const oldPrimaryIndex = findPrimaryIndex(arrayHelpers);
  const newPrimary = arrayHelpers.form.values.familyInformation.emailAddresses[index];

  arrayHelpers.replace(oldPrimaryIndex, new Email({ ...oldPrimary, isPrimary: false }));
  arrayHelpers.replace(index, new Email({ ...newPrimary, isPrimary: true }));
};

// disable contact type if same contact owner type already has that contact type
// ie, the transferee can only have two phone numbers if they are different types (home and mobile)
const isContactTypeDisabled = (idToCheck, currentInstance, allInstances, idKey) => {
  if (idToCheck === currentInstance[idKey]) {
    return false;
  }

  return !!allInstances.find((instance) => {
    return instance[idKey] === idToCheck && instance.contactInfoOwnerTypeId === currentInstance.contactInfoOwnerTypeId;
  });
};

// disable contact owner type if there is already an instance with the same contact type / contact owner type combination
const isContactOwnerTypeDisabled = (contactOwnerTypeId, currentInstance, allInstances, idKey) => {
  if (contactOwnerTypeId === currentInstance.contactInfoOwnerTypeId || isNotDefined(currentInstance[idKey])) {
    return false;
  }

  return !!allInstances.find((instance) => {
    return instance.contactInfoOwnerTypeId === contactOwnerTypeId && instance[idKey] === currentInstance[idKey];
  });
};


class FamilyInformation extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.familyInformation, nextProps.familyInformation) ||
      !isEqual(this.props.eeIntakeForm, nextProps.eeIntakeForm) ||
      this.props.isSubmitting !== nextProps.isSubmitting;
  }

  handleBirthdayChange = (event, index) => {
    const dateOfBirth = event.target.value;
    const today = new Date();
    const birthDateObj = new Date(dateOfBirth);
  
    if (birthDateObj > today) {
      this.props.setFieldValue(`familyInformation.contacts.${index}.dateOfBirth`, '');
      this.props.setFieldValue(`familyInformation.contacts.${index}.age`, '');
      this.props.setFieldError(`familyInformation.contacts.${index}.dateOfBirth`, 'Date of birth cannot be in the future');
    } else {
      const newAge = calculateAge(dateOfBirth);
      this.props.setFieldValue(`familyInformation.contacts.${index}.dateOfBirth`, dateOfBirth);
      this.props.setFieldValue(`familyInformation.contacts.${index}.age`, newAge);
      this.props.setFieldError(`familyInformation.contacts.${index}.dateOfBirth`, '');
    }

    this.props.setFieldTouched(`familyInformation.contacts.${index}.dateOfBirth`, true, false);
  };


  handlePhoneNumberChange = (phoneNumber, index) => {
    this.props.setFieldValue(`familyInformation.phoneNumbers.${index}.phoneNumber`, convertPhoneNumber(phoneNumber.target.value, this.props.familyInformation.phoneNumbers[index].countryCode));
  }

  handleCountryCodeChange = (countryCode, index) => {
    this.props.setFieldValue(`familyInformation.phoneNumbers.${index}.countryCode`, countryCode.target.value);
    this.props.setFieldValue(`familyInformation.phoneNumbers.${index}.phoneNumber`, convertPhoneNumber(this.props.familyInformation.phoneNumbers[index].phoneNumber, countryCode.target.value));
  }
  
  removeDuplicatePrimaryEmails() {
    let primaryFound = false;
    this.props.familyInformation.emailAddresses.map((e, index) => {
      if (primaryFound && e.isPrimary) {
        this.props.setFieldValue(`familyInformation.emailAddresses.${index}.isPrimary`, false, false);
      } else if (!primaryFound && e.isPrimary) {
        primaryFound = true;
      }
    });
  }

  removeDuplicatePrimaryPhones() {
    let primaryFound = false;
    this.props.familyInformation.phoneNumbers.map((e, index) => {
      if (primaryFound && e.isPrimary) {
        this.props.setFieldValue(`familyInformation.phoneNumbers.${index}.isPrimary`, false, false);
      } else if (!primaryFound && e.isPrimary) {
        primaryFound = true;
      }
    });
  }

  containsPetMatch(obj, objList) {
    let hasMatch = false;
    objList.map((o) => {
      if (isEqual(o.breed, obj.breed) && isEqual(o.petName, obj.petName) && isEqual(o.petTypeId, obj.petTypeId) && isEqual(o.weight, obj.weight)) {
        hasMatch = true;
      }
    });
    return hasMatch;
  }

  containsContactMatch(obj, objList) {
    let hasMatch = false;
    objList.map((o) => {
      if (isEqual(o.firstName, obj.firstName) && isEqual(o.lastName, obj.lastName) && isEqual(o.contactTypeId, obj.contactTypeId) && isEqual(o.age, obj.age)) {
        hasMatch = true;
      }
    });
    return hasMatch;
  }

  containsPhoneMatch(obj, objList) {
    let hasMatch = false;
    objList.map((o) => {
      if (isEqual(o.phoneNumber, obj.phoneNumber) && isEqual(o.phoneTypeId, obj.phoneTypeId) && isEqual(o.contactInfoOwnerTypeId, obj.contactInfoOwnerTypeId)) {
        hasMatch = true;
      }
    });
    return hasMatch;
  }

  containsEmailMatch(obj, objList) {
    let hasMatch = false;
    objList.map((o) => {
      if (isEqual(o.emailAddress, obj.emailAddress) && isEqual(o.emailTypeId, obj.emailTypeId) && isEqual(o.contactInfoOwnerTypeId, obj.contactInfoOwnerTypeId)) {
        hasMatch = true;
      }
    });
    return hasMatch;
  }

  componentDidMount() {
    this.removeDuplicatePrimaryEmails();
    this.removeDuplicatePrimaryPhones();
  }

  renderContacts() {
    const {
      moveTypeId,
      classes,
      familyInformation,
      handleChange,
      handleBlur,
      metadata: { contactTypes, moveTypes },
      isSubmitting,
      eeIntakeForm,
    } = this.props;

    const isCandidateOrIntern = (isCandidateMoveType(moveTypeId, moveTypes) || isInternMoveType(moveTypeId, moveTypes));
    return (
      <Grid item className={classes.customGridItem}>
        {!isCandidateOrIntern &&
          <>
            <FieldArray
              name="familyInformation.contacts"
              render={(arrayHelpers) => (
                <Paper classes={{ root: 'card-root' }}>
                  <Grid container item alignItems="center" justify="space-between">
                    <FormLabel>Spouse/SO Information</FormLabel>
                    <Button disabled={isSubmitting} onClick={addContact(arrayHelpers, contactTypes)}>Add</Button>
                  </Grid>
                  {familyInformation.contacts.map((contact, index) => (
                    <div>
                      <div className="input-row" key={index}>
                        <div>
                          <Field
                            component={TextField}
                            name={`familyInformation.contacts.${index}.firstName`}
                            label="First Name"
                            value={contact.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Field
                            component={TextField}
                            name={`familyInformation.contacts.${index}.lastName`}
                            label="Last Name"
                            value={contact.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Field
                            component={Select}
                            name={`familyInformation.contacts.${index}.contactTypeId`}
                            label="Relationship"
                            className={classes.inputRowSelect}
                            value={contact.contactTypeId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelWidth={76}
                          >
                            {getSelectableContactTypes(familyInformation.contacts, contact, contactTypes).map((contactType) => (
                              <MenuItem key={contactType.id} disabled={contactType.disabled} value={contactType.id}>{contactType.description}</MenuItem>
                            ))}
                          </Field>
                          {contact.isChild() && (
                            <>
                              <Field
                                component={TextField}
                                name={`familyInformation.contacts.${index}.dateOfBirth`}
                                label="Birthday"
                                value={contact.dateOfBirth}
                                onChange={(event) => {
                                  this.handleBirthdayChange(event, index);
                                  handleChange(event);
                                }}
                                onBlur={handleBlur}
                                type="date"
                              />
                              <Field
                                component={TextField}
                                name={`familyInformation.contacts.${index}.age`}
                                label="Age"
                                className={classes.inputRowSmallFormControl}
                                value={contact.age}
                                disabled={true}
                                onBlur={handleBlur}
                                numeric
                              />
                            </>
                          )}
                        </div>
                        <AutosaveDeleteButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
                      </div>
                      {eeIntakeForm?.record.isSubmitted && this.containsContactMatch(contact, eeIntakeForm?.contacts) && (
                        <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
                      )}
                    </div>
                  ))}
                </Paper>
              )}
            />
          </>
        }
        {isCandidateOrIntern &&
          <>
            <FieldArray
              name="familyInformation.contacts"
              render={(arrayHelpers) => (
                <Paper classes={{ root: 'card-root' }}>
                  <Grid container item alignItems="center" justify="space-between">
                    <FormLabel>Accompanying Spouse/Dependant Information</FormLabel>
                    <Button disabled={isSubmitting} onClick={addContact(arrayHelpers, contactTypes)}>Add</Button>
                  </Grid>
                  {familyInformation.contacts.map((contact, index) => (
                    <div>
                      <div className="input-row" key={index}>
                        <div>
                          <Field
                            component={TextField}
                            name={`familyInformation.contacts.${index}.firstName`}
                            label="First Name"
                            value={contact.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Field
                            component={TextField}
                            name={`familyInformation.contacts.${index}.lastName`}
                            label="Last Name"
                            value={contact.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Field
                            component={Select}
                            name={`familyInformation.contacts.${index}.contactTypeId`}
                            label="Relationship"
                            className={classes.inputRowSelect}
                            value={contact.contactTypeId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelWidth={76}
                          >
                            {getSelectableContactTypes(familyInformation.contacts, contact, contactTypes).map((contactType) => (
                              <MenuItem key={contactType.id} disabled={contactType.disabled} value={contactType.id}>{contactType.description}</MenuItem>
                            ))}
                          </Field>
                          {contact.isChild() && (
                            <>
                              <Field
                                component={TextField}
                                name={`familyInformation.contacts.${index}.dateOfBirth`}
                                label="Birthday"
                                defaultValue={contact.dateOfBirth}
                                onChange={(event) => {
                                  this.handleBirthdayChange(event, index);
                                  handleChange(event);
                                }}
                                type="date"
                              />
                              <Field
                                component={TextField}
                                name={`familyInformation.contacts.${index}.age`}
                                label="Age"
                                className={classes.inputRowSmallFormControl}
                                value={contact.age}
                                disabled={true}
                                numeric
                              />
                            </>
                          )}
                        </div>
                        <AutosaveDeleteButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
                      </div>
                      {eeIntakeForm?.record.isSubmitted && this.containsContactMatch(contact, eeIntakeForm?.contacts) && (
                        <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
                      )}
                    </div>
                  ))}
                </Paper>
              )}
            />
          </>
        }
      </Grid>
    );
  }

  renderPets() {
    const {
      moveTypeId,
      classes,
      familyInformation,
      handleChange,
      handleBlur,
      metadata: { moveTypes, petTypes },
      isSubmitting,
      eeIntakeForm,
    } = this.props;

    const isCandidateOrIntern = (isCandidateMoveType(moveTypeId, moveTypes) || isInternMoveType(moveTypeId, moveTypes));

    return (
      <Grid item className={classes.customGridItem}>
        {!isCandidateOrIntern && (
          <>
            <FieldArray
              name="familyInformation.pets"
              render={(arrayHelpers) => (
                <Paper classes={{ root: 'card-root' }}>
                  <Grid container item alignItems="center" justify="space-between">
                    <FormLabel>Pet Information</FormLabel>
                    <Button disabled={isSubmitting} onClick={addPet(arrayHelpers)}>Add Pet</Button>
                  </Grid>
                  {familyInformation.pets.map((pet, index) => (
                    <div>
                      <div className="input-row" key={index}>
                        <div>
                          <Field
                            component={Select}
                            name={`familyInformation.pets.${index}.petTypeId`}
                            label="Type"
                            className={classes.inputRowSelect}
                            value={pet.petTypeId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelWidth={30}
                          >
                            {getValues(petTypes).map((petType) => (
                              <MenuItem key={petType.id} value={petType.id}>{petType.description}</MenuItem>
                            ))}
                          </Field>
                          <Field
                            component={TextField}
                            name={`familyInformation.pets.${index}.petName`}
                            label="Pet Name"
                            value={pet.petName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Field
                            component={TextField}
                            name={`familyInformation.pets.${index}.breed`}
                            label="Breed"
                            value={pet.breed}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Field
                            component={TextField}
                            name={`familyInformation.pets.${index}.weight`}
                            label="Weight"
                            value={pet.weight}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            numeric
                          />
                        </div>
                        <AutosaveDeleteButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
                      </div>
                      {eeIntakeForm?.record.isSubmitted && this.containsPetMatch(pet, eeIntakeForm?.pets) && (
                        <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
                      )}
                    </div>
                  ))}
                </Paper>
              )}
            />
          </>
        )}
        
      </Grid>
    );
  }

  renderPhones() {
    const {
      classes,
      familyInformation,
      handleChange,
      handleBlur,
      metadata: { phoneTypes, contactInfoOwnerTypes },
      isSubmitting,
      eeIntakeForm,
    } = this.props;

    let countryOptions = [];
    if (COUNTRY_CODES) {
      countryOptions = COUNTRY_CODES.map((country) => ({
        value: country['alpha-3'],
        label: country.name,
      }));
    }

    return (
      <Grid item className={classes.customGridItem}>
        <FieldArray
          name="familyInformation.phoneNumbers"
          render={(arrayHelpers) => (
            <Paper classes={{ root: 'card-root' }}>
              <Grid container item alignItems="center" justify="space-between">
                <FormLabel>Phone Information</FormLabel>
                <Button disabled={isSubmitting} onClick={addPhoneNumber(arrayHelpers)}>Add Phone</Button>
              </Grid>
              {familyInformation.phoneNumbers.map((phoneNumber, index) => (
                <div>
                  <div className="input-row" key={index}>
                    <Field
                      component={Select}
                      name={`familyInformation.phoneNumbers.${index}.countryCode`}
                      label="Country"
                      className={classes.countryCodeSelect}
                      value={phoneNumber.countryCode}
                      onChange={(value) => this.handleCountryCodeChange(value, index)}
                      onBlur={handleBlur}
                      labelWidth={40}
                    >
                      {countryOptions.map((country) => (
                        <MenuItem
                          key={country.value}
                          value={country.value}
                        >
                          {country.label}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      component={TextField}
                      name={`familyInformation.phoneNumbers.${index}.phoneNumber`}
                      label="Phone Number"
                      value={convertPhoneNumber(phoneNumber.phoneNumber, phoneNumber.countryCode)}
                      onChange={(value) => this.handlePhoneNumberChange(value, index)}
                      onBlur={handleBlur}
                      className={classes.phoneNumberInputRowSelect}
                    />
                    <Field
                      component={Select}
                      name={`familyInformation.phoneNumbers.${index}.phoneTypeId`}
                      label="Type"
                      className={classes.phoneInputRowSelect}
                      value={phoneNumber.phoneTypeId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelWidth={30}
                    >
                      {getValues(phoneTypes).map((phoneType) => (
                        <MenuItem
                          key={phoneType.id}
                          value={phoneType.id}
                          disabled={isContactTypeDisabled(phoneType.id, phoneNumber, familyInformation.phoneNumbers, 'phoneTypeId')}
                        >
                          {phoneType.description}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      component={Select}
                      name={`familyInformation.phoneNumbers.${index}.contactInfoOwnerTypeId`}
                      label="Owner"
                      className={classes.inputRowSelect}
                      value={phoneNumber.contactInfoOwnerTypeId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelWidth={40}
                    >
                      {getValues(contactInfoOwnerTypes).map((ownerType) => (
                        <MenuItem
                          key={ownerType.id}
                          value={ownerType.id}
                          disabled={isContactOwnerTypeDisabled(ownerType.id, phoneNumber, familyInformation.phoneNumbers, 'phoneTypeId')}
                        >
                          {ownerType.description}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      component={Checkbox}
                      name={`familyInformation.phoneNumbers.${index}.isPrimary`}
                      label="Primary"
                      value={phoneNumber.isPrimary}
                      onChange={() => {
                        setPrimaryPhone(arrayHelpers, index);
                        handleChange();
                      }}
                      onBlur={handleBlur}
                      disabled={!canChangePrimaryPhone(arrayHelpers)}
                      alignCenter
                    />
                  </div>
                  {eeIntakeForm?.record.isSubmitted && this.containsPhoneMatch(phoneNumber, eeIntakeForm?.phones) && (
                    <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
                  )}
                </div>
              ))}
            </Paper>
          )}
        />
      </Grid>
    );
  }

  renderEmails() {
    const {
      classes,
      familyInformation,
      handleChange,
      handleBlur,
      metadata: { emailTypes, contactInfoOwnerTypes },
      isSubmitting,
      eeIntakeForm,
    } = this.props;

    return (
      <Grid item className={classes.customGridItem}>
        <FieldArray
          name="familyInformation.emailAddresses"
          render={(arrayHelpers) => (
            <Paper classes={{ root: 'card-root' }}>
              <Grid container item alignItems="center" justify="space-between">
                <FormLabel>Email Information</FormLabel>
                <Button disabled={isSubmitting} onClick={addEmailAddress(arrayHelpers)}>Add Email</Button>
              </Grid>
              {familyInformation.emailAddresses.map((emailAddress, index) => (
                <div>
                  <div className="input-row" key={index}>
                    <div>
                      <Field
                        component={TextField}
                        name={`familyInformation.emailAddresses.${index}.emailAddress`}
                        label="Email"
                        value={emailAddress.emailAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Field
                        component={Select}
                        name={`familyInformation.emailAddresses.${index}.emailTypeId`}
                        label="Type"
                        className={classes.inputRowSelect}
                        value={emailAddress.emailTypeId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelWidth={30}
                      >
                        {getValues(emailTypes).map((emailType) => (
                          <MenuItem
                            key={emailType.id}
                            value={emailType.id}
                            disabled={isContactTypeDisabled(emailType.id, emailAddress, familyInformation.emailAddresses, 'emailTypeId')}
                          >
                            {emailType.description}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        component={Select}
                        name={`familyInformation.emailAddresses.${index}.contactInfoOwnerTypeId`}
                        label="Owner"
                        className={classes.inputRowSelect}
                        value={emailAddress.contactInfoOwnerTypeId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelWidth={40}
                      >
                        {getValues(contactInfoOwnerTypes).map((ownerType) => (
                          <MenuItem
                            key={ownerType.id}
                            value={ownerType.id}
                            disabled={isContactOwnerTypeDisabled(ownerType.id, emailAddress, familyInformation.emailAddresses, 'emailTypeId')}
                          >
                            {ownerType.description}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        component={Checkbox}
                        name={`familyInformation.emailAddresses.${index}.isPrimary`}
                        label="Primary"
                        value={emailAddress.isPrimary}
                        onChange={() => {
                          setPrimaryEmail(arrayHelpers, index);
                          handleChange();
                        }}
                        onBlur={handleBlur}
                        disabled={!canChangePrimaryEmail(arrayHelpers)}
                        alignCenter
                      />
                    </div>
                    <AutosaveDeleteButton disabled={isSubmitting || !canRemoveItem(arrayHelpers, index)} onClick={() => arrayHelpers.remove(index)} />
                  </div>
                  {eeIntakeForm?.record.isSubmitted && this.containsEmailMatch(emailAddress, eeIntakeForm?.emails) && (
                    <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
                  )}
                </div>
              ))}
            </Paper>
          )}
        />
      </Grid>
    );
  }

  render() {
    Log.trace('RENDER', 'FamilyInformation');
    const {
      moveTypeId,
      familyInformation,
      handleChange,
      handleBlur,
      metadata: {
        contactPreferenceTypes,
        maritalStatuses,
        moveTypes,
        taxFilingStatuses,
      },
      eeIntakeForm,
    } = this.props;
    const isCandidateOrIntern = (isCandidateMoveType(moveTypeId, moveTypes) || isInternMoveType(moveTypeId, moveTypes));
    return (
      <div className="form-group">
        <Typography gutterBottom variant="h3">General & Family Information</Typography>
        {!isCandidateOrIntern &&
          <>
            <div className="row">
              <Field
                component={RadioGroup}
                label="Marital Status"
                name="familyInformation.maritalStatusId"
                value={familyInformation.maritalStatusId}
                onChange={handleChange}
                onBlur={handleBlur}
                options={maritalStatuses}
              />
            </div>
            {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.maritalStatusId && (
              <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
            )}

            <div className="row mt-2">
              <Field
                component={RadioGroup}
                label="Tax Filing Status"
                name="familyInformation.taxFilingStatusId"
                value={familyInformation.taxFilingStatusId}
                onChange={handleChange}
                onBlur={handleBlur}
                options={taxFilingStatuses}
              />
            </div>
            {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.taxFilingStatusId && (
              <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
            )}
          </>
        }
        <Grid container spacing={2}>
          {this.renderContacts()}
          {this.renderPets()}
          {this.renderPhones()}
          {this.renderEmails()}
        </Grid>

        <div className="row mt-2">
          <Field
            component={ToggleSelect}
            name="familyInformation.contactPreferenceTypeId"
            label="Contact Preference"
            labelPlacement="top"
            value={familyInformation.contactPreferenceTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={getValues(contactPreferenceTypes)}
            variant="spread"
          />
        </div>
      </div>
    );
  }
}

FamilyInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  familyInformation: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, {
    showToast,
  }),
)(FamilyInformation);
