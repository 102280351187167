import { Box } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ClientContactsTable from './dataTables/clientContactsTable.component';
import ClientGeneralNotesTable from './dataTables/clientGeneralNotesTable.component';
import ClientIssuesAndChallengesTable from './dataTables/clientIssuesAndChallengesTable.component';
import ClientOverviewTable from './dataTables/clientOverviewTable.component';
import ClientSpecialProcessesTable from './dataTables/clientSpecialProcessesTable';
import PropTypes from 'prop-types';
import React from 'react';
import TRCTeamTable from './dataTables/trcTeamTable.component';

const GeneralInfoTab = ({ formikProps, clientContacts, clientSpecialProcesses, clientIssuesAndChallenges, clientGeneralNotes }) => {
  return (
    <Box marginX={2} marginY={4}>
      <ClientOverviewTable formikProps={formikProps} />
      { clientContacts && <ClientContactsTable clientId={formikProps.values.moveTrackClientId} />  }
      { clientSpecialProcesses && <ClientSpecialProcessesTable clientId={formikProps.values.moveTrackClientId} />  }
      <TRCTeamTable formikProps={formikProps} />
      { clientIssuesAndChallenges && <ClientIssuesAndChallengesTable clientId={formikProps.values.moveTrackClientId} /> }
      { clientGeneralNotes && <ClientGeneralNotesTable clientId={formikProps.values.moveTrackClientId} /> }


    </Box>
  );
};

GeneralInfoTab.propTypes = {
  formikProps: PropTypes.object.isRequired,
  clientContacts: PropTypes.array,
  clientIssuesAndChallenges: PropTypes.array,
  clientGeneralNotes: PropTypes.array,
};


const mapStateToProps = (state) => {
  const { clientContacts, clientSpecialProcesses, clientIssuesAndChallenges, clientGeneralNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  return {
    clientContacts,
    clientSpecialProcesses,
    clientIssuesAndChallenges,
    clientGeneralNotes,
  };
};
  
export default compose(
  connect(mapStateToProps),
)(GeneralInfoTab);