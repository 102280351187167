import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
   
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';
    
import { GET_CLIENT_SPECIAL_PROCESS_FAILURE, UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE } from '../../types';

import { deleteClientSpecialProcess, getClientSpecialProcessCategoryTypes, getClientSpecialProcesses, upsertClientSpecialProcess } from '../../clients.actions';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientSpecialProcessesTable = (props) => {
  const [clientSpecialProcessesOverview, setClientSpecialProcessesOverview] = useState(false);
  const theme = useTheme();
  
  const rendersCategoryTypeOptions = () => {
    if (props.clientSpecialProcessCategories == null) return [];
    
    return props.clientSpecialProcessCategories.map(( type ) => (
      type.description
    ));
  };

  const adjustedColumns = [
    { accessorKey: 'title', header: 'Title', type: 'text', required: true, size: 70, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 70, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'clientSpecialProcessCategoryTypeDescription', header: 'Category', type: 'dropdown', size: 30, options: rendersCategoryTypeOptions() }];

  const updateRow = async (clientSpecialProcess, values) => {
    const id = clientSpecialProcess.original.clientSpecialProcessId;
    values.clientSpecialProcessId = id;
    values.clientId = props.clientId;
    values.clientSpecialProcessCategoryTypeId =  props.clientSpecialProcessCategories.find((a) => a.description === values.clientSpecialProcessCategoryTypeDescription).id;
    const resp = await props.upsertClientSpecialProcess(values);
    if (resp.type === UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientSpecialProcesses(props.clientId);
      if (getResp.type === GET_CLIENT_SPECIAL_PROCESS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientSpecialProcessId = 0;
    values.clientId = props.clientId;
    values.clientSpecialProcessCategoryTypeId =  props.clientSpecialProcessCategories.find((a) => a.description === values.clientSpecialProcessCategoryTypeDescription).id;
    const resp = await props.upsertClientSpecialProcess(values);
    if (resp.type === UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientSpecialProcesses(props.clientId);
      if (getResp.type === GET_CLIENT_SPECIAL_PROCESS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (clientSpecialProcess) => {
    const clientSpecialProcessId = clientSpecialProcess.original.clientSpecialProcessId;
    const clientId = props.clientId;
    const resp = await props.deleteClientSpecialProcess( { clientId, clientSpecialProcessId });
    if (resp.type === UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientSpecialProcesses(props.clientId);
      if (getResp.type === GET_CLIENT_SPECIAL_PROCESS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const setSpecialProcessCategoryName = (values) =>{
    values.forEach((value) => {
      value.clientSpecialProcessCategoryTypeDescription = value.clientSpecialProcessCategoryType.description;
    });
    return values;
  };

  return (
    <Accordion expanded={clientSpecialProcessesOverview} onChange={()=> {setClientSpecialProcessesOverview(!clientSpecialProcessesOverview);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Special Processes</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientSpecialProcessesLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={setSpecialProcessCategoryName(props.clientSpecialProcesses)}
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {props.clientContactsLoading && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

ClientSpecialProcessesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientSpecialProcessesLoading: PropTypes.bool,
  clientId: PropTypes.number,
  // formikProps: PropTypes.object.isRequired,
  upsertClientSpecialProcess: PropTypes.func,
  getClientSpecialProcesses: PropTypes.func,
  deleteClientSpecialProcesses: PropTypes.func,
  getClientSpecialProcessesCategoryTypes: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { clientSpecialProcesses, clientSpecialProcessCategories } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientSpecialProcessesLoading } = state.clients;
  return {
    clientSpecialProcesses,
    clientSpecialProcessCategories,
    clientSpecialProcessesLoading,
  };
};
  
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientSpecialProcessCategoryTypes,
    getClientSpecialProcesses,
    upsertClientSpecialProcess,
    deleteClientSpecialProcess,
  }),
)(ClientSpecialProcessesTable);