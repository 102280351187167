/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Tab, Tabs, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import queryString from 'query-string';

import { getClientContacts, getClientContract, getClientContractFees, getClientContractSLA, getClientContractsCreditsAndRebates, getClientDetails, getClientGeneralNotes, getClientIssuesAndChallenges, getClientPoliciesImportantNotes, getClientSpecialProcessCategoryTypes, 
  getClientSpecialProcesses, getHomesaleClosingCosts, getHomesaleEligibleProperties, getHomesaleGboInspections, getHomesaleHHGProcesses, getInternalUsers, setClientFocusedTab, setClientId, setLoadingComplete } from '../clients.actions';
import { getClientHomesaleGeneralInfo, getClientLumpSum, getClientMetadata, getClientPoliciesGeneralInfo, getClientPolicyNotes } from '../clients.actions';
import { hideToast, setPageTitle, showToast } from 'modules/layout/layout.actions';

import {
  BUSCODES,
  CONTRACT,
  CUSTOM,
  FEES,
  GENERAL_INFO,
  HEADER_HEIGHT_CLIENT,
  INTEREST,
  OPTIONS,
  PAYROLL,
  POLICIES,
  TASKS,
  USERS,
} from '../constants';
import { GET_CLIENT_CONTACTS_FAILURE, GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, GET_CLIENT_CONTRACT_FAILURE, GET_CLIENT_CONTRACT_FEE_FAILURE, GET_CLIENT_CONTRACT_SLA_FAILURE, GET_CLIENT_GENERAL_NOTES_FAILURE, GET_CLIENT_HOMESALE_INFO_FAILURE, GET_CLIENT_ISSUES_CHALLENGES_FAILURE, GET_CLIENT_SPECIAL_PROCESS_FAILURE, GET_POLICIES_GENERAL_INFO__FAILURE } from '../types';

import {
  GET_CLIENT_DETAILS_FAILURE, GET_CLIENT_LUMP_SUM_FAILURE, GET_CLIENT_METADATA_FAILURE, GET_CLIENT_POLICY_NOTES_FAILURE, GET_HOMESALE_CLOSING_COSTS_FAILURE, GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, GET_HOMESALE_GBO_INSPECTIONS_FAILURE, GET_HOMESALE_HHG_PROCESS_FAILURE,
} from '../types';
import { grabFullName } from '../utils';
import ClientContractsTab from './clientContractsTab.component';
import ClientHeader from './clientHeader.component';
import ClientPoliciesTab from './clientPoliciesTab.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import GeneralInfoTab from './generalInfoTab.component';
import PayrollContainer from './payroll/payroll.container';

const styles = (theme) => ({
  box: {
    height: '100%',
  },
  form: {
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  tabsRoot: {
    paddingTop: HEADER_HEIGHT_CLIENT,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tab: {
    width: 120,
    minWidth: 120,
  },
});

function ClientDetailsView(props){
  const [reloadData, setReloadData] = useState(true);

  const { location, setPageTitle: propsSetPageTitle, clientsInfo } = props;
  const clientInfo = clientsInfo ? clientsInfo[props.match.params.id] : null;
  
  //initial load of form
  useEffect(() => {
    propsSetPageTitle('Client Details');

    const { tab } = queryString.parse(location.search);
    if (tab) {
      props.setClientFocusedTab(tab);
    } else {
      props.setClientFocusedTab(GENERAL_INFO);
    }

    setReloadData(true);
  }, [location]);

  //reload data
  useEffect(() => { 

    if (reloadData) {
      setReloadData(false);
      loadData();
    }
    async function loadData() {
      props.hideToast();

      const id = + props.match.params.id;

      props.setClientId(id);

      // Fetch metadata
      if (!props.metadata) {
        let { type } = await props.getClientMetadata();
        if (type === GET_CLIENT_METADATA_FAILURE) {
          props.showToast('Failed to retrieve metadata, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      // Fetch full client info
      if (!clientInfo) {

        const resp = await props.getClientDetails(id);
        if (resp.type === GET_CLIENT_DETAILS_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        const [contactsResp, issuesAndChallengesResp, generalNotesResp, clientContractsSLAResp, clientContractsFeeResp, clientContractsCreditsAndRebatesResp, clientContractsResp, clientSpecialProcessess, 
          clientSpecialProcessCategories, clientPolicyNotesResp, clientPoliciesImportantNotes, homesaleEligiblePropertiesResp, homesaleClosingCosts, homesaleGboInspections, homesaleHHGProcesses, lumpSum, clientHomesaleGeneralInfoResp,
          policiesGeneralInfo ] = await Promise.all([
          props.getClientContacts(id),
          props.getClientIssuesAndChallenges(id),
          props.getClientGeneralNotes(id),
          props.getClientContractSLA(id),
          props.getClientContractFees(id),
          props.getClientContractsCreditsAndRebates(id),
          props.getClientContract(id),
          props.getClientSpecialProcesses(id),
          props.getClientSpecialProcessCategoryTypes(),
          props.getClientPolicyNotes(id),
          props.getClientPoliciesImportantNotes(id),
          props.getHomesaleEligibleProperties(id),
          props.getHomesaleClosingCosts(id),
          props.getHomesaleGboInspections(id),
          props.getHomesaleHHGProcesses(id),
          props.getClientLumpSum(id),
          props.getClientHomesaleGeneralInfo(id),
          props.getClientPoliciesGeneralInfo(id),
        ]);

        props.setLoadingComplete();

        if (contactsResp.type === GET_CLIENT_CONTACTS_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (issuesAndChallengesResp.type === GET_CLIENT_ISSUES_CHALLENGES_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (generalNotesResp.type === GET_CLIENT_GENERAL_NOTES_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      

        if (clientContractsSLAResp.type === GET_CLIENT_CONTRACT_SLA_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (clientContractsFeeResp.type === GET_CLIENT_CONTRACT_FEE_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (clientContractsCreditsAndRebatesResp.type === GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      
        if (clientContractsResp.type === GET_CLIENT_CONTRACT_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      
        if (clientSpecialProcessess.type === GET_CLIENT_SPECIAL_PROCESS_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (clientSpecialProcessCategories.type === GET_CLIENT_SPECIAL_PROCESS_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (clientPolicyNotesResp.type === GET_CLIENT_POLICY_NOTES_FAILURE) {
          props.showToast('Failed to retrieve client policy notes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (clientPoliciesImportantNotes.type === GET_CLIENT_SPECIAL_PROCESS_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (homesaleEligiblePropertiesResp.type === GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (homesaleClosingCosts.type === GET_HOMESALE_CLOSING_COSTS_FAILURE) {
          props.showToast('Failed to retrieve closing cost data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (homesaleGboInspections.type === GET_HOMESALE_GBO_INSPECTIONS_FAILURE) {
          props.showToast('Failed to retrieve gbo inspections data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (homesaleHHGProcesses.type === GET_HOMESALE_HHG_PROCESS_FAILURE) {
          props.showToast('Failed to retrieve hhg processes data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        } 

        if (lumpSum.type === GET_CLIENT_LUMP_SUM_FAILURE) {
          props.showToast('Failed to retrieve lump sum data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (clientHomesaleGeneralInfoResp.type === GET_CLIENT_HOMESALE_INFO_FAILURE) {
          props.showToast('Failed to retrieve client homesale general info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (policiesGeneralInfo.type === GET_POLICIES_GENERAL_INFO__FAILURE) {
          props.showToast('Failed to retrieve policies general info data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      }

      if (!props.internalUsers) {
        await props.getInternalUsers();
      }
    }
    
  }, [reloadData]);

  const extractHeaderInfo = (details) => {
    return {
      clientCode: details.clientCode,
      description: details.description,
      clientStatus: details.clientStatus,
      serviceRep: grabFullName(props.internalUsers, details.serviceRep),
      id: details.clientId,
    };
  };

  const handleTabChange = (tab) => {
    props.setClientFocusedTab(tab);

    const values = queryString.parse(props.location.search);
    values.tab = tab;
    props.history.replace(`${props.location.pathname}?${queryString.stringify(values)}`);
  };

  return (
    <Box pl={1} pr={1} classes={{ root: classNames(props.classes.box) }}>
      {(props.isLoading || !(clientInfo)) && <FullscreenSpinner />}
      {(!props.isLoading && (clientInfo && clientInfo.clientDetails)) && (
        <div className={props.classes.form}>
          <ClientHeader
            headerInfo={extractHeaderInfo(clientInfo.clientDetails)}
          />
          <Tabs
            variant="standard"
            value={props.focusedClientTab}
            onChange={(event, tab) => handleTabChange(tab)}
            classes={{
              root: classNames(props.classes.tabsRoot),
            }}
          >
            <Tab value={GENERAL_INFO} label="General Info" classes={{ root: classNames(props.classes.tab) }} />
            <Tab value={PAYROLL} label="Payroll" classes={{ root: classNames(props.classes.tab) }} />
            <Tab value={TASKS} label="Tasks" classes={{ root: classNames(props.classes.tab) }} disabled />
            <Tab value={CONTRACT} label="Contract" classes={{ root: classNames(props.classes.tab) }} />
            <Tab value={INTEREST} label="Interest" classes={{ root: classNames(props.classes.tab) }} disabled />
            <Tab value={BUSCODES} label="Bus Codes" classes={{ root: classNames(props.classes.tab) }} disabled />
            <Tab value={FEES} label="Fees" classes={{ root: classNames(props.classes.tab) }} disabled />
            <Tab value={CUSTOM} label="Custom" classes={{ root: classNames(props.classes.tab) }} disabled />
            <Tab value={OPTIONS} label="Options" classes={{ root: classNames(props.classes.tab) }} disabled />
            <Tab value={USERS} label="Users" classes={{ root: classNames(props.classes.tab) }} disabled />
            <Tab value={POLICIES} label="Policies" classes={{ root: classNames(props.classes.tab) }} />
          </Tabs>
                    
          <Formik
            initialValues={clientInfo.clientDetails}
            // validate={validate}
            // onSubmit={handleSaveRequest}
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
          >
            {(formikProps) => (
              <>
                {props.focusedClientTab === GENERAL_INFO && <GeneralInfoTab formikProps={formikProps} />}
                {props.focusedClientTab === PAYROLL && <PayrollContainer formikProps={formikProps} />}
                {props.focusedClientTab === CONTRACT && <ClientContractsTab formikProps={formikProps} />}
                {props.focusedClientTab === POLICIES && <ClientPoliciesTab formikProps={formikProps} />}
              </>
            )}
          </Formik>
        </div>
      )}
    </Box>
  );
}

ClientDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  clientDetails: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
  setClientFocusedTab: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getInternalUsers: PropTypes.func.isRequired,
  getClientDetails: PropTypes.func.isRequired,
  getClientContacts: PropTypes.func.isRequired,
  getClientIssuesAndChallenges: PropTypes.func.isRequired,
  getClientGeneralNotes: PropTypes.func.isRequired,
  getClientContractSLA: PropTypes.func.isRequired, 
  getClientContractFees: PropTypes.func.isRequired,
  getClientContractsCreditsAndRebates: PropTypes.func.isRequired,
  getClientContract: PropTypes.func.isRequired,
  getClientPoliciesImportantNotes: PropTypes.func.isRequired,
  getClientLumpSum: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showToast: PropTypes.func.isRequired,
  hideToast: PropTypes.func.isRequired,
  focusedClientTab: PropTypes.string,
  theme: PropTypes.object,
  getClientSpecialProcesses: PropTypes.func.isRequired,
  getClientSpecialProcessCategoryTypes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { internalUsers, focusedClientTab, isLoading, metadata } = state.clients;
  const clientsInfo = state.clients.clientsInfo;
     
  return {
    focusedClientTab,
    isLoading,
    clientsInfo,
    internalUsers,
    metadata,
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  withTheme,
  connect(mapStateToProps, {
    setClientFocusedTab,
    getClientDetails,
    getInternalUsers,
    getClientContacts,
    getClientIssuesAndChallenges,
    getClientGeneralNotes,
    getClientContractSLA,
    getClientContractFees,
    getClientContractsCreditsAndRebates,
    getClientContract,
    getHomesaleClosingCosts,
    getHomesaleEligibleProperties,
    getHomesaleGboInspections,
    getHomesaleHHGProcesses,
    setPageTitle,
    hideToast,
    showToast,
    getClientHomesaleGeneralInfo,
    getClientLumpSum,
    getClientPolicyNotes,
    getClientMetadata,
    getClientSpecialProcesses,
    getClientSpecialProcessCategoryTypes,
    getClientPoliciesImportantNotes,
    getClientPoliciesGeneralInfo,
    setClientId,
    setLoadingComplete,
  }),
)(ClientDetailsView);
