import * as React from 'react';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
 
function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

const capitalizeFirstLetter = (str) => {
  if (typeof str === 'string' && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

/*
  Instructions: Place this component on any page where you'd like the router breadcrumbs (i.e. each portion of the URL separated by /).
  
  Props:
    - friendlyLinks (optional): an object array with a link and name defined. Name should represent a human readable link (i.e. clientCode rather than clientId by default).
*/
const RouterBreadcrumbs = ({ friendlyLinks }) => {

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  return (
    <Box sx={{ padding: '5px' }}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" sx={{ color: 'white !important' }} />}>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const name = (friendlyLinks && friendlyLinks.find((e) => e.link === to || (to.includes(e.short) && !to.includes('/expenses/')))) ? friendlyLinks.find((e) => e.link === to || to.includes(e.short)).name : capitalizeFirstLetter(pathnames[index]);
          return (
            <LinkRouter variant="h6" underline="hover" color="white" to={to} key={to}>
              {name}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default RouterBreadcrumbs;