import {
  GET_GLOBAL_DESTINATION_SERVICES_FAILURE,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  GET_GLOBAL_LANGUAGE_TRAINING_FAILURE,
} from 'modules/authorizations/store/types/globalTypes';
import {
  GET_VENDORS_FAILURE,
  GET_VENDOR_CONTACT_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  getGlobalDestinationServices,
  getGlobalInternationalSchooling,
  getGlobalLanguageTraining,
} from 'modules/authorizations/store/actions/global.actions';
import { 
  getVendorContacts,
  getVendors, 
} from 'modules/authorizations/store/actions/dataManagement.actions';
import { useDispatch } from 'react-redux';

export const useFetchGlobalDestinationServiceDataHandler = (showToast) => {
  const dispatch = useDispatch();

  const fetchData = async (authorizationId) => {
    try {
      const [globalDestResponse, globalInternationalSchoolingResponse, globalLanguageTrainingResponse] = await Promise.all([
        dispatch(getGlobalDestinationServices(authorizationId)),
        dispatch(getGlobalInternationalSchooling(authorizationId)),
        dispatch(getGlobalLanguageTraining(authorizationId)),
      ]);
      if (globalDestResponse.type === GET_GLOBAL_DESTINATION_SERVICES_FAILURE) {
        showToast('Failed fetching global destination services, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }

      if (globalInternationalSchoolingResponse.type === GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE) {
        showToast('Failed fetching global international schooling, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }

      if (globalLanguageTrainingResponse.type === GET_GLOBAL_LANGUAGE_TRAINING_FAILURE) {
        showToast('Failed fetching global language training, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }

      return true;
    } catch (error) {
      showToast('Failed fetching global destination service data, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return { fetchData };
};

export const useFetchVendorDataHandler = (showToast) => {
  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      const [vendorsResponse, vendorContactsResponse] = await Promise.all([dispatch(getVendors()), dispatch(getVendorContacts())]);

      if (vendorsResponse.type === GET_VENDORS_FAILURE) {
        showToast('Failed fetching vendors, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }

      if (vendorContactsResponse.type === GET_VENDOR_CONTACT_FAILURE) {
        showToast('Failed fetching vendor contacts, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('Failed fetching vendors or vendor contacts, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return { fetchData };
};

export const useFetchFamilyDataHandler = () => {
  const fetchData = (authorizationId) => {
    return [
      {
        id: 1,
        name: 'Billy Bob',
        
      },
      {
        id: 2,
        name: 'Bob Billy',
      },
    ];
  };
  return { fetchData };
};
