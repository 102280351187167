/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';

import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import classNames from 'classnames';
import PrimaryExpenseLineDetailsInfo from './primaryExpenseDetails/primaryExpenseLineDetailsInfo.component';

import queryString from 'query-string';
import ExpenseLineItemCustomView from './customExpenseDetails/expenseLineItemCustomView.component';
import ExpenseLineItemsTaxesDetailsView from './taxesExpenseDetails/expenseLineItemsTaxesDetailsView.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import { getExpenseLineCustomTaxes } from 'modules/expenses/expenses.actions';
import { GET_EXPENSE_LINE_CUSTOM_TAXES_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`
  },
  headerFixed: {
    zIndex: 4
  },
  tabFixed: {
    paddingTop: '100px',
    zIndex: 3
  },
  estimateHeaderFixed: {
    paddingTop: '150px',
    zIndex: 2
  }
});

const ExpenseLineItemDetails = props => {
  const { details, expenseLineId, location, shouldShowSideDrawer, history, viewOnly } = props;
  const { employee, authClientPolicies } = details?.authDetails;
  const { expenseLines, customTaxes } = props.details?.authExpenses;
  const [currentTab, setCurrentTab] = useState(queryString.parse(location.search).tab ? queryString.parse(location.search).tab : 'PRIMARY');
  const expenseLineItemId = parseInt(props.expenseLineId, 10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  
  const changeTab = tab => {
    setCurrentTab(tab);
    const values = queryString.parse(location.search);
    values.tab = tab;
    history.replace(`${location.pathname}?${queryString.stringify(values)}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (expenseLines && expenseLines.length > 0 && expenseLineItemId !== undefined && expenseLineItemId !== null) {
        const expenseLineItem = expenseLines.find(s => s.expenseLineId === expenseLineItemId);
        if (expenseLineItem) {
          const customTaxesTemp = customTaxes ?? [];
          const customTaxesForExpenseLineItem = customTaxesTemp.filter(s => s.expenseLineId === expenseLineItemId);
          if (!customTaxesForExpenseLineItem || customTaxesForExpenseLineItem.length === 0) {
            setIsLoadingData(true);
            const customTaxesResp = await props.getExpenseLineCustomTaxes(expenseLineItem.expenseId, expenseLineItemId);
            if (customTaxesResp.resp === GET_EXPENSE_LINE_CUSTOM_TAXES_FAILURE) {
              showToast('Failed to retrieve custom taxes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
            setIsLoadingData(false);
          }
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Box>
      <Paper
        square
        className={classNames(!viewOnly ? 'position-fixed' : '', !viewOnly ? props.classes.headerFixed : '', props.classes.container, {
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px'
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Box></Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Authorization Name
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {`${employee?.firstName} ${employee?.lastName}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Policy
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {authClientPolicies?.clientPolicyCode}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Status
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  Active
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Move CAP
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                $5,000,000,000
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Budget Remaining
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                4,999,998,001
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper
        square
        className={classNames(!viewOnly ? 'position-fixed' : '', !viewOnly ? props.classes.tabFixed : '', props.classes.container, {
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
      >
        <Tabs variant="fullWidth" value={currentTab} onChange={(event, tab) => changeTab(tab)} sx={{}}>
          <Tab value={'PRIMARY'} label="Primary Information" />
          <Tab value={'TAXES'} label="Taxes" />
          <Tab value={'CUSTOM'} label="Custom" />
        </Tabs>
      </Paper>
      {!isLoadingData && expenseLineId != null && (
        <>
          {currentTab === 'PRIMARY' && (
            <Box sx={{ padding: '20px', paddingTop: !viewOnly ? '165px' : '20px' }}>
              <PrimaryExpenseLineDetailsInfo details={details} id={props.id} expenseLineId={expenseLineId} viewOnly={viewOnly} />
            </Box>
          )}
          {currentTab === 'CUSTOM' && (
            <Box sx={{ padding: '20px', paddingTop: !viewOnly ? '165px' : '20px' }}>
              <ExpenseLineItemCustomView details={details} id={props.id} expenseLineId={expenseLineId} viewOnly={viewOnly} />
            </Box>
          )}
          {currentTab === 'TAXES' && (
            <Box sx={{ padding: '20px', paddingTop: !viewOnly ? '165px' : '20px' }}>
              <ExpenseLineItemsTaxesDetailsView details={details} id={props.id} expenseLineId={expenseLineId} viewOnly={viewOnly} />
            </Box>
          )}
        </>
      )}
      {isLoadingData && <FullscreenSpinner />}
    </Box>
  );
};

ExpenseLineItemDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    showToast,
    getExpenseLineCustomTaxes
  })
)(ExpenseLineItemDetails);
