export const createCustomInfoTabs = () => {
  return [{ header: 'Custom Info', order: 1, iconPath: ['fas', 'truck-moving'] }];
};
export const createCustomExceptionTabs = () => {
  return [{ header: 'Exceptions', order: 1, iconPath: ['fas', 'truck-moving'] }];
};
export const createCustomInfoFormData = expenseLineItem => {
  expenseLineItem = expenseLineItem ?? {};
  return [
    {
      accessorKey: 'purchaseOrderNumber',
      header: 'PO #',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.purchaseOrderNumber,
      tab: 1
    },
    {
      accessorKey: 'sourceType',
      header: 'Source Type',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.sourceType,
      tab: 1
    },
    {
      accessorKey: 'transStatus',
      header: 'Trans Status',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.transStatus,
      tab: 1
    },
    {
      accessorKey: 'purchaseOrderDetStatus',
      header: 'PO Det Status',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.purchaseOrderDetStatus,
      tab: 1
    },
    {
      accessorKey: 'houseHoldGoodsId',
      header: 'HHG Id',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.houseHoldGoodsId,
      tab: 1
    },
    {
      accessorKey: 'taxableBenefitAmount',
      header: 'Taxable Benefit Amount',
      type: 'number',
      required: false,
      size: 65,
      value: expenseLineItem.taxableBenefitAmount,
      tab: 1
    },
    {
      accessorKey: 'onHoldDate',
      header: 'On Hold Date',
      type: 'date',
      required: false,
      size: 65,
      value: expenseLineItem.onHoldDate,
      tab: 1
    },
    { accessorKey: 'checkVoid', header: 'Check Void', type: 'text', required: false, size: 65, value: expenseLineItem.checkVoid, tab: 1 },
    {
      accessorKey: 'seInvNumber',
      header: 'SE Inv Number',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.seInvNumber,
      tab: 1
    },
    {
      accessorKey: 'inventoryCurrent',
      header: 'Inventory Current',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.inventoryCurrent,
      tab: 1
    },
    {
      accessorKey: 'payeeAddressOne',
      header: 'Payee Address One',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.payeeAddressOne,
      tab: 1
    },
    {
      accessorKey: 'payeeAddressTwo',
      header: 'Payee Address Two',
      type: 'text',
      required: false,
      size: 65,
      value: expenseLineItem.payeeAddressTwo,
      tab: 1
    },
    { accessorKey: 'exception', header: 'Exception', type: 'text', required: false, size: 65, value: expenseLineItem.exception, tab: 1 },
    { accessorKey: 'taxYear', header: 'Tax Year', type: 'text', required: false, size: 65, value: expenseLineItem.taxYear, tab: 1 },
    { accessorKey: 'farReason', header: 'Far Reason', type: 'text', required: false, size: 65, value: expenseLineItem.farReason, tab: 1 },
    { accessorKey: 'netCheck', header: 'Net Check', type: 'text', required: false, size: 65, value: expenseLineItem.netCheck, tab: 1 },
    { accessorKey: 'payType', header: 'Pay Type', type: 'text', required: false, size: 65, value: expenseLineItem.payType, tab: 1 },
    { accessorKey: 'voidBy', header: 'Void By', type: 'text', required: false, size: 65, value: expenseLineItem.voidBy, tab: 1 }
  ];
};

export const createCustomExceptionFormData = () => {
  return [
    {
      accessorKey: 'exceptionDate',
      header: 'Exception Date',
      type: 'date',
      required: false,
      size: 65,
      value: new Date(),
      tab: 1
    },
    { accessorKey: 'exceptionAmount', header: 'Exception Amount', type: 'number', required: false, size: 65, value: 23.0, tab: 1 },
    { accessorKey: 'approvedBy', header: 'Approved By', type: 'text', required: false, size: 65, value: 'JSHOEPKE', tab: 1 },
    {
      accessorKey: 'exceptionReason',
      header: 'Exception Reason',
      type: 'text',
      required: false,
      size: 65,
      value: 'Additional Funds Approved',
      tab: 1
    },
    { accessorKey: 'tempLivingDays', header: 'tempLivingDays', type: 'number', required: false, size: 65, value: 14, tab: 1 },
    { accessorKey: 'exceptionId', header: 'Exception Id', type: 'number', required: false, size: 65, value: 13344, tab: 1 }
  ];
};

export const createCustomInfoPutRequest = (expenseLineItem, formData) => {
  if (!formData) return { ...expenseLineItem };
  return {
    ...expenseLineItem,
    purchaseOrderNumber: formData.purchaseOrderNumber,
    sourceType: formData.sourceType,
    transStatus: formData.transStatus,
    purchaseOrderDetStatus: formData.purchaseOrderDetStatus,
    houseHoldGoodsId: formData.houseHoldGoodsId,
    taxableBenefitAmount: parseFormValue(formData.taxableBenefitAmount, ValueType.FloatType),
    onHoldDate: parseFormValue(formData.onHoldDate, ValueType.DateTimeType),
    checkVoid: formData.checkVoid,
    seInvNumber: formData.seInvNumber,
    inventoryCurrent: formData.inventoryCurrent,
    payeeAddressOne: formData.payeeAddressOne,
    payeeAddressTwo: formData.payeeAddressTwo,
    exception: formData.exception,
    taxYear: formData.taxYear,
    farReason: formData.farReason,
    netCheck: formData.netCheck,
    payType: formData.payType,
    voidBy: formData.voidBy
  };
};

export const createCustomCurrencyPutRequest = (expenseLineItem, formData) => {
  if (!formData) return { ...expenseLineItem };
  return {
    ...expenseLineItem,
    receiptAmount: parseFormValue(formData.receiptAmount, ValueType.FloatType),
    payInAmount: parseFormValue(formData.payInAmount, ValueType.FloatType),
    homeAmount: parseFormValue(formData.homeAmount, ValueType.FloatType),
    hostAmount: parseFormValue(formData.hostAmount, ValueType.FloatType),
    usdAmount: parseFormValue(formData.usdAmount, ValueType.FloatType),
    cadAmount: parseFormValue(formData.cadAmount, ValueType.FloatType),
    serviceCompanyAmount: parseFormValue(formData.serviceCompanyAmount, ValueType.FloatType),
    equivalentCurrency: formData.equivalentCurrency,
    exchangeRateDate: parseFormValue(formData.exchangeRateDate, ValueType.DateTimeType),
    budgetCurrencyAmount: parseFormValue(formData.budgetCurrencyAmount, ValueType.FloatType)
  };
};

export const ValueType = {
  DateTimeType: 0,
  IntType: 1,
  FloatType: 2,
  MoneyType: 3,
  StringType: 4
};

export const parseFormValue = (value, valueType) => {
  if (!value || value === '') return null;

  switch (valueType) {
    case ValueType.DateTimeType:
      return value;
    case ValueType.IntType:
      return parseInt(value);
    case ValueType.FloatType:
      return parseFloat(value);
    default:
      console.log('Invalid Type');
      return null;
  }
};
