/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-target-blank */
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, FormControlLabel, IconButton, MenuItem, useTheme, withStyles } from '@material-ui/core';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, TextField } from 'modules/intake/form';
import { compose } from 'recompose';
import { memoize } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
import countries from 'modules/common/countries.json';
import states from 'modules/common/states.json';

const styles = (theme) => ({
  accordionStyle: {
    maxWidth: 1050,
  },
  inputGroup: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  root: {
    minWidth: 350,
  },
  webRoot: {
    minWidth: 315,
  },
  mediumRoot: {
    minWidth: 200,
  },
  miniRoot: {
    width: 140,
  },
  detailsLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
  },
  contentCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  dualField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    width: 350,
    marginRight: theme.spacing(1),
  },
  checkbox: {
    paddingTop: 15,
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    alignContent: 'center',
  },
});

const ClientOverviewTable = (props) => {
  const [clientOverview, setClientOverview] = useState(true);
  const formikProps = props.formikProps;
  const theme = useTheme();

  const renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    return states.filter((state) => state.countryCode === useCountryCode)
      .map(({ code, description }) => (
        <MenuItem key={code} value={code}>{description}</MenuItem>
      ));
  });

  const renderCountryOptions = memoize(() => {
    return countries.map(({ countryCode, description }) => (
      <MenuItem key={countryCode} value={countryCode}>{description}</MenuItem>
    ));
  });

  const appendPrefix = (base) => base.includes('http') ? base : `https://${  base}`;

  const isValidLink = (link) => {
    if (link && link.length !== 0) {
      let result = appendPrefix(link).match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return result;
    } else return false;
  };

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyle) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <h2>Client Overview</h2>
      </AccordionSummary>
      <AccordionDetails>
        <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
          <div className={props.classes.contentCol}>
            <Field
              component={TextField}
              name="shortDescription"
              label="Short Name"
              className={props.classes.root}
              value={formikProps.values.shortDescription}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="fullDescription"
              label="Legal Name"
              className={props.classes.root}
              value={formikProps.values.fullDescription}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="webSite"
                label="Website"
                className={props.classes.webRoot}
                value={formikProps.values.webSite}
                type="url"
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <IconButton
                color={isValidLink(formikProps.values.webSite) ? 'primary' : 'gray'}
                size="small"
                classes={{ root: classNames(props.classes.linkIcon) }}
              >
                {isValidLink(formikProps.values.webSite) && <a href={appendPrefix(formikProps.values.webSite)} target="_blank"><FontAwesomeIcon icon={['fas', 'external-link']} size="1x" /></a>}
                {!isValidLink(formikProps.values.webSite) && <FontAwesomeIcon icon={['fas', 'external-link']} size="1x" />}
              </IconButton>
            </div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="ein"
                label="Tax ID"
                className={props.classes.miniRoot}
                value={formikProps.values.ein}
                numeric
                inputProps={{
                  maxLength: 9,
                }}
                includeThousandsSeparator={false}
                allowDecimal={false}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="mainPhone"
                label="Phone"
                className={props.classes.mediumRoot}
                value={formikProps.values.mainPhone}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="mainFax"
                label="Fax"
                className={props.classes.mediumRoot}
                value={formikProps.values.mainFax}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="serviceCo"
                label="Service Co"
                className={props.classes.miniRoot}
                value={formikProps.values.serviceCo}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <Field
              component={TextField}
              name="dunnBradstreetNumber"
              label="Dunn and Bradstreet"
              className={props.classes.root}
              value={formikProps.values.dunnBradstreetNumber}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="industry"
              label="Industry"
              className={props.classes.root}
              value={formikProps.values.industry}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="previousProvider"
              label="Prev. Provider"
              className={props.classes.root}
              value={formikProps.values.previousProvider}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </div>

          <div className={props.classes.contentCol}>
            <Field
              component={TextField}
              name="address1"
              label="Address 1"
              className={props.classes.root}
              value={formikProps.values.address1}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="address2"
              label="Address 2"
              className={props.classes.root}
              value={formikProps.values.address2}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="city"
                label="City"
                className={props.classes.mediumRoot}
                value={formikProps.values.city}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="zipCode"
                label="Postal Code"
                className={props.classes.miniRoot}
                value={formikProps.values.zipCode}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <div className={props.classes.dualField}>
              <Field
                component={Select}
                name={'state'}
                label="State/Prov."
                value={formikProps.values.state}
                className={props.classes.miniRoot}
                InputLabelProps={{ style: { fontSize: 13 } }}
              >
                {renderStateOptions(formikProps.values.country)}
              </Field>
              <Field
                component={Select}
                name={'country'}
                label="Country"
                value={formikProps.values.country}
                className={props.classes.mediumRoot}
                InputLabelProps={{ style: { fontSize: 13 } }}
              >
                {renderCountryOptions()}
              </Field>
            </div>
            <div className="my-2"><Divider variant="fullWidth" classes={{ root: props.classes.divider }} /></div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="addedOn"
                label="Added On"
                className={props.classes.miniRoot}
                value={formikProps.values.addedOn}
                type="date"
                disabled={true}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="addedBy"
                label="Added By"
                className={props.classes.mediumRoot}
                value={formikProps.values.addedBy}
                disabled={true}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="modifiedOn"
                label="Modified On"
                className={props.classes.miniRoot}
                value={formikProps.values.modifiedOn}
                type="date"
                disabled={true}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="modifiedBy"
                label="Modified By"
                className={props.classes.mediumRoot}
                value={formikProps.values.modifiedBy}
                disabled={true}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <FormControlLabel
              control={
                <Field
                  component={Checkbox}
                  name="ercMember"
                  label="Is ERC Member"
                  value={formikProps.values.vip}
                />
              }
              label="Is ERC Member"
              classes={{ root: props.classes.checkbox }}
            />
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

ClientOverviewTable.propTypes = {
  classes: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
};
  
export default compose(
  withStyles(styles),
)(ClientOverviewTable);