import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { DELETE_HOMESALE_HHG_PROCESS_FAILURE, GET_HOMESALE_HHG_PROCESS_FAILURE, POST_HOMESALE_HHG_PROCESS_FAILURE, UPDATE_HOMESALE_HHG_PROCESS_FAILURE } from '../../types';
import { deleteHomesaleHHGProcess, getHomesaleHHGProcesses, postHomesaleHHGProcess, updateHomesaleHHGProcess } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
    crudTitle: {
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleHHGProcessesTable = (props) => {

  const processTypeOptions = [
    { title: 'General Process', id: 1 },
    { title: 'Specific Process', id: 0 },
  ];

  const basicOptions = [
    { title: 'Yes', id: 2 },
    { title: 'No', id: 1 },
    { title: 'N/A', id: 0 },
  ];

  const adjustedColumns = [
    { accessorKey: 'processType', header: 'Process Type', type: 'dropdown-v2', options: processTypeOptions, size: 75, defaultVal: 1, required: true, Cell: ({ cell }) => cell.row.original.processType != null && <p>{processTypeOptions.find((o) => o.id === cell.row.original.processType).title}</p> },
    { accessorKey: 'processName', header: 'Process Name', type: 'text', required: false, size: 75 },
    { accessorKey: 'air', header: 'Air', type: 'dropdown-v2', options: basicOptions, size: 60, defaultVal: 0, required: true, Cell: ({ cell }) => cell.row.original.air != null && <p>{basicOptions.find((o) => o.id === cell.row.original.air).title}</p> },
    { accessorKey: 'sea', header: 'Sea', type: 'dropdown-v2', options: basicOptions, size: 60, defaultVal: 0, required: true, Cell: ({ cell }) => cell.row.original.sea != null && <p>{basicOptions.find((o) => o.id === cell.row.original.sea).title}</p> },
    { accessorKey: 'surface', header: 'Surface', type: 'dropdown-v2', options: basicOptions, size: 70, defaultVal: 0, required: true, Cell: ({ cell }) => cell.row.original.surface != null && <p>{basicOptions.find((o) => o.id === cell.row.original.surface).title}</p> },
    { accessorKey: 'comments', header: 'Comments', type: 'notes', size: 70, enableColumnDragging: false, enableColumnActions: false, enableResizing: false, hideInTable: true },
  ];

  const updateRow = async (hhgProcess, values) => {
    const id = hhgProcess.original.clientHomesaleProcessId;
    values.clientHomesaleProcessId = id;
    values.clientId = props.clientId;

    const resp = await props.updateHomesaleHHGProcess(values);
    if (resp.type === UPDATE_HOMESALE_HHG_PROCESS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleHHGProcesses(props.clientId);
      if (getResp.type === GET_HOMESALE_HHG_PROCESS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;

    const resp = await props.postHomesaleHHGProcess(values);
    if (resp.type === POST_HOMESALE_HHG_PROCESS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleHHGProcesses(props.clientId);
      if (getResp.type === GET_HOMESALE_HHG_PROCESS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (hhgProcess) => {
    const id = hhgProcess.original.clientHomesaleProcessId;
    const resp = await props.deleteHomesaleHHGProcess(id);
    if (resp.type === DELETE_HOMESALE_HHG_PROCESS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleHHGProcesses(props.clientId);
      if (getResp.type === GET_HOMESALE_HHG_PROCESS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div className={props.classes.tabBorder}>
      <h3 className={props.classes.crudTitle}>General and Specific Processes</h3>
      {!props.homesaleHHGProcessesLoading && (
        <CrudTable 
          editingMode={'modal'} // Can be changed to 'row' to edit inline 
          columns={adjustedColumns} 
          rows={props.homesaleHHGProcesses} 
          updateRow={updateRow} 
          createRow={createRow} 
          deleteRow={deleteRow} 
        />
      )}
      {props.homesaleHHGProcessesLoading && (
        <Spinner />
      )}
    </div>
  );
}; 

HomeSaleHHGProcessesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { homesaleHHGProcesses } = state.clients.clientsInfo[state.clients.currentClientId];
  const { homesaleHHGProcessesLoading } = state.clients;
  return {
    homesaleHHGProcesses,
    homesaleHHGProcessesLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getHomesaleHHGProcesses,
    deleteHomesaleHHGProcess,
    postHomesaleHHGProcess,
    updateHomesaleHHGProcess,
  }),
)(HomeSaleHHGProcessesTable);