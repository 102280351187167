import { getInspectorDisplayName } from './inspectionsUtils';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

const InspectionsSelection = React.memo(
  ({ inspections = [], selectedInspection, setSelectedInspection, vendors = [] }) => {
    
    const handleChange = (event) => {
      const newSelected = inspections.find((item) => item.inspectionId === event.target.value);
      setSelectedInspection(newSelected);
    };

    useEffect(() => {
      if (inspections.length === 0 && selectedInspection) {
        setSelectedInspection(null);
      }
    }, [inspections, selectedInspection, setSelectedInspection]);

    return (
      <TextField
        select
        label="Inspections"
        value={selectedInspection?.inspectionId || 0}
        onChange={handleChange}
        SelectProps={{
          autoWidth: true,
          displayEmpty: true,
        }}
        variant="outlined"
        disabled={inspections.length === 0}
      >
        {inspections.length > 0 ? (
          inspections.map((item) => (
            <MenuItem key={item.inspectionId} value={item.inspectionId}>
              {getInspectorDisplayName(item, vendors)}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={0} value={0} disabled style={{ justifyContent: 'center', opacity: 1 }}>
            <em>No Inspections Created</em>
          </MenuItem>
        )}
      </TextField>
    );
  },
);

InspectionsSelection.propTypes = {
  inspections: PropTypes.array.isRequired,
  selectedInspection: PropTypes.object,
  setSelectedInspection: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
};

export default InspectionsSelection;
