import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createInspectionsFormTab, createNewInspectionsFormFields } from './FormFields/inspectionsFormFields';
import EditableCard from 'modules/common/editableCard.component';
import InspectionsSelection from './inspectionsSelection.component';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const InspectionsHeader = React.memo(
  ({
    inspections = [],
    vendors = [],
    createNewInspectionFunc,
    isLoading,
    selectedInspection,
    setSelectedInspection,
    handleDeleteSelectedInspection,
    isReadOnly
  }) => {
    const [showForm, setShowForm] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleFormSubmission = async (tab, values) => {
      const resp = await createNewInspectionFunc(values);
      setShowForm(false);
      return resp;
    };

    const onCancel = () => {
      setShowForm(false);
    };

    const onClickCreateInspection = () => {
      setShowForm(true);
    };

    const onClickAllInspectionsReceived = () => {
      console.log('All Inspections Received clicked');
    };

    const onClickDeleteInspection = () => {
      setOpenDialog(true);
    };

    const handleDeleteConfirm = async () => {
      setOpenDialog(false);
      await handleDeleteSelectedInspection();
    };

    const handleDialogClose = () => {
      setOpenDialog(false);
    };

    return (
      <>
        {showForm && (
          <div style={{ display: 'none' }}>
            <EditableCard
              tabs={createInspectionsFormTab()}
              summaries={createNewInspectionsFormFields(vendors)}
              updateFields={handleFormSubmission}
              isLoading={isLoading}
              onCancelProp={onCancel}
              openModel={true}
              hideEdit={isReadOnly}
            />
          </div>
        )}
        <Box
          sx={{
            position: 'sticky',
            top: 64,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            zIndex: 1000,
            backgroundColor: 'white',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <InspectionsSelection
              inspections={inspections}
              selectedInspection={selectedInspection}
              setSelectedInspection={setSelectedInspection}
              vendors={vendors}
            />
            {!isReadOnly && <><Button
              onClick={onClickCreateInspection}
              color="secondary"
              variant="contained"
              disabled={(vendors?.length ?? 0) === 0}
              sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
            >
              Add Inspection
            </Button>
            <Button
              onClick={onClickDeleteInspection}
              color="secondary"
              variant="contained"
              disabled={inspections === null}
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: '#868686',
                '&:hover': { backgroundColor: '#474747' },
              }}
            >
              Delete Inspection
            </Button>
            </> }
            {(vendors?.length ?? 0) === 0 && <div>No Inspectors configured.</div>}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            {!isReadOnly && <Button
              onClick={onClickAllInspectionsReceived}
              color="secondary"
              variant="contained"
              disabled={(inspections?.length ?? 0) === 0}
              sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
            >
              All Inspections Received
            </Button>
            }
          </Box>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this inspection?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

InspectionsHeader.propTypes = {
  inspections: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  createNewInspectionFunc: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedInspection: PropTypes.object,
  setSelectedInspection: PropTypes.func.isRequired,
  handleDeleteSelectedInspection: PropTypes.func.isRequired,
};

export default InspectionsHeader;
