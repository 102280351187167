import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const StorageInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const tabs = [
    { header: 'Storage Info', order: 1, iconPath: ['fas', 'file-contract'] },
  ];

  const summaries = [
    { header: 'Storage Facility', accessorKey: 'storageFacility', type: 'text', required: false, value: segmentInfo ? segmentInfo.storageFacility : '', tab: 1 },
    { header: 'Storage Addr', accessorKey: 'storageAddr', type: 'text', required: false, value: segmentInfo ? segmentInfo.storageAddr : '', tab: 1 },
    { header: 'Storage C/S/Z', accessorKey: 'storageCsz', type: 'text', required: false, value: segmentInfo ? segmentInfo.storageCsz : '', tab: 1 },
    { header: 'Storage Phone', accessorKey: 'storagePhone', type: 'text', required: false, value: segmentInfo ? segmentInfo.storagePhone : '', tab: 1 },
    { header: 'Storage Units', accessorKey: 'storageUnits', type: 'number', required: false, value: segmentInfo ? segmentInfo.storageUnits : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.storageFacility = values.storageFacility;
    newValues.storageAddr = values.storageAddr;
    newValues.storageCsz = values.storageCsz;
    newValues.storagePhone = values.storagePhone;
    newValues.storageUnits = values.storageUnits;

    // values.name = segmentInfo.name;
    // values.type = values.type === 'Domestic' ? 0 : 1;
    // values.authorizationId = props.id;
    // values.segmentId = segmentInfo.segmentId;
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(StorageInfoCard);