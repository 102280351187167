//TODO - Consider making these editable card/grids into a reusable component?
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SpinnerComponent from 'modules/common/spinner.component';
import { memoize } from 'lodash';
import states from 'modules/common/states.json';
import { ValueType } from './expenseLineItemsUtils';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const renderStateOptions = memoize(countryCode => {
  const useCountryCode = countryCode || 'USA';
  let allStates = states.filter(state => state.countryCode === useCountryCode).map(state => state.description);
  return allStates;
});

const ExpenseLineItemsPayrollEditableCard = props => {
  const { updateFields, isLoading, selectedExpenseLineItem, expneseSummary } = props;
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const createTableValues = () => {
    return {
      'Original (1.45%)': {
        taxAssistanceAmount: selectedExpenseLineItem?.medicareTaxAssistanceOriginalAmount,
        withholdingAmount: selectedExpenseLineItem?.medicareWithholdingOriginalAmount
      },
      'Surtax (0.9%)': {
        taxAssistanceAmount: selectedExpenseLineItem?.medicareTaxAssistanceSurtaxAmount,
        withholdingAmount: selectedExpenseLineItem?.medicareWithholdingSurtaxAmount
      }
    };
  };

  const rowNames = ['Original (1.45%)', 'Surtax (0.9%)'];

  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const handleSaveEdits = async (authId, values) => {
    if (updateFields) {
      const resp = await updateFields(authId, values);
      if (!resp) {
        showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      } else {
        setEditingModalOpen(false);
      }
    }
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  const handleCancelEdits = () => {
    setEditingModalOpen(false);
  };

  const renderHeader = () => {
    return (
      <div className="row justify-space-between align-center">
        <div className={classes.iconRow}>
          <Tooltip title="Payroll" key={'Payroll'} placement="right">
            <FontAwesomeIcon icon={['fas', 'money-bill-transfer']} size="2x" />
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              paddingLeft: '20px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: '1.3125rem',
                fontWeight: 'bold'
              }}
            >
              Payroll
            </Typography>
          </Box>
        </div>
        {!props.viewOnly && (
          <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
            <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
          </Fab>
        )}
      </div>
    );
  };

  const parseValueForGrid = val => {
    if (val === null || isNaN(val)) return '--';

    let parsedVal = parseFloat(val);
    if (isNaN(parsedVal)) return '--';

    return `$${parsedVal.toFixed(2)}`;
  };

  //Top part ...
  const renderOption = (value, valueType, header) => {
    return (
      <Grid item xs={2} sm={2} md={2} xl={2}>
        <Box>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
              {header}
            </Typography>
          </div>
          {valueType === ValueType.DateTimeType && (
            <div className={classes.mb2}>
              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                {value ? new Date(value).toLocaleDateString('en-us') : <div>&mdash;</div>}
              </Typography>
            </div>
          )}
          {valueType === ValueType.MoneyType && (
            <div className={classes.mb2}>
              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                {value ? `$${value}` : <div>&mdash;</div>}
              </Typography>
            </div>
          )}
          {valueType === ValueType.StringType && (
            <div className={classes.mb2}>
              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                {value ? value : <div>&mdash;</div>}
              </Typography>
            </div>
          )}
        </Box>
      </Grid>
    );
  };

  const renderEditableCardTable = values => {
    return (
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              sx={{
                color: '#777667',
                fontWeight: 400,
                fontSize: '1rem',
                whiteSpace: 'nowrap'
              }}
            >
              Medicare Tax Assist
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              sx={{
                color: '#777667',
                fontWeight: 400,
                fontSize: '1rem',
                whiteSpace: 'nowrap'
              }}
            >
              Medicare Withholding
            </Typography>
          </Grid>
          {rowNames.map(name => (
            <React.Fragment key={name}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                  {parseValueForGrid(values[name].taxAssistanceAmount)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                  {parseValueForGrid(values[name].withholdingAmount)}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ position: 'relative' }}>
        <Box>
          <CardContent className={classes.cardContainer}>{renderHeader()}</CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2} columns={{ xs: 6 }}>
              {renderOption(selectedExpenseLineItem?.stateExcludableAmount, ValueType.MoneyType, 'State Excludable')}
              {renderOption(selectedExpenseLineItem?.payrollInProcessDate, ValueType.DateTimeType, 'Payroll in Process')}
              {renderOption(selectedExpenseLineItem?.payrollPostedDate, ValueType.DateTimeType, 'Payroll Posted')}
              {renderOption(selectedExpenseLineItem?.payrollType, ValueType.StringType, 'Payroll Type')}
              {renderOption(selectedExpenseLineItem?.earningsCode, ValueType.StringType, 'Earnings Code')}
              {renderOption(selectedExpenseLineItem?.taxState, ValueType.StringType, 'Tax State')}
              {/* TODO - What is Year Posted? */}
              {renderOption(expneseSummary?.dateSubmitted, ValueType.StringType, 'Year Posted')}
              {/* TODO - What is Payroll batch bumber? */}
              {renderOption(expneseSummary?.batchType, ValueType.StringType, 'Payroll Batch #')}
              {renderOption(selectedExpenseLineItem?.payAmount, ValueType.MoneyType, 'Pay Amount')}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Box sx={{ paddingTop: '10px' }}>{renderEditableCardTable(createTableValues())}</Box>
            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          expneseSummary={expneseSummary}
          selectedExpenseLineItem={selectedExpenseLineItem}
          classes={classes}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={handleCancelEdits}
          onSubmit={handleSaveEdits}
        />
      )}
    </ThemeProvider>
  );
};
ExpenseLineItemsPayrollEditableCard.propTypes = {
  tabs: PropTypes.array.isRequired,
  summaries: PropTypes.array.isRequired,
  updateFields: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
};

export default connect(null, { showToast })(ExpenseLineItemsPayrollEditableCard);

export const EditCardModal = ({ selectedExpenseLineItem, expneseSummary, classes, onClose, onSubmit, isLoading, open }) => {
  const stateOptions = renderStateOptions('');
  const [values, setValues] = useState(selectedExpenseLineItem);

  useEffect(() => {
    setValues(selectedExpenseLineItem);
  }, [selectedExpenseLineItem]);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(values);
  };

  const handleCancel = e => {
    e.preventDefault();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Currency" key="Currency" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-bill-transfer']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Payroll
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px' },
              gap: '1.5rem'
            }}
          >
            <Grid
              container
              spacing={{ xs: 2 }}
              sx={{ padding: '30px', paddingTop: '0px', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <Grid item key={'stateExcludableAmount'}>
                <TextField
                  key="stateExcludableAmount"
                  label="State Excludable"
                  name="stateExcludableAmount"
                  value={values?.stateExcludableAmount}
                  margin="dense"
                  variant="standard"
                  sx={{ maxWidth: '360px' }}
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  allowDecimal={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'payrollInProcessDate'}>
                <TextField
                  key="payrollInProcessDate"
                  label="Payroll In Process"
                  name="payrollInProcessDate"
                  value={formatDateForInput(values?.payrollInProcessDate)}
                  margin="dense"
                  sx={{ maxWidth: '360px' }}
                  variant="standard"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'payrollPostedDate'}>
                <TextField
                  key="payrollPostedDate"
                  label="Payroll Posted"
                  name="payrollPostedDate"
                  value={formatDateForInput(values?.payrollPostedDate)}
                  margin="dense"
                  sx={{ maxWidth: '360px' }}
                  variant="standard"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'payrollType'}>
                <TextField
                  key="payrollType"
                  label="Payroll Type"
                  name="payrollType"
                  value={values?.payrollType}
                  margin="dense"
                  sx={{ maxWidth: '360px' }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'earningsCode'}>
                <TextField
                  key="earningsCode"
                  label="Earnings Code"
                  name="earningsCode"
                  value={values?.earningsCode}
                  margin="dense"
                  sx={{ maxWidth: '360px' }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'taxState'}>
                <FormControl sx={{ marginTop: '6px' }}>
                  <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id="Tax State">
                    Tax State
                  </InputLabel>
                  <Select
                    labelId="Tax State"
                    key="taxState"
                    label="Tax State"
                    name="taxState"
                    value={values?.taxState}
                    margin="dense"
                    variant="standard"
                    sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                    onChange={handleChange}
                  >
                    {stateOptions.map(opt => (
                      <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                        {opt}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item key={'yearPosted'}>
                <TextField
                  key="yearPosted"
                  label="Year Posted"
                  name="yearPosted"
                  value={expneseSummary?.dateSubmitted}
                  margin="dense"
                  disabled={true}
                  sx={{ maxWidth: '360px' }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'payrollBatchNumber'}>
                <TextField
                  key="payrollBatchNumber"
                  label="Payroll Batch Number"
                  name="payrollBatchNumber"
                  value={expneseSummary?.batchType}
                  margin="dense"
                  disabled={true}
                  sx={{ maxWidth: '360px' }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item key={'payAmount'}>
                <TextField
                  key="payAmount"
                  label="Pay Amount"
                  name="payAmount"
                  value={values?.payAmount}
                  margin="dense"
                  variant="standard"
                  sx={{ maxWidth: '360px' }}
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  allowDecimal={true}
                  onChange={handleChange}
                />
              </Grid>
              <Box sx={{ padding: '10px', maxWidth: '800px', paddingTop: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Medicare Tax Assist
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" sx={{ maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Medicare Withholding
                    </Typography>
                  </Grid>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle2"
                        sx={{ paddingTop: '10px', maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}
                      >
                        Original (1.45%)
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="medicareTaxAssistanceOriginalAmount"
                        label="Tax Assistance Original"
                        name="medicareTaxAssistanceOriginalAmount"
                        value={values?.medicareTaxAssistanceOriginalAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="medicareWithholdingOriginalAmount"
                        label="Withholding Original"
                        name="medicareWithholdingOriginalAmount"
                        value={values?.medicareWithholdingOriginalAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle2"
                        sx={{ paddingTop: '10px', maxWidth: '360px', color: '#777667', fontWeight: 400, fontSize: '1rem' }}
                      >
                        Surtax (0.9%)
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="medicareTaxAssistanceSurtaxAmount"
                        label="Tax Assistance Surtax "
                        name="medicareTaxAssistanceSurtaxAmount"
                        value={values?.medicareTaxAssistanceSurtaxAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        key="medicareWithholdingSurtaxAmount"
                        label="Withholding Surtax"
                        name="medicareWithholdingSurtaxAmount"
                        value={values?.medicareWithholdingSurtaxAmount}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={handleChange}
                      />
                    </Grid>
                  </React.Fragment>
                </Grid>
              </Box>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <SpinnerComponent />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};
