/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { Button, Checkbox, FormControlLabel, FormControl, FormHelperText, TextField, Typography, makeStyles, MenuItem } from '@material-ui/core';
import { GRANT_WEB_ACCESS_FAILURE, GRANT_WEB_ACCESS_SUCCESS, RESEND_WELCOME_EMAIL_FAILURE, RESEND_WELCOME_EMAIL_SUCCESS, UPDATE_INTAKE_RECORD_FAILURE, UPDATE_INTAKE_RECORD_SUCCESS } from '../types';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from '../../layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { resendWelcomeEmail, setEEIntakeStatus, updateIntakeRecord, grantWebAccess } from '../intake.actions';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes, { nominalTypeHack } from 'prop-types';
import React, { useEffect, useState } from 'react';

import Dialog from 'modules/common/dialog.component';
import Spinner from 'modules/common/spinner.component';
import { convertPhoneNumber, grabCountryCode } from '../details/authorizationIntake/utils';
import { COUNTRY_CODES } from 'modules/common/countryCodes';

const useStyles = makeStyles((theme) => ({
  mobileNum: {
    marginRight: 10,
  },
  email: {
    minWidth: 175,
  },
  countryCode: {
    maxWidth: 145,
    marginRight: 10,
  }
}));

function WebAccessAlterationsDialog(props) {

  const classes = useStyles(props);

  const {
    onSubmit,
    onCancel,
    maxLength,
    disabled,
    record,
    ...rest
  } = props;
  const [eeIntakeRequired, setEEIntakeRequired] = useState(props.record && props.record.transfereeIntakeRequired ? props.record.transfereeIntakeRequired : false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [noMobileNum, setNoMobileNum] = useState(props.record && props.record.noMobileNumber ? props.record.noMobileNumber : false);
  const [isSaving, setIsSaving] = useState(false);
  const [isResendingWelcomeEmail, setIsResendingWelcomeEmail] = useState(false);
  const [grantingAccess, setGrantingAccess] = useState(false);
  const [countryCode, setCountryCode] = useState('');

  const moveTypeExists = props.record && (props.record.moveTypeId || props.record.moveTypeId === 0);
  const noWebAccess = props.record && (!props.record.transferreeHasWebAccess || props.record.transferreeHasWebAccess === false);

  const handleSave = async () => {
    const record = props.record;

    if (record) {
      let newRecord = { ...record };

      const originalMobile = newRecord.phoneNumbers.find((o) => o.phoneTypeId === 1);
      const originalEmail = newRecord.emailAddresses.find((o) => o.isPrimary === true);

      const diffMobile = originalMobile?.phoneNumber !== mobileNumber && mobileNumber?.length > 0;
      const diffEmail = originalEmail?.emailAddress !== primaryEmail && primaryEmail?.length > 0;
      newRecord.transfereeIntakeRequired = eeIntakeRequired;
      newRecord.noMobileNumber = noMobileNum;

      if (diffMobile) {
        if (originalMobile) {
          newRecord.phoneNumbers.find((o) => o.phoneTypeId === 1).phoneNumber = mobileNumber;
        } else {
          newRecord.phoneNumbers.push({
            phoneNumber: mobileNumber,
            phoneTypeId: 1,
            contactInfoOwnerTypeId: 1,
            isPrimary: false,
          });
        }
      }
            
      if (diffEmail) {
        if (originalEmail) {
          newRecord.emailAddresses.find((o) => o.isPrimary === true).emailAddress = primaryEmail;
        } else {
          newRecord.emailAddresses.push({
            emailAddress: primaryEmail,
            emailTypeId: 0,
            contactInfoOwnerTypeId: 1,
            isPrimary: true,
          });
        }
      }

      const webAccessReq = {
        Id: record.id,
        AuthorizationId: record.mtAuthorizationId,
        NoMobileNumber: noMobileNum,
        EmailAddress: primaryEmail,
        PhoneNumber: mobileNumber,
      };

        // make the update call, wait on it then grantwebaccess
        const updateResponse = await props.updateIntakeRecord({ ...record, ...newRecord });
        if (updateResponse.type === UPDATE_INTAKE_RECORD_FAILURE) {
          props.showToast('Failed to save data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setGrantingAccess(false);
          return;
        } else {
          if(!record.transferreeHasWebAccess) {
            const resp = await props.grantWebAccess(webAccessReq);
            if (resp.type === GRANT_WEB_ACCESS_SUCCESS) {
              if (eeIntakeRequired) await props.setEEIntakeStatus(record.id, true);
              props.showToast(`Successfully saved and granted web access for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
              onCancel();
            } else if (resp.type === GRANT_WEB_ACCESS_FAILURE) {
              props.showToast(`Failed to grant web access for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
              onCancel();
            }
          }
          else {
            props.showToast(`Successfully saved web access info for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
          }
        }
    }
    setIsSaving(false);
  }


  const handleResendWelcomeEmail = async () => {
    const record = props.record;

    setIsResendingWelcomeEmail(true);
    if (record) {
      let newRecord = { ...record };

      const originalMobile = newRecord.phoneNumbers.find((o) => o.phoneTypeId === 1);
      const originalEmail = newRecord.emailAddresses.find((o) => o.isPrimary === true);

      const diffMobile = originalMobile?.phoneNumber !== mobileNumber && mobileNumber?.length > 0;
      const diffEmail = originalEmail?.emailAddress !== primaryEmail && primaryEmail?.length > 0;
      newRecord.transfereeIntakeRequired = eeIntakeRequired;
      newRecord.noMobileNumber = noMobileNum;

      if (diffMobile) {
        if (originalMobile) {
          newRecord.phoneNumbers.find((o) => o.phoneTypeId === 1).phoneNumber = mobileNumber;
        } else {
          newRecord.phoneNumbers.push({
            phoneNumber: mobileNumber,
            phoneTypeId: 1,
            contactInfoOwnerTypeId: 1,
            isPrimary: false,
          });
        }
      }
            
      if (diffEmail) {
        if (originalEmail) {
          newRecord.emailAddresses.find((o) => o.isPrimary === true).emailAddress = primaryEmail;
        } else {
          newRecord.emailAddresses.push({
            emailAddress: primaryEmail,
            emailTypeId: 0,
            contactInfoOwnerTypeId: 1,
            isPrimary: true,
          });
        }
      }

      const webAccessReq = {
        Id: record.id,
        AuthorizationId: record.mtAuthorizationId,
        NoMobileNumber: noMobileNum,
        EmailAddress: primaryEmail,
        PhoneNumber: mobileNumber,
      };

      if (diffEmail || diffMobile) {
        // make the update call, wait on it then grantwebaccess
        const updateResponse = await props.updateIntakeRecord({ ...record, ...newRecord });
        if (updateResponse.type === UPDATE_INTAKE_RECORD_FAILURE) {
          props.showToast('Failed to save data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsResendingWelcomeEmail(false);
          return;
        } else if (updateResponse.type === UPDATE_INTAKE_RECORD_SUCCESS) {
          const resp = await props.resendWelcomeEmail(webAccessReq);
          if (resp.type === RESEND_WELCOME_EMAIL_SUCCESS) {

            if (eeIntakeRequired) await props.setEEIntakeStatus(record.id, true);

            props.showToast(`Successfully saved web access info and resent welcome email for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
            onCancel();
          } else if (resp.type === RESEND_WELCOME_EMAIL_FAILURE) {
            props.showToast(`Failed to save web access info and resend welcome email for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            onCancel();
          }
        }
      } else {
        const resp = await props.resendWelcomeEmail(webAccessReq);
        if (resp.type === RESEND_WELCOME_EMAIL_SUCCESS) {

          if (eeIntakeRequired) await props.setEEIntakeStatus(record.id, true);

          props.showToast(`Successfully saved web access info and resent welcome email for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
          onCancel();
        } else if (resp.type === RESEND_WELCOME_EMAIL_FAILURE) {
          props.showToast(`Failed to save web access info and resend welcome email for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          onCancel();
        }
      }
    }
    setIsResendingWelcomeEmail(false);
  };

  useEffect(() => {
    if (props.record) {
      const number = props.record.phoneNumbers?.find((o) => o.phoneTypeId === 1)?.phoneNumber;
      const email = props.record.emailAddresses?.find((o) => o.isPrimary === true)?.emailAddress;
      if (number) {
        let tempCountryCode = grabCountryCode(number);
        setCountryCode(tempCountryCode);
        setMobileNumber(convertPhoneNumber(number, tempCountryCode));
      }
      if (email) setPrimaryEmail(email);
      if(props.record && props.record.transfereeIntakeRequired)
      {
        setEEIntakeRequired(props.record.transfereeIntakeRequired);
      }
      if(props.record && props.record.noMobileNumber)
      {
        setNoMobileNum(props.record.noMobileNumber)
      }
    }
  }, [props.record]);

  let countryOptions = [];
  if (COUNTRY_CODES) {
    countryOptions = COUNTRY_CODES.map((country) => ({
      value: country['alpha-3'],
      label: country.name,
    }));
  }

  return (
    <Dialog
      {...rest}
      title="Alter Web Access"
      content={
        <div>
          {props.record && (
            <div>
              <Typography>{`${props.record.contacts[0].firstName} ${props.record.contacts[0].lastName}`}</Typography>
              <Typography>{props.record.client.description}</Typography>
              <div>
                <TextField
                  select
                  label="Country"
                  className={classes.countryCode}
                  value={countryCode}
                  onChange={(e) => { setCountryCode(e.target.value); setMobileNumber(convertPhoneNumber(mobileNumber, e.target.value)); }}
                  SelectProps={{
                    autoWidth: true,
                  }}
                  variant="outlined"
                >
                  {countryOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="text"
                  name="mobileNumber"
                  label="Mobile Number"
                  variant="outlined"
                  value={convertPhoneNumber(mobileNumber, countryCode)}
                  onChange={(e) => setMobileNumber(convertPhoneNumber(e.target.value, countryCode))}
                  className={classes.mobileNum}
                  disabled={noMobileNum}
                />
                <TextField
                  type="text"
                  name="emailAddress"
                  label="Email Address"
                  variant="outlined"
                  value={primaryEmail}
                  onChange={(e) => setPrimaryEmail(e.target.value)}
                  className={classes.email}
                  disabled={true}
                />
              </div>
              <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'noMobileNum'}
                    name={'noMobileNum'}
                    label={'Confirm No Mobile Number'}
                    labelPlacement="end"
                    control={<Checkbox />}
                    checked={noMobileNum}
                    variant="standard"
                    margin="dense"
                    sx={{ minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setNoMobileNum( e.target.checked ? true : false )
                    }
                  />   
                </FormControl>
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'eeIntakeRequired'}
                    name={'eeIntakeRequired'}
                    label={'Make Questionnaire Available to EE'}
                    labelPlacement="end"
                    control={<Checkbox />}
                    checked={eeIntakeRequired}
                    variant="standard"
                    margin="dense"
                    sx={{ minWidth: '180px', maxWidth: '360px' }}
                    disabled={props.record.transfereeIntakeSubmitted}
                    onClick={(e) => {
                      if (props.record && !props.record.transfereeIntakeSubmitted) setEEIntakeRequired( e.target.checked ? true : false )
                    }}
                  />   
                </FormControl>
            </div>
          )}
          {(!props.record || isSaving || isResendingWelcomeEmail || grantingAccess) && (
            <Spinner />
          )}
          {(!moveTypeExists) && <FormHelperText error>You must set the MoveType before enabling Web Access for this authorization.</FormHelperText>}
          {/*{(noWebAccess) && <FormHelperText error>Web Access has not been granted for this authorization. Please verify phone number or email to be correct, then select 'Resend ReloSource Invitation'.</FormHelperText>}*/}
        </div>
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onCancel} variant="outlined" color="primary">Close</Button>
          <Button disabled={!props.record || (!moveTypeExists) || isResendingWelcomeEmail} onClick={handleResendWelcomeEmail} variant="contained" color="primary">Resend ReloSource Invitation</Button>
          <Button disabled={!props.record || (!moveTypeExists) || isSaving || grantingAccess} onClick={handleSave} variant="contained" color="primary">Save Changes</Button>
        </>
      }
    />
  );
}

WebAccessAlterationsDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  record: PropTypes.object,
};

export default compose(
  connect(null, {
    resendWelcomeEmail,
    setEEIntakeStatus,
    showToast,
    updateIntakeRecord,
    grantWebAccess,
  }),
)(WebAccessAlterationsDialog);