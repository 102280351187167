/* eslint-disable react-hooks/exhaustive-deps */

import {
  Grid,
} from '@mui/material';
import { POST_CANDIDATE_TRIP_FAILURE, UPDATE_CANDIDATE_TRIP_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { calculateTripDuration } from 'modules/authorizations/utils';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getCandidateTripById, postCandidateTrip, updateCandidateTrip } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
  
const CandidateTripCard = (props) => {
  
  const [isLoading, setIsLoading] = useState(false);
  
  const { trip, id, hideEdit } = props;

  const tripTabs = [
    { header: 'Trip Summary', order: 1, iconPath: ['fas', 'fa-plane-departure'] },
    { header: 'Travel Booking', order: 2, iconPath: ['fas', 'fa-hotel'] },
  ];
  
  const tripSummaries = [
    { header: 'Trip Title', accessorKey: 'tripName', type: 'text', required: false,  value: trip ? trip.tripName : '', tab: 1 },
    { header: 'Spouse Trip', accessorKey: 'spouseTrip', type: 'checkbox', required: false,  value: trip && trip.spouseTrip ? trip.spouseTrip : false, tab: 1 },
    { header: 'Trip Start Date', accessorKey: 'tripStartDate', type: 'date', required: false, value: trip ? trip.tripStartDate : null, tab: 1 },
    { header: 'Trip End Date', accessorKey: 'tripEndDate', type: 'date', required: false, value: trip ? trip.tripEndDate : null, tab: 1 },
    { header: 'Trip Duration', accessorKey: 'tripDuration', type: 'number', required: false, value: trip && (trip.tripStartDate && trip.tripEndDate) ? calculateTripDuration(trip.tripStartDate, trip.tripEndDate, trip.tripDuration) : 0, tab: 1 },
    { header: 'Special Needs', accessorKey: 'specialNeeds', type: 'notes', required: false, value: trip ? trip.specialNeeds : '', tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', required: false, value: trip ? trip.notes : '', tab: 1 },
    { header: 'Air Transport', accessorKey: 'airTransport', type: 'checkbox', required: false,  value: trip ? trip.airTransport : false, tab: 2 },
    { header: 'Departure Date', accessorKey: 'airTransportDetpartureDate', type: 'date', required: false, value: trip ? trip.airTransportDetpartureDate : null, tab: 2 },
    { header: 'Arrival Date', accessorKey: 'airTransportArrivalDate', type: 'date', required: false, value: trip ? trip.airTransportArrivalDate : null, tab: 2 },
    { dividerLine: true, tab: 2 },
    { header: 'Hotel Booking', accessorKey: 'hotelBooking', type: 'checkbox', required: false,  value: trip ? trip.hotelBooking : false, tab: 2 },
    { header: 'Hotel Name', accessorKey: 'hotelName', type: 'text', required: false,  value: trip ? trip.hotelName : '', tab: 2 },
    { header: 'Hotel Address', accessorKey: 'hotelAddress', type: 'text', required: false,  value: trip ? trip.hotelAddress : '', tab: 2 },
    { header: 'Hotel Check-In Date', accessorKey: 'hotelCheckIn', type: 'date', required: false, value: trip ? trip.hotelCheckIn : null, tab: 2 },
    { header: 'Hotel Check-Out Date', accessorKey: 'hotelCheckOut', type: 'date', required: false, value: trip ? trip.hotelCheckOut : null, tab: 2 },
    { header: 'Duration Of Stay', accessorKey: 'hotelDurationOfStay', type: 'number', required: false, value: trip && (trip.hotelCheckIn && trip.hotelCheckOut) ? calculateTripDuration(trip.hotelCheckIn, trip.hotelCheckOut, trip.hotelDurationOfStay) : 0, tab: 2 },
    { dividerLine: true, tab: 2 },
    { header: 'Rental Car', accessorKey: 'rentalCarBooking', type: 'checkbox', required: false,  value: trip ? trip.rentalCarBooking : false, tab: 2 },
    { header: 'Rental Car Year', accessorKey: 'rentalCarYear', type: 'text', required: false,  value: trip ? trip.rentalCarYear : '', tab: 2 },
    { header: 'Rental Car Make', accessorKey: 'rentalCarMake', type: 'text', required: false,  value: trip ? trip.rentalCarMake : '', tab: 2 },
    { header: 'Rental Car Model', accessorKey: 'rentalCarModel', type: 'text', required: false,  value: trip ? trip.rentalCarModel : '', tab: 2 },
    { header: 'Rental Car Start Date', accessorKey: 'rentalCarStartDate', type: 'date', required: false, value: trip ? trip.rentalCarStartDate : null, tab: 2 },
    { header: 'Rental Car End Date', accessorKey: 'rentalCarEndDate', type: 'date', required: false, value: trip ? trip.rentalCarEndDate : null, tab: 2 },
    { header: 'Rental Duration', accessorKey: 'rentalCarDuration', type: 'number', required: false, value: trip && (trip.rentalCarStartDate && trip.rentalCarEndDate) ? calculateTripDuration(trip.rentalCarStartDate, trip.rentalCarEndDate, trip.rentalCarDuration) : 0, tab: 2 },    
  ];
  
    
  
  const updateTripFields = async (tab, values) => {
    setIsLoading(true);
    if (trip && trip.length !== 0) {
      let newValues = { ...trip };

      if (tab === 1) {
        newValues.tripName = values.tripName;
        newValues.spouseTrip = values.spouseTrip;
        newValues.tripStartDate = values.tripStartDate;
        newValues.tripEndDate = values.tripEndDate;
        newValues.tripDuration = values.tripDuration;
        newValues.specialNeeds = values.specialNeeds;
        newValues.notes = values.notes;
      } else {
        newValues.airTransport = values.airTransport;
        newValues.airTransportDetpartureDate = values.airTransportDetpartureDate;
        newValues.airTransportArrivalDate = values.airTransportArrivalDate;
        newValues.hotelBooking = values.hotelBooking;
        newValues.hotelName = values.hotelName;
        newValues.hotelAddress = values.hotelAddress;
        newValues.hotelCheckIn = values.hotelCheckIn;
        newValues.hotelCheckOut = values.hotelCheckOut;
        newValues.hotelDurationOfStay = values.hotelDurationOfStay;
        newValues.rentalCarBooking = values.rentalCarBooking;
        newValues.rentalCarYear = values.rentalCarYear;
        newValues.rentalCarMake = values.rentalCarMake;
        newValues.rentalCarModel = values.rentalCarModel;
        newValues.rentalCarStartDate = values.rentalCarStartDate;
        newValues.rentalCarEndDate = values.rentalCarEndDate;
        newValues.rentalCarDuration = values.rentalCarDuration;
      }
  
      const resp = await props.updateCandidateTrip(newValues);
      if (resp.type === UPDATE_CANDIDATE_TRIP_FAILURE) {
        props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;
  
      const resp = await props.postCandidateTrip(values);
      if (resp.type === POST_CANDIDATE_TRIP_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  return (
    <Grid item sx={{ marginBottom: '10px' }}>
      <EditableCard
        tabs={tripTabs}
        summaries={tripSummaries}
        numColumns={5} 
        updateFields={updateTripFields}
        isLoading={isLoading}
        hideEdit={hideEdit}
      />
    </Grid>
  );
  
};
  
const mapStateToProps = (state) => {
  return {
  };
};
  
export default compose(
  connect(mapStateToProps, {
    postCandidateTrip,
    updateCandidateTrip,
    getCandidateTripById,
    showToast,
  }),
)(CandidateTripCard);