/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { MenuItem, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    firstCol: {
      width: 200,
    },
    textField: {
      minWidth: 350,
    },
  };
};

const HomeSaleGeneralInformation = (props) => {

  const clientHomesaleGeneralInfo = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleGeneral : null;

  const [policyId, setPolicyId] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.eligiblePolicyId : null);
  const [reimbursement, setReimbursement] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.reimbursement : null);
  const [complianceFees, setComplianceFees] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.complianceFees : null);
  const [preferredAgents, setPreferredAgents] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.preferredAgents : null);
  const [homeSaleBonusInitiated, setHomeSaleBonusInitiated] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.homeSaleBonusInitiated : null); 
  const [lossOnSaleInitiated, setLossOnSaledInitiated] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.lossOnSaleInitiated : null);
  const [equityAdvanceAllowed, setEquityAdvanceAllowed] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.equityAdvanceAllowed : null);
  const [closingCostsInitiated, setClosingCostsInitiated] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.closingCostsInitiated : false);
  const [hhgDetailsMatrixInitiated, setHhgDetailsMatrixInitiated] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.hhgDetailsMatrixInitiated : false);
  const [notes, setNotes] = useState(clientHomesaleGeneralInfo ? clientHomesaleGeneralInfo.notes : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      EligiblePolicyId: policyId,
      Reimbursement: reimbursement,
      ComplianceFees: complianceFees,
      PreferredAgents: preferredAgents,
      HomeSaleBonusInitiated: homeSaleBonusInitiated,
      LossOnSaleInitiated: lossOnSaleInitiated,
      EquityAdvanceAllowed: equityAdvanceAllowed,
      ClosingCostsInitiated: closingCostsInitiated,
      HhgDetailsMatrixInitiated: hhgDetailsMatrixInitiated,
      Notes: notes,
    };

    if (clientHomesaleGeneralInfo && clientHomesaleGeneralInfo.id) updateBody.Id = clientHomesaleGeneralInfo.id;

    props.updateInfo(updateBody);
  }, [policyId, reimbursement, complianceFees, preferredAgents, homeSaleBonusInitiated, lossOnSaleInitiated, equityAdvanceAllowed, notes, closingCostsInitiated, hhgDetailsMatrixInitiated]);

  return (
    <div className={props.classes.contractsGrid}>
      <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
        <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
          <TextField
            className={props.classes.textField}
            variant="outlined"
            fullWidth
            label={'Who is Eligible?'}
            onChange={(e) => setPolicyId(e.target.value)}
            select
            value={policyId}
          >
            {props.clientPolicyNotes && props.clientPolicyNotes.map((c) => {
              return <MenuItem key={c.clientPolicyNotesKey} value={c.clientPolicyNotesKey}>{c.name}</MenuItem>;
            })}
          </TextField>
          <TextField
            id="name"
            label="Direct Reimbursement (GBO, BVO, HMA, none)"
            className={props.classes.textField}
            variant="outlined"
            style={{ width: '100%', marginRight: 4 }}
            value={reimbursement}
            onChange={(e) => setReimbursement(e.target.value)}
          />
          <TextField
            className={props.classes.textField}
            variant="outlined"
            fullWidth
            label={'Non Compliance Fees - Client or Transferee Pays?'}
            onChange={(e) => setComplianceFees(e.target.value)}
            required
            select
            value={complianceFees}
          >
            <MenuItem value={'client'}>Client</MenuItem>
            <MenuItem value={'transferee'}>Transferee</MenuItem>
            <MenuItem value={'other'}>Other</MenuItem>
          </TextField>
          <TextField
            className={props.classes.textField}
            variant="outlined"
            fullWidth
            label={'Required to use TRCs network of Agents?'}
            onChange={(e) => setPreferredAgents(e.target.value)}
            required
            select
            value={preferredAgents}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
          </TextField>
          <TextField
            className={props.classes.textField}
            variant="outlined"
            fullWidth
            label={'Home Sale Bonus'}
            onChange={(e) => setHomeSaleBonusInitiated(e.target.value)}
            required
            select
            value={homeSaleBonusInitiated}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
            <MenuItem value={'na'}>N/A</MenuItem>
          </TextField>
        </div>

        <div className={props.classes.contentCol}>
          <TextField
            className={props.classes.textField}
            variant="outlined"
            fullWidth
            label={'Loss on Sale'}
            onChange={(e) => setLossOnSaledInitiated(e.target.value)}
            required
            select
            value={lossOnSaleInitiated}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
            <MenuItem value={'na'}>N/A</MenuItem>
          </TextField>
          <TextField
            className={props.classes.textField}
            variant="outlined"
            fullWidth
            label={'Equity Advance Allowed'}
            onChange={(e) => setEquityAdvanceAllowed(e.target.value)}
            required
            select
            value={equityAdvanceAllowed}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
            <MenuItem value={'na'}>N/A</MenuItem>
          </TextField>
          <TextField
            variant="outlined"
            label={'Other Notes'}
            value={notes}
            multiline
            minRows={5}
            sx={{ width: '1100px' }}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
}; 

HomeSaleGeneralInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleGeneralInformation);