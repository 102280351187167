/* eslint-disable react-hooks/exhaustive-deps */

import { POST_AUTH_CLIENT_POLICIES_FAILURE, UPDATE_AUTH_CLIENT_POLICIES_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getAuthClientPoliciesListByClient, postAuthClientPolicies, updateAuthClientPolicies } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useEffect, useState } from 'react';

const ClientPoliciesCard = (props) => {

  const [policyOptionsFull, setPolicyOptionsFull] = useState([]);
  const [policyOptions, setPolicyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { clientInfo, clientPolicies, id } = props;
  const details = props.authorizationDetails ? props.authorizationDetails[id] : null;

  const getClientPolicyOptions = async () => {
    let policyOptionsList = [];
    let clientPoliciesByClient = details?.authDetails?.clientPoliciesByClient;
    
    if(!clientPoliciesByClient || clientPoliciesByClient.length === 0){
      let clientPoliciesListResp = await props.getAuthClientPoliciesListByClient(clientInfo.moveTrackClientId);
      clientPoliciesByClient = clientPoliciesListResp?.response;
    }
    setPolicyOptionsFull(clientPoliciesByClient);
    clientPoliciesByClient.map((p) => policyOptionsList.push({ id: p.id, title: p.clientPolicyDescription }));
    setPolicyOptions(policyOptionsList);
  };

  const clientPoliciesTab = [
    { header: 'Client Policies', order: 1, iconPath: ['fa', 'file-contract'] },
  ];

  const clientPoliciesSummaries = [
    { header: 'Client Policy Name', accessorKey: 'clientPolicyId', type: 'dropdown-v2', options: policyOptions, required: true,  value: clientPolicies ? clientPolicies.clientPolicyId : '', tab: 1, hideInTable: true },
    { header: 'Client ID', accessorKey: 'clientId', type: 'text', required: false, value: clientPolicies ? clientPolicies.clientId : '', tab: 1, hideInModal: true },
    { header: 'Client Code', accessorKey: 'clientCode', type: 'text',  required: false, value: clientPolicies ? clientPolicies.clientCode : '', tab: 1, hideInModal: true },
    { header: 'Client Name', accessorKey: 'clientFullDescription', type: 'text', required: false, value: clientPolicies ? clientPolicies.clientFullDescription : '', tab: 1, hideInModal: true },
    { header: 'Client Status', accessorKey: 'clientStatus', type: 'text', required: false, value: clientPolicies ? clientPolicies.clientStatus : '', tab: 1, hideInModal: true },
    { header: 'Client Contact', accessorKey: 'clientContactName', type: 'text', required: false,  value: clientPolicies ? clientPolicies.clientContactName : '', tab: 1, hideInModal: true },
    { header: 'Relo Policy Code', accessorKey: 'clientPolicyCode', type: 'text', required: false, value: clientPolicies ? clientPolicies.clientPolicyCode : '', tab: 1, hideInModal: true },
    { header: 'Relo Policy Description', accessorKey: 'clientPolicyDescription', type: 'text', required: false, value: clientPolicies ? clientPolicies.clientPolicyDescription : '', tab: 1, hideInModal: true },
    { header: 'Billing Policy', accessorKey: 'clientBillingPolicy', type: 'text', required: false,  value: clientPolicies ? clientPolicies.clientBillingPolicy : '', tab: 1, hideInModal: true },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    const matchingPolicy = policyOptionsFull.find((p) => p.id === values.clientPolicyId);
    values = { ...matchingPolicy };
    values.authorizationId = props.id;
    values.clientPolicyId = matchingPolicy.id;
        
    if (clientPolicies && clientPolicies.length !== 0) {
      const resp = await props.updateAuthClientPolicies(values);
      if (resp.type === UPDATE_AUTH_CLIENT_POLICIES_FAILURE) {
        props.showToast('Failed to update the employee client policy info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postAuthClientPolicies(values);
      if (resp.type === POST_AUTH_CLIENT_POLICIES_FAILURE) {
        props.showToast('Failed to update employee client policy info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  useEffect(() => {
    if (clientInfo) {
      getClientPolicyOptions();
    }
  }, [clientInfo]);
    
  return (
    <EditableCard
      tabs={clientPoliciesTab}
      summaries={clientPoliciesSummaries}
      numColumns={4} 
      updateFields={updateFields}
      isLoading={isLoading}
      smallerModal={true}
    />
  );

};

const mapStateToProps = state => {
  const { authorizationDetails } = state.authorizations;
  return {
    authorizationDetails
  };
};


export default compose(
  connect(mapStateToProps, {
    getAuthClientPoliciesListByClient,
    postAuthClientPolicies,
    updateAuthClientPolicies,
    showToast,
  }),
)(ClientPoliciesCard);