import { ValueType, parseFormValue, vendorContactSearchInfo, vendorSearchInfo } from './formDataConstants';

export const createInternationalTrainingRows = (formDataList, vendors, vendorContacts, familyMembers) => {
  if (!formDataList || !Array.isArray(formDataList)) {
    return [];
  }

  return formDataList.map((data) => ({
    ...data,
    tuitionAmount: parseFormValue(data.tuitionAmount, ValueType.FloatType),
    costOfBooks: parseFormValue(data.costOfBooks, ValueType.FloatType),
    miscellaneousCost: parseFormValue(data.miscellaneousCost, ValueType.FloatType),
    studentsAge: parseFormValue(data.studentsAge, ValueType.FloatType),
    familyMember: familyMembers?.find((f) => f.id === data?.familyId)?.name,
  }));
};

export const createInternationalTrainingPostRequest = (formData, authorizationId, familyMembers) => {
  const request = { ...formData };
  request.authorizationId = authorizationId;
  parseSchoolingServiceFromValues(request, formData);
  request.familyId = familyMembers?.find((f) => f.name === formData.familyMember)?.id;
  return request;
};

export const createInternationalTrainingPutRequest = (originalFormData, newFormData, authorizationId, familyMembers) => {
  const request = { ...newFormData };
  request.authorizationId = authorizationId;
  request.authGlobalInternationSchoolingId = originalFormData.authGlobalInternationSchoolingId;
  request.familyId = familyMembers?.find((f) => f.name === newFormData.familyMember)?.id;
  return request;
};

export const createInternationalSchoolingColumns = (vendors, vendorContacts, familyMembers) => {
  return [
    {
      accessorKey: 'familyMember',
      header: 'Student',
      type: 'dropdown',
      options: familyMembers?.map((f) => f.name),
      required: true,
      size: 65,
    },
    {
      accessorKey: 'vendorId',
      header: 'Vendor (School)',
      nameKey: 'companyName',
      searchInfo: vendorSearchInfo(vendors),
      type: 'advanced-search',
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? vendors?.find((v) => v.vendorId === cell.getValue())?.companyName : ''),
    },
    {
      //TODO - Figure out how to filter this based on the selected company ...
      accessorKey: 'vendorContactId',
      header: 'Vendor Contact',
      nameKey: 'fullName',
      searchInfo: vendorContactSearchInfo(vendorContacts),
      type: 'advanced-search',
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? vendorContacts?.find((v) => v.vendorContactId === cell.getValue())?.fullName : ''),
    },
    { accessorKey: 'schoolinglevel', header: 'Schooling Level', type: 'text', required: true, size: 65 },
    { accessorKey: 'tuitionAmount', header: 'Tuition Amount', type: 'number', hideInTable: false, size: 65 },
    { accessorKey: 'costOfBooks', header: 'Cost of Books', type: 'number', hideInTable: true, size: 65 },
    { accessorKey: 'miscellaneousCost', header: 'Miscellaneous Cost', type: 'number', hideInTable: true, size: 65 },
    { accessorKey: 'tuitionPaymentTerms', header: 'Tuition Payment Terms', type: 'text', hideInTable: true, size: 65 },
    { accessorKey: 'studentsAge', header: 'Students Age', type: 'number', hideInTable: true, size: 65 },
    { accessorKey: 'program', header: 'Program', type: 'text', hideInTable: true, size: 65 },
  ];
};

const parseSchoolingServiceFromValues = (currentRequest, newRequestValues) => {
  currentRequest.tuitionAmount = parseFormValue(newRequestValues.tuitionAmount, ValueType.FloatType);
  currentRequest.costOfBooks = parseFormValue(newRequestValues.costOfBooks, ValueType.FloatType);
  currentRequest.miscellaneousCost = parseFormValue(newRequestValues.miscellaneousCost, ValueType.FloatType);
  currentRequest.studentsAge = parseFormValue(newRequestValues.studentsAge, ValueType.FloatType);
};
