/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';

import { Box, Grid } from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import VisaPermitCard from './visaPermitCard.component';
import ImmunizationCard from './immunizationCard.component';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const VisaTab = props => {
  const { details, id, familyMemberId, isReadOnly } = props;

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={6} rowSpacing={2}>
            <VisaPermitCard globalInfo={familyMemberId === 0 ? details?.authGlobal?.global : details?.authDetails?.family.find((f) => f.familyMemberId === familyMemberId)} id={id} familyMemberId={familyMemberId} isReadOnly={isReadOnly}/>
        </Grid>
        <Grid item xs={12} xl={6} rowSpacing={2}>
            <ImmunizationCard globalInfo={familyMemberId === 0 ? details?.authGlobal?.global : details?.authDetails?.family.find((f) => f.familyMemberId === familyMemberId)} id={id} familyMemberId={familyMemberId} isReadOnly={isReadOnly}/>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = state => {
  return {};
};

export default compose(withStyles(styles), connect(mapStateToProps, {}))(VisaTab);
