import { Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditableCard from 'modules/common/editableCard.component';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { updateExpenseLine } from 'modules/expenses/expenses.actions';
import { UPDATE_EXPENSE_LINE_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

const PrimaryExpenseLineDetailsInfo = props => {
  const { expenseLines } = props.details?.authExpenses;
  const expenseLineItemId = parseInt(props.expenseLineId, 10);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedExpenseLineItem, setSelectedExpenseLineItem] = useState();

  useEffect(() => {
    if (expenseLines && expenseLines.length > 0 && expenseLineItemId !== undefined && expenseLineItemId !== null) {
      const foundItem = expenseLines.find(s => s.expenseLineId === expenseLineItemId);
      setSelectedExpenseLineItem(foundItem);
    }
  }, [expenseLines, expenseLineItemId]);

  const accountsPayableTabs = [
    { header: 'Accounts Payable', order: 1, iconPath: ['fas', 'suitcase'] },
  ];

  const accountsReceivableTabs = [
    { header: 'Accounts Receivable', order: 1, iconPath: ['fas', 'suitcase'] },
  ];

  const basicInfoTabs = [
      { header: 'Basic Info', order: 1, iconPath: ['fas', 'suitcase'] },
    ];

  const accountsPayableSummaries = [
    { header: 'Voucher Number', accessorKey: 'voucherNumber', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.voucherNumber : '', tab: 1, },
    { header: 'GL Number', accessorKey: 'glNumber', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.glNumber : '', tab: 1, },
    { header: 'Invoice Number', accessorKey: 'apInvoiceNumber', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.apInvoiceNumber : '', tab: 1 },
    { header: 'Reimbursement Info', accessorKey: 'reimbursementInfo', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.reimbursementInfo : '', tab: 1 },
    { header: 'Payment Address', accessorKey: 'paymentAddress', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.paymentAddress : '', tab: 1 },
    { header: 'AP in Process', accessorKey: 'apInProgress', type: 'date', required: false,  value: selectedExpenseLineItem ? selectedExpenseLineItem.apInProgress : null, tab: 1 },
    { header: 'AP Posted', accessorKey: 'apPosted', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.apPosted : null, tab: 1 },
    { header: 'Check Number', accessorKey: 'apCheckNumber', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.apCheckNumber : '', tab: 1 },
    { header: 'Check Date', accessorKey: 'checkDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.checkDate : null, tab: 1 },
    { header: 'Wire Transfer', accessorKey: 'wireTransfer', type: 'checkbox', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.wireTransfer : false, tab: 1 },
    { header: 'AP Route To', accessorKey: 'apRouteTo', type: 'dropdown', options: ['Yes', 'No'], required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.apRouteTo : '', tab: 1 },
    { header: 'Account One Balance', accessorKey: 'accountOneBalance', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.accountOneBalance : '', tab: 1 },
    { header: 'Account Two Balance', accessorKey: 'accountTwoBalance', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.accountTwoBalance : '', tab: 1 },
    { header: 'Reduce Advance', accessorKey: 'reduceAdvance', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.reduceAdvance : '', tab: 1 },
  ];

  const accountsReceivableSummaries = [
    { header: 'Invoice Number', accessorKey: 'arInvoiceNumber', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.arInvoiceNumber : '', tab: 1, },
    { header: 'Invoice Date', accessorKey: 'invoiceDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.invoiceDate : null, tab: 1, },
    { header: 'AR In Progress', accessorKey: 'arInProgress', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.arInProgress : null, tab: 1 },
    { header: 'AR Posted', accessorKey: 'arPosted', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.arPosted : null, tab: 1 },
    { header: 'Currency Code', accessorKey: 'currencyCode', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.currencyCode : '', tab: 1 },
    { header: 'Amount', accessorKey: 'arAmount', type: 'money', required: false,  value: selectedExpenseLineItem ? selectedExpenseLineItem.arAmount : '', tab: 1 },
    { header: 'Cash Received', accessorKey: 'cashReceived', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.cashReceived : null, tab: 1 },
    { header: 'AR Paid Date', accessorKey: 'arPaidDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.arPaidDate : null, tab: 1 },
    { header: 'Check Number', accessorKey: 'arCheckNumber', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.arCheckNumber : '', tab: 1 },
    { header: 'AR Paid Amount', accessorKey: 'arPaidAmount', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.arPaidAmount : '', tab: 1 },
    { header: 'Writeoff Amount', accessorKey: 'writeOffAmount', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.writeOffAmount : '', tab: 1 },
    { header: 'Total Deposit Billing', accessorKey: 'totalDepositBilling', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.totalDepositBilling : '', tab: 1 },
    { header: 'Balance Deposit Billing', accessorKey: 'balanceDepositBilling', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.balanceDepositBilling : '', tab: 1 },
    { header: 'Refund Date', accessorKey: 'refundDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.refundDate : null, tab: 1 },
    { header: 'Refund Amount', accessorKey: 'refundAmount', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.refundAmount : '', tab: 1 },
    { header: 'Refund Check Num', accessorKey: 'refundCheckNum', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.refundCheckNum : '', tab: 1 },
    { header: 'Void Invoice Number', accessorKey: 'voidInvoiceNumber', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.voidInvoiceNumber : '', tab: 1 },
    { header: 'Void Date', accessorKey: 'voidDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.voidDate : null, tab: 1 },
    { header: 'Void By', accessorKey: 'voidBy', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.voidBy : '', tab: 1 },
    { header: 'Unpay Date', accessorKey: 'unpayDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.unpayDate : null, tab: 1 },
    { header: 'Unpay By', accessorKey: 'unpayBy', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.unpayBy : '', tab: 1 },
    { header: 'Dep Applied Amount', accessorKey: 'depAppliedAmount', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.depAppliedAmount : '', tab: 1 },
];

const basicInfoSummaries = [
  { header: 'Amount', accessorKey: 'amount', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.amount : '', tab: 1, },
  { header: 'Expense Code', accessorKey: 'expenseCode', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.expenseCode : '', tab: 1, },
  { header: 'Code Desc', accessorKey: 'expenseCodeDescription', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.expenseCodeDescription : '', tab: 1 },
  { header: 'Payroll Date', accessorKey: 'payrollDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.payrollDate : null, tab: 1 },
  { header: 'Entry Date', accessorKey: 'entryDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.entryDate : null, tab: 1 },
  { header: 'Tax Report Date', accessorKey: 'taxReportDate', type: 'date', required: false,  value: selectedExpenseLineItem ? selectedExpenseLineItem.taxReportDate : null, tab: 1 },
  { header: 'Invoice Date', accessorKey: 'invoiceDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.invoiceDate : null, tab: 1 },
  { header: 'Service', accessorKey: 'service', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.service : '', tab: 1 },
  { header: 'Category', accessorKey: 'category', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.category : '', tab: 1 },
  { header: 'From Date', accessorKey: 'fromDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.fromDate : null, tab: 1 },
  { header: 'To Date', accessorKey: 'toDate', type: 'date', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.toDate : null, tab: 1 },
  { header: 'Paid To Detail', accessorKey: 'paidToDetail', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.paidToDetail : '', tab: 1 },
  { header: 'Approved Amount', accessorKey: 'approved', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.approved : '', tab: 1 },
  { header: 'Denied Amount', accessorKey: 'denied', type: 'money', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.denied : '', tab: 1 },
  { header: 'Denied Reason', accessorKey: 'deniedReason', type: 'text', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.deniedReason : '', tab: 1 },
  { header: 'Reference', accessorKey: 'reference', type: 'notes', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.reference : '', tab: 1 },
  { header: 'AP Line Desc', accessorKey: 'apLineDescription', type: 'notes', required: false, value: selectedExpenseLineItem ? selectedExpenseLineItem.apLineDescription : '', tab: 1 },
];

const updateAccountsPayableFields = async (tab, values) => {
  setIsLoading(false);
  let newValues = { ...selectedExpenseLineItem };

  // Accounts Payable
  newValues.voucherNumber = values.voucherNumber;
  newValues.glNumber = values.glNumber;
  newValues.apInvoiceNumber = values.apInvoiceNumber;
  newValues.reimbursementInfo = values.reimbursementInfo;
  newValues.paymentAddress = values.paymentAddress;
  newValues.apInProgress = values.apInProgress;
  newValues.apPosted = values.apPosted;
  newValues.apCheckNumber = values.apCheckNumber;
  newValues.checkDate = values.checkDate;
  newValues.wireTransfer = values.wireTransfer;
  newValues.apRouteTo = values.apRouteTo;
  newValues.accountOneBalance = values.accountOneBalance;
  newValues.accountTwoBalance = values.accountTwoBalance;
  newValues.reduceAdvance = values.reduceAdvance;
      
  if (selectedExpenseLineItem && selectedExpenseLineItem.length !== 0) {

    const resp = await props.updateExpenseLine(newValues);
    if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
      props.showToast('Failed to update the approved expense account info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  }
};

const updateAccountsReceivableFields = async (tab, values) => {
  setIsLoading(false);
  let newValues = { ...selectedExpenseLineItem };

    // Accounts Receivable
    newValues.arInvoiceNumber = values.arInvoiceNumber;
    newValues.invoiceDate = values.invoiceDate;
    newValues.arInProgress = values.arInProgress;
    newValues.arPosted = values.arPosted;
    newValues.currencyCode = values.currencyCode;
    newValues.arAmount = values.arAmount;
    newValues.cashReceived = values.cashReceived;
    newValues.arPaidDate = values.arPaidDate;
    newValues.arCheckNumber = values.arCheckNumber;
    newValues.arPaidAmount = values.arPaidAmount;
    newValues.writeoffAmount = values.writeoffAmount;
    newValues.totalDepositBilling = values.totalDepositBilling;
    newValues.balanceDepositBilling = values.balanceDepositBilling;
    newValues.refundDate = values.refundDate;
    newValues.refundAmount = values.refundAmount;
    newValues.refundCheckNum = values.refundCheckNum;
    newValues.voidInvoiceNumber = values.voidInvoiceNumber;
    newValues.voidDate = values.voidDate;
    newValues.voidBy = values.voidBy;
    newValues.unpayDate = values.unpayDate;
    newValues.unpayBy = values.unpayBy;
    newValues.depAppliedAmount = values.depAppliedAmount;
      
  if (selectedExpenseLineItem && selectedExpenseLineItem.length !== 0) {

    const resp = await props.updateExpenseLine(newValues);
    if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
      props.showToast('Failed to update the approved expense account info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  }
};

const updateBasicFields = async (tab, values) => {
  setIsLoading(false);

  let newValues = { ...selectedExpenseLineItem };

  newValues.amount = values.amount;
  newValues.expenseCode = values.expenseCode;
  newValues.expenseCodeDescription = values.expenseCodeDescription;
  newValues.payrollDate = values.payrollDate;
  newValues.entryDate = values.entryDate;
  newValues.taxReportDate = values.taxReportDate;
  newValues.invoiceDate = values.invoiceDate;
  newValues.service = values.service;
  newValues.category = values.category;
  newValues.fromDate = values.fromDate;
  newValues.toDate = values.toDate;
  newValues.paidToDetail = values.paidToDetail;
  newValues.approved = values.approved;
  newValues.denied = values.denied;
  newValues.deniedreason = values.deniedreason;
  newValues.reference = values.reference;
  newValues.apLineDescription = values.apLineDescription;
      
  if (selectedExpenseLineItem && selectedExpenseLineItem.length !== 0) {
    const resp = await props.updateExpenseLine(newValues);
    if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
      props.showToast('Failed to update the approved expense basic info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  }
};

  return (
      <Box>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={12} xl={8} rowSpacing={2}>
                  <EditableCard
                      tabs={accountsPayableTabs}
                      summaries={accountsPayableSummaries}
                      numColumns={5} 
                      updateFields={updateAccountsPayableFields}
                      isLoading={isLoading}
                      hideEdit={props.viewOnly}
                  />
                  <Box sx={{ paddingTop: '16px' }}>
                      <EditableCard
                          tabs={accountsReceivableTabs}
                          summaries={accountsReceivableSummaries}
                          numColumns={5} 
                          updateFields={updateAccountsReceivableFields}
                          isLoading={isLoading}
                          hideEdit={props.viewOnly}
                      />
                  </Box>
              </Grid>
              <Grid item xs={12} xl={4} rowSpacing={2}>
                  <EditableCard
                      tabs={basicInfoTabs}
                      summaries={basicInfoSummaries}
                      numColumns={2} 
                      updateFields={updateBasicFields}
                      isLoading={isLoading}
                      hideEdit={props.viewOnly}
                  />
              </Grid>
        </Grid>
      </Box>
  );
};

export default compose(connect(null, { updateExpenseLine }))(PrimaryExpenseLineDetailsInfo);
