/* eslint-disable array-callback-return */
import { keyBy } from 'lodash';

export const remapClients = (clients) => {
  return keyBy(clients, 'id');
};

export const remapUsers = (users) => {
  return keyBy(users, 'id');
};

export const extractManagers = (clients) => {
  let managers = [];
  clients.map((client) => {
    if (!managers.some((manager) => manager === client.serviceRep) && client.serviceRep != null && client.serviceRep.length !== 0) {
      managers.push(client.serviceRep);
    }
  });
  return managers.sort((a, b) => a > b ? 1 : -1);
};

export const sortById = (clients) => clients.sort((a, b) => a.moveTrackClientId - b.moveTrackClientId);

export const cleanClientDetails = (clientDetails) => {
  clientDetails.addedOn = clientDetails.addedOn?.split('T')[0];
  clientDetails.modifiedOn = clientDetails.modifiedOn?.split('T')[0];
  clientDetails.contractDate = clientDetails.contractDate?.split('T')[0];
  clientDetails.contractExpires = clientDetails.contractExpires?.split('T')[0];
  clientDetails.renewalStart = clientDetails.renewalStart?.split('T')[0];
  clientDetails.renewalEnd = clientDetails.renewalEnd?.split('T')[0];
  clientDetails.cancelDate = clientDetails.cancelDate?.split('T')[0];
  return clientDetails;
};

export const getMonth = (num) => {
  if (num === 0) return 'Jan';
  else if (num === 1) return 'Feb';
  else if (num === 2) return 'Mar';
  else if (num === 3) return 'Apr';
  else if (num === 4) return 'May';
  else if (num === 5) return 'Jun';
  else if (num === 6) return 'Jul';
  else if (num === 7) return 'Aug';
  else if (num === 8) return 'Sep';
  else if (num === 9) return 'Oct';
  else if (num === 10) return 'Nov';
  else if (num === 11) return 'Dec';
};