import { Checkbox, Divider, FormControlLabel, MenuItem, Typography, withStyles } from '@material-ui/core';
import { Field } from 'formik';
import { Select, TextField } from 'modules/intake/form';
import { compose } from 'recompose';
import { formatDateForInput } from 'utilities/common';
import { memoize } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import contractTerms from 'modules/common/contractTerms.json';
import terms from 'modules/common/terms.json';


const styles = (theme) => ({
  contractsGrid: {
    maxWidth: 900,
    marginTop: theme.spacing(4),
  },
  inputGroup: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  root: {
    minWidth: 350,
  },
  webRoot: {
    minWidth: 315,
  },
  mediumRoot: {
    minWidth: 200,
  },
  miniRoot: {
    minWidth: 170,
  },
  cancelRoot: {
    minWidth: 140,
  },
  currencyRoot: {
    width: 350,
  },
  detailsLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
  },
  divider: {
    width: 350,
    marginRight: theme.spacing(1),
  },
  contentCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstCol: {
    width: 200,
  },
  dualField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 200,
  },
  checkbox: {
    paddingTop: 15,
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    alignContent: 'center',
  },
});

const ClientContractsTable = (props) => {
  const formikProps = props.formikProps;

  //if clientContract is undefined/null, it hasn't been populated yet in common.reducer.js so return null
  if (!formikProps.values.clientContract) return null;

  const renderTermsOptions = memoize(() => {
    return terms.map(({ term, description }) => (
      <MenuItem key={term} value={term}>{description}</MenuItem>
    ));
  });

  const renderContractTermsOptions = memoize(() => {
    return contractTerms.map(({ contract, description }) => (
      <MenuItem key={contract} value={contract}>{description}</MenuItem>
    ));
  });

  return (
    <div className="form-group">
      <Typography gutterBottom variant="h3">Contracts</Typography>
      <div className={props.classes.contractsGrid}>
        <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
          <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
            <Field
              component={TextField}
              name="dbNumber"
              label="D&B Number"
              className={props.classes.root}
              value={formikProps.values.clientContract.dbNumber}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={Select}
              name="contractTerms"
              label="Contract Terms"
              className={props.classes.root}
              value={formikProps.values.clientContract.contractTerms}
              InputLabelProps={{ style: { fontSize: 13 } }}
            >
              {renderContractTermsOptions()}
            </Field>
            <div className="my-2"><Divider variant="fullWidth" classes={{ root: props.classes.divider }} /></div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="contractStart"
                label="Original Contract Start"
                className={props.classes.miniRoot}
                value={formatDateForInput(formikProps.values.clientContract.contractStart)}
                type="date"
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="contractEnd"
                label="Original Contract End"
                className={props.classes.miniRoot}
                value={formatDateForInput(formikProps.values.clientContract.contractEnd)}
                type="date"
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="renewalStart"
                label="Last Renewal"
                className={props.classes.miniRoot}
                value={formatDateForInput(formikProps.values.clientContract.renewalStart)}
                type="date"
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="renewalEnd"
                label="Last Renewal End"
                className={props.classes.miniRoot}
                value={formatDateForInput(formikProps.values.clientContract.renewalEnd)}
                type="date"
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="lastRFPDate"
                label="Last RFP Date"
                className={props.classes.cancelRoot}
                value={formatDateForInput(formikProps.values.clientContract.lastRFPDate)}
                type="date"
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <div className={props.classes.dualField}>
              <Field
                component={TextField}
                name="cancelDate"
                label="Cancelled Date"
                className={props.classes.cancelRoot}
                value={formatDateForInput(formikProps.values.clientContract.cancelDate)}
                type="date"
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
              <Field
                component={TextField}
                name="cancelBy"
                label="Cancelled By"
                className={props.classes.mediumRoot}
                value={formikProps.values.clientContract.cancelBy}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            </div>
            <div className="my-2">
              <Divider variant="fullWidth" classes={{ root: props.classes.divider }} /></div>
            <FormControlLabel
              control={
                <Field
                  component={Checkbox}
                  name="isHHGRevenueAct"
                  label="HHG Revenue Act"
                  classes={{ root: props.classes.checkbox }}
                  checked={formikProps.values.clientContract.isHHGRevenueAct}
                />
              }
              label="HHG Revenue Act"
              classes={{ root: props.classes.checkbox }}
              title={'Check box if contract applies to the HHG Revenue Act'}
            />
          </div>

          <div className={props.classes.contentCol}>
            <Field
              component={TextField}
              name="contractCredits"
              label="Credits"
              variant="outlined"
              value={formikProps.values.clientContract.contractCredits}
              className={props.classes.root}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="contractRebates"
              label="Rebates"
              variant="outlined"
              value={formikProps.values.clientContract.contractRebates}
              className={props.classes.root}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="resignBonus"
              label="Resigning Bonus"
              variant="outlined"
              value={formikProps.values.clientContract.resignBonus}
              className={props.classes.root}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
            <Field
              component={TextField}
              name="resignBonusTerms"
              label="Resigning Bonus Terms"
              className={props.classes.root}
              value={formikProps.values.clientContract.resignBonusTerms}
              InputLabelProps={{ style: { fontSize: 13 } }}

            />
            <Field
              component={Select}
              name="expenseTerms"
              label="Expense Payment Terms"
              className={props.classes.root}
              value={formikProps.values.clientContract.expenseTerms}
              InputLabelProps={{ style: { fontSize: 13 } }}
            >
              {renderTermsOptions()}
            </Field>
            
            <Field
              component={Select}
              name={'feeTerms'}
              label="Service Fee Payment Terms"
              className={props.classes.root}
              value={formikProps.values.clientContract.feeTerms}
              InputLabelProps={{ style: { fontSize: 13 } }}
            >
              {renderTermsOptions()}

            </Field>
          </div>
        </form>
      </div>
    </div>
  );
};

ClientContractsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
};
  
export default compose(
  withStyles(styles),
)(ClientContractsTable);