/* eslint-disable react/prop-types */
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box } from '@material-ui/core';
import { Button, Grid, MenuItem, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Field } from 'formik';
import { POST_CLIENT_CALENDAR_FAILURE } from 'modules/clients/types';
import { Select, TextField } from 'modules/intake/form';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { cleanCalendarInfo, generateFinalPeriodList, parsePayrollEvents, periodOptions } from './utils';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postClientCalendar } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import Dialog from 'modules/common/dialog.component';
import Hint from 'modules/common/hint.component';
import NewAnnualDates from './newAnnualDates.component';
import NewPeriodDates from './newPeriodDates.component';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import classNames from 'classnames';
import moment from 'moment';

const styles = (theme) => ({
  container: {
    height: '100%',
  },
  root: {
    minWidth: 250,
  },
  tab: {
    width: 160,
    minWidth: 160,
  },
  tabsRoot: {
    marginBottom: theme.spacing(1),
  },
  textFieldExt: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  dropdownItem: {
    minWidth: 150,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 150,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(10),
    width: '90%',
    position: 'fixed',
    bottom: 0,
  },
  newCalendarButtons: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  buttonProgress: {
    width: 100,
  },
});  

const localizer = momentLocalizer(moment);

const NewCalendarComponent = (props) => {
  const [focusedTab, setFocusedTab] = useState('ANNUAL DATES');
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const [payrollEvents, setPayrollEvents] = useState([]);

  const formikProps = props.formikProps;

  const generateSampleCalendar = (values) => {
    const periodList = generateFinalPeriodList(values);
    setPayrollEvents(parsePayrollEvents(values.calendarInfo, periodList));
  };

  const publishCalendar = async (values) => {
    let resp = await props.postClientCalendar({
      CalendarInfo: cleanCalendarInfo(values.calendarInfo),
      PeriodList: generateFinalPeriodList(values),
    });

    if (resp.type === POST_CLIENT_CALENDAR_FAILURE) {
      props.showToast('Failed to publish your new calendar, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    let calendarId = resp.response;

    props.newCalendarCreated(values.calendarInfo.calendarYear, calendarId);
  };

  return (
    <Box marginX={2} marginY={2}>
      <div className={props.classes.container}>
        {formikProps.values && (
          <>
            <h2>New Calendar</h2>

            <Grid container>
              <Grid xs={6}>
                <div className={props.classes.headerContainer}>
                  <Field
                    component={TextField}
                    name={'calendarInfo.name'}
                    label={'Calendar Name'}
                    className={props.classes.root}
                    value={formikProps.values.calendarInfo.name}
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={Select}
                    name={'calendarInfo.calendarYear'}
                    label="Select Year"
                    value={formikProps.values.calendarInfo.calendarYear}
                    fullWidth
                    labelWidth={20}
                  >
                    {[2023, 2024, 2025].map((y) => {
                      return (
                        <MenuItem value={y} className={props.classes.dropdownItem}>
                          {y}
                        </MenuItem>
                      );
                    })}
                  </Field>
                  <Field
                    component={Select}
                    name={'calendarInfo.periodFrequencyId'}
                    label="Select Period Length"
                    value={formikProps.values.calendarInfo.periodFrequencyId}
                    fullWidth
                    labelWidth={20}
                  >
                    {periodOptions.map((p) => {
                      return (
                        <MenuItem value={p.id}>
                          {p.description}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </div>

                {formikProps.values.calendarInfo.calendarYear && formikProps.values.calendarInfo.periodFrequencyId && (
                  <>
                    <Tabs
                      variant="standard"
                      value={focusedTab}
                      onChange={(event, tab) => setFocusedTab(tab)}
                      classes={{
                        root: classNames(props.classes.tabsRoot),
                      }}
                    >
                      <Tab value={'ANNUAL DATES'} label="Annual Info" classes={{ root: classNames(props.classes.tab) }} />
                      <Tab value={'PERIOD DATES'} label="Period Info" classes={{ root: classNames(props.classes.tab) }} />
                    </Tabs>

                    {focusedTab === 'ANNUAL DATES' && (
                      <NewAnnualDates formikProps={formikProps} />
                    )}

                    {focusedTab === 'PERIOD DATES' && (
                      <NewPeriodDates formikProps={formikProps} />
                    )}
                  </>
                )}

                {(!formikProps.values.calendarInfo.calendarYear || !formikProps.values.calendarInfo.periodFrequencyId) && (
                  <Hint>Select a period length and year to edit dates below.</Hint>
                )}
                        
              </Grid>
              <Grid xs={6}>
                <Calendar
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500, marginTop: 20 }}
                  views={['month', 'week']}
                  events={payrollEvents}
                  eventPropGetter={(event) => {
                    const eventData = payrollEvents.find((o) => o.id === event.id);
                    const backgroundColor = eventData && eventData.color;
                    return { style: { backgroundColor } };
                  }}
                />

                <div className={props.classes.newCalendarButtons}>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={() => generateSampleCalendar(formikProps.values)}
                    disabled={!formikProps.values.calendarInfo.calendarYear || !formikProps.values.calendarInfo.periodFrequencyId}
                  >
                    Generate Sample
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => setShowPublishConfirmation(true)}
                    disabled={!formikProps.values.calendarInfo.name || !formikProps.values.calendarInfo.calendarYear || !formikProps.values.calendarInfo.periodFrequencyId}
                  >
                    Publish Calendar
                  </Button>
                </div>

                {(!formikProps.values.calendarInfo.name || !formikProps.values.calendarInfo.calendarYear || !formikProps.values.calendarInfo.periodFrequencyId) && (
                  <Hint>Calendar name, year, and period length are required to publish a new calendar.</Hint>
                )}
              </Grid>
            </Grid>

            {showPublishConfirmation && (
            // <ConfirmationDialog
            //     onConfirm={() => {publishCalendar(formikProps.values);}}
            //     onCancel={() => {setShowPublishConfirmation(false);}}
            //     open
            //     title="Calendar Publication Confirmation"
            //     confirmLabel="Publish"
            // >
            //     <Typography>Are you sure you would like to publish this calendar? Try generating a sample to confirm your dates look as expected.</Typography>
            // </ConfirmationDialog>

              <Dialog
                title="Calendar Publication"
                onCancel={() => { setShowPublishConfirmation(false); }}
                open={showPublishConfirmation}
                content={
                  <Typography>Are you sure you would like to publish this calendar? Try generating a sample to confirm your dates look as expected.</Typography>
                }
                actions={
                  <>
                    {!props.isPublishingCalendar &&
                      <Button size="large" variant="outlined" color="secondary" onClick={() => { setShowPublishConfirmation(false); }}>Cancel</Button>
                    }
                                
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      disabled={props.isPublishingCalendar}
                      onClick={() => {publishCalendar(formikProps.values);}}
                      className={props.classes.buttonProgress}
                    >
                      {props.isPublishingCalendar ? <Spinner size="lg" color="#fff" /> : 'Submit'}
                    </Button>
                  </>
                }
              />
            )}
          </>
        )}
      </div>
    </Box>
  );
};

NewCalendarComponent.propTypes = {
  clientId: PropTypes.number,
};


const mapStateToProps = (state) => {
  const { isPublishingCalendar } = state.clients;
  return {
    isPublishingCalendar,
  };
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    postClientCalendar,
    showToast,
  }),
)(NewCalendarComponent);