import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateListing } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const OtherInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { listingInfo, isReadOnly } = props;

  const tabs = [
    { header: 'Other Info', order: 1, iconPath: ['fas', 'file-contract'] },
  ];

  const selectedByOptions = [
    { title: 'Relo', id: 1 },
    { title: 'EE', id: 2 },
    { title: 'Client', id: 3 },
  ];

  const listingTypes = [
    { title: 'ORIGLIST', id: 1 },
    { title: 'EXTENSION', id: 2 },
    { title: 'PRICECHG', id: 3 },
    { title: 'RELIST', id: 4 },
  ];

  const summaries = [
    { header: 'Listing Agent Name', accessorKey: 'listingAgentName', type: 'text', required: false, value: listingInfo ? listingInfo.listingAgentName : '', tab: 1 },
    { header: 'Listing Agent Phone', accessorKey: 'listingAgentPhone', type: 'text', required: false, options: selectedByOptions, value: listingInfo ? listingInfo.listingAgentPhone : '', tab: 1 },
    { header: 'Listing Agent Email', accessorKey: 'listingAgentEmail', type: 'text', required: false, options: listingTypes, value: listingInfo ? listingInfo.listingAgentEmail : '', tab: 1 },
    { header: 'Goal to List On', accessorKey: 'goalToListOn', type: 'date', required: false, value: listingInfo ? listingInfo.goalToListOn : '', tab: 1 },
    { header: 'Send Broker Info On', accessorKey: 'sendBrokerInfoOn', type: 'date', required: false, value: listingInfo ? listingInfo.sendBrokerInfoOn : '', tab: 1 },
    { header: 'MLS Link', accessorKey: 'mlsLink', type: 'text', required: false, value: listingInfo ? listingInfo.mlsLink : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...listingInfo };

    newValues.listingAgentName = values.listingAgentName;
    newValues.listingAgentPhone = values.listingAgentPhone;
    newValues.listingAgentEmail = values.listingAgentEmail;
    newValues.goalToListOn = values.goalToListOn;
    newValues.sendBrokerInfoOn = values.sendBrokerInfoOn;
    newValues.mlsLink = values.mlsLink;

    const resp = await props.updateListing(newValues);
    if (resp.type === UPDATE_LISTINGS_FAILURE) {
      props.showToast('Failed to update broker info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateListing,
    showToast,
  }),
)(OtherInfoCard);