import {
  GET_ALL_CANDIDATE_TRIPS_SUCCESS,
  GET_CANDIDATE_SERVICES_SUCCESS,
  GET_COST_ESTIMATES_SUCCESS,
  GET_INTERN_SERVICES_SUCCESS,
  POST_CANDIDATE_SERVICES_SUCCESS,
  POST_COST_ESTIMATES_SUCCESS,
  POST_INTERN_SERVICES_SUCCESS,
  UPDATE_CANDIDATE_SERVICES_SUCCESS,
  UPDATE_CANDIDATE_TRIP_SUCCESS,
  UPDATE_COST_ESTIMATES_SUCCESS,
  UPDATE_INTERN_SERVICES_SUCCESS
} from '../types/canidateTripTypes';

const handleCanidateTrips = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId].authCanidateTrips)
    draft.authorizationDetails[draft.currentAuthId].authCanidateTrips = {};

  switch (action.type) {
    case GET_CANDIDATE_SERVICES_SUCCESS:
    case UPDATE_CANDIDATE_SERVICES_SUCCESS:
    case POST_CANDIDATE_SERVICES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authCanidateTrips.candidateServices = action.response;
      break;

    case POST_INTERN_SERVICES_SUCCESS:
    case UPDATE_INTERN_SERVICES_SUCCESS:
    case GET_INTERN_SERVICES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authCanidateTrips.internServices = action.response;
      break;

    case GET_ALL_CANDIDATE_TRIPS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authCanidateTrips.candidateTrips = action.response;
      break;

    case UPDATE_CANDIDATE_TRIP_SUCCESS:
      let tempTrips = draft.authorizationDetails[draft.currentAuthId].authCanidateTrips.candidateTrips;
      const tempTripsIndex = tempTrips.findIndex(s => s.tripId === action.response.tripId);
      tempTrips[tempTripsIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].authCanidateTrips.candidateTrips = tempTrips;
      break;

    case UPDATE_COST_ESTIMATES_SUCCESS:
    case POST_COST_ESTIMATES_SUCCESS:
    case GET_COST_ESTIMATES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authCanidateTrips.costEstimate = action.response;
      break;

    default:
      break;
  }

  return draft;
};

export default handleCanidateTrips;
