import React, { useState } from 'react';
import { Box, Button, Modal, TableRow, Paper } from '@mui/material';
import { withStyles, withTheme, Typography } from '@material-ui/core';

import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import { compose } from 'recompose';
import { connect } from 'react-redux';

const styles = theme => ({
  wideControl: {
    minWidth: 174
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  selfServicePolicySelect: {
    minWidth: 300
  }
});
const ExpensesModal = ({
  isExpenseModalOpen,
  voucherSummaries,
  expenseLines,
  authorizationId,
  vendorId,
  handleCloseModal,
  history,
  classes
}) => {
  let filteredVouchers = voucherSummaries ? voucherSummaries.filter(s => s.vendorId === vendorId && s.payToVendor === true) || [] : [];
  let voucherIds = filteredVouchers.map(v => v.voucherId) || [];
  let expenseLinesFiltered = expenseLines
    ? expenseLines.filter(expense => voucherIds.includes(expense.voucherId) && expense.authorizationId === authorizationId) || []
    : [];

  return (
    <Modal open={isExpenseModalOpen} onClose={handleCloseModal} aria-labelledby="expenses-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          width: '80vw',
          //height: '80vh',
          minHeight: '50vh',
          maxWidth: '80vw',
          maxHeight: '80vh',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h5">Expenses</Typography>
        <Box sx={{ mt: 2, flexGrow: 1 }}>
          <ApprovedExpenses expenseLines={expenseLinesFiltered} history={history} classes={classes} vendorId={vendorId}/>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 3
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            sx={{ maxHeight: '40px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }}
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, {}), withTheme)(ExpensesModal);

const ApprovedExpenses = props => {
  const { expenseLines, history, vendorId } = props;

  const [sorting, setSorting] = useState([{ columnName: 'expenseDate', direction: 'desc' }]);
  const [tableColumnExtensions] = useState([{ columnName: 'description', width: 250, wordWrapEnabled: true }]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [pageSizes] = useState([15, 20, 25]);
  const [filters, setFilters] = useState([]);

  const changeSorting = sorting => setSorting(sorting);

  const headerRowComponent = props => (
    <TableRow sx={{ backgroundColor: '#41B7C0', '& .MuiButtonBase-root': { color: 'white !important' } }}>
      {props.children.map(c => c)}
    </TableRow>
  );

  const cellComponent = props => {
    const { column, row } = props;
    const value = props.value;

    let displayValue = value;
    if (column.name === 'status') {
      displayValue = row.onHoldDate ? 'On Hold' : 'Approved';
    } else if (column.name === 'amount') {
      displayValue = `$${isNaN(parseFloat(value)) ? 0 : parseFloat(value).toFixed(2)}`;
    }

    const clickHandler = () => {
      history.push(`/vouchers/${row.voucherId}`, { previousLocation: 'vendorHistory', vendorId: vendorId });
    };

    return (
      <Table.Cell onClick={clickHandler} className="handles-click" {...props}>
        {displayValue}
      </Table.Cell>
    );
  };

  const noDataCellComponent = props => (
    <Table.Cell {...props}>
      <Typography>No Records were returned.</Typography>
    </Table.Cell>
  );

  const [columns] = useState([
    {
      name: 'status',
      title: 'Status',
      getCellValue: record => (record.onHoldDate ? 'On Hold' : 'Approved')
    },
    { name: 'description', title: 'Description' },
    {
      name: 'amount',
      title: 'Amount',
      getCellValue: record => record.amount
    },
    { name: 'expenseCode', title: 'Expense Code' }
  ]);

  return (
    <Paper>
      <Grid rows={expenseLines || []} columns={columns}>
        <SortingState sorting={sorting} onSortingChange={changeSorting} />
        <IntegratedSorting />
        <PagingState currentPage={currentPage} onCurrentPageChange={setCurrentPage} pageSize={pageSize} onPageSizeChange={setPageSize} />
        <FilteringState filters={filters} onFiltersChange={setFilters} />
        <IntegratedFiltering />
        <IntegratedPaging />
        <Table columnExtensions={tableColumnExtensions} cellComponent={cellComponent} noDataCellComponent={noDataCellComponent} />
        <TableHeaderRow rowComponent={headerRowComponent} showSortingControls />
        <TableFilterRow />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>
    </Paper>
  );
};
