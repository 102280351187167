/* eslint-disable array-callback-return */
import {
  Autocomplete,
  TextField,
} from '@mui/material';
import { GET_ALL_AUTH_SERVICES_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';
import { GET_SEARCH_RESULTS_SUCCESS } from './types';
import { connect } from 'react-redux';
import { getSearchResults } from './common.actions';
import { showToast } from 'modules/layout/layout.actions';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


/**
 * Searchable Dropdown uses the 'Autocomplete' react component to create a searchable dropdown. Once the user starts typing, it will return the top 20 closest results out of all results.
 * 
 * Input Props:
 * label (required): The label of the autocomplete field
 * options (required): An array of options the searchable dropdown should have
 *      -THIS SHOULD ALWAYS BE AN EMPTY ARRAY UNLESS OTHERWISE SPECIFIED BY STORY REQUIREMENTS. THE API CALL MADE WILL RETRIEVE OPTIONS WHEN THE USER STARTS TYPING.
 * renderOptionsOnSearch (required): a function that compiles the returned object from the search api endpoint into an array with a key and a label. This function also handles sorting the options if any filtering was lost.
 *      -Example of a renderOptionsOnSearch function:
 * 
 *        const renderPolicyOptions = (values) => {
            let tempArray = [];
            values.map((val) => {
              let tempObject = {
                key: val.clientPolicyCode,
                label: val.clientPolicyDescription,
              };
              tempArray.push(tempObject);
            });
            // if the returned object wasn't filtered properly, filter the new array
            tempArray.sort((a, b) => a.label.localeCompare(b.label))
            return tempArray;
          };
 * 
 * 
 * value: The displayed value after being selected
 * onChange (function): The onChange function for the field
 * onFocus (function, optional)
 * onBlur (function, optional)
 * className (string, optional)
 * disabled (bool, optional)
 * 
 */

const SearchableDropdown = (props) => {
    
  const { value, disabled, label, onFocus, onBlur, onChange, renderOptionsOnSearch } = props;

  const [options, setOptions] = useState(props.options);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const withinTimeout = async (input) => {
      setLoading(true);
      const searchResults = await props.getSearchResults(input);
      if (searchResults.type === GET_SEARCH_RESULTS_SUCCESS)
      {
        setLoading(false);
        setOptions(renderOptionsOnSearch(searchResults.response));
      }
      else if (searchResults.type === GET_ALL_AUTH_SERVICES_FAILURE)
      {
        showToast('Failed to get search results.');
      }
    };

    if (searchTerm.length === 0)
    {
      setOptions([]);
      setLoading(false);
    }
    else if (searchTerm.length < 3)
    {
      setLoading(true);
      setOptions([]);
    }
    else
    {
      const searchingDelay = setTimeout(() => {
        withinTimeout(searchTerm);
      }, 500);
      return () => clearTimeout(searchingDelay);
    }
  }, [searchTerm, props, renderOptionsOnSearch]);

  return (
    <Autocomplete
      id="searchable-dropdown"
      onInputChange={(event, input) => {
        setSearchTerm(input);
      }}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      options={options}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      disabled={disabled}
      clearOnEscape={true}
      noOptionsText={'Type to search...'}
      fullWidth
    />
  );


};

SearchableDropdown.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired,
  renderOptionsOnSearch: PropTypes.func.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
export default connect(
  null,
  { getSearchResults,
    showToast,
  },
)(SearchableDropdown);

  
