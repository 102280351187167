import { FormTypes } from '../globalDestServiceConstants';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  UPDATE_GLOBAL_DESTINATION_SERVICES_FAILURE,
  UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  UPDATE_GLOBAL_LANGUAGE_TRAINING_FAILURE,
} from 'modules/authorizations/store/types/globalTypes';
import {
  updateGlobalDestinationServices,
  updateGlobalInternationalSchooling,
  updateGlobalLanguageTraining,
} from 'modules/authorizations/store/actions/global.actions';
import { useDispatch } from 'react-redux';
  

export const useUpdateDataHandler = (showToast) => {
  const dispatch = useDispatch();

  const handleUpdateDestinationServiceData = async (form) => {
    const response = await dispatch(updateGlobalDestinationServices(form));
    if (response.type === UPDATE_GLOBAL_DESTINATION_SERVICES_FAILURE) {
      showToast('Failed updating destination service, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const handleUpdateLanguageTrainingData = async (form) => {
    const response = await dispatch(updateGlobalLanguageTraining(form));
    if (response.type === UPDATE_GLOBAL_LANGUAGE_TRAINING_FAILURE) {
      showToast('Failed updating language training, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const handleUpdateInternationalSchoolingData = async (form) => {
    const response = await dispatch(updateGlobalInternationalSchooling(form));
    if (response.type === UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE) {
      showToast('Failed updating international schooling, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const updateData = async (formType, form) => {
    try {
      switch (formType) {
        case FormTypes.DestinationService:
          return await handleUpdateDestinationServiceData(form);
        case FormTypes.LanguageTraining:
          return await handleUpdateLanguageTrainingData(form);
        case FormTypes.InternationalSchooling:
          return await handleUpdateInternationalSchoolingData(form);
        default:
          console.error('Not a valid form type.');
          return false;
      }
    } catch (error) {
      showToast('Failed updating data, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return { updateData };
};
