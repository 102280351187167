import { Checkbox } from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateListing } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const BrokerInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { listingInfo, isReadOnly } = props;

  const tabs = [
    { header: 'Listing Info', order: 1, iconPath: ['fas', 'house'] },
  ];

  const statusOptions = [
    { title: 'Active', id: 1 },
    { title: 'Inactive', id: 2 },
  ];

  const selectedByOptions = [
    { title: 'Relo', id: 1 },
    { title: 'EE', id: 2 },
    { title: 'Client', id: 3 },
  ];

  const listingTypes = [
    { title: 'ORIGLIST', id: 1 },
    { title: 'EXTENSION', id: 2 },
    { title: 'PRICECHG', id: 3 },
    { title: 'RELIST', id: 4 },
  ];

  const dateDiff = (endDate, startDate) => {
    const diffInMs = endDate - startDate;
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
  };

  const summaries = [
    { header: 'Status', accessorKey: 'status', type: 'dropdown-v2', required: false, options: statusOptions, value: listingInfo ? listingInfo.status : '', tab: 1 },
    { header: 'Selected By', accessorKey: 'selectedBy', type: 'dropdown-v2', required: false, options: selectedByOptions, value: listingInfo ? listingInfo.selectedBy : '', tab: 1 },
    { header: 'Type', accessorKey: 'type', type: 'dropdown-v2', required: false, options: listingTypes, value: listingInfo ? listingInfo.type : '', tab: 1 },
    // { header: 'List Date', accessorKey: 'listDate', type: 'date', required: false, value: listingInfo ? listingInfo.listDate : '', tab: 1 },
    { header: 'List Begins', accessorKey: 'listBegins', type: 'date', required: false, value: listingInfo ? listingInfo.listBegins : '', tab: 1 },
    { header: 'List Ends', accessorKey: 'listEnds', type: 'date', required: false, value: listingInfo ? listingInfo.listEnds : '', tab: 1 },
    // { header: 'List Agreement', accessorKey: 'listAgreementDate', type: 'date', required: false, value: listingInfo ? listingInfo.listAgreementDate : '', tab: 1 },
    { header: 'List Price', accessorKey: 'listPrice', type: 'money', required: false, value: listingInfo ? listingInfo.listPrice : '', tab: 1 },
    { header: 'Days on Market', accessorKey: 'daysOnMarketAuto', type: 'text', required: false, value: (listingInfo && listingInfo.listBegins) ? dateDiff(new Date(), new Date(listingInfo.listBegins)) : '', hideInModal: true, tab: 1 },
    { header: 'Exclusion Clause', accessorKey: 'exclusionClause', type: 'checkbox', required: false, value: listingInfo ? listingInfo.exclusionClause : '', tab: 1, Cell: ({ cell }) => <Checkbox name="exclusionClause" label="Active" checked={cell.row.original.exclusionClause} disabled={true} /> },
    { dividerLine: true, tab: 1 },
    { header: 'Incentives', accessorKey: 'incentives', type: 'notes', required: false, value: listingInfo ? listingInfo.incentives : '', showBelow: true, tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...listingInfo };

    newValues.status = values.status;
    newValues.selectedBy = values.selectedBy;
    newValues.type = values.type;
    newValues.listDate = values.listDate;
    newValues.listBegins = values.listBegins;
    newValues.listEnds = values.listEnds;
    newValues.listAgreementDate = values.listAgreementDate;
    newValues.listPrice = values.listPrice;
    newValues.exclusionClause = values.exclusionClause;
    newValues.incentives = values.incentives;

    const resp = await props.updateListing(newValues);
    if (resp.type === UPDATE_LISTINGS_FAILURE) {
      props.showToast('Failed to update broker info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateListing,
    showToast,
  }),
)(BrokerInfoCard);