import { Grid, Paper, Typography, Tooltip, Box  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Field } from 'formik';
import { compose } from 'recompose';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Checkbox, TextField, ToggleSelect } from 'modules/intake/form';
import ExpansionPanel from 'modules/common/expansionPanel.component';
import ExpansionPanelDetails from 'modules/common/expansionPanelDetails.component';
import ExpansionPanelSummary from 'modules/common/expansionPanelSummary.component';
import Log from 'utilities/log';

import { DestinationInformation, FinalMoveBenefits, HouseHuntingBenefits } from 'modules/intake/models';
import { YES_NO_OPTIONS } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import styles from 'modules/intake/styles';
import WarningIcon from '@material-ui/icons/Warning'

class NewDestinationForm extends Component {
  state = {
    showConfirmation: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.newDestination, nextProps.newDestination) ||
      !isEqual(this.state, nextState) ||
      !isEqual(this.props.eeIntakeForm, nextProps.eeIntakeForm) ||
      !isEqual(this.props.moveTypeId, nextProps.moveTypeId);
  }

  // force refresh or useeffect in order to look at the state of the movetypeid

  renderHeader() {
    const { moveTypeId, classes, newDestination, handleChange, handleBlur, destinationInformationComment } = this.props;
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h3">New Destination Information</Typography>
        </Grid>
        {newDestination.isDestinationServiceInitiated && destinationInformationComment && (
          <Grid item>
            <Tooltip title={<Typography variant="body1">Transferee has left a comment regarding Destination Information</Typography>}>
              <Box
                sx={{
                  height: '30px',
                  width: '30px',
                  backgroundColor: '#f0b11d',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 1
                }}
              >
                <WarningIcon sx={{ color: 'white' }} />
              </Box>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <Field
            component={Checkbox}
            className={classes.headerToggle}
            name="newDestination.isDestinationServiceInitiated"
            value={newDestination.isDestinationServiceInitiated}
            onChange={event => {
              // this value is the current state, not what it will change to if we allow it
              if (event.target.value === 'checked') {
                this.setState({ showConfirmation: true });
              } else {
                if (!newDestination.isDestinationServiceInitiated && (moveTypeId === 0 || moveTypeId === 7)) {
                  this.setState({ showChangeMoveType: true });
                } else {
                  handleChange(event);
                }
              }
            }}
            onBlur={handleBlur}
            label="Initiate Service"
          />
        </Grid>
      </Grid>
    );
  }

  renderAutomaticInitiated() {
    const { classes, newDestination, handleBlur, destinationInformationComment } = this.props;

    if (!newDestination.isDestinationServiceInitiated)
    {
      newDestination.isDestinationServiceInitiated = true;
      this.props.setFieldValue(newDestination, new DestinationInformation(), true);
    }

    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h3">New Destination Information</Typography>
        </Grid>
        {newDestination.isDestinationServiceInitiated && destinationInformationComment && (
          <Grid item>
            <Tooltip title={<Typography variant="body1">"Transferee has left a comment regarding Destination Information"</Typography>}>
              <Box
                sx={{
                  height: '30px',
                  width: '30px',
                  backgroundColor: '#f0b11d',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 1
                }}
              >
                <WarningIcon sx={{ color: 'white' }} />
              </Box>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <Field
            component={Checkbox}
            className={classes.headerToggle}
            name="newDestination.isDestinationServiceInitiated"
            value={newDestination.isDestinationServiceInitiated}
            disabled={true}
            onBlur={handleBlur}
            label="Initiate Service"
          />
        </Grid>
      </Grid>
    );
  }

  handleDisableService = () => {
    this.props.setFieldValue('newDestination', new DestinationInformation(), false);
    this.props.setFieldValue('benefits.houseHunting', new HouseHuntingBenefits(), false);
    this.props.setFieldValue('benefits.finalMove', new FinalMoveBenefits(), false);
    this.setState({ showConfirmation: false });
  };

  render() {
    Log.trace('RENDER', 'NewDestinationForm');
    const {
      classes,
      eeIntakeForm,
      newDestination,
      handleChange,
      handleBlur,
      isHhgInitiated,
      isTempLivingInitiated,
      metadata: { familiarWithAreaTypes, housePropertyTypes, preferenceTypes },
    } = this.props;
    const { showConfirmation, showChangeMoveType } = this.state;
    return (
      <div className="form-group">
        <ExpansionPanel expanded={newDestination.isDestinationServiceInitiated}>
          { (isHhgInitiated || isTempLivingInitiated) &&
            <>
              <ExpansionPanelSummary>{this.renderAutomaticInitiated()}</ExpansionPanelSummary>
            </>
          }
          { !(isHhgInitiated || isTempLivingInitiated) &&
            <>
              <ExpansionPanelSummary>{this.renderHeader()}</ExpansionPanelSummary>
            </>
          }
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <Paper classes={{ root: 'card-root' }}>
                  <ExpansionPanel expanded={!!newDestination.isPurchasing}>
                    <ExpansionPanelSummary>
                      <div className="row align-center">
                        <Typography variant="h4">Purchasing</Typography>
                        <div>
                          <Field
                            component={ToggleSelect}
                            name="newDestination.isPurchasing"
                            value={newDestination.isPurchasing}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={YES_NO_OPTIONS}
                            label="Is Purchasing"
                            className={classes.headerToggle}
                          />
                          {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.isPurchasing != null) && (
                            <Typography style={{ color:'green' }} className={classes.headerToggle}> ^ Sourced from EE</Typography>
                          )}
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <div className="row mb-1">
                            <div>
                              <Field
                                component={ToggleSelect}
                                name="newDestination.purchasingDestinationHomeTypeId"
                                value={newDestination.purchasingDestinationHomeTypeId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={values(housePropertyTypes)}
                                variant="spread"
                                label="Property Type"
                                labelPlacement="top"
                              />
                              {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.purchasingDestinationHomeTypeId != null) && (
                                <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
                              )}
                            </div>
                          </div>
                          <div className="row mb-1">
                            <div>
                              <Field
                                component={ToggleSelect}
                                name="newDestination.purchasingFamiliarWithAreaId"
                                value={newDestination.purchasingFamiliarWithAreaId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={values(familiarWithAreaTypes)}
                                label="Familiar with Area?"
                              />
                              {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.purchasingFamiliarWithAreaId != null) && (
                                <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
                              )}
                            </div>
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.purchasingMinPrice"
                              label="Min Price"
                              value={newDestination.purchasingMinPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                            <Field
                              component={TextField}
                              name="newDestination.purchasingMaxPrice"
                              label="Max Price"
                              value={newDestination.purchasingMaxPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.purchasingBedroomsPreferred"
                              label="Bedroom Preference"
                              value={newDestination.purchasingBedroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                            <Field
                              component={TextField}
                              name="newDestination.purchasingBathroomsPreferred"
                              label="Bathroom Preference"
                              value={newDestination.purchasingBathroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                            <Field
                              component={TextField}
                              name="newDestination.purchasingSquareFootage"
                              label="Square Feet"
                              value={newDestination.purchasingSquareFootage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingFireplacePreferenceId"
                              value={newDestination.purchasingFireplacePreferenceId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(preferenceTypes)}
                              label="Fireplace Preference?"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingGaragePreferenceId"
                              value={newDestination.purchasingGaragePreferenceId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(preferenceTypes)}
                              label="Garage Preference?"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingBasementPreferenceId"
                              value={newDestination.purchasingBasementPreferenceId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(preferenceTypes)}
                              label="Basement Preference?"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Field
                            component={ToggleSelect}
                            name="newDestination.purchasingHasSchoolRequest"
                            value={newDestination.purchasingHasSchoolRequest}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={YES_NO_OPTIONS}
                            label="School Request?"
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Field
                            component={TextField}
                            name="newDestination.purchasingImportantFeaturesSpecialRequests"
                            label="Important Features/Special Requests/Preferences"
                            value={newDestination.purchasingImportantFeaturesSpecialRequests}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper classes={{ root: 'card-root' }}>
                  <ExpansionPanel expanded={!!newDestination.isRenting}>
                    <ExpansionPanelSummary>
                      <div className="row align-center">
                        <Typography variant="h4">Renting</Typography>
                        <Field
                          component={ToggleSelect}
                          name="newDestination.isRenting"
                          value={newDestination.isRenting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={YES_NO_OPTIONS}
                          className={classes.headerToggle}
                          label="Is Renting"
                        />
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.rentingMinPrice"
                              label="Min Price"
                              value={newDestination.rentingMinPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                            <Field
                              component={TextField}
                              name="newDestination.rentingMaxPrice"
                              label="Max Price"
                              value={newDestination.rentingMaxPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.rentingBedroomsPreferred"
                              label="Bedroom Preference"
                              value={newDestination.rentingBedroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                            <Field
                              component={TextField}
                              name="newDestination.rentingBathroomsPreferred"
                              label="Bathroom Preference"
                              value={newDestination.rentingBathroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Field
                            component={ToggleSelect}
                            name="newDestination.rentingHasSchoolRequest"
                            value={newDestination.rentingHasSchoolRequest}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={YES_NO_OPTIONS}
                            label="School Request?"
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Paper>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Service?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableService}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this service, you will lose all data entered in this section and any timeline entries related to this service. <br /><br />Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={showChangeMoveType}
          title="Change Move Type?"
          confirmLabel="Change Move Type"
          cancelLabel="Cancel"
          onConfirm={() => {window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); this.setState({ showChangeMoveType: false });}}
          onCancel={() => this.setState({ showChangeMoveType: false })}
        >
          <Typography>
            In order to initiate Destination Services, please change the move type from either Candidate or Intern.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

NewDestinationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  newDestination: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(NewDestinationForm);
