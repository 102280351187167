import { POST_COMMUNICATION_INFO_FAILURE, UPDATE_COMMUNICATION_INFO_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postCommunicationInfo, updateCommunicationInfo } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const CommunicationCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { clientInfo, communication } = props;

  const communicationTabs = [
    { header: 'Communication', order: 1, iconPath: ['fas', 'phone'] },
  ];

  const communicationSummaries = [
    { header: 'Preferred Contact', accessorKey: 'preferredContact', type: 'dropdown', required: true, options: ['Mobile', 'Email'], value: communication ? communication.preferredContact : '', tab: 1 },
    { header: 'Primary Number', accessorKey: 'primaryNumber', type: 'text', required: false, value: communication ? communication.primaryNumber : '', tab: 1 },
    { header: 'Primary Email', accessorKey: 'primaryEmail', type: 'text', required: false, value: communication ? communication.primaryEmail : '', tab: 1 },
    { header: 'Text Messaging', accessorKey: 'textMessaging', type: 'checkbox', required: false, value: communication ? communication.textMessaging : '', tab: 1 },
    { header: 'Fax', accessorKey: 'fax', type: 'text', required: false, value: communication ? communication.fax : '', tab: 1 },
    { header: 'Significant Other Mobile', accessorKey: 'soMobile', type: 'text', required: false,  value: communication ? communication.soMobile : '', tab: 1 },
    { header: 'Work Email', accessorKey: 'workEmail', type: 'text', required: false, value: communication ? communication.workEmail : '', tab: 1 },
    { header: 'Significant Other Personal Email', accessorKey: 'soPersonalEmail', type: 'text', required: false, value: communication ? communication.soPersonalEmail : '', tab: 1 },
    { header: 'Survey Type', accessorKey: 'surveyType', type: 'dropdown', options: ['Renter'], required: false, value: communication ? communication.surveyType : '', tab: 1 },
  ];

  const topLink = clientInfo ? { title: clientInfo.description, link: `/clients/${clientInfo.moveTrackClientId}` } : null;

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;
        
    if (communication && communication.length !== 0) {
      const resp = await props.updateCommunicationInfo(values);
      if (resp.type === UPDATE_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update the employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postCommunicationInfo(values);
      if (resp.type === POST_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={communicationTabs}
      summaries={communicationSummaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      topLink={topLink}
    />
  );

};

export default compose(
  connect(null, {
    postCommunicationInfo,
    updateCommunicationInfo,
    showToast,
  }),
)(CommunicationCard);