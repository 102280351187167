/* eslint-disable no-useless-computed-key */
import { withStyles } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

//import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import AuthorizationsGrid from './authorizationsGrid.component';
import Log from 'utilities/log';

import { GET_AUTHORIZATIONS_FAILURE } from './store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getAuthorizationSummaries } from './store/actions/authorizations.actions';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
});

class AuthorizationsView extends PureComponent {
  state = {
  };

  async componentDidMount() {
    this.props.setPageTitle('Authorizations');

    if (!this.props.authorizationSummaries) {
      let { type } = await this.props.getAuthorizationSummaries();
      if (type === GET_AUTHORIZATIONS_FAILURE) {
        this.props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSummarySelect = (id) => {
    this.props.history.push(`/authorizations/${id}`);
  };


  render() {
    Log.trace('RENDER', 'ClientsView');
    const { history, isLoading } = this.props;

    let authorizationSummaries = this.props.authorizationSummaries ?? [];

    return (
      <Fragment>
        <div className="px-2">
          <AuthorizationsGrid
            authorizations={authorizationSummaries}
            onSelect={this.handleSummarySelect}
            isLoading={isLoading}
            history={history}
          />
        </div>
      </Fragment>
    );
  }
}

AuthorizationsView.propTypes = {
  setPageTitle: PropTypes.func,
  classes: PropTypes.object,
  unmappedClients: PropTypes.array,
  isLoadingAuths: PropTypes.bool,
  showToast: PropTypes.func,
  profile: PropTypes.object,
  managerOptions: PropTypes.array,
  internalUsers: PropTypes.array,
  history: ReactRouterPropTypes.history,
};

const mapStateToProps = (state) => {
  const {
    authorizations: { authorizationSummaries, isLoading },
  } = state;
  return { authorizationSummaries, isLoading };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getTestApiV2,
    getAuthorizationSummaries,
  }),
)(AuthorizationsView);