import { Box, Button, DialogActions, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { POST_HOMESALE_INFO_FAILURE, UPDATE_HOMESALE_INFO_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postHomesaleInfo, updateHomesaleInfo } from 'modules/authorizations/store/actions/homesale.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const PropertyInfoCard = (props) => {

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const { homesaleInfo, id, listingInfo, isReadOnly } = props;

  const tabs = [
    { header: 'Listing Info', order: 1, iconPath: ['fas', 'money-bill-transfer'] },
  ];

  const summaries = [
    { header: 'Broker', accessorKey: 'broker', type: 'text', required: false, value: listingInfo ? listingInfo.brokerName : null,  tab: 1 },
    { header: 'BMA Average', accessorKey: 'bmaAverage', type: 'money', required: false, value: null, tab: 1 },
    { header: 'Listing Price', accessorKey: 'listingPrice', type: 'money', required: false, value: listingInfo ? listingInfo.listPrice : '', tab: 1 },
    { header: 'Original List', accessorKey: 'originalListDate', type: 'date', required: false, value: listingInfo ? listingInfo.listBegins : '', tab: 1 },
    { header: 'Referral', accessorKey: 'referralDate', type: 'money', required: false,  value: listingInfo ? listingInfo.actualReferralFeeReceived : '', tab: 1 },
    { header: 'Equity Return', accessorKey: 'equityReturn', type: 'date', required: false, value: listingInfo ? listingInfo.hoDisclosureSent : '', tab: 1 },
    { header: 'Listing Date', accessorKey: 'listingDate', type: 'date', required: false, value: listingInfo ? listingInfo.listDate : false, tab: 1 },
    { header: 'Original List Price', accessorKey: 'originalListPrice', type: 'money', required: false, value: listingInfo ? listingInfo.listPrice : '', tab: 1 },
  ];

  const orderTitle = async () => {
    setIsLoadingButton(true);
    let newVals = { ...homesaleInfo };
    newVals.orderTitleSent = true;

    if (!homesaleInfo || (homesaleInfo && homesaleInfo.length === 0)) {
      newVals.authorizationId = id;

      let resp = await props.postHomesaleInfo(newVals);
      if (resp.type === POST_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to order title, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Title ordered successfully!', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      }

      setIsLoadingButton(false);
      return resp;
    } else {
      let resp = await props.updateHomesaleInfo(newVals);
      if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to order title, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Title ordered successfully!', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      }

      setIsLoadingButton(false);
      return resp;
    }
  };

  const markAsInInventory = async () => {
    setIsLoadingButton(true);
    let newVals = { ...homesaleInfo };
    newVals.markAsInventorySent = true;

    if (!homesaleInfo  || (homesaleInfo && homesaleInfo.length === 0)) {
      newVals.authorizationId = id;

      let resp = await props.postHomesaleInfo(newVals);
      if (resp.type === POST_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to mark as in inventory, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Listing marked as in inventory.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      }

      setIsLoadingButton(false);
      return resp;
    } else {
      let resp = await props.updateHomesaleInfo(newVals);
      if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to mark as in inventory, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Listing marked as in inventory.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      }

      setIsLoadingButton(false);
      return resp;
    }
  };
  

  const ListingInfoButtons = ({ ...rest }) => {
    
    return (
      <>
        {isLoadingButton && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          </Box>
        )}
        {!isLoadingButton && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                {!isReadOnly && (!homesaleInfo || (homesaleInfo && !homesaleInfo.orderTitleSent)) && (
                  <Button
                    onClick={async () => await orderTitle()}
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#868686',
                      '&:hover': { backgroundColor: '#474747' },
                    }}
                  >
                    Order Title
                  </Button>
                )}
                {!isReadOnly && (!homesaleInfo || (homesaleInfo && !homesaleInfo.markAsInventorySent)) && (
                  <Button
                    onClick={async () => await markAsInInventory()}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    Mark as in Inventory
                  </Button>
                )}
              </Box>
            </Box>
            {homesaleInfo && (homesaleInfo.orderTitleSent || homesaleInfo.markAsInventorySent) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Box>
                  {homesaleInfo.orderTitleSent && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Title has been sent successfully {!isReadOnly && <><Link onClick={async () => await orderTitle()} sx={{ cursor: 'pointer' }}>, click here</Link> to resend. </>}</Typography>}
                  {homesaleInfo.markAsInventorySent && <Typography color="secondary" sx={{ paddingTop: '10px' }}> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Listing marked as in inventory.</Typography>}
                </Box>
              </Box>
            )}
          </Box>
          
        )}
      </>
    );
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      // updateFields={updateFields}
      // isLoading={isLoading}
      ButtonComponent={ListingInfoButtons}
      hideEdit={true}
    />
  );

};

export default compose(
  connect(null, {
    updateHomesaleInfo,
    postHomesaleInfo,
    showToast,
  }),
)(PropertyInfoCard);