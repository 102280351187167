import 'react-quill/dist/quill.snow.css';
import React from 'react';
import ReactQuill from 'react-quill'; // include styles

class QuillTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: '' };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(html) {
    this.setState({ editorHtml: html });
  }

  render() {
    return (
      <ReactQuill 
        value={this.state.editorHtml}
        onChange={this.handleChange}
      />
    );
  }
}

export default QuillTextEditor;
