import { CALL_API } from 'middleware/api';
import {
  CREATE_BENEFIT_FAILURE,
  CREATE_BENEFIT_REQUEST,
  CREATE_BENEFIT_SUCCESS,
  CREATE_DRAFT_FAILURE,
  CREATE_DRAFT_REQUEST,
  CREATE_DRAFT_SUCCESS,
  DELETE_BENEFIT_DRAFT_FAILURE,
  DELETE_BENEFIT_DRAFT_REQUEST,
  DELETE_BENEFIT_DRAFT_SUCCESS,
  DELETE_BENEFIT_FAILURE,
  DELETE_BENEFIT_REQUEST,
  DELETE_BENEFIT_SUCCESS,
  GET_BENEFITS_FAILURE,
  GET_BENEFITS_REQUEST,
  GET_BENEFITS_SUCCESS,
  GET_BENEFIT_FAILURE,
  GET_BENEFIT_HISTORY_FAILURE,
  GET_BENEFIT_HISTORY_REQUEST,
  GET_BENEFIT_HISTORY_SUCCESS,
  GET_BENEFIT_REQUEST,
  GET_BENEFIT_SUCCESS,
  GET_COST_LOOKUP_APIS_FAILURE,
  GET_COST_LOOKUP_APIS_REQUEST,
  GET_COST_LOOKUP_APIS_SUCCESS,
  GET_COST_LOOKUP_FAILURE,
  GET_COST_LOOKUP_REQUEST,
  GET_COST_LOOKUP_SUCCESS,
  GET_LOOKUP_TABLES_FAILURE,
  GET_LOOKUP_TABLES_REQUEST,
  GET_LOOKUP_TABLES_SUCCESS,
  PUBLISH_BENEFIT_FAILURE,
  PUBLISH_BENEFIT_REQUEST,
  PUBLISH_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_FAILURE,
  UPDATE_BENEFIT_REQUEST,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_MASTER_BENEFIT_FAILURE,
  UPDATE_MASTER_BENEFIT_REQUEST,
  UPDATE_MASTER_BENEFIT_SUCCESS,
} from './benefit.types';

export const getBenefits = () => {
  return {
    [CALL_API]: {
      types: [GET_BENEFITS_REQUEST, GET_BENEFITS_SUCCESS, GET_BENEFITS_FAILURE],
      authenticated: true,
      endpoint: 'benefits',
      method: 'GET',
    },
  };
};

export const getBenefit = (id) => {
  return {
    [CALL_API]: {
      types: [GET_BENEFIT_REQUEST, GET_BENEFIT_SUCCESS, GET_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${id}`,
      method: 'GET',
    },
  };
};

export const createBenefit = (version, createOptions) => {
  return {
    [CALL_API]: {
      types: [CREATE_BENEFIT_REQUEST, CREATE_BENEFIT_SUCCESS, CREATE_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits?publish=${createOptions.publish}&enable=${createOptions.enable}`,
      method: 'POST',
      payload: toWire(version),
    },
  };
};

export const createDraft = (benefitId) => {
  return {
    [CALL_API]: {
      types: [CREATE_DRAFT_REQUEST, CREATE_DRAFT_SUCCESS, CREATE_DRAFT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefitId}/draft`,
      method: 'POST',
    },
  };
};

export const updateMasterBenefit = (benefit) => {
  return {
    [CALL_API]: {
      types: [UPDATE_MASTER_BENEFIT_REQUEST, UPDATE_MASTER_BENEFIT_SUCCESS, UPDATE_MASTER_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefit.id}`,
      method: 'PUT',
      payload: benefit,
    },
  };
};

export const updateBenefit = (version) => {
  return {
    [CALL_API]: {
      types: [UPDATE_BENEFIT_REQUEST, UPDATE_BENEFIT_SUCCESS, UPDATE_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${version.masterBenefitId}/v/${version.versionId}`,
      method: 'PUT',
      payload: toWire(version),
    },
  };
};

export const publishBenefit = (benefit, enable) => {
  return {
    [CALL_API]: {
      types: [PUBLISH_BENEFIT_REQUEST, PUBLISH_BENEFIT_SUCCESS, PUBLISH_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefit.masterBenefitId}/v/${benefit.versionId}/publish?enable=${enable}`,
      method: 'PUT',
      payload: toWire(benefit),
    },
  };
};

export const getLookupTables = () => {
  return {
    [CALL_API]: {
      types: [GET_LOOKUP_TABLES_REQUEST, GET_LOOKUP_TABLES_SUCCESS, GET_LOOKUP_TABLES_FAILURE],
      authenticated: true,
      endpoint: 'tableCostLookup',
      method: 'GET',
    },
  };
};

export const getCostLookupApis = () => {
  return {
    [CALL_API]: {
      types: [GET_COST_LOOKUP_APIS_REQUEST, GET_COST_LOOKUP_APIS_SUCCESS, GET_COST_LOOKUP_APIS_FAILURE],
      authenticated: true,
      endpoint: 'apiCostLookup',
      method: 'GET',
    },
  };
};

export const getCostLookupByTable = (tableName) => {
  return {
    [CALL_API]: {
      types: [GET_COST_LOOKUP_REQUEST, GET_COST_LOOKUP_SUCCESS, GET_COST_LOOKUP_FAILURE],
      authenticated: true,
      endpoint: `tableCostLookup/${tableName}`,
      method: 'GET',
      context: {
        tableName,
      },
    },
  };
};

export const getBenefitHistory = (benefitId) => {
  return {
    [CALL_API]: {
      types: [GET_BENEFIT_HISTORY_REQUEST, GET_BENEFIT_HISTORY_SUCCESS, GET_BENEFIT_HISTORY_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefitId}/history`,
      method: 'GET',
    },
  };
};

export const deleteBenefitDraft = (version) => {
  return {
    [CALL_API]: {
      types: [DELETE_BENEFIT_DRAFT_REQUEST, DELETE_BENEFIT_DRAFT_SUCCESS, DELETE_BENEFIT_DRAFT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${version.masterBenefitId}/v/${version.versionId}`,
      method: 'DELETE',
    },
  };
};

export const deleteBenefit = (benefitId) => {
  return {
    [CALL_API]: {
      types: [DELETE_BENEFIT_REQUEST, DELETE_BENEFIT_SUCCESS, DELETE_BENEFIT_FAILURE],
      authenticated: true,
      endpoint: `benefits/${benefitId}`,
      method: 'DELETE',
    },
  };
};

// remove any props that middleware does not expect to receive here
const toWire = (version) => {
  return {
    ...version,
    optionDetails: version.optionDetails.map((opt, idx) => {
      opt.tempId = undefined;
      opt.order = idx;
      return opt;
    }),
    questionDetails: version.questionDetails.map((q) => {
      q.tempId = undefined;
      return q;
    }),
    vendorQuestionDetails: version.vendorQuestionDetails.filter((q) => q.text).map((q) => {
      q.tempId = undefined;
      return q;
    }),
  };
};

