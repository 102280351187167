import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const StorageDatesCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const tabs = [
    { header: 'Storage Dates', order: 1, iconPath: ['fas', 'calendar-days'] },
  ];

  const summaries = [
    { header: 'Estimated Start', accessorKey: 'estimatedStorageStart', type: 'date', required: false, value: segmentInfo ? segmentInfo.estimatedStorageStart : '', tab: 1 },
    { header: 'Actual Start', accessorKey: 'actualStorageStart', type: 'date', required: false, value: segmentInfo ? segmentInfo.actualStorageStart : '', tab: 1 },
    { header: 'Estimated End', accessorKey: 'estimatedStorageEnd', type: 'date', required: false, value: segmentInfo ? segmentInfo.estimatedStorageEnd : '', tab: 1 },
    { header: 'Actual End', accessorKey: 'actualStorageEnd', type: 'date', required: false, value: segmentInfo ? segmentInfo.actualStorageEnd : '', tab: 1 },
    { dividerLine: true },
    { header: 'Authorized Days', accessorKey: 'authorizedStorageDays', type: 'number', required: false, value: segmentInfo ? segmentInfo.authorizedStorageDays : '', tab: 1 },
    { header: 'Exception Days', accessorKey: 'exceptionStorageDays', type: 'number', required: false, value: segmentInfo ? segmentInfo.exceptionStorageDays : '', tab: 1 },
    { header: 'Estimated Days', accessorKey: 'estimatedStorageDays', type: 'number', required: false, value: segmentInfo ? segmentInfo.estimatedStorageDays : '', tab: 1 },
    { header: 'Actual Days', accessorKey: 'actualStorageDays', type: 'number', required: false, value: segmentInfo ? segmentInfo.actualStorageDays : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.estimatedStorageStart = values.estimatedStorageStart;
    newValues.actualStorageStart = values.actualStorageStart;
    newValues.estimatedStorageEnd = values.estimatedStorageEnd;
    newValues.actualStorageEnd = values.actualStorageEnd;

    newValues.authorizedStorageDays = values.authorizedStorageDays;
    newValues.exceptionStorageDays = values.exceptionStorageDays;
    newValues.estimatedStorageDays = values.estimatedStorageDays;
    newValues.actualStorageDays = values.actualStorageDays;

        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={4} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(StorageDatesCard);