import { Box } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ClientContractsCreditsAndRebatesTable from './dataTables/clientContractsCreditsAndRebatesTable.component';
import ClientContractsFeesTable from './dataTables/clientContractsFeesTable.component';
import ClientContractsSLATable from './dataTables/clientContractsSLATable.component';
import ClientContractsTable from './dataTables/clientContractsTable.component';
import PropTypes from 'prop-types';
import React from 'react';


const ClientContractsTab = ({ formikProps, clientContractsSLA, clientContractsFees, clientContractsCreditsAndRebates }) => {
  return (
    <Box marginX={2} marginY={4}>
      <ClientContractsTable formikProps={formikProps} />
      { clientContractsSLA && <ClientContractsSLATable clientId={formikProps.values.moveTrackClientId} /> }
      { clientContractsFees && <ClientContractsFeesTable clientId={formikProps.values.moveTrackClientId} /> }
      { clientContractsCreditsAndRebates && <ClientContractsCreditsAndRebatesTable clientId={formikProps.values.moveTrackClientId} /> }
    </Box>
  );
};

ClientContractsTab.propTypes = {
  formikProps: PropTypes.object.isRequired,
  clientContractsSLA: PropTypes.array,
  clientContractsFees: PropTypes.array,
  clientContractsCreditsAndRebates: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { clientContractsSLA, clientContractsFees, clientContractsCreditsAndRebates } = state.clients.clientsInfo[state.clients.currentClientId];
  return {
    clientContractsSLA,
    clientContractsFees,
    clientContractsCreditsAndRebates,
  };
};

  
export default compose(
  connect(mapStateToProps),
)(ClientContractsTab);