import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import React, { useState } from 'react';

import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DELETE_HHG_MOVING_DATE_FAILURE, GET_HHG_MOVING_DATE_FAILURE, POST_HHG_MOVING_DATE_FAILURE, UPDATE_HHG_MOVING_DATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { deleteHhgMovingDate, getHhgMovingDates, postHhgMovingDate, updateHhgMovingDate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import CrudTable from '../../../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const EstimateMovingDates = (props) => {

  const { isLoadingMovingDates, hideEdit, segmentInfo, estimates } = props;

  const [isLoading, setIsLoading] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 75 },
    { accessorKey: 'estimateType', header: 'Estimate Type', type: 'text', required: true, size: 75 },
    {
        header: 'Ordered Date',
        accessorKey: 'orderedDate',
        type: 'date',
        required: false,
        tab: 1,
        disabled: true,
        Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-', 
        size: 65
      },
      {
        header: 'Appointment Date',
        accessorKey: 'appointmentDate',
        type: 'date',
        required: false,
        tab: 1,
        Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-', 
        size: 65
      },
      {
        header: 'Received Date',
        accessorKey: 'receivedDate',
        type: 'date',
        required: false,
        tab: 1,
        Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-', 
        size: 65
      },
    //   {
    //     header: 'Accepted Date',
    //     accessorKey: 'acceptedDate',
    //     type: 'date',
    //     required: false,
    //     tab: 1,
    //     disabled: true,
    //     Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-', 
    //     size: 65
    //   },
  ];

  const updateRow = async (movingDate, values) => {
    setIsLoading(true);
    values.movingDateId = movingDate.original.movingDateId;
    values.segmentId = segmentInfo.segmentId;
    values.authorizationId = segmentInfo.authorizationId;
    values.isEditable = true;

    if (values.estimatedStart && values.estimatedStart.length === 0) values.estimatedStart = null;
    if (values.estimatedEnd && values.estimatedEnd.length === 0) values.estimatedEnd = null;
    if (values.actualStart && values.actualStart.length === 0) values.actualStart = null;
    if (values.actualEnd && values.actualEnd.length === 0) values.actualEnd = null;

    const resp = await props.updateHhgMovingDate(values);
    if (resp.type === UPDATE_HHG_MOVING_DATE_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getHhgMovingDates(segmentInfo.segmentId);
      if (getResp.type === GET_HHG_MOVING_DATE_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const createRow = async (values) => {
    setIsLoading(true);
    values.segmentId = segmentInfo.segmentId;
    values.authorizationId = segmentInfo.authorizationId;
    values.isEditable = true;

    if (values.estimatedStart && values.estimatedStart.length === 0) values.estimatedStart = null;
    if (values.estimatedEnd && values.estimatedEnd.length === 0) values.estimatedEnd = null;
    if (values.actualStart && values.actualStart.length === 0) values.actualStart = null;
    if (values.actualEnd && values.actualEnd.length === 0) values.actualEnd = null;

    const resp = await props.postHhgMovingDate(values);
    if (resp.type === POST_HHG_MOVING_DATE_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getHhgMovingDates(segmentInfo.segmentId);
      if (getResp.type === GET_HHG_MOVING_DATE_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const deleteRow = async (movingDate) => {
    setIsLoading(true);
    const id = movingDate.original.movingDateId;
    const resp = await props.deleteHhgMovingDate(id);
    if (resp.type === DELETE_HHG_MOVING_DATE_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getHhgMovingDates(segmentInfo.segmentId);
      if (getResp.type === GET_HHG_MOVING_DATE_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={'Moving Dates'} key={'movingDates'} placement="right">
            <FontAwesomeIcon icon={['fas', 'calendar-days']} size="2x" />
          </Tooltip>
          <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
            Moving Dates
          </Typography>
        </Box>
        {!isLoading && !isLoadingMovingDates && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={estimates ? estimates.filter((e) => e.estimateType != null) : []} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
            customAddText={'Add Custom Date'}
            hideEdit={hideEdit}
          />
        )}
        {(isLoading || isLoadingMovingDates) && (
          <Spinner />
        )}
      </CardContent>
    </Card>
  );
}; 

export default compose(
  withStyles(styles),
  withTheme,
  connect(null, {
    getHhgMovingDates,
    deleteHhgMovingDate,
    postHhgMovingDate,
    updateHhgMovingDate,
  }),
)(EstimateMovingDates);