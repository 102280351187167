const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const { GET_CANDIDATE_SERVICES_REQUEST, GET_CANDIDATE_SERVICES_SUCCESS, GET_CANDIDATE_SERVICES_FAILURE, POST_CANDIDATE_SERVICES_REQUEST, POST_CANDIDATE_SERVICES_SUCCESS, POST_CANDIDATE_SERVICES_FAILURE, UPDATE_CANDIDATE_SERVICES_REQUEST, UPDATE_CANDIDATE_SERVICES_SUCCESS, UPDATE_CANDIDATE_SERVICES_FAILURE, GET_INTERN_SERVICES_REQUEST, GET_INTERN_SERVICES_SUCCESS, GET_INTERN_SERVICES_FAILURE, POST_INTERN_SERVICES_REQUEST, POST_INTERN_SERVICES_SUCCESS, POST_INTERN_SERVICES_FAILURE, UPDATE_INTERN_SERVICES_REQUEST, UPDATE_INTERN_SERVICES_SUCCESS, UPDATE_INTERN_SERVICES_FAILURE, GET_ALL_CANDIDATE_TRIPS_REQUEST, GET_ALL_CANDIDATE_TRIPS_SUCCESS, GET_ALL_CANDIDATE_TRIPS_FAILURE, GET_CANDIDATE_TRIP_REQUEST, GET_CANDIDATE_TRIP_SUCCESS, GET_CANDIDATE_TRIP_FAILURE, POST_CANDIDATE_TRIP_REQUEST, POST_CANDIDATE_TRIP_SUCCESS, POST_CANDIDATE_TRIP_FAILURE, UPDATE_CANDIDATE_TRIP_REQUEST, UPDATE_CANDIDATE_TRIP_SUCCESS, UPDATE_CANDIDATE_TRIP_FAILURE, DELETE_CANDIDATE_TRIP_REQUEST, DELETE_CANDIDATE_TRIP_SUCCESS, DELETE_CANDIDATE_TRIP_FAILURE, GET_COST_ESTIMATES_REQUEST, GET_COST_ESTIMATES_SUCCESS, GET_COST_ESTIMATES_FAILURE, POST_COST_ESTIMATES_REQUEST, POST_COST_ESTIMATES_SUCCESS, POST_COST_ESTIMATES_FAILURE, UPDATE_COST_ESTIMATES_REQUEST, UPDATE_COST_ESTIMATES_SUCCESS, UPDATE_COST_ESTIMATES_FAILURE } = require('../types/canidateTripTypes');


// Candidate Services

export const getCandidateServices = (authorizationId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_CANDIDATE_SERVICES_REQUEST, GET_CANDIDATE_SERVICES_SUCCESS, GET_CANDIDATE_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authorizationId}/candidateInternService/candidate`,
      method: 'GET',
    },
  };
};


export const postCandidateServices = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_CANDIDATE_SERVICES_REQUEST, POST_CANDIDATE_SERVICES_SUCCESS, POST_CANDIDATE_SERVICES_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/candidateInternService/candidate',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateCandidateServices = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_CANDIDATE_SERVICES_REQUEST, UPDATE_CANDIDATE_SERVICES_SUCCESS, UPDATE_CANDIDATE_SERVICES_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/candidateInternService/candidate',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getInternServices = (authorizationId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_INTERN_SERVICES_REQUEST, GET_INTERN_SERVICES_SUCCESS, GET_INTERN_SERVICES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authorizationId}/candidateInternService/intern`,
      method: 'GET',
    },
  };
};


export const postInternServices = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_INTERN_SERVICES_REQUEST, POST_INTERN_SERVICES_SUCCESS, POST_INTERN_SERVICES_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/candidateInternService/intern',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateInternServices = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_INTERN_SERVICES_REQUEST, UPDATE_INTERN_SERVICES_SUCCESS, UPDATE_INTERN_SERVICES_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/candidateInternService/intern',
      method: 'PUT',
      payload: values,
    },
  };
};
// Candidate Trips

export const getCandidateTrips = (authorizationId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ALL_CANDIDATE_TRIPS_REQUEST, GET_ALL_CANDIDATE_TRIPS_SUCCESS, GET_ALL_CANDIDATE_TRIPS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/candidateInternService/candidateTrip/${authorizationId}`,
      method: 'GET',
    },
  };
};

export const getCandidateTripById = (tripId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_CANDIDATE_TRIP_REQUEST, GET_CANDIDATE_TRIP_SUCCESS, GET_CANDIDATE_TRIP_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/candidateInternService/candidateTrip/getById/${tripId}`,
      method: 'GET',
    },
  };
};


export const postCandidateTrip = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_CANDIDATE_TRIP_REQUEST, POST_CANDIDATE_TRIP_SUCCESS, POST_CANDIDATE_TRIP_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/candidateInternService/candidateTrip',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateCandidateTrip = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_CANDIDATE_TRIP_REQUEST, UPDATE_CANDIDATE_TRIP_SUCCESS, UPDATE_CANDIDATE_TRIP_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/candidateInternService/candidateTrip',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteCandidateTrip = (tripId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_CANDIDATE_TRIP_REQUEST, DELETE_CANDIDATE_TRIP_SUCCESS, DELETE_CANDIDATE_TRIP_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/candidateInternService/candidateTrip/${tripId}`,
      method: 'DELETE',
    },
  };
};

export const getCostEstimates = (authorizationId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_COST_ESTIMATES_REQUEST, GET_COST_ESTIMATES_SUCCESS, GET_COST_ESTIMATES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authorizationId}/costEstimates`,
      method: 'GET',
    },
  };
};


export const postCostEstimates = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_COST_ESTIMATES_REQUEST, POST_COST_ESTIMATES_SUCCESS, POST_COST_ESTIMATES_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/costEstimates',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateCostEstimates = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_COST_ESTIMATES_REQUEST, UPDATE_COST_ESTIMATES_SUCCESS, UPDATE_COST_ESTIMATES_FAILURE],
      authenticated: true,
      endpoint: 'api/authorizations/costEstimates',
      method: 'PUT',
      payload: values,
    },
  };
};
