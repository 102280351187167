import {
  DELETE_FAMILY_PET_FAILURE,
  GET_FAMILY_PET_FAILURE,
  POST_FAMILY_PET_FAILURE,
  UPDATE_FAMILY_PET_FAILURE,
} from '../../../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

export const usePetHandler = ({ postFamilyPet, updateFamilyPet, getFamilyPets, deleteFamilyPet, showToast }) => {
  const handleCreatePet = async (values) => {
    try {
      const resp = await postFamilyPet(values);
      if (resp.type === POST_FAMILY_PET_FAILURE) {
        showToast('Failed to create pet, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while creating pet.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleUpdatePet = async (values) => {
    try {
      const resp = await updateFamilyPet(values);
      if (resp.type === UPDATE_FAMILY_PET_FAILURE) {
        showToast('Failed to update pet, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while updating pet.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleGetPet = async (authId) => {
    try {
      const resp = await getFamilyPets(authId);
      if (resp.type === GET_FAMILY_PET_FAILURE) {
        showToast('Failed to get pet, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while fetching pets', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleDeletePet = async (authId, petId) => {
    try {
      const resp = await deleteFamilyPet(authId, petId);
      if (resp.type === DELETE_FAMILY_PET_FAILURE) {
        showToast('Failed to delete pet, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while deleting pet.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return {
    handleCreatePet,
    handleUpdatePet,
    handleGetPet,
    handleDeletePet,
  };
};
