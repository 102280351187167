/* eslint-disable react-hooks/exhaustive-deps */

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Button, Card, CardContent, Dialog, DialogActions, Fab, Grid, Stack, TextField, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';

import { formatDateForInput } from 'utilities/common';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));


const EditCardModal = (props) => {
  const { open, onSubmit, isLoading, onClose, estimateInfo } = props;
  const classes = useStyles();

  const [estimatedCarLoadingStart, setEstimatedCarLoadingStart] = useState(estimateInfo ? estimateInfo.estimatedCarLoadingStart : '');
  const [estimatedCarLoadingEnd, setEstimatedCarLoadingEnd] = useState(estimateInfo ? estimateInfo.estimatedCarLoadingEnd : '');
  const [actualCarLoadingStart, setActualCarLoadingStart] = useState(estimateInfo ? estimateInfo.actualCarLoadingStart : '');
  const [actualCarLoadingEnd, setActualCarLoadingEnd] = useState(estimateInfo ? estimateInfo.actualCarLoadingEnd : '');
  const [estimatedCarDeliveryStart, setEstimatedCarDeliveryStart] = useState(estimateInfo ? estimateInfo.estimatedCarDeliveryStart : '');
  const [estimatedCarDeliveryEnd, setEstimatedCarDeliveryEnd] = useState(estimateInfo ? estimateInfo.estimatedCarDeliveryEnd : '');
  const [actualCarDeliveryStart, setActualCarDeliveryStart] = useState(estimateInfo ? estimateInfo.actualCarDeliveryStart : '');
  const [actualCarDeliveryEnd, setActualCarDeliveryEnd] = useState(estimateInfo ? estimateInfo.actualCarDeliveryEnd : '');

  const [isDirty, setIsDirty] = useState(false);

  const onSubmitModal = (e) => {
    e.preventDefault();

    const values = {
      estimatedCarLoadingStart,
      estimatedCarLoadingEnd,
      actualCarLoadingStart,
      actualCarLoadingEnd,
      estimatedCarDeliveryStart,
      estimatedCarDeliveryEnd,
      actualCarDeliveryStart,
      actualCarDeliveryEnd,
    };

    onSubmit(values);
  };

  useEffect(() => {
    if (estimateInfo) {
      if (
            (!(estimatedCarLoadingStart === '' && estimateInfo.estimatedCarLoadingStart == null) && estimatedCarLoadingStart !== estimateInfo.estimatedCarLoadingStart) || (!(estimatedCarLoadingEnd === '' && estimateInfo.estimatedCarLoadingEnd == null) && estimatedCarLoadingEnd !== estimateInfo.estimatedCarLoadingEnd) || (!(actualCarLoadingStart === '' && estimateInfo.actualCarLoadingStart == null) && actualCarLoadingStart !== estimateInfo.actualCarLoadingStart) || (!(actualCarLoadingEnd === '' && estimateInfo.actualCarLoadingEnd == null) && actualCarLoadingEnd !== estimateInfo.actualCarLoadingEnd) ||
            (!(estimatedCarDeliveryStart === '' && estimateInfo.estimatedCarDeliveryStart == null) && estimatedCarDeliveryStart !== estimateInfo.estimatedCarDeliveryStart) || (!(estimatedCarDeliveryEnd === '' && estimateInfo.estimatedCarDeliveryEnd == null) && estimatedCarDeliveryEnd !== estimateInfo.estimatedCarDeliveryEnd) || (!(actualCarDeliveryStart === '' && estimateInfo.actualCarDeliveryStart == null) && actualCarDeliveryStart !== estimateInfo.actualCarDeliveryStart) || (!(actualCarDeliveryEnd === '' && estimateInfo.actualCarDeliveryEnd == null) && actualCarDeliveryEnd !== estimateInfo.actualCarDeliveryEnd)
      ) {
        setIsDirty(true);
      }
    }

  }, [actualCarDeliveryStart, actualCarDeliveryEnd, estimatedCarLoadingStart, estimatedCarLoadingEnd, actualCarLoadingStart, actualCarLoadingEnd, estimatedCarDeliveryStart, estimatedCarDeliveryEnd]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Move Dates" key="Move Dates" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Move Dates
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual End</Typography>
                  </div>
                </Box>
              </Grid>  
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Loading</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarLoadingStart'}
                    label={''}
                    name={'estimatedCarLoadingStart'}
                    value={formatDateForInput(estimatedCarLoadingStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarLoadingStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarLoadingEnd'}
                    label={''}
                    name={'estimatedCarLoadingEnd'}
                    value={formatDateForInput(estimatedCarLoadingEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarLoadingEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarLoadingStart'}
                    label={''}
                    name={'actualCarLoadingStart'}
                    value={formatDateForInput(actualCarLoadingStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarLoadingStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarLoadingEnd'}
                    label={''}
                    name={'actualCarLoadingEnd'}
                    value={formatDateForInput(actualCarLoadingEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarLoadingEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarDeliveryStart'}
                    label={''}
                    name={'estimatedCarDeliveryStart'}
                    value={formatDateForInput(estimatedCarDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarDeliveryEnd'}
                    label={''}
                    name={'estimatedCarDeliveryEnd'}
                    value={formatDateForInput(estimatedCarDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarDeliveryStart'}
                    label={''}
                    name={'actualCarDeliveryStart'}
                    value={formatDateForInput(actualCarDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarDeliveryEnd'}
                    label={''}
                    name={'actualCarDeliveryEnd'}
                    value={formatDateForInput(actualCarDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>Save</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const CarShipmentMoveDatesCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const { estimateInfo } = props;

  const updateFields = async (values) => {
    setIsLoading(true);

    let newVals = { ...estimateInfo };
    
    newVals.estimatedCarLoadingStart= values.estimatedCarLoadingStart;
    newVals.estimatedCarLoadingEnd= values.estimatedCarLoadingEnd;
    newVals.actualCarLoadingStart= values.actualCarLoadingStart;
    newVals.actualCarLoadingEnd= values.actualCarLoadingEnd;
    newVals.estimatedCarDeliveryStart= values.estimatedCarDeliveryStart;
    newVals.estimatedCarDeliveryEnd= values.estimatedCarDeliveryEnd;
    newVals.actualCarDeliveryStart= values.actualCarDeliveryStart;
    newVals.actualCarDeliveryEnd= values.actualCarDeliveryEnd;

    const resp = await props.updateHhgEstimate(newVals);
    if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
      props.showToast('Failed to update the moving dates segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };

  const handleSaveEdits = async (values) => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update moving dates data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } 
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };
    
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'calendar-days']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Moving Dates
                </Typography>
              </div>
              { !props.hideEdit && 
              <>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
              </>
              }
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Loading</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.estimatedCarLoadingStart) ? new Date(estimateInfo.estimatedCarLoadingStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.estimatedCarLoadingEnd) ? new Date(estimateInfo.estimatedCarLoadingEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.actualCarLoadingStart) ? new Date(estimateInfo.actualCarLoadingStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.actualCarLoadingEnd) ? new Date(estimateInfo.actualCarLoadingEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.estimatedCarDeliveryStart) ? new Date(estimateInfo.estimatedCarDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.estimatedCarDeliveryEnd) ? new Date(estimateInfo.estimatedCarDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.actualCarDeliveryStart) ? new Date(estimateInfo.actualCarDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(estimateInfo && estimateInfo.actualCarDeliveryEnd) ? new Date(estimateInfo.actualCarDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          estimateInfo={estimateInfo}
        />
      )}
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateHhgEstimate,
    showToast,
  }),
)(CarShipmentMoveDatesCard);