/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';

import { Box, Grid } from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import OverviewGlobalCard from './overviewGlobalCard.component';
import PropTypes from 'prop-types';
import React from 'react';
import StatusStepperGlobal from './statusStepperGlobal.component';
import DatesGlobalCard from './datesGlobalCard.component';
import AssignmentExtensionsCrudComponent from './assignmentExtensionsCrud.component';
import TripsOnAssignmentCrudComponent from './tripsOnAssignmentCrud.component';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const AssignmentLanding = props => {
  const { details, id, isReadOnly } = props;

  return (
    <Box>
      <StatusStepperGlobal globalExtensions={details?.authGlobal?.globalExtensions} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <OverviewGlobalCard globalInfo={details?.authGlobal?.global} id={id} isReadOnly={isReadOnly} />
          <Box sx={{ paddingTop: '16px' }}>
            <AssignmentExtensionsCrudComponent globalExtensions={details?.authGlobal?.globalExtensions} id={id} isReadOnly={isReadOnly} />
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <TripsOnAssignmentCrudComponent globalTrips={details?.authGlobal?.globalTrips} id={id} isReadOnly={isReadOnly} globalInfo={details?.authGlobal?.global}/>
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <DatesGlobalCard globalInfo={details?.authGlobal?.global} id={id} isReadOnly={isReadOnly} />
        </Grid>
      </Grid>
    </Box>
  );
};

AssignmentLanding.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default compose(withStyles(styles), connect(mapStateToProps, {}))(AssignmentLanding);
