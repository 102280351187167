/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 350,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleInventoryHomes = (props) => {

  const clientHomesaleInventoryHomes = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleInventoryHomes : null;

  const [inventoryHomesInitiated, setInventoryHomesInitiated] = useState(clientHomesaleInventoryHomes ? clientHomesaleInventoryHomes.inventoryHomesInitiated : false);
  const [canTRCApproveRepairs, setCanTRCApproveRepairs] = useState(clientHomesaleInventoryHomes ? clientHomesaleInventoryHomes.canTRCApproveRepairs : null);
  const [canTRCAcceptSales, setCanTRCAcceptSales] = useState(clientHomesaleInventoryHomes ? clientHomesaleInventoryHomes.canTRCAcceptSales : null);
  const [canTRCApproveGeneralMaintenance, setCanTRCApproveGeneralMaintenance] = useState(clientHomesaleInventoryHomes ? clientHomesaleInventoryHomes.canTRCApproveGeneralMaintenance : null);
  const [canTRCApproveInspectionRepairs, setCanTRCApproveInspectionRepairs] = useState(clientHomesaleInventoryHomes ? clientHomesaleInventoryHomes.canTRCApproveInspectionRepairs : null); 
  const [otherNotes, setOtherNotes] = useState(clientHomesaleInventoryHomes ? clientHomesaleInventoryHomes.otherNotes : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      InventoryHomesInitiated: inventoryHomesInitiated,
      CanTRCApproveRepairs: canTRCApproveRepairs,
      CanTRCAcceptSales: canTRCAcceptSales,
      CanTRCApproveGeneralMaintenance: canTRCApproveGeneralMaintenance,
      CanTRCApproveInspectionRepairs: canTRCApproveInspectionRepairs,
      OtherNotes: otherNotes,
    };

    if (clientHomesaleInventoryHomes && clientHomesaleInventoryHomes.id) updateBody.Id = clientHomesaleInventoryHomes.id;

    props.updateInfo(updateBody);
  }, [inventoryHomesInitiated, canTRCApproveRepairs, canTRCAcceptSales, canTRCApproveGeneralMaintenance, canTRCApproveInspectionRepairs, otherNotes]);

  return (
    <div className={props.classes.contractsGrid}>
      <div className={props.classes.headerRow}>
        <h3>Inventory Homes</h3>
        <FormControlLabel
          control={
            <Checkbox
              name="inventoryHomes"
              label="Activate Service"
              checked={inventoryHomesInitiated}
              onChange={(e) => setInventoryHomesInitiated(e.target.checked)}
            />
          }
          label="Activate Service"
          classes={{ root: props.classes.checkbox }}
        />
      </div>
      {inventoryHomesInitiated && (
        <div className={props.classes.tabBorder}>
          <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
            <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
              <p className={props.classes.descText}>Can TRC approve repairs/marketing costs up to a certain dollar limit when a home comes into inventory? If yes, what amount can TRC approve?</p>
              <TextField
                id="name"
                label="Can Approve Repairs"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={canTRCApproveRepairs}
                onChange={(e) => setCanTRCApproveRepairs(e.target.value)}
              />
              <p className={props.classes.descText}>Can TRC accept sales within a limit?</p>
              <TextField
                id="name"
                label="Can Accept Sales"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={canTRCAcceptSales}
                onChange={(e) => setCanTRCAcceptSales(e.target.value)}
              />
              <p className={props.classes.descText}>Can TRC approve general maintenance costs?</p>
              <TextField
                id="name"
                label="Approve General Maintenance"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={canTRCApproveGeneralMaintenance}
                onChange={(e) => setCanTRCApproveGeneralMaintenance(e.target.value)}
              />
            </div>

            <div className={props.classes.contentCol}>
              <p className={props.classes.descText}>Once an outside buyer is found, is TRC allowed to approve repairs resulting from their inspections? If yes, up to what dollar amount?</p>
              <TextField
                id="name"
                label="Approve Inspection Repairs"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={canTRCApproveInspectionRepairs}
                onChange={(e) => setCanTRCApproveInspectionRepairs(e.target.value)}
              />
              <TextField
                variant="outlined"
                label={'Other Notes'}
                value={otherNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}; 

HomeSaleInventoryHomes.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleInventoryHomes);