/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box, Button, DialogActions, Grid, Link, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { POST_HOMESALE_RESALE_OFFER_FAILURE, UPDATE_HOMESALE_RESALE_OFFER_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getHomesaleResaleOffer, postHomesaleResaleOffer, updateHomesaleResaleOffer } from 'modules/authorizations/store/actions/homesale.actions';
import { memoize } from 'lodash';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import states from 'modules/common/states.json';

const ResaleOffersCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const { offer, id, deleteOffer, isReadOnly } = props;

  const [didSaleFellThrough, setDidSaleFellThrough] = useState(offer && offer.saleFellThrough ? offer.saleFellThrough : false);
  const [didOfferAccepted, setDidOfferAccepted] = useState(offer && offer.offerAccepted ? offer.offerAccepted : false);
  const [didOfferAcceptedLetterSent, setDidOfferAcceptedLetterSent] = useState(offer && offer.offerAcceptedLetterSent ? offer.offerAcceptedLetterSent : false);
  const [didSaleClosed, setDidSaleClosed] = useState(offer && offer.saleClosed ? offer.saleClosed : false);
  const [didTransfereeNotifiedOfClose, setDidTransfereeNotifiedOfClose] = useState(offer && offer.transfereeNotifiedOfClose ? offer.transfereeNotifiedOfClose : false);
  const [didClosingConfirmationSent, setDidClosingConfirmationSent] = useState(offer && offer.closingConfirmationSent ? offer.closingConcessions : false);
  const [didSendSaleClosed, setDidSendSaleClosed] = useState(offer && offer.sendSaleClosed ? offer.sendSaleClosed : false);

  const renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    let allStates = states.filter((state) => state.countryCode === useCountryCode)
      .map(( state ) => (
        state.description
      ));
    return allStates;
  });

  const offerDetailsTabs = [
    { header: 'Offer Details', order: 1, iconPath: ['fas', 'fa-file-invoice'] },
  ];

  const resaleDetailsTabs = [
    { header: 'Resale Details', order: 1, iconPath: ['fas', 'fa-house'] },
  ];

  const buyerDetailsTabs = [
    { header: 'Buyer Details', order: 1, iconPath: ['fas', 'fa-user'] },
  ];

  const localTitleCoTabs = [
    { header: 'Local Title Co.', order: 1, iconPath: ['fas', 'fa-money-check-dollar'] },
  ];

  const offerDetailsSummaries = [
    { header: 'Offer Date', accessorKey: 'offerDate', type: 'date', required: false, value: offer ? offer.offerDate : null, tab: 1 },
    { header: 'Offer Amount', accessorKey: 'offerAmount', type: 'money', required: false, value: offer ? offer.offerAmount : '', tab: 1 },
    { header: 'Earnest Money', accessorKey: 'earnestMoney', type: 'money', required: false, value: offer ? offer.earnestMoney : '', tab: 1 },
    { header: 'Financing', accessorKey: 'financing', type: 'text', required: false, value: offer ? offer.financing : '', tab: 1 },
    { header: 'Mortgage Amount', accessorKey: 'mortgageAmount', type: 'money', required: false, value: offer ? offer.mortgageAmount : '', tab: 1 },
    { header: 'Points', accessorKey: 'points', type: 'text', required: false, value: offer ? offer.points : '', tab: 1 },
    { header: 'Amount', accessorKey: 'amount', type: 'text', value: offer ? offer.amount : '', required: false, tab: 1 },
    { header: 'Interest Rate', accessorKey: 'interestRate', type: 'text', value: offer ? offer.interestRate : '', required: false, tab: 1 },
    { header: 'Mortgage App Date', accessorKey: 'mortgageAppDate', type: 'date', value: offer ? offer.mortgageAppDate : null, required: false, tab: 1 },
    { header: 'Mortgage Commit Date', accessorKey: 'mortgageCommitDate', type: 'date', value: offer ? offer.mortgageCommitDate : null, required: false, tab: 1 },
    { header: 'Inspection Date', accessorKey: 'inspectionDate', type: 'date', value: offer ? offer.inspectionDate : null, required: false, tab: 1 },
    { header: 'Scheduled Close Date', accessorKey: 'scheduledCloseDate', type: 'date', value: offer ? offer.scheduledCloseDate : null, required: false, tab: 1 },
    { header: 'Accepted Date', accessorKey: 'acceptedDate', type: 'date', value: offer ? offer.acceptedDate : null, required: false, tab: 1 },
    { header: 'Fall Through Date', accessorKey: 'fallThroughDate', type: 'date', value: offer ? offer.fallThroughDate : null, required: false, tab: 1 },
    { header: 'Fall Through Reason', accessorKey: 'fallThroughReason', type: 'text', value: offer ? offer.fallThroughReason : '', required: false, tab: 1 },
    { header: 'Offer Status', accessorKey: 'offerStatus', type: 'dropdown', options: ['Active', 'Inactive'], value: offer ? offer.offerStatus : '', required: false, tab: 1 },
    { header: 'Subject To', accessorKey: 'subjectTo', type: 'text', value: offer ? offer.subjectTo : '', required: false, tab: 1 },
    { header: 'Repairs', accessorKey: 'repairs', type: 'text', value: offer ? offer.repairs : '', required: false, tab: 1 },
    { header: 'Repairs Amount', accessorKey: 'repairsAmount', type: 'money', value: offer ? offer.repairsAmount : '', required: false, tab: 1 },
    { header: 'Warranty', accessorKey: 'warranty', type: 'text', value: offer ? offer.warranty : '', required: false, tab: 1 },
    { header: 'Details Concessions', accessorKey: 'detailsConcessions', type: 'text', value: offer ? offer.detailsConcessions : '', required: false, tab: 1 },
    { header: 'Broker Commission', accessorKey: 'brokerCommission', type: 'text', value: offer ? offer.brokerCommission : '', required: false, tab: 1 },
    { header: 'Counter Date', accessorKey: 'counterDate', type: 'date', value: offer ? offer.counterDate : null, required: false, tab: 1 },
    { header: 'Counter Amount', accessorKey: 'counterAmount', type: 'money', value: offer ? offer.counterAmount : '', required: false, tab: 1 },
    { header: 'Inspection Release', accessorKey: 'inspectionRelease', type: 'text', value: offer ? offer.inspectionRelease : '', required: false, tab: 1 },
  ];

  const resaleDetailsSummaries = [
    { header: 'Sale Type', accessorKey: 'saleType', type: 'text', required: false, value: offer ? offer.saleType : '', tab: 1 },
    { header: 'Buyer Names', accessorKey: 'buyerNames', type: 'text', required: false, value: offer ? offer.buyerNames : '', tab: 1 },
    { header: 'Actual Buyout Date', accessorKey: 'actualBuyoutDate', type: 'date', value: offer ? offer.actualBuyoutDate : null, required: false, tab: 1 },
    { header: 'Resale Coordinator', accessorKey: 'resaleCoordinator', type: 'text', required: false, value: offer ? offer.resaleCoordinator : '', tab: 1 },
    { header: 'Days on Market', accessorKey: 'daysOnMarket', type: 'number', required: false, value: offer ? offer.daysOnMarket : '', tab: 1 },
    { header: 'Offer Accepted Amount', accessorKey: 'offerAcceptedAmount', type: 'money', required: false, value: offer ? offer.offerAcceptedAmount : '', tab: 1 },
    { header: 'Fall Through', accessorKey: 'fallThrough', type: 'text', required: false, value: offer ? offer.fallThrough : '', tab: 1 },
    { header: 'Resale Contract Date', accessorKey: 'resaleContractDate', type: 'date', value: offer ? offer.resaleContractDate : null, required: false, tab: 1 },
    { header: 'Gross Sale Price', accessorKey: 'grossSalePrice', type: 'money', required: false, value: offer ? offer.grossSalePrice : '', tab: 1 },
    { header: 'Closing Concessions', accessorKey: 'closingConcessions', type: 'text', required: false, value: offer ? offer.closingConcessions : '', tab: 1 },
    { header: 'Home Warranty', accessorKey: 'homeWarranty', type: 'text', required: false, value: offer ? offer.homeWarranty : '', tab: 1 },
    { header: 'Closing Repairs', accessorKey: 'closingRepairs', type: 'text', required: false, value: offer ? offer.closingRepairs : '', tab: 1 },
    { header: 'Scheduled Resale Close', accessorKey: 'scheduledResaleClose', type: 'date', value: offer ? offer.scheduledResaleClose : null, required: false, tab: 1 },
    { header: 'Actual Resale Close', accessorKey: 'actualResaleClose', type: 'date', value: offer ? offer.actualResaleClose : null, required: false, tab: 1 },
    { header: 'Comments', accessorKey: 'comments', type: 'notes', required: false, value: offer ? offer.comments : '', tab: 1 },
  ];

  const buyerDetailsSummaries = [
    { header: 'Buyer Close Costs', accessorKey: 'buyerCloseCosts', type: 'money', required: false, value: offer ? offer.buyerCloseCosts : '', tab: 1 },
    { header: 'Closing Costs Percentage', accessorKey: 'closingCostsPercentage', type: 'text', required: false, value: offer ? offer.closingCostsPercentage : '', tab: 1 },
    { header: 'Buyer Name', accessorKey: 'buyerName', type: 'text', required: false, value: offer ? offer.buyerName : '', tab: 1 },
    { header: 'Buyer Income', accessorKey: 'buyerIncome', type: 'money', required: false, value: offer ? offer.buyerIncome : '', tab: 1 },
    { header: 'Co-Buyer Income', accessorKey: 'coBuyerIncome', type: 'money', required: false, value: offer ? offer.coBuyerIncome : '', tab: 1 },
    { header: 'Buyer Type', accessorKey: 'buyerType', type: 'text', required: false, value: offer ? offer.buyerType : '', tab: 1 },
    { header: 'Own or Rent', accessorKey: 'ownOrRent', type: 'dropdown', options: ['Own', 'Rent'], required: false, value: offer ? offer.ownOrRent : '', tab: 1 },
    { header: 'Employer', accessorKey: 'employer', type: 'text', required: false, value: offer ? offer.employer : '', tab: 1 },
    { header: 'How Long', accessorKey: 'howLong', type: 'text', required: false, value: offer ? offer.howLong : '', tab: 1 },
  ];

  const localTitleCoSummaries = [
    { header: 'Contact', accessorKey: 'contact', type: 'text', required: false, value: offer ? offer.contact : '', tab: 1 },
    { header: 'Company', accessorKey: 'company', type: 'text', required: false, value: offer ? offer.company : '', tab: 1 },
    { header: 'Address', accessorKey: 'address', type: 'text', required: false, value: offer ? offer.address : '', tab: 1 },
    { header: 'City', accessorKey: 'city', type: 'text', required: false, value: offer ? offer.city : '', tab: 1 },
    { header: 'State', accessorKey: 'state', type: 'dropdown', options: renderStateOptions(''), required: false, value: offer ? offer.state : '', tab: 1 },
    { header: 'Zip', accessorKey: 'zip', type: 'text', required: false, value: offer ? offer.zip : '', tab: 1 },
    { header: 'Phone Number', accessorKey: 'phoneNumber', type: 'text', required: false, value: offer ? offer.phoneNumber : '', tab: 1 },
    { header: 'Fax', accessorKey: 'fax', type: 'text', required: false, value: offer ? offer.fax : '', tab: 1 },
    { header: 'Email', accessorKey: 'email', type: 'text', required: false, value: offer ? offer.email : '', tab: 1 },
    { header: 'Documents Sent', accessorKey: 'documentsSent', type: 'dropdown', options: ['Yes', 'No'], required: false, value: offer ? offer.documentsSent : '', tab: 1 },
  ];

  const updateOfferDetailsFields = async (tab, values) => {
    setIsLoading(true);
    if (offer && offer.length !== 0) {
      let newValues = { ...offer };

      newValues.offerDate = values.offerDate;
      newValues.offerAmount = values.offerAmount;
      newValues.earnestMoney = values.earnestMoney;
      newValues.financing = values.financing;
      newValues.mortgageAmount = values.mortgageAmount;
      newValues.points = values.points;
      newValues.amount = values.amount;
      newValues.interestRate = values.interestRate;
      newValues.mortgageAmount = values.mortgageAmount;
      newValues.mortgageAppDate = values.mortgageAppDate;
      newValues.mortgageCommitDate = values.mortgageCommitDate;
      newValues.inspectionDate = values.inspectionDate;
      newValues.scheduledCloseDate = values.scheduledCloseDate;
      newValues.acceptedDate = values.acceptedDate;
      newValues.fallThroughDate = values.fallThroughDate;
      newValues.fallThroughReason = values.fallThroughReason;
      newValues.offerStatus = values.offerStatus;
      newValues.subjectTo = values.subjectTo;
      newValues.repairs = values.repairs;
      newValues.repairsAmount = values.repairsAmount;
      newValues.warranty = values.warranty;
      newValues.detailsConcessions = values.detailsConcessions;
      newValues.brokerCommission = values.brokerCommission;
      newValues.counterDate = values.counterDate;
      newValues.counterAmount = values.counterAmount;
      newValues.inspectionRelease = values.inspectionRelease;

      const resp = await props.updateHomesaleResaleOffer(newValues);
      if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postHomesaleResaleOffer(values);
      if (resp.type === POST_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const updateResaleDetailsFields = async (tab, values) => {
    setIsLoading(true);
    if (offer && offer.length !== 0) {
      let newValues = { ...offer };

      newValues.saleFellThrough = values.saleFellThrough;
      newValues.offerAccepted = values.offerAccepted;
      newValues.saleType = values.saleType;
      newValues.buyerNames = values.buyerNames;
      newValues.actualBuyoutDate = values.actualBuyoutDate;
      newValues.resaleCoordinator = values.resaleCoordinator;
      newValues.daysOnMarket = values.daysOnMarket;
      newValues.offerAcceptedAmount = values.offerAcceptedAmount;
      newValues.fallThrough = values.fallThrough;
      newValues.resaleContractDate = values.resaleContractDate;
      newValues.grossSalePrice = values.grossSalePrice;
      newValues.closingConcessions = values.closingConcessions;
      newValues.homeWarranty = values.homeWarranty;
      newValues.closingRepairs = values.closingRepairs;
      newValues.scheduledResaleClose = values.scheduledResaleClose;
      newValues.actualResaleClose = values.actualResaleClose;
      newValues.comments = values.comments;
      
      const resp = await props.updateHomesaleResaleOffer(newValues);
      if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postHomesaleResaleOffer(values);
      if (resp.type === POST_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const updateBuyerDetailsFields = async (tab, values) => {
    setIsLoading(true);
    if (offer && offer.length !== 0) {
      let newValues = { ...offer };

      newValues.saleClosed = values.saleClosed;
      newValues.transfereeNotifiedOfClose = values.transfereeNotifiedOfClose;
      newValues.closingConfirmationSent = values.closingConfirmationSent;
      newValues.sendSaleClosed = values.sendSaleClosed;
      newValues.buyerCloseCosts = values.buyerCloseCosts;
      newValues.closingCostsPercentage = values.closingCostsPercentage;
      newValues.buyerName = values.buyerName;
      newValues.buyerIncome = values.buyerIncome;
      newValues.coBuyerIncome = values.coBuyerIncome;
      newValues.buyerType = values.buyerType;
      newValues.ownOrRent = values.ownOrRent;
      newValues.employer = values.employer;
      newValues.howLong = values.howLong;

      const resp = await props.updateHomesaleResaleOffer(newValues);
      if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postHomesaleResaleOffer(values);
      if (resp.type === POST_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const updateLocalTitleCoFields = async (tab, values) => {
    setIsLoading(true);
    if (offer && offer.length !== 0) {
      let newValues = { ...offer };

      newValues.contact = values.contact;
      newValues.company = values.company;
      newValues.address = values.address;
      newValues.city = values.city;
      newValues.state = values.state;
      newValues.zip = values.zip;
      newValues.phoneNumber = values.phoneNumber;
      newValues.fax = values.fax;
      newValues.email = values.email;
      newValues.documentsSent = values.documentsSent;

      const resp = await props.updateHomesaleResaleOffer(newValues);
      if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postHomesaleResaleOffer(values);
      if (resp.type === POST_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const updateButtons = async () => {
    setIsLoadingButton(true);
    const newValues = { ...offer };

    newValues.saleFellThrough = didSaleFellThrough;
    newValues.offerAccepted = didOfferAccepted;
    newValues.saleClosed = didSaleClosed;
    newValues.transfereeNotifiedOfClose = didTransfereeNotifiedOfClose;
    newValues.closingConfirmationSent = didClosingConfirmationSent;
    newValues.sendSaleClosed = didSaleClosed;

    const resp = await props.updateHomesaleResaleOffer(newValues);
    if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingButton(false);
      return false;
    } else { 
      setIsLoadingButton(false);
      return true; 
    }
  };

  const updateSaleFellThrough = async () => {
    setIsLoadingButton(true);
    const newValues = { ...offer };
    newValues.saleFellThrough = didSaleFellThrough;
    newValues.fallThroughDate = new Date().toISOString().split('T')[0];
    newValues.offerStatus = 'Fall Through';

    const resp = await props.updateHomesaleResaleOffer(newValues);
    if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingButton(false);
      return false;
    } else { 
      setIsLoadingButton(false);
      return true; 
    }
  };

  const undoSaleFellThrough = async () => {
    setIsLoadingButton(true);
    const newValues = { ...offer };
    newValues.saleFellThrough = didSaleFellThrough;
    newValues.fallThroughDate = null;
    newValues.offerStatus = '';

    const resp = await props.updateHomesaleResaleOffer(newValues);
    if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingButton(false);
      return false;
    } else { 
      setIsLoadingButton(false);
      return true; 
    }
  };

  const updateCloseSale = async () => {
    setIsLoadingButton(true);
    const newValues = { ...offer };
    newValues.saleClosed = didSaleClosed;
    newValues.actualResaleClose = new Date().toISOString().split('T')[0];

    const resp = await props.updateHomesaleResaleOffer(newValues);
    if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingButton(false);
      return false;
    } else { 
      setIsLoadingButton(false);
      return true; 
    }
  };

  const undoCloseSale = async () => {
    setIsLoadingButton(true);
    const newValues = { ...offer };
    newValues.saleClosed = didSaleClosed;
    newValues.actualResaleClose = null;

    const resp = await props.updateHomesaleResaleOffer(newValues);
    if (resp.type === UPDATE_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingButton(false);
      return false;
    } else { 
      setIsLoadingButton(false);
      return true; 
    }
  };

  const offerDetailsButtons = ({ ...rest }) => {
    
    return (
      <>
        {isLoadingButton && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          </Box>
        )}
        {!isLoadingButton && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                {!isReadOnly && offer && (
                  <Button
                    onClick={deleteOffer}
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#868686',
                      '&:hover': { backgroundColor: '#474747' },
                    }}
                  >
                    Delete Offer
                  </Button>
                )}
                {!isReadOnly && !didSaleFellThrough && (
                  <Button
                    onClick={(e) => {setDidSaleFellThrough(true); updateSaleFellThrough();}}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    Sale Fell Through
                  </Button>
                )}
                {!isReadOnly && !didOfferAccepted && (
                  <Button
                    onClick={(e) => {setDidOfferAccepted(true); updateButtons();}}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    Accept Offer
                  </Button>
                )}
                {!isReadOnly && didOfferAccepted && !didOfferAcceptedLetterSent && (
                  <Button
                    onClick={(e) => {setDidOfferAcceptedLetterSent(true); updateButtons();}}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    Send Prepare for Closing Letter
                  </Button>
                )}
              </Box>
            </Box>
            {(offer || didSaleFellThrough || didOfferAccepted) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Box>
                  {didSaleFellThrough && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} />Offer marked as 'Sale Fell Through' successfully. {!isReadOnly && <> Please provide a fall through reason or <Link onClick={(e) => {setDidSaleFellThrough(false); undoSaleFellThrough();}} sx={{ cursor: 'pointer' }}>click here</Link> to undo. </> }</Typography>}
                  {didOfferAccepted && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Offer accepted successfully! {!isReadOnly && <>  <Link onClick={(e) => {setDidOfferAccepted(false); updateButtons();}} sx={{ cursor: 'pointer' }}>click here</Link> to undo. </> }</Typography>}
                  {didOfferAcceptedLetterSent && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} />Letter "Homesale - Accepted Offer – Prepare For Closing" has been sent. {!isReadOnly && <>  <Link sx={{ cursor: 'pointer' }}>click here</Link> to resend. </> }</Typography>}

                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  const resaleDetailsButtons = ({ ...rest }) => {
    
    return (
      <>
        {isLoadingButton && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          </Box>
        )}
        {!isLoadingButton && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                {!isReadOnly && !didSaleClosed && (
                  <Button
                    onClick={(e) => {setDidSaleClosed(true); updateCloseSale();}}
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#868686',
                      '&:hover': { backgroundColor: '#474747' },
                    }}
                  >
                    Close Sale
                  </Button>
                )}
                {!isReadOnly && !didTransfereeNotifiedOfClose && (
                  <Button
                    onClick={(e) => {setDidTransfereeNotifiedOfClose(true); updateButtons();}}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    Notify EE of Close
                  </Button>
                )}
                {!isReadOnly && !didClosingConfirmationSent && (
                  <Button
                    onClick={(e) => {setDidClosingConfirmationSent(true); updateButtons();}}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    Send Closing Confirmation
                  </Button>
                )}
                {!isReadOnly && !didSendSaleClosed && (
                  <Button
                    onClick={(e) => {setDidSendSaleClosed(true); updateButtons();}}
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                  >
                    Send Sale Closed
                  </Button>
                )}
              </Box>
            </Box>
            {(offer || didSendSaleClosed || didTransfereeNotifiedOfClose || didClosingConfirmationSent || didSaleClosed) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Box>
                  {didSaleClosed && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} />Resale marked as 'Closed' successfully. Letter "Cancel Title Insurance" has been sent. {!isReadOnly && <> <Link onClick={(e) => {setDidSaleClosed(false); undoCloseSale();}} sx={{ cursor: 'pointer' }}>click here</Link> to undo. </> } </Typography>}
                  {didTransfereeNotifiedOfClose && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} />Transferee Notified successfully! Letter "Homesale - Property Sale Closed" has been sent. {!isReadOnly && <> <Link sx={{ cursor: 'pointer' }}>click here</Link> to resend. </> } </Typography>}
                  {didClosingConfirmationSent && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} />Closing Confirmation sent successfully! {!isReadOnly && <> <Link sx={{ cursor: 'pointer' }}>click here</Link> to resend. </> } </Typography>}
                  {didSendSaleClosed && <Typography color="secondary"> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} />Sale Closed Letter sent successfully! {!isReadOnly && <> <Link sx={{ cursor: 'pointer' }}>click here</Link> to resend. </> } </Typography>}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };
    
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      <Grid item xs={12} xl={8} rowSpacing={2}>
        <Grid item sx={{ marginBottom: '10px' }}>
          <EditableCard
            tabs={offerDetailsTabs}
            summaries={offerDetailsSummaries}
            numColumns={5} 
            updateFields={updateOfferDetailsFields}
            isLoading={isLoading}
            ButtonComponent={offerDetailsButtons}
            hideEdit={isReadOnly}
          />
        </Grid>
        <Grid item >
          <EditableCard
            tabs={resaleDetailsTabs}
            summaries={resaleDetailsSummaries}
            numColumns={5} 
            updateFields={updateResaleDetailsFields}
            isLoading={isLoading}
            ButtonComponent={resaleDetailsButtons}
            hideEdit={isReadOnly}
          />  
        </Grid>
      </Grid>
      <Grid item xs={12} xl={4} rowSpacing={2}>
        <Grid item sx={{ marginBottom: '10px' }}>
          <EditableCard
            tabs={buyerDetailsTabs}
            summaries={buyerDetailsSummaries}
            numColumns={3} 
            updateFields={updateBuyerDetailsFields}
            isLoading={isLoading}
            hideEdit={isReadOnly}
          />
        </Grid>
        <Grid item >
          <EditableCard
            tabs={localTitleCoTabs}
            summaries={localTitleCoSummaries}
            numColumns={3} 
            updateFields={updateLocalTitleCoFields}
            isLoading={isLoading}
            hideEdit={isReadOnly}
          />  
        </Grid>
      </Grid>
    </Grid>
  );

};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  connect(mapStateToProps, {
    postHomesaleResaleOffer,
    updateHomesaleResaleOffer,
    getHomesaleResaleOffer,
    showToast,
  }),
)(ResaleOffersCard);