import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const ToolTip = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, alignCenter, ...toolTipProps } = props;
  const { label } = toolTipProps;
  const [isOpen, setIsOpen] = useState(false);

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
     
  });

  return (
    <ThemeProvider theme={theme}>
      <Tooltip style={{ paddingTop: 0, paddingBottom: 0, display: 'inline-flex' }} title={label} placement="top-start" open={isOpen} onOpen={() => {setIsOpen(true);}} onClose={() => {setIsOpen(false);}}>
        <IconButton>
          <FontAwesomeIcon size="1x" icon={['fas', 'fa-question-circle']} color={theme.palette.primary} onClick={() => {setIsOpen(!isOpen);}} />
        </IconButton>
      </Tooltip>
    </ThemeProvider>
  );
};

ToolTip.defaultProps = {
  alignCenter: false,
};

ToolTip.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  alignCenter: PropTypes.bool,
  classes: PropTypes.object,
};

export default (ToolTip);
