/* eslint-disable array-callback-return */
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Fab,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';  
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';

import { Link } from 'react-router-dom';
  
const useStyles = makeStyles((theme) => (
  {
    card: {
      width: '100%',
      maxWidth: 1500,
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cardContainer: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
  
    },
    iconRow:{
      display: 'flex',
      flexDirection: 'row',
    },
    dialogIconRow:{
      display: 'flex',
      flexDirection: 'row',
      padding: '40px',
      paddingBottom: '20px',
    },
    cardDisplayContent: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '10px',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipClickable: {
      cursor: 'pointer',
    },
    iconOverlayImg: {
      position: 'absolute',
    },
    spacingX: {
      marginRight: theme.spacing(1),
    },
    footer: {
      height: 15,
      marginBottom: theme.spacing(2),
    },
    mb2: {
      marginBottom: '6.5px',
      marginTop: '6.5px',
      marginRight: '16px',
    },
    editModalContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100% !important',
      marginLeft: '0px !important',
    },
    editModalColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100% !important',
    },
    editModalInput: {
      minWidth: '332px',
    },
    dialogContentContainer: {
      margin: '15px',
    },
    labelText: {
      minWidth: 350,
      marginBottom: 0,
    },
    initiatedStatusBox: {
      border: '1px solid #67a783',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#67a783',
    },
    notInitiatedStatusBox: {
      border: '1px solid #fd6120',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#fd6120',
    },
    expensesStatusBox: {
      border: '1px solid #868686',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#868686',
    },
  }
));
  
/*
      - Required Props: 
       * tabs - a list of tabs to be included in the card
       * summaries - a list of objects detailing the specific textfield requirements for the editing feature as well as the necessary values to display
       * numColumns - number of xl device columns
      - Optional Props: 
       * updateFields (typically an API PUT call followed by GET call to refresh the data, returns boolean) 
  
      - Required Label Fields: accessorKey, header, type, options (only required for dropdown type)
      - Optional Label Fields: required (defaults to false), size, enableColumnOrdering, enableEditing, enableSorting, hideInTable (if column should be displayed in the CRUD table or not)
  
      - Label Types: text, notes, number, date, dropdown, dropdownv2
  
      Tabs are displayed in the order they appear in the list. If there is only one item in the list, then it will be displayed as a 'header' (not clickable, no other visible tabs) and all items in the summaries list will be displayed and editable by default. If within the summaries list
      there is an item with a 'tab' option of a different value than the 'order' in the single item within the tabs list. (Example - there is an item in the summaries list with a 'tab' value of 3. There is only one item in the tabs list. That item will still be displayed within the card.)
  
      const tabs = [
          { accessorKey: 'nameOfTabMatchingInSummaries', header: 'displayTitleOfTab', order: 1, iconPath: ['fas', 'user-friends'] },
      ];
  
      The editable card is largely based off of the CRUD table functionality. An example of a label input is:
      const summaries = [
          { header: 'Text Example', type: 'text', required: true, size: 72 },
          { header: 'Dropdown Example', type: 'dropdown', options: ['Yes', 'No'], required: true, size: 68 },
          { header: 'Dropdown Version 2 Example', type: 'dropdown-v2', options: arrayOfOptions, defaultVal: 0, required: true, Cell: ({cell}) => cell.fieldValues.original.dropdownV2Example != null && <p>{dropdownV2Exampoe.find(o => o.id === cell.fieldValues.dropdownV2Example).title}</p>} ,
          { header: 'Checkbox Example', type: 'checkbox', defaultVal: false, required: true, Cell: ({cell}) => <Checkbox name="checkboxExample" label="Active" checked=(cell.fieldValues.original.checkboxExample} disabled={false} },
          { header: 'Date Example', type: 'date', required: true, size: 72 },
          { header: 'Notes Example', type: 'notes', required: true},
      ]
  
      Included in the summaries array, you need a VALUE that contains the proper value you want displayed. An example is that fields = authorizationInformation. Values will then be gathered by the accessor key in the summaries array:
  
            We want to display the values for an authorization's ID and Client:
            const summaries = [
              { header: 'File #', value: this.props.authorizationInformation.mtAuthorizationId, type: 'text', required: true, disabled: true },
              { header: 'Client', value: this.props.authorizationInformation.client.description, type: 'text', required: true, disabled: true },
            ]
  
      The updateFields props is a FUNCTION for updating the fields via the editing modal. Here is an example of how to write an updateFields function:
      
      const updateAuthorizationInformation = async (authorizationInformation, values) => {
        const id = authorizationInformation.mtAuthorizationId;
        values.mtAuthorizationId = id;
        values.clientId = authorizationInformation.clients.clientId;
        const resp = await props.updateAuthorizationInformation(values);
        if (resp.type === UPDATE_AUTHORIZATION_INFORMATION_FAILURE) {
          return false;
        } else {
        const getResp = await props.getAuthorizationInformation(authorizationInformation.clients.clientId);
        if (getResp.type === GET_AUTHORIZATION_INFORMATION_FAILURE) {
          return false;
        } else {
          return true;
        }
      }
    };
  
  */
  
  
const ServicesEditableCard = (props) => {
  const { servicesInfo, updateFields, isLoading } = props;
  const classes = useStyles();
  
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
   
  });
  
  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

    

  
  const handleSaveEdits = async ( temporaryLiving, taxAssistance, homesale, global, homefindRenter, homefindBuyer, householdGoods ) => {
    if (!Object.keys(validationErrors).length) {
      // Send update call to parent to update and refresh values.
      if (updateFields) {
        const resp = await updateFields(temporaryLiving, taxAssistance, homesale, global, homefindRenter, homefindBuyer, householdGoods);
        if (!resp) {
          showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        } else {
          setEditingModalOpen(false);
        }
      }
      setEditingModalOpen(false); //required to exit editing mode and close modal
    }
  };
  
  const handleCancelEdits = () => {
    setValidationErrors({});
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  const taxAssistanceActive = servicesInfo.taxAssistance && (servicesInfo.taxAssistance === 'Completed' || servicesInfo.taxAssistance === 'Initiated');
  const tempLivingActive = servicesInfo.temporaryLiving && (servicesInfo.temporaryLiving === 'Completed' || servicesInfo.temporaryLiving === 'Initiated');
  const destinationServicesActive = servicesInfo.homefindRenter && (servicesInfo.homefindRenter === 'Completed' || servicesInfo.homefindRenter === 'Initiated');
  const candidateServicesActive = servicesInfo.homefindBuyer && (servicesInfo.homefindBuyer === 'Completed' || servicesInfo.homefindBuyer === 'Initiated');
  const householdGoodsActive = servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated');
  const globalActive = servicesInfo.global && (servicesInfo.global === 'Completed' || servicesInfo.global === 'Initiated');
  const homesaleActive = servicesInfo.homesale && (servicesInfo.homesale === 'Completed' || servicesInfo.homesale === 'Initiated');

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Typography variant="h5" >
                  Services
                </Typography>
              </div>
              <Fab className={classes.iconOverlayImg} color="primary" size = "small" onClick={() => setEditingModalOpen(true)}>
                <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
              </Fab>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={{ xs: 2, md: 2 }}>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: taxAssistanceActive ? '#36939B' : '#868686' } }}>
                    <Link to={taxAssistanceActive ? `/authorizations/${servicesInfo.authorizationId}/taxAssistance` : null} > + Tax Assistance </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                { (servicesInfo.taxAssistance && (servicesInfo.taxAssistance === 'Completed' || servicesInfo.taxAssistance === 'Initiated')) &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.taxAssistance}
                      </Typography>
                    </Alert>
                  </>
                }
                { !(servicesInfo.taxAssistance && (servicesInfo.taxAssistance === 'Completed' || servicesInfo.taxAssistance === 'Initiated')) &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.taxAssistance}
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: homesaleActive ? '#36939B' : '#868686' } }}>
                    <Link to={homesaleActive ? `/authorizations/${servicesInfo.authorizationId}/homesale` : null}> + Homesale </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                { (servicesInfo.homesale && (servicesInfo.homesale === 'Completed' || servicesInfo.homesale === 'Initiated')) &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.homesale}
                      </Typography>
                    </Alert>
                  </>
                }
                { !(servicesInfo.homesale && (servicesInfo.homesale === 'Completed' || servicesInfo.homesale === 'Initiated')) &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.homesale}
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: globalActive ? '#36939B' : '#868686' } }}>
                    <Link to={globalActive ? `/authorizations/${servicesInfo.authorizationId}/global` : null} > + Global </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                { (servicesInfo.global && (servicesInfo.global === 'Completed' || servicesInfo.global === 'Initiated')) &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.global}
                      </Typography>
                    </Alert>
                  </>
                }
                { !(servicesInfo.global && (servicesInfo.global === 'Completed' || servicesInfo.global === 'Initiated')) &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.global}
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: destinationServicesActive ? '#36939B' : '#868686' } }}>
                    <Link to={destinationServicesActive ? `/authorizations/${servicesInfo.authorizationId}/destinationServices` : null} > + Destination Services </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                { (servicesInfo.homefindRenter && (servicesInfo.homefindRenter === 'Completed' || servicesInfo.homefindRenter === 'Initiated')) &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.homefindRenter}
                      </Typography>
                    </Alert>
                  </>
                }
                { !(servicesInfo.homefindRenter && (servicesInfo.homefindRenter === 'Completed' || servicesInfo.homefindRenter === 'Initiated')) &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.homefindRenter}
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: candidateServicesActive ? '#36939B' : '#868686' } }}>
                    <Link to={candidateServicesActive ? `/authorizations/${servicesInfo.authorizationId}/candidateServices` : null}>Candidate Services</Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                { (servicesInfo.homefindBuyer && (servicesInfo.homefindBuyer === 'Completed' || servicesInfo.homefindBuyer === 'Initiated')) &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.homefindBuyer}
                      </Typography>
                    </Alert>
                  </>
                }
                { !(servicesInfo.homefindBuyer && (servicesInfo.homefindBuyer === 'Completed' || servicesInfo.homefindBuyer === 'Initiated')) &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.homefindBuyer}
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: tempLivingActive ? '#36939B' : '#868686' } }}>
                    <Link to={tempLivingActive ? `/authorizations/${servicesInfo.authorizationId}/tempLiving` : null}>Temporary Living</Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                { (servicesInfo.temporaryLiving && (servicesInfo.temporaryLiving === 'Completed' || servicesInfo.temporaryLiving === 'Initiated')) &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.temporaryLiving}
                      </Typography>
                    </Alert>
                  </>
                }
                { !(servicesInfo.temporaryLiving && (servicesInfo.temporaryLiving === 'Completed' || servicesInfo.temporaryLiving === 'Initiated')) &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.temporaryLiving}
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: (servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated')) ? '#36939B' : '#868686' } }}>
                    <Link to={householdGoodsActive ? `/authorizations/${servicesInfo.authorizationId}/hhg` : null} > Household Goods </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                { (servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated')) &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.householdGoods}
                      </Typography>
                    </Alert>
                  </>
                }
                { !(servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated')) &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {servicesInfo && servicesInfo.householdGoods}
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="6" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: '#36939B' } }}>
                    <Link to={`/authorizations/${servicesInfo.authorizationId}/expenses`}>Expenses</Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item md="6">
                <Alert
                  severity="info"
                  iconMapping={{
                    info: <InfoIcon fontSize="small" color="white !important" sx={{ marginRight: '-5px', color: 'white !important' }} />,
                  }}
                  sx={{
                    fontSize: '0.875rem',
                    maxHeight: 35,
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'black',
                    color: 'white !important',
                  }}
                >
                  <Typography variant="body2">
                    {servicesInfo && servicesInfo.expenses}
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
      { editingModalOpen &&
          
        <EditCardModal
          servicesInfo={servicesInfo}
          classes={classes}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={handleCancelEdits}
          onSubmit={handleSaveEdits}
        />
      }
  
    </ThemeProvider>
  );
};
ServicesEditableCard.propTypes = {
  updateFields: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};
export default connect(
  null,
  { showToast },
)(ServicesEditableCard);
  
  
export const EditCardModal = ({ open, classes, servicesInfo, onClose, onSubmit, isLoading }) => {

  const [temporaryLiving, setTemporaryLiving] = useState(servicesInfo.temporaryLiving ?? '');
  const [taxAssistance, setTaxAssistance] = useState(servicesInfo.taxAssistance ?? '');
  const [homesale, setHomesale] = useState(servicesInfo.homesale ?? '');
  const [global, setGlobal] = useState(servicesInfo.global ?? '');
  const [homefindRenter, setHomefindRenter] = useState(servicesInfo.homefindRenter ?? '');
  const [homefindBuyer, setHomefindBuyer] = useState(servicesInfo.homefindBuyer ?? '');
  const [householdGoods, setHouseholdGoods] = useState(servicesInfo.householdGoods ?? '');
  
  const handleSubmit = (e) => {
    //put your validation logic here
    // need to add 'modified on / by' logic here
    e.preventDefault();
    onSubmit(temporaryLiving, taxAssistance, homesale, global, homefindRenter, homefindBuyer, householdGoods);
  };
  
  const handleCancel = (e) => {
    //put your validation logic here
    e.preventDefault();
    onClose();
  };
  
  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Typography variant="h5" >
              Services
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 3 }} sx={{ padding: '30px', paddingTop: '0px' }}>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'taxAssistance'}
                    name={'taxAssistance'}
                    label={'Tax Assistance'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={taxAssistance && (taxAssistance === 'Completed' || taxAssistance === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setTaxAssistance( e.target.checked ? 'Initiated' : 'Not Initiated' )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'homesale'}
                    name={'homesale'}
                    label={'Homesale'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={homesale && (homesale === 'Completed' || homesale === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHomesale(e.target.checked ? 'Initiated' : 'Not Initiated' )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'global'}
                    name={'global'}
                    label={'Global'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={global && (global === 'Completed' || global === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setGlobal(e.target.checked ? 'Initiated' : 'Not Initiated' )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'homefindRenter'}
                    name={'homefindRenter'}
                    label={'Destination Services'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={homefindRenter && (homefindRenter === 'Completed' || homefindRenter === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHomefindRenter(e.target.checked ? 'Initiated' : 'Not Initiated' )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'homefindBuyer'}
                    name={'homefindBuyer'}
                    label={'Candidate Services'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={homefindBuyer && (homefindBuyer === 'Completed' || homefindBuyer === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHomefindBuyer(e.target.checked ? 'Initiated' : 'Not Initiated' )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'temporaryLiving'}
                    name={'temporaryLiving'}
                    label={'Temporary Living'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={temporaryLiving && (temporaryLiving === 'Completed' || temporaryLiving === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setTemporaryLiving(e.target.checked ? 'Initiated' : 'Not Initiated' )
                    }
                  />   
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'householdGoods'}
                    name={'householdGoods'}
                    label={'Household Goods'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={householdGoods && (householdGoods === 'Completed' || householdGoods === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHouseholdGoods(e.target.checked ? 'Initiated' : 'Not Initiated' )
                    }
                  />   
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && 
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        }
        {!isLoading && 
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        }
      </form>
    </Dialog>
  );
};
  
  