import { Accordion, AccordionDetails, AccordionSummary, Box, Button, MenuItem, TextField, useTheme, withStyles, withTheme  } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { getClientPoliciesGeneralInfo, updateClientPoliciesGeneralInfo } from '../../clients.actions';

import { UPDATE_POLICIES_GENERAL_INFO__FAILURE, UPDATE_POLICIES_GENERAL_INFO__SUCCESS } from '../../types';

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

import Spinner from 'modules/common/spinner.component';

const styles = (theme) => ({
  accordionStyle: {
    maxWidth: 1050,
  },
  row: {
    backgroundColor: theme.palette.common.white,
  },
  accordionStyleContacts: {
    maxWidth: 1050,
  },
  inputGroup: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  root: {
    maxWidth: 170,
    marginRight: 10,
  },
  extRoot: {
    width: 115,
    marginRight: 10,
  },
  primaryContactRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  primaryText: {
    paddingTop: 12,
    paddingRight: 5,
  },
  newRow: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1050,
  },
  contractsGrid: {
    width: '100%',
    maxWidth: 900,
    marginTop: theme.spacing(4),
  },
  detailsLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
  },
  contentCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstCol: {
    width: 200,
  },
  textField: {
    minWidth: 350,
  },
  descText: {
    minWidth: 350,
    marginBottom: 0,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 16,
  },
  checkbox: {
    paddingLeft: 10,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tabBorder: {
    borderLeft: `2px solid ${theme.palette.grey[400]}`,
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    marginLeft: 16,
    marginTop: 16,
  },
});

const GeneralInformation = (props) => {
  const [expand, setExpand] = useState(false);

  const [clientUSMileageRatesTypeId, setClientUSMileageRatesTypeId] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.clientUSMileageRatesTypeId : null);
  const [clientCanadaMileageRatesTypeId, setClientCanadaMileageRatesTypeId] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.clientCanadaMileageRatesTypeId : null);
  const [clientUSMileageRateOther, setClientUSMileageRateOther] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.clientUSMileageRateOther : null);
  const [clientCanadaMileageRateOther, setClientCanadaMileageRateOther] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.clientCanadaMileageRateOther : null);
  const [receiptsMandatory, setReceiptsMandatory] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.receiptsMandatory : null); 
  const [alcoholReimbursable, setAlcoholReimbursable] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.alcoholReimbursable : null);
  const [clientReferral, setClientReferral] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.clientReferral : null);
  const [agentReferralRebate, setAgentReferralRebate] = useState(props.policiesGeneralInfo ? props.policiesGeneralInfo.agentReferralRebate : null);

  const updatePoliciesInfo = async () => {
    const updateBody = {
      ClientId: props.clientId,
      ClientUSMileageRatesTypeId: clientUSMileageRatesTypeId,
      ClientCanadaMileageRatesTypeId: clientCanadaMileageRatesTypeId,
      ClientUSMileageRateOther: clientUSMileageRateOther,
      ClientCanadaMileageRateOther: clientCanadaMileageRateOther,
      ReceiptsMandatory: receiptsMandatory,
      AlcoholReimbursable: alcoholReimbursable,
      ClientReferral: clientReferral,
      AgentReferralRebate: agentReferralRebate,
    };
    
    const updateResp = await props.updateClientPoliciesGeneralInfo(updateBody);
    if (updateResp.type === UPDATE_POLICIES_GENERAL_INFO__FAILURE) {
      props.showToast('Failed to save client homesale data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } else if (updateResp.type === UPDATE_POLICIES_GENERAL_INFO__SUCCESS) {
      await props.getClientPoliciesGeneralInfo(props.clientId);
    }
  };

  const theme = useTheme();

  return (
    <Accordion expanded={expand} onChange={()=> {setExpand(!expand);}} classes={{ root: classNames(props.classes.accordionStyle) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <h2>General Information</h2>
      </AccordionSummary>
      <AccordionDetails>
        {props.policiesGeneralInfoLoading && <Spinner />}
        {!props.policiesGeneralInfoLoading && (
          <div className={props.classes.contractsGrid}>
            <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
              <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
                <TextField
                  className={props.classes.textField}
                  variant="outlined"
                  fullWidth
                  label={'US Mileage Reimbursement Rate'}
                  onChange={(e) => setClientUSMileageRatesTypeId(e.target.value)}
                  required
                  select
                  value={clientUSMileageRatesTypeId}
                >
                  {props.metadata.clientUSMileageRatesTypes.map((o) => 
                    <MenuItem value={o.id}>{o.description}</MenuItem>,
                  )}
                </TextField>
                {clientUSMileageRatesTypeId === 1 && (
                  <TextField
                    id="name"
                    label="Other US Rate"
                    className={props.classes.textField}
                    variant="outlined"
                    style={{ width: '100%', marginRight: 4 }}
                    value={clientUSMileageRateOther}
                    onChange={(e) => setClientUSMileageRateOther(e.target.value)}
                  />
                )}
                <TextField
                  className={props.classes.textField}
                  variant="outlined"
                  fullWidth
                  label={'Canadian Mileage Reimbursement Rate'}
                  onChange={(e) => setClientCanadaMileageRatesTypeId(e.target.value)}
                  required
                  select
                  value={clientCanadaMileageRatesTypeId}
                >
                  {props.metadata.clientCanadaMileageRatesTypes.map((o) => 
                    <MenuItem value={o.id}>{o.description}</MenuItem>,
                  )}
                </TextField>
                {clientCanadaMileageRatesTypeId === 1 && (
                  <TextField
                    id="name"
                    label="Other Canada Rate"
                    className={props.classes.textField}
                    variant="outlined"
                    style={{ width: '100%', marginRight: 4 }}
                    value={clientCanadaMileageRateOther}
                    onChange={(e) => setClientCanadaMileageRateOther(e.target.value)}
                  />
                )}
                <TextField
                  id="name"
                  label="Are receipts mandatory for all items?"
                  className={props.classes.textField}
                  variant="outlined"
                  style={{ width: '100%', marginRight: 4 }}
                  value={receiptsMandatory}
                  onChange={(e) => setReceiptsMandatory(e.target.value)}
                />
              </div>

              <div className={props.classes.contentCol}>
                <TextField
                  className={props.classes.textField}
                  variant="outlined"
                  fullWidth
                  label={'Is Alcohol Reimbursable?'}
                  onChange={(e) => setAlcoholReimbursable(e.target.value)}
                  required
                  select
                  value={alcoholReimbursable}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
                <TextField
                  id="name"
                  label="How does a client refer to employees?"
                  className={props.classes.textField}
                  variant="outlined"
                  style={{ width: '100%', marginRight: 4 }}
                  value={clientReferral}
                  onChange={(e) => setClientReferral(e.target.value)}
                />
                <TextField
                  id="name"
                  label="Agent Referral Rebate"
                  className={props.classes.textField}
                  variant="outlined"
                  style={{ width: '100%', marginRight: 4 }}
                  value={agentReferralRebate}
                  onChange={(e) => setAgentReferralRebate(e.target.value)}
                />
              </div>
            </form>
            <div className={props.classes.buttonContainer}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {updatePoliciesInfo();}}
              >
                Save Info
              </Button>
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );  
};

GeneralInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  const { policiesGeneralInfo } = state.clients.clientsInfo[state.clients.currentClientId];
  const { metadata, policiesGeneralInfoLoading } = state.clients;
  return {
    metadata,
    policiesGeneralInfo,
    policiesGeneralInfoLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientPoliciesGeneralInfo,
    updateClientPoliciesGeneralInfo,
  }),
)(GeneralInformation);