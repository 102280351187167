/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import {
  DELETE_INSPECTORS_FAILURE,
  GET_INSPECTORS_FAILURE,
  POST_INSPECTORS_FAILURE,
  UPDATE_INSPECTORS_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteInspector, getInspectors, postInspector, updateInspector } from 'modules/authorizations/store/actions/dataManagement.actions';
import { memoize } from 'lodash';
import CrudTable from '../clients/details/crudTable.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import classNames from 'classnames';
import countries from 'modules/common/countries.json';
import states from 'modules/common/states.json';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const InspectorsTable = (props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    let allStates = states.filter((state) => state.countryCode === useCountryCode).map((state) => state.description);
    return allStates;
  });

  const renderCountryOptions = memoize(() => {
    let allCountries = countries.map((country) => country.description);
    return allCountries;
  });

  const adjustedColumns = [
    { accessorKey: 'firstName', header: 'Contact First Name', type: 'text', required: false, size: 50 },
    { accessorKey: 'lastName', header: 'Contact Last Name', type: 'text', required: false, size: 50 },
    { accessorKey: 'companyName', header: 'Company Name', type: 'text', required: true, size: 70 },
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 70 },
    { accessorKey: 'city', header: 'City', type: 'text', required: true, size: 50 },
    { accessorKey: 'state', header: 'State', type: 'dropdown', options: renderStateOptions(''), required: true, size: 50 },
    { accessorKey: 'zip', header: 'Zip Code', type: 'text', required: true, size: 50, hideInTable: true },
    {
      accessorKey: 'country',
      header: 'Country',
      type: 'dropdown',
      options: renderCountryOptions(),
      required: true,
      size: 50,
      hideInTable: true,
    },
    { accessorKey: 'phone', header: 'Phone Number', type: 'text', required: false, size: 50, hideInTable: true },
    //TODO - we should update this to a select of existing companies
    { accessorKey: 'vendorId', header: 'Vendor Id', type: 'number', required: false, size: 50, hideInTable: true },
    { accessorKey: 'citiesServed', header: 'Cities Served', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'zipsServed', header: 'Zip Codes Served', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'fax', header: 'Contact fax', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'inspectorPhone', header: 'Contact Phone Number', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'inspectorEmail', header: 'Contact Email', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'standardFee', header: 'Standard Fee', type: 'money', required: false, size: 50, hideInTable: true },
    { accessorKey: 'comments', header: 'Comments', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'inspectorCode', header: 'InspectorCode', type: 'text', required: false, size: 50, hideInTable: true },
  ];

  const updateRow = async (orgRow, newRow) => {
    newRow.inspectorId = orgRow.original.inspectorId;

    const checkValues = {
      ...newRow,
      standardFee: !newRow?.standardFee || newRow?.standardFee === '' ? null : newRow?.standardFee,
      vendorId: !newRow?.vendorId || newRow?.vendorId === '' ? 0 : newRow?.vendorId,
      inspectorId: orgRow.original.inspectorId,
    };

    const resp = await props.updateInspector(checkValues);
    if (resp.type === UPDATE_INSPECTORS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getInspectors();
      if (getResp.type === GET_INSPECTORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    const checkValues = {
      ...values,
      standardFee: !values?.standardFee || values?.standardFee === '' ? null : values?.standardFee,
      vendorId: !values?.vendorId || values?.vendorId === '' ? 0 : values?.vendorId,
    };

    const resp = await props.postInspector(checkValues);
    if (resp.type === POST_INSPECTORS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getInspectors();
      if (getResp.type === GET_INSPECTORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (rowToDelete) => {
    const id = rowToDelete.original.inspectorId;
    const resp = await props.deleteInspector(id);
    if (resp.type === DELETE_INSPECTORS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getInspectors();
      if (getResp.type === GET_INSPECTORS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
      classes={{ root: classNames(props.classes.accordionStyleContacts) }}
    >
      <AccordionSummary
        expandIcon={
          <Box color={theme.palette.secondary.main}>
            <ExpandMoreIcon color="inherit" />
          </Box>
        }
      >
        <div className={props.classes.newRow}>
          <h2>Inspectors</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.isInspectorsLoading && props.inspectors && (
          <div>
            <CrudTable
              editingMode={'modal'} // Can be changed to 'row' to edit inline
              columns={adjustedColumns}
              rows={props.inspectors}
              updateRow={updateRow}
              createRow={createRow}
              deleteRow={deleteRow}
            />
          </div>
        )}
        {props.isInspectorsLoading && <Spinner />}
      </AccordionDetails>
    </Accordion>
  );
};

InspectorsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { inspectors, isInspectorsLoading } = state.authorizations;
  return {
    inspectors,
    isInspectorsLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    updateInspector,
    deleteInspector,
    postInspector,
    getInspectors,
  }),
)(InspectorsTable);
