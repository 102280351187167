/* eslint-disable no-case-declarations */
import {
  GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS,
  GET_APPRAISALS_SUCCESS,
  GET_CREDIT_SUCCESS,
  GET_EQUITY_SUCCESS,
  GET_HOMESALE_INFO_SUCCESS,
  GET_INSPECTIONS_SUCCESS,
  GET_LISTINGS_SUCCESS,
  GET_TAX_SUCCESS,
  POST_HOMESALE_INFO_SUCCESS,
  POST_INSPECTIONS_SUCCESS,
  UPDATE_APPRAISALS_SUCCESS,
  UPDATE_EQUITY_SUCCESS,
  UPDATE_HOMESALE_INFO_SUCCESS,
  UPDATE_HOMESALE_RESALE_OFFER_SUCCESS,
  UPDATE_INSPECTIONS_STATE,
  UPDATE_INSPECTIONS_SUCCESS,
  UPDATE_LISTINGS_SUCCESS,
} from '../types/homesaleTypes';

const handleHomesaleReducer = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId].authHomesale)
    draft.authorizationDetails[draft.currentAuthId].authHomesale = {};

  switch (action.type) {
    case POST_HOMESALE_INFO_SUCCESS:
    case UPDATE_HOMESALE_INFO_SUCCESS:
    case GET_HOMESALE_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.homesale = action.response;
      break;

    case GET_APPRAISALS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.appraisalsBmas = action.response;
      break;

    case UPDATE_APPRAISALS_SUCCESS:
      let tempAppraisals = draft.authorizationDetails[draft.currentAuthId].authHomesale.appraisalsBmas;
      const tempAppraisalIndex = tempAppraisals.findIndex((e) => e.appraisalId === action.response.appraisalId);
      tempAppraisals[tempAppraisalIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].authHomesale.appraisalsBmas = tempAppraisals;
      break;

    case GET_INSPECTIONS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.inspections = action.response;
      break;

    case UPDATE_INSPECTIONS_SUCCESS: {
      const inspections = draft.authorizationDetails[draft.currentAuthId]?.authHomesale.inspections;
      if (inspections) {
        const indexToUpdate = inspections.findIndex((i) => i.inspectionId === action.response?.inspectionId);
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].authHomesale.inspections[indexToUpdate] = action.response;
        }
      }
      break;
    }

    case POST_INSPECTIONS_SUCCESS: {
      const inspections = draft.authorizationDetails[draft.currentAuthId]?.inspections || [];
      draft.authorizationDetails[draft.currentAuthId].authHomesale.inspections = [...inspections, action.response];
      break;
    }

    case UPDATE_INSPECTIONS_STATE:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.inspections = action.response;
      break;

    case GET_LISTINGS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.listings = action.response;
      break;

    case UPDATE_LISTINGS_SUCCESS:
      let tempListings = draft.authorizationDetails[draft.currentAuthId].authHomesale.listings;
      const tempListingIndex = tempListings.findIndex((e) => e.listingId === action.response.listingId);
      tempListings[tempListingIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].authHomesale.listings = tempListings;
      break;

    case GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.allHomesaleResaleOffers = action.response;
      break;

    case UPDATE_HOMESALE_RESALE_OFFER_SUCCESS:
      let tempOffers = draft.authorizationDetails[draft.currentAuthId].authHomesale.allHomesaleResaleOffers;
      const tempOfferIndex = tempOffers.findIndex((s) => s.offerId === action.response.offerId);
      tempOffers[tempOfferIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].authHomesale.allHomesaleResaleOffers = tempOffers;
      break;

    case GET_EQUITY_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.equities = action.response;
      break;

    case UPDATE_EQUITY_SUCCESS:
      let tempEquities = draft.authorizationDetails[draft.currentAuthId].authHomesale.equities;
      const tempEquitiesIndex = tempEquities.findIndex((e) => e.equityId === action.response.equityId);
      tempEquities[tempEquitiesIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].authHomesale.equities = tempEquities;
      break;

    case GET_TAX_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.equityTaxes = action.response;
      break;

    case GET_CREDIT_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authHomesale.equityCredits = action.response;
      break;

    default:
      break;
  }

  return draft;
};

export default handleHomesaleReducer;
