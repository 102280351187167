/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Grid, MenuItem, Tab, Tabs, TextField, withStyles } from '@material-ui/core';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_PERIOD_FAILURE, UPDATE_PERIOD_SUCCESS } from 'modules/clients/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getCalendarPeriods, updatePeriod } from 'modules/clients/clients.actions';
import { parsePayrollEvents } from './utils';
import { showToast } from 'modules/layout/layout.actions';
import AnnualDates from './annualDates.component';
import Hint from 'modules/common/hint.component';
import PeriodDates from './periodDates.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

const styles = (theme) => ({
  root: {
    minWidth: 200,
  },
  tab: {
    width: 160,
    minWidth: 160,
  },
  tabsRoot: {
    marginBottom: theme.spacing(1),
  },
  textFieldExt: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
});  

const localizer = momentLocalizer(moment);

const CalendarComponent = (props) => {
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedPeriodData, setSelectedPeriodData] = useState(null);
  const [focusedTab, setFocusedTab] = useState('ANNUAL DATES');

  const updatePeriod = async (values) => {
    const resp = await props.updatePeriod(values);
    if (resp.type === UPDATE_PERIOD_FAILURE) {
      props.showToast('Failed to update the specified period, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else if (resp.type === UPDATE_PERIOD_SUCCESS) {
      props.reloadPeriodUpdate(values.clientPayrollCalendarId, values.clientPayrollPeriodId);
    }
  };

  const payrollEvents = (props.calendarData && props.periodData) ? parsePayrollEvents(props.calendarData, props.periodData) : [];

  useEffect(() => {
    setFocusedTab(props.initialFocusedTab);
    setSelectedPeriod(props.initialPeriod);
    if (props.periodData && props.initialPeriod) setSelectedPeriodData(props.periodData.find((o) => o.clientPayrollPeriodId === props.initialPeriod));
  }, [props.initialPeriodData, props.initialPeriod, props.initialFocusedTab]);

  return (
    <Box marginX={2} marginY={2}>
      <Grid container>
        <Grid xs={6}>
          <Tabs
            variant="standard"
            value={focusedTab}
            onChange={(event, tab) => setFocusedTab(tab)}
            classes={{
              root: classNames(props.classes.tabsRoot),
            }}
          >
            <Tab value={'ANNUAL DATES'} label="Annual Info" classes={{ root: classNames(props.classes.tab) }} />
            <Tab value={'PERIOD DATES'} label="Period Info" classes={{ root: classNames(props.classes.tab) }} />
          </Tabs>

          {focusedTab === 'ANNUAL DATES' && (
            <AnnualDates calendarData={props.calendarData} updateCalendar={props.updateCalendar} />
          )}

          {focusedTab === 'PERIOD DATES' && (
            <div>
              <TextField
                select
                label="Select Period"
                className={props.classes.textFieldExt}
                value={selectedPeriod}
                onChange={(e) => { setSelectedPeriod(e.target.value); setSelectedPeriodData(props.periodData.find((o) => o.clientPayrollPeriodId === e.target.value));}}
                SelectProps={{ autoWidth: true }}
                variant="outlined"
              >
                {props.periodData && props.periodData.map((c, i) => {
                  return (
                    <MenuItem value={c.clientPayrollPeriodId}>
                      {`Period ${i + 1} (${c.periodStartDate.split('T')[0]} to ${c.periodEndDate.split('T')[0]})`}
                    </MenuItem>
                  );
                })}
              </TextField>

              {!selectedPeriodData && <Hint>Select a Period from the dropdown to view and edit data.</Hint>}
              {selectedPeriodData && <PeriodDates selectedPeriodData={selectedPeriodData} updatePeriod={updatePeriod} />}
            </div>
          )}
        </Grid>
        <Grid xs={6}>
          <Calendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, marginTop: 20 }}
            views={['month', 'week']}
            events={payrollEvents}
            eventPropGetter={(event) => {
              const eventData = payrollEvents.find((o) => o.id === event.id);
              const backgroundColor = eventData && eventData.color;
              return { style: { backgroundColor } };
            }}
          />
          <br /><Hint>Calendar is for display purposes only, to modify a date use the fields on the left.</Hint>
        </Grid>
      </Grid>
    </Box>
  );
};

CalendarComponent.propTypes = {
  calendarData: PropTypes.object,
  periodData: PropTypes.array,
  selectedPeriodId: PropTypes.number,
};


// const mapStateToProps = (state) => {
//   const { clientCalendars, isLoading, periodData } = state.clients;
//   return {
//     clientCalendars,
//     isLoading,
//     periodData,
//   };
// };
  
export default compose(
  withStyles(styles),
  connect(null, {
    updatePeriod,
    getCalendarPeriods,
    showToast,
  }),
)(CalendarComponent);