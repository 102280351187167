/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import { Button, MenuItem, TextField, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { GET_CALENDAR_PERIODS_SUCCESS, GET_CLIENT_CALENDARS_SUCCESS, UPDATE_CLIENT_CALENDAR_FAILURE, UPDATE_CLIENT_CALENDAR_SUCCESS } from 'modules/clients/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { generateInitialValues } from './utils';
import { getCalendarPeriods, getClientCalendars, updateClientCalendar } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import CalendarComponent from './calendar.component';
import Hint from 'modules/common/hint.component';
import NewCalendar from './newCalendar.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 150,
  },
  textFieldExt: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  dropdownItem: {
    minWidth: 150,
  },
});  

const PayrollContainer = (props) => {
  const [newCalendarView, setNewCalendarView] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const clientId = props.formikProps.values.moveTrackClientId;

  const [newCalendarValues, setNewCalendarValues] = useState(generateInitialValues(clientId));

  const [initialFocusedTab, setInitialFocusedTab] = useState('ANNUAL DATES');
  const [initialPeriod, setInitialPeriod] = useState(null);
  const [initialPeriodData, setInitialPeriodData] = useState(null);

  const reloadYears = async (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    if (year) await props.getClientCalendars(clientId, year);
  };

  const reloadCalendars = async (e) => { 
    const calendar = e.target.value;
    setSelectedCalendar(calendar);
    if (calendar) await props.getCalendarPeriods(calendar);
  };

  const newCalendarCreated = async (year, id) => {
    setNewCalendarView(false);
    if (id && year) {
      const resp = await props.getClientCalendars(clientId, year);
      if (resp.type === GET_CLIENT_CALENDARS_SUCCESS) {
        setSelectedYear(year);
        setSelectedCalendar(id);
        await props.getCalendarPeriods(id);
      }
    }
  };

  const updateCalendar = async (values) => {
    const resp = await props.updateClientCalendar(values);
    if (resp.type === UPDATE_CLIENT_CALENDAR_FAILURE) {
      props.showToast('Failed to update the specified calendar, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else if (resp.type === UPDATE_CLIENT_CALENDAR_SUCCESS) {
      if (clientId) await props.getClientCalendars(clientId, values.calendarYear);
      setSelectedCalendar(values.clientPayrollCalendarId);
      await props.getCalendarPeriods(values.clientPayrollCalendarId);
    }
  };

  const reloadPeriodUpdate = async (calendarId, periodId) => {
    const getResp = await props.getCalendarPeriods(calendarId);
    if (getResp.type === GET_CALENDAR_PERIODS_SUCCESS && props.calendarPeriods && !props.isLoadingCalendars) {
      setInitialFocusedTab('PERIOD DATES');
      setInitialPeriod(periodId); 
      setInitialPeriodData(props.calendarPeriods.find((o) => o.clientPayrollPeriodId === periodId));
    }
  };

  useEffect(async () => {
    if (clientId) await props.getClientCalendars(clientId, new Date().getFullYear());
  }, []);

  return (
    <Box marginX={2} marginY={4}>
      {!newCalendarView && (
        <>
          <h2>Payroll Calendars</h2>
          <div className={props.classes.headerContainer}>
            <TextField
              select
              label="Select Year"
              className={props.classes.textField}
              value={selectedYear}
              onChange={reloadYears}
              SelectProps={{ autoWidth: true }}
              variant="outlined"
            >
              {['2022', '2023', '2024'].map((y) => {
                return (
                  <MenuItem value={y} className={props.classes.dropdownItem}>
                    {y}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              select
              label="Select Calendar"
              className={props.classes.textFieldExt}
              value={selectedCalendar}
              onChange={reloadCalendars}
              SelectProps={{ autoWidth: true }}
              variant="outlined"
            >
              {props.clientCalendars && props.clientCalendars.map((c) => {
                return (
                  <MenuItem value={c.clientPayrollCalendarId}>
                    {`${c.name} (${c.periodFrequency.description})`}
                  </MenuItem>
                );
              })}
            </TextField>
            <Button variant="contained" color="primary" onClick={() => setNewCalendarView(true)}>New Calendar</Button>
          </div>
          {!props.calendarPeriods && <Hint>Select a Calendar to view the results below.</Hint>}

          {props.isLoadingCalendars && <Spinner />}

          {!props.isLoadingCalendars && props.calendarPeriods && (
            <CalendarComponent 
              calendarData={props.clientCalendars.find((c) => c.clientPayrollCalendarId === selectedCalendar)} 
              periodData={props.calendarPeriods} 
              updateCalendar={updateCalendar}
              reloadPeriodUpdate={reloadPeriodUpdate}
              initialPeriod={initialPeriod}
              initialPeriodData={initialPeriodData}
              initialFocusedTab={initialFocusedTab}
            />
          )}
        </>
      )}
      {newCalendarView && (
        <Formik
          initialValues={newCalendarValues}
          validate={(e) => { setNewCalendarValues(e); }}
          // onSubmit={handleSaveRequest}
          enableReinitialize
          validateOnBlur={true}
          validateOnChange={true}
        >
          {(formikProps) => {
            return (
              <NewCalendar clientId={clientId} formikProps={formikProps} newCalendarCreated={newCalendarCreated} />
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

PayrollContainer.propTypes = {
  formikProps: PropTypes.object.isRequired,
  clientCalendars: PropTypes.array,
  isLoadingCalendars: PropTypes.bool,
  calendarPeriods: PropTypes.array,
};


const mapStateToProps = (state) => {
  const { clientCalendars, calendarPeriods } = state.clients.clientsInfo[state.clients.currentClientId];
  const { isLoadingCalendars } = state.clients;
  return {
    clientCalendars,
    isLoadingCalendars,
    calendarPeriods,
  };
};
  
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getClientCalendars,
    getCalendarPeriods,
    updateClientCalendar,
    showToast,
  }),
)(PayrollContainer);