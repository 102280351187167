export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';

export const SHOW_SIDE_DRAWER = 'SHOW_SIDE_DRAWER';
export const HIDE_SIDE_DRAWER = 'HIDE_SIDE_DRAWER';

export const GET_API_VERSION_REQUEST = 'GET_API_VERSION_REQUEST';
export const GET_API_VERSION_SUCCESS = 'GET_API_VERSION_SUCCESS';
export const GET_API_VERSION_FAILURE = 'GET_API_VERSION_FAILURE';

export const TOAST_MESSAGE_SEVERITY_ERROR = 'error';
export const TOAST_MESSAGE_SEVERITY_WARNING = 'warning';
export const TOAST_MESSAGE_SEVERITY_INFO = 'info';
export const TOAST_MESSAGE_SEVERITY_SUCCESS = 'success';

export const GET_API_V2_TEST_REQUEST = 'GET_API_VERSION_REQUEST';
export const GET_API_V2_TEST_SUCCESS = 'GET_API_VERSION_SUCCESS';
export const GET_API_V2_TEST_FAILURE = 'GET_API_VERSION_FAILURE';