import { Checkbox, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { DELETE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, POST_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE } from '../../types';
import { deleteHomesaleEligibleProperties, getHomesaleEligibleProperties, postHomesaleEligibleProperties, updateHomesaleEligibleProperties } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const HomeSaleEligiblePropertiesTable = (props) => {

  const adjustedColumns = [
    { accessorKey: 'propertyType', header: 'Property Type', type: 'text', required: true, size: 70 },
    { accessorKey: 'eligibleForBVO', header: 'Eligible for BVO', type: 'checkbox', defaultVal: false, required: true, size: 70, Cell: ({ cell }) => <Checkbox name="eligibleForBVO" label="Active" checked={cell.row.original.eligibleForBVO} disabled={true} /> },
    { accessorKey: 'eligibleForGBO', header: 'Eligible for GBO', type: 'checkbox', defaultVal: false, required: true, size: 70, Cell: ({ cell }) => <Checkbox name="eligibleForGBO" label="Active" checked={cell.row.original.eligibleForGBO} disabled={true} /> },
    { accessorKey: 'notes', header: 'Notes', type: 'notes', required: true, size: 50 },
  ];

  const updateRow = async (eligibleProperty, values) => {
    const id = eligibleProperty.original.clientHomeSaleEligiblePropertiesKey;
    values.clientHomeSaleEligiblePropertiesKey = id;
    values.clientId = props.clientId;
    const resp = await props.updateHomesaleEligibleProperties(values);
    if (resp.type === UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleEligibleProperties(props.clientId);
      if (getResp.type === GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postHomesaleEligibleProperties(values);
    if (resp.type === POST_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleEligibleProperties(props.clientId);
      if (getResp.type === GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (eligibleProperty) => {
    const id = eligibleProperty.original.clientHomeSaleEligiblePropertiesKey;
    const resp = await props.deleteHomesaleEligibleProperties(id);
    if (resp.type === DELETE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleEligibleProperties(props.clientId);
      if (getResp.type === GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      {!props.clientHomesaleEligiblePropertiesLoading && (
        <CrudTable 
          editingMode={'modal'} // Can be changed to 'row' to edit inline 
          columns={adjustedColumns} 
          rows={props.clientHomesaleEligibleProperties} 
          updateRow={updateRow} 
          createRow={createRow} 
          deleteRow={deleteRow} 
        />
      )}
      {props.clientHomesaleEligiblePropertiesLoading && (
        <Spinner />
      )}
    </>
  );
}; 

HomeSaleEligiblePropertiesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientHomesaleEligibleProperties } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleEligiblePropertiesLoading } = state.clients;
  return {
    clientHomesaleEligibleProperties,
    clientHomesaleEligiblePropertiesLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getHomesaleEligibleProperties,
    deleteHomesaleEligibleProperties,
    postHomesaleEligibleProperties,
    updateHomesaleEligibleProperties,
  }),
)(HomeSaleEligiblePropertiesTable);