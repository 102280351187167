import { DELETE_FAMILY_FAILURE, GET_FAMILY_FAILURE, POST_FAMILY_FAILURE, UPDATE_FAMILY_FAILURE } from '../../../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

export const useFamilyHandler = ({ postFamilyMember, updateFamilyMember, getFamilyMembers, deleteFamilyMember, showToast }) => {
  const handleCreateFamilyMember = async (values) => {
    try {
      const resp = await postFamilyMember(values);
      if (resp.type === POST_FAMILY_FAILURE) {
        showToast('Failed to create family member, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while creating family member.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleUpdateFamilyMember = async (values) => {
    try {
      const resp = await updateFamilyMember(values);
      if (resp.type === UPDATE_FAMILY_FAILURE) {
        showToast('Failed to update family member, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while updating family member.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleGetFamilyMembers = async (authId) => {
    try {
      const resp = await getFamilyMembers(authId);
      if (resp.type === GET_FAMILY_FAILURE) {
        showToast('Failed to get family members, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while fetching family members.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  const handleDeleteFamilyMember = async (authId, familyMemberId) => {
    try {
      const resp = await deleteFamilyMember(authId, familyMemberId);
      if (resp.type === DELETE_FAMILY_FAILURE) {
        showToast('Failed to delete family member, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      }
      return true;
    } catch (error) {
      showToast('An unexpected error occurred while deleting family member.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return {
    handleCreateFamilyMember,
    handleUpdateFamilyMember,
    handleGetFamilyMembers,
    handleDeleteFamilyMember,
  };
};
