import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_ESTIMATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const StorageVendorInfoCard = (props) => {

  const { segmentInfo, vendors, vendorContacts } = props;

  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    { header: 'Vendor Info', order: 1, iconPath: ['fas', 'person-walking-luggage'] },
  ];

  const vendorSearchInfo = {
    searchingColumns: [{ name: 'vendorId', title: 'Vendor Id' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }, { name: 'phone', title: 'Company Phone' }, { name: 'email', title: 'Company Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorId', width: 150 }, { columnName: 'companyName', width: 250 }, { name: 'city', width: 175 }, { name: 'state', width: 175 }, { columnName: 'phone', width: 175 }, { columnName: 'email', width: 175 }],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName',
  };

  const vendorContactSearchInfo = {
    searchingColumns: [{ name: 'vendorContactId', title: 'Vendor Contact Id' }, { name: 'vendorId', title: 'Vendor Id' }, { name: 'fullName', title: 'Full Name' }, { name: 'officePhone', title: 'Contact Phone' }, { name: 'officeEmail', title: 'Contact Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorContactId', width: 150 }, { columnName: 'vendorId', width: 150 }, { columnName: 'fullName', width: 200 }, { columnName: 'officePhone', width: 175 }, { columnName: 'officeEmail', width: 175 }],
    searchingRows: vendorContacts,
    idKey: 'vendorContactId',
    nameKey: 'fullName',
  };

  const summaries = [
    { header: 'Vendor Name', accessorKey: 'storageVendorId', nameKey: 'companyName', nameValue: segmentInfo ? segmentInfo.storageVendorCompanyName : '', type: 'advanced-search', searchInfo: vendorSearchInfo, required: true,  value: segmentInfo ? segmentInfo.storageVendorId : '', tab: 1, hideInTable: true },
    { header: 'Vendor Contact', accessorKey: 'storageVendorContactId', nameKey: 'fullName', nameValue: segmentInfo ? segmentInfo.storageVendorContactName: '',  type: 'advanced-search', searchInfo: vendorContactSearchInfo, required: true,  value: segmentInfo ? segmentInfo.storageVendorContactId : '', tab: 1, hideInTable: true },
    { header: 'Company Name', accessorKey: 'storageVendorCompanyName', type: 'text',  required: false, value: segmentInfo ? segmentInfo.storageVendorCompanyName : '', tab: 1, hideInModal: true },
    { header: 'Company Phone', accessorKey: 'storageVendorPhone', type: 'text', required: false, value: segmentInfo ? segmentInfo.storageVendorPhone : '', tab: 1, hideInModal: true },
    { header: 'Company Email', accessorKey: 'storageVendorEmail', type: 'text', required: false, value: segmentInfo ? segmentInfo.storageVendorEmail : '', tab: 1, hideInModal: true },
    { header: 'Contact Name', accessorKey: 'storageVendorContactName', type: 'text', required: false,  value: segmentInfo ? segmentInfo.storageVendorContactName : '', tab: 1, hideInModal: true },
    { header: 'Contact Email', accessorKey: 'storageVendorContactEmail', type: 'text', required: false,  value: segmentInfo ? segmentInfo.storageVendorContactEmail : '', tab: 1, hideInModal: true },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };
    
    if (values.storageVendorId != null) {
      const newVendor = vendors.find((v) => v.vendorId === values.storageVendorId);
      newValues.storageVendorId = newVendor.vendorId;
      newValues.storageVendorCompanyName = newVendor.companyName;
      newValues.storageVendorPhone = newVendor.phone;
      newValues.storageVendorEmail = newVendor.email;
    }

    if (values.storageVendorContactId != null) {
      const newVendorContact = vendorContacts.find((v) => v.vendorContactId === values.storageVendorContactId);
      newValues.storageVendorContactId = newVendorContact.vendorContactId;
      newValues.storageVendorContactName = newVendorContact.fullName;
      newValues.storageVendorContactEmail = newVendorContact.officeEmail;
    }
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_ESTIMATE_FAILURE) {
        props.showToast('Failed to update the storage vendor info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      smallerModal={true}
      hideEdit={props.hideEdit}
    />
  );

};

const mapStateToProps = (state) => {
  const {
    authorizations: { vendors, vendorContacts },
  } = state;
  return { vendors, vendorContacts };
};

export default compose(
  connect(mapStateToProps, {
    updateHhgSegment,
    showToast,
  }),
)(StorageVendorInfoCard);