import { FormTypes } from '../globalDestServiceConstants';
import {
  POST_GLOBAL_DESTINATION_SERVICES_FAILURE,
  POST_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  POST_GLOBAL_LANGUAGE_TRAINING_FAILURE,
} from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  postGlobalDestinationServices,
  postGlobalInternationalSchooling,
  postGlobalLanguageTraining,
} from 'modules/authorizations/store/actions/global.actions';
import { useDispatch } from 'react-redux';
  

export const useCreateDataHandler = (showToast) => {
  const dispatch = useDispatch();

  const handleCreateDestinationServiceData = async (form) => {
    const response = await dispatch(postGlobalDestinationServices(form));
    if (response.type === POST_GLOBAL_DESTINATION_SERVICES_FAILURE) {
      showToast('Failed creating destination service, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const handleCreateLanguageTrainingData = async (form) => {
    const response = await dispatch(postGlobalLanguageTraining(form));
    if (response.type === POST_GLOBAL_LANGUAGE_TRAINING_FAILURE) {
      showToast('Failed creating language training, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const handleCreateInternationalSchoolingData = async (form) => {
    const response = await dispatch(postGlobalInternationalSchooling(form));
    if (response.type === POST_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE) {
      showToast('Failed creating international schooling, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
    return true;
  };

  const createData = async (formType, form) => {
    try {
      switch (formType) {
        case FormTypes.DestinationService:
          return await handleCreateDestinationServiceData(form);
        case FormTypes.LanguageTraining:
          return await handleCreateLanguageTrainingData(form);
        case FormTypes.InternationalSchooling:
          return await handleCreateInternationalSchoolingData(form);
        default:
          console.error('Not a valid form type.');
          return false;
      }
    } catch (error) {
      showToast('Failed creating data, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return false;
    }
  };

  return { createData };
};
