import { OfferSentByOptions, OfferSentToOptions, SaleTypeOptions, TitleRepSearchInfo as titleRepSearchInfo } from './buyoutConstants';

export const CreateApprovalAndAcceptanceFormFields = (homeSaleData) => {
  return {
    tabs: [
      { header: 'Approval', order: 1, iconPath: ['fas', 'house'] },
      { header: 'Acceptance', order: 2, iconPath: ['fas', 'money-bill-transfer'] },
    ],
    summaries: [
      {
        header: 'Sale Type',
        accessorKey: 'approvalSaleType',
        type: 'dropdown',
        options: SaleTypeOptions,
        requried: false,
        value: homeSaleData?.approvalSaleType,
        tab: 1,
      },
      {
        header: 'Appraised Value',
        accessorKey: 'appraisedValue',
        type: 'money',
        requried: false,
        value: homeSaleData?.appraisedValue,
        tab: 1,
      },
      {
        header: 'Offer Amount',
        accessorKey: 'offerAmount',
        type: 'money',
        requried: false,
        value: homeSaleData?.offerAmount,
        tab: 1,
      },
      {
        header: 'Offer Date',
        accessorKey: 'offerDate',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerDate,
        tab: 1,
      },
      {
        header: 'Offer Valid',
        accessorKey: 'offerValid',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerValid,
        tab: 1,
      },
      {
        header: 'Client Approved Date',
        accessorKey: 'clientApprovedDate',
        type: 'date',
        requried: false,
        value: homeSaleData?.clientApprovedDate,
        tab: 1,
      },
      {
        header: 'Client Approved By',
        accessorKey: 'clientApprovedBy',
        type: 'text',
        requried: false,
        value: homeSaleData?.clientApprovedBy,
        tab: 1,
      },
      {
        header: 'Approval Concessions',
        accessorKey: 'approvalConcessions',
        type: 'date',
        requried: false,
        value: homeSaleData?.approvalConcessions,
        tab: 1,
      },
      {
        header: 'Offer Sent By',
        accessorKey: 'offerSentBy',
        type: 'dropdown',
        options: OfferSentByOptions,
        requried: false,
        value: homeSaleData?.offerSentBy,
        tab: 1,
      },
      {
        header: 'Offer Sent To',
        accessorKey: 'offerSentTo',
        type: 'dropdown',
        options: OfferSentToOptions,
        requried: false,
        value: homeSaleData?.offerSentTo,
        tab: 1,
      },
      {
        header: 'Offer Sent',
        accessorKey: 'offerSent',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerSent,
        tab: 1,
      },
      {
        header: 'Initial Bill Date',
        accessorKey: 'initialBillDate',
        type: 'date',
        requried: false,
        value: homeSaleData?.initialBillDate,
        tab: 1,
      },
      {
        header: 'Offer Received',
        accessorKey: 'offerReceived',
        type: 'checkbox',
        requried: false,
        value: homeSaleData?.offerReceived,
        tab: 1,
      },
      {
        header: 'Contingencies',
        accessorKey: 'contingencies',
        type: 'checkbox',
        requried: false,
        value: homeSaleData?.contingencies,
        tab: 1,
      },
      {
        header: 'Subject To',
        accessorKey: 'subjectTo',
        type: 'text',
        requried: false,
        value: homeSaleData?.subjectTo,
        tab: 1,
      },

      {
        header: 'Amd. Val. Acct. Amount',
        accessorKey: 'amdValAcctAmount',
        type: 'money',
        requried: false,
        value: homeSaleData?.amdValAcctAmount,
        tab: 2,
      },
      {
        header: 'Offer Accepted Amount',
        accessorKey: 'offerAcceptedAmount',
        type: 'money',
        requried: false,
        value: homeSaleData?.offerAcceptedAmount,
        tab: 2,
      },
      {
        header: 'Offer Accepted',
        accessorKey: 'offerAccepted',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerAccepted,
        tab: 2,
      },
      {
        header: 'Offer Verbally Accepted',
        accessorKey: 'offerVerballyAccepted',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerVerballyAccepted,
        tab: 2,
      },
      {
        header: 'Directed Equity',
        accessorKey: 'directedEquity',
        type: 'text',
        requried: false,
        value: homeSaleData?.directedEquity,
        tab: 2,
      },
      {
        header: 'Concessions',
        accessorKey: 'acceptanceConcessions',
        type: 'text',
        requried: false,
        value: homeSaleData?.acceptanceConcessions,
        tab: 2,
      },
      {
        header: 'Home Warranty',
        accessorKey: 'homeWarranty',
        type: 'text',
        requried: false,
        value: homeSaleData?.homeWarranty,
        tab: 2,
      },
      {
        header: 'Repairs',
        accessorKey: 'repairs',
        type: 'text',
        requried: false,
        value: homeSaleData?.repairs,
        tab: 2,
      },
      {
        header: 'Scheduled Buyout',
        accessorKey: 'scheduledBuyout',
        type: 'date',
        requried: false,
        value: homeSaleData?.scheduledBuyout,
        tab: 2,
      },
      {
        header: 'Actual Buyout',
        accessorKey: 'actualBuyout',
        type: 'date',
        requried: false,
        value: homeSaleData?.actualBuyout,
        tab: 2,
      },
      {
        header: 'Proration',
        accessorKey: 'proration',
        type: 'date',
        requried: false,
        value: homeSaleData?.proration,
        tab: 2,
      },
      {
        header: 'Possession',
        accessorKey: 'possession',
        type: 'date',
        requried: false,
        value: homeSaleData?.possession,
        tab: 2,
      },
      {
        header: 'Insure',
        accessorKey: 'insure',
        type: 'date',
        requried: false,
        value: homeSaleData?.insure,
        tab: 2,
      },
      {
        header: 'End Insure',
        accessorKey: 'endInsure',
        type: 'date',
        requried: false,
        value: homeSaleData?.endInsure,
        tab: 2,
      },
      {
        header: 'Offer Ext Granted',
        accessorKey: 'offerExtGranted',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerExtGranted,
        tab: 2,
      },
      {
        header: 'Offer Ext Expires',
        accessorKey: 'offerExtExpires',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerExtExpires,
        tab: 2,
      },
      {
        header: 'Re-Appraisal Ordered',
        accessorKey: 'reAppraisalOrdered',
        type: 'date',
        requried: false,
        value: homeSaleData?.reAppraisalOrdered,
        tab: 2,
      },
      {
        header: 'Offer Rejected',
        accessorKey: 'offerRejected',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerRejected,
        tab: 2,
      },
      {
        header: 'Offer Follow Up',
        accessorKey: 'offerFollowUp',
        type: 'date',
        requried: false,
        value: homeSaleData?.offerFollowUp,
        tab: 2,
      },
      {
        header: 'Fund',
        accessorKey: 'fund',
        type: 'date',
        requried: false,
        value: homeSaleData?.fund,
        tab: 2,
      },
      {
        header: 'Vacate',
        accessorKey: 'vacate',
        type: 'date',
        requried: false,
        value: homeSaleData?.vacate,
        tab: 2,
      },
    ],
  };
};

export const CreatePersonalPropertyFormFields = (homeSaleData) => {
  return {
    tabs: [{ header: 'Personal Property', order: 1, iconPath: ['fas', 'suitcase-rolling'] }],
    summaries: [
      {
        header: 'Personal Property Included',
        accessorKey: 'personalPropertyIncluded',
        type: 'text',
        requried: false,
        value: homeSaleData?.personalPropertyIncluded,
        tab: 1,
      },
      {
        header: 'Personal Property Excluded',
        accessorKey: 'personalPropertyExcluded',
        type: 'text',
        requried: false,
        value: homeSaleData?.personalPropertyExcluded,
        tab: 1,
      },
    ],
  };
};

export const CreateTitleFormFields = (homeSaleData, titleReps) => {
  titleReps = titleReps ?? [];
  let titleRep = titleReps.find((s) => s.titleRepId === homeSaleData.titleCompanyId);

  return {
    tabs: [
      { header: 'Title Order', order: 1, iconPath: ['fas', 'file-contract'] },
      { header: 'Title Documents', order: 2, iconPath: ['fas', 'person-walking-luggage'] },
      { header: 'Local Title Co.', order: 3, iconPath: ['fas', 'money-bill-transfer'] },
    ],
    summaries: [
      {
        header: 'Title Rep Company',
        accessorKey: 'titleRepId',
        nameKey: 'companyName',
        nameValue: titleRep?.companyName,
        type: 'advanced-search',
        searchInfo: titleRepSearchInfo(titleReps),
        requried: false,
        value: homeSaleData?.titleCompanyId,
        tab: 1,
      },
      {
        header: 'Ordered Date',
        accessorKey: 'orderedDate',
        type: 'date',
        requried: false,
        value: homeSaleData?.orderedDate,
        tab: 1,
      },
      {
        header: 'Due Date',
        accessorKey: 'titleOrderDueDate',
        type: 'date',
        requried: false,
        value: homeSaleData?.titleOrderDueDate,
        tab: 1,
      },
      {
        header: 'Received Date',
        accessorKey: 'receivedDate',
        type: 'date',
        requried: false,
        value: homeSaleData?.receivedDate,
        tab: 1,
      },
      {
        header: 'Title Clear',
        accessorKey: 'titleClear',
        type: 'checkbox',
        requried: false,
        value: homeSaleData?.titleClear,
        tab: 1,
      },
      {
        header: 'Ordered Number',
        accessorKey: 'orderedNumber',
        type: 'text',
        requried: false,
        value: homeSaleData?.orderedNumber,
        tab: 1,
      },

      {
        header: 'Title Document Sent To',
        accessorKey: 'titleDocumentSentTo',
        type: 'text',
        requried: false,
        value: homeSaleData?.titleDocumentSentTo,
        tab: 2,
      },
      {
        header: 'Title Documents Address',
        accessorKey: 'titleDocumentsAddress',
        type: 'text',
        requried: false,
        value: homeSaleData?.titleDocumentsAddress,
        tab: 2,
      },
      {
        header: 'Title Document City',
        accessorKey: 'titleDocumentCity',
        type: 'text',
        requried: false,
        value: homeSaleData?.titleDocumentCity,
        tab: 2,
      },
      {
        header: 'Title Document State',
        accessorKey: 'titleDocumentState',
        type: 'text',
        requried: false,
        value: homeSaleData?.titleDocumentState,
        tab: 2,
      },
      {
        header: 'Title Documents Zipcode',
        accessorKey: 'titleDocumentsZipcode',
        type: 'text',
        requried: false,
        value: homeSaleData?.titleDocumentsZipcode,
        tab: 2,
      },
      {
        header: 'Title Document Sent For Signature',
        accessorKey: 'titleDocumentSentForSignature',
        type: 'date',
        requried: false,
        value: homeSaleData?.titleDocumentSentForSignature,
        tab: 2,
      },
      {
        header: 'Recieved Back',
        accessorKey: 'recievedBack',
        type: 'date',
        requried: false,
        value: homeSaleData?.recievedBack,
        tab: 2,
      },

      {
        header: 'Local Title Contact',
        accessorKey: 'localTitleContact',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleContact,
        tab: 3,
      },
      {
        header: 'Local Title Company',
        accessorKey: 'localTitleCompany',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleCompany,
        tab: 3,
      },
      {
        header: 'Local Title Address',
        accessorKey: 'localTitleAddress',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleAddress,
        tab: 3,
      },
      {
        header: 'Local Title City',
        accessorKey: 'localTitleCity',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleCity,
        tab: 3,
      },
      {
        header: 'Local Title State',
        accessorKey: 'localTitleState',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleState,
        tab: 3,
      },
      {
        header: 'Local Title Zipcode',
        accessorKey: 'localTitleZipcode',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleZipcode,
        tab: 3,
      },
      {
        header: 'Local Title Phone',
        accessorKey: 'localTitlePhone',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitlePhone,
        tab: 3,
      },
      {
        header: 'Local Title Fax',
        accessorKey: 'localTitleFax',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleFax,
        tab: 3,
      },
      {
        header: 'Local Title Email',
        accessorKey: 'localTitleEmail',
        type: 'text',
        requried: false,
        value: homeSaleData?.localTitleEmail,
        tab: 3,
      },
      {
        header: 'Local Title Documents Sent',
        accessorKey: 'localTitleDocumentsSent',
        type: 'date',
        requried: false,
        value: homeSaleData?.localTitleDocumentsSent,
        tab: 3,
      },
    ],
  };
};

export const CreateImportantDeadlinesFormFields = (homeSaleData) => {
  return {
    tabs: [{ header: 'Important Dates', order: 1, iconPath: ['fas', 'calendar-days'] }],
    summaries: [
      {
        header: 'Loan App',
        accessorKey: 'loanApp',
        type: 'date',
        requried: false,
        value: homeSaleData?.loanApp,
        tab: 1,
      },
      {
        header: 'Loan Commit',
        accessorKey: 'loanCommit',
        type: 'date',
        requried: false,
        value: homeSaleData?.loanCommit,
        tab: 1,
      },
      {
        header: 'Credit Info',
        accessorKey: 'creditInfo',
        type: 'date',
        requried: false,
        value: homeSaleData?.creditInfo,
        tab: 1,
      },
      {
        header: 'Dissapproval',
        accessorKey: 'dissapproval',
        type: 'date',
        requried: false,
        value: homeSaleData?.dissapproval,
        tab: 1,
      },
      {
        header: 'Loan Docs',
        accessorKey: 'loanDocs',
        type: 'date',
        requried: false,
        value: homeSaleData?.loanDocs,
        tab: 1,
      },
      {
        header: 'Existing Loan',
        accessorKey: 'existingLoan',
        type: 'date',
        requried: false,
        value: homeSaleData?.existingLoan,
        tab: 1,
      },
      {
        header: 'Loan Transfer',
        accessorKey: 'loanTransfer',
        type: 'date',
        requried: false,
        value: homeSaleData?.loanTransfer,
        tab: 1,
      },
      {
        header: 'Appraisal',
        accessorKey: 'appraisal',
        type: 'date',
        requried: false,
        value: homeSaleData?.appraisal,
        tab: 1,
      },
      {
        header: 'Title',
        accessorKey: 'title',
        type: 'date',
        requried: false,
        value: homeSaleData?.title,
        tab: 1,
      },
      {
        header: 'Survey',
        accessorKey: 'survey',
        type: 'date',
        requried: false,
        value: homeSaleData?.survey,
        tab: 1,
      },
      {
        header: 'Document Request',
        accessorKey: 'documentRequest',
        type: 'date',
        requried: false,
        value: homeSaleData?.documentRequest,
        tab: 1,
      },
      {
        header: 'Governing Docs',
        accessorKey: 'governingDocs',
        type: 'date',
        requried: false,
        value: homeSaleData?.governingDocs,
        tab: 1,
      },
      {
        header: 'Off Record Matters',
        accessorKey: 'offRecordMatters',
        type: 'date',
        requried: false,
        value: homeSaleData?.offRecordMatters,
        tab: 1,
      },
      {
        header: 'Off Record Matters Obj',
        accessorKey: 'offRecordmattersObj',
        type: 'date',
        requried: false,
        value: homeSaleData?.offRecordmattersObj,
        tab: 1,
      },
      {
        header: 'Right Of Refusal',
        accessorKey: 'rightOfRefusal',
        type: 'date',
        requried: false,
        value: homeSaleData?.rightOfRefusal,
        tab: 1,
      },
      {
        header: 'Sellers Property Disclose',
        accessorKey: 'sellersPropertyDisclose',
        type: 'date',
        requried: false,
        value: homeSaleData?.sellersPropertyDisclose,
        tab: 1,
      },
      {
        header: 'Inspection Objection',
        accessorKey: 'inspectionObjection',
        type: 'date',
        requried: false,
        value: homeSaleData?.inspectionObjection,
        tab: 1,
      },
      {
        header: 'Resolution',
        accessorKey: 'resolution',
        type: 'date',
        requried: false,
        value: homeSaleData?.resolution,
        tab: 1,
      },
    ],
  };
};
