/* eslint-disable react-hooks/exhaustive-deps */

import { Checkbox } from '@mui/material';
import {
  Grid,
} from '@mui/material';
import { POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalHomeServiceProperty, updateGlobalHomeServiceProperty } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import CrudTableCard from 'modules/common/crudTableCard.component';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const PropertyManagementHomeService = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { details, propertyId, tenants, id, brokerAgents, vendors, vendorContacts, updateTenantRow, createTenantRow, deleteTenantRow, isReadOnly } = props;

  const property = details.allHomeServiceProperties ? details.allHomeServiceProperties.find((s) => s.propertyId === propertyId) : null;

  const statusTypes = ['Active', 'Inactive', 'Occupied', 'Sold', 'Vacant'];

  const residenceTypes = ['Condo', 'Detached', 'Duplex', 'Farm', 'Land', 'Mobile Home', 'Multi Family', 'Single Family', 'Unspecified'];

  const vendorSearchInfo = {
    searchingColumns: [{ name: 'vendorId', title: 'Vendor Id' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }, { name: 'phone', title: 'Company Phone' }, { name: 'email', title: 'Company Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorId', width: 150 }, { columnName: 'companyName', width: 250 }, { name: 'city', width: 175 }, { name: 'state', width: 175 }, { columnName: 'phone', width: 175 }, { columnName: 'email', width: 175 }],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName',
  };

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'typeOfAgent', title: 'Type of Agent' }, { name: 'agentName', title: 'Agent Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'typeOfAgent', width: 150 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents,
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const vendorContactSearchInfo = {
    searchingColumns: [{ name: 'vendorContactId', title: 'Vendor Contact Id' }, { name: 'vendorId', title: 'Vendor Id' }, { name: 'fullName', title: 'Full Name' }, { name: 'officePhone', title: 'Contact Phone' }, { name: 'officeEmail', title: 'Contact Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorContactId', width: 150 }, { columnName: 'vendorId', width: 150 }, { columnName: 'fullName', width: 200 }, { columnName: 'officePhone', width: 175 }, { columnName: 'officeEmail', width: 175 }],
    searchingRows: vendorContacts,
    idKey: 'vendorContactId',
    nameKey: 'fullName',
  };

  const tabs = [
    { header: 'Management Details', order: 1, iconPath: ['fas', 'fa-house'] },
    { header: 'Property Info.', order: 2, iconPath: ['fas', 'file-contract'] },
    { header: 'Property Details', order: 3, iconPath: ['fas', 'house-user'] },
  ];

  const crudTableTenantsTabs = [
    { header: 'Tenants', order: 1, iconPath: ['fas', 'fa-person'] },
  ];

  const tenantsAdjustedColumns = [
    { accessorKey: 'tenantName', header: 'Tenant Name', type: 'text', required: true, size: 70, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'status', header: 'Status', type: 'dropdown', options: statusTypes, required: false, size: 50, enableColumnDragging: false, enableColumnActions: false, enableResizing: false  },
    { accessorKey: 'leaseStartDate', header: 'Lease Start Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'leaseEndDate', header: 'Lease End date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'cancelledDate', header: 'Cancelled Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'noticeGiven', header: 'Notice Given', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'moveInInspectionDone', header: 'Move In Inspection Done', type: 'checkbox', required: false, size: 50, hideInTable: true },
    { accessorKey: 'moveOutInspectionDone', header: 'Move Out Inspection Done', type: 'checkbox', required: false, size: 50, hideInTable: true },
    { accessorKey: 'actualMoveInDate', header: 'Actual Move In', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'actualMoveOutdate', header: 'Actual Move Out', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'noticeGiven', header: 'Notice Given', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'noticeDays', header: 'Notice Days', type: 'number', required: false, size: 50, hideInTable: true },
    { accessorKey: 'depositAmount', header: 'Deposit Amount', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'monthlyAmount', header: 'Monthly Amount', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'terminationFee', header: 'Termination Fee', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'numAdults', header: 'Number of Adults', type: 'text', required: false, size: 50, enableColumnDragging: false, enableColumnActions: false, enableResizing: false  },
    { accessorKey: 'numChildren', header: 'Number of Children', type: 'text', required: false, size: 50, enableColumnDragging: false, enableColumnActions: false, enableResizing: false  },
    { accessorKey: 'numPets', header: 'Number of Pets', type: 'text', required: false, size: 50, enableColumnDragging: false, enableColumnActions: false, enableResizing: false  },
    { accessorKey: 'comments', header: 'Comments', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'propertyManagementCompany', header: 'Property Management Company', type: 'text', required: false, size: 50, hideInTable: true },
  ];

  const summaries = [
    { header: 'Broker/Agent', accessorKey: 'managementBrokerAgentId', nameKey: 'brokerAgentName', nameValue: property && property.managementBrokerAgentName ? property.managementBrokerAgentName : '', type: 'advanced-search', searchInfo: brokerAgentSearchInfo, required: false, value: property && property.managementBrokerAgentId ? property.managementBrokerAgentId : 0, tab: 1 },
    { header: 'Destination Service Company', accessorKey: 'managementDestServiceCompanyId', nameKey: 'fullName', nameValue: property ? property.managementDestServiceCompanyName : '',  type: 'advanced-search', searchInfo: vendorContactSearchInfo, required: false, value: property ? property.managementDestServiceCompanyId : 0, hideInCard: true, tab: 1 },
    { header: 'Other Vendor', accessorKey: 'managementVendorId', nameKey: 'companyName', nameValue: property ? property.managementVendorName : '', type: 'advanced-search', searchInfo: vendorSearchInfo, required: false, value: property ? property.managementVendorId : '', hideInCard: true, tab: 1 },
    { header: 'Vendor Contact', accessorKey: 'managementVendorContactId', nameKey: 'fullName', nameValue: property ? property.managementVendorContactName: '',  type: 'advanced-search', searchInfo: vendorContactSearchInfo, required: false, value: property ? property.managementVendorContactId : '', hideInCard: true, tab: 1 },
    { header: 'Management Fee', accessorKey: 'managementFee', type: 'money', required: false, value: property ? property.managementFee : '', tab: 1 },
    { header: 'Lease Commission', accessorKey: 'managementLeaseCommission', type: 'money', required: false, value: property ? property.managementLeaseCommission : '', tab: 1 },
    { header: 'Vacant Managed', accessorKey: 'managementVacantManaged', type: 'checkbox', required: false, value: property ? property.managementVacantManaged : '', tab: 1, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.managementVacantManaged} disabled={true} /> },
    { header: 'Tenant Managed', accessorKey: 'managementTenantManaged', type: 'checkbox', required: false, value: property ? property.managementTenantManaged : '', tab: 1, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.managementTenantManaged} disabled={true} /> },
    { header: 'Management Contract From', accessorKey: 'managementContractFromDate', type: 'date', required: false, value: property ? property.managementContractFromDate : '', tab: 1 },
    { header: 'Management Contract To', accessorKey: 'managementContractToDate', type: 'date', required: false, value: property ? property.managementContractToDate : '', tab: 1 },
    { header: 'Status', accessorKey: 'managementStatus', type: 'dropdown', options: statusTypes, value: property ? property.managementStatus : '', required: false, tab: 1 },

    { header: 'PM Initiation', accessorKey: 'propertyPMInitiation', type: 'date', required: false, value: property ? property.propertyPMInitiation : '', tab: 2 },
    { header: 'PM Cancelled', accessorKey: 'propertyPMCancelled', type: 'date', required: false, value: property ? property.propertyPMCancelled : '', tab: 2 },
    { header: 'Transferee Vacated', accessorKey: 'propertyTransfereeVacated', type: 'date', required: false, value: property ? property.propertyTransfereeVacated : '', tab: 2 },
    { header: 'VPA Call Received', accessorKey: 'propertyVPACallReceived', type: 'date', required: false, value: property ? property.propertyVPACallReceived : '', tab: 2 },
    { header: 'Report to Duty', accessorKey: 'propertyReportToDuty', type: 'date', required: false, value: property ? property.propertyReportToDuty : '', tab: 2 },
    { header: 'Sign PM Agent', accessorKey: 'propertySignPMAgent', type: 'date', required: false, value: property ? property.propertySignPMAgent : '', tab: 2 },
    { header: 'Status', accessorKey: 'propertyStatus',  type: 'dropdown', options: statusTypes, value: property ? property.propertyStatus : '', required: false, tab: 2 },
    { header: 'Management Paid By', accessorKey: 'propertyManagementPaidBy',  type: 'dropdown', options: ['TRC'], value: property ? property.propertyManagementPaidBy : '', required: false, tab: 2 },
    { header: 'Excess Funds to Transferee', accessorKey: 'propertyExcessFundsToTransferee', type: 'checkbox', required: false, value: property ? property.propertyExcessFundsToTransferee : '', tab: 2, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.propertyExcessFundsToTransferee} disabled={true} /> },
    { header: 'Power of Attorney', accessorKey: 'propertyPowerOfAttorney', type: 'checkbox', required: false, value: property ? property.propertyPowerOfAttorney : '', tab: 2, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.propertyPowerOfAttorney} disabled={true} /> },
    { header: 'Lease With Owner', accessorKey: 'propertyLeaseWithOwner', type: 'checkbox', required: false, value: property ? property.propertyLeaseWithOwner : '', tab: 2, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.propertyLeaseWithOwner} disabled={true} /> },
    { header: 'Lease With Management Firm', accessorKey: 'propertyLeaseWithManagementFirm', type: 'checkbox', required: false, value: property ? property.propertyLeaseWithManagementFirm : '', tab: 2, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.propertyLeaseWithManagementFirm} disabled={true} /> },

    { header: 'Number of Bedrooms', accessorKey: 'propertyNumBedrooms', type: 'number', required: false, value: property ? property.propertyNumBedrooms : 0, tab: 3 },
    { header: 'Number of Bathrooms', accessorKey: 'propertyNumBathrooms', type: 'number', required: false, value: property ? property.propertyNumBathrooms : 0, tab: 3 },
    { header: 'Number of Car Garages', accessorKey: 'propertyNumCarGarage', type: 'number', required: false, value: property ? property.propertyNumCarGarage : 0, tab: 3 },
    { header: 'Number of Fireplaces', accessorKey: 'propertyNumFireplaces', type: 'number', required: false, value: property ? property.propertyNumFireplaces : 0, tab: 3 },
    { header: 'Year Built', accessorKey: 'propertyYearBuilt', type: 'number', required: false, value: property ? property.propertyYearBuilt : 0, tab: 3 },
    { header: 'Square Feet', accessorKey: 'propertySquareFeet', type: 'number', required: false, value: property ? property.propertySquareFeet : 0, tab: 3 },
    { header: 'A/C', accessorKey: 'propertyAC',  type: 'dropdown', options: ['Yes', 'No'], value: property ? property.propertyAC : '', required: false, tab: 3 },
    { header: 'Heat', accessorKey: 'propertyHeat',  type: 'dropdown', options: ['Yes', 'No'], value: property ? property.propertyHeat : '', required: false, tab: 3 },
    { header: 'Water', accessorKey: 'propertyWater',  type: 'dropdown', options: ['Yes', 'No'], value: property ? property.propertyWater : '', required: false, tab: 3 },
    { header: 'Sewer', accessorKey: 'propertySewer',  type: 'dropdown', options: ['Yes', 'No'], value: property ? property.propertySewer : '', required: false, tab: 3 },
    { header: 'Basement', accessorKey: 'propertyBasement', type: 'checkbox', required: false, value: property ? property.propertyBasement : '', tab: 3, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.propertyBasement} disabled={true} /> },
    { header: 'Pool', accessorKey: 'propertyPool', type: 'checkbox', required: false, value: property ? property.propertyPool : '', tab: 3, Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.propertyPool} disabled={true} /> },
    { header: 'Type of House', accessorKey: 'propertyTypeOfHouse',  type: 'dropdown', options: residenceTypes, value: property ? property.propertyTypeOfHouse : '', required: false, tab: 3 },

  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (property && property.length !== 0) {
      let newValues = { ...property };

      if (tab === 1) {
        newValues.managementFee = values.managementFee;
        newValues.managementLeaseCommission = values.managementLeaseCommission;
        newValues.managementVacantManaged = values.managementVacantManaged;
        newValues.managementTenantManaged = values.managementTenantManaged;
        newValues.managementContractFromDate = values.managementContractFromDate;
        newValues.managementContractToDate = values.managementContractToDate;
        newValues.managementStatus = values.managementStatus;

        if (values.managementBrokerAgentId != null) {
          const newVendor = brokerAgents.find((b) => b.brokerId === values.managementBrokerAgentId);
          newValues.managementBrokerAgentId = newVendor.brokerId;
          newValues.managementBrokerAgentName = newVendor.agentName;
        }

        if (values.managementDestServiceCompanyId != null) {
          const newVendorContact = vendorContacts.find((v) => v.vendorContactId === values.managementDestServiceCompanyId);
          newValues.managementDestServiceCompanyId = newVendorContact.vendorContactId;
          newValues.managementDestServiceCompanyName = newVendorContact.fullName;
        }

        if (values.managementVendorId != null) {
          const newVendor = vendors.find((v) => v.vendorId === values.managementVendorId);
          newValues.managementVendorId = newVendor.vendorId;
          newValues.managementVendorName = newVendor.companyName;
          newValues.managementVendorEmail = newVendor.email;
        }
        
        if (values.managementVendorContactId != null) {
          const newVendorContact = vendorContacts.find((v) => v.vendorContactId === values.managementVendorContactId);
          newValues.managementVendorContactId = newVendorContact.vendorContactId;
          newValues.managementVendorContactName = newVendorContact.fullName;
          newValues.managementVendorContactEmail = newVendorContact.officeEmail;
        }
      } else if (tab === 2) {
        newValues.propertyPMInitiation = values.propertyPMInitiation;
        newValues.propertyPMCancelled = values.propertyPMCancelled;
        newValues.propertyTransfereeVacated = values.propertyTransfereeVacated;
        newValues.propertyVPACallReceived = values.propertyVPACallReceived;
        newValues.propertyReportToDuty = values.propertyReportToDuty;
        newValues.propertySignPMAgent = values.propertySignPMAgent;
        newValues.propertyStatus = values.propertyStatus;
        newValues.propertyManagementPaidBy = values.propertyManagementPaidBy;
        newValues.propertyExcessFundsToTransferee = values.propertyExcessFundsToTransferee;
        newValues.propertyPowerOfAttorney = values.propertyPowerOfAttorney;
        newValues.propertyLeaseWithOwner = values.propertyLeaseWithOwner;
        newValues.propertyLeaseWithManagementFirm = values.propertyLeaseWithManagementFirm;
      } else {
        newValues.propertyNumBedrooms = values.propertyNumBedrooms;
        newValues.propertyNumBathrooms = values.propertyNumBathrooms;
        newValues.propertyNumCarGarage = values.propertyNumCarGarage;
        newValues.propertyNumFireplaces = values.propertyNumFireplaces;
        newValues.propertyYearBuilt = values.propertyYearBuilt;
        newValues.propertySquareFeet = values.propertySquareFeet;
        newValues.propertyAC = values.propertyAC;
        newValues.propertyHeat = values.propertyHeat;
        newValues.propertyWater = values.propertyWater;
        newValues.propertySewer = values.propertySewer;
        newValues.propertyBasement = values.propertyBasement;
        newValues.propertyPool = values.propertyPool;
        newValues.propertyTypeOfHouse = values.propertyTypeOfHouse;
      }

      const resp = await props.updateGlobalHomeServiceProperty(newValues);
      if (resp.type === UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postGlobalHomeServiceProperty(values);
      if (resp.type === POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const crudTableCardSummaries = [
    { adjustedColumns: tenantsAdjustedColumns, rows: tenants, addButtonText: 'Add Tenant', tab: 1, updateRow: updateTenantRow, createRow: createTenantRow, deleteRow: deleteTenantRow, hideEdit: isReadOnly },
  ];
    
  return (
    <Grid item>
      <Grid item sx={{ marginBottom: '10px' }}>
        <EditableCard
          tabs={tabs}
          summaries={summaries}
          numColumns={5} 
          updateFields={updateFields}
          isLoading={isLoading}
          hideEdit={isReadOnly}
        />
      </Grid>
      <Grid item >
        <CrudTableCard
          tabs={crudTableTenantsTabs}
          summaries={crudTableCardSummaries}
          numColumns={5}
        />    
      </Grid>
    </Grid>
  );

};

const mapStateToProps = (state) => {
  const {
    authorizations: { vendors, vendorContacts, brokerAgents },
  } = state;
  return { vendors, vendorContacts, brokerAgents };
};

export default compose(
  connect(mapStateToProps, {
    updateGlobalHomeServiceProperty,
    postGlobalHomeServiceProperty,
    showToast,
  }),
)(PropertyManagementHomeService);