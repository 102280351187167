import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
    
import { DELETE_CLIENT_GENERAL_NOTES_FAILURE, GET_CLIENT_GENERAL_NOTES_FAILURE, POST_CLIENT_GENERAL_NOTES_FAILURE, UPDATE_CLIENT_GENERAL_NOTES_FAILURE } from '../../types';
import { deleteClientGeneralNotes, getClientGeneralNotes, postClientGeneralNotes, updateClientGeneralNotes } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ClientGeneralNotesTable = (props) => {
  const [clientOverview, setClientOverview] = useState(false);
  const theme = useTheme();

  const adjustedColumns = [
    { accessorKey: 'title', header: 'Title', type: 'text', required: true, enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
    { accessorKey: 'description', header: 'Description', type: 'notes', enableColumnDragging: false, enableColumnActions: false, enableResizing: false },
  ];

  const updateRow = async (generalNotes, values) => {
    const id = generalNotes.original.clientGeneralNotesKey;
    values.clientGeneralNotesKey = id;
    values.clientId = props.clientId;
    const resp = await props.updateClientGeneralNotes(values);
    if (resp.type === UPDATE_CLIENT_GENERAL_NOTES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientGeneralNotes(props.clientId);
      if (getResp.type === GET_CLIENT_GENERAL_NOTES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postClientGeneralNotes(values);
    if (resp.type === POST_CLIENT_GENERAL_NOTES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientGeneralNotes(props.clientId);
      if (getResp.type === GET_CLIENT_GENERAL_NOTES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (generalNotes) => {
    const id = generalNotes.original.clientGeneralNotesKey;
    const resp = await props.deleteClientGeneralNotes(id);
    if (resp.type === DELETE_CLIENT_GENERAL_NOTES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getClientGeneralNotes(props.clientId);
      if (getResp.type === GET_CLIENT_GENERAL_NOTES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={clientOverview} onChange={()=> {setClientOverview(!clientOverview);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>General Notes</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.clientGeneralNotesLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.clientGeneralNotes} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {props.clientGeneralNotesLoading && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

ClientGeneralNotesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientGeneralNotesLoading: PropTypes.bool,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
  internalUsers: PropTypes.array,
  postClientGeneralNotes: PropTypes.func,
  updateClientGeneralNotes: PropTypes.func,
  getClientGeneralNotes: PropTypes.func,
  deleteClientGeneralNotes: PropTypes.func,
  clientGeneralNotes: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { clientGeneralNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientGeneralNotesLoading } = state.clients;
  return {
    clientGeneralNotes,
    clientGeneralNotesLoading,
  };
};
  
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientGeneralNotes,
    deleteClientGeneralNotes,
    postClientGeneralNotes,
    updateClientGeneralNotes,
  }),
)(ClientGeneralNotesTable);