/* eslint-disable react-hooks/exhaustive-deps */
import { TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 350,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  };
};

const HomeSaleEquityAdvance = (props) => {

  const clientHomesaleEquityAdvance = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleEquityAdvance : null;

  const [percentAdvanced, setPercentAdvanced] = useState(clientHomesaleEquityAdvance ? clientHomesaleEquityAdvance.percentAdvanced : null);
  const [documentationNeeded, setDocumentationNeeded] = useState(clientHomesaleEquityAdvance ? clientHomesaleEquityAdvance.documentationNeeded : null);
  const [otherInformation, setOtherInformation] = useState(clientHomesaleEquityAdvance ? clientHomesaleEquityAdvance.otherInformation : null);
  const [otherNotes, setOtherNotes] = useState(clientHomesaleEquityAdvance ? clientHomesaleEquityAdvance.otherNotes : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      PercentAdvanced: percentAdvanced,
      DocumentationNeeded: documentationNeeded,
      OtherInformation: otherInformation,
      OtherNotes: otherNotes,
    };

    if (clientHomesaleEquityAdvance && clientHomesaleEquityAdvance.id) updateBody.Id = clientHomesaleEquityAdvance.id;

    props.updateInfo(updateBody);
  }, [percentAdvanced, documentationNeeded, otherInformation, otherNotes]);

  return (
    <div className={props.classes.contractsGrid}>
      <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
        <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
          <p className={props.classes.descText}>What percent should be advanced (based on closing cost information)? Recommended 80%-90%.</p>
          <TextField
            id="name"
            label="Percent Advanced"
            className={props.classes.textField}
            variant="outlined"
            style={{ width: '100%', marginRight: 4 }}
            value={percentAdvanced}
            onChange={(e) => setPercentAdvanced(e.target.value)}
          />
          <TextField
            variant="outlined"
            label={'Other Information'}
            value={otherInformation}
            multiline
            minRows={5}
            sx={{ minWidth: '350px' }}
            onChange={(e) => setOtherInformation(e.target.value)}
          />
        </div>

        <div className={props.classes.contentCol}>
          <p className={props.classes.descText}>Documentation Needed: recommend NH purchase contract and good faith estimate</p>
          <TextField
            id="name"
            label="Documentation Needed"
            className={props.classes.textField}
            variant="outlined"
            style={{ width: '100%', marginRight: 4 }}
            value={documentationNeeded}
            onChange={(e) => setDocumentationNeeded(e.target.value)}
          />
          <TextField
            variant="outlined"
            label={'Other Notes'}
            value={otherNotes}
            multiline
            minRows={5}
            sx={{ minWidth: '350px' }}
            onChange={(e) => setOtherNotes(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
}; 

HomeSaleEquityAdvance.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleEquityAdvance);