import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DELETE_AUTH_SERVICES_FAILURE, GET_ALL_AUTH_SERVICES_FAILURE, POST_AUTH_SERVICES_FAILURE, UPDATE_AUTH_SERVICES_FAILURE } from '../authorizations/store/types/authorizationTypes';
import { deleteServices, getAuthServices, postServices, updateServices } from '../authorizations/store/actions/authorizations.actions';
import CrudTable from '../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';

import classNames from 'classnames';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const AuthServicesTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'authorizationId', header: 'Auth Id', type: 'text', required: true, size: 70 },
    { accessorKey: 'taxAssistance', header: 'Tax Assistance', type: 'dropdown', options: ['Not Initiated', 'Initiated', 'Completed'], size: 70 },
    { accessorKey: 'homesale', header: 'Homesale', type: 'dropdown', options: ['Not Initiated', 'Initiated', 'Completed'], size: 70 },
    { accessorKey: 'global', header: 'Global', type: 'dropdown', options: ['Not Initiated', 'Initiated', 'Completed'], size: 50 },
    { accessorKey: 'homefindRenter', header: 'Homefind - Renter', type: 'dropdown', options: ['Not Initiated', 'Initiated', 'Completed'], size: 50 },
    { accessorKey: 'homefindBuyer', header: 'Homefind - Buyer', type: 'dropdown', options: ['Not Initiated', 'Initiated', 'Completed'], size: 50 },
    { accessorKey: 'temporaryLiving', header: 'Temporary Living', type: 'dropdown', options: ['Not Initiated', 'Initiated', 'Completed'], size: 50 },
    { accessorKey: 'householdGoods', header: 'Household Goods', type: 'dropdown', options: ['Not Initiated', 'Initiated', 'Completed'], size: 50 },
    { accessorKey: 'expenses', header: 'Expenses', type: 'text', size: 50 },
  ];

  const updateRow = async (eligibleProperty, values) => {
    const id = eligibleProperty.original.authorizationId;
    values.authorizationId = id;
    values.clientId = props.clientId;
    const resp = await props.updateServices(values);
    if (resp.type === UPDATE_AUTH_SERVICES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthServices();
      if (getResp.type === GET_ALL_AUTH_SERVICES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;
    const resp = await props.postServices(values);
    if (resp.type === POST_AUTH_SERVICES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthServices();
      if (getResp.type === GET_ALL_AUTH_SERVICES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (eligibleProperty) => {
    const id = eligibleProperty.original.authorizationId;
    const resp = await props.deleteServices(id);
    if (resp.type === DELETE_AUTH_SERVICES_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAuthServices();
      if (getResp.type === GET_ALL_AUTH_SERVICES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Authorization Services</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.allServicesLoading && props.allServices && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={props.allServices} 
            updateRow={updateRow} 
            createRow={createRow} 
            deleteRow={deleteRow} 
          />
        )}
        {props.allServicesLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

AuthServicesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { allServices, allServicesLoading } = state.authorizations;
  const { clients } = state.clients;
  return {
    allServices,
    allServicesLoading,
    clients,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getAuthServices,
    deleteServices,
    postServices,
    updateServices,
  }),
)(AuthServicesTable);