
import { POST_AUTH_DATE_SUMMARY_FAILURE, UPDATE_AUTH_DATE_SUMMARY_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postTeam, updateTeam } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const TeamCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { team } = props;

  const summaryTabs = [
    { header: 'Team', order: 1 },
  ];

  const fields = [
    { header: 'Counselor', accessorKey: 'counselor', type: 'text', required: false, value: team ? team.counselor : null, tab: 1 },
    { header: 'Team', accessorKey: 'teamName', type: 'text', required: false, value: team ? team.teamName : null, tab: 1 },
    { header: 'RC Reports To', accessorKey: 'reportsTo', type: 'text', required: false, value: team ? team.reportsTo : null, tab: 1 },
    { header: 'CSM', accessorKey: 'csm', type: 'text', required: false, value: team ? team.csm : null, tab: 1 },
    { header: 'DSD', accessorKey: 'dsd', type: 'text', required: false, value: team ? team.dsd : null, tab: 1 },
    { header: 'CSS', accessorKey: 'css', type: 'text', required: false, value: team ? team.css : null, tab: 1 },
    { dividerLine: true },
    { header: 'Inventory SP', accessorKey: 'inventorySP', type: 'text', required: false, value: team ? team.inventorySP : null, tab: 1 },
    { header: 'Expense Analyst', accessorKey: 'expenseAnalyst', type: 'text', required: false, value: team ? team.expenseAnalyst : null, tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;

    if (team && team.length !== 0) {
      const resp = await props.updateTeam(values);
      if (resp.type === UPDATE_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to update team info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postTeam(values);
      if (resp.type === POST_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to update team info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={summaryTabs}
      summaries={fields}
      numColumns={2} 
      updateFields={updateFields}
      isLoading={isLoading}
    />
  );

};

export default compose(
  connect(null, {
    postTeam,
    updateTeam,
    showToast,
  }),
)(TeamCard);