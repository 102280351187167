import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { DELETE_HOMESALE_CLOSING_COSTS_FAILURE, GET_HOMESALE_CLOSING_COSTS_FAILURE, POST_HOMESALE_CLOSING_COSTS_FAILURE, UPDATE_HOMESALE_CLOSING_COSTS_FAILURE } from '../../types';
import { deleteHomesaleClosingCosts, getHomesaleClosingCosts, postHomesaleClosingCosts, updateHomesaleClosingCosts } from '../../clients.actions';
import CrudTable from '../crudTable.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleClosingCostsTable = (props) => {

  const adjustedColumns = [
    { accessorKey: 'costType', header: 'Cost Type', type: 'text', required: true, size: 65 },
    { accessorKey: 'coveredForBVO', header: 'Covered for BVO', type: 'dropdown', options: ['Yes', 'No', 'N/A'], required: true, size: 68 },
    { accessorKey: 'coveredForGBO', header: 'Converted for GBO', type: 'dropdown', options: ['Yes', 'No', 'N/A'], required: true, size: 72 },
    { accessorKey: 'convertedForDirectReimbursement', header: 'Converted for Direct Reimbursement', type: 'dropdown', options: ['Yes', 'No', 'N/A'], required: true, size: 80 },
  ];

  const updateRow = async (closingCost, values) => {
    const id = closingCost.original.clientClosingCostsKey;
    values.clientClosingCostsKey = id;
    values.clientId = props.clientId;

    const resp = await props.updateHomesaleClosingCosts(values);
    if (resp.type === UPDATE_HOMESALE_CLOSING_COSTS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleClosingCosts(props.clientId);
      if (getResp.type === GET_HOMESALE_CLOSING_COSTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    values.clientId = props.clientId;

    const resp = await props.postHomesaleClosingCosts(values);
    if (resp.type === POST_HOMESALE_CLOSING_COSTS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleClosingCosts(props.clientId);
      if (getResp.type === GET_HOMESALE_CLOSING_COSTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (closingCost) => {
    const id = closingCost.original.clientClosingCostsKey;
    const resp = await props.deleteHomesaleClosingCosts(id);
    if (resp.type === DELETE_HOMESALE_CLOSING_COSTS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getHomesaleClosingCosts(props.clientId);
      if (getResp.type === GET_HOMESALE_CLOSING_COSTS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div className={props.classes.tabBorder}>
      {!props.homesaleClosingCostsLoading && (
        <CrudTable 
          editingMode={'modal'} // Can be changed to 'row' to edit inline 
          columns={adjustedColumns} 
          rows={props.homesaleClosingCosts} 
          updateRow={updateRow} 
          createRow={createRow} 
          deleteRow={deleteRow} 
        />
      )}
      {props.homesaleClosingCostsLoading && (
        <Spinner />
      )}
    </div>
  );
}; 

HomeSaleClosingCostsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  formikProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { homesaleClosingCosts } = state.clients.clientsInfo[state.clients.currentClientId];
  const { homesaleClosingCostsLoading } = state.clients;
  return {
    homesaleClosingCosts,
    homesaleClosingCostsLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getHomesaleClosingCosts,
    deleteHomesaleClosingCosts,
    postHomesaleClosingCosts,
    updateHomesaleClosingCosts,
  }),
)(HomeSaleClosingCostsTable);