/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Checkbox, Chip, FormControlLabel, MenuItem, Select, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { POST_GBO_INSPECTIONS_INITIALIZE_SUCCESS } from '../../types';
import { getClientHomesaleGeneralInfo, getHomesaleGboInspections, initializeGboInspections, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

import HomeSaleEligiblePropertiesTable from './homeSaleEligiblePropertiesTable.component';
import HomeSaleEquityAdvance from './homeSaleEquityAdvance.component';
import HomeSaleGboInspectionsTable from './homeSaleGboInspectionsTable.component';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 400,
    },
    firstCol: {
      width: 200,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleBvoGbo = (props) => {

  const clientHomesaleBvoGbo = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleBvoGbo : null;

  const [isEnabled, setIsEnabled] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.isEnabled : false);
  const [bvoEligible, setBvoEligible] = useState(clientHomesaleBvoGbo && clientHomesaleBvoGbo.bvoEligible ? clientHomesaleBvoGbo.bvoEligible.split(',') : []);
  const [maximumVariance, setMaximumVariance] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.maximumVariance : null);
  const [isThirdOrdered, setIsThirdOrdered] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.isThirdOrdered : null); 
  const [listingDetermined, setListingDetermined] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.listingDetermined : null);
  const [specificRequirements, setSpecificRequirements] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.specificRequirements : null);
  const [acceptanceRequirement, setAcceptanceRequired] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.acceptanceRequirement : null);
  const [riskTolerance, setRiskTolerance] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.riskTolerance : null);
  const [inspectionsOrdered, setInspectionsOrdered] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.inspectionsOrdered : null);
  const [inspectionsGBOInitiated, setInspectionsGBOInitiated] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.inspectionsGBOInitiated : false);
  const [eligiblePropertiesInitiated, setEligiblePropertiesInitiated] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.eligiblePropertiesInitiated : false);
  const [equityAdvanceInitiated, setEquityAdvanceInitiated] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.equityAdvanceInitiated : false);
  const [otherNotes, setOtherNotes] = useState(clientHomesaleBvoGbo ? clientHomesaleBvoGbo.otherNotes : null);

  const gboInspectionsClicked = async (val) => {
    if (val) {
      if (!props.clientHomesaleGboInspections || (props.clientHomesaleGboInspections && props.clientHomesaleGboInspections.length === 0)) {
        const resp = await props.initializeGboInspections(props.clientId);
        if (resp.type === POST_GBO_INSPECTIONS_INITIALIZE_SUCCESS) {
          await props.getHomesaleGboInspections(props.clientId);
        }
      }

      setInspectionsGBOInitiated(val);
    } else {
      setInspectionsGBOInitiated(val);
    }
  };

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      IsEnabled: isEnabled,
      BvoEligible: bvoEligible.join(','),
      MaximumVariance: maximumVariance,
      IsThirdOrdered: isThirdOrdered,
      ListingDetermined: listingDetermined,
      SpecificRequirements: specificRequirements,
      AcceptanceRequirement: acceptanceRequirement,
      RiskTolerance: riskTolerance,
      InspectionsOrdered: inspectionsOrdered,
      InspectionsGBOInitiated: inspectionsGBOInitiated,
      EligiblePropertiesInitiated: eligiblePropertiesInitiated,
      EquityAdvanceInitiated: equityAdvanceInitiated,
      OtherNotes: otherNotes,
    };

    if (clientHomesaleBvoGbo && clientHomesaleBvoGbo.id) updateBody.Id = clientHomesaleBvoGbo.id;

    props.updateInfo(updateBody);
  }, [isEnabled, bvoEligible, maximumVariance, isThirdOrdered, listingDetermined, specificRequirements, acceptanceRequirement, riskTolerance, inspectionsOrdered, inspectionsGBOInitiated, eligiblePropertiesInitiated, equityAdvanceInitiated, otherNotes]);

  return (
    <div className={props.classes.contractsGrid}>
      <div className={props.classes.headerRow}>
        <h3>BVO/GBO Home Sale</h3>
        <FormControlLabel
          control={
            <Checkbox
              name="bvoGbo"
              label="Activate Service"
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
          }
          label="Activate Service"
          classes={{ root: props.classes.checkbox }}
        />
      </div>
      {isEnabled && (
        <div className={props.classes.tabBorder}>
          <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
            <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
              <p className={props.classes.descText}>Who is eligible for the BVO?</p>
              <Select
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Who is Eligible?'}
                onChange={(e) => setBvoEligible(e.target.value)}
                multiple
                value={bvoEligible}
                renderValue={(selected) => {
                  return (
                    <div className={props.classes.chips}>
                      {selected.map((value) => {
                        const policyInfo = props.clientPolicyNotes.find((o) => o.clientPolicyNotesKey === parseInt(value));
                        if (policyInfo != null) {
                          return (
                            <Chip
                              key={policyInfo.clientPolicyNotesKey}
                              label={policyInfo.name}
                              className={props.classes.chip}
                              onDelete={() => {
                                setBvoEligible(bvoEligible.filter((b) => b !== value));
                              }}
                              onMouseDown={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          );
                        }
                      })}
                    </div>
                  );}}
              >
                {props.clientPolicyNotes && props.clientPolicyNotes.map((c) => {
                  return <MenuItem key={c.clientPolicyNotesKey} value={c.clientPolicyNotesKey}>{c.name}</MenuItem>;
                })}
              </Select>
              <p className={props.classes.descText}>What is the maximum variance between Broker Market Analysis (BMA)? (5% recommended - based on Most Likely Sale Price)</p>
              <TextField
                id="name"
                label="Maximum Variance?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={maximumVariance}
                onChange={(e) => setMaximumVariance(e.target.value)}
              />
              <p className={props.classes.descText}>If BMAs are not within acceptable variance, is a 3rd ordered? Is there a case where a 3rd would not be ordered?</p>
              <TextField
                id="name"
                label="3rd Ordered?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={isThirdOrdered}
                onChange={(e) => setIsThirdOrdered(e.target.value)}
              />
              <p className={props.classes.descText}>If a 3rd is ordered, how is the listing determined? (Recommended: Average the 2 closest - Other options: average 2 highest, 2 closest, or all 3)</p>
              <TextField
                id="name"
                label="Listing Determined?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={listingDetermined}
                onChange={(e) => setListingDetermined(e.target.value)}
              />
              <p className={props.classes.descText}>Are any inspections ordered and paid for on a BVO home?</p>
              <TextField
                id="name"
                label="Inspections Ordered"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={inspectionsOrdered}
                onChange={(e) => setInspectionsOrdered(e.target.value)}
              />
            </div>

            <div className={props.classes.contentCol}>
              <p className={props.classes.descText}>We look for the following to consider it a bona fide offer: Pre-Approval letter from the buyer, closing within 60 days amd customary deposit. We will allow a contigency on the CLOSE of a buyer's home but NOT the SALE. Should any other specific items be required?</p>
              <TextField
                id="name"
                label="Specific Requirements"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={specificRequirements}
                onChange={(e) => setSpecificRequirements(e.target.value)}
              />
              <p className={props.classes.descText}>If a bona fide offer is presented, is there an acceptance requirement if the offer is within a specific range of the Most Likely Sales Price established by the BMA?</p>
              <TextField
                id="name"
                label="Acceptance Requirement"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={acceptanceRequirement}
                onChange={(e) => setAcceptanceRequired(e.target.value)}
              />
              <p className={props.classes.descText}>What is the risk tolerance? We typically will require the buyer's inspection to be cleared, and will not acquire until the vacate date or 15 days prior the outside sale. For FHHA/VA, is the lender appraisal required before acquiring?</p>
              <TextField
                id="name"
                label="Risk Tolerance"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={riskTolerance}
                onChange={(e) => setRiskTolerance(e.target.value)}
              />
              <TextField
                variant="outlined"
                label={'Other Notes'}
                value={otherNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
            </div>
          </form>

          <div className={props.classes.headerRow}>
            <h3>Initiate GBO Inspections</h3>
            <FormControlLabel
              control={
                <Checkbox
                  name="gboInspections"
                  label="Activate Service"
                  checked={inspectionsGBOInitiated}
                  onChange={(e) => gboInspectionsClicked(e.target.checked)}
                />
              }
              label="Activate Service"
              classes={{ root: props.classes.checkbox }}
            />
          </div>
          {inspectionsGBOInitiated && <HomeSaleGboInspectionsTable clientId={props.clientId} />}
          <div className={props.classes.headerRow}>
            <h3>Initiate Eligible Properties</h3>
            <FormControlLabel
              control={
                <Checkbox
                  name="elgibleProperties"
                  label="Activate Service"
                  checked={eligiblePropertiesInitiated}
                  onChange={(e) => setEligiblePropertiesInitiated(e.target.checked)}
                />
              }
              label="Activate Service"
              classes={{ root: props.classes.checkbox }}
            />
          </div>
          {eligiblePropertiesInitiated && <HomeSaleEligiblePropertiesTable clientId={props.clientId} />}
          <div className={props.classes.headerRow}>
            <h3>Initiate Equity Advance</h3>
            <FormControlLabel
              control={
                <Checkbox
                  name="equityAdvance"
                  label="Activate Service"
                  checked={equityAdvanceInitiated}
                  onChange={(e) => setEquityAdvanceInitiated(e.target.checked)}
                />
              }
              label="Activate Service"
              classes={{ root: props.classes.checkbox }}
            />
          </div>
          {equityAdvanceInitiated && <HomeSaleEquityAdvance clientHomesaleGeneralInfo={props.clientHomesaleGeneralInfo} clientId={props.clientId} updateInfo={props.updateEquityAdvanceInfo} />}
        </div>
      )}
    </div>
  );
}; 

HomeSaleBvoGbo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes, clientHomesaleGboInspections } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotes,
    clientHomesaleGboInspections,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    getHomesaleGboInspections,
    initializeGboInspections,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleBvoGbo);