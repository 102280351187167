/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  deleteExpenseLineCustomTax,
  getExpenseLineCustomTaxes,
  postExpenseLineCustomTax,
  updateExpenseLine,
  updateExpenseLineCustomTax
} from 'modules/expenses/expenses.actions';
import {
  DELETE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE,
  POST_EXPENSE_LINE_CUSTOM_TAXES_FAILURE,
  UPDATE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE,
  UPDATE_EXPENSE_LINE_FAILURE
} from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  createPostCustomTaxApiRequest,
  createUpdateCustomTaxApiRequest,
  createUpdatePayrollApiRequest,
  createUpdateTaxesApiRequest
} from './expenseLineItemsUtils';
import ExpenseLineItemsPayrollEditableCardComponent from './expenseLineItemsPayrollEditableCard.component';
import ExpenseLineItemsTaxesEditableCard from './expenseLineItemsTaxesEditableCard.component';
import { showToast } from 'modules/layout/layout.actions';

const ExpenseLineItemsTaxesDetailsView = props => {
  const { id } = props.details;
  const { expenseLines, customTaxes } = props.details?.authExpenses;
  const expenseLineItemId = parseInt(props.expenseLineId, 10);
  const showToast = props.showToast;
  const expenseSummary = props.details[id]?.authExpenses.submittedExpenses;
  
  const [selectedExpenseLineItem, setSelectedExpenseLineItem] = useState({});
  const [selectedExpenseSummary, setSelectedExpenseSummary] = useState({});
  const getExpenseLineCustomTaxes = props.getExpenseLineCustomTaxes;

  const [customTaxesForExpenseLineItem, setCustomTaxesForExpenseLineItem] = useState(customTaxes ?? []);
  const [isLoadingCustomLineTaxes, setIsLoadingCustomLineTaxes] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const setData = async () => {
      if (expenseLines && expenseLines.length > 0 && expenseLineItemId !== undefined && expenseLineItemId !== null) {
        const expenseLineItem = expenseLines.find(s => s.expenseLineId === expenseLineItemId);
        if (expenseLineItem) {
          setSelectedExpenseLineItem(expenseLineItem);
          if (expenseSummary) {
            const foundSummary = expenseSummary.find(s => s.expenseId === expenseLineItem.expenseId);
            setSelectedExpenseSummary(foundSummary);
          }
        }
      }
    };
    setData();
  }, [expenseLines, expenseLineItemId, customTaxesForExpenseLineItem, id, showToast, expenseSummary, getExpenseLineCustomTaxes]);

  const handlePayrollSubmission = async values => {
    let isSuccessful = false;
    setIsLoadingCustomLineTaxes(true);
    const request = createUpdatePayrollApiRequest(selectedExpenseLineItem, values);
    try {
      const resp = await props.updateExpenseLine(request);
      if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
        props.showToast('Fauled to update Expense Line Item', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        isSuccessful = true;
      }
    } catch (error) {
      console.error('Error submitting employee form:', error);
    }
    setIsLoadingCustomLineTaxes(false);
    return isSuccessful;
  };

  const handleTaxesSubmission = async values => {
    const request = createUpdateTaxesApiRequest(selectedExpenseLineItem, values);
    let isSuccessful = false;
    setIsLoadingCustomLineTaxes(true);
    try {
      const resp = await props.updateExpenseLine(request);
      if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
        props.showToast('Fauled to update Expense Line Item', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        isSuccessful = true;
      }
    } catch (error) {
      console.error('Error submitting employee form:', error);
    }
    setIsLoadingCustomLineTaxes(false);
    return isSuccessful;
  };

  const handleCreateCustomTax = async values => {
    const request = createPostCustomTaxApiRequest(values, expenseLineItemId);
    let createCustomTaxResp = { isSuccessful: false, newId: null };
    setIsLoadingCustomLineTaxes(true);
    try {
      const resp = await props.postExpenseLineCustomTax(selectedExpenseLineItem.expenseId, expenseLineItemId, request);
      if (resp.type === POST_EXPENSE_LINE_CUSTOM_TAXES_FAILURE) {
        props.showToast('Failed to creating custom tax', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        createCustomTaxResp.isSuccessful = true;
        createCustomTaxResp.newId = resp?.response?.customTaxId;
        let customTaxes = await props.getExpenseLineCustomTaxes(selectedExpenseLineItem.expenseId, expenseLineItemId);
        setCustomTaxesForExpenseLineItem(customTaxes.response);
      }
    } catch (error) {
      console.error('Error submitting custom tax:', error);
    }
    setIsLoadingCustomLineTaxes(false);
    return createCustomTaxResp;
  };

  const handleUpdateCustomTax = async values => {
    if (!Array.isArray(customTaxesForExpenseLineItem) || !customTaxesForExpenseLineItem || customTaxesForExpenseLineItem.length === 0)
      return false;

    let customTax = customTaxesForExpenseLineItem.find(s => s.customTaxId === values.id);
    const request = createUpdateCustomTaxApiRequest(customTax, values);
    let isSuccessful = false;
    setIsLoadingCustomLineTaxes(true);

    try {
      const resp = await props.updateExpenseLineCustomTax(
        selectedExpenseLineItem.expenseId,
        expenseLineItemId,
        request.customTaxId,
        request
      );
      if (resp.type === UPDATE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE) {
        props.showToast('Failed to updating custom tax', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        isSuccessful = true;
        let customTaxes = await props.getExpenseLineCustomTaxes(selectedExpenseLineItem.expenseId, expenseLineItemId);
        setCustomTaxesForExpenseLineItem(customTaxes.response);
      }
    } catch (error) {
      console.error('Error submitting custom tax:', error);
    }
    setIsLoadingCustomLineTaxes(false);
    return isSuccessful;
  };

  const handleDeleteCustomTax = async customTaxId => {
    let isSuccessful = false;
    setIsDeleteLoading(true);
    try {
      const resp = await props.deleteExpenseLineCustomTax(selectedExpenseLineItem.expenseId, expenseLineItemId, customTaxId);
      if (resp.type === DELETE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE) {
        props.showToast('Failed to delete custom tax', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        isSuccessful = true;
        let customTaxes = await props.getExpenseLineCustomTaxes(selectedExpenseLineItem.expenseId, expenseLineItemId);
        setCustomTaxesForExpenseLineItem(customTaxes.response);
      }
    } catch (error) {
      console.error('Error deleting custom tax:', error);
    }
    setIsDeleteLoading(false);
    return isSuccessful;
  };

  return (
    <Grid container spacing={2} sx={{ padding: '20px' }}>
      <Grid item sm={12} md={12} lg={7} xl={7} sx={{ display: 'flex', flexDirection: 'column', height: 'auto', alignSelf: 'flex-start' }}>
        <ExpenseLineItemsTaxesEditableCard
          selectedLineExpense={selectedExpenseLineItem}
          selectedLineExpenseCustomTaxes={customTaxesForExpenseLineItem}
          updateCustomTaxes={handleUpdateCustomTax}
          deleteCustomTaxes={handleDeleteCustomTax}
          createCustomTaxes={handleCreateCustomTax}
          handleTaxesSubmission={handleTaxesSubmission}
          isLoading={isLoadingCustomLineTaxes}
          isDeleteLoading={isDeleteLoading}
          viewOnly={props.viewOnly}
        />
      </Grid>
      <Grid item sm={12} md={12} lg={5} xl={5}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <ExpenseLineItemsPayrollEditableCardComponent
              updateFields={handlePayrollSubmission}
              isLoading={isLoadingCustomLineTaxes}
              selectedExpenseLineItem={selectedExpenseLineItem}
              expneseSummary={selectedExpenseSummary}
              viewOnly={props.viewOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(
  connect(null, {
    showToast,
    updateExpenseLine,
    postExpenseLineCustomTax,
    deleteExpenseLineCustomTax,
    updateExpenseLineCustomTax,
    getExpenseLineCustomTaxes
  })
)(ExpenseLineItemsTaxesDetailsView);
