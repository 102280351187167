/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';

import { Box, Grid } from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import RequestDetails from './requestDetails.component';
import Spinner from 'modules/common/spinner.component';
import VendorInformation from './vendorInformation.component';
import CarShipmentVehicles from './carShipmentVehicles.component';
import CarShipmentMoveDates from './carShipmentMoveDates.component';
import GoodShipmentMoveDates from './goodsShipmentMoveDates.component';
import LongTermStorageMoveDates from './longTermStorageMoveDates.component';
import CarShipmentEstimates from './carShipmentEstimates.component';
import GoodsShipmentEstimates from './goodsShipmentEstimates.component';
import LongTermStorageEstimates from './longTermStorageEstimates.component';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const EstimatesActuals = props => {
  const { currentTab, details, estimateId, location, isLoadingEstimates, segmentId, isReadOnly, id } = props;

  const segmentInfo = details?.authHouseHoldGoods?.hhgSegments
    ? details?.authHouseHoldGoods?.hhgSegments.find(s => s.segmentId === segmentId)
    : null;

  const estimateInfo =
    segmentInfo && estimateId != null ? details?.authHouseHoldGoods?.hhgEstimates.find(e => e.estimateId === estimateId) : null;

  let itemsTotal = 0;
  if (details?.authHouseHoldGoods?.hhgItems)
    details.authHouseHoldGoods.hhgItems.map(item => (itemsTotal = itemsTotal + Number(item.value)));

  let estimateInfoEmptyOrValid = estimateInfo == null || (estimateInfo != null && estimateInfo.estimateType !== currentTab);
  let vehicles = details?.authHouseHoldGoods?.hhgSegmentVehicles
    ? details?.authHouseHoldGoods?.hhgSegmentVehicles.filter(v => v.estimateId === estimateId)
    : [];

  return (
    <Box>
      {estimateInfoEmptyOrValid && !isLoadingEstimates && (
        <Box sx={{ padding: '20px', fontSize: '20px' }}>
          <b>No estimate selected. Create a new estimate above to get started.</b>
        </Box>
      )}
      {!estimateInfoEmptyOrValid && !isLoadingEstimates && (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <RequestDetails
              estimateInfo={estimateInfo}
              hideEdit={location.pathname.includes('expenses') || isReadOnly}
              currentTab={currentTab}
            />
            {currentTab === 'CAR_SHIPMENT' && (
              <>
                <Box sx={{ paddingTop: '16px' }}>
                  <CarShipmentVehicles
                    hhgSegmentVehicles={vehicles}
                    estimateId={estimateId}
                    authorizationId={id}
                    isReadOnly={location.pathname.includes('expenses') || isReadOnly}
                  />
                </Box>
                <Box sx={{ paddingTop: '16px' }}>
                  <CarShipmentMoveDates estimateInfo={estimateInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} />
                </Box>
              </>
            )}
            {currentTab === 'GOODS_SHIPMENT' && (
              <Box sx={{ paddingTop: '16px' }}>
                <GoodShipmentMoveDates estimateInfo={estimateInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} />
              </Box>
            )}
            {currentTab === 'LONG_TERM_STORAGE' && (
              <Box sx={{ paddingTop: '16px' }}>
                <LongTermStorageMoveDates estimateInfo={estimateInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <VendorInformation numCols={3} estimateInfo={estimateInfo} hideEdit={location.pathname.includes('expenses') || isReadOnly} />
            <Box sx={{ paddingTop: '16px' }}>
              {currentTab === 'CAR_SHIPMENT' && (
                <CarShipmentEstimates
                  estimateInfo={estimateInfo}
                  itemsTotal={itemsTotal}
                  hideEdit={location.pathname.includes('expenses') || isReadOnly}
                />
              )}
              {currentTab === 'GOODS_SHIPMENT' && (
                <GoodsShipmentEstimates
                  estimateInfo={estimateInfo}
                  itemsTotal={itemsTotal}
                  hideEdit={location.pathname.includes('expenses') || isReadOnly}
                />
              )}
              {currentTab === 'LONG_TERM_STORAGE' && (
                <LongTermStorageEstimates
                  estimateInfo={estimateInfo}
                  itemsTotal={itemsTotal}
                  hideEdit={location.pathname.includes('expenses') || isReadOnly}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      {isLoadingEstimates && <Spinner />}
    </Box>
  );
};

EstimatesActuals.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default compose(withStyles(styles), connect(mapStateToProps, {}))(EstimatesActuals);
