import { Checkbox } from '@mui/material';
import { POST_GLOBAL_INFO_FAILURE, UPDATE_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

import { postFamilyMember, updateFamilyMember } from 'modules/authorizations/store/actions/authorizations.actions';
import { UPDATE_FAMILY_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';

const ImmunizationCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { globalInfo, id, isReadOnly, familyMemberId } = props;

  const tabs = [
    { header: 'Permit and Immunization', order: 1, iconPath: ['fas', 'syringe'] },
  ];

  const summaries = [
    { header: 'Work Permit', accessorKey: 'workPermit', type: 'checkbox', required: false, value: globalInfo ? globalInfo.workPermit : '', tab: 1, Cell: ({ cell }) => <Checkbox name="workPermit" label="Active" checked={cell.row.original.workPermit} disabled={true} /> },
    { header: 'Residency Permit', accessorKey: 'residencyPermit', type: 'checkbox', required: false, value: globalInfo ? globalInfo.residencyPermit : '', tab: 1, Cell: ({ cell }) => <Checkbox name="residencyPermit" label="Active" checked={cell.row.original.residencyPermit} disabled={true} /> },
    { header: 'Immunization Required', accessorKey: 'immunizationRequired', type: 'checkbox', required: false, value: globalInfo ? globalInfo.immunizationRequired : '', tab: 1, Cell: ({ cell }) => <Checkbox name="immunizationRequired" label="Active" checked={cell.row.original.immunizationRequired} disabled={true} /> },
    { header: 'Immunization Filed', accessorKey: 'immunizationFiled', type: 'checkbox', required: false, value: globalInfo ? globalInfo.immunizationFiled : '', tab: 1, Cell: ({ cell }) => <Checkbox name="immunizationFiled" label="Active" checked={cell.row.original.immunizationFiled} disabled={true} /> },
    { header: 'Work Permit Acquired', accessorKey: 'workPermitAcquired', type: 'date', required: false, value: globalInfo ? globalInfo.workPermitAcquired : '', tab: 1 },
    { header: 'Residency Permit Acquired', accessorKey: 'residencyPermitAcquired', type: 'date', required: false, value: globalInfo ? globalInfo.residencyPermitAcquired : '', tab: 1 },
    { header: 'Immunization Filed Date', accessorKey: 'immunizationFiledDate', type: 'date', required: false, value: globalInfo ? globalInfo.immunizationFiledDate : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (globalInfo && globalInfo.length !== 0) {
      let newValues = { ...globalInfo };


      newValues.workPermit = values.workPermit;
      newValues.residencyPermit = values.residencyPermit;
      newValues.immunizationRequired = values.immunizationRequired;
      newValues.immunizationFiled = values.immunizationFiled;
      newValues.workPermitAcquired = values.workPermitAcquired;
      newValues.residencyPermitAcquired = values.residencyPermitAcquired;
      newValues.immunizationFiledDate = values.immunizationFiledDate;

      if (familyMemberId === 0) {
        const resp = await props.updateGlobalInfo(newValues);
        if (resp.type === UPDATE_GLOBAL_INFO_FAILURE) {
          props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      } else {
        const resp = await props.updateFamilyMember(newValues);
        if (resp.type === UPDATE_FAMILY_FAILURE) {
          props.showToast('Failed to update family member info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      }

    } else {
      values.authorizationId = id;

      if (familyMemberId === 0) {
        const resp = await props.postGlobalInfo(values);
        if (resp.type === POST_GLOBAL_INFO_FAILURE) {
          props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      } 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    updateFamilyMember,
    postFamilyMember,
    showToast,
  }),
)(ImmunizationCard);