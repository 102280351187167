import { InitalizationFormFields, MovedOutAndMovedInFormFields, PropertyFormFields } from '../TempLivingConstants';
import { New } from '../TempLivingConstants';
import { getNextStatus, getStatusKey } from './tempLivingConstantsUtils';

export const createCompleteAuthorizationStepRequest = (assignment, status) => {
  const nextStatus = getNextStatus(status);
  if (!nextStatus) return assignment;

  assignment.activeStatus = getStatusKey(nextStatus);

  if (nextStatus?.dateFieldName && nextStatus?.dateFieldName in assignment) {
    assignment[nextStatus?.dateFieldName] = new Date().toISOString();
  }
  return assignment;
};

export const createNewInitializationFormRequest = (values, authorizationId) => {
  const { assignmentType, moveToCity } = values;

  const displayName = `${assignmentType} - ${moveToCity}`;
  const assignmentCreatedOn = new Date().toISOString();
  const newAssignment = {
    ...values,
    displayName,
    activeStatus: New,
    authorizationId,
    assignmentCreatedOn,
  };

  return newAssignment;
};

export const createUpdateInitializationFormRequest = (existingAssignment, values) => {
  const updatedAssignment = updateAssignmentFields(existingAssignment, values, InitalizationFormFields);
  updatedAssignment.displayName = `${values.assignmentType} - ${values.moveToCity}`;

  return updatedAssignment;
};

export const createUpdateMovedOutAndMovedInFormRequest = (existingAssignment, values) => {
  return updateAssignmentFields(existingAssignment, values, MovedOutAndMovedInFormFields);
};

export const createUpdateTempLivingPropertyRequest = (existingAssignment, values) => {
  return updateAssignmentFields(existingAssignment, values, PropertyFormFields);
};

const updateAssignmentFields = (currentAssignment, values, fieldsToUpdate) => {
  const updatedAssignment = { ...currentAssignment };

  fieldsToUpdate.forEach((field) => {
    if (field in values) {
      updatedAssignment[field] = values[field];
    }
  });
  return updatedAssignment;
};
