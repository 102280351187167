import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const HomeInfoCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const communicationTabs = [
    { header: 'Home Info', order: 1, iconPath: ['fas', 'house-user'] },
  ];

  const homeTypeOptions = [
    { title: 'Condo', id: 1 },
    { title: 'Town Home', id: 2 },
    { title: 'Single Family', id: 3 },
    { title: 'Mobile Home', id: 4 },
    { title: 'Other', id: 5 },
  ];

  const basementOptions = [
    { title: 'Finished - Full', id: 1 },
    { title: 'Finished - Partial', id: 2 },
    { title: 'Unfinished - Full', id: 3 },
    { title: 'Unfinished - Partial', id: 4 },
    { title: 'None', id: 5 },
  ];

  const maritalOptions = [
    { title: 'Single', id: 1 },
    { title: 'Married', id: 2 },
    { title: 'Divorced', id: 3 },
    { title: 'Separated', id: 4 },
    { title: 'Widowed', id: 5 },
    { title: 'Other', id: 6 },
  ];

  const communicationSummaries = [
    { header: 'Home Type', accessorKey: 'homeType', type: 'dropdown-v2', options: homeTypeOptions, required: false, value: segmentInfo ? segmentInfo.homeType : '', tab: 1 },
    { header: 'Basement', accessorKey: 'basement', type: 'dropdown-v2', options: basementOptions, required: false, value: segmentInfo ? segmentInfo.basement : '', tab: 1 },
    { header: 'Marital Status', accessorKey: 'maritalStatus', type: 'dropdown-v2', options: maritalOptions, required: false, value: segmentInfo ? segmentInfo.maritalStatus : '', tab: 1 },
    { header: 'Square Footage', accessorKey: 'squareFootage', type: 'number', required: false, value: segmentInfo ? segmentInfo.squareFootage : '', tab: 1 },
    { header: 'Number of Children', accessorKey: 'numberOfChildren', type: 'number', required: false,  value: segmentInfo ? segmentInfo.numberOfChildren : '', tab: 1 },
    { header: 'Number of Rooms', accessorKey: 'numberOfRooms', type: 'number', required: false, value: segmentInfo ? segmentInfo.numberOfRooms : '', tab: 1 },
    { header: 'Number of Bedrooms', accessorKey: 'numberOfBedrooms', type: 'number', required: false, value: segmentInfo ? segmentInfo.numberOfBedrooms : '', tab: 1 },
    { dividerLine: true },
    { header: 'Moved Out', accessorKey: 'moveOut', type: 'date', required: false, value: segmentInfo ? segmentInfo.moveOut : '', tab: 1 },
    { header: 'Moved In', accessorKey: 'moveIn', type: 'date', required: false, value: segmentInfo ? segmentInfo.moveIn : '', tab: 1 },
    { header: 'Car Delivery', accessorKey: 'carDelivery', type: 'date', required: false, value: segmentInfo ? segmentInfo.carDelivery : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newVals = { ...segmentInfo };
    newVals.basement = values.basement;
    newVals.carDelivery = values.carDelivery;
    newVals.homeType = values.homeType;
    newVals.maritalStatus = values.maritalStatus;
    newVals.moveIn = values.moveIn;
    newVals.moveOut = values.moveOut;
    newVals.numberOfBedrooms = values.numberOfBedrooms;
    newVals.numberOfChildren = values.numberOfChildren;
    newVals.numberOfRooms = values.numberOfRooms;
    newVals.squareFootage = values.squareFootage;
        
    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newVals);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={communicationTabs}
      summaries={communicationSummaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(HomeInfoCard);