import { array, boolean, object, string } from 'yup';
import { chain } from 'lodash';

import { formatForWire, isNotDefined, parseNumbers } from 'utilities/common';

import { Contact, Pet } from '.';
import { MAX_LENGTH_ERROR_MESSAGE, REQUIRED_NUMBER } from 'modules/common/constants';

export default class TemporaryLivingInformation {
  static schema = object().shape({
    isTemporaryLivingServiceInitiated: boolean(),
    occupants: array().of(object().shape({
      id: REQUIRED_NUMBER,
    })),
    specialRequestsPreferences: string().max(1000, MAX_LENGTH_ERROR_MESSAGE),
    costCap: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
    bedroomsPreferred: string(),
  });

  constructor(wireData, contacts, pets) {
    const json = wireData || {};
    this.id = json.id;
    this.isTemporaryLivingServiceInitiated = !!json.isTemporaryLivingServiceInitiated;
    this.specialRequestsPreferences = json.specialRequestsPreferences || '';
    this.bedroomsPreferred = isNotDefined(json.bedroomsPreferred) ? '' : json.bedroomsPreferred;

    const temporaryLivingPets = json.pets && pets ? pets.filter((pet) => json.pets.some((occupantId) => pet.id === occupantId)) : [];
    const temporaryLivingContacts = json.contacts && contacts ? contacts.filter((contact) => json.contacts.some((occupantId) => contact.id === occupantId)) : [];

    this.occupants = [...temporaryLivingPets, ...temporaryLivingContacts];
  }

  static fromWireData(wireData, contacts, pets) {
    return new TemporaryLivingInformation(wireData, contacts, pets);
  }

  static generateErrors(errors) {
    return errors;
  }

  isPopulated() {
    return this.specialRequestsPreferences ||
      this.bedroomsPreferred ||
      this.occupants.length > 0;
  }

  toWireData(contacts, pets) {
    return formatForWire({
      id: this.id,
      isTemporaryLivingServiceInitiated: this.isTemporaryLivingServiceInitiated,
      specialRequestsPreferences: this.specialRequestsPreferences,
      pets: chain(this.occupants)
        .filter((occupant) => pets.find((instance) => instance.id === occupant.id && occupant instanceof Pet))
        .map('id')
        .value(),
      contacts: chain(this.occupants)
        .filter((occupant) => contacts.find((instance) => instance.id === occupant.id && occupant instanceof Contact))
        .map('id')
        .value(),
      ...parseNumbers({
        bedroomsPreferred: this.bedroomsPreferred,
      }),
    });
  }
}
