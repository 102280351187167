export const createUpdatePayrollApiRequest = (expenseLineItem, formValues) => {
  return {
    ...expenseLineItem,
    stateExcludableAmount: parseFormValue(formValues.stateExcludableAmount, ValueType.FloatType),
    payrollInProcessDate: parseFormValue(formValues.payrollInProcessDate, ValueType.DateTimeType),
    payrollPostedDate: parseFormValue(formValues.payrollPostedDate, ValueType.DateTimeType),
    payrollType: formValues.payrollType,
    earningsCode: formValues.earningsCode,
    taxState: formValues.taxState,
    payAmount: parseFormValue(formValues.payAmount, ValueType.FloatType),
    medicareTaxAssistanceOriginalAmount: parseFormValue(formValues.medicareTaxAssistanceOriginalAmount, ValueType.FloatType),
    medicareWithholdingOriginalAmount: parseFormValue(formValues.medicareWithholdingOriginalAmount, ValueType.FloatType),
    medicareTaxAssistanceSurtaxAmount: parseFormValue(formValues.medicareTaxAssistanceSurtaxAmount, ValueType.FloatType),
    medicareWithholdingSurtaxAmount: parseFormValue(formValues.medicareWithholdingSurtaxAmount, ValueType.FloatType)
  };
};

export const createUpdateTaxesApiRequest = (expenseLineItem, formValues) => {
  let response = { ...expenseLineItem };
  switch (formValues.id) {
    case 'Federal':
      response.federalTaxAssistanceAmount = parseFormValue(formValues.taxAssistanceAmount, ValueType.FloatType);
      response.federalWithholdingAmount = parseFormValue(formValues.withholdingAmount, ValueType.FloatType);
      response.federalReportableIncomeAmount = parseFormValue(formValues.reportableIncomeAmount, ValueType.FloatType);
      break;
    case 'State':
      response.stateTaxAssitanceAmount = parseFormValue(formValues.taxAssistanceAmount, ValueType.FloatType);
      response.stateWithholdingAmount = parseFormValue(formValues.withholdingAmount, ValueType.FloatType);
      response.stateReportableIncomeAmount = parseFormValue(formValues.reportableIncomeAmount, ValueType.FloatType);
      break;
    case 'Soc Sec':
      response.socialSecurityAssitanceAmount = parseFormValue(formValues.taxAssistanceAmount, ValueType.FloatType);
      response.socialSecurityWithholdingAmount = parseFormValue(formValues.withholdingAmount, ValueType.FloatType);
      response.socialSecurityReportableIncomeAmount = parseFormValue(formValues.reportableIncomeAmount, ValueType.FloatType);
      break;
    case 'Medicare':
      response.medicareAssitanceAmount = parseFormValue(formValues.taxAssistanceAmount, ValueType.FloatType);
      response.medicareWithholdingAmount = parseFormValue(formValues.withholdingAmount, ValueType.FloatType);
      response.medicareReportableIncomeAmount = parseFormValue(formValues.reportableIncomeAmount, ValueType.FloatType);
      break;
    default:
      break;
  }
  return response;
};

//TODO - Once we have a DB, we can do all these in one API request
export const createUpdateCustomTaxApiRequest = (customTax, formValues) => {
  return {
    ...customTax,
    displayName: formValues.displayName,
    taxAssistanceAmount: parseFormValue(formValues.taxAssistanceAmount, ValueType.FloatType),
    withholdingAmount: parseFormValue(formValues.withholdingAmount, ValueType.FloatType),
    reportableIncomeAmount: parseFormValue(formValues.reportableIncomeAmount, ValueType.FloatType)
  };
};

export const createPostCustomTaxApiRequest = (formValues, expenseLineItemId) => {
  return {
    expenseLineId: expenseLineItemId,
    displayName: formValues.displayName,
    taxAssistanceAmount: parseFormValue(formValues.taxAssistanceAmount, ValueType.FloatType),
    withholdingAmount: parseFormValue(formValues.withholdingAmount, ValueType.FloatType),
    reportableIncomeAmount: parseFormValue(formValues.reportableIncomeAmount, ValueType.FloatType)
  };
};

export const ValueType = {
  DateTimeType: 0,
  IntType: 1,
  FloatType: 2,
  MoneyType: 3,
  StringType: 4
};

export const parseFormValue = (value, valueType) => {
  if (!value || value === '') return null;

  switch (valueType) {
    case ValueType.DateTimeType:
      return value;
    case ValueType.IntType:
      return parseInt(value);
    case ValueType.FloatType:
      return parseFloat(value);
    default:
      console.log('Invalid Type');
      return null;
  }
};
