/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Chip, FormControlLabel, MenuItem, Select, TextField, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClientHomesaleGeneralInfo, updateClientHomesaleGeneralInfo } from '../../clients.actions';

import { showToast } from 'modules/layout/layout.actions';

// MOVE ALL HOMESALE INFO TO BE PROVIDED BY PARENT, WILL FIX NOT UODATING STATE ISSUE

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    accordionStyleContacts: {
      maxWidth: 1050,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
    contractsGrid: {
      width: '100%',
      maxWidth: 900,
      marginTop: theme.spacing(4),
    },
    detailsLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    contentCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    firstCol: {
      width: 200,
    },
    textField: {
      minWidth: 350,
    },
    descText: {
      minWidth: 350,
      marginBottom: 0,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 16,
    },
    checkbox: {
      paddingLeft: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    tabBorder: {
      borderLeft: `2px solid ${theme.palette.grey[400]}`,
      marginLeft: theme.spacing(2),
    },
  };
};

const HomeSaleMarketingAssistance = (props) => {

  const clientHomesaleMarketingAssistance = props.clientHomesaleGeneralInfo ? props.clientHomesaleGeneralInfo.clientHomesaleMarketingAssistance : null;

  const [isEnabled, setIsEnabled] = useState(clientHomesaleMarketingAssistance ? clientHomesaleMarketingAssistance.isEnabled : false);
  const [homeMarketingAssistanceElgibility, setHomeMarketingAssistanceElgibility] = useState(clientHomesaleMarketingAssistance && clientHomesaleMarketingAssistance.homeMarketingAssistanceElgibility ? clientHomesaleMarketingAssistance.homeMarketingAssistanceElgibility.split(',') : []);
  const [marketingAssistanceTiedTo, setMarketingAssistanceTiedTo] = useState(clientHomesaleMarketingAssistance ? clientHomesaleMarketingAssistance.marketingAssistanceTiedTo : null);
  const [maximumVariance, setMaximumVariance] = useState(clientHomesaleMarketingAssistance ? clientHomesaleMarketingAssistance.maximumVariance : null);
  const [isThirdOrdered, setIsThirdOrdered] = useState(clientHomesaleMarketingAssistance ? clientHomesaleMarketingAssistance.isThirdOrdered : null); 
  const [thirdOrderedDetermined, setThirdOrderedDetermined] = useState(clientHomesaleMarketingAssistance ? clientHomesaleMarketingAssistance.thirdOrderedDetermined : null);
  const [otherNotes, setOtherNotes] = useState(clientHomesaleMarketingAssistance ? clientHomesaleMarketingAssistance.otherNotes : null);

  useEffect(() => {
    const updateBody = {
      ClientId: props.clientId,
      IsEnabled: isEnabled,
      HomeMarketingAssistanceElgibility: homeMarketingAssistanceElgibility.join(','),
      MarketingAssistanceTiedTo: marketingAssistanceTiedTo,
      MaximumVariance: maximumVariance,
      IsThirdOrdered: isThirdOrdered,
      ThirdOrderedDetermined: thirdOrderedDetermined,
      OtherNotes: otherNotes,
    };

    if (clientHomesaleMarketingAssistance && clientHomesaleMarketingAssistance.id) updateBody.Id = clientHomesaleMarketingAssistance.id;

    props.updateInfo(updateBody);
  }, [isEnabled, homeMarketingAssistanceElgibility, marketingAssistanceTiedTo, maximumVariance, isThirdOrdered, thirdOrderedDetermined, otherNotes]);

  return (
    <div className={props.classes.contractsGrid}>
      <div className={props.classes.headerRow}>
        <h3>Marketing Assistance Only</h3>
        <FormControlLabel
          control={
            <Checkbox
              name="marketingAssistanceOnly"
              label="Activate Service"
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
          }
          label="Activate Service"
          classes={{ root: props.classes.checkbox }}
        />
      </div>
      {isEnabled && (
        <div className={props.classes.tabBorder}>
          <form noValidate autoComplete="off" className={props.classes.detailsLayout}>
            <div className={classNames(props.classes.contentCol, props.classes.firstCol)}>
              <p className={props.classes.descText}>Who is eligible for Home Marketing Assistance?</p>
              <Select
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Who is Eligible?'}
                onChange={(e) => setHomeMarketingAssistanceElgibility(e.target.value)}
                multiple
                value={homeMarketingAssistanceElgibility}
                renderValue={(selected) => {
                  return (
                    <div className={props.classes.chips}>
                      {selected.map((value) => {
                        const policyInfo = props.clientPolicyNotes.find((o) => o.clientPolicyNotesKey === parseInt(value));
                        return (
                          <Chip
                            key={policyInfo.clientPolicyNotesKey}
                            label={policyInfo.name}
                            className={props.classes.chip}
                            onDelete={() => {
                              setHomeMarketingAssistanceElgibility(homeMarketingAssistanceElgibility.filter((b) => b !== value));
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        );
                      })}
                    </div>
                  );}}
              >
                {props.clientPolicyNotes && props.clientPolicyNotes.map((c) => {
                  return <MenuItem key={c.clientPolicyNotesKey} value={c.clientPolicyNotesKey}>{c.name}</MenuItem>;
                })}
              </Select>
              <p className={props.classes.descText}>Is Marketing Assistance tied to an incentive, bonus or loss program?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Marketing Assistance Tied To?'}
                onChange={(e) => setMarketingAssistanceTiedTo(e.target.value)}
                required
                select
                value={marketingAssistanceTiedTo}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'na'}>N/A</MenuItem>
              </TextField>
              <p className={props.classes.descText}>What is the maximum variance between Broker Market Analysis (BMA)? (5% recommended)</p>
              <TextField
                id="name"
                label="Maximum Variance?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={maximumVariance}
                onChange={(e) => setMaximumVariance(e.target.value)}
              />
            </div>

            <div className={props.classes.contentCol}>
              <p className={props.classes.descText}>If BMAs are not within acceptable variance, is a 3rd ordered?</p>
              <TextField
                className={props.classes.textField}
                variant="outlined"
                fullWidth
                label={'Is a 3rd Ordered?'}
                onChange={(e) => setIsThirdOrdered(e.target.value)}
                required
                select
                value={isThirdOrdered}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'na'}>N/A</MenuItem>
              </TextField>
              <p className={props.classes.descText}>If a 3rd is ordered, how is the listing determined?</p>
              <TextField
                id="name"
                label="Listing Determined?"
                className={props.classes.textField}
                variant="outlined"
                style={{ width: '100%', marginRight: 4 }}
                value={thirdOrderedDetermined}
                onChange={(e) => setThirdOrderedDetermined(e.target.value)}
              />
              <TextField
                variant="outlined"
                label={'Other Notes'}
                value={otherNotes}
                multiline
                minRows={5}
                sx={{ width: '1100px' }}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
              {/* <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {updateGeneralInfo();}}
                  >
                      Save Info
                  </Button> */}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}; 

HomeSaleMarketingAssistance.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { clientPolicyNotes } = state.clients.clientsInfo[state.clients.currentClientId];
  const { clientHomesaleGeneralInfoLoading } = state.clients;
  return {
    clientHomesaleGeneralInfoLoading,
    clientPolicyNotes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getClientHomesaleGeneralInfo,
    showToast,
    updateClientHomesaleGeneralInfo,
  }),
)(HomeSaleMarketingAssistance);