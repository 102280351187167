import {
    Typography,
    withStyles,
    withTheme,
  } from '@material-ui/core';
      
  import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
  import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
  import {
    IntegratedSorting,
    SortingState,
  } from '@devexpress/dx-react-grid';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
      
  import { compose } from 'recompose';
  import React, { PureComponent } from 'react';
  import classNames from 'classnames';
      
  import { formatDateGeneric } from 'utilities/common';
  import Log from 'utilities/log';
  import Spinner from 'modules/common/spinner.component';
      
  const styles = (theme) => {
    const intakeRecordBorder = `1px solid ${theme.palette.grey[500]}`;
    return {
      table: {
        borderSpacing: theme.spacing(0, 2),
      },
      tableHead: {
        height: 32,
      },
      cell: {
        borderTop: intakeRecordBorder,
        borderBottom: intakeRecordBorder,
        borderLeft: 0,
        borderRight: 0,
        '&:first-child': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderLeft: intakeRecordBorder,
        },
        '&:last-child': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
          borderRight: intakeRecordBorder,
        },
      },
      urgencyMenuItem: {
        justifyContent: 'center',
      },
      selectInput: {
        lineHeight: 1.75,
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
      },
      row: {
        backgroundColor: theme.palette.common.white,
      },
      noDataCell: {
        paddingBottom: theme.spacing(12),
        paddingTop: theme.spacing(12),
        textAlign: 'center',
      },
    };
  };
      
  const FILTER_DISABLED_COLUMNS = ['organization'];

  const statusOptions = [
    { title: 'Active', id: 1 },
    { title: 'Inactive', id: 2 },
  ];

  const vendorTypes = [
    { title: 'Inspectors', id: 1 },
    { title: 'School', id: 2 },
    { title: 'Insp', id: 3 },
    { title: 'Brk', id: 4 },
    { title: 'Hhg', id: 5 },
    { title: 'Spc', id: 6 },
    { title: 'Tax', id: 7 },
    { title: 'Oth', id: 8 },
    { title: 'TenancyMgmt', id: 9 },
    { title: 'TI', id: 10 },
    { title: 'Client', id: 11 },
    { title: 'Hoa', id: 12 },
    { title: 'Mtg', id: 13 },
    { title: 'Insur', id: 14 },
    { title: 'Util', id: 15 },
    { title: 'App', id: 16 },
    { title: 'Tp', id: 17 },
    { title: 'Pet', id: 18 },
    { title: 'Dsc', id: 19 },
    { title: 'Division', id: 20 },
  ];
      
  class VendorsGrid extends PureComponent {
        static defaultProps = {
          isLoading: false,
        };

        constructor(props) {
          super(props);
                
          this.state = {
            columns: [
              { name: 'vendorId', title: 'Vendor ID' },
              { name: 'companyName', title: 'Company Name' },
              { name: 'status', title: 'Status', getCellValue: (record) => <Typography>{statusOptions.find((o) => o.id === record.status)?.title}</Typography> },
              { name: 'address', title: 'Address' },
              { name: 'city', title: 'City' },
              { name: 'state', title: 'State' },
              { name: 'zip', title: 'Zip' },
              { name: 'vendorType', title: 'Vendor Type', getCellValue: (record) => <Typography>{vendorTypes.find((o) => o.title === record.vendorType)?.title}</Typography> },
            ],
            filters: [],
            sorting: [{ columnName: 'invoiceDate', direction: 'desc' }],
            integratedFilteringColumnExtensions: [
              { columnName: 'details', predicate: this.keyPredicate },
            ],
          };
    
          this.changeSorting = (sorting) => this.setState({ sorting });
    
        }
  
        keyPredicate = (value, filter) => {
          const valueLower = (value.key || '').toLowerCase();
          const filterLower = (filter.value || '').toLowerCase();
          return valueLower.indexOf(filterLower) > -1;
        };
  
        formatDate = (key) => (record) => {
          return formatDateGeneric(record[key]);
        };
    
        cellClicked = (id) => {
          this.props.history.push(`/vnedors/${id}`);
        }

        getNumExpenseLines = (record) => {
            const { expenseLines } = this.props;
            const lines = expenseLines.filter(instance => instance.expenseId === record.expenseId);
            return lines ? lines.length : 0;
        };

        getSetUrgencyCellValue = (record) => {
            const { theme: { palette } } = this.props;
            let date = record.dateSubmitted ? new Date(record.dateSubmitted) : null;
            let today = new Date();
            const diffTime = date ? Math.abs(today - date) : null;
            const diffDays = diffTime ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : null;
    
            return (
                <div>
                {(record.status === 1 && diffDays >= 3) && (
                    <FontAwesomeIcon color={palette.primary.main} icon={['fas', 'exclamation-circle']} size="lg" title="Not viewed for the past 3 business days or more."  />
                )}
                {(record.status === 1 || record.status === 2) && (
                    <FontAwesomeIcon color={palette.warning.main} icon={['fas', 'fa-circle']} size="lg" title="Not viewed." />
                )}
                {record.status === 3 && (
                    <FontAwesomeIcon color={palette.error.main} icon={['fas', 'exclamation-triangle']} size="lg" title="On Hold." />
                )}
                {record.status === 4 && (
                <FontAwesomeIcon color={palette.primary.main} icon={['fas', 'check-circle']} size="lg" title="Viewed." />
                )}
                </div>
            );
        };
      
        changeFilters = (filters) => this.setState({ filters });
      
        filterCell = (props) => {
          return props.column.title && !FILTER_DISABLED_COLUMNS.includes(props.column.name) ?
            <TableFilterRow.Cell {...props} /> : null;
        };
      
        headerCell = (props) => {
          return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
        };
      
        headerRowComponent = (props) => {
          return <TableHeaderRow.Row className={this.props.classes.tableHead} {...props} />;
        };
      
        rowComponent = (props) => {
          return <Table.Row className={this.props.classes.row} {...props} />;
        };
      
        cellComponent = (props) => {
          let clickHandler = () => this.props.history.push(`/vendors/${props.row.vendorId}`); // Add handler for when a client is clicked
          let pointerClass = 'handles-click';
          const className = classNames(this.props.classes.cell, pointerClass);
          return <Table.Cell onClick={clickHandler} className={className} {...props} />;
        };
      
        tableComponent = (props) => {
          return <Table.Table className={this.props.classes.table} {...props} />;
        };
      
        noDataCellComponent = (props) => {
          const content = this.props.isLoading ? <Spinner logo /> : 'No Records were returned.  Check the filters above.';
          // need to remove the getMessage function to avoid exception
          // eslint-disable-next-line no-unused-vars
          const { getMessage, ...rest } = props;
          return (
            <Table.Cell {...rest} className={this.props.classes.noDataCell}>
              <Typography>{content}</Typography>
            </Table.Cell>
          );
        };
      
        render() {
          Log.trace('RENDER', 'vendorsGrid');
          const { vendors } = this.props;
          const {
            columns,
            filters,
            tableColumnExtensions,
            integratedFilteringColumnExtensions,
            sorting,
          } = this.state;
      
          return (
            <Grid
              rows={vendors}
              columns={columns}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
                
              <FilteringState
                filters={filters}
                onFiltersChange={this.changeFilters}
              />
              <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
              <IntegratedSorting />
              <Table
                columnExtensions={tableColumnExtensions}
                rowComponent={this.rowComponent}
                tableComponent={this.tableComponent}
                cellComponent={this.cellComponent}
                noDataCellComponent={this.noDataCellComponent}
              />
              <TableHeaderRow rowComponent={this.headerRowComponent} cellComponent={this.headerCell} showSortingControls />
              <TableFilterRow cellComponent={this.filterCell} />
            </Grid>
          );
        }
  }
      
  export default compose(
    withStyles(styles),
    withTheme,
  )(VendorsGrid);
      