export const GENERAL_INFO = 'GENERAL_INFO';
export const TASKS = 'TASKS';
export const CONTRACT = 'CONTRACT';
export const INTEREST = 'INTEREST';
export const BUSCODES = 'BUSCODES';
export const FEES = 'FEES';
export const PAYROLL = 'PAYROLL';
export const CUSTOM = 'CUSTOM';
export const OPTIONS = 'OPTIONS';
export const USERS = 'USERS';
export const POLICIES = 'POLICIES';

export const HEADER_HEIGHT = 110;
export const HEADER_HEIGHT_SHRINK = 94;
export const HEADER_HEIGHT_CLIENT = 120;