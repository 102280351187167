import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateEquity } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const EquityDetailsCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { equityInfo, isReadOnly } = props;

  const tabs = [
    { header: 'Primary Mortgage', order: 1, iconPath: ['fas', 'file-contract'] },
    { header: 'Secondary Mortgage', order: 2, iconPath: ['fas', 'file-contract'] },
    { header: 'Other Loans', order: 3, iconPath: ['fas', 'file-contract'] },
  ];

  const mortgageTypeOptions = [
    { title: 'Assumption', id: 1 },
    { title: 'Payoff', id: 2 },
  ];

  const prorationDaysOptions = [
    { title: 'N/A', id: 1 },
    { title: '360', id: 2 },
    { title: '365', id: 3 },
  ];

  const summaries = [
    { header: 'Lender Name', accessorKey: 'primaryLenderName', type: 'text', required: false, value: equityInfo ? equityInfo.primaryLenderName : '', tab: 1 },
    { header: 'Mortgage Balance', accessorKey: 'primaryMortgageBalance', type: 'money', required: false, value: equityInfo ? equityInfo.primaryMortgageBalance : '', tab: 1 },
    { header: 'Type', accessorKey: 'primaryType', type: 'dropdown-v2', required: false, options: mortgageTypeOptions, value: equityInfo ? equityInfo.primaryType : '', tab: 1 },
    { header: 'Escrow Balance', accessorKey: 'primaryEscrowBalance', type: 'money', required: false, value: equityInfo ? equityInfo.primaryEscrowBalance : '', tab: 1 },
    { header: 'Escrow Amount', accessorKey: 'primaryEscrowAmount', type: 'money', required: false, value: equityInfo ? equityInfo.primaryEscrowAmount : '', tab: 1 },
    { header: 'Interest Rate', accessorKey: 'primaryInterestRate', type: 'number', required: false, value: equityInfo ? equityInfo.primaryInterestRate : '', tab: 1 },
    { header: 'Last Payment', accessorKey: 'primaryLastPayment', type: 'date', required: false, value: equityInfo ? equityInfo.primaryLastPayment : '', tab: 1 },
    { header: 'Payment', accessorKey: 'primaryPayment', type: 'money', required: false, value: equityInfo ? equityInfo.primaryPayment : '', tab: 1 },
    { header: 'Interest Due From', accessorKey: 'primaryInterestDueFrom', type: 'date', required: false, value: equityInfo ? equityInfo.primaryInterestDueFrom : '', tab: 1 },
    { header: 'P & I Payment', accessorKey: 'primaryPAndIPayment', type: 'money', required: false, value: equityInfo ? equityInfo.primaryPAndIPayment : '', tab: 1 },
    { header: 'Int Proration Date', accessorKey: 'primaryIntProration', type: 'date', required: false, value: equityInfo ? equityInfo.primaryIntProration : '', tab: 1 },
    { header: 'Int Proration Amount', accessorKey: 'primaryIntProrationAmount', type: 'money', required: false, value: equityInfo ? equityInfo.primaryIntProrationAmount : '', tab: 1 },
    { header: 'Principal Amount', accessorKey: 'primaryPrincipalAmount', type: 'money', required: false, value: equityInfo ? equityInfo.primaryPrincipalAmount : '', tab: 1 },
    { header: 'Desc Code', accessorKey: 'primaryDescCode', type: 'text', required: false, value: equityInfo ? equityInfo.primaryDescCode : '', tab: 1 },
    { header: 'Interest Amount', accessorKey: 'primaryInterestAmount', type: 'money', required: false, value: equityInfo ? equityInfo.primaryInterestAmount : '', tab: 1 },
    { header: 'Proration Days', accessorKey: 'primaryProrationDays', type: 'dropdown-v2', options: prorationDaysOptions, required: false, value: equityInfo ? equityInfo.primaryProrationDays : '', tab: 1 },

    { header: 'Lender Name', accessorKey: 'secondaryLenderName', type: 'text', required: false, value: equityInfo ? equityInfo.secondaryLenderName : '', tab: 2 },
    { header: 'Lender Balance', accessorKey: 'secondaryLenderBalance', type: 'money', required: false, value: equityInfo ? equityInfo.secondaryLenderBalance : '', tab: 2 },
    { header: 'Type', accessorKey: 'secondaryType', type: 'dropdown-v2', required: false, options: mortgageTypeOptions, value: equityInfo ? equityInfo.secondaryType : '', tab: 2 }, // HERE ^
    { header: 'Next Int Payment Amt', accessorKey: 'secondaryNextIntPaymentAmount', type: 'money', required: false, value: equityInfo ? equityInfo.secondaryNextIntPaymentAmount : '', tab: 2 },
    { header: 'Interest Due From', accessorKey: 'secondaryInterestDueFrom', type: 'date', required: false, value: equityInfo ? equityInfo.secondaryInterestDueFrom : '', tab: 2 },
    { header: 'Desc Code', accessorKey: 'secondaryDescCode', type: 'text', required: false, value: equityInfo ? equityInfo.secondaryDescCode : '', tab: 2 },
    { header: 'Int Proration Amt', accessorKey: 'secondaryIntProrationAmount', type: 'money', required: false, value: equityInfo ? equityInfo.secondaryIntProrationAmount : '', tab: 2 },

    { header: 'Lender Name', accessorKey: 'otherLenderName', type: 'text', required: false, value: equityInfo ? equityInfo.otherLenderName : '', tab: 3 },
    { header: 'Loan Balance', accessorKey: 'otherLoanBalance', type: 'money', required: false, value: equityInfo ? equityInfo.otherLoanBalance : '', tab: 3 },
    { header: 'Type', accessorKey: 'otherType', type: 'dropdown-v2', options: mortgageTypeOptions, required: false, value: equityInfo ? equityInfo.otherType : '', tab: 3 },
    { header: 'Interest Amt', accessorKey: 'otherInterestAmount', type: 'money', required: false, value: equityInfo ? equityInfo.otherInterestAmount : '', tab: 3 },
    { header: 'Desc Code', accessorKey: 'otherDescCode', type: 'text', required: false, value: equityInfo ? equityInfo.otherDescCode : '', tab: 3 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...equityInfo };

    if (tab === 1) {
      newValues.primaryLenderName = values.primaryLenderName;
      newValues.primaryMortgageBalance = values.primaryMortgageBalance;
      newValues.primaryType = values.primaryType;
      newValues.primaryEscrowBalance = values.primaryEscrowBalance;
      newValues.primaryEscrowAmount = values.primaryEscrowAmount;
      newValues.primaryInterestRate = values.primaryInterestRate;
      newValues.primaryLastPayment = values.primaryLastPayment;
      newValues.primaryPayment = values.primaryPayment;
      newValues.primaryInterestDueFrom = values.primaryInterestDueFrom;
      newValues.primaryPAndIPayment = values.primaryPAndIPayment;
      newValues.primaryIntProration = values.primaryIntProration;
      newValues.primaryIntProrationAmount = values.primaryIntProrationAmount;
      newValues.primaryPrincipalAmount = values.primaryPrincipalAmount;
      newValues.primaryDescCode = values.primaryDescCode;
      newValues.primaryInterestAmount = values.primaryInterestAmount;
      newValues.primaryProrationDays = values.primaryProrationDays;
    } else if (tab === 2) {
      newValues.secondaryLenderName = values.secondaryLenderName;
      newValues.secondaryLenderBalance = values.secondaryLenderBalance;
      newValues.secondaryType = values.secondaryType;
      newValues.secondaryNextIntPaymentAmount = values.secondaryNextIntPaymentAmount;
      newValues.secondaryInterestDueFrom = values.secondaryInterestDueFrom;
      newValues.secondaryDescCode = values.secondaryDescCode;
      newValues.secondaryIntProrationAmount = values.secondaryIntProrationAmount;
    } else {
      newValues.otherLenderName = values.otherLenderName;
      newValues.otherLoanBalance = values.otherLoanBalance;
      newValues.otherType = values.otherType;
      newValues.otherInterestAmount = values.otherInterestAmount;
      newValues.otherDescCode = values.otherDescCode;
    }

    const resp = await props.updateEquity(newValues);
    if (resp.type === UPDATE_LISTINGS_FAILURE) {
      props.showToast('Failed to update equity info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateEquity,
    showToast,
  }),
)(EquityDetailsCard);