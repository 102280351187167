/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import { Button, withStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { TextField } from 'modules/intake/form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    minWidth: 200,
  },
  tab: {
    width: 160,
    minWidth: 160,
  },
  tabsRoot: {
    marginBottom: theme.spacing(1),
  },
  newCalendarButtons: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
});  

const PeriodDates = (props) => {

  const periodStart = props.selectedPeriodData ? props.selectedPeriodData.periodStartDate?.split('T')[0] : null;
  const periodEnd = props.selectedPeriodData ? props.selectedPeriodData.periodEndDate?.split('T')[0] : null;

  return (
    <Box marginX={2} marginY={2}>
      <Formik
        initialValues={props.selectedPeriodData}
        enableReinitialize
        validateOnBlur={true}
        validateOnChange={true}
      >
        {(formikProps) => (
          <div>
            {formikProps.values && (
              <div>
                <h4>Time Frame</h4>
                <div>
                  <Field
                    component={TextField}
                    name={'periodStartDate'}
                    label={'Period Start'}
                    className={props.classes.root}
                    value={formikProps.values.periodStartDate?.split('T')[0]}
                    type="date"
                    disabled={true}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'periodEndDate'}
                    label={'Period End'}
                    className={props.classes.root}
                    value={formikProps.values.periodEndDate?.split('T')[0]}
                    type="date"
                    disabled={true}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                </div>
                        
                <h4>General</h4>
                <div>
                  <Field
                    component={TextField}
                    name={'actualExpenseCutoffDate'}
                    label={'Actual Expense Cutoff'}
                    className={props.classes.root}
                    value={formikProps.values.actualExpenseCutoffDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'actualPayrollSentDate'}
                    label={'Actual Payroll Sent'}
                    className={props.classes.root}
                    value={formikProps.values.actualPayrollSentDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'actualPayrollStartDate'}
                    label={'Actual Payroll Start'}
                    className={props.classes.root}
                    value={formikProps.values.actualPayrollStartDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'actualReadyForAuditDate'}
                    label={'Actual Ready For Audit'}
                    className={props.classes.root}
                    value={formikProps.values.actualReadyForAuditDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'actualWageRequestReceivedDate'}
                    label={'Actual Wage Request Received'}
                    className={props.classes.root}
                    value={formikProps.values.actualWageRequestReceivedDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'expenseCodesAuditedDate'}
                    label={'Expense Codes Audited'}
                    className={props.classes.root}
                    value={formikProps.values.expenseCodesAuditedDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'expenseCutoffDate'}
                    label={'Expense Cutoff'}
                    className={props.classes.root}
                    value={formikProps.values.expenseCutoffDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'payrollReconciledThroughDate'}
                    label={'Payroll Reconciled Through'}
                    className={props.classes.root}
                    value={formikProps.values.payrollReconciledThroughDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'payrollSentDate'}
                    label={'Payroll Sent'}
                    className={props.classes.root}
                    value={formikProps.values.payrollSentDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'payrollStartDate'}
                    label={'Payroll Start'}
                    className={props.classes.root}
                    value={formikProps.values.payrollStartDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'readyForAuditDate'}
                    label={'Ready for Audit'}
                    className={props.classes.root}
                    value={formikProps.values.readyForAuditDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'reconciledDate'}
                    label={'Reconciled'}
                    className={props.classes.root}
                    value={formikProps.values.reconciledDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'taxAssistanceUploadedThroughDate'}
                    label={'Tax Assistance Uploaded Through'}
                    className={props.classes.root}
                    value={formikProps.values.taxAssistanceUploadedThroughDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'numberOfEESent'}
                    label={'Number of EE Sent'}
                    className={props.classes.root}
                    value={formikProps.values.numberOfEESent}
                    type="number"
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'numberOfEEReceived'}
                    label={'Number of EE Received'}
                    className={props.classes.root}
                    value={formikProps.values.numberOfEEReceived}
                    type="number"
                    disabled={false}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                </div>

                <h4>Wage Request</h4>
                <div>
                  <Field
                    component={TextField}
                    name={'actualWageRequestPrepDate'}
                    label={'Actual Wage Request Prep'}
                    className={props.classes.root}
                    value={formikProps.values.actualWageRequestPrepDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'actualWageRequestSentDate'}
                    label={'Actual Wage Request Sent'}
                    className={props.classes.root}
                    value={formikProps.values.actualWageRequestSentDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'actualWageRequestUploadDate'}
                    label={'Actual Wage Request Upload'}
                    className={props.classes.root}
                    value={formikProps.values.actualWageRequestUploadDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'wageRequestPrepDate'}
                    label={'Wage Request Prep'}
                    className={props.classes.root}
                    value={formikProps.values.wageRequestPrepDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'wageRequestReceivedDate'}
                    label={'Wage Request Received'}
                    className={props.classes.root}
                    value={formikProps.values.wageRequestReceivedDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'wageRequestSentDate'}
                    label={'Wage Request Sent'}
                    className={props.classes.root}
                    value={formikProps.values.wageRequestSentDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                  <Field
                    component={TextField}
                    name={'wageRequestUploadDate'}
                    label={'Wage Request Upload'}
                    className={props.classes.root}
                    value={formikProps.values.wageRequestUploadDate?.split('T')[0]}
                    type="date"
                    disabled={false}
                    max={periodEnd}
                    min={periodStart}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                  />
                </div>

                <div className={props.classes.newCalendarButtons}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {props.updatePeriod(formikProps.values);}}
                    disabled={JSON.stringify(formikProps.values) === JSON.stringify(props.selectedPeriodData)}
                  >
                    Update Calendar
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Formik>
    </Box>
  );
};

PeriodDates.propTypes = {
  selectedPeriodData: PropTypes.object,
  periodDatesList: PropTypes.array,
};


// const mapStateToProps = (state) => {
//   const { clientCalendars, isLoading, periodData } = state.clients;
//   return {
//     clientCalendars,
//     isLoading,
//     periodData,
//   };
// };
  
export default compose(
  withStyles(styles),
  connect(null, {
  }),
)(PeriodDates);