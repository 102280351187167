import { Box, DialogActions } from '@material-ui/core';
import { Button } from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_ESTIMATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const RequestDetailsCard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const { estimateInfo, currentTab } = props;

  const createCarSummaries = () => {
    return [
      {
        header: 'Ordered Date',
        accessorKey: 'orderedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.orderedDate : '',
        tab: 1,
        disabled: true
      },
      {
        header: 'Appointment Date',
        accessorKey: 'appointmentDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.appointmentDate : '',
        tab: 1
      },
      {
        header: 'Received Date',
        accessorKey: 'receivedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.receivedDate : '',
        tab: 1
      },
      {
        header: 'Accepted Date',
        accessorKey: 'acceptedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.acceptedDate : '',
        tab: 1,
        disabled: true
      },
      {
        header: 'Move Miles',
        accessorKey: 'moveMiles',
        type: 'number',
        required: false,
        value: estimateInfo ? estimateInfo.moveMiles : '',
        tab: 1
      },
      {
        header: '# of Vehicles Authorized',
        accessorKey: 'numVehiclesAuthorized',
        type: 'number',
        required: false,
        value: estimateInfo ? estimateInfo.numVehiclesAuthorized : '',
        tab: 1
      },
      {
        header: 'Comment',
        accessorKey: 'comment',
        type: 'notes',
        showBelow: true,
        required: false,
        value: estimateInfo ? estimateInfo.comment : '',
        tab: 1
      },
      { dividerLine: true },
      {
        header: 'Vendor Could Not Accept',
        accessorKey: 'vendorCouldNotAccept',
        type: 'checkbox',
        required: false,
        value: estimateInfo ? estimateInfo.vendorCouldNotAccept : '',
        tab: 1
      },
      {
        header: 'Reason Not Accepted',
        accessorKey: 'vendorCouldNotAcceptReason',
        type: 'text',
        required: false,
        value: estimateInfo ? estimateInfo.vendorCouldNotAcceptReason : '',
        tab: 1
      }
    ];
  };

  const createLongTermStorageSummaries = () => {
    return [
      {
        header: 'Ordered Date',
        accessorKey: 'orderedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.orderedDate : '',
        tab: 1,
        disabled: true
      },
      {
        header: 'Appointment Date',
        accessorKey: 'appointmentDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.appointmentDate : '',
        tab: 1
      },
      {
        header: 'Received Date',
        accessorKey: 'receivedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.receivedDate : '',
        tab: 1
      },
      {
        header: 'Accepted Date',
        accessorKey: 'acceptedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.acceptedDate : '',
        tab: 1,
        disabled: true
      },
      {
        header: 'Move Miles',
        accessorKey: 'moveMiles',
        type: 'number',
        required: false,
        value: estimateInfo ? estimateInfo.moveMiles : '',
        tab: 1
      },
      {
        header: '# of Days Authorized',
        accessorKey: 'longTermStorageDaysAuthorized',
        type: 'number',
        required: false,
        value: estimateInfo?.longTermStorageDaysAuthorized,
        tab: 1
      },
      {
        header: '# of Days Needed',
        accessorKey: 'longTermStorageDaysNeeded',
        type: 'number',
        required: false,
        value: estimateInfo?.longTermStorageDaysNeeded,
        tab: 1
      },
      {
        header: 'Comment',
        accessorKey: 'comment',
        type: 'notes',
        showBelow: true,
        required: false,
        value: estimateInfo ? estimateInfo.comment : '',
        tab: 1
      },
      { dividerLine: true },
      {
        header: 'Vendor Could Not Accept',
        accessorKey: 'vendorCouldNotAccept',
        type: 'checkbox',
        required: false,
        value: estimateInfo ? estimateInfo.vendorCouldNotAccept : '',
        tab: 1
      },
      {
        header: 'Reason Not Accepted',
        accessorKey: 'vendorCouldNotAcceptReason',
        type: 'text',
        required: false,
        value: estimateInfo ? estimateInfo.vendorCouldNotAcceptReason : '',
        tab: 1
      }
    ];
  };

  const createGoodShipmentsSumamries = () => {
    return [
      {
        header: 'Ordered Date',
        accessorKey: 'orderedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.orderedDate : '',
        tab: 1,
        disabled: true
      },
      {
        header: 'Appointment Date',
        accessorKey: 'appointmentDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.appointmentDate : '',
        tab: 1
      },
      {
        header: 'Received Date',
        accessorKey: 'receivedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.receivedDate : '',
        tab: 1
      },
      {
        header: 'Accepted Date',
        accessorKey: 'acceptedDate',
        type: 'date',
        required: false,
        value: estimateInfo ? estimateInfo.acceptedDate : '',
        tab: 1,
        disabled: true
      },
      {
        header: 'HHG Inventory',
        accessorKey: 'inventoryFileName',
        type: 'file',
        required: false,
        value: estimateInfo ? estimateInfo.inventoryFileName : '',
        tab: 1
      },
      {
        header: 'Move Miles',
        accessorKey: 'moveMiles',
        type: 'number',
        required: false,
        value: estimateInfo ? estimateInfo.moveMiles : '',
        tab: 1
      },
      {
        header: '# of Vehicles Authorized',
        accessorKey: 'numVehiclesAuthorized',
        type: 'number',
        required: false,
        value: estimateInfo ? estimateInfo.numVehiclesAuthorized : '',
        tab: 1
      },
      {
        header: 'Comment',
        accessorKey: 'comment',
        type: 'notes',
        showBelow: true,
        required: false,
        value: estimateInfo ? estimateInfo.comment : '',
        tab: 1
      },
      { dividerLine: true },
      {
        header: 'Vendor Could Not Accept',
        accessorKey: 'vendorCouldNotAccept',
        type: 'checkbox',
        required: false,
        value: estimateInfo ? estimateInfo.vendorCouldNotAccept : '',
        tab: 1
      },
      {
        header: 'Reason Not Accepted',
        accessorKey: 'vendorCouldNotAcceptReason',
        type: 'text',
        required: false,
        value: estimateInfo ? estimateInfo.vendorCouldNotAcceptReason : '',
        tab: 1
      }
    ];
  };

  const createSummaries = () => {
    if (currentTab === 'GOODS_SHIPMENT') {
      return createGoodShipmentsSumamries();
    } else if (currentTab === 'CAR_SHIPMENT') {
      return createCarSummaries();
    } else {
      return createLongTermStorageSummaries();
    }
  };

  const sendUpdate = async newVals => {
    setIsLoading(true);
    if (estimateInfo && estimateInfo.length !== 0) {
      const resp = await props.updateHhgEstimate(newVals);
      if (resp.type === UPDATE_HHG_ESTIMATE_FAILURE) {
        props.showToast('Failed to update the estimate info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const createFormTabs = () => {
    let tabs = [{ header: 'Request Details', order: 1, iconPath: ['fas', 'file-contract'] }];
    if (estimateInfo?.orderedDate !== null && estimateInfo?.orderedDate !== '') {
      //Estimate has been sent ...
      let headerMessage;
      if (estimateInfo?.acceptedDate === null || estimateInfo?.acceptedDate === '') {
        //Estimate not accepted ...
        headerMessage = 'This estimate has been sent.';
      } else {
        //Estimate must have been accepted ...
        headerMessage = 'This estimate has been accepted.';
      }
      tabs[0].headerMessage = headerMessage;
    }
    return tabs;
  };

  const updateFields = async (tab, values) => {
    let newVals = { ...estimateInfo };
    newVals.appointmentDate = values.appointmentDate;
    newVals.receivedDate = values.receivedDate;
    newVals.comment = values.comment;
    newVals.moveMiles = values.moveMiles;
    newVals.vendorCouldNotAccept = values.vendorCouldNotAccept;
    newVals.vendorCouldNotAcceptReason = values.vendorCouldNotAcceptReason;

    if (currentTab === 'GOODS_SHIPMENT') {
      newVals.inventoryFileName = values.inventoryFileName;
      newVals.numVehiclesAuthorized = values.numVehiclesAuthorized;
    }
    if (currentTab === 'CAR_SHIPMENT') {
      newVals.numVehiclesAuthorized = values.numVehiclesAuthorized;
    }
    if (currentTab === 'LONG_TERM_STORAGE') {
      newVals.longTermStorageDaysNeeded = values.longTermStorageDaysNeeded;
      newVals.longTermStorageDaysAuthorized = values.longTermStorageDaysAuthorized;
    }
    return await sendUpdate(newVals);
  };

  const updateTimeStamp = async fieldName => {
    setIsLoadingButton(true);
    let newVals = { ...estimateInfo };
    newVals[fieldName] = new Date().toISOString();
    let resp = await sendUpdate(newVals);
    setIsLoadingButton(false);
    return resp;
  };

  const reorderEstimates = async () => {
    setIsLoadingButton(true);
    let newVals = { ...estimateInfo };
    newVals.orderedDate = null;
    newVals.acceptedDate = null;
    let resp = await sendUpdate(newVals);
    setIsLoadingButton(false);
    return resp;
  };

  const SendEstimateRequestButton = ({ ...rest }) => {
    let onClickEvent;
    let buttonText;
    let reorderEstimatesButtonExist = false;
    if (estimateInfo?.orderedDate === null || estimateInfo?.orderedDate === '') {
      //Assumed they havn't sent estimate
      buttonText = 'Send Estimate Request';
      onClickEvent = async () => await updateTimeStamp('orderedDate');
    } else if (estimateInfo?.acceptedDate === null || estimateInfo?.acceptedDate === '') {
      //Assumed they sent estimate but havn't accepted
      buttonText = 'Accept Estimate';
      onClickEvent = async () => await updateTimeStamp('acceptedDate');
      reorderEstimatesButtonExist = true;
    } else {
      //Assumed they sent estimate and accepted offer
      buttonText = 'Send Actual Reminder';
      onClickEvent = () => console.log('Send Actual Reminder was clicked');
      reorderEstimatesButtonExist = true;
    }

    return (
      <>
        {isLoadingButton && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          </Box>
        )}
        {!isLoadingButton && !props.hideEdit && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 10px'
              }}
            >
              {reorderEstimatesButtonExist && (
                <Button
                  onClick={async () => await reorderEstimates()}
                  variant="contained"
                  sx={{
                    maxHeight: '40px',
                    marginLeft: '12px',
                    marginTop: '8px',
                    backgroundColor: '#868686',
                    '&:hover': { backgroundColor: '#474747' }
                  }}
                >
                  Reorder Estimates
                </Button>
              )}
              <Button
                onClick={onClickEvent}
                color="secondary"
                variant="contained"
                sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
              >
                {buttonText}
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <EditableCard
      tabs={createFormTabs()}
      summaries={createSummaries()}
      numColumns={4}
      updateFields={updateFields}
      isLoading={isLoading}
      ButtonComponent={SendEstimateRequestButton}
      hideEdit={props.hideEdit}
    />
  );
};

export default compose(
  connect(null, {
    updateHhgEstimate,
    showToast
  })
)(RequestDetailsCard);
