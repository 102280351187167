import { Checkbox } from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import { updateVendor } from 'modules/authorizations/store/actions/dataManagement.actions';
import { UPDATE_VENDORS_REQUEST } from 'modules/authorizations/store/types/dataManagementTypes';

const VendorDetailsCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { vendorDetails, isReadOnly } = props;

  const vendorTypes = [ 'Inspectors', 'School', 'Insp', 'Brk', 'Hhg', 'Spc', 'Tax', 'Oth', 'TenancyMgmt', 'TI', 'Client', 'Hoa', 'Mtg', 'Insur', 'Util', 'App', 'Tp', 'Pet', 'Dsc','Division'];

  const tabs = [
    { header: 'Vendor Details', order: 1, iconPath: ['fas', 'file-contract'] },
  ];

  const summaries = [
    { header: 'Company Name', accessorKey: 'companyName', type: 'text', required: false, value: vendorDetails ? vendorDetails.companyName : null, hideInTable: true, tab: 1 },
    { header: 'Vendor Type', accessorKey: 'vendorType', type: 'dropdown', required: false, value: vendorDetails ? vendorDetails.vendorType : false, options: vendorTypes, hideInTable: true, tab: 1 },
    { header: 'Status', accessorKey: 'status', type: 'text', required: false, value: vendorDetails ? vendorDetails.status : '', disabled: true, hideInTable: true, tab: 1 },
    { header: 'Contract Date', accessorKey: 'contractDate', type: 'date', required: false, value: vendorDetails ? vendorDetails.contractDate : '', hideInTable: true, tab: 1 },
    { header: 'Contract Expires', accessorKey: 'contractExpires', type: 'date', required: false,  value: vendorDetails ? vendorDetails.contractExpires : '', hideInTable: true, tab: 1 },
    { header: 'Status Updated', accessorKey: 'statusLastUpdated', type: 'date', required: false, value: vendorDetails ? vendorDetails.statusLastUpdated : '', hideInTable: true, disabled: true, tab: 1 },
    
    { header: 'Description', accessorKey: 'description', type: 'text', required: false, value: vendorDetails ? vendorDetails.description : null, tab: 1 },
    { header: 'Office Phone', accessorKey: 'phone', type: 'text', required: false, value: vendorDetails ? vendorDetails.phone : false, tab: 1 },
    { header: 'Office Email', accessorKey: 'email', type: 'text', required: false, value: vendorDetails ? vendorDetails.email : '', tab: 1 },
    { header: 'Address', accessorKey: 'address', type: 'text', required: false, value: vendorDetails ? vendorDetails.address : '', tab: 1 },
    { header: 'City', accessorKey: 'city', type: 'text', required: false,  value: vendorDetails ? vendorDetails.city : '', tab: 1 },
    { header: 'State', accessorKey: 'state', type: 'text', required: false, value: vendorDetails ? vendorDetails.state : '', tab: 1 },
    { header: 'Zip', accessorKey: 'zip', type: 'text', required: false, value: vendorDetails ? vendorDetails.zip : false, tab: 1 },
    { header: 'Company Website', accessorKey: 'companyWebsite', type: 'text', required: false, value: vendorDetails ? vendorDetails.companyWebsite : '', tab: 1 },
    { header: 'SLA Ref', accessorKey: 'slaRef', type: 'text', required: false, value: vendorDetails ? vendorDetails.slaRef : '', tab: 1 },
    { header: 'Designations', accessorKey: 'designations', type: 'text', required: false, value: vendorDetails ? vendorDetails.designations : '', tab: 1 },
    { header: 'Commissions', accessorKey: 'commissions', type: 'text', required: false, value: vendorDetails ? vendorDetails.commissions : '', tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'text', required: false, value: vendorDetails ? vendorDetails.notes : '', tab: 1 },

    { header: 'Banking Info Required', accessorKey: 'bankingRequired', type: 'checkbox', required: false, value: vendorDetails ? vendorDetails.bankingRequired : false, hideInTable: true, tab: 1, Cell: ({ cell }) => <Checkbox name="bankingRequired" label="Active" checked={cell.row.original.bankingRequired} /> },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...vendorDetails };

    newValues.companyName = values.companyName;
    newValues.vendorType = values.vendorType;
    newValues.contractDate = values.contractDate;
    newValues.contractExpires = values.contractExpires;
    newValues.description = values.description;
    newValues.phone = values.phone;
    newValues.email = values.email;
    newValues.address = values.address;
    newValues.city = values.city;
    newValues.state = values.state;
    newValues.zip = values.zip;
    newValues.companyWebsite = values.companyWebsite;
    newValues.slaRef = values.slaRef;
    newValues.designations = values.designations;
    newValues.commissions = values.commissions;
    newValues.notes = values.notes;
    newValues.bankingRequired = values.bankingRequired;

    const resp = await props.updateVendor(newValues);
    if (resp.type === UPDATE_VENDORS_REQUEST) {
        props.showToast('Failed to update vendor details, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
    } else { 
        setIsLoading(false);
        return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateVendor,
    showToast,
  }),
)(VendorDetailsCard);