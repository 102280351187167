/* eslint-disable array-callback-return */
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DELETE_TAX_POLICY_FAILURE, GET_TAX_POLICIES_FAILURE, POST_TAX_POLICY_FAILURE, UPDATE_TAX_POLICY_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { deleteTaxPolicy, getTaxPolicies, postTaxPolicy, updateTaxPolicy } from 'modules/authorizations/store/actions/dataManagement.actions';
import CrudTable from '../clients/details/crudTable.component';
import Spinner from 'modules/common/spinner.component';

import classNames from 'classnames';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const TaxPoliciesTable = (props) => {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const adjustedColumns = [
    { accessorKey: 'clientId', header: 'Client Id', type: 'text', required: true, size: 70 },
    { accessorKey: 'policyCode', header: 'Policy Code', type: 'text', required: true, size: 70 },
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 100 },
  ];

  const updateRow = async (policy, values) => {
    const id = policy.original.id;
    values.id = id;
    const resp = await props.updateTaxPolicy(values);
    if (resp.type === UPDATE_TAX_POLICY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getTaxPolicies();
      if (getResp.type === GET_TAX_POLICIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    const resp = await props.postTaxPolicy(values);
    if (resp.type === POST_TAX_POLICY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getTaxPolicies();
      if (getResp.type === GET_TAX_POLICIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (policy) => {
    const id = policy.original.id;
    const resp = await props.deleteTaxPolicy(id);
    if (resp.type === DELETE_TAX_POLICY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getTaxPolicies();
      if (getResp.type === GET_TAX_POLICIES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion expanded={isOpen} onChange={()=> {setIsOpen(!isOpen);}} classes={{ root: classNames(props.classes.accordionStyleContacts) }}>
      <AccordionSummary expandIcon={<Box color={theme.palette.secondary.main}><ExpandMoreIcon color="inherit" /></Box>}>
        <div className={props.classes.newRow}>
          <h2>Tax Policies</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.taxPoliciesLoading && props.taxPolicies && (
          <div>
            <CrudTable 
              editingMode={'modal'} // Can be changed to 'row' to edit inline 
              columns={adjustedColumns} 
              rows={props.taxPolicies} 
              updateRow={updateRow} 
              createRow={createRow} 
              deleteRow={deleteRow} 
            />
          </div>
        )}
        {props.taxPoliciesLoading  && (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
}; 

TaxPoliciesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { taxPolicies, taxPoliciesLoading } = state.authorizations;
  return {
    taxPolicies,
    taxPoliciesLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    deleteTaxPolicy, 
    getTaxPolicies, 
    postTaxPolicy, 
    updateTaxPolicy,
  }),
)(TaxPoliciesTable);