import { CALL_INTAKE_API } from 'middleware/intakeApi';

import {
  DELETE_CALENDAR_FAILURE, DELETE_CALENDAR_REQUEST, DELETE_CALENDAR_SUCCESS,
  DELETE_CLIENT_CONTACT_FAILURE, DELETE_CLIENT_CONTACT_REQUEST, DELETE_CLIENT_CONTACT_SUCCESS,
  DELETE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, DELETE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, DELETE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS,
  DELETE_CLIENT_CONTRACT_FEE_FAILURE, DELETE_CLIENT_CONTRACT_FEE_REQUEST, DELETE_CLIENT_CONTRACT_FEE_SUCCESS,
  DELETE_CLIENT_CONTRACT_SLA_FAILURE, DELETE_CLIENT_CONTRACT_SLA_REQUEST, DELETE_CLIENT_CONTRACT_SLA_SUCCESS,
  DELETE_CLIENT_GENERAL_NOTES_FAILURE, DELETE_CLIENT_GENERAL_NOTES_REQUEST, DELETE_CLIENT_GENERAL_NOTES_SUCCESS,
  DELETE_CLIENT_ISSUES_CHALLENGES_FAILURE, DELETE_CLIENT_ISSUES_CHALLENGES_REQUEST, DELETE_CLIENT_ISSUES_CHALLENGES_SUCCESS,
  DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE, DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST, DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS,
  DELETE_CLIENT_POLICY_NOTES_FAILURE, DELETE_CLIENT_POLICY_NOTES_REQUEST, DELETE_CLIENT_POLICY_NOTES_SUCCESS,
  DELETE_CLIENT_SPECIAL_PROCESS_FAILURE, DELETE_CLIENT_SPECIAL_PROCESS_REQUEST, DELETE_CLIENT_SPECIAL_PROCESS_SUCCESS,
  DELETE_HOMESALE_CLOSING_COSTS_FAILURE, DELETE_HOMESALE_CLOSING_COSTS_REQUEST, DELETE_HOMESALE_CLOSING_COSTS_SUCCESS,
  DELETE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, DELETE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, DELETE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS,
  DELETE_HOMESALE_GBO_INSPECTIONS_FAILURE, DELETE_HOMESALE_GBO_INSPECTIONS_REQUEST, DELETE_HOMESALE_GBO_INSPECTIONS_SUCCESS,
  DELETE_HOMESALE_HHG_PROCESS_FAILURE, DELETE_HOMESALE_HHG_PROCESS_REQUEST, DELETE_HOMESALE_HHG_PROCESS_SUCCESS,
  GET_CALENDAR_PERIODS_FAILURE, GET_CALENDAR_PERIODS_REQUEST, GET_CALENDAR_PERIODS_SUCCESS,
  GET_CLIENTS_FAILURE, GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS,
  GET_CLIENT_CALENDARS_FAILURE, GET_CLIENT_CALENDARS_REQUEST, GET_CLIENT_CALENDARS_SUCCESS,
  GET_CLIENT_CONTACTS_FAILURE,
  GET_CLIENT_CONTACTS_REQUEST,
  GET_CLIENT_CONTACTS_SUCCESS,
  GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, GET_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, GET_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS,
  GET_CLIENT_CONTRACT_FAILURE, GET_CLIENT_CONTRACT_FEE_FAILURE, GET_CLIENT_CONTRACT_FEE_REQUEST,
  GET_CLIENT_CONTRACT_FEE_SUCCESS, GET_CLIENT_CONTRACT_REQUEST, GET_CLIENT_CONTRACT_SLA_FAILURE,
  GET_CLIENT_CONTRACT_SLA_REQUEST, GET_CLIENT_CONTRACT_SLA_SUCCESS, GET_CLIENT_CONTRACT_SUCCESS,
  GET_CLIENT_DETAILS_FAILURE, GET_CLIENT_DETAILS_REQUEST, GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_GENERAL_NOTES_FAILURE, GET_CLIENT_GENERAL_NOTES_REQUEST, GET_CLIENT_GENERAL_NOTES_SUCCESS,
  GET_CLIENT_HOMESALE_INFO_FAILURE, GET_CLIENT_HOMESALE_INFO_REQUEST, GET_CLIENT_HOMESALE_INFO_SUCCESS,
  GET_CLIENT_ISSUES_CHALLENGES_FAILURE, GET_CLIENT_ISSUES_CHALLENGES_REQUEST, GET_CLIENT_ISSUES_CHALLENGES_SUCCESS,
  GET_CLIENT_LUMP_SUM_FAILURE, GET_CLIENT_LUMP_SUM_REQUEST, GET_CLIENT_LUMP_SUM_SUCCESS,
  GET_CLIENT_METADATA_FAILURE, GET_CLIENT_METADATA_REQUEST, GET_CLIENT_METADATA_SUCCESS,
  GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE, GET_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST, GET_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS,
  GET_CLIENT_POLICY_NOTES_FAILURE, GET_CLIENT_POLICY_NOTES_REQUEST, GET_CLIENT_POLICY_NOTES_SUCCESS,
  GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_FAILURE, GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_REQUEST, GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_SUCCESS,
  GET_CLIENT_SPECIAL_PROCESS_FAILURE, GET_CLIENT_SPECIAL_PROCESS_REQUEST, GET_CLIENT_SPECIAL_PROCESS_SUCCESS, 
  GET_HOMESALE_CLOSING_COSTS_FAILURE, GET_HOMESALE_CLOSING_COSTS_REQUEST, GET_HOMESALE_CLOSING_COSTS_SUCCESS, 
  GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, GET_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, GET_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS,
  GET_HOMESALE_GBO_INSPECTIONS_FAILURE, GET_HOMESALE_GBO_INSPECTIONS_REQUEST, GET_HOMESALE_GBO_INSPECTIONS_SUCCESS,
  GET_HOMESALE_HHG_PROCESS_FAILURE, GET_HOMESALE_HHG_PROCESS_REQUEST, GET_HOMESALE_HHG_PROCESS_SUCCESS, GET_INTERNAL_USERS_FAILURE, GET_INTERNAL_USERS_REQUEST, GET_INTERNAL_USERS_SUCCESS, GET_POLICIES_GENERAL_INFO_REQUEST, GET_POLICIES_GENERAL_INFO__FAILURE, GET_POLICIES_GENERAL_INFO__SUCCESS,
  POST_CLIENT_CALENDAR_FAILURE, POST_CLIENT_CALENDAR_REQUEST, POST_CLIENT_CALENDAR_SUCCESS, POST_CLIENT_CONTACT_FAILURE, POST_CLIENT_CONTACT_REQUEST, POST_CLIENT_CONTACT_SUCCESS,
  POST_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, POST_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, POST_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS, POST_CLIENT_CONTRACT_FEE_FAILURE, POST_CLIENT_CONTRACT_FEE_REQUEST, POST_CLIENT_CONTRACT_FEE_SUCCESS,
  POST_CLIENT_CONTRACT_SLA_FAILURE, POST_CLIENT_CONTRACT_SLA_REQUEST, POST_CLIENT_CONTRACT_SLA_SUCCESS,
  POST_CLIENT_GENERAL_NOTES_FAILURE, POST_CLIENT_GENERAL_NOTES_REQUEST, POST_CLIENT_GENERAL_NOTES_SUCCESS,
  POST_CLIENT_ISSUES_CHALLENGES_FAILURE, POST_CLIENT_ISSUES_CHALLENGES_REQUEST, POST_CLIENT_ISSUES_CHALLENGES_SUCCESS,
  POST_CLIENT_POLICY_NOTES_FAILURE,
  POST_CLIENT_POLICY_NOTES_REQUEST, POST_CLIENT_POLICY_NOTES_SUCCESS, POST_GBO_INSPECTIONS_INITIALIZE_FAILURE, POST_GBO_INSPECTIONS_INITIALIZE_REQUEST, POST_GBO_INSPECTIONS_INITIALIZE_SUCCESS, POST_HOMESALE_CLOSING_COSTS_FAILURE, POST_HOMESALE_CLOSING_COSTS_REQUEST, POST_HOMESALE_CLOSING_COSTS_SUCCESS, POST_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE,
  POST_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, POST_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS, POST_HOMESALE_GBO_INSPECTIONS_FAILURE, POST_HOMESALE_GBO_INSPECTIONS_REQUEST, POST_HOMESALE_GBO_INSPECTIONS_SUCCESS, POST_HOMESALE_HHG_PROCESS_FAILURE,

  POST_HOMESALE_HHG_PROCESS_REQUEST, POST_HOMESALE_HHG_PROCESS_SUCCESS, SET_CURRENT_CLIENT_ID,
  SET_LOADING_COMPLETE, UPDATE_CLIENT_CALENDAR_FAILURE, UPDATE_CLIENT_CALENDAR_REQUEST,
  UPDATE_CLIENT_CALENDAR_SUCCESS, UPDATE_CLIENT_CONTACT_FAILURE, UPDATE_CLIENT_CONTACT_REQUEST,
  UPDATE_CLIENT_CONTACT_SUCCESS, UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST,
  UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS, UPDATE_CLIENT_CONTRACT_FEE_FAILURE, UPDATE_CLIENT_CONTRACT_FEE_REQUEST,
  UPDATE_CLIENT_CONTRACT_FEE_SUCCESS, UPDATE_CLIENT_CONTRACT_SLA_FAILURE, UPDATE_CLIENT_CONTRACT_SLA_REQUEST,
  UPDATE_CLIENT_CONTRACT_SLA_SUCCESS, UPDATE_CLIENT_GENERAL_NOTES_FAILURE, UPDATE_CLIENT_GENERAL_NOTES_REQUEST,
  UPDATE_CLIENT_GENERAL_NOTES_SUCCESS, UPDATE_CLIENT_HOMESALE_INFO_FAILURE, UPDATE_CLIENT_HOMESALE_INFO_REQUEST,

  UPDATE_CLIENT_HOMESALE_INFO_SUCCESS, UPDATE_CLIENT_ISSUES_CHALLENGES_FAILURE, UPDATE_CLIENT_ISSUES_CHALLENGES_REQUEST,
  UPDATE_CLIENT_ISSUES_CHALLENGES_SUCCESS, UPDATE_CLIENT_LUMP_SUM_FAILURE, UPDATE_CLIENT_LUMP_SUM_REQUEST,
  UPDATE_CLIENT_LUMP_SUM_SUCCESS, UPDATE_CLIENT_POLICY_NOTES_FAILURE, UPDATE_CLIENT_POLICY_NOTES_REQUEST,
  UPDATE_CLIENT_POLICY_NOTES_SUCCESS, UPDATE_HOMESALE_CLOSING_COSTS_FAILURE, UPDATE_HOMESALE_CLOSING_COSTS_REQUEST,
  UPDATE_HOMESALE_CLOSING_COSTS_SUCCESS, UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST,
  UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS, UPDATE_HOMESALE_GBO_INSPECTIONS_FAILURE, UPDATE_HOMESALE_GBO_INSPECTIONS_REQUEST,
  UPDATE_HOMESALE_GBO_INSPECTIONS_SUCCESS, UPDATE_HOMESALE_HHG_PROCESS_FAILURE, UPDATE_HOMESALE_HHG_PROCESS_REQUEST,
  UPDATE_HOMESALE_HHG_PROCESS_SUCCESS, UPDATE_PERIOD_FAILURE, UPDATE_PERIOD_REQUEST,
  UPDATE_PERIOD_SUCCESS, UPDATE_POLICIES_GENERAL_INFO__FAILURE, UPDATE_POLICIES_GENERAL_INFO__REQUEST,
  UPDATE_POLICIES_GENERAL_INFO__SUCCESS, UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE, UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST,
  UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS, UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE, UPSERT_CLIENT_SPECIAL_PROCESS_REQUEST,
  UPSERT_CLIENT_SPECIAL_PROCESS_SUCCESS,
} from './types';

import { SET_FOCUSED_TAB } from 'modules/intake/types';

export const getInternalUsers = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_INTERNAL_USERS_REQUEST, GET_INTERNAL_USERS_SUCCESS, GET_INTERNAL_USERS_FAILURE],
      authenticated: true,
      endpoint: 'users/basicUsersInfo',
      method: 'GET',
    },
  };
};

export const getClientCalendars = (clientId, year) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_CALENDARS_REQUEST, GET_CLIENT_CALENDARS_SUCCESS, GET_CLIENT_CALENDARS_FAILURE],
      authenticated: true,
      endpoint: `clientPayroll/calendars/${clientId}/${year}`,
      method: 'GET',
    },
  };
};

export const getCalendarPeriods = (calendarId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CALENDAR_PERIODS_REQUEST, GET_CALENDAR_PERIODS_SUCCESS, GET_CALENDAR_PERIODS_FAILURE],
      authenticated: true,
      endpoint: `clientPayroll/calendars/${calendarId}`,
      method: 'GET',
    },
  };
};

export const postClientCalendar = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_CALENDAR_REQUEST, POST_CLIENT_CALENDAR_SUCCESS, POST_CLIENT_CALENDAR_FAILURE],
      authenticated: true,
      endpoint: 'clientPayroll/calendars',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateClientCalendar = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_CALENDAR_REQUEST, UPDATE_CLIENT_CALENDAR_SUCCESS, UPDATE_CLIENT_CALENDAR_FAILURE],
      authenticated: true,
      endpoint: 'clientPayroll/calendars',
      method: 'PUT',
      payload: values,
    },
  };
};

export const updatePeriod = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_PERIOD_REQUEST, UPDATE_PERIOD_SUCCESS, UPDATE_PERIOD_FAILURE],
      authenticated: true,
      endpoint: 'clientPayroll/calendars/periods',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteClientCalendar = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CALENDAR_REQUEST, DELETE_CALENDAR_SUCCESS, DELETE_CALENDAR_FAILURE],
      authenticated: true,
      endpoint: `clientPayroll/calendars/${id}`,
      method: 'DELETE',
    },
  };
};

export const getClientPolicyNotes = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_POLICY_NOTES_REQUEST, GET_CLIENT_POLICY_NOTES_SUCCESS, GET_CLIENT_POLICY_NOTES_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/policynotes`,
      method: 'GET',
    },
  };
};

export const postClientPolicyNotes = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_POLICY_NOTES_REQUEST, POST_CLIENT_POLICY_NOTES_SUCCESS, POST_CLIENT_POLICY_NOTES_FAILURE],
      authenticated: true,
      endpoint: 'clients/policynotes',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteClientPolicyNotes = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_POLICY_NOTES_REQUEST, DELETE_CLIENT_POLICY_NOTES_SUCCESS, DELETE_CLIENT_POLICY_NOTES_FAILURE],
      authenticated: true,
      endpoint: `clients/policynotes/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateClientPolicyNotes = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_POLICY_NOTES_REQUEST, UPDATE_CLIENT_POLICY_NOTES_SUCCESS, UPDATE_CLIENT_POLICY_NOTES_FAILURE],
      authenticated: true,
      endpoint: 'clients/policynotes',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientHomesaleGeneralInfo = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_HOMESALE_INFO_REQUEST, GET_CLIENT_HOMESALE_INFO_SUCCESS, GET_CLIENT_HOMESALE_INFO_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/homeSaleGeneralInfo`,
      method: 'GET',
    },
  };
};

export const updateClientHomesaleGeneralInfo = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_HOMESALE_INFO_REQUEST, UPDATE_CLIENT_HOMESALE_INFO_SUCCESS, UPDATE_CLIENT_HOMESALE_INFO_FAILURE],
      authenticated: true,
      endpoint: 'clients/homeSaleGeneralInfo',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientLumpSum = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_LUMP_SUM_REQUEST, GET_CLIENT_LUMP_SUM_SUCCESS, GET_CLIENT_LUMP_SUM_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/getClientLumpSum`,
      method: 'GET',
    },
  };
};

export const updateClientLumpSum = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_LUMP_SUM_REQUEST, UPDATE_CLIENT_LUMP_SUM_SUCCESS, UPDATE_CLIENT_LUMP_SUM_FAILURE],
      authenticated: true,
      endpoint: 'clients/updateClientLumpSum',
      method: 'PUT',
      payload: values,
    },
  };
};

export const setClientFocusedTab = (tab) => ({
  type: SET_FOCUSED_TAB,
  response: tab,
});

export const setClientId = (id) => ({
  type: SET_CURRENT_CLIENT_ID,
  response: id,
});

export const getClients = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS, GET_CLIENTS_FAILURE],
      authenticated: true,
      endpoint: 'clients',
      method: 'GET',
    },
  };
};

export const getClientDetails = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_DETAILS_REQUEST, GET_CLIENT_DETAILS_SUCCESS, GET_CLIENT_DETAILS_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}`,
      method: 'GET',
    },
  };
};

export const getClientContacts = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_CONTACTS_REQUEST, GET_CLIENT_CONTACTS_SUCCESS, GET_CLIENT_CONTACTS_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/contacts`,
      method: 'GET',
    },
  };
};

export const getClientContract = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_CONTRACT_REQUEST, GET_CLIENT_CONTRACT_SUCCESS, GET_CLIENT_CONTRACT_FAILURE],
      authenticated: true,
      endpoint: `clientcontracts/${id}`,
      method: 'GET',
    },
  };
};

export const postClientContact = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_CONTACT_REQUEST, POST_CLIENT_CONTACT_SUCCESS, POST_CLIENT_CONTACT_FAILURE],
      authenticated: true,
      endpoint: 'clients/contacts',
      method: 'POST',
      payload: values,
    },
  };
};

export const deleteClientContact = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_CONTACT_REQUEST, DELETE_CLIENT_CONTACT_SUCCESS, DELETE_CLIENT_CONTACT_FAILURE],
      authenticated: true,
      endpoint: `clients/contacts/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateClientContact = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_CONTACT_REQUEST, UPDATE_CLIENT_CONTACT_SUCCESS, UPDATE_CLIENT_CONTACT_FAILURE],
      authenticated: true,
      endpoint: 'clients/contacts',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientIssuesAndChallenges = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_ISSUES_CHALLENGES_REQUEST, GET_CLIENT_ISSUES_CHALLENGES_SUCCESS, GET_CLIENT_ISSUES_CHALLENGES_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/issuesandchallenges`,
      method: 'GET',
    },
  };
};

export const postClientIssuesAndChallenges = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_ISSUES_CHALLENGES_REQUEST, POST_CLIENT_ISSUES_CHALLENGES_SUCCESS, POST_CLIENT_ISSUES_CHALLENGES_FAILURE],
      authenticated: true,
      endpoint: 'clients/issuesandchallenges',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteClientIssuesAndChallenges = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_ISSUES_CHALLENGES_REQUEST, DELETE_CLIENT_ISSUES_CHALLENGES_SUCCESS, DELETE_CLIENT_ISSUES_CHALLENGES_FAILURE],
      authenticated: true,
      endpoint: `clients/issuesandchallenges/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateClientIssuesAndChallenges = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_ISSUES_CHALLENGES_REQUEST, UPDATE_CLIENT_ISSUES_CHALLENGES_SUCCESS, UPDATE_CLIENT_ISSUES_CHALLENGES_FAILURE],
      authenticated: true,
      endpoint: 'clients/issuesandchallenges',
      method: 'PUT',
      payload: values,
    },
  };
};


export const getClientGeneralNotes = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_GENERAL_NOTES_REQUEST, GET_CLIENT_GENERAL_NOTES_SUCCESS, GET_CLIENT_GENERAL_NOTES_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/generalnotes`,
      method: 'GET',
    },
  };
};

export const postClientGeneralNotes = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_GENERAL_NOTES_REQUEST, POST_CLIENT_GENERAL_NOTES_SUCCESS, POST_CLIENT_GENERAL_NOTES_FAILURE],
      authenticated: true,
      endpoint: 'clients/generalnotes',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteClientGeneralNotes = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_GENERAL_NOTES_REQUEST, DELETE_CLIENT_GENERAL_NOTES_SUCCESS, DELETE_CLIENT_GENERAL_NOTES_FAILURE],
      authenticated: true,
      endpoint: `clients/generalnotes/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateClientGeneralNotes = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_GENERAL_NOTES_REQUEST, UPDATE_CLIENT_GENERAL_NOTES_SUCCESS, UPDATE_CLIENT_GENERAL_NOTES_FAILURE],
      authenticated: true,
      endpoint: 'clients/generalnotes',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientContractSLA = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_CONTRACT_SLA_REQUEST, GET_CLIENT_CONTRACT_SLA_SUCCESS, GET_CLIENT_CONTRACT_SLA_FAILURE],
      authenticated: true,
      endpoint: `clientcontracts/${id}/sla`,
      method: 'GET',
    },
  };
};

export const postClientContractSLA = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_CONTRACT_SLA_REQUEST, POST_CLIENT_CONTRACT_SLA_SUCCESS, POST_CLIENT_CONTRACT_SLA_FAILURE],
      authenticated: true,
      endpoint: 'clientcontracts/sla',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteClientContractSLA = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_CONTRACT_SLA_REQUEST, DELETE_CLIENT_CONTRACT_SLA_SUCCESS, DELETE_CLIENT_CONTRACT_SLA_FAILURE],
      authenticated: true,
      endpoint: `clientcontracts/sla/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateClientContractSLA = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_CONTRACT_SLA_REQUEST, UPDATE_CLIENT_CONTRACT_SLA_SUCCESS, UPDATE_CLIENT_CONTRACT_SLA_FAILURE],
      authenticated: true,
      endpoint: 'clientcontracts/sla',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientContractFees = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_CONTRACT_FEE_REQUEST, GET_CLIENT_CONTRACT_FEE_SUCCESS, GET_CLIENT_CONTRACT_FEE_FAILURE],
      authenticated: true,
      endpoint: `clientcontracts/${id}/fee`,
      method: 'GET',
    },
  };
};

export const postClientContractFees = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_CONTRACT_FEE_REQUEST, POST_CLIENT_CONTRACT_FEE_SUCCESS, POST_CLIENT_CONTRACT_FEE_FAILURE],
      authenticated: true,
      endpoint: 'clientcontracts/fee',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteClientContractFees = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_CONTRACT_FEE_REQUEST, DELETE_CLIENT_CONTRACT_FEE_SUCCESS, DELETE_CLIENT_CONTRACT_FEE_FAILURE],
      authenticated: true,
      endpoint: `clientcontracts/fee/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateClientContractFees = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_CONTRACT_FEE_REQUEST, UPDATE_CLIENT_CONTRACT_FEE_SUCCESS, UPDATE_CLIENT_CONTRACT_FEE_FAILURE],
      authenticated: true,
      endpoint: 'clientcontracts/fee',
      method: 'PUT',
      payload: values,
    },
  };
};


export const getClientContractsCreditsAndRebates = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, GET_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS, GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE],
      authenticated: true,
      endpoint: `clientcontracts/${id}/creditsandrebates`,
      method: 'GET',
    },
  };
};

export const postClientContractsCreditsAndRebates = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, POST_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS, POST_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE],
      authenticated: true,
      endpoint: 'clientcontracts/creditsandrebates',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteClientContractsCreditsAndRebates = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, DELETE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS, DELETE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE],
      authenticated: true,
      endpoint: `clientcontracts/creditsandrebates/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateClientContractsCreditsAndRebates = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS, UPDATE_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE],
      authenticated: true,
      endpoint: 'clientcontracts/creditsandrebates',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientSpecialProcesses = (clientId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_SPECIAL_PROCESS_REQUEST, GET_CLIENT_SPECIAL_PROCESS_SUCCESS, GET_CLIENT_SPECIAL_PROCESS_FAILURE],
      authenticated: true,
      endpoint: `ClientSpecialProcesses/getall?clientId=${clientId}`,
      method: 'GET',
    },
  };  
};

export const getClientSpecialProcessCategoryTypes = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_REQUEST, GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_SUCCESS, GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_FAILURE],
      authenticated: true,
      endpoint: 'ClientSpecialProcesses/GetSpecialProcessCategories',
      method: 'GET',
    },
  };
};

export const upsertClientSpecialProcess = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPSERT_CLIENT_SPECIAL_PROCESS_REQUEST, UPSERT_CLIENT_SPECIAL_PROCESS_SUCCESS, UPSERT_CLIENT_SPECIAL_PROCESS_FAILURE],
      authenticated: true,
      endpoint: 'ClientSpecialProcesses/Upsert',
      method: 'POST',
      payload: values,
    },
  };
};

export const deleteClientSpecialProcess = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_SPECIAL_PROCESS_REQUEST, DELETE_CLIENT_SPECIAL_PROCESS_SUCCESS, DELETE_CLIENT_SPECIAL_PROCESS_FAILURE],
      authenticated: true,
      endpoint: 'ClientSpecialProcesses/Delete',
      method: 'DELETE',
      payload: values,
    },
  };
};

export const getClientPoliciesImportantNotes = (clientId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST, GET_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS, GET_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE],
      authenticated: true,
      endpoint: `ClientPolicies/ImportantNotes?clientId=${clientId}`,
      method: 'GET',
    },
  };
};
export const upsertClientPoliciesImportantNotes = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST, UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS, UPSERT_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE],
      authenticated: true,
      endpoint: 'ClientPolicies/ImportantNotes/Upsert',
      method: 'POST',
      payload: values,
    },
  };
};

export const deleteClientPoliciesImportantNotes = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST, DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS, DELETE_CLIENT_POLICIES_IMPORTANT_NOTES_FAILURE],
      authenticated: true,
      endpoint: 'ClientPolicies/ImportantNotes/Delete',
      method: 'DELETE',
      payload: values,
    },
  };
};

/* Home Sale Eligible Properties Requests */
export const getHomesaleEligibleProperties = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, GET_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS, GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/eligibleProperties`,
      method: 'GET',
    },
  };
};

export const postHomesaleEligibleProperties = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, POST_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS, POST_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE],
      authenticated: true,
      endpoint: 'clients/eligibleProperties',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteHomesaleEligibleProperties = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, DELETE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS, DELETE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE],
      authenticated: true,
      endpoint: `clients/eligibleProperties/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateHomesaleEligibleProperties = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS, UPDATE_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE],
      authenticated: true,
      endpoint: 'clients/eligibleProperties',
      method: 'PUT',
      payload: values,
    },
  };
};

/* Home Sale Clos Requests */
export const getHomesaleClosingCosts = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_HOMESALE_CLOSING_COSTS_REQUEST, GET_HOMESALE_CLOSING_COSTS_SUCCESS, GET_HOMESALE_CLOSING_COSTS_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/closingCosts`,
      method: 'GET',
    },
  };
};

export const postHomesaleClosingCosts = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_HOMESALE_CLOSING_COSTS_REQUEST, POST_HOMESALE_CLOSING_COSTS_SUCCESS, POST_HOMESALE_CLOSING_COSTS_FAILURE],
      authenticated: true,
      endpoint: 'clients/closingCosts',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteHomesaleClosingCosts = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_HOMESALE_CLOSING_COSTS_REQUEST, DELETE_HOMESALE_CLOSING_COSTS_SUCCESS, DELETE_HOMESALE_CLOSING_COSTS_FAILURE],
      authenticated: true,
      endpoint: `clients/closingCosts/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateHomesaleClosingCosts = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_HOMESALE_CLOSING_COSTS_REQUEST, UPDATE_HOMESALE_CLOSING_COSTS_SUCCESS, UPDATE_HOMESALE_CLOSING_COSTS_FAILURE],
      authenticated: true,
      endpoint: 'clients/closingCosts',
      method: 'PUT',
      payload: values,
    },
  };
};

/* Home Sale GBO Inspections Requests */
export const getHomesaleGboInspections = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_HOMESALE_GBO_INSPECTIONS_REQUEST, GET_HOMESALE_GBO_INSPECTIONS_SUCCESS, GET_HOMESALE_GBO_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/gboInspections`,
      method: 'GET',
    },
  };
};

export const postHomesaleGboInspections = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_HOMESALE_GBO_INSPECTIONS_REQUEST, POST_HOMESALE_GBO_INSPECTIONS_SUCCESS, POST_HOMESALE_GBO_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: 'clients/gboInspections',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteHomesaleGboInspections = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_HOMESALE_GBO_INSPECTIONS_REQUEST, DELETE_HOMESALE_GBO_INSPECTIONS_SUCCESS, DELETE_HOMESALE_GBO_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: `clients/gboInspections/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateHomesaleGboInspections = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_HOMESALE_GBO_INSPECTIONS_REQUEST, UPDATE_HOMESALE_GBO_INSPECTIONS_SUCCESS, UPDATE_HOMESALE_GBO_INSPECTIONS_FAILURE],
      authenticated: true,
      endpoint: 'clients/gboInspections',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientPoliciesGeneralInfo = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_POLICIES_GENERAL_INFO_REQUEST, GET_POLICIES_GENERAL_INFO__SUCCESS, GET_POLICIES_GENERAL_INFO__FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/policiesGeneral`,
      method: 'GET',
    },
  };
};

export const updateClientPoliciesGeneralInfo = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_POLICIES_GENERAL_INFO__REQUEST, UPDATE_POLICIES_GENERAL_INFO__SUCCESS, UPDATE_POLICIES_GENERAL_INFO__FAILURE],
      authenticated: true,
      endpoint: 'clients/policiesGeneral',
      method: 'PUT',
      payload: values,
    },
  };
};

export const getClientMetadata = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENT_METADATA_REQUEST, GET_CLIENT_METADATA_SUCCESS, GET_CLIENT_METADATA_FAILURE],
      authenticated: true,
      endpoint: 'clients/metadata',
      method: 'GET',
    },
  };
};

export const initializeGboInspections = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_GBO_INSPECTIONS_INITIALIZE_REQUEST, POST_GBO_INSPECTIONS_INITIALIZE_SUCCESS, POST_GBO_INSPECTIONS_INITIALIZE_FAILURE],
      authenticated: true,
      endpoint: `clients/gboInspections/initialize/${id}`,
      method: 'POST',
    },
  };
};

/* Home Sale HHG Processes Requests */
export const getHomesaleHHGProcesses = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_HOMESALE_HHG_PROCESS_REQUEST, GET_HOMESALE_HHG_PROCESS_SUCCESS, GET_HOMESALE_HHG_PROCESS_FAILURE],
      authenticated: true,
      endpoint: `clients/${id}/hhgProcesses`,
      method: 'GET',
    },
  };
};

export const postHomesaleHHGProcess = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [POST_HOMESALE_HHG_PROCESS_REQUEST, POST_HOMESALE_HHG_PROCESS_SUCCESS, POST_HOMESALE_HHG_PROCESS_FAILURE],
      authenticated: true,
      endpoint: 'clients/hhgProcesses',
      method: 'POST',
      payload: values,
    },
  };
};


export const deleteHomesaleHHGProcess = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_HOMESALE_HHG_PROCESS_REQUEST, DELETE_HOMESALE_HHG_PROCESS_SUCCESS, DELETE_HOMESALE_HHG_PROCESS_FAILURE],
      authenticated: true,
      endpoint: `clients/hhgProcesses/${id}`,
      method: 'DELETE',
    },
  };
};

export const updateHomesaleHHGProcess = (values) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_HOMESALE_HHG_PROCESS_REQUEST, UPDATE_HOMESALE_HHG_PROCESS_SUCCESS, UPDATE_HOMESALE_HHG_PROCESS_FAILURE],
      authenticated: true,
      endpoint: 'clients/hhgProcesses',
      method: 'PUT',
      payload: values,
    },
  };
};

export const setLoadingComplete = () => ({
  type: SET_LOADING_COMPLETE,
});